import * as React from 'react';
import { IAusgabe } from './types';
import { Row, Col } from 'reactstrap';
import { EnumInput, CurrencyInput, DateInput, TextInput } from '../../common/aif';
import { FaTrashAlt } from 'react-icons/fa';

interface IAusgabeProps {
    data: IAusgabe
    onChange?(data: IAusgabe): void
    onRemove?(data: IAusgabe): void
}

interface IAusgabeState {
    data: IAusgabe
}

export default class Ausgabe extends React.Component<IAusgabeProps, IAusgabeState> {
    constructor(props: IAusgabeProps) {
        super(props);

        this.state = {
            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IAusgabeProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.data);
        }
    }
    public render() {
        return (
            <Row className="border mb-2">
                <Col>
                    <EnumInput required label="Art" name="ausgabe_art" value={this.state.data.ausgabe_art} onChange={this.onChange} />
                </Col>

                <Col md="2">
                    <DateInput label="Gültig ab" name="gueltig_ab" value={this.state.data.gueltig_ab} onChange={this.onChange} />
                </Col>
                <Col md="2">
                    <DateInput label="Gültig bis" name="gueltig_bis" value={this.state.data.gueltig_bis} onChange={this.onChange} />
                </Col>
                <Col>
                    <TextInput label="Bemerkung" name="bemerkung" value={this.state.data.bemerkung} onChange={this.onChange} />
                </Col>
                <Col md="2">
                    <CurrencyInput required label="Betrag" name="betrag" value={this.state.data.betrag} onChange={this.onChange} />
                </Col>
                <Col md="1">
                    <FaTrashAlt style={{ marginTop: '100%' }} className="removeIcon" onClick={this.onRemove} title="Entfernen" />
                </Col>
            </Row>
        );
    }
}
