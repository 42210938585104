import * as React from 'react';
import { IBsvProvisionVermittler, IBsvProvisionVermittlerDaten } from '../types';
import { Row, Col } from 'reactstrap';
import { NumberInput, PercentInput, AButton } from '../../common/aif';
import { FaPlus } from 'react-icons/fa';
import ProvisionVermittlerDaten from './ProvisionVermittlerDaten';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

interface IProvisionVermittlerProps {
    data?: IBsvProvisionVermittler
    onChange?(data: IBsvProvisionVermittler): void
}

interface IProvisionVermittlerState {
    data: IBsvProvisionVermittler
}

export default class ProvisionVermittler extends React.Component<IProvisionVermittlerProps, IProvisionVermittlerState> {
    constructor(props: IProvisionVermittlerProps) {
        super(props);

        this.state = {
            data: props.data || {}
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addVermitlerdaten = this.addVermitlerdaten.bind(this);
        this.deleteVermitlerdata = this.deleteVermitlerdata.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IProvisionVermittlerProps) {
        this.setState({ data: newProps.data || {} });
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    addVermitlerdaten() {
        let liste = [...this.state.data.vermittler_daten_liste || []];
        liste.push({});
        const copy = Object.assign(this.state.data, { vermittler_daten_liste: liste });
        this.setState({ data: copy }, this.notify);
    }

    deleteVermitlerdata(data: IBsvProvisionVermittlerDaten) {
        const index = this.state.data.vermittler_daten_liste?.indexOf(data);
        if (index !== undefined && index !== -1) {
            const liste = [...this.state.data.vermittler_daten_liste || []]
            liste.splice(index, 1);
            const copy = Object.assign(this.state.data, { vermittler_daten_liste: liste });
            this.setState({ data: copy }, this.notify);
        }
    }


    public render() {
        const liste = this.state.data.vermittler_daten_liste?.map((data, i) => <ProvisionVermittlerDaten key={i} data={data} onRemove={this.deleteVermitlerdata} onChange={this.notify} />);
        return (
            <>
                <Row>
                    <Col>
                        <NumberInput label="Sparkassen BLB" name="sparkassen_blb" value={this.state.data.sparkassen_blb} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <NumberInput label="Werber" name="bsv_werber" value={this.state.data.bsv_werber} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <PercentInput label="Werber Satz" name="bsv_werber_satz" value={this.state.data.bsv_werber_satz} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CheckBoxInput label="Sondervermittlung" name="sondervermittlung" value={this.state.data.sondervermittlung} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="text-secondary">Vermitlerdaten</Col>
                    <Col sm="1">
                        <AButton color="info" size="sm" outline rounded onClick={this.addVermitlerdaten} ><FaPlus /></AButton>
                    </Col>
                </Row>
                {liste}
                <div className="mb-2"></div>
            </>
        );
    }
}
