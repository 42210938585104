import * as React from 'react';
import { Container } from 'reactstrap';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import PersonListe from '../../common/Person/PersonListe';
import { IBausparantrag } from '../types';

interface IPersonenProps {
    bsv: IBausparantrag
    onDataChange(obj: any): void
}

interface IPersonenState {
    bsv: IBausparantrag
    _render?: boolean
}

export default class Personen extends React.Component<IPersonenProps, IPersonenState> {
    constructor(props: IPersonenProps) {
        super(props);
        this.state = {
            bsv: props.bsv
        }
        this.onChange = this.onChange.bind(this);
        this.onPLChange = this.onPLChange.bind(this);
    }

    shouldComponentUpdate(nextProps: IPersonenProps, nextState: IPersonenState) {
        if (nextState._render === false) {
            delete nextState._render;
            return false;
        }
        return true;
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let obj = { [ev.target.name]: ev.target.value }
        this.props.onDataChange(obj);
    }

    onPLChange(obj: any) {

        let copy = Object.assign(this.state.bsv, obj);
        let _render = obj.person_liste && (obj.person_liste?.length !== this.state.bsv.person_liste.length);
        this.setState({ bsv: copy, _render: _render });
        this.props.onDataChange(obj);
    }

    public render() {
        if (!this.state.bsv) return <></>
        const pcount = this.state.bsv.person_liste.length > 1;
        let beguenstigung_gegenseitig = pcount ? <CheckBoxInput label="Beguenstigung gegenseitig" name="beguenstigung_gegenseitig" value={this.props.bsv.beguenstigung_gegenseitig} onChange={this.onChange} /> : '';
        return (
            <div>
                <React.Fragment key="checkbox"><Container>{beguenstigung_gegenseitig}</Container></React.Fragment>
                <PersonListe verbund_liste={this.props.bsv.verbund_liste || []} person_liste={this.props.bsv.person_liste} onChange={this.onPLChange} />
            </div>
        );
    }
}
