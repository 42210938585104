import * as React from 'react';
import { Container, Fade, Row, Col } from 'reactstrap';
import { ISpardaten } from '../types';
import CurrencyInput from '../../common/aif/CurrencyInput';
import BSTarifSelect from '../../common/BSTarivSelect';
import DateInput from '../../common/aif/DateInput';
import TextInput from '../../common/aif/TextInput';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

interface ISpardatenProps {
    data: ISpardaten
    onChange?(data: ISpardaten): void
}

interface ISpardatenState {
    data: ISpardaten
}

export default class Spardaten extends React.Component<ISpardatenProps, ISpardatenState> {

    constructor(props: ISpardatenProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col>
                            <CurrencyInput required label="Bausparsume" name="bausparsumme" value={this.state.data.bausparsumme} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <BSTarifSelect required label="BSV Tarif" name="tarif_nr" value={this.state.data.tarif_nr} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <DateInput label="Vertragsbeginn" required name="vertragsbeginn" value={this.state.data.vertragsbeginn} onChange={this.onChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col><TextInput label="Bausparvertrag Nr." name="bausparvertrag_nr" value={this.state.data.bausparvertrag_nr} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput label="Abo 'Das Haus' " name="abo_das_haus" value={this.state.data.abo_das_haus} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput label="Dauerzulagenantrag" name="dauerzulagenantrag" value={this.state.data.dauerzulagenantrag} onChange={this.onChange} /></Col>
                    </Row>
                </Fade>
            </Container>
        );
    }
}

