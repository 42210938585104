import * as React from 'react';
import { Container, Fade, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { ILbsBauspardarlehen, TFinanzierungsmittel, ILbsDarlehenOhneBsv, ILbsKredit, IFremddarlehen } from './types';
import LbsBauspardarlehen from './LbsBauspardarlehen';
import TarifService, { ITarifData } from '../../common/Settings/Tarife/TarifService';
import './style.css';
import { IVerbund } from '../../common/Verbund/types';
import { FaPlus } from 'react-icons/fa';
import { IEnum, EnumService } from '../../../services';
import LbsKredit from './LbsKredit';
import LbsDarlehenOhneBsv from './LbsDarlehenOhneBsv';
import Fremddarlehen from './Fremddarlehen';
import { TPerson } from '../../common/Person/types';
import { IFM_ListeItem } from '../../common/Riesterdaten';

export * from './types';

interface IFinanzierungsbausteineProps {
  data: TFinanzierungsmittel[]
  onChange?(data: any): void
  person_liste: TPerson[]
  verbund_liste: IVerbund[]
}

interface IState {
  dropdownOpen: boolean
  liste: TFinanzierungsmittel[]
  tarife?: ITarifData
  enums?: IEnum
}

export default class Finanzierungsbausteine extends React.Component<IFinanzierungsbausteineProps, IState> {

  constructor(props: IFinanzierungsbausteineProps) {
    super(props);
    this.state = { dropdownOpen: false, liste: props.data }
    this.toggleButtonDropdown = this.toggleButtonDropdown.bind(this);
    this.addLbsBauspardarlehen = this.addLbsBauspardarlehen.bind(this);
    this.onListItemChange = this.onListItemChange.bind(this);
    this.removeListItem = this.removeListItem.bind(this);
    this.copyListItem = this.copyListItem.bind(this);
    this.addDarlehenOhneBsv = this.addDarlehenOhneBsv.bind(this);
    this.addLbsKredit = this.addLbsKredit.bind(this);
    this.addFremddarlehen = this.addFremddarlehen.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: IFinanzierungsbausteineProps) {
    this.setState({ liste: newProps.data });
  }

  UNSAFE_componentWillMount() {
    const tService = new TarifService();
    Promise.all([
      tService.getAll().then(ok => {
        this.setState({ tarife: ok[0] })
      }),
      EnumService.getEnums().then(ok => {
        this.setState({ enums: ok })
      })
    ])
  }

  toggleButtonDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  async addLbsBauspardarlehen() {
    const tService = new TarifService();
    const tarife = await tService.getAll();
    const t = tarife[0];
    const init: ILbsBauspardarlehen = {
      antragssteller_person_index: 0,
      bausparsumme: 200000,
      termin_auszahlung: new Date(),
      finanzierungsmittel_art: 'LBS Bauspardarlehen',
      spar_raten_liste: [],
      darlehen_raten_liste: [],
      tarif: t.data.nummer,
      darlehen_betrag: 0
    }
    let copy = Object.assign({}, this.state);
    copy.liste.push(init);
    this.setState({ liste: copy.liste });
  }
  async addFremddarlehen() {
    const init: IFremddarlehen = {
      antragssteller_person_index: 0,
      darlehen_betrag: 0,
      termin_auszahlung: new Date(),
      finanzierungsmittel_art: 'Fremddarlehen',
      sollzins: 1,
      tilgungsform: 'Annuitätendarlehen'
    }

    let copy = Object.assign({}, this.state);
    copy.liste.push(init);
    this.setState({ liste: copy.liste });
  }

  async addLbsKredit() {
    const tService = new TarifService();
    const tarife = await tService.getAll();
    const t = tarife[0];

    const item: ILbsKredit = {
      antragssteller_person_index: 0,
      bausparsumme: 10000,
      finanzierungsmittel_art: 'LBS Kredit',
      kredit_art: 'Vorfinanzierungskredit',
      kredit_betrag: 10000,
      raten_liste: [],
      sollzins: 1,
      termin_auszahlung: new Date(),
      tarif: t.data.nummer,
      zwischenfinanzierung: false,
    }
    let copy = Object.assign({}, this.state);
    copy.liste.push(item);
    this.setState({ liste: copy.liste });
  }

  addDarlehenOhneBsv() {
    const item: ILbsDarlehenOhneBsv = {
      antragssteller_person_index: 0,
      darlehen_betrag: 10000,
      finanzierungsmittel_art: 'LBS Darlehen ohne BSV',
      sollzins: 1,
      termin_auszahlung: new Date()
    }

    let copy = Object.assign({}, this.state);
    copy.liste.push(item);
    this.setState({ liste: copy.liste });
  }


  onListItemChange(index: number, item: TFinanzierungsmittel) {
    const copy = [...this.state.liste];
    copy[index] = item;
    this.setState({ liste: copy }, () => {
      if (this.props.onChange) {
        this.props.onChange({ finanzierungsmittel_liste: this.state.liste });
      }
    });
  }

  removeListItem(index: number) {
    const copy = [...this.state.liste]
    copy.splice(index, 1);
    this.setState({ liste: copy }, () => {
      if (this.props.onChange) {
        this.props.onChange({ finanzierungsmittel_liste: this.state.liste });
      }
    });
  }

  copyListItem(data: any) {
    const copy = [...this.state.liste]
    copy.push({...data})
    this.setState({liste: copy}, () => {
      if (this.props.onChange) {
        this.props.onChange({ finanzierungsmittel_liste: this.state.liste });
      }
    });
  }

  public render() {

    const fm_liste: IFM_ListeItem[] = this.state.liste.map((stein, i) => {
      return { index: i, title: stein.finanzierungsmittel_art }
    });


    const bausteine = this.state.liste.map((stein, i) => {
      const data: any = stein;
      const getStein = () => {
        const filtered_FMList = fm_liste.filter(li => li.index !== i);

        if (stein.finanzierungsmittel_art === "LBS Bauspardarlehen") {
          return (<LbsBauspardarlehen
            onRemove={this.removeListItem}
            onCopy={this.copyListItem}
            onChange={this.onListItemChange}
            data={data} index={i}
            person_liste={this.props.person_liste}
            verbund_liste={this.props.verbund_liste}
            fm_liste={filtered_FMList}
            key={i}
          />)
        }
        else if (stein.finanzierungsmittel_art === "LBS Darlehen ohne BSV") {
          return (<LbsDarlehenOhneBsv
            onRemove={this.removeListItem}
            onCopy={this.copyListItem}
            onChange={this.onListItemChange}
            data={data} key={i}
            person_liste={this.props.person_liste}
            verbund_liste={this.props.verbund_liste}
            index={i}
          />)
        } else if (stein.finanzierungsmittel_art === "LBS Kredit") {
          return (<LbsKredit
            onRemove={this.removeListItem}
            onCopy={this.copyListItem}
            onChange={this.onListItemChange}
            data={data} key={i}
            person_liste={this.props.person_liste}
            verbund_liste={this.props.verbund_liste}
            index={i}
            fm_liste={filtered_FMList}
          />)
        } else if (stein.finanzierungsmittel_art === "Fremddarlehen") {
          return (<Fremddarlehen
            onRemove={this.removeListItem}
            onCopy={this.copyListItem}
            onChange={this.onListItemChange}
            data={data} key={i}
            person_liste={this.props.person_liste}
            verbund_liste={this.props.verbund_liste}
            index={i}
          />)
        }
      }
      return (<ListGroupItem key={i}>{getStein()}</ListGroupItem>)
    }
    )

    return (
      <Container className="mt-4" style={{ minHeight: '600px' }}>
        <Fade>
          <div className="text-right mb-2">
            <ButtonDropdown color="info" isOpen={this.state.dropdownOpen} toggle={this.toggleButtonDropdown}>
              <DropdownToggle caret color="info">
                <FaPlus /> Neu
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.addLbsKredit}>LBS Kredit</DropdownItem>
                <DropdownItem divider />

                <DropdownItem onClick={this.addLbsBauspardarlehen}>LBS Bauspardarlehen</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.addDarlehenOhneBsv}>LBS Darlehen ohne Bsv</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.addFremddarlehen}>Fremddarlehen</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <ListGroup>{bausteine}</ListGroup>
        </Fade>
      </Container>
    );
  }
}
