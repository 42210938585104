import * as React from 'react';
import { IAbteilung3 } from './types';
import Abteilung3 from './Abteilung3';
import {  Row, Col } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { AButton } from '../../../common/aif';

interface IReturn {
    abteilung3_liste: IAbteilung3[]
}
interface IA3ListeProps {
    data?: IAbteilung3[]
    onChange?(data: IReturn): void
}

interface IA3ListeState {
    data: IAbteilung3[]
}

export default class A3Liste extends React.Component<IA3ListeProps, IA3ListeState> {
    constructor(props: IA3ListeProps) {
        super(props);
        this.state = {
            data: this.props.data || []
        }
        this.addItem = this.addItem.bind(this);
        this.remove = this.remove.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onChange) { this.props.onChange({ abteilung3_liste: this.state.data }) }
    }

    UNSAFE_componentWillReceiveProps(newProps: IA3ListeProps) {
        this.setState({ data: newProps.data || [] });
    }

    addItem() {
        let item: IAbteilung3 = {
            abtretung_liste: [],
            eigenrecht:false,
            neueintragung:false,
        }
        let copy = [...this.state.data];
        copy.push(item);
        this.setState({ data: copy });
    }

    remove(data: IAbteilung3) {
        const index = this.state.data.indexOf(data);

        if (index !== -1) {
            let copy = [...this.state.data];
            copy.splice(index, 1);
            this.setState({ data: copy });
        }
    }


    public render() {
        const items = this.state.data.map((a, i) => {
            return <Abteilung3 key={i} data={a} onRemove={this.remove} />
        });

        return (
            <div>
                <Row>
                    <Col>
                        <div className="text-secondary pl-2">Belastungen</div>
                    </Col>
                    <Col>
                        <div className="text-right"><AButton rounded onClick={this.addItem} size="sm" color="info" outline><FaPlus /></AButton> </div>
                    </Col>
                </Row>
                {items}
            </div>
        );
    }
}
