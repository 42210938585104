import * as React from 'react';
import { IKommunikationsdaten } from '../types';
import { IEnum } from '../../../../services';
import { Row, Col } from 'reactstrap';
import EnumInput from '../../aif/EnumInput';
import TextInput from '../../aif/TextInput';
import { FaTrashAlt } from 'react-icons/fa';
import CheckBoxInput from '../../aif/CheckBoxInput';
import TrivalentInput, { ITrivalent } from '../../aif/TrivalentInput';

interface IEntryProps {
    data: IKommunikationsdaten
    enums?: IEnum
    onRemove?(item: IKommunikationsdaten): void
}

interface IEntryState {
    data: IKommunikationsdaten
}

export default class Entry extends React.Component<IEntryProps, IEntryState> {
    constructor(props: IEntryProps) {
        super(props);
        this.state = {
            data: this.props.data
        }
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onTrivalentChange = this.onTrivalentChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const e = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: e });
    }

    onTrivalentChange(data: ITrivalent) {
        const copy = Object.assign(this.state.data, { [data.name]: data.value });
        this.setState({ data: copy });
    }

    onRemove() {
        if (this.props.onRemove) { this.props.onRemove(this.props.data) }
    }

    public render() {
        return (
            <Row >
                <Col>
                    <Row className="border-bottom">    
                        <Col md="2"><EnumInput label="Art" name="kommunikation_art" value={this.state.data.kommunikation_art} onChange={this.onChange} /> </Col>
                        <Col md="2"><EnumInput label="Nutzung" name="kommunikation_nutzung" value={this.state.data.kommunikation_nutzung} onChange={this.onChange} /> </Col>
                        <Col><TextInput label="Adresse" name="kommunikation_adresse" value={this.state.data.kommunikation_adresse} onChange={this.onChange} /> </Col>
                        <Col md="1" ><FaTrashAlt style={{position:'relative',marginTop:'90%'}} className="removeIcon"  onClick={this.onRemove} /></Col>
                    </Row>
                    <Row className="border-bottom">
                        <Col md="2"><TextInput label="Adressen Id" name="ans_id" value={this.state.data.ans_id} onChange={this.onChange} /> </Col>
                        <Col md="2"><TextInput label="Bemerkung" name="bemerkung" value={this.state.data.bemerkung} onChange={this.onChange} /> </Col>
                        <Col md="2"><CheckBoxInput label="Aktualisierung" name="einfache_aktualisierung" value={this.state.data.einfache_aktualisierung} onChange={this.onChange} /> </Col>
                        <Col md="3"><TrivalentInput label="Einwilligung Produkt Information" name="einwilligung_produkt_information" value={this.state.data.einwilligung_produkt_information} onChange={this.onTrivalentChange} /> </Col>
                        <Col md="2"><CheckBoxInput label="Löschen" name="loesch_kz" value={this.state.data.loesch_kz} onChange={this.onChange} /> </Col>
                    </Row> 
                </Col>
            </Row>
        );
    }
}
