import * as React from 'react';

export interface IPercentInputProps {
    name: string
    label?: string
    value?: number
    required?: boolean
    /**
     * default DE-de
     */
    localeString?: string
    /**
     * default EUR
     */
    currency?: string
    append?: string
    onChange(ev: React.ChangeEvent<HTMLInputElement>): void
    onBlur?(): void
    autoFocus?: boolean
}
const null_value = '-.-';
export default class PercentInput extends React.Component<IPercentInputProps> {
    constructor(props: IPercentInputProps) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.toMaskedValue = this.toMaskedValue.bind(this);
    }

    toMaskedValue(): string {
        const value: any = this.props.value
        if (value !== undefined && value !== null && value !== '') {
            const val: number = value;
            const corrected = val / 100;
            const ret = parseFloat(corrected + '').toLocaleString(this.props.localeString || 'DE-de',
                {
                    style: 'percent',
                    minimumFractionDigits: 2
                });
            let i = ret.indexOf('%');
            const digits = ret.match(/\d+/g);
            return (digits !== null) ? ret.substr(0, i - 1) : null_value;
        }
        else {
            return null_value;
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const mval = ev.target.value;
        try {
            if (mval !== undefined) {
                const digits = mval.match(/\d+/g);
                let val = null;
                if (digits !== null) { val = parseFloat((digits ? digits.join('') : '0.00')) / 100; }
                ev.target = Object.assign(ev.target, { value: val });
                this.props.onChange(ev);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public render() {
        let mainClass = 'AnimatedInput PercentInput'
        const value = this.toMaskedValue();
        const hasValue = (value && value !== null_value) ? true : false;
        let labelClass = hasValue ? 'hasValue' : ''
        if (this.props.required && !hasValue) { mainClass += ' invalid'; }
        return (
            <div className={mainClass}>
                <input type="text"
                    name={this.props.name}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.props.onBlur}
                    autoFocus={this.props.autoFocus}
                />
                <label htmlFor={this.props.name} className={labelClass}>
                    <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''} {this.props.append}</span>
                </label>
            </div>
        );
    }
}
