import * as React from 'react';
import { Link } from 'react-router-dom';
import { IFinanzierungsantrag } from '../../Finanzierungsantrag/types';
import { Badge } from 'reactstrap';

export interface IFbadListItemProps {
    data: any
}

export default class FbadListItem extends React.Component<IFbadListItemProps> {
    public render() {
        const fbad: IFinanzierungsantrag = this.props.data.fbad;
        const title = fbad.person_liste.map((p: any, index) => {
            if (index > 2) return '.';
            return <span key={index}>{index === 0 ? '' : ' ,'} {p.vorname} {p.nachname}</span>
        })
        const path = '/fbad/' + this.props.data._idbid;
        return (
            <div className="InboxListItem">
                <div>
                    <Badge className={fbad.antrag_art}> FBAD </Badge>
                    <Link to={path}  >{title}</Link>
                </div>
                <div className="text-right">
                    {this.props.data.datum + " "}
                    {fbad.status}</div>
            </div>
        );
    }
}
