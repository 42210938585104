const PlzOrt={
    "10115": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10117": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10119": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10178": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10179": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10243": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10245": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10247": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10249": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10315": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10317": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10318": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10319": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10365": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10367": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10369": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10405": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10407": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10409": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10435": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10437": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10439": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10551": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10553": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10555": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10557": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10559": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10585": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10587": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10589": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10623": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10625": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10627": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10629": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10707": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10709": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10711": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10713": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10715": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10717": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10719": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10777": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10779": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10781": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10783": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10785": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10787": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10789": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10823": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10825": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10827": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10829": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10961": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10963": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10965": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10967": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10969": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10997": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "10999": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12043": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12045": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12047": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12049": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12051": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12053": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12055": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12057": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12059": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12099": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12101": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12103": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12105": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12107": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12109": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12157": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12159": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12161": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12163": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12165": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12167": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12169": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12203": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12205": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12207": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12209": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12247": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12249": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12277": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12279": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12305": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12307": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12309": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12347": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12349": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12351": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12353": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12355": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12357": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12359": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12435": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12437": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12439": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12459": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12487": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12489": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12524": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12526": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12527": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12529": [
        {
            "o": "Schönefeld",
            "l": "Brandenburg"
        }
    ],
    "12555": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12557": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12559": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12587": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12589": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12619": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12621": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12623": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12627": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12629": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12679": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12681": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12683": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12685": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12687": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "12689": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13051": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13053": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13055": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13057": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13059": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13086": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13088": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13089": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13125": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13127": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13129": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13156": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13158": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13159": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13187": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13189": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13347": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13349": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13351": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13353": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13355": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13357": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13359": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13403": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13405": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13407": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13409": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13435": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13437": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13439": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13465": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13467": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13469": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13503": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13505": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13507": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13509": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13581": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13583": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13585": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13587": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13589": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13591": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13593": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13595": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13597": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13599": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13627": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "13629": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14050": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14052": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14053": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14055": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14057": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14059": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14089": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14109": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14129": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14163": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14165": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14167": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14169": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14193": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14195": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14197": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14199": [
        {
            "o": "Berlin",
            "l": "Berlin"
        }
    ],
    "14467": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14469": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14471": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14473": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14476": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14478": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14480": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14482": [
        {
            "o": "Potsdam",
            "l": "Brandenburg"
        }
    ],
    "14513": [
        {
            "o": "Teltow",
            "l": "Brandenburg"
        }
    ],
    "14532": [
        {
            "o": "Kleinmachnow",
            "l": "Brandenburg"
        },
        {
            "o": "Stahnsdorf",
            "l": "Brandenburg"
        }
    ],
    "14542": [
        {
            "o": "Werder (Havel)",
            "l": "Brandenburg"
        }
    ],
    "14547": [
        {
            "o": "Beelitz",
            "l": "Brandenburg"
        }
    ],
    "14548": [
        {
            "o": "Schwielowsee",
            "l": "Brandenburg"
        }
    ],
    "14550": [
        {
            "o": "Groß Kreutz (Havel)",
            "l": "Brandenburg"
        }
    ],
    "14552": [
        {
            "o": "Michendorf",
            "l": "Brandenburg"
        }
    ],
    "14554": [
        {
            "o": "Seddiner See",
            "l": "Brandenburg"
        }
    ],
    "14558": [
        {
            "o": "Nuthetal",
            "l": "Brandenburg"
        }
    ],
    "14612": [
        {
            "o": "Falkensee",
            "l": "Brandenburg"
        }
    ],
    "14621": [
        {
            "o": "Schönwalde-Glien",
            "l": "Brandenburg"
        }
    ],
    "14624": [
        {
            "o": "Dallgow-Döberitz",
            "l": "Brandenburg"
        }
    ],
    "14641": [
        {
            "o": "Nauen",
            "l": "Brandenburg"
        },
        {
            "o": "Paulinenaue",
            "l": "Brandenburg"
        },
        {
            "o": "Pessin",
            "l": "Brandenburg"
        },
        {
            "o": "Retzow",
            "l": "Brandenburg"
        },
        {
            "o": "Wustermark",
            "l": "Brandenburg"
        }
    ],
    "14656": [
        {
            "o": "Brieselang",
            "l": "Brandenburg"
        }
    ],
    "14662": [
        {
            "o": "Friesack",
            "l": "Brandenburg"
        },
        {
            "o": "Mühlenberge",
            "l": "Brandenburg"
        },
        {
            "o": "Wiesenaue",
            "l": "Brandenburg"
        }
    ],
    "14669": [
        {
            "o": "Ketzin/Havel",
            "l": "Brandenburg"
        }
    ],
    "14712": [
        {
            "o": "Rathenow",
            "l": "Brandenburg"
        }
    ],
    "14715": [
        {
            "o": "Havelaue",
            "l": "Brandenburg"
        },
        {
            "o": "Kotzen",
            "l": "Brandenburg"
        },
        {
            "o": "Märkisch Luch",
            "l": "Brandenburg"
        },
        {
            "o": "Milower Land",
            "l": "Brandenburg"
        },
        {
            "o": "Nennhausen",
            "l": "Brandenburg"
        },
        {
            "o": "Schollene",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Seeblick",
            "l": "Brandenburg"
        },
        {
            "o": "Stechow-Ferchesar",
            "l": "Brandenburg"
        }
    ],
    "14727": [
        {
            "o": "Premnitz",
            "l": "Brandenburg"
        }
    ],
    "14728": [
        {
            "o": "Gollenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Kleßen-Görne",
            "l": "Brandenburg"
        },
        {
            "o": "Rhinow",
            "l": "Brandenburg"
        }
    ],
    "14770": [
        {
            "o": "Brandenburg an der Havel",
            "l": "Brandenburg"
        }
    ],
    "14772": [
        {
            "o": "Brandenburg an der Havel",
            "l": "Brandenburg"
        }
    ],
    "14774": [
        {
            "o": "Brandenburg an der Havel",
            "l": "Brandenburg"
        }
    ],
    "14776": [
        {
            "o": "Brandenburg an der Havel",
            "l": "Brandenburg"
        }
    ],
    "14778": [
        {
            "o": "Beetzsee",
            "l": "Brandenburg"
        },
        {
            "o": "Beetzseeheide",
            "l": "Brandenburg"
        },
        {
            "o": "Golzow",
            "l": "Brandenburg"
        },
        {
            "o": "Päwesin",
            "l": "Brandenburg"
        },
        {
            "o": "Roskow",
            "l": "Brandenburg"
        },
        {
            "o": "Wenzlow",
            "l": "Brandenburg"
        },
        {
            "o": "Wollin",
            "l": "Brandenburg"
        }
    ],
    "14789": [
        {
            "o": "Bensdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Rosenau",
            "l": "Brandenburg"
        },
        {
            "o": "Wusterwitz",
            "l": "Brandenburg"
        }
    ],
    "14793": [
        {
            "o": "Buckautal",
            "l": "Brandenburg"
        },
        {
            "o": "Gräben",
            "l": "Brandenburg"
        },
        {
            "o": "Ziesar",
            "l": "Brandenburg"
        }
    ],
    "14797": [
        {
            "o": "Kloster Lehnin",
            "l": "Brandenburg"
        }
    ],
    "14798": [
        {
            "o": "Havelsee",
            "l": "Brandenburg"
        }
    ],
    "14806": [
        {
            "o": "Bad Belzig",
            "l": "Brandenburg"
        },
        {
            "o": "Planetal",
            "l": "Brandenburg"
        }
    ],
    "14822": [
        {
            "o": "Borkheide",
            "l": "Brandenburg"
        },
        {
            "o": "Borkwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Brück",
            "l": "Brandenburg"
        },
        {
            "o": "Linthe",
            "l": "Brandenburg"
        },
        {
            "o": "Mühlenfließ",
            "l": "Brandenburg"
        },
        {
            "o": "Planebruch",
            "l": "Brandenburg"
        }
    ],
    "14823": [
        {
            "o": "Mühlenfließ",
            "l": "Brandenburg"
        },
        {
            "o": "Niemegk",
            "l": "Brandenburg"
        },
        {
            "o": "Rabenstein/Fläming",
            "l": "Brandenburg"
        }
    ],
    "14827": [
        {
            "o": "Wiesenburg/Mark",
            "l": "Brandenburg"
        }
    ],
    "14828": [
        {
            "o": "Görzke",
            "l": "Brandenburg"
        }
    ],
    "14913": [
        {
            "o": "Jüterbog",
            "l": "Brandenburg"
        },
        {
            "o": "Niederer Fläming",
            "l": "Brandenburg"
        },
        {
            "o": "Niedergörsdorf",
            "l": "Brandenburg"
        }
    ],
    "14929": [
        {
            "o": "Treuenbrietzen",
            "l": "Brandenburg"
        }
    ],
    "14943": [
        {
            "o": "Luckenwalde",
            "l": "Brandenburg"
        }
    ],
    "14947": [
        {
            "o": "Nuthe-Urstromtal",
            "l": "Brandenburg"
        }
    ],
    "14959": [
        {
            "o": "Trebbin",
            "l": "Brandenburg"
        }
    ],
    "14974": [
        {
            "o": "Ludwigsfelde",
            "l": "Brandenburg"
        }
    ],
    "14979": [
        {
            "o": "Großbeeren",
            "l": "Brandenburg"
        }
    ],
    "15230": [
        {
            "o": "Frankfurt (Oder)",
            "l": "Brandenburg"
        }
    ],
    "15232": [
        {
            "o": "Frankfurt (Oder)",
            "l": "Brandenburg"
        }
    ],
    "15234": [
        {
            "o": "Frankfurt (Oder)",
            "l": "Brandenburg"
        }
    ],
    "15236": [
        {
            "o": "Frankfurt (Oder)",
            "l": "Brandenburg"
        },
        {
            "o": "Jacobsdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Treplin",
            "l": "Brandenburg"
        }
    ],
    "15295": [
        {
            "o": "Brieskow-Finkenheerd",
            "l": "Brandenburg"
        },
        {
            "o": "Groß Lindow",
            "l": "Brandenburg"
        },
        {
            "o": "Wiesenau",
            "l": "Brandenburg"
        },
        {
            "o": "Ziltendorf",
            "l": "Brandenburg"
        }
    ],
    "15299": [
        {
            "o": "Grunow-Dammendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Mixdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Müllrose",
            "l": "Brandenburg"
        }
    ],
    "15306": [
        {
            "o": "Falkenhagen (Mark)",
            "l": "Brandenburg"
        },
        {
            "o": "Fichtenhöhe",
            "l": "Brandenburg"
        },
        {
            "o": "Gusow-Platkow",
            "l": "Brandenburg"
        },
        {
            "o": "Lietzen",
            "l": "Brandenburg"
        },
        {
            "o": "Lindendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Seelow",
            "l": "Brandenburg"
        },
        {
            "o": "Vierlinden",
            "l": "Brandenburg"
        }
    ],
    "15320": [
        {
            "o": "Neuhardenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Neutrebbin",
            "l": "Brandenburg"
        }
    ],
    "15324": [
        {
            "o": "Letschin",
            "l": "Brandenburg"
        }
    ],
    "15326": [
        {
            "o": "Lebus",
            "l": "Brandenburg"
        },
        {
            "o": "Podelzig",
            "l": "Brandenburg"
        },
        {
            "o": "Zeschdorf",
            "l": "Brandenburg"
        }
    ],
    "15328": [
        {
            "o": "Alt Tucheband",
            "l": "Brandenburg"
        },
        {
            "o": "Bleyen-Genschmar",
            "l": "Brandenburg"
        },
        {
            "o": "Golzow",
            "l": "Brandenburg"
        },
        {
            "o": "Küstriner Vorland",
            "l": "Brandenburg"
        },
        {
            "o": "Reitwein",
            "l": "Brandenburg"
        },
        {
            "o": "Zechin",
            "l": "Brandenburg"
        }
    ],
    "15344": [
        {
            "o": "Strausberg",
            "l": "Brandenburg"
        }
    ],
    "15345": [
        {
            "o": "Altlandsberg",
            "l": "Brandenburg"
        },
        {
            "o": "Garzau-Garzin",
            "l": "Brandenburg"
        },
        {
            "o": "Petershagen/Eggersdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Prötzel",
            "l": "Brandenburg"
        },
        {
            "o": "Rehfelde",
            "l": "Brandenburg"
        },
        {
            "o": "Reichenow-Möglin",
            "l": "Brandenburg"
        },
        {
            "o": "Rüdersdorf bei Berlin",
            "l": "Brandenburg"
        }
    ],
    "15366": [
        {
            "o": "Hoppegarten",
            "l": "Brandenburg"
        },
        {
            "o": "Neuenhagen bei Berlin",
            "l": "Brandenburg"
        }
    ],
    "15370": [
        {
            "o": "Fredersdorf-Vogelsdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Petershagen/Eggersdorf",
            "l": "Brandenburg"
        }
    ],
    "15374": [
        {
            "o": "Müncheberg",
            "l": "Brandenburg"
        }
    ],
    "15377": [
        {
            "o": "Buckow",
            "l": "Brandenburg"
        },
        {
            "o": "Märkische Höhe",
            "l": "Brandenburg"
        },
        {
            "o": "Oberbarnim",
            "l": "Brandenburg"
        },
        {
            "o": "Waldsieversdorf",
            "l": "Brandenburg"
        }
    ],
    "15378": [
        {
            "o": "Rüdersdorf bei Berlin",
            "l": "Brandenburg"
        }
    ],
    "15517": [
        {
            "o": "Fürstenwalde/Spree",
            "l": "Brandenburg"
        }
    ],
    "15518": [
        {
            "o": "Berkenbrück",
            "l": "Brandenburg"
        },
        {
            "o": "Briesen (Mark)",
            "l": "Brandenburg"
        },
        {
            "o": "Langewahl",
            "l": "Brandenburg"
        },
        {
            "o": "Rauen",
            "l": "Brandenburg"
        },
        {
            "o": "Steinhöfel",
            "l": "Brandenburg"
        }
    ],
    "15526": [
        {
            "o": "Bad Saarow",
            "l": "Brandenburg"
        },
        {
            "o": "Reichenwalde",
            "l": "Brandenburg"
        }
    ],
    "15528": [
        {
            "o": "Spreenhagen",
            "l": "Brandenburg"
        }
    ],
    "15537": [
        {
            "o": "Erkner",
            "l": "Brandenburg"
        },
        {
            "o": "Gosen-Neu Zittau",
            "l": "Brandenburg"
        },
        {
            "o": "Grünheide (Mark)",
            "l": "Brandenburg"
        }
    ],
    "15562": [
        {
            "o": "Rüdersdorf bei Berlin",
            "l": "Brandenburg"
        }
    ],
    "15566": [
        {
            "o": "Schöneiche bei Berlin",
            "l": "Brandenburg"
        }
    ],
    "15569": [
        {
            "o": "Woltersdorf",
            "l": "Brandenburg"
        }
    ],
    "15711": [
        {
            "o": "Königs Wusterhausen",
            "l": "Brandenburg"
        }
    ],
    "15712": [
        {
            "o": "Königs Wusterhausen",
            "l": "Brandenburg"
        }
    ],
    "15713": [
        {
            "o": "Königs Wusterhausen",
            "l": "Brandenburg"
        }
    ],
    "15732": [
        {
            "o": "Eichwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Schulzendorf",
            "l": "Brandenburg"
        }
    ],
    "15738": [
        {
            "o": "Zeuthen",
            "l": "Brandenburg"
        }
    ],
    "15741": [
        {
            "o": "Bestensee",
            "l": "Brandenburg"
        }
    ],
    "15745": [
        {
            "o": "Wildau",
            "l": "Brandenburg"
        }
    ],
    "15746": [
        {
            "o": "Groß Köris",
            "l": "Brandenburg"
        }
    ],
    "15748": [
        {
            "o": "Märkisch Buchholz",
            "l": "Brandenburg"
        },
        {
            "o": "Münchehofe",
            "l": "Brandenburg"
        }
    ],
    "15749": [
        {
            "o": "Mittenwalde",
            "l": "Brandenburg"
        }
    ],
    "15754": [
        {
            "o": "Heidesee",
            "l": "Brandenburg"
        }
    ],
    "15755": [
        {
            "o": "Schwerin",
            "l": "Brandenburg"
        },
        {
            "o": "Teupitz",
            "l": "Brandenburg"
        }
    ],
    "15757": [
        {
            "o": "Halbe",
            "l": "Brandenburg"
        }
    ],
    "15806": [
        {
            "o": "Zossen",
            "l": "Brandenburg"
        }
    ],
    "15827": [
        {
            "o": "Blankenfelde-Mahlow",
            "l": "Brandenburg"
        }
    ],
    "15831": [
        {
            "o": "Blankenfelde-Mahlow",
            "l": "Brandenburg"
        }
    ],
    "15834": [
        {
            "o": "Rangsdorf",
            "l": "Brandenburg"
        }
    ],
    "15837": [
        {
            "o": "Baruth/Mark",
            "l": "Brandenburg"
        }
    ],
    "15838": [
        {
            "o": "Am Mellensee",
            "l": "Brandenburg"
        }
    ],
    "15848": [
        {
            "o": "Beeskow",
            "l": "Brandenburg"
        },
        {
            "o": "Friedland",
            "l": "Brandenburg"
        },
        {
            "o": "Ragow-Merz",
            "l": "Brandenburg"
        },
        {
            "o": "Rietz-Neuendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Tauche",
            "l": "Brandenburg"
        }
    ],
    "15859": [
        {
            "o": "Storkow (Mark)",
            "l": "Brandenburg"
        }
    ],
    "15864": [
        {
            "o": "Diensdorf-Radlow",
            "l": "Brandenburg"
        },
        {
            "o": "Wendisch Rietz",
            "l": "Brandenburg"
        }
    ],
    "15868": [
        {
            "o": "Jamlitz",
            "l": "Brandenburg"
        },
        {
            "o": "Lieberose",
            "l": "Brandenburg"
        }
    ],
    "15890": [
        {
            "o": "Eisenhüttenstadt",
            "l": "Brandenburg"
        },
        {
            "o": "Schlaubetal",
            "l": "Brandenburg"
        },
        {
            "o": "Siehdichum",
            "l": "Brandenburg"
        },
        {
            "o": "Vogelsang",
            "l": "Brandenburg"
        }
    ],
    "15898": [
        {
            "o": "Lawitz",
            "l": "Brandenburg"
        },
        {
            "o": "Neißemünde",
            "l": "Brandenburg"
        },
        {
            "o": "Neuzelle",
            "l": "Brandenburg"
        }
    ],
    "15907": [
        {
            "o": "Lübben (Spreewald)",
            "l": "Brandenburg"
        }
    ],
    "15910": [
        {
            "o": "Bersteland",
            "l": "Brandenburg"
        },
        {
            "o": "Krausnick-Groß Wasserburg",
            "l": "Brandenburg"
        },
        {
            "o": "Rietzneuendorf-Staakow",
            "l": "Brandenburg"
        },
        {
            "o": "Schlepzig",
            "l": "Brandenburg"
        },
        {
            "o": "Schönwald",
            "l": "Brandenburg"
        },
        {
            "o": "Unterspreewald",
            "l": "Brandenburg"
        }
    ],
    "15913": [
        {
            "o": "Alt Zauche-Wußwerk",
            "l": "Brandenburg"
        },
        {
            "o": "Byhleguhre-Byhlen",
            "l": "Brandenburg"
        },
        {
            "o": "Märkische Heide",
            "l": "Brandenburg"
        },
        {
            "o": "Neu Zauche",
            "l": "Brandenburg"
        },
        {
            "o": "Schwielochsee",
            "l": "Brandenburg"
        },
        {
            "o": "Spreewaldheide",
            "l": "Brandenburg"
        },
        {
            "o": "Straupitz",
            "l": "Brandenburg"
        }
    ],
    "15926": [
        {
            "o": "Heideblick",
            "l": "Brandenburg"
        },
        {
            "o": "Luckau",
            "l": "Brandenburg"
        }
    ],
    "15936": [
        {
            "o": "Dahme/Mark",
            "l": "Brandenburg"
        },
        {
            "o": "Dahmetal",
            "l": "Brandenburg"
        },
        {
            "o": "Ihlow",
            "l": "Brandenburg"
        }
    ],
    "15938": [
        {
            "o": "Drahnsdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Golßen",
            "l": "Brandenburg"
        },
        {
            "o": "Kasel-Golzig",
            "l": "Brandenburg"
        },
        {
            "o": "Steinreich",
            "l": "Brandenburg"
        }
    ],
    "16225": [
        {
            "o": "Eberswalde",
            "l": "Brandenburg"
        }
    ],
    "16227": [
        {
            "o": "Eberswalde",
            "l": "Brandenburg"
        }
    ],
    "16230": [
        {
            "o": "Breydin",
            "l": "Brandenburg"
        },
        {
            "o": "Britz",
            "l": "Brandenburg"
        },
        {
            "o": "Chorin",
            "l": "Brandenburg"
        },
        {
            "o": "Melchow",
            "l": "Brandenburg"
        },
        {
            "o": "Sydower Fließ",
            "l": "Brandenburg"
        }
    ],
    "16244": [
        {
            "o": "Schorfheide",
            "l": "Brandenburg"
        }
    ],
    "16247": [
        {
            "o": "Althüttendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Friedrichswalde",
            "l": "Brandenburg"
        },
        {
            "o": "Joachimsthal",
            "l": "Brandenburg"
        },
        {
            "o": "Ziethen",
            "l": "Brandenburg"
        }
    ],
    "16248": [
        {
            "o": "Hohenfinow",
            "l": "Brandenburg"
        },
        {
            "o": "Liepe",
            "l": "Brandenburg"
        },
        {
            "o": "Lunow-Stolzenhagen",
            "l": "Brandenburg"
        },
        {
            "o": "Niederfinow",
            "l": "Brandenburg"
        },
        {
            "o": "Oderberg",
            "l": "Brandenburg"
        },
        {
            "o": "Parsteinsee",
            "l": "Brandenburg"
        }
    ],
    "16259": [
        {
            "o": "Bad Freienwalde (Oder)",
            "l": "Brandenburg"
        },
        {
            "o": "Beiersdorf-Freudenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Falkenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Heckelberg-Brunow",
            "l": "Brandenburg"
        },
        {
            "o": "Höhenland",
            "l": "Brandenburg"
        },
        {
            "o": "Neulewin",
            "l": "Brandenburg"
        },
        {
            "o": "Oderaue",
            "l": "Brandenburg"
        }
    ],
    "16269": [
        {
            "o": "Bliesdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Wriezen",
            "l": "Brandenburg"
        }
    ],
    "16278": [
        {
            "o": "Angermünde",
            "l": "Brandenburg"
        },
        {
            "o": "Mark Landin",
            "l": "Brandenburg"
        },
        {
            "o": "Pinnow",
            "l": "Brandenburg"
        },
        {
            "o": "Schöneberg",
            "l": "Brandenburg"
        }
    ],
    "16303": [
        {
            "o": "Schwedt/Oder",
            "l": "Brandenburg"
        }
    ],
    "16306": [
        {
            "o": "Berkholz-Meyenburg",
            "l": "Brandenburg"
        },
        {
            "o": "Casekow",
            "l": "Brandenburg"
        },
        {
            "o": "Hohenselchow-Groß Pinnow",
            "l": "Brandenburg"
        },
        {
            "o": "Passow",
            "l": "Brandenburg"
        },
        {
            "o": "Zichow",
            "l": "Brandenburg"
        }
    ],
    "16307": [
        {
            "o": "Gartz (Oder)",
            "l": "Brandenburg"
        },
        {
            "o": "Mescherin",
            "l": "Brandenburg"
        },
        {
            "o": "Tantow",
            "l": "Brandenburg"
        }
    ],
    "16321": [
        {
            "o": "Bernau",
            "l": "Brandenburg"
        },
        {
            "o": "Rüdnitz",
            "l": "Brandenburg"
        }
    ],
    "16341": [
        {
            "o": "Panketal",
            "l": "Brandenburg"
        }
    ],
    "16348": [
        {
            "o": "Marienwerder",
            "l": "Brandenburg"
        },
        {
            "o": "Wandlitz",
            "l": "Brandenburg"
        }
    ],
    "16356": [
        {
            "o": "Ahrensfelde",
            "l": "Brandenburg"
        },
        {
            "o": "Werneuchen",
            "l": "Brandenburg"
        }
    ],
    "16359": [
        {
            "o": "Biesenthal",
            "l": "Brandenburg"
        }
    ],
    "16515": [
        {
            "o": "Mühlenbecker Land",
            "l": "Brandenburg"
        },
        {
            "o": "Oranienburg",
            "l": "Brandenburg"
        }
    ],
    "16540": [
        {
            "o": "Hohen Neuendorf",
            "l": "Brandenburg"
        }
    ],
    "16547": [
        {
            "o": "Birkenwerder",
            "l": "Brandenburg"
        }
    ],
    "16548": [
        {
            "o": "Glienicke/Nordbahn",
            "l": "Brandenburg"
        }
    ],
    "16552": [
        {
            "o": "Mühlenbecker Land",
            "l": "Brandenburg"
        }
    ],
    "16556": [
        {
            "o": "Hohen Neuendorf",
            "l": "Brandenburg"
        }
    ],
    "16559": [
        {
            "o": "Liebenwalde",
            "l": "Brandenburg"
        }
    ],
    "16562": [
        {
            "o": "Hohen Neuendorf",
            "l": "Brandenburg"
        }
    ],
    "16567": [
        {
            "o": "Mühlenbecker Land",
            "l": "Brandenburg"
        }
    ],
    "16727": [
        {
            "o": "Oberkrämer",
            "l": "Brandenburg"
        },
        {
            "o": "Velten",
            "l": "Brandenburg"
        }
    ],
    "16761": [
        {
            "o": "Hennigsdorf",
            "l": "Brandenburg"
        }
    ],
    "16766": [
        {
            "o": "Kremmen",
            "l": "Brandenburg"
        }
    ],
    "16767": [
        {
            "o": "Leegebruch",
            "l": "Brandenburg"
        }
    ],
    "16775": [
        {
            "o": "Gransee",
            "l": "Brandenburg"
        },
        {
            "o": "Großwoltersdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Löwenberger Land",
            "l": "Brandenburg"
        },
        {
            "o": "Schönermark",
            "l": "Brandenburg"
        },
        {
            "o": "Sonnenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Stechlin",
            "l": "Brandenburg"
        }
    ],
    "16792": [
        {
            "o": "Zehdenick",
            "l": "Brandenburg"
        }
    ],
    "16798": [
        {
            "o": "Fürstenberg/Havel",
            "l": "Brandenburg"
        }
    ],
    "16816": [
        {
            "o": "Neuruppin",
            "l": "Brandenburg"
        }
    ],
    "16818": [
        {
            "o": "Dabergotz",
            "l": "Brandenburg"
        },
        {
            "o": "Fehrbellin",
            "l": "Brandenburg"
        },
        {
            "o": "Märkisch Linden",
            "l": "Brandenburg"
        },
        {
            "o": "Neuruppin",
            "l": "Brandenburg"
        },
        {
            "o": "Rheinsberg",
            "l": "Brandenburg"
        },
        {
            "o": "Storbeck-Frankendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Temnitzquell",
            "l": "Brandenburg"
        },
        {
            "o": "Walsleben",
            "l": "Brandenburg"
        }
    ],
    "16827": [
        {
            "o": "Neuruppin",
            "l": "Brandenburg"
        }
    ],
    "16831": [
        {
            "o": "Rheinsberg",
            "l": "Brandenburg"
        }
    ],
    "16833": [
        {
            "o": "Fehrbellin",
            "l": "Brandenburg"
        },
        {
            "o": "Neuruppin",
            "l": "Brandenburg"
        }
    ],
    "16835": [
        {
            "o": "Herzberg (Mark)",
            "l": "Brandenburg"
        },
        {
            "o": "Lindow (Mark)",
            "l": "Brandenburg"
        },
        {
            "o": "Neuruppin",
            "l": "Brandenburg"
        },
        {
            "o": "Rheinsberg",
            "l": "Brandenburg"
        },
        {
            "o": "Rüthnick",
            "l": "Brandenburg"
        },
        {
            "o": "Vielitzsee",
            "l": "Brandenburg"
        }
    ],
    "16837": [
        {
            "o": "Rheinsberg",
            "l": "Brandenburg"
        }
    ],
    "16845": [
        {
            "o": "Breddin",
            "l": "Brandenburg"
        },
        {
            "o": "Dreetz",
            "l": "Brandenburg"
        },
        {
            "o": "Fehrbellin",
            "l": "Brandenburg"
        },
        {
            "o": "Großderschau",
            "l": "Brandenburg"
        },
        {
            "o": "Neustadt (Dosse)",
            "l": "Brandenburg"
        },
        {
            "o": "Sieversdorf-Hohenofen",
            "l": "Brandenburg"
        },
        {
            "o": "Stüdenitz-Schönermark",
            "l": "Brandenburg"
        },
        {
            "o": "Temnitztal",
            "l": "Brandenburg"
        },
        {
            "o": "Wusterhausen/Dosse",
            "l": "Brandenburg"
        },
        {
            "o": "Zernitz-Lohm",
            "l": "Brandenburg"
        }
    ],
    "16866": [
        {
            "o": "Gumtow",
            "l": "Brandenburg"
        },
        {
            "o": "Kyritz",
            "l": "Brandenburg"
        },
        {
            "o": "Wusterhausen/Dosse",
            "l": "Brandenburg"
        }
    ],
    "16868": [
        {
            "o": "Wusterhausen/Dosse",
            "l": "Brandenburg"
        }
    ],
    "16909": [
        {
            "o": "Heiligengrabe",
            "l": "Brandenburg"
        },
        {
            "o": "Wittstock/Dosse",
            "l": "Brandenburg"
        }
    ],
    "16928": [
        {
            "o": "Gerdshagen",
            "l": "Brandenburg"
        },
        {
            "o": "Groß Pankow (Prignitz)",
            "l": "Brandenburg"
        },
        {
            "o": "Pritzwalk",
            "l": "Brandenburg"
        }
    ],
    "16945": [
        {
            "o": "Halenbeck-Rohlsdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Kümmernitztal",
            "l": "Brandenburg"
        },
        {
            "o": "Marienfließ",
            "l": "Brandenburg"
        },
        {
            "o": "Meyenburg",
            "l": "Brandenburg"
        }
    ],
    "16949": [
        {
            "o": "Putlitz",
            "l": "Brandenburg"
        },
        {
            "o": "Triglitz",
            "l": "Brandenburg"
        }
    ],
    "17033": [
        {
            "o": "Neubrandenburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17034": [
        {
            "o": "Neubrandenburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17036": [
        {
            "o": "Neubrandenburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17039": [
        {
            "o": "Beseritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Blankenhof",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Brunn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neddemin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neuenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neverin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sponholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Staven",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Trollenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Woggersin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wulkenzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zirzow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17087": [
        {
            "o": "Altentreptow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17089": [
        {
            "o": "Bartow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Breest",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Burow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gnevkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Golchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grapzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grischow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gültz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Siedenbollentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Werder",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17091": [
        {
            "o": "Altenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Breesen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Teetzleben",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Knorrendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kriesow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mölln",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pripsleben",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Röckwitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rosenow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tützpatz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wildberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wolde",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17094": [
        {
            "o": "Burg Stargard",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Cölpin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Nemerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Holldorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pragsdorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17098": [
        {
            "o": "Friedland",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17099": [
        {
            "o": "Datzetal",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Galenbeck",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17109": [
        {
            "o": "Demmin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17111": [
        {
            "o": "Beggerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Borrentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenbollentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenmocker",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kentzlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kletzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lindenberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Meesiger",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nossendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sarow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schönfeld",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Siedenbrünzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sommersdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Utzedel",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Verchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warrenzin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17121": [
        {
            "o": "Görmin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Loitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sassen-Trantow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17126": [
        {
            "o": "Jarmen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17129": [
        {
            "o": "Alt Tellin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bentzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Daberkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kruckow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tutow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Völschow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17139": [
        {
            "o": "Basedow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Faulenrost",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gielow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kummerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Malchin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17153": [
        {
            "o": "Bredenfelde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Briggow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grammentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gülzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ivenack",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jürgenstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kittendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ritzerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stavenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zettemin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17154": [
        {
            "o": "Neukalen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17159": [
        {
            "o": "Dargun",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17166": [
        {
            "o": "Alt Sührkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dahmen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dalkendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Roge",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Wokern",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohen Demzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schorssow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Teterow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17168": [
        {
            "o": "Groß Wüstenfelde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jördenstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lelkendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Prebberede",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schwasdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sukow-Levitzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Thürkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warnkenhagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17179": [
        {
            "o": "Altkalen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Behren-Lübchin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Finkenthal",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gnoien",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Walkendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17192": [
        {
            "o": "Groß Plasten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kargow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klink",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Peenehagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schloen-Dratow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Torgelow am See",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Waren (Müritz)",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17194": [
        {
            "o": "Grabowhöfe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohen Wangelin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jabel",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klocksin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Moltzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Peenehagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Vollrathsruhe",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17207": [
        {
            "o": "Bollewick",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gotthun",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Kelle",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Röbel/Müritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Südmüritz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17209": [
        {
            "o": "Altenhof",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Buchholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bütow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Eldetal",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Fincken",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kieve",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Leizen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Melz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Priborn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sietow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stuer",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Südmüritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Walow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zislow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17213": [
        {
            "o": "Fünfseen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Göhren-Lebbin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Malchow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Penkow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17214": [
        {
            "o": "Alt Schwerin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nossentiner Hütte",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Silz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17217": [
        {
            "o": "Kuckssee",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Penzlin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17219": [
        {
            "o": "Ankershagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Möllenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Penzlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schloen-Dratow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17235": [
        {
            "o": "Neustrelitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17237": [
        {
            "o": "Blankensee",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Blumenholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Carpin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Godendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grünow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenzieritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Vielen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kratzeburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Möllenbeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Userin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wokuhl-Dabelow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17248": [
        {
            "o": "Lärz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rechlin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17252": [
        {
            "o": "Mirow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schwarz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17255": [
        {
            "o": "Priepert",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wesenberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wustrow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17258": [
        {
            "o": "Feldberger Seenlandschaft",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17268": [
        {
            "o": "Boitzenburger Land",
            "l": "Brandenburg"
        },
        {
            "o": "Flieth-Stegelitz",
            "l": "Brandenburg"
        },
        {
            "o": "Gerswalde",
            "l": "Brandenburg"
        },
        {
            "o": "Milmersdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Mittenwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Temmen-Ringenwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Templin",
            "l": "Brandenburg"
        }
    ],
    "17279": [
        {
            "o": "Lychen",
            "l": "Brandenburg"
        }
    ],
    "17291": [
        {
            "o": "Carmzow-Wallmow",
            "l": "Brandenburg"
        },
        {
            "o": "Göritz",
            "l": "Brandenburg"
        },
        {
            "o": "Gramzow",
            "l": "Brandenburg"
        },
        {
            "o": "Grünow",
            "l": "Brandenburg"
        },
        {
            "o": "Nordwestuckermark",
            "l": "Brandenburg"
        },
        {
            "o": "Oberuckersee",
            "l": "Brandenburg"
        },
        {
            "o": "Prenzlau",
            "l": "Brandenburg"
        },
        {
            "o": "Randowtal",
            "l": "Brandenburg"
        },
        {
            "o": "Schenkenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Schönfeld",
            "l": "Brandenburg"
        },
        {
            "o": "Uckerfelde",
            "l": "Brandenburg"
        }
    ],
    "17309": [
        {
            "o": "Brietzig",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Fahrenwalde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jatznick",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Koblentz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krugsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nieden",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Papendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pasewalk",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Polzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rollwitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schönwalde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Viereck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zerrenthin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17321": [
        {
            "o": "Bergholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Löcknitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Plöwen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ramin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rothenklempenow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17322": [
        {
            "o": "Blankensee",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Boock",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Glasow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grambow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krackow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rossow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rothenklempenow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17326": [
        {
            "o": "Brüssow",
            "l": "Brandenburg"
        }
    ],
    "17328": [
        {
            "o": "Krackow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Penkun",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17329": [
        {
            "o": "Krackow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nadrensee",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17335": [
        {
            "o": "Strasburg (Uckermark)",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17337": [
        {
            "o": "Groß Luckow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schönhausen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Uckerland",
            "l": "Brandenburg"
        }
    ],
    "17348": [
        {
            "o": "Woldegk",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17349": [
        {
            "o": "Groß Miltzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kublank",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lindetal",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neetzka",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schönbeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Voigtsdorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17358": [
        {
            "o": "Hammer a.d. Uecker",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Torgelow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17367": [
        {
            "o": "Eggesin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17373": [
        {
            "o": "Ueckermünde",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17375": [
        {
            "o": "Ahlbeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Altwarp",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Eggesin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grambin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hintersee",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Leopoldshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Liepgarten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Luckow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Meiersberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mönkebude",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Vogelsang-Warsin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17379": [
        {
            "o": "Altwigshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ferdinandshof",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Heinrichswalde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lübs",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rothemühl",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wilhelmsburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17389": [
        {
            "o": "Anklam",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17390": [
        {
            "o": "Groß Polzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Bünzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Murchin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rubkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schmatzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ziethen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17391": [
        {
            "o": "Iven",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krien",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krusenfelde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Medow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neetzow-Liepen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Postlow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Spantekow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stolpe an der Peene",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17392": [
        {
            "o": "Blesewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Boldekow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Butzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neuenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sarnow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Spantekow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17398": [
        {
            "o": "Bargischow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bugewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ducherow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neu Kosenow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rossin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17406": [
        {
            "o": "Rankwitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stolpe auf Usedom",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Usedom",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17419": [
        {
            "o": "Dargen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Garz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Heringsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kamminke",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Korswandt",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zirchow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17424": [
        {
            "o": "Heringsdorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17429": [
        {
            "o": "Benz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dargen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Heringsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mellenthin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pudagla",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17438": [
        {
            "o": "Wolgast",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17440": [
        {
            "o": "Buggenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kröslin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krummin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lassan",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lütow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sauzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zemitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17449": [
        {
            "o": "Karlshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mölschow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Peenemünde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Trassenheide",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17454": [
        {
            "o": "Zinnowitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17459": [
        {
            "o": "Koserow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Loddin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ückeritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zempin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17489": [
        {
            "o": "Greifswald",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17491": [
        {
            "o": "Greifswald",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17493": [
        {
            "o": "Greifswald",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17495": [
        {
            "o": "Groß Kiesow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Karlsburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wrangelsburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Züssow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17498": [
        {
            "o": "Behrenhoff",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dargelin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dersekow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hinrichshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Levenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mesekenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neuenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wackerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Weitenhagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17506": [
        {
            "o": "Bandelin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gribow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gützkow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "17509": [
        {
            "o": "Brünzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hanshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Katzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kemnitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Loissin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lubmin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neu Boltenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rubenow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wusterhusen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18055": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18057": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18059": [
        {
            "o": "Papendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pölchow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ziesendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18069": [
        {
            "o": "Lambrechtshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18106": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18107": [
        {
            "o": "Elmenhorst/Lichtenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18109": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18119": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18146": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18147": [
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18181": [
        {
            "o": "Graal-Müritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18182": [
        {
            "o": "Bentwisch",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Blankenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gelbensande",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mönchhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rostock",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rövershagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18184": [
        {
            "o": "Broderstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Poppendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Roggentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Thulendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18190": [
        {
            "o": "Sanitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18195": [
        {
            "o": "Cammin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gnewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grammow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nustrow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Selpin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stubbendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tessin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Thelkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zarnewanz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18196": [
        {
            "o": "Dummerstorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18198": [
        {
            "o": "Kritzmow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stäbelow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18209": [
        {
            "o": "Bad Doberan",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bartenshagen-Parkentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenfelde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Reddelich",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Steffenshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wittenbeck",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18211": [
        {
            "o": "Admannshagen-Bargeshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Börgerende-Rethwisch",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenfelde",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nienhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Retschow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18225": [
        {
            "o": "Kühlungsborn",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18230": [
        {
            "o": "Bastorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Biendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rerik",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18233": [
        {
            "o": "Alt Bukow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Am Salzhaff",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Carinerland",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neubukow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18236": [
        {
            "o": "Carinerland",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kröpelin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18239": [
        {
            "o": "Satow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18246": [
        {
            "o": "Baumgarten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bernitt",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bützow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jürgenshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Belitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rühn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Steinhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zepelin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18249": [
        {
            "o": "Baumgarten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bernitt",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dreetz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Penzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tarnow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warnow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18258": [
        {
            "o": "Benitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bröbberow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kassow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rukieten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schwaan",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Vorbeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wiendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18273": [
        {
            "o": "Güstrow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18276": [
        {
            "o": "Glasewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Schwiesow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gülzow-Prüzen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gutow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Upahl",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kuhs",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lohmen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lüssow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mistorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mühl Rosin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Plaaz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Reimershagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sarmstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zehna",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18279": [
        {
            "o": "Lalendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18292": [
        {
            "o": "Dobbin-Linstow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hoppenrade",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krakow am See",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kuchelmiß",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18299": [
        {
            "o": "Dolgen am See",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohen Sprenz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Laage",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wardow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18311": [
        {
            "o": "Ribnitz-Damgarten",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18314": [
        {
            "o": "Divitz-Spoldershagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kenz-Küstrow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Löbnitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lüdershagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18317": [
        {
            "o": "Saal",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18320": [
        {
            "o": "Ahrenshagen-Daskow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schlemmin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Trinwillershagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18334": [
        {
            "o": "Bad Sülze",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dettmannsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Eixen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lindholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Semlow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18337": [
        {
            "o": "Marlow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18347": [
        {
            "o": "Ahrenshoop",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dierhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wustrow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18356": [
        {
            "o": "Barth",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Fuhlendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pruchten",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18374": [
        {
            "o": "Zingst",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18375": [
        {
            "o": "Born a. Darß",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Prerow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wieck a. Darß",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18435": [
        {
            "o": "Stralsund",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18437": [
        {
            "o": "Stralsund",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18439": [
        {
            "o": "Stralsund",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18442": [
        {
            "o": "Groß Kordshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jakobsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lüssow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Niepars",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pantelitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Steinhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18445": [
        {
            "o": "Altenpleen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Mohrdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klausdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kramerhof",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Preetz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Prohn",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18461": [
        {
            "o": "Franzburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gremersdorf-Buchholz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Millienhagen-Oebelitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Richtenberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Weitenhagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18465": [
        {
            "o": "Drechow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hugoldsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tribsees",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18469": [
        {
            "o": "Karnin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Velgast",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18507": [
        {
            "o": "Grimmen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18510": [
        {
            "o": "Elmenhorst",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Papenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wittenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zarrendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18513": [
        {
            "o": "Deyelsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Glewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grammendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gransebieth",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Splietsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wendisch Baggendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18516": [
        {
            "o": "Süderholz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18519": [
        {
            "o": "Sundhagen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18528": [
        {
            "o": "Bergen auf Rügen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Buschvitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lietzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Parchtitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Patzig",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ralswiek",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rappin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sehlen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zirkow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18546": [
        {
            "o": "Sassnitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18551": [
        {
            "o": "Glowe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lohme",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sagard",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18556": [
        {
            "o": "Altenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Breege",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dranske",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Putgarten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wiek",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18565": [
        {
            "o": "Insel Hiddensee",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18569": [
        {
            "o": "Gingst",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kluis",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neuenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schaprode",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Trent",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ummanz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18573": [
        {
            "o": "Altefähr",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dreschvitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rambin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Samtens",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18574": [
        {
            "o": "Garz/Rügen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gustow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Poseritz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18581": [
        {
            "o": "Putbus",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18586": [
        {
            "o": "Baabe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Göhren",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lancken-Granitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mönchgut",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sellin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "18609": [
        {
            "o": "Binz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19053": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19055": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19057": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19059": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19061": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19063": [
        {
            "o": "Schwerin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19065": [
        {
            "o": "Gneven",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pinnow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Raben Steinfeld",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19067": [
        {
            "o": "Cambs",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dobin am See",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Langen Brütz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Leezen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19069": [
        {
            "o": "Alt Meteln",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Trebbow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lübstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pingelshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Seehof",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zickhusen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19071": [
        {
            "o": "Brüsewitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Cramonshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dalberg-Wendelstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grambow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19073": [
        {
            "o": "Dümmer",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grambow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klein Rogahn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schossin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stralendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wittenförden",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zülow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19075": [
        {
            "o": "Holthusen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pampow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schossin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warsow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19077": [
        {
            "o": "Lübesse",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rastow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sülstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Uelitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19079": [
        {
            "o": "Banzkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sukow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19086": [
        {
            "o": "Plate",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19089": [
        {
            "o": "Barnin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bülow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Crivitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Demen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Friedrichsruhe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tramm",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zapel",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19205": [
        {
            "o": "Dragun",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gadebusch",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Holdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kneese",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krembz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mühlen Eichsen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pokrent",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Roggendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rögnitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Veelböken",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19209": [
        {
            "o": "Gottesgabe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lützow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Perlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schildetal",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19217": [
        {
            "o": "Carlow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dechow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Molzahn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Holdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Königsfeld",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rehna",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rieps",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schlagsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Thandorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Utecht",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wedendorfersee",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19230": [
        {
            "o": "Alt Zachun",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bandenitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Belsch",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bobzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bresegard bei Picher",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gammelin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Krams",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hagenow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hoort",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hülseburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kirch Jesar",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kuhstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Moraas",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pätow-Steegen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Picher",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Pritzier",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Redefin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Strohkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Toddin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warlitz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19243": [
        {
            "o": "Wittenburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wittendörp",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19246": [
        {
            "o": "Kogel",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lüttow-Valluhn",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zarrentin am Schaalsee",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19249": [
        {
            "o": "Lübtheen",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19258": [
        {
            "o": "Bengerstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Besitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Boizenburg/Elbe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gallin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gresse",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Greven",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neu Gülze",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Nostorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schwanheide",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Tessin b. Boizenburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19260": [
        {
            "o": "Dersenow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Vellahn",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19273": [
        {
            "o": "Amt Neuhaus",
            "l": "Niedersachsen"
        },
        {
            "o": "Bleckede",
            "l": "Niedersachsen"
        },
        {
            "o": "Brahlstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Teldau",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19288": [
        {
            "o": "Alt Krenzlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Göhlen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Laasch",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lüblow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ludwigslust",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warlow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wöbbelin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19294": [
        {
            "o": "Bresegard bei Eldena",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Eldena",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gorlosen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grebs-Niendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Karenz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Karstädt",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Malk Göhren",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Malliß",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Milow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neu Kaliß",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19300": [
        {
            "o": "Balow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grabow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kremmin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Milow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Möllenbeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Muchow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Prislich",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zierzow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19303": [
        {
            "o": "Dömitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Vielank",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19306": [
        {
            "o": "Blievenstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Brenz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neustadt-Glewe",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19309": [
        {
            "o": "Lanz",
            "l": "Brandenburg"
        },
        {
            "o": "Lenzen (Elbe)",
            "l": "Brandenburg"
        },
        {
            "o": "Lenzerwische",
            "l": "Brandenburg"
        }
    ],
    "19322": [
        {
            "o": "Breese",
            "l": "Brandenburg"
        },
        {
            "o": "Cumlosen",
            "l": "Brandenburg"
        },
        {
            "o": "Rühstädt",
            "l": "Brandenburg"
        },
        {
            "o": "Weisen",
            "l": "Brandenburg"
        },
        {
            "o": "Wittenberge",
            "l": "Brandenburg"
        }
    ],
    "19336": [
        {
            "o": "Bad Wilsnack",
            "l": "Brandenburg"
        },
        {
            "o": "Legde/Quitzöbel",
            "l": "Brandenburg"
        }
    ],
    "19339": [
        {
            "o": "Plattenburg",
            "l": "Brandenburg"
        }
    ],
    "19348": [
        {
            "o": "Berge",
            "l": "Brandenburg"
        },
        {
            "o": "Gülitz-Reetz",
            "l": "Brandenburg"
        },
        {
            "o": "Perleberg",
            "l": "Brandenburg"
        },
        {
            "o": "Pirow",
            "l": "Brandenburg"
        }
    ],
    "19357": [
        {
            "o": "Brunow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dambeck",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Karstädt",
            "l": "Brandenburg"
        }
    ],
    "19370": [
        {
            "o": "Parchim",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19372": [
        {
            "o": "Brunow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Godems",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Karrenzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lewitzrand",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rom",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Spornitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stolpe",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ziegendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19374": [
        {
            "o": "Domsühl",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lewitzrand",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mestlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Obere Warnow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Parchim",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zölkow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19376": [
        {
            "o": "Ruhner Berge",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Siggelkow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19386": [
        {
            "o": "Gallin-Kuppentin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gehlsbach",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Granzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kreien",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kritzow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lübz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Passow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Werder",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19395": [
        {
            "o": "Barkhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ganzlin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Plau am See",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19399": [
        {
            "o": "Dobbertin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Goldberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neu Poserin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Techentin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19406": [
        {
            "o": "Borkow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Dabel",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohen Pritz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kobrow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Mustin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Sternberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Witzin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19412": [
        {
            "o": "Blankenberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Brüel",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kloster Tempzin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kuhlen-Wendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Weitendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "19417": [
        {
            "o": "Bibow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Jesendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Ventschow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warin",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "20095": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20097": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20099": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20144": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20146": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20148": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20149": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20249": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20251": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20253": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20255": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20257": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20259": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20354": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20355": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20357": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20359": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20457": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20459": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20535": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20537": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "20539": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21029": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21031": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21033": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21035": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21037": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21039": [
        {
            "o": "Börnsen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Escheburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamburg",
            "l": "Hamburg"
        },
        {
            "o": "Kröppelshagen-Fahrendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "21073": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21075": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21077": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21079": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21107": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21109": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21129": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21147": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21149": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "21217": [
        {
            "o": "Seevetal",
            "l": "Niedersachsen"
        }
    ],
    "21218": [
        {
            "o": "Seevetal",
            "l": "Niedersachsen"
        }
    ],
    "21220": [
        {
            "o": "Seevetal",
            "l": "Niedersachsen"
        }
    ],
    "21224": [
        {
            "o": "Rosengarten",
            "l": "Niedersachsen"
        }
    ],
    "21227": [
        {
            "o": "Bendestorf",
            "l": "Niedersachsen"
        }
    ],
    "21228": [
        {
            "o": "Harmstorf",
            "l": "Niedersachsen"
        }
    ],
    "21244": [
        {
            "o": "Buchholz in der Nordheide",
            "l": "Niedersachsen"
        }
    ],
    "21255": [
        {
            "o": "Dohren",
            "l": "Niedersachsen"
        },
        {
            "o": "Drestedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Kakenstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Königsmoor",
            "l": "Niedersachsen"
        },
        {
            "o": "Tostedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Wistedt",
            "l": "Niedersachsen"
        }
    ],
    "21256": [
        {
            "o": "Handeloh",
            "l": "Niedersachsen"
        }
    ],
    "21258": [
        {
            "o": "Heidenau",
            "l": "Niedersachsen"
        }
    ],
    "21259": [
        {
            "o": "Otter",
            "l": "Niedersachsen"
        }
    ],
    "21261": [
        {
            "o": "Welle",
            "l": "Niedersachsen"
        }
    ],
    "21266": [
        {
            "o": "Jesteburg",
            "l": "Niedersachsen"
        }
    ],
    "21271": [
        {
            "o": "Asendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hanstedt",
            "l": "Niedersachsen"
        }
    ],
    "21272": [
        {
            "o": "Egestorf",
            "l": "Niedersachsen"
        }
    ],
    "21274": [
        {
            "o": "Undeloh",
            "l": "Niedersachsen"
        }
    ],
    "21279": [
        {
            "o": "Appel",
            "l": "Niedersachsen"
        },
        {
            "o": "Drestedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Hollenstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Wenzendorf",
            "l": "Niedersachsen"
        }
    ],
    "21335": [
        {
            "o": "Lüneburg",
            "l": "Niedersachsen"
        }
    ],
    "21337": [
        {
            "o": "Lüneburg",
            "l": "Niedersachsen"
        }
    ],
    "21339": [
        {
            "o": "Lüneburg",
            "l": "Niedersachsen"
        }
    ],
    "21354": [
        {
            "o": "Bleckede",
            "l": "Niedersachsen"
        }
    ],
    "21357": [
        {
            "o": "Bardowick",
            "l": "Niedersachsen"
        },
        {
            "o": "Barum",
            "l": "Niedersachsen"
        },
        {
            "o": "Wittorf",
            "l": "Niedersachsen"
        }
    ],
    "21358": [
        {
            "o": "Mechtersen",
            "l": "Niedersachsen"
        }
    ],
    "21360": [
        {
            "o": "Vögelsen",
            "l": "Niedersachsen"
        }
    ],
    "21365": [
        {
            "o": "Adendorf",
            "l": "Niedersachsen"
        }
    ],
    "21368": [
        {
            "o": "Boitze",
            "l": "Niedersachsen"
        },
        {
            "o": "Dahlem",
            "l": "Niedersachsen"
        },
        {
            "o": "Dahlenburg",
            "l": "Niedersachsen"
        }
    ],
    "21369": [
        {
            "o": "Nahrendorf",
            "l": "Niedersachsen"
        }
    ],
    "21371": [
        {
            "o": "Tosterglope",
            "l": "Niedersachsen"
        }
    ],
    "21376": [
        {
            "o": "Eyendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Garlstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Gödenstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Salzhausen",
            "l": "Niedersachsen"
        }
    ],
    "21379": [
        {
            "o": "Echem",
            "l": "Niedersachsen"
        },
        {
            "o": "Lüdersburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Rullstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Scharnebeck",
            "l": "Niedersachsen"
        }
    ],
    "21380": [
        {
            "o": "Artlenburg",
            "l": "Niedersachsen"
        }
    ],
    "21382": [
        {
            "o": "Brietlingen",
            "l": "Niedersachsen"
        }
    ],
    "21385": [
        {
            "o": "Amelinghausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Oldendorf (Luhe)",
            "l": "Niedersachsen"
        },
        {
            "o": "Rehlingen",
            "l": "Niedersachsen"
        }
    ],
    "21386": [
        {
            "o": "Betzendorf",
            "l": "Niedersachsen"
        }
    ],
    "21388": [
        {
            "o": "Soderstorf",
            "l": "Niedersachsen"
        }
    ],
    "21391": [
        {
            "o": "Lüneburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Reppenstedt",
            "l": "Niedersachsen"
        }
    ],
    "21394": [
        {
            "o": "Kirchgellersen",
            "l": "Niedersachsen"
        },
        {
            "o": "Südergellersen",
            "l": "Niedersachsen"
        },
        {
            "o": "Westergellersen",
            "l": "Niedersachsen"
        }
    ],
    "21395": [
        {
            "o": "Tespe",
            "l": "Niedersachsen"
        }
    ],
    "21397": [
        {
            "o": "Barendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Vastorf",
            "l": "Niedersachsen"
        }
    ],
    "21398": [
        {
            "o": "Neetze",
            "l": "Niedersachsen"
        }
    ],
    "21400": [
        {
            "o": "Reinstorf",
            "l": "Niedersachsen"
        }
    ],
    "21401": [
        {
            "o": "Thomasburg",
            "l": "Niedersachsen"
        }
    ],
    "21403": [
        {
            "o": "Wendisch Evern",
            "l": "Niedersachsen"
        }
    ],
    "21406": [
        {
            "o": "Barnstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Melbeck",
            "l": "Niedersachsen"
        }
    ],
    "21407": [
        {
            "o": "Deutsch Evern",
            "l": "Niedersachsen"
        }
    ],
    "21409": [
        {
            "o": "Embsen",
            "l": "Niedersachsen"
        }
    ],
    "21423": [
        {
            "o": "Drage",
            "l": "Niedersachsen"
        },
        {
            "o": "Winsen (Luhe)",
            "l": "Niedersachsen"
        }
    ],
    "21435": [
        {
            "o": "Stelle",
            "l": "Niedersachsen"
        }
    ],
    "21436": [
        {
            "o": "Marschacht",
            "l": "Niedersachsen"
        }
    ],
    "21438": [
        {
            "o": "Brackel",
            "l": "Niedersachsen"
        }
    ],
    "21439": [
        {
            "o": "Marxen",
            "l": "Niedersachsen"
        }
    ],
    "21441": [
        {
            "o": "Garstedt",
            "l": "Niedersachsen"
        }
    ],
    "21442": [
        {
            "o": "Toppenstedt",
            "l": "Niedersachsen"
        }
    ],
    "21444": [
        {
            "o": "Vierhöfen",
            "l": "Niedersachsen"
        }
    ],
    "21445": [
        {
            "o": "Wulfsen",
            "l": "Niedersachsen"
        }
    ],
    "21447": [
        {
            "o": "Handorf",
            "l": "Niedersachsen"
        }
    ],
    "21449": [
        {
            "o": "Radbruch",
            "l": "Niedersachsen"
        }
    ],
    "21465": [
        {
            "o": "Reinbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wentorf bei Hamburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "21481": [
        {
            "o": "Buchhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lauenburg/Elbe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schnakenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "21483": [
        {
            "o": "Basedow",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dalldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gülzow",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Juliusburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krukow",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krüzen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lanze",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lütau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wangelau",
            "l": "Schleswig-Holstein"
        }
    ],
    "21493": [
        {
            "o": "Basthorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Elmenhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fuhlenhagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grabau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Pampau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grove",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Havekost",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Möhnsen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mühlenrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sahms",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schretstaken",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwarzenbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Talkau",
            "l": "Schleswig-Holstein"
        }
    ],
    "21502": [
        {
            "o": "Geesthacht",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamwarde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wiershop",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Worth",
            "l": "Schleswig-Holstein"
        }
    ],
    "21509": [
        {
            "o": "Glinde",
            "l": "Schleswig-Holstein"
        }
    ],
    "21514": [
        {
            "o": "Bröthen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Büchen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fitzen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Göttin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Güster",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hornbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kankelau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Pampau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Langenlehsten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Roseburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Siebeneichen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Witzeeze",
            "l": "Schleswig-Holstein"
        }
    ],
    "21516": [
        {
            "o": "Müssen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schulendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tramm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Woltersdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "21521": [
        {
            "o": "Aumühle",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dassendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reinbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wohltorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "21522": [
        {
            "o": "Hittbergen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hohnstorf (Elbe)",
            "l": "Niedersachsen"
        }
    ],
    "21524": [
        {
            "o": "Brunstorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "21526": [
        {
            "o": "Hohenhorn",
            "l": "Schleswig-Holstein"
        }
    ],
    "21527": [
        {
            "o": "Kollow",
            "l": "Schleswig-Holstein"
        }
    ],
    "21529": [
        {
            "o": "Kröppelshagen-Fahrendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "21614": [
        {
            "o": "Buxtehude",
            "l": "Niedersachsen"
        }
    ],
    "21629": [
        {
            "o": "Neu Wulmstorf",
            "l": "Niedersachsen"
        }
    ],
    "21635": [
        {
            "o": "Jork",
            "l": "Niedersachsen"
        }
    ],
    "21640": [
        {
            "o": "Bliedersdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Horneburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuenkirchen",
            "l": "Niedersachsen"
        },
        {
            "o": "Nottensdorf",
            "l": "Niedersachsen"
        }
    ],
    "21641": [
        {
            "o": "Apensen",
            "l": "Niedersachsen"
        }
    ],
    "21643": [
        {
            "o": "Beckdorf",
            "l": "Niedersachsen"
        }
    ],
    "21644": [
        {
            "o": "Sauensiek",
            "l": "Niedersachsen"
        }
    ],
    "21646": [
        {
            "o": "Halvesbostel",
            "l": "Niedersachsen"
        }
    ],
    "21647": [
        {
            "o": "Moisburg",
            "l": "Niedersachsen"
        }
    ],
    "21649": [
        {
            "o": "Regesbostel",
            "l": "Niedersachsen"
        }
    ],
    "21680": [
        {
            "o": "Stade",
            "l": "Niedersachsen"
        }
    ],
    "21682": [
        {
            "o": "Stade",
            "l": "Niedersachsen"
        }
    ],
    "21683": [
        {
            "o": "Stade",
            "l": "Niedersachsen"
        }
    ],
    "21684": [
        {
            "o": "Agathenburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Stade",
            "l": "Niedersachsen"
        }
    ],
    "21698": [
        {
            "o": "Bargstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Brest",
            "l": "Niedersachsen"
        },
        {
            "o": "Harsefeld",
            "l": "Niedersachsen"
        }
    ],
    "21702": [
        {
            "o": "Ahlerstedt",
            "l": "Niedersachsen"
        }
    ],
    "21706": [
        {
            "o": "Drochtersen",
            "l": "Niedersachsen"
        }
    ],
    "21709": [
        {
            "o": "Burweg",
            "l": "Niedersachsen"
        },
        {
            "o": "Düdenbüttel",
            "l": "Niedersachsen"
        },
        {
            "o": "Himmelpforten",
            "l": "Niedersachsen"
        }
    ],
    "21710": [
        {
            "o": "Engelschoff",
            "l": "Niedersachsen"
        }
    ],
    "21712": [
        {
            "o": "Großenwörden",
            "l": "Niedersachsen"
        }
    ],
    "21714": [
        {
            "o": "Hammah",
            "l": "Niedersachsen"
        }
    ],
    "21717": [
        {
            "o": "Deinste",
            "l": "Niedersachsen"
        },
        {
            "o": "Fredenbeck",
            "l": "Niedersachsen"
        }
    ],
    "21720": [
        {
            "o": "Grünendeich",
            "l": "Niedersachsen"
        },
        {
            "o": "Guderhandviertel",
            "l": "Niedersachsen"
        },
        {
            "o": "Mittelnkirchen",
            "l": "Niedersachsen"
        },
        {
            "o": "Steinkirchen",
            "l": "Niedersachsen"
        }
    ],
    "21723": [
        {
            "o": "Hollern-Twielenfleth",
            "l": "Niedersachsen"
        }
    ],
    "21726": [
        {
            "o": "Heinbockel",
            "l": "Niedersachsen"
        },
        {
            "o": "Kranenburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Oldendorf",
            "l": "Niedersachsen"
        }
    ],
    "21727": [
        {
            "o": "Estorf",
            "l": "Niedersachsen"
        }
    ],
    "21729": [
        {
            "o": "Freiburg (Elbe)",
            "l": "Niedersachsen"
        }
    ],
    "21730": [
        {
            "o": "Balje",
            "l": "Niedersachsen"
        }
    ],
    "21732": [
        {
            "o": "Krummendeich",
            "l": "Niedersachsen"
        }
    ],
    "21734": [
        {
            "o": "Oederquart",
            "l": "Niedersachsen"
        }
    ],
    "21737": [
        {
            "o": "Wischhafen",
            "l": "Niedersachsen"
        }
    ],
    "21739": [
        {
            "o": "Dollern",
            "l": "Niedersachsen"
        }
    ],
    "21745": [
        {
            "o": "Hemmoor",
            "l": "Niedersachsen"
        }
    ],
    "21755": [
        {
            "o": "Hechthausen",
            "l": "Niedersachsen"
        }
    ],
    "21756": [
        {
            "o": "Osten",
            "l": "Niedersachsen"
        }
    ],
    "21762": [
        {
            "o": "Osterbruch",
            "l": "Niedersachsen"
        },
        {
            "o": "Otterndorf",
            "l": "Niedersachsen"
        }
    ],
    "21763": [
        {
            "o": "Neuenkirchen",
            "l": "Niedersachsen"
        }
    ],
    "21765": [
        {
            "o": "Nordleda",
            "l": "Niedersachsen"
        }
    ],
    "21769": [
        {
            "o": "Armstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hollnseth",
            "l": "Niedersachsen"
        },
        {
            "o": "Lamstedt",
            "l": "Niedersachsen"
        }
    ],
    "21770": [
        {
            "o": "Mittelstenahe",
            "l": "Niedersachsen"
        }
    ],
    "21772": [
        {
            "o": "Stinstedt",
            "l": "Niedersachsen"
        }
    ],
    "21775": [
        {
            "o": "Ihlienworth",
            "l": "Niedersachsen"
        },
        {
            "o": "Odisheim",
            "l": "Niedersachsen"
        },
        {
            "o": "Steinau",
            "l": "Niedersachsen"
        }
    ],
    "21776": [
        {
            "o": "Wanna",
            "l": "Niedersachsen"
        }
    ],
    "21781": [
        {
            "o": "Cadenberge",
            "l": "Niedersachsen"
        }
    ],
    "21782": [
        {
            "o": "Bülkau",
            "l": "Niedersachsen"
        }
    ],
    "21785": [
        {
            "o": "Belum",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuhaus",
            "l": "Niedersachsen"
        }
    ],
    "21787": [
        {
            "o": "Oberndorf",
            "l": "Niedersachsen"
        }
    ],
    "21789": [
        {
            "o": "Wingst",
            "l": "Niedersachsen"
        }
    ],
    "22041": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22043": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22045": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22047": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22049": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22081": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22083": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22085": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22087": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22089": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22111": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22113": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        },
        {
            "o": "Oststeinbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "22115": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22117": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22119": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22143": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22145": [
        {
            "o": "Braak",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamburg",
            "l": "Hamburg"
        },
        {
            "o": "Stapelfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "22147": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22149": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22159": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22175": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22177": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22179": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22297": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22299": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22301": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22303": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22305": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22307": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22309": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22335": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22337": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22339": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22359": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22391": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22393": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22395": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22397": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22399": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22415": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22417": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22419": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22453": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22455": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22457": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22459": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22523": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22525": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22527": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22529": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22547": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22549": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22559": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22587": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22589": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22605": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22607": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22609": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22761": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22763": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22765": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22767": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22769": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "22844": [
        {
            "o": "Norderstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22846": [
        {
            "o": "Norderstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22848": [
        {
            "o": "Norderstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22850": [
        {
            "o": "Norderstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22851": [
        {
            "o": "Norderstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22869": [
        {
            "o": "Schenefeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "22880": [
        {
            "o": "Wedel",
            "l": "Schleswig-Holstein"
        }
    ],
    "22885": [
        {
            "o": "Barsbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "22889": [
        {
            "o": "Tangstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "22926": [
        {
            "o": "Ahrensburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "22927": [
        {
            "o": "Großhansdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "22929": [
        {
            "o": "Hamfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kasseburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Köthel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Köthel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rausdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schönberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "22941": [
        {
            "o": "Bargteheide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Delingsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hammoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jersbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "22946": [
        {
            "o": "Brunsbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dahmker",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grande",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Großensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hohenfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Trittau",
            "l": "Schleswig-Holstein"
        }
    ],
    "22949": [
        {
            "o": "Ammersbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "22952": [
        {
            "o": "Lütjensee",
            "l": "Schleswig-Holstein"
        }
    ],
    "22955": [
        {
            "o": "Hoisdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "22956": [
        {
            "o": "Grönwohld",
            "l": "Schleswig-Holstein"
        }
    ],
    "22958": [
        {
            "o": "Kuddewörde",
            "l": "Schleswig-Holstein"
        }
    ],
    "22959": [
        {
            "o": "Linau",
            "l": "Schleswig-Holstein"
        }
    ],
    "22961": [
        {
            "o": "Hoisdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "22962": [
        {
            "o": "Siek",
            "l": "Schleswig-Holstein"
        }
    ],
    "22964": [
        {
            "o": "Steinburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "22965": [
        {
            "o": "Todendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "22967": [
        {
            "o": "Tremsbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "22969": [
        {
            "o": "Witzhave",
            "l": "Schleswig-Holstein"
        }
    ],
    "23552": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23554": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23556": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23558": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23560": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23562": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23564": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23566": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23568": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23569": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23570": [
        {
            "o": "Lübeck",
            "l": "Schleswig-Holstein"
        }
    ],
    "23611": [
        {
            "o": "Bad Schwartau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ratekau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23617": [
        {
            "o": "Stockelsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23619": [
        {
            "o": "Badendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamberge",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heilshoop",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mönkhagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rehhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Zarpen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23623": [
        {
            "o": "Ahrensbök",
            "l": "Schleswig-Holstein"
        }
    ],
    "23626": [
        {
            "o": "Ratekau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23627": [
        {
            "o": "Groß Grönau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Sarau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23628": [
        {
            "o": "Klempau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krummesse",
            "l": "Schleswig-Holstein"
        }
    ],
    "23629": [
        {
            "o": "Scharbeutz",
            "l": "Schleswig-Holstein"
        }
    ],
    "23669": [
        {
            "o": "Ratekau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Timmendorfer Strand",
            "l": "Schleswig-Holstein"
        }
    ],
    "23683": [
        {
            "o": "Scharbeutz",
            "l": "Schleswig-Holstein"
        }
    ],
    "23684": [
        {
            "o": "Scharbeutz",
            "l": "Schleswig-Holstein"
        }
    ],
    "23689": [
        {
            "o": "Ratekau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23701": [
        {
            "o": "Eutin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süsel",
            "l": "Schleswig-Holstein"
        }
    ],
    "23714": [
        {
            "o": "Kirchnüchel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Malente",
            "l": "Schleswig-Holstein"
        }
    ],
    "23715": [
        {
            "o": "Bosau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23717": [
        {
            "o": "Kasseedorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23719": [
        {
            "o": "Glasau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23730": [
        {
            "o": "Altenkrempe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neustadt in Holstein",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schashagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sierksdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23738": [
        {
            "o": "Beschendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Damlos",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Harmsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kabelhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lensahn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Manhagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Riepsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23743": [
        {
            "o": "Grömitz",
            "l": "Schleswig-Holstein"
        }
    ],
    "23744": [
        {
            "o": "Schönwalde am Bungsberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "23746": [
        {
            "o": "Kellenhusen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23747": [
        {
            "o": "Dahme",
            "l": "Schleswig-Holstein"
        }
    ],
    "23749": [
        {
            "o": "Grube",
            "l": "Schleswig-Holstein"
        }
    ],
    "23758": [
        {
            "o": "Göhl",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gremersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldenburg in Holstein",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wangels",
            "l": "Schleswig-Holstein"
        }
    ],
    "23769": [
        {
            "o": "Fehmarn",
            "l": "Schleswig-Holstein"
        }
    ],
    "23774": [
        {
            "o": "Heiligenhafen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23775": [
        {
            "o": "Großenbrode",
            "l": "Schleswig-Holstein"
        }
    ],
    "23777": [
        {
            "o": "Heringsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23779": [
        {
            "o": "Neukirchen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23795": [
        {
            "o": "Bad Segeberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fahrenkrug",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Rönnau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Högersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Gladebrügge",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Rönnau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mözen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Negernbötel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schackendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schieren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwissel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stipsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Traventhal",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Weede",
            "l": "Schleswig-Holstein"
        }
    ],
    "23812": [
        {
            "o": "Wahlstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "23813": [
        {
            "o": "Blunk",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nehms",
            "l": "Schleswig-Holstein"
        }
    ],
    "23815": [
        {
            "o": "Geschendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Strukdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerrade",
            "l": "Schleswig-Holstein"
        }
    ],
    "23816": [
        {
            "o": "Bebensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Niendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Leezen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neversdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23818": [
        {
            "o": "Neuengörs",
            "l": "Schleswig-Holstein"
        }
    ],
    "23820": [
        {
            "o": "Pronstorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23821": [
        {
            "o": "Rohlstorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23823": [
        {
            "o": "Seedorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23824": [
        {
            "o": "Damsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tensfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "23826": [
        {
            "o": "Bark",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fredesdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Todesfelde",
            "l": "Schleswig-Holstein"
        }
    ],
    "23827": [
        {
            "o": "Krems II",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Travenhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wensin",
            "l": "Schleswig-Holstein"
        }
    ],
    "23829": [
        {
            "o": "Kükels",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wittenborn",
            "l": "Schleswig-Holstein"
        }
    ],
    "23843": [
        {
            "o": "Bad Oldesloe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neritz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rümpel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Travenbrück",
            "l": "Schleswig-Holstein"
        }
    ],
    "23845": [
        {
            "o": "Bahrenhof",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bühnsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dreggers",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grabau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Itzstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oering",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sülfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wakendorf I",
            "l": "Schleswig-Holstein"
        }
    ],
    "23847": [
        {
            "o": "Bliestorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Düchelsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grinau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Boden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kastorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lasbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meddewade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pölitz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rethwisch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schiphorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schürensöhlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Siebenbäumen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sierksrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Steinhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stubben",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23858": [
        {
            "o": "Barnitz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Feldhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heidekamp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reinfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wesenberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "23860": [
        {
            "o": "Groß Schenkenberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Wesenberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "23863": [
        {
            "o": "Bargfeld-Stegen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kayhude",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nienwohld",
            "l": "Schleswig-Holstein"
        }
    ],
    "23866": [
        {
            "o": "Nahe",
            "l": "Schleswig-Holstein"
        }
    ],
    "23867": [
        {
            "o": "Sülfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "23869": [
        {
            "o": "Elmenhorst",
            "l": "Schleswig-Holstein"
        }
    ],
    "23879": [
        {
            "o": "Mölln",
            "l": "Schleswig-Holstein"
        }
    ],
    "23881": [
        {
            "o": "Alt Mölln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bälau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Borstorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Breitenfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Koberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lankau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Niendorf/Stecknitz",
            "l": "Schleswig-Holstein"
        }
    ],
    "23883": [
        {
            "o": "Brunsmark",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grambek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hollenbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Horst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Zecher",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lehmrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Salem",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmilau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seedorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sterley",
            "l": "Schleswig-Holstein"
        }
    ],
    "23896": [
        {
            "o": "Nusse",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Panten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Poggensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ritzerau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Walksfelde",
            "l": "Schleswig-Holstein"
        }
    ],
    "23898": [
        {
            "o": "Duvensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klinkrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kühsen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Labenz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lüchow",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sandesneben",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sirksfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wentorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "23899": [
        {
            "o": "Besenthal",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gudow",
            "l": "Schleswig-Holstein"
        }
    ],
    "23909": [
        {
            "o": "Albsfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bäk",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fredeburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Giesensdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mechow",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ratzeburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Römnitz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmilau",
            "l": "Schleswig-Holstein"
        }
    ],
    "23911": [
        {
            "o": "Buchholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Einhaus",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Disnack",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Harmsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kittlitz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kulpin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mustin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pogeez",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Salem",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmilau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ziethen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23919": [
        {
            "o": "Behlendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Berkenthin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Göldenitz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Niendorf bei Berkenthin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rondeshagen",
            "l": "Schleswig-Holstein"
        }
    ],
    "23923": [
        {
            "o": "Lüdersdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Menzendorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Roduchelstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Schönberg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Selmsdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Siemz-Niendorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23936": [
        {
            "o": "Bernstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Damshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grevesmühlen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Grieben",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Roggenstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Rüting",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Stepenitztal",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Testorf-Steinfort",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Upahl",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warnow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23942": [
        {
            "o": "Dassow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kalkhorst",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23946": [
        {
            "o": "Boltenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klütz",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23948": [
        {
            "o": "Damshagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Kalkhorst",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Klütz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Warnow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23966": [
        {
            "o": "Bobitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wismar",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23968": [
        {
            "o": "Barnekow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Gägelow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohenkirchen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wismar",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zierow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23970": [
        {
            "o": "Benz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hornstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Wismar",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23972": [
        {
            "o": "Dorf Mecklenburg",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Groß Stieten",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lübow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Metelsdorf",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23974": [
        {
            "o": "Blowatz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Boiensdorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hornstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Krusenhagen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neuburg",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23992": [
        {
            "o": "Glasin",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Lübberstorf",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Neukloster",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Passee",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Zurow",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Züsow",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23996": [
        {
            "o": "Bad Kleinen",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Bobitz",
            "l": "Mecklenburg-Vorpommern"
        },
        {
            "o": "Hohen Viecheln",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "23999": [
        {
            "o": "Insel Poel",
            "l": "Mecklenburg-Vorpommern"
        }
    ],
    "24103": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24105": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24106": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24107": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ottendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Quarnbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24109": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Melsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24111": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24113": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Molfsee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24114": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24116": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24118": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24119": [
        {
            "o": "Kronshagen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24143": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24145": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24146": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24147": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24148": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24149": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24159": [
        {
            "o": "Kiel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24161": [
        {
            "o": "Altenholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Felm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuwittenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24211": [
        {
            "o": "Honigsee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kühren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lehmkuhlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pohnsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Postfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Preetz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rastorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schellhorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wahlstorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24214": [
        {
            "o": "Felm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gettorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lindau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neudorf-Bornstein",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuwittenbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Noer",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schinkel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tüttendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24217": [
        {
            "o": "Barsbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bendfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fiefbergen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Höhndorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krokau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krummbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schönberg (Holstein)",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stakendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wisch",
            "l": "Schleswig-Holstein"
        }
    ],
    "24220": [
        {
            "o": "Böhnhusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Boksee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Flintbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schönhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Techelsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24222": [
        {
            "o": "Schwentinental",
            "l": "Schleswig-Holstein"
        }
    ],
    "24223": [
        {
            "o": "Schwentinental",
            "l": "Schleswig-Holstein"
        }
    ],
    "24226": [
        {
            "o": "Heikendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24229": [
        {
            "o": "Dänischenhagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwedeneck",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Strande",
            "l": "Schleswig-Holstein"
        }
    ],
    "24232": [
        {
            "o": "Dobersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schönkirchen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24235": [
        {
            "o": "Brodersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Laboe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lutterbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stein",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wendtorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24238": [
        {
            "o": "Lammershagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Martensrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mucheln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Selent",
            "l": "Schleswig-Holstein"
        }
    ],
    "24239": [
        {
            "o": "Achterwehr",
            "l": "Schleswig-Holstein"
        }
    ],
    "24241": [
        {
            "o": "Blumenthal",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grevenkrug",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reesdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schierensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmalstede",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sören",
            "l": "Schleswig-Holstein"
        }
    ],
    "24242": [
        {
            "o": "Felde",
            "l": "Schleswig-Holstein"
        }
    ],
    "24244": [
        {
            "o": "Felm",
            "l": "Schleswig-Holstein"
        }
    ],
    "24245": [
        {
            "o": "Barmissen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Großbarkau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kirchbarkau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Barkau",
            "l": "Schleswig-Holstein"
        }
    ],
    "24247": [
        {
            "o": "Mielkendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rodenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24248": [
        {
            "o": "Mönkeberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24250": [
        {
            "o": "Bothkamp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Löptin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nettelsee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Warnau",
            "l": "Schleswig-Holstein"
        }
    ],
    "24251": [
        {
            "o": "Osdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24253": [
        {
            "o": "Fahren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Passade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Prasdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Probsteierhagen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24254": [
        {
            "o": "Rumohr",
            "l": "Schleswig-Holstein"
        }
    ],
    "24256": [
        {
            "o": "Fargau-Pratjau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schlesen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stoltenberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24257": [
        {
            "o": "Hohenfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Köhn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwartbuck",
            "l": "Schleswig-Holstein"
        }
    ],
    "24259": [
        {
            "o": "Westensee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24306": [
        {
            "o": "Bösdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lebrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Plön",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rathjensdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wittmoldt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24321": [
        {
            "o": "Behrensdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Giekau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Helmstorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hohwacht",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klamp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lütjenburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Panker",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rantzau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tröndel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24326": [
        {
            "o": "Ascheberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dersau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dörnick",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kalübbe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nehmten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stocksee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24327": [
        {
            "o": "Blekendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Högsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kletkamp",
            "l": "Schleswig-Holstein"
        }
    ],
    "24329": [
        {
            "o": "Dannau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grebin",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rantzau",
            "l": "Schleswig-Holstein"
        }
    ],
    "24340": [
        {
            "o": "Altenhof",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Eckernförde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gammelby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Goosefeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Windeby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24351": [
        {
            "o": "Damp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Thumby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24354": [
        {
            "o": "Kosel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rieseby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24357": [
        {
            "o": "Fleckeby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Güby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hummelfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24358": [
        {
            "o": "Ahlefeld-Bistensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ascheffel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hütten",
            "l": "Schleswig-Holstein"
        }
    ],
    "24360": [
        {
            "o": "Barkelsby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24361": [
        {
            "o": "Damendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Wittensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Haby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Holzbunge",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Wittensee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24363": [
        {
            "o": "Holtsee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24364": [
        {
            "o": "Holzdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24366": [
        {
            "o": "Loose",
            "l": "Schleswig-Holstein"
        }
    ],
    "24367": [
        {
            "o": "Osterby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24369": [
        {
            "o": "Waabs",
            "l": "Schleswig-Holstein"
        }
    ],
    "24376": [
        {
            "o": "Grödersby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hasselberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kappeln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rabel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24392": [
        {
            "o": "Boren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mohrkirch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Norderbrarup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nottfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Saustrup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Scheggerott",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderbrarup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wagersrott",
            "l": "Schleswig-Holstein"
        }
    ],
    "24395": [
        {
            "o": "Gelting",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kronsgaard",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nieby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Niesgrau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pommerby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rabenholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stangheck",
            "l": "Schleswig-Holstein"
        }
    ],
    "24398": [
        {
            "o": "Brodersby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dörphof",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Karby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Winnemark",
            "l": "Schleswig-Holstein"
        }
    ],
    "24399": [
        {
            "o": "Arnis",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grödersby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24401": [
        {
            "o": "Böel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24402": [
        {
            "o": "Esgrus",
            "l": "Schleswig-Holstein"
        }
    ],
    "24404": [
        {
            "o": "Kappeln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Maasholm",
            "l": "Schleswig-Holstein"
        }
    ],
    "24405": [
        {
            "o": "Böel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mohrkirch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rügge",
            "l": "Schleswig-Holstein"
        }
    ],
    "24407": [
        {
            "o": "Oersberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rabenkirchen-Faulück",
            "l": "Schleswig-Holstein"
        }
    ],
    "24409": [
        {
            "o": "Stoltebüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "24534": [
        {
            "o": "Neumünster",
            "l": "Schleswig-Holstein"
        }
    ],
    "24536": [
        {
            "o": "Neumünster",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tasdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24537": [
        {
            "o": "Neumünster",
            "l": "Schleswig-Holstein"
        }
    ],
    "24539": [
        {
            "o": "Neumünster",
            "l": "Schleswig-Holstein"
        }
    ],
    "24558": [
        {
            "o": "Henstedt-Ulzburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wakendorf II",
            "l": "Schleswig-Holstein"
        }
    ],
    "24568": [
        {
            "o": "Kaltenkirchen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kattendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nützen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Winsen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24576": [
        {
            "o": "Bad Bramstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bimöhlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hagen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hitzhusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mönkloh",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Weddelbrook",
            "l": "Schleswig-Holstein"
        }
    ],
    "24582": [
        {
            "o": "Bissee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bordesholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brügge",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Buchwald",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hoffeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mühbrook",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schönbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wattenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24589": [
        {
            "o": "Borgdorf-Seedorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dätgen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Eisendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ellerdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nortorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schülp bei Nortorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24594": [
        {
            "o": "Grauel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heinkenborstel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hohenwestedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jahrsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meezen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mörel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nindorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rade b. Hohenwestedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Remmels",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tappendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wapelfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24598": [
        {
            "o": "Boostedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heidmühlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Latendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24601": [
        {
            "o": "Belau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ruhwinkel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stolpe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wankendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24610": [
        {
            "o": "Gönnebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Trappenkamp",
            "l": "Schleswig-Holstein"
        }
    ],
    "24613": [
        {
            "o": "Aukrug",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wiedenborstel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24616": [
        {
            "o": "Armstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Borstel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brokstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hardebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hasenkrug",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sarlhusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Willenscharen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24619": [
        {
            "o": "Bornhöved",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rendswühren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tarbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24620": [
        {
            "o": "Bönebüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24622": [
        {
            "o": "Gnutz",
            "l": "Schleswig-Holstein"
        }
    ],
    "24623": [
        {
            "o": "Großenaspe",
            "l": "Schleswig-Holstein"
        }
    ],
    "24625": [
        {
            "o": "Großharrie",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Negenharrie",
            "l": "Schleswig-Holstein"
        }
    ],
    "24626": [
        {
            "o": "Groß Kummerfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24628": [
        {
            "o": "Hartenholm",
            "l": "Schleswig-Holstein"
        }
    ],
    "24629": [
        {
            "o": "Kisdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24631": [
        {
            "o": "Langwedel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24632": [
        {
            "o": "Heidmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lentföhrden",
            "l": "Schleswig-Holstein"
        }
    ],
    "24634": [
        {
            "o": "Arpsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Padenstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24635": [
        {
            "o": "Daldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rickling",
            "l": "Schleswig-Holstein"
        }
    ],
    "24637": [
        {
            "o": "Schillsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24638": [
        {
            "o": "Schmalensee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24640": [
        {
            "o": "Hasenmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lentföhrden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmalfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24641": [
        {
            "o": "Hüttblek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sievershütten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stuvenborn",
            "l": "Schleswig-Holstein"
        }
    ],
    "24643": [
        {
            "o": "Struvenhütten",
            "l": "Schleswig-Holstein"
        }
    ],
    "24644": [
        {
            "o": "Krogaspe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Loop",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Timmaspe",
            "l": "Schleswig-Holstein"
        }
    ],
    "24646": [
        {
            "o": "Warder",
            "l": "Schleswig-Holstein"
        }
    ],
    "24647": [
        {
            "o": "Ehndorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wasbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24649": [
        {
            "o": "Fuhlendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wiemersdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24768": [
        {
            "o": "Rendsburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24782": [
        {
            "o": "Alt Duvenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Büdelsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rickert",
            "l": "Schleswig-Holstein"
        }
    ],
    "24783": [
        {
            "o": "Osterrönfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24784": [
        {
            "o": "Westerrönfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24787": [
        {
            "o": "Fockbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rickert",
            "l": "Schleswig-Holstein"
        }
    ],
    "24790": [
        {
            "o": "Haßmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ostenfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schacht-Audorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schülldorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24791": [
        {
            "o": "Alt Duvenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neu Duvenstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24793": [
        {
            "o": "Bargstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brammer",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldenhütten",
            "l": "Schleswig-Holstein"
        }
    ],
    "24794": [
        {
            "o": "Borgstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bünsdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neu Duvenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schacht-Audorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24796": [
        {
            "o": "Bovenau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bredenbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krummwisch",
            "l": "Schleswig-Holstein"
        }
    ],
    "24797": [
        {
            "o": "Breiholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hörsten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tackesdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24799": [
        {
            "o": "Christiansholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Friedrichsgraben",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Friedrichsholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Königshügel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meggerdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24800": [
        {
            "o": "Elsdorf-Westermühlen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24802": [
        {
            "o": "Bokel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Emkendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Vollstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24803": [
        {
            "o": "Erfde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tielen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24805": [
        {
            "o": "Hamdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Prinzenmoor",
            "l": "Schleswig-Holstein"
        }
    ],
    "24806": [
        {
            "o": "Bargstall",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hohn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lohe-Föhrden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sophienhamm",
            "l": "Schleswig-Holstein"
        }
    ],
    "24808": [
        {
            "o": "Jevenstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24809": [
        {
            "o": "Nübbel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24811": [
        {
            "o": "Alt Duvenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brekendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Owschlag",
            "l": "Schleswig-Holstein"
        }
    ],
    "24813": [
        {
            "o": "Schülp bei Rendsburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24814": [
        {
            "o": "Sehestedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24816": [
        {
            "o": "Brinjahe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hamweddel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Luhnstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stafstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24817": [
        {
            "o": "Tetenhusen",
            "l": "Schleswig-Holstein"
        }
    ],
    "24819": [
        {
            "o": "Embühren",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Haale",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nienborstel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Todenbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24837": [
        {
            "o": "Schleswig",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schuby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24848": [
        {
            "o": "Alt Bennebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jagel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Bennebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Rheide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kropp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Owschlag",
            "l": "Schleswig-Holstein"
        }
    ],
    "24850": [
        {
            "o": "Hüsby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lürschau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schuby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24852": [
        {
            "o": "Eggebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Langstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sollerup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderhackstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24855": [
        {
            "o": "Bollingstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jübek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24857": [
        {
            "o": "Borgwedel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fahrdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24860": [
        {
            "o": "Böklund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klappholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Uelsby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24861": [
        {
            "o": "Bergenhusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meggerdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24863": [
        {
            "o": "Börm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Bennebek",
            "l": "Schleswig-Holstein"
        }
    ],
    "24864": [
        {
            "o": "Brodersby-Goltoft",
            "l": "Schleswig-Holstein"
        }
    ],
    "24866": [
        {
            "o": "Busdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24867": [
        {
            "o": "Dannewerk",
            "l": "Schleswig-Holstein"
        }
    ],
    "24869": [
        {
            "o": "Dörpstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24870": [
        {
            "o": "Ellingstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24872": [
        {
            "o": "Groß Rheide",
            "l": "Schleswig-Holstein"
        }
    ],
    "24873": [
        {
            "o": "Havetoft",
            "l": "Schleswig-Holstein"
        }
    ],
    "24876": [
        {
            "o": "Hollingstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24878": [
        {
            "o": "Jagel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lottorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24879": [
        {
            "o": "Idstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuberend",
            "l": "Schleswig-Holstein"
        }
    ],
    "24881": [
        {
            "o": "Nübel",
            "l": "Schleswig-Holstein"
        }
    ],
    "24882": [
        {
            "o": "Schaalby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24884": [
        {
            "o": "Geltorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Selk",
            "l": "Schleswig-Holstein"
        }
    ],
    "24885": [
        {
            "o": "Sieverstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24887": [
        {
            "o": "Silberstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24888": [
        {
            "o": "Loit",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Steinfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "24890": [
        {
            "o": "Stolk",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderfahrenstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24891": [
        {
            "o": "Böel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mohrkirch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schnarup-Thumby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Struxdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "24893": [
        {
            "o": "Taarstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24894": [
        {
            "o": "Tolk",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Twedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24896": [
        {
            "o": "Ahrenviölfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Treia",
            "l": "Schleswig-Holstein"
        }
    ],
    "24897": [
        {
            "o": "Ulsnis",
            "l": "Schleswig-Holstein"
        }
    ],
    "24899": [
        {
            "o": "Wohlde",
            "l": "Schleswig-Holstein"
        }
    ],
    "24937": [
        {
            "o": "Flensburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24939": [
        {
            "o": "Flensburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24941": [
        {
            "o": "Flensburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24943": [
        {
            "o": "Flensburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tastrup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24944": [
        {
            "o": "Flensburg",
            "l": "Schleswig-Holstein"
        }
    ],
    "24955": [
        {
            "o": "Harrislee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24960": [
        {
            "o": "Glücksburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Munkbrarup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24963": [
        {
            "o": "Jerrishoe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tarp",
            "l": "Schleswig-Holstein"
        }
    ],
    "24966": [
        {
            "o": "Sörup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24969": [
        {
            "o": "Großenwiehe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lindewitt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24972": [
        {
            "o": "Steinberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Steinbergkirche",
            "l": "Schleswig-Holstein"
        }
    ],
    "24975": [
        {
            "o": "Ausacker",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hürup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Husby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Maasbüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "24976": [
        {
            "o": "Handewitt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24977": [
        {
            "o": "Grundhof",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Langballig",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ringsberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerholz",
            "l": "Schleswig-Holstein"
        }
    ],
    "24980": [
        {
            "o": "Hörup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meyn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nordhackstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schafflund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wallsbüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "24983": [
        {
            "o": "Handewitt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24986": [
        {
            "o": "Mittelangeln",
            "l": "Schleswig-Holstein"
        }
    ],
    "24988": [
        {
            "o": "Oeversee",
            "l": "Schleswig-Holstein"
        }
    ],
    "24989": [
        {
            "o": "Dollerup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24991": [
        {
            "o": "Freienwill",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Großsolt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24992": [
        {
            "o": "Janneby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jörl",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderhackstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "24994": [
        {
            "o": "Böxlund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Holt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Jardelund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Medelby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Osterby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Weesby",
            "l": "Schleswig-Holstein"
        }
    ],
    "24996": [
        {
            "o": "Ahneby",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sterup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24997": [
        {
            "o": "Wanderup",
            "l": "Schleswig-Holstein"
        }
    ],
    "24999": [
        {
            "o": "Wees",
            "l": "Schleswig-Holstein"
        }
    ],
    "25335": [
        {
            "o": "Altenmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bokholt-Hanredder",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Elmshorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuendorf bei Elmshorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Raa-Besenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "25336": [
        {
            "o": "Elmshorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klein Nordende",
            "l": "Schleswig-Holstein"
        }
    ],
    "25337": [
        {
            "o": "Elmshorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kölln-Reisiek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seeth-Ekholt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25348": [
        {
            "o": "Blomesche Wildnis",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Engelbrechtsche Wildnis",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Glückstadt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25355": [
        {
            "o": "Barmstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bevern",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bullenkuhlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Groß Offenseth-Aspern",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heede",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lutzhorn",
            "l": "Schleswig-Holstein"
        }
    ],
    "25358": [
        {
            "o": "Hohenfelde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Horst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sommerland",
            "l": "Schleswig-Holstein"
        }
    ],
    "25361": [
        {
            "o": "Elskop",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grevenkop",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krempdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krempe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuenbrook",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderau",
            "l": "Schleswig-Holstein"
        }
    ],
    "25364": [
        {
            "o": "Bokel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brande-Hörnerkirchen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Osterhorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerhorn",
            "l": "Schleswig-Holstein"
        }
    ],
    "25365": [
        {
            "o": "Klein Offenseth-Sparrieshoop",
            "l": "Schleswig-Holstein"
        }
    ],
    "25368": [
        {
            "o": "Kiebitzreihe",
            "l": "Schleswig-Holstein"
        }
    ],
    "25370": [
        {
            "o": "Seester",
            "l": "Schleswig-Holstein"
        }
    ],
    "25371": [
        {
            "o": "Seestermühe",
            "l": "Schleswig-Holstein"
        }
    ],
    "25373": [
        {
            "o": "Ellerhoop",
            "l": "Schleswig-Holstein"
        }
    ],
    "25376": [
        {
            "o": "Borsfleth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krempdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25377": [
        {
            "o": "Kollmar",
            "l": "Schleswig-Holstein"
        }
    ],
    "25379": [
        {
            "o": "Herzhorn",
            "l": "Schleswig-Holstein"
        }
    ],
    "25421": [
        {
            "o": "Pinneberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "25436": [
        {
            "o": "Groß Nordende",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heidgraben",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Moorrege",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuendeich",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tornesch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Uetersen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25451": [
        {
            "o": "Quickborn",
            "l": "Schleswig-Holstein"
        }
    ],
    "25462": [
        {
            "o": "Rellingen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25469": [
        {
            "o": "Halstenbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "25474": [
        {
            "o": "Bönningstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ellerbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hasloh",
            "l": "Schleswig-Holstein"
        }
    ],
    "25479": [
        {
            "o": "Ellerau",
            "l": "Schleswig-Holstein"
        }
    ],
    "25482": [
        {
            "o": "Appen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25485": [
        {
            "o": "Bilsen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hemdingen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Langeln",
            "l": "Schleswig-Holstein"
        }
    ],
    "25486": [
        {
            "o": "Alveslohe",
            "l": "Schleswig-Holstein"
        }
    ],
    "25488": [
        {
            "o": "Holm",
            "l": "Schleswig-Holstein"
        }
    ],
    "25489": [
        {
            "o": "Haselau",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Haseldorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25491": [
        {
            "o": "Hetlingen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25492": [
        {
            "o": "Heist",
            "l": "Schleswig-Holstein"
        }
    ],
    "25494": [
        {
            "o": "Borstel-Hohenraden",
            "l": "Schleswig-Holstein"
        }
    ],
    "25495": [
        {
            "o": "Kummerfeld",
            "l": "Schleswig-Holstein"
        }
    ],
    "25497": [
        {
            "o": "Prisdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25499": [
        {
            "o": "Tangstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25524": [
        {
            "o": "Bekmünde",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Breitenburg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heiligenstedten",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Heiligenstedtenerkamp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Itzehoe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kollmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oelixdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25541": [
        {
            "o": "Brunsbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25548": [
        {
            "o": "Auufer",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kellinghusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mühlenbarbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oeschebüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rosdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Störkathen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wittenbergen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25551": [
        {
            "o": "Hohenlockstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lockstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lohbarbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Peissen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schlotfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Silzen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Winseldorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25554": [
        {
            "o": "Bekdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Brokdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dammfleth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kleve",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krummendiek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Landrecht",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Moorhusen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuendorf-Sachsenbande",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nortorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stördorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wilster",
            "l": "Schleswig-Holstein"
        }
    ],
    "25557": [
        {
            "o": "Beldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bornholt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gokels",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hanerau-Hademarschen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldenbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seefeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Steenfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Thaden",
            "l": "Schleswig-Holstein"
        }
    ],
    "25560": [
        {
            "o": "Aasbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Agethorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bokhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hadenfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kaisborstel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldenborstel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pöschendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Puls",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schenefeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Warringholz",
            "l": "Schleswig-Holstein"
        }
    ],
    "25563": [
        {
            "o": "Föhrden-Barl",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hingstheide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Quarnstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wrist",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wulfsmoor",
            "l": "Schleswig-Holstein"
        }
    ],
    "25566": [
        {
            "o": "Lägerdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rethwisch",
            "l": "Schleswig-Holstein"
        }
    ],
    "25569": [
        {
            "o": "Bahrenfleth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hodorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kremperheide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krempermoor",
            "l": "Schleswig-Holstein"
        }
    ],
    "25572": [
        {
            "o": "Aebtissinwisch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bokhorst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Büttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ecklak",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kudensee",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Landscheide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sankt Margarethen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Vaale",
            "l": "Schleswig-Holstein"
        }
    ],
    "25573": [
        {
            "o": "Beidenfleth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dammfleth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25575": [
        {
            "o": "Beringstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25576": [
        {
            "o": "Brokdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25578": [
        {
            "o": "Dägeling",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neuenbrook",
            "l": "Schleswig-Holstein"
        }
    ],
    "25579": [
        {
            "o": "Fitzbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rade",
            "l": "Schleswig-Holstein"
        }
    ],
    "25581": [
        {
            "o": "Hennstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Poyenberg",
            "l": "Schleswig-Holstein"
        }
    ],
    "25582": [
        {
            "o": "Drage",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hohenaspe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kaaks",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Looft",
            "l": "Schleswig-Holstein"
        }
    ],
    "25584": [
        {
            "o": "Besdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Holstenniendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25585": [
        {
            "o": "Lütjenwestedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tackesdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25587": [
        {
            "o": "Münsterdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25588": [
        {
            "o": "Huje",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Mehlbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldendorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25590": [
        {
            "o": "Osterstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25591": [
        {
            "o": "Ottenbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25593": [
        {
            "o": "Christinenthal",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reher",
            "l": "Schleswig-Holstein"
        }
    ],
    "25594": [
        {
            "o": "Nutteln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Vaale",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Vaalermoor",
            "l": "Schleswig-Holstein"
        }
    ],
    "25596": [
        {
            "o": "Bokelrehm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Gribbohm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nienbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wacken",
            "l": "Schleswig-Holstein"
        }
    ],
    "25597": [
        {
            "o": "Breitenberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kronsmoor",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Moordiek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westermoor",
            "l": "Schleswig-Holstein"
        }
    ],
    "25599": [
        {
            "o": "Wewelsfleth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25693": [
        {
            "o": "Gudendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sankt Michaelisdonn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Trennewurth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Volsemenhusen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25704": [
        {
            "o": "Bargenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Elpersbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Epenwöhrden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Meldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nindorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nordermeldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wolmersdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25709": [
        {
            "o": "Diekhusen-Fahrstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Helse",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kaiser-Wilhelm-Koog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kronprinzenkoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Marne",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Marnerdeich",
            "l": "Schleswig-Holstein"
        }
    ],
    "25712": [
        {
            "o": "Brickeln",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Buchholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Burg (Dithmarschen)",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Großenrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hochdonn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kuden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Quickborn",
            "l": "Schleswig-Holstein"
        }
    ],
    "25715": [
        {
            "o": "Averlak",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dingen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Eddelak",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ramhusen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25718": [
        {
            "o": "Friedrichskoog",
            "l": "Schleswig-Holstein"
        }
    ],
    "25719": [
        {
            "o": "Barlt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Busenwurth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25721": [
        {
            "o": "Eggstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25724": [
        {
            "o": "Neufeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neufelderkoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schmedeswurth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25725": [
        {
            "o": "Bornholt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schafstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25727": [
        {
            "o": "Frestedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krumstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderhastedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25729": [
        {
            "o": "Windbergen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25746": [
        {
            "o": "Heide",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lohe-Rickelshof",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Norderwöhrden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ostrohe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wesseln",
            "l": "Schleswig-Holstein"
        }
    ],
    "25761": [
        {
            "o": "Büsum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Büsumer Deichhausen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hedwigenkoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oesterdeichstrich",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Warwerort",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerdeichstrich",
            "l": "Schleswig-Holstein"
        }
    ],
    "25764": [
        {
            "o": "Friedrichsgabekoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hellschen-Heringsand-Unterschaar",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hillgroven",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Norddeich",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oesterwurth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reinsbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schülp",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderdeich",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wesselburen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wesselburener Deichhausen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wesselburenerkoog",
            "l": "Schleswig-Holstein"
        }
    ],
    "25767": [
        {
            "o": "Albersdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Arkebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Beldorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bunsoh",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Immenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Offenbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Osterrade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tensbüttel-Röst",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wennbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25770": [
        {
            "o": "Hemmingstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lieth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25774": [
        {
            "o": "Groven",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hemme",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Karolinenkoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Krempel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lehe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lunden",
            "l": "Schleswig-Holstein"
        }
    ],
    "25776": [
        {
            "o": "Kleve",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lehe",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rehm-Flehde-Bargen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sankt Annen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25779": [
        {
            "o": "Bergewöhrden",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Fedderingen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Glüsing",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hennstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kleve",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Norderheistedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schlichting",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderheistedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wiemerstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25782": [
        {
            "o": "Gaushorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hövede",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schalkholz",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schrum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tellingstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Welmbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerborstel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25785": [
        {
            "o": "Nordhastedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Odderade",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sarzbüttel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25786": [
        {
            "o": "Dellstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25788": [
        {
            "o": "Delve",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hollingstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wallen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25791": [
        {
            "o": "Barkenholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Linden",
            "l": "Schleswig-Holstein"
        }
    ],
    "25792": [
        {
            "o": "Neuenkirchen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Strübbel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25794": [
        {
            "o": "Dörpling",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pahlen",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tielenhemme",
            "l": "Schleswig-Holstein"
        }
    ],
    "25795": [
        {
            "o": "Stelle-Wittenwurth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Weddingstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25797": [
        {
            "o": "Wöhrden",
            "l": "Schleswig-Holstein"
        }
    ],
    "25799": [
        {
            "o": "Wrohm",
            "l": "Schleswig-Holstein"
        }
    ],
    "25813": [
        {
            "o": "Husum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwesing",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Simonsberg",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Südermarsch",
            "l": "Schleswig-Holstein"
        }
    ],
    "25821": [
        {
            "o": "Almdorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bordelum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bredstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Breklum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Reußenköge",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sönnebüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Struckum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Vollstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25826": [
        {
            "o": "Sankt Peter-Ording",
            "l": "Schleswig-Holstein"
        }
    ],
    "25832": [
        {
            "o": "Kotzenbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tönning",
            "l": "Schleswig-Holstein"
        }
    ],
    "25836": [
        {
            "o": "Garding",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Grothusenkoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Katharinenheerd",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kirchspiel Garding",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Osterhever",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Poppenbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Vollerwiek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Welt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25840": [
        {
            "o": "Drage",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Friedrichstadt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Koldenbüttel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seeth",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Südermarsch",
            "l": "Schleswig-Holstein"
        }
    ],
    "25842": [
        {
            "o": "Bargum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bordelum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Langenhorn",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lütjenholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ockholm",
            "l": "Schleswig-Holstein"
        }
    ],
    "25845": [
        {
            "o": "Elisabeth-Sophien-Koog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nordstrand",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Pellworm",
            "l": "Schleswig-Holstein"
        }
    ],
    "25849": [
        {
            "o": "Pellworm",
            "l": "Schleswig-Holstein"
        }
    ],
    "25850": [
        {
            "o": "Behrendorf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bondelum",
            "l": "Schleswig-Holstein"
        }
    ],
    "25852": [
        {
            "o": "Bordelum",
            "l": "Schleswig-Holstein"
        }
    ],
    "25853": [
        {
            "o": "Ahrenshöft",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Bohmstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Drelsdorf",
            "l": "Schleswig-Holstein"
        }
    ],
    "25855": [
        {
            "o": "Haselund",
            "l": "Schleswig-Holstein"
        }
    ],
    "25856": [
        {
            "o": "Hattstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hattstedtermarsch",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wobbenbüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "25858": [
        {
            "o": "Högel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25859": [
        {
            "o": "Hallig Hooge",
            "l": "Schleswig-Holstein"
        }
    ],
    "25860": [
        {
            "o": "Arlewatt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Horstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Olderup",
            "l": "Schleswig-Holstein"
        }
    ],
    "25862": [
        {
            "o": "Goldebek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Goldelund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Joldelund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Kolkerheide",
            "l": "Schleswig-Holstein"
        }
    ],
    "25863": [
        {
            "o": "Langeneß",
            "l": "Schleswig-Holstein"
        }
    ],
    "25864": [
        {
            "o": "Löwenstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25866": [
        {
            "o": "Mildstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25867": [
        {
            "o": "Langeneß",
            "l": "Schleswig-Holstein"
        }
    ],
    "25869": [
        {
            "o": "Gröde",
            "l": "Schleswig-Holstein"
        }
    ],
    "25870": [
        {
            "o": "Norderfriedrichskoog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldenswort",
            "l": "Schleswig-Holstein"
        }
    ],
    "25872": [
        {
            "o": "Ostenfeld (Husum)",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wittbek",
            "l": "Schleswig-Holstein"
        }
    ],
    "25873": [
        {
            "o": "Oldersbek",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rantrum",
            "l": "Schleswig-Holstein"
        }
    ],
    "25876": [
        {
            "o": "Fresendelf",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Hude",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ramstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Schwabstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderhöft",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wisch",
            "l": "Schleswig-Holstein"
        }
    ],
    "25878": [
        {
            "o": "Drage",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Seeth",
            "l": "Schleswig-Holstein"
        }
    ],
    "25879": [
        {
            "o": "Stapel",
            "l": "Schleswig-Holstein"
        }
    ],
    "25881": [
        {
            "o": "Tating",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tümlauer Koog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westerhever",
            "l": "Schleswig-Holstein"
        }
    ],
    "25882": [
        {
            "o": "Tetenbüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "25884": [
        {
            "o": "Norstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sollwitt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Viöl",
            "l": "Schleswig-Holstein"
        }
    ],
    "25885": [
        {
            "o": "Ahrenviöl",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ahrenviölfeld",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Immenstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oster-Ohrstedt",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wester-Ohrstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25887": [
        {
            "o": "Winnert",
            "l": "Schleswig-Holstein"
        }
    ],
    "25889": [
        {
            "o": "Uelvesbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Witzwort",
            "l": "Schleswig-Holstein"
        }
    ],
    "25899": [
        {
            "o": "Bosbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dagebüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Galmsbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klixbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Niebüll",
            "l": "Schleswig-Holstein"
        }
    ],
    "25917": [
        {
            "o": "Achtrup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Enge-Sande",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Leck",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Sprakebüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stadum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Tinningstedt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25920": [
        {
            "o": "Risum-Lindholm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Stedesand",
            "l": "Schleswig-Holstein"
        }
    ],
    "25923": [
        {
            "o": "Braderup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ellhöft",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Holm",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Humptrup",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Lexgaard",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderlügum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Uphusum",
            "l": "Schleswig-Holstein"
        }
    ],
    "25924": [
        {
            "o": "Emmelsbüll-Horsbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Friedrich-Wilhelm-Lübke-Koog",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Klanxbüll",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Rodenäs",
            "l": "Schleswig-Holstein"
        }
    ],
    "25926": [
        {
            "o": "Bramstedtlund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Karlum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Ladelund",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Westre",
            "l": "Schleswig-Holstein"
        }
    ],
    "25927": [
        {
            "o": "Aventoft",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Neukirchen",
            "l": "Schleswig-Holstein"
        }
    ],
    "25938": [
        {
            "o": "Alkersum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Borgsum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Dunsum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Midlum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Nieblum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oevenum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Oldsum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Süderende",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Utersum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Witsum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wrixum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wyk auf Föhr",
            "l": "Schleswig-Holstein"
        }
    ],
    "25946": [
        {
            "o": "Nebel",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Norddorf auf Amrum",
            "l": "Schleswig-Holstein"
        },
        {
            "o": "Wittdün auf Amrum",
            "l": "Schleswig-Holstein"
        }
    ],
    "25980": [
        {
            "o": "Sylt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25992": [
        {
            "o": "List auf Sylt",
            "l": "Schleswig-Holstein"
        }
    ],
    "25996": [
        {
            "o": "Wenningstedt-Braderup (Sylt)",
            "l": "Schleswig-Holstein"
        }
    ],
    "25997": [
        {
            "o": "Hörnum (Sylt)",
            "l": "Schleswig-Holstein"
        }
    ],
    "25999": [
        {
            "o": "Kampen (Sylt)",
            "l": "Schleswig-Holstein"
        }
    ],
    "26121": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26122": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26123": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26125": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26127": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26129": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26131": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26133": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26135": [
        {
            "o": "Oldenburg",
            "l": "Niedersachsen"
        }
    ],
    "26160": [
        {
            "o": "Bad Zwischenahn",
            "l": "Niedersachsen"
        }
    ],
    "26169": [
        {
            "o": "Friesoythe",
            "l": "Niedersachsen"
        }
    ],
    "26180": [
        {
            "o": "Rastede",
            "l": "Niedersachsen"
        }
    ],
    "26188": [
        {
            "o": "Edewecht",
            "l": "Niedersachsen"
        }
    ],
    "26197": [
        {
            "o": "Großenkneten",
            "l": "Niedersachsen"
        }
    ],
    "26203": [
        {
            "o": "Wardenburg",
            "l": "Niedersachsen"
        }
    ],
    "26209": [
        {
            "o": "Hatten",
            "l": "Niedersachsen"
        }
    ],
    "26215": [
        {
            "o": "Wiefelstede",
            "l": "Niedersachsen"
        }
    ],
    "26219": [
        {
            "o": "Bösel",
            "l": "Niedersachsen"
        }
    ],
    "26316": [
        {
            "o": "Varel",
            "l": "Niedersachsen"
        }
    ],
    "26340": [
        {
            "o": "Zetel",
            "l": "Niedersachsen"
        }
    ],
    "26345": [
        {
            "o": "Bockhorn",
            "l": "Niedersachsen"
        }
    ],
    "26349": [
        {
            "o": "Jade",
            "l": "Niedersachsen"
        }
    ],
    "26382": [
        {
            "o": "Wilhelmshaven",
            "l": "Niedersachsen"
        }
    ],
    "26384": [
        {
            "o": "Wilhelmshaven",
            "l": "Niedersachsen"
        }
    ],
    "26386": [
        {
            "o": "Wilhelmshaven",
            "l": "Niedersachsen"
        }
    ],
    "26388": [
        {
            "o": "Wilhelmshaven",
            "l": "Niedersachsen"
        }
    ],
    "26389": [
        {
            "o": "Wilhelmshaven",
            "l": "Niedersachsen"
        }
    ],
    "26409": [
        {
            "o": "Wittmund",
            "l": "Niedersachsen"
        }
    ],
    "26419": [
        {
            "o": "Schortens",
            "l": "Niedersachsen"
        }
    ],
    "26427": [
        {
            "o": "Dunum",
            "l": "Niedersachsen"
        },
        {
            "o": "Esens",
            "l": "Niedersachsen"
        },
        {
            "o": "Holtgast",
            "l": "Niedersachsen"
        },
        {
            "o": "Moorweg",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuharlingersiel",
            "l": "Niedersachsen"
        },
        {
            "o": "Stedesdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Werdum",
            "l": "Niedersachsen"
        }
    ],
    "26434": [
        {
            "o": "Wangerland",
            "l": "Niedersachsen"
        }
    ],
    "26441": [
        {
            "o": "Jever",
            "l": "Niedersachsen"
        }
    ],
    "26446": [
        {
            "o": "Friedeburg",
            "l": "Niedersachsen"
        }
    ],
    "26452": [
        {
            "o": "Sande",
            "l": "Niedersachsen"
        }
    ],
    "26465": [
        {
            "o": "Langeoog",
            "l": "Niedersachsen"
        }
    ],
    "26474": [
        {
            "o": "Spiekeroog",
            "l": "Niedersachsen"
        }
    ],
    "26486": [
        {
            "o": "Wangerooge",
            "l": "Niedersachsen"
        }
    ],
    "26487": [
        {
            "o": "Blomberg",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuschoo",
            "l": "Niedersachsen"
        }
    ],
    "26489": [
        {
            "o": "Ochtersum",
            "l": "Niedersachsen"
        }
    ],
    "26506": [
        {
            "o": "Norden",
            "l": "Niedersachsen"
        }
    ],
    "26524": [
        {
            "o": "Berumbur",
            "l": "Niedersachsen"
        },
        {
            "o": "Hage",
            "l": "Niedersachsen"
        },
        {
            "o": "Hagermarsch",
            "l": "Niedersachsen"
        },
        {
            "o": "Halbemond",
            "l": "Niedersachsen"
        },
        {
            "o": "Lütetsburg",
            "l": "Niedersachsen"
        }
    ],
    "26529": [
        {
            "o": "Leezdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Marienhafe",
            "l": "Niedersachsen"
        },
        {
            "o": "Osteel",
            "l": "Niedersachsen"
        },
        {
            "o": "Rechtsupweg",
            "l": "Niedersachsen"
        },
        {
            "o": "Upgant-Schott",
            "l": "Niedersachsen"
        },
        {
            "o": "Wirdum",
            "l": "Niedersachsen"
        }
    ],
    "26532": [
        {
            "o": "Großheide",
            "l": "Niedersachsen"
        }
    ],
    "26548": [
        {
            "o": "Norderney",
            "l": "Niedersachsen"
        }
    ],
    "26553": [
        {
            "o": "Dornum",
            "l": "Niedersachsen"
        }
    ],
    "26556": [
        {
            "o": "Eversmeer",
            "l": "Niedersachsen"
        },
        {
            "o": "Nenndorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Schweindorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Utarp",
            "l": "Niedersachsen"
        },
        {
            "o": "Westerholt",
            "l": "Niedersachsen"
        }
    ],
    "26571": [
        {
            "o": "Juist",
            "l": "Niedersachsen"
        }
    ],
    "26579": [
        {
            "o": "Baltrum",
            "l": "Niedersachsen"
        }
    ],
    "26603": [
        {
            "o": "Aurich",
            "l": "Niedersachsen"
        }
    ],
    "26605": [
        {
            "o": "Aurich",
            "l": "Niedersachsen"
        }
    ],
    "26607": [
        {
            "o": "Aurich",
            "l": "Niedersachsen"
        }
    ],
    "26624": [
        {
            "o": "Südbrookmerland",
            "l": "Niedersachsen"
        }
    ],
    "26629": [
        {
            "o": "Großefehn",
            "l": "Niedersachsen"
        }
    ],
    "26632": [
        {
            "o": "Ihlow",
            "l": "Niedersachsen"
        }
    ],
    "26639": [
        {
            "o": "Wiesmoor",
            "l": "Niedersachsen"
        }
    ],
    "26655": [
        {
            "o": "Westerstede",
            "l": "Niedersachsen"
        }
    ],
    "26670": [
        {
            "o": "Uplengen",
            "l": "Niedersachsen"
        }
    ],
    "26676": [
        {
            "o": "Barßel",
            "l": "Niedersachsen"
        }
    ],
    "26683": [
        {
            "o": "Saterland",
            "l": "Niedersachsen"
        }
    ],
    "26689": [
        {
            "o": "Apen",
            "l": "Niedersachsen"
        }
    ],
    "26721": [
        {
            "o": "Emden",
            "l": "Niedersachsen"
        }
    ],
    "26723": [
        {
            "o": "Emden",
            "l": "Niedersachsen"
        }
    ],
    "26725": [
        {
            "o": "Emden",
            "l": "Niedersachsen"
        }
    ],
    "26736": [
        {
            "o": "Krummhörn",
            "l": "Niedersachsen"
        }
    ],
    "26757": [
        {
            "o": "Borkum",
            "l": "Niedersachsen"
        }
    ],
    "26759": [
        {
            "o": "Hinte",
            "l": "Niedersachsen"
        },
        {
            "o": "Südbrookmerland",
            "l": "Niedersachsen"
        }
    ],
    "26789": [
        {
            "o": "Leer",
            "l": "Niedersachsen"
        }
    ],
    "26802": [
        {
            "o": "Moormerland",
            "l": "Niedersachsen"
        }
    ],
    "26810": [
        {
            "o": "Westoverledingen",
            "l": "Niedersachsen"
        }
    ],
    "26817": [
        {
            "o": "Rhauderfehn",
            "l": "Niedersachsen"
        }
    ],
    "26826": [
        {
            "o": "Weener",
            "l": "Niedersachsen"
        }
    ],
    "26831": [
        {
            "o": "Bunde",
            "l": "Niedersachsen"
        }
    ],
    "26835": [
        {
            "o": "Brinkum",
            "l": "Niedersachsen"
        },
        {
            "o": "Firrel",
            "l": "Niedersachsen"
        },
        {
            "o": "Hesel",
            "l": "Niedersachsen"
        },
        {
            "o": "Holtland",
            "l": "Niedersachsen"
        },
        {
            "o": "Neukamperfehn",
            "l": "Niedersachsen"
        },
        {
            "o": "Schwerinsdorf",
            "l": "Niedersachsen"
        }
    ],
    "26842": [
        {
            "o": "Ostrhauderfehn",
            "l": "Niedersachsen"
        }
    ],
    "26844": [
        {
            "o": "Jemgum",
            "l": "Niedersachsen"
        }
    ],
    "26845": [
        {
            "o": "Nortmoor",
            "l": "Niedersachsen"
        }
    ],
    "26847": [
        {
            "o": "Detern",
            "l": "Niedersachsen"
        }
    ],
    "26849": [
        {
            "o": "Filsum",
            "l": "Niedersachsen"
        }
    ],
    "26871": [
        {
            "o": "Papenburg",
            "l": "Niedersachsen"
        }
    ],
    "26892": [
        {
            "o": "Dörpen",
            "l": "Niedersachsen"
        },
        {
            "o": "Heede",
            "l": "Niedersachsen"
        },
        {
            "o": "Kluse",
            "l": "Niedersachsen"
        },
        {
            "o": "Lehe",
            "l": "Niedersachsen"
        },
        {
            "o": "Wippingen",
            "l": "Niedersachsen"
        }
    ],
    "26897": [
        {
            "o": "Bockhorst",
            "l": "Niedersachsen"
        },
        {
            "o": "Breddenberg",
            "l": "Niedersachsen"
        },
        {
            "o": "Esterwegen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hilkenbrook",
            "l": "Niedersachsen"
        }
    ],
    "26899": [
        {
            "o": "Rhede (Ems)",
            "l": "Niedersachsen"
        }
    ],
    "26901": [
        {
            "o": "Lorup",
            "l": "Niedersachsen"
        },
        {
            "o": "Rastdorf",
            "l": "Niedersachsen"
        }
    ],
    "26903": [
        {
            "o": "Surwold",
            "l": "Niedersachsen"
        }
    ],
    "26904": [
        {
            "o": "Börger",
            "l": "Niedersachsen"
        }
    ],
    "26906": [
        {
            "o": "Dersum",
            "l": "Niedersachsen"
        }
    ],
    "26907": [
        {
            "o": "Walchum",
            "l": "Niedersachsen"
        }
    ],
    "26909": [
        {
            "o": "Neubörger",
            "l": "Niedersachsen"
        },
        {
            "o": "Neulehe",
            "l": "Niedersachsen"
        }
    ],
    "26919": [
        {
            "o": "Brake",
            "l": "Niedersachsen"
        }
    ],
    "26931": [
        {
            "o": "Elsfleth",
            "l": "Niedersachsen"
        }
    ],
    "26935": [
        {
            "o": "Stadland",
            "l": "Niedersachsen"
        }
    ],
    "26936": [
        {
            "o": "Stadland",
            "l": "Niedersachsen"
        }
    ],
    "26937": [
        {
            "o": "Stadland",
            "l": "Niedersachsen"
        }
    ],
    "26939": [
        {
            "o": "Ovelgönne",
            "l": "Niedersachsen"
        }
    ],
    "26954": [
        {
            "o": "Nordenham",
            "l": "Niedersachsen"
        }
    ],
    "26969": [
        {
            "o": "Butjadingen",
            "l": "Niedersachsen"
        }
    ],
    "27211": [
        {
            "o": "Bassum",
            "l": "Niedersachsen"
        }
    ],
    "27232": [
        {
            "o": "Sulingen",
            "l": "Niedersachsen"
        }
    ],
    "27239": [
        {
            "o": "Twistringen",
            "l": "Niedersachsen"
        }
    ],
    "27243": [
        {
            "o": "Beckeln",
            "l": "Niedersachsen"
        },
        {
            "o": "Colnrade",
            "l": "Niedersachsen"
        },
        {
            "o": "Dünsen",
            "l": "Niedersachsen"
        },
        {
            "o": "Groß Ippener",
            "l": "Niedersachsen"
        },
        {
            "o": "Harpstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Kirchseelte",
            "l": "Niedersachsen"
        },
        {
            "o": "Prinzhöfte",
            "l": "Niedersachsen"
        },
        {
            "o": "Winkelsett",
            "l": "Niedersachsen"
        }
    ],
    "27245": [
        {
            "o": "Bahrenborstel",
            "l": "Niedersachsen"
        },
        {
            "o": "Barenburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Kirchdorf",
            "l": "Niedersachsen"
        }
    ],
    "27246": [
        {
            "o": "Borstel",
            "l": "Niedersachsen"
        }
    ],
    "27248": [
        {
            "o": "Ehrenburg",
            "l": "Niedersachsen"
        }
    ],
    "27249": [
        {
            "o": "Maasen",
            "l": "Niedersachsen"
        },
        {
            "o": "Mellinghausen",
            "l": "Niedersachsen"
        }
    ],
    "27251": [
        {
            "o": "Neuenkirchen",
            "l": "Niedersachsen"
        },
        {
            "o": "Scholen",
            "l": "Niedersachsen"
        }
    ],
    "27252": [
        {
            "o": "Schwaförden",
            "l": "Niedersachsen"
        }
    ],
    "27254": [
        {
            "o": "Siedenburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Staffhorst",
            "l": "Niedersachsen"
        }
    ],
    "27257": [
        {
            "o": "Affinghausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Sudwalde",
            "l": "Niedersachsen"
        }
    ],
    "27259": [
        {
            "o": "Freistatt",
            "l": "Niedersachsen"
        },
        {
            "o": "Varrel",
            "l": "Niedersachsen"
        },
        {
            "o": "Wehrbleck",
            "l": "Niedersachsen"
        }
    ],
    "27283": [
        {
            "o": "Verden (Aller)",
            "l": "Niedersachsen"
        }
    ],
    "27299": [
        {
            "o": "Langwedel",
            "l": "Niedersachsen"
        }
    ],
    "27305": [
        {
            "o": "Bruchhausen-Vilsen",
            "l": "Niedersachsen"
        }
    ],
    "27308": [
        {
            "o": "Kirchlinteln",
            "l": "Niedersachsen"
        }
    ],
    "27313": [
        {
            "o": "Dörverden",
            "l": "Niedersachsen"
        }
    ],
    "27318": [
        {
            "o": "Hilgermissen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hoya",
            "l": "Niedersachsen"
        },
        {
            "o": "Hoyerhagen",
            "l": "Niedersachsen"
        }
    ],
    "27321": [
        {
            "o": "Emtinghausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Thedinghausen",
            "l": "Niedersachsen"
        }
    ],
    "27324": [
        {
            "o": "Eystrup",
            "l": "Niedersachsen"
        },
        {
            "o": "Gandesbergen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hämelhausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hassel (Weser)",
            "l": "Niedersachsen"
        },
        {
            "o": "Schweringen",
            "l": "Niedersachsen"
        }
    ],
    "27327": [
        {
            "o": "Martfeld",
            "l": "Niedersachsen"
        },
        {
            "o": "Schwarme",
            "l": "Niedersachsen"
        }
    ],
    "27330": [
        {
            "o": "Asendorf",
            "l": "Niedersachsen"
        }
    ],
    "27333": [
        {
            "o": "Bücken",
            "l": "Niedersachsen"
        },
        {
            "o": "Schweringen",
            "l": "Niedersachsen"
        },
        {
            "o": "Warpe",
            "l": "Niedersachsen"
        }
    ],
    "27336": [
        {
            "o": "Frankenfeld",
            "l": "Niedersachsen"
        },
        {
            "o": "Häuslingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Rethem (Aller)",
            "l": "Niedersachsen"
        }
    ],
    "27337": [
        {
            "o": "Blender",
            "l": "Niedersachsen"
        }
    ],
    "27339": [
        {
            "o": "Riede",
            "l": "Niedersachsen"
        }
    ],
    "27356": [
        {
            "o": "Rotenburg",
            "l": "Niedersachsen"
        }
    ],
    "27367": [
        {
            "o": "Ahausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Bötersen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hassendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hellwege",
            "l": "Niedersachsen"
        },
        {
            "o": "Horstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Reeßum",
            "l": "Niedersachsen"
        },
        {
            "o": "Sottrum",
            "l": "Niedersachsen"
        }
    ],
    "27374": [
        {
            "o": "Visselhövede",
            "l": "Niedersachsen"
        }
    ],
    "27383": [
        {
            "o": "Scheeßel",
            "l": "Niedersachsen"
        }
    ],
    "27386": [
        {
            "o": "Bothel",
            "l": "Niedersachsen"
        },
        {
            "o": "Brockel",
            "l": "Niedersachsen"
        },
        {
            "o": "Hemsbünde",
            "l": "Niedersachsen"
        },
        {
            "o": "Hemslingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Kirchwalsede",
            "l": "Niedersachsen"
        },
        {
            "o": "Westerwalsede",
            "l": "Niedersachsen"
        }
    ],
    "27389": [
        {
            "o": "Fintel",
            "l": "Niedersachsen"
        },
        {
            "o": "Helvesiek",
            "l": "Niedersachsen"
        },
        {
            "o": "Lauenbrück",
            "l": "Niedersachsen"
        },
        {
            "o": "Stemmen",
            "l": "Niedersachsen"
        },
        {
            "o": "Vahlde",
            "l": "Niedersachsen"
        }
    ],
    "27404": [
        {
            "o": "Elsdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Gyhum",
            "l": "Niedersachsen"
        },
        {
            "o": "Heeslingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Ostereistedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Rhade",
            "l": "Niedersachsen"
        },
        {
            "o": "Seedorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Zeven",
            "l": "Niedersachsen"
        }
    ],
    "27412": [
        {
            "o": "Breddorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Bülstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Hepstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Kirchtimke",
            "l": "Niedersachsen"
        },
        {
            "o": "Tarmstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Vorwerk",
            "l": "Niedersachsen"
        },
        {
            "o": "Westertimke",
            "l": "Niedersachsen"
        },
        {
            "o": "Wilstedt",
            "l": "Niedersachsen"
        }
    ],
    "27419": [
        {
            "o": "Groß Meckelsen",
            "l": "Niedersachsen"
        },
        {
            "o": "Hamersen",
            "l": "Niedersachsen"
        },
        {
            "o": "Kalbe",
            "l": "Niedersachsen"
        },
        {
            "o": "Klein Meckelsen",
            "l": "Niedersachsen"
        },
        {
            "o": "Lengenbostel",
            "l": "Niedersachsen"
        },
        {
            "o": "Sittensen",
            "l": "Niedersachsen"
        },
        {
            "o": "Tiste",
            "l": "Niedersachsen"
        },
        {
            "o": "Vierden",
            "l": "Niedersachsen"
        },
        {
            "o": "Wohnste",
            "l": "Niedersachsen"
        }
    ],
    "27432": [
        {
            "o": "Alfstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Basdahl",
            "l": "Niedersachsen"
        },
        {
            "o": "Bremervörde",
            "l": "Niedersachsen"
        },
        {
            "o": "Ebersdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hipstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Oerel",
            "l": "Niedersachsen"
        }
    ],
    "27442": [
        {
            "o": "Gnarrenburg",
            "l": "Niedersachsen"
        }
    ],
    "27446": [
        {
            "o": "Anderlingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Deinstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Farven",
            "l": "Niedersachsen"
        },
        {
            "o": "Sandbostel",
            "l": "Niedersachsen"
        },
        {
            "o": "Selsingen",
            "l": "Niedersachsen"
        }
    ],
    "27449": [
        {
            "o": "Kutenholz",
            "l": "Niedersachsen"
        }
    ],
    "27472": [
        {
            "o": "Cuxhaven",
            "l": "Niedersachsen"
        }
    ],
    "27474": [
        {
            "o": "Cuxhaven",
            "l": "Niedersachsen"
        }
    ],
    "27476": [
        {
            "o": "Cuxhaven",
            "l": "Niedersachsen"
        }
    ],
    "27478": [
        {
            "o": "Cuxhaven",
            "l": "Niedersachsen"
        }
    ],
    "27498": [
        {
            "o": "Helgoland",
            "l": "Schleswig-Holstein"
        }
    ],
    "27499": [
        {
            "o": "Hamburg",
            "l": "Hamburg"
        }
    ],
    "27568": [
        {
            "o": "Bremen",
            "l": "Bremen"
        },
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27570": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27572": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27574": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27576": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27578": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27580": [
        {
            "o": "Bremerhaven",
            "l": "Bremen"
        }
    ],
    "27607": [
        {
            "o": "Geestland",
            "l": "Niedersachsen"
        }
    ],
    "27612": [
        {
            "o": "Loxstedt",
            "l": "Niedersachsen"
        }
    ],
    "27616": [
        {
            "o": "Beverstedt",
            "l": "Niedersachsen"
        }
    ],
    "27619": [
        {
            "o": "Schiffdorf",
            "l": "Niedersachsen"
        }
    ],
    "27624": [
        {
            "o": "Geestland",
            "l": "Niedersachsen"
        }
    ],
    "27628": [
        {
            "o": "Hagen im Bremischen",
            "l": "Niedersachsen"
        }
    ],
    "27639": [
        {
            "o": "Wurster Nordseeküste",
            "l": "Niedersachsen"
        }
    ],
    "27711": [
        {
            "o": "Osterholz-Scharmbeck",
            "l": "Niedersachsen"
        }
    ],
    "27721": [
        {
            "o": "Ritterhude",
            "l": "Niedersachsen"
        }
    ],
    "27726": [
        {
            "o": "Breddorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Worpswede",
            "l": "Niedersachsen"
        }
    ],
    "27729": [
        {
            "o": "Axstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Hambergen",
            "l": "Niedersachsen"
        },
        {
            "o": "Holste",
            "l": "Niedersachsen"
        },
        {
            "o": "Lübberstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Vollersode",
            "l": "Niedersachsen"
        }
    ],
    "27749": [
        {
            "o": "Delmenhorst",
            "l": "Niedersachsen"
        }
    ],
    "27751": [
        {
            "o": "Delmenhorst",
            "l": "Niedersachsen"
        }
    ],
    "27753": [
        {
            "o": "Delmenhorst",
            "l": "Niedersachsen"
        }
    ],
    "27755": [
        {
            "o": "Delmenhorst",
            "l": "Niedersachsen"
        }
    ],
    "27777": [
        {
            "o": "Ganderkesee",
            "l": "Niedersachsen"
        }
    ],
    "27793": [
        {
            "o": "Wildeshausen",
            "l": "Niedersachsen"
        }
    ],
    "27798": [
        {
            "o": "Hude (Oldenburg)",
            "l": "Niedersachsen"
        }
    ],
    "27801": [
        {
            "o": "Dötlingen",
            "l": "Niedersachsen"
        }
    ],
    "27804": [
        {
            "o": "Berne",
            "l": "Niedersachsen"
        }
    ],
    "27809": [
        {
            "o": "Lemwerder",
            "l": "Niedersachsen"
        }
    ],
    "28195": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28197": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28199": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28201": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28203": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28205": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28207": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28209": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28211": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28213": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28215": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28217": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28219": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28237": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28239": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28259": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28277": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28279": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28307": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28309": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28325": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28327": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28329": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28355": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28357": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28359": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28717": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28719": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28755": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28757": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28759": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28777": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28779": [
        {
            "o": "Bremen",
            "l": "Bremen"
        }
    ],
    "28790": [
        {
            "o": "Schwanewede",
            "l": "Niedersachsen"
        }
    ],
    "28816": [
        {
            "o": "Stuhr",
            "l": "Niedersachsen"
        }
    ],
    "28832": [
        {
            "o": "Achim",
            "l": "Niedersachsen"
        }
    ],
    "28844": [
        {
            "o": "Weyhe",
            "l": "Niedersachsen"
        }
    ],
    "28857": [
        {
            "o": "Syke",
            "l": "Niedersachsen"
        }
    ],
    "28865": [
        {
            "o": "Lilienthal",
            "l": "Niedersachsen"
        }
    ],
    "28870": [
        {
            "o": "Ottersberg",
            "l": "Niedersachsen"
        }
    ],
    "28876": [
        {
            "o": "Oyten",
            "l": "Niedersachsen"
        }
    ],
    "28879": [
        {
            "o": "Grasberg",
            "l": "Niedersachsen"
        }
    ],
    "29221": [
        {
            "o": "Celle",
            "l": "Niedersachsen"
        }
    ],
    "29223": [
        {
            "o": "Celle",
            "l": "Niedersachsen"
        }
    ],
    "29225": [
        {
            "o": "Celle",
            "l": "Niedersachsen"
        }
    ],
    "29227": [
        {
            "o": "Celle",
            "l": "Niedersachsen"
        }
    ],
    "29229": [
        {
            "o": "Celle",
            "l": "Niedersachsen"
        },
        {
            "o": "Winsen (Aller)",
            "l": "Niedersachsen"
        }
    ],
    "29303": [
        {
            "o": "Bergen",
            "l": "Niedersachsen"
        },
        {
            "o": "Lohheide",
            "l": "Niedersachsen"
        },
        {
            "o": "Südheide",
            "l": "Niedersachsen"
        }
    ],
    "29308": [
        {
            "o": "Winsen (Aller)",
            "l": "Niedersachsen"
        }
    ],
    "29313": [
        {
            "o": "Hambühren",
            "l": "Niedersachsen"
        }
    ],
    "29320": [
        {
            "o": "Südheide",
            "l": "Niedersachsen"
        }
    ],
    "29323": [
        {
            "o": "Wietze",
            "l": "Niedersachsen"
        }
    ],
    "29328": [
        {
            "o": "Faßberg",
            "l": "Niedersachsen"
        }
    ],
    "29331": [
        {
            "o": "Lachendorf",
            "l": "Niedersachsen"
        }
    ],
    "29336": [
        {
            "o": "Nienhagen",
            "l": "Niedersachsen"
        }
    ],
    "29339": [
        {
            "o": "Wathlingen",
            "l": "Niedersachsen"
        }
    ],
    "29342": [
        {
            "o": "Wienhausen",
            "l": "Niedersachsen"
        }
    ],
    "29345": [
        {
            "o": "Südheide",
            "l": "Niedersachsen"
        }
    ],
    "29348": [
        {
            "o": "Eschede",
            "l": "Niedersachsen"
        }
    ],
    "29351": [
        {
            "o": "Eldingen",
            "l": "Niedersachsen"
        }
    ],
    "29352": [
        {
            "o": "Adelheidsdorf",
            "l": "Niedersachsen"
        }
    ],
    "29353": [
        {
            "o": "Ahnsbeck",
            "l": "Niedersachsen"
        }
    ],
    "29355": [
        {
            "o": "Beedenbostel",
            "l": "Niedersachsen"
        }
    ],
    "29356": [
        {
            "o": "Bröckel",
            "l": "Niedersachsen"
        }
    ],
    "29358": [
        {
            "o": "Eicklingen",
            "l": "Niedersachsen"
        }
    ],
    "29359": [
        {
            "o": "Eschede",
            "l": "Niedersachsen"
        }
    ],
    "29361": [
        {
            "o": "Eschede",
            "l": "Niedersachsen"
        }
    ],
    "29362": [
        {
            "o": "Hohne",
            "l": "Niedersachsen"
        }
    ],
    "29364": [
        {
            "o": "Langlingen",
            "l": "Niedersachsen"
        }
    ],
    "29365": [
        {
            "o": "Sprakensehl",
            "l": "Niedersachsen"
        }
    ],
    "29367": [
        {
            "o": "Steinhorst",
            "l": "Niedersachsen"
        }
    ],
    "29369": [
        {
            "o": "Ummern",
            "l": "Niedersachsen"
        }
    ],
    "29378": [
        {
            "o": "Wittingen",
            "l": "Niedersachsen"
        }
    ],
    "29379": [
        {
            "o": "Wittingen",
            "l": "Niedersachsen"
        }
    ],
    "29386": [
        {
            "o": "Dedelstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hankensbüttel",
            "l": "Niedersachsen"
        },
        {
            "o": "Obernholz",
            "l": "Niedersachsen"
        }
    ],
    "29389": [
        {
            "o": "Bad Bodenteich",
            "l": "Niedersachsen"
        }
    ],
    "29392": [
        {
            "o": "Wesendorf",
            "l": "Niedersachsen"
        }
    ],
    "29393": [
        {
            "o": "Groß Oesingen",
            "l": "Niedersachsen"
        }
    ],
    "29394": [
        {
            "o": "Lüder",
            "l": "Niedersachsen"
        }
    ],
    "29396": [
        {
            "o": "Schönewörde",
            "l": "Niedersachsen"
        }
    ],
    "29399": [
        {
            "o": "Wahrenholz",
            "l": "Niedersachsen"
        }
    ],
    "29410": [
        {
            "o": "Salzwedel",
            "l": "Sachsen-Anhalt"
        }
    ],
    "29413": [
        {
            "o": "Dähre",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Diesdorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wallstawe",
            "l": "Sachsen-Anhalt"
        }
    ],
    "29416": [
        {
            "o": "Kuhfelde",
            "l": "Sachsen-Anhalt"
        }
    ],
    "29439": [
        {
            "o": "Lüchow",
            "l": "Niedersachsen"
        }
    ],
    "29451": [
        {
            "o": "Dannenberg",
            "l": "Niedersachsen"
        }
    ],
    "29456": [
        {
            "o": "Hitzacker",
            "l": "Niedersachsen"
        }
    ],
    "29459": [
        {
            "o": "Clenze",
            "l": "Niedersachsen"
        }
    ],
    "29462": [
        {
            "o": "Wustrow",
            "l": "Niedersachsen"
        }
    ],
    "29465": [
        {
            "o": "Schnega",
            "l": "Niedersachsen"
        }
    ],
    "29468": [
        {
            "o": "Bergen an der Dumme",
            "l": "Niedersachsen"
        }
    ],
    "29471": [
        {
            "o": "Gartow",
            "l": "Niedersachsen"
        }
    ],
    "29472": [
        {
            "o": "Damnatz",
            "l": "Niedersachsen"
        }
    ],
    "29473": [
        {
            "o": "Göhrde",
            "l": "Niedersachsen"
        }
    ],
    "29475": [
        {
            "o": "Gorleben",
            "l": "Niedersachsen"
        }
    ],
    "29476": [
        {
            "o": "Gusborn",
            "l": "Niedersachsen"
        }
    ],
    "29478": [
        {
            "o": "Höhbeck",
            "l": "Niedersachsen"
        }
    ],
    "29479": [
        {
            "o": "Jameln",
            "l": "Niedersachsen"
        }
    ],
    "29481": [
        {
            "o": "Karwitz",
            "l": "Niedersachsen"
        }
    ],
    "29482": [
        {
            "o": "Küsten",
            "l": "Niedersachsen"
        }
    ],
    "29484": [
        {
            "o": "Langendorf",
            "l": "Niedersachsen"
        }
    ],
    "29485": [
        {
            "o": "Lemgow",
            "l": "Niedersachsen"
        }
    ],
    "29487": [
        {
            "o": "Luckau (Wendland)",
            "l": "Niedersachsen"
        }
    ],
    "29488": [
        {
            "o": "Lübbow",
            "l": "Niedersachsen"
        }
    ],
    "29490": [
        {
            "o": "Neu Darchau",
            "l": "Niedersachsen"
        }
    ],
    "29491": [
        {
            "o": "Prezelle",
            "l": "Niedersachsen"
        }
    ],
    "29493": [
        {
            "o": "Schnackenburg",
            "l": "Niedersachsen"
        }
    ],
    "29494": [
        {
            "o": "Trebel",
            "l": "Niedersachsen"
        }
    ],
    "29496": [
        {
            "o": "Waddeweitz",
            "l": "Niedersachsen"
        }
    ],
    "29497": [
        {
            "o": "Woltersdorf",
            "l": "Niedersachsen"
        }
    ],
    "29499": [
        {
            "o": "Zernien",
            "l": "Niedersachsen"
        }
    ],
    "29525": [
        {
            "o": "Uelzen",
            "l": "Niedersachsen"
        }
    ],
    "29549": [
        {
            "o": "Bad Bevensen",
            "l": "Niedersachsen"
        }
    ],
    "29553": [
        {
            "o": "Bienenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "29556": [
        {
            "o": "Suderburg",
            "l": "Niedersachsen"
        }
    ],
    "29559": [
        {
            "o": "Wrestedt",
            "l": "Niedersachsen"
        }
    ],
    "29562": [
        {
            "o": "Suhlendorf",
            "l": "Niedersachsen"
        }
    ],
    "29565": [
        {
            "o": "Wriedel",
            "l": "Niedersachsen"
        }
    ],
    "29571": [
        {
            "o": "Rosche",
            "l": "Niedersachsen"
        }
    ],
    "29574": [
        {
            "o": "Ebstorf",
            "l": "Niedersachsen"
        }
    ],
    "29575": [
        {
            "o": "Altenmedingen",
            "l": "Niedersachsen"
        }
    ],
    "29576": [
        {
            "o": "Barum",
            "l": "Niedersachsen"
        }
    ],
    "29578": [
        {
            "o": "Eimke",
            "l": "Niedersachsen"
        }
    ],
    "29579": [
        {
            "o": "Emmendorf",
            "l": "Niedersachsen"
        }
    ],
    "29581": [
        {
            "o": "Gerdau",
            "l": "Niedersachsen"
        }
    ],
    "29582": [
        {
            "o": "Hanstedt",
            "l": "Niedersachsen"
        }
    ],
    "29584": [
        {
            "o": "Himbergen",
            "l": "Niedersachsen"
        }
    ],
    "29585": [
        {
            "o": "Jelmstorf",
            "l": "Niedersachsen"
        }
    ],
    "29587": [
        {
            "o": "Natendorf",
            "l": "Niedersachsen"
        }
    ],
    "29588": [
        {
            "o": "Oetzen",
            "l": "Niedersachsen"
        }
    ],
    "29590": [
        {
            "o": "Rätzlingen",
            "l": "Niedersachsen"
        }
    ],
    "29591": [
        {
            "o": "Römstedt",
            "l": "Niedersachsen"
        }
    ],
    "29593": [
        {
            "o": "Schwienau",
            "l": "Niedersachsen"
        }
    ],
    "29594": [
        {
            "o": "Soltendieck",
            "l": "Niedersachsen"
        }
    ],
    "29597": [
        {
            "o": "Stoetze",
            "l": "Niedersachsen"
        }
    ],
    "29599": [
        {
            "o": "Weste",
            "l": "Niedersachsen"
        }
    ],
    "29614": [
        {
            "o": "Soltau",
            "l": "Niedersachsen"
        }
    ],
    "29633": [
        {
            "o": "Munster",
            "l": "Niedersachsen"
        }
    ],
    "29640": [
        {
            "o": "Schneverdingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Undeloh",
            "l": "Niedersachsen"
        }
    ],
    "29643": [
        {
            "o": "Neuenkirchen",
            "l": "Niedersachsen"
        }
    ],
    "29646": [
        {
            "o": "Bispingen",
            "l": "Niedersachsen"
        }
    ],
    "29649": [
        {
            "o": "Wietzendorf",
            "l": "Niedersachsen"
        }
    ],
    "29664": [
        {
            "o": "Osterheide",
            "l": "Niedersachsen"
        },
        {
            "o": "Walsrode",
            "l": "Niedersachsen"
        }
    ],
    "29683": [
        {
            "o": "Bad Fallingbostel",
            "l": "Niedersachsen"
        },
        {
            "o": "Osterheide",
            "l": "Niedersachsen"
        }
    ],
    "29690": [
        {
            "o": "Buchholz (Aller)",
            "l": "Niedersachsen"
        },
        {
            "o": "Essel",
            "l": "Niedersachsen"
        },
        {
            "o": "Gilten",
            "l": "Niedersachsen"
        },
        {
            "o": "Grethem",
            "l": "Niedersachsen"
        },
        {
            "o": "Lindwedel",
            "l": "Niedersachsen"
        },
        {
            "o": "Schwarmstedt",
            "l": "Niedersachsen"
        }
    ],
    "29693": [
        {
            "o": "Ahlden",
            "l": "Niedersachsen"
        },
        {
            "o": "Böhme",
            "l": "Niedersachsen"
        },
        {
            "o": "Eickeloh",
            "l": "Niedersachsen"
        },
        {
            "o": "Hademstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Hodenhagen",
            "l": "Niedersachsen"
        },
        {
            "o": "Walsrode",
            "l": "Niedersachsen"
        }
    ],
    "29699": [
        {
            "o": "Bomlitz",
            "l": "Niedersachsen"
        }
    ],
    "30159": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30161": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30163": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30165": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30167": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30169": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30171": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30173": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30175": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30177": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30179": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30419": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30449": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30451": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30453": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30455": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30457": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30459": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30519": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30521": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30539": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30559": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30625": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30627": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30629": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30655": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30657": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30659": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        }
    ],
    "30669": [
        {
            "o": "Hannover",
            "l": "Niedersachsen"
        },
        {
            "o": "Langenhagen",
            "l": "Niedersachsen"
        }
    ],
    "30823": [
        {
            "o": "Garbsen",
            "l": "Niedersachsen"
        }
    ],
    "30826": [
        {
            "o": "Garbsen",
            "l": "Niedersachsen"
        }
    ],
    "30827": [
        {
            "o": "Garbsen",
            "l": "Niedersachsen"
        }
    ],
    "30851": [
        {
            "o": "Langenhagen",
            "l": "Niedersachsen"
        }
    ],
    "30853": [
        {
            "o": "Langenhagen",
            "l": "Niedersachsen"
        }
    ],
    "30855": [
        {
            "o": "Langenhagen",
            "l": "Niedersachsen"
        }
    ],
    "30880": [
        {
            "o": "Laatzen",
            "l": "Niedersachsen"
        }
    ],
    "30890": [
        {
            "o": "Barsinghausen",
            "l": "Niedersachsen"
        }
    ],
    "30900": [
        {
            "o": "Wedemark",
            "l": "Niedersachsen"
        }
    ],
    "30916": [
        {
            "o": "Isernhagen",
            "l": "Niedersachsen"
        }
    ],
    "30926": [
        {
            "o": "Seelze",
            "l": "Niedersachsen"
        }
    ],
    "30938": [
        {
            "o": "Burgwedel",
            "l": "Niedersachsen"
        }
    ],
    "30952": [
        {
            "o": "Ronnenberg",
            "l": "Niedersachsen"
        }
    ],
    "30966": [
        {
            "o": "Hemmingen",
            "l": "Niedersachsen"
        }
    ],
    "30974": [
        {
            "o": "Wennigsen (Deister)",
            "l": "Niedersachsen"
        }
    ],
    "30982": [
        {
            "o": "Pattensen",
            "l": "Niedersachsen"
        }
    ],
    "30989": [
        {
            "o": "Gehrden",
            "l": "Niedersachsen"
        }
    ],
    "31008": [
        {
            "o": "Elze",
            "l": "Niedersachsen"
        }
    ],
    "31020": [
        {
            "o": "Salzhemmendorf",
            "l": "Niedersachsen"
        }
    ],
    "31028": [
        {
            "o": "Gronau",
            "l": "Niedersachsen"
        }
    ],
    "31036": [
        {
            "o": "Eime",
            "l": "Niedersachsen"
        }
    ],
    "31061": [
        {
            "o": "Alfeld (Leine)",
            "l": "Niedersachsen"
        }
    ],
    "31073": [
        {
            "o": "Delligsen",
            "l": "Niedersachsen"
        }
    ],
    "31079": [
        {
            "o": "Sibbesse",
            "l": "Niedersachsen"
        }
    ],
    "31084": [
        {
            "o": "Freden (Leine)",
            "l": "Niedersachsen"
        }
    ],
    "31089": [
        {
            "o": "Duingen",
            "l": "Niedersachsen"
        }
    ],
    "31134": [
        {
            "o": "Hildesheim",
            "l": "Niedersachsen"
        }
    ],
    "31135": [
        {
            "o": "Hildesheim",
            "l": "Niedersachsen"
        }
    ],
    "31137": [
        {
            "o": "Hildesheim",
            "l": "Niedersachsen"
        }
    ],
    "31139": [
        {
            "o": "Hildesheim",
            "l": "Niedersachsen"
        }
    ],
    "31141": [
        {
            "o": "Hildesheim",
            "l": "Niedersachsen"
        }
    ],
    "31157": [
        {
            "o": "Sarstedt",
            "l": "Niedersachsen"
        }
    ],
    "31162": [
        {
            "o": "Bad Salzdetfurth",
            "l": "Niedersachsen"
        }
    ],
    "31167": [
        {
            "o": "Bockenem",
            "l": "Niedersachsen"
        }
    ],
    "31171": [
        {
            "o": "Nordstemmen",
            "l": "Niedersachsen"
        }
    ],
    "31174": [
        {
            "o": "Schellerten",
            "l": "Niedersachsen"
        }
    ],
    "31177": [
        {
            "o": "Harsum",
            "l": "Niedersachsen"
        }
    ],
    "31180": [
        {
            "o": "Giesen",
            "l": "Niedersachsen"
        }
    ],
    "31185": [
        {
            "o": "Söhlde",
            "l": "Niedersachsen"
        }
    ],
    "31188": [
        {
            "o": "Holle",
            "l": "Niedersachsen"
        }
    ],
    "31191": [
        {
            "o": "Algermissen",
            "l": "Niedersachsen"
        }
    ],
    "31195": [
        {
            "o": "Lamspringe",
            "l": "Niedersachsen"
        }
    ],
    "31199": [
        {
            "o": "Diekholzen",
            "l": "Niedersachsen"
        }
    ],
    "31224": [
        {
            "o": "Peine",
            "l": "Niedersachsen"
        }
    ],
    "31226": [
        {
            "o": "Peine",
            "l": "Niedersachsen"
        }
    ],
    "31228": [
        {
            "o": "Peine",
            "l": "Niedersachsen"
        }
    ],
    "31234": [
        {
            "o": "Edemissen",
            "l": "Niedersachsen"
        }
    ],
    "31241": [
        {
            "o": "Ilsede",
            "l": "Niedersachsen"
        }
    ],
    "31246": [
        {
            "o": "Ilsede",
            "l": "Niedersachsen"
        }
    ],
    "31249": [
        {
            "o": "Hohenhameln",
            "l": "Niedersachsen"
        }
    ],
    "31275": [
        {
            "o": "Lehrte",
            "l": "Niedersachsen"
        }
    ],
    "31303": [
        {
            "o": "Burgdorf",
            "l": "Niedersachsen"
        }
    ],
    "31311": [
        {
            "o": "Uetze",
            "l": "Niedersachsen"
        }
    ],
    "31319": [
        {
            "o": "Sehnde",
            "l": "Niedersachsen"
        }
    ],
    "31515": [
        {
            "o": "Wunstorf",
            "l": "Niedersachsen"
        }
    ],
    "31535": [
        {
            "o": "Neustadt am Rübenberge",
            "l": "Niedersachsen"
        }
    ],
    "31542": [
        {
            "o": "Bad Nenndorf",
            "l": "Niedersachsen"
        }
    ],
    "31547": [
        {
            "o": "Rehburg-Loccum",
            "l": "Niedersachsen"
        }
    ],
    "31552": [
        {
            "o": "Apelern",
            "l": "Niedersachsen"
        },
        {
            "o": "Rodenberg",
            "l": "Niedersachsen"
        }
    ],
    "31553": [
        {
            "o": "Auhagen",
            "l": "Niedersachsen"
        },
        {
            "o": "Sachsenhagen",
            "l": "Niedersachsen"
        }
    ],
    "31555": [
        {
            "o": "Suthfeld",
            "l": "Niedersachsen"
        }
    ],
    "31556": [
        {
            "o": "Wölpinghausen",
            "l": "Niedersachsen"
        }
    ],
    "31558": [
        {
            "o": "Hagenburg",
            "l": "Niedersachsen"
        }
    ],
    "31559": [
        {
            "o": "Haste",
            "l": "Niedersachsen"
        },
        {
            "o": "Hohnhorst",
            "l": "Niedersachsen"
        }
    ],
    "31582": [
        {
            "o": "Nienburg/Weser",
            "l": "Niedersachsen"
        }
    ],
    "31592": [
        {
            "o": "Stolzenau",
            "l": "Niedersachsen"
        }
    ],
    "31595": [
        {
            "o": "Steyerberg",
            "l": "Niedersachsen"
        }
    ],
    "31600": [
        {
            "o": "Uchte",
            "l": "Niedersachsen"
        }
    ],
    "31603": [
        {
            "o": "Diepenau",
            "l": "Niedersachsen"
        }
    ],
    "31604": [
        {
            "o": "Raddestorf",
            "l": "Niedersachsen"
        }
    ],
    "31606": [
        {
            "o": "Warmsen",
            "l": "Niedersachsen"
        }
    ],
    "31608": [
        {
            "o": "Marklohe",
            "l": "Niedersachsen"
        }
    ],
    "31609": [
        {
            "o": "Balge",
            "l": "Niedersachsen"
        }
    ],
    "31613": [
        {
            "o": "Wietzen",
            "l": "Niedersachsen"
        }
    ],
    "31618": [
        {
            "o": "Liebenau",
            "l": "Niedersachsen"
        }
    ],
    "31619": [
        {
            "o": "Binnen",
            "l": "Niedersachsen"
        }
    ],
    "31621": [
        {
            "o": "Pennigsehl",
            "l": "Niedersachsen"
        }
    ],
    "31622": [
        {
            "o": "Heemsen",
            "l": "Niedersachsen"
        }
    ],
    "31623": [
        {
            "o": "Drakenburg",
            "l": "Niedersachsen"
        }
    ],
    "31626": [
        {
            "o": "Haßbergen",
            "l": "Niedersachsen"
        }
    ],
    "31627": [
        {
            "o": "Rohrsen",
            "l": "Niedersachsen"
        }
    ],
    "31628": [
        {
            "o": "Landesbergen",
            "l": "Niedersachsen"
        }
    ],
    "31629": [
        {
            "o": "Estorf",
            "l": "Niedersachsen"
        }
    ],
    "31632": [
        {
            "o": "Husum",
            "l": "Niedersachsen"
        }
    ],
    "31633": [
        {
            "o": "Leese",
            "l": "Niedersachsen"
        }
    ],
    "31634": [
        {
            "o": "Steimbke",
            "l": "Niedersachsen"
        }
    ],
    "31636": [
        {
            "o": "Linsburg",
            "l": "Niedersachsen"
        }
    ],
    "31637": [
        {
            "o": "Rodewald",
            "l": "Niedersachsen"
        }
    ],
    "31638": [
        {
            "o": "Stöckse",
            "l": "Niedersachsen"
        }
    ],
    "31655": [
        {
            "o": "Stadthagen",
            "l": "Niedersachsen"
        }
    ],
    "31675": [
        {
            "o": "Bückeburg",
            "l": "Niedersachsen"
        }
    ],
    "31683": [
        {
            "o": "Obernkirchen",
            "l": "Niedersachsen"
        }
    ],
    "31688": [
        {
            "o": "Nienstädt",
            "l": "Niedersachsen"
        }
    ],
    "31691": [
        {
            "o": "Helpsen",
            "l": "Niedersachsen"
        },
        {
            "o": "Seggebruch",
            "l": "Niedersachsen"
        }
    ],
    "31693": [
        {
            "o": "Hespe",
            "l": "Niedersachsen"
        }
    ],
    "31698": [
        {
            "o": "Lindhorst",
            "l": "Niedersachsen"
        }
    ],
    "31699": [
        {
            "o": "Beckedorf",
            "l": "Niedersachsen"
        }
    ],
    "31700": [
        {
            "o": "Heuerßen",
            "l": "Niedersachsen"
        }
    ],
    "31702": [
        {
            "o": "Lüdersfeld",
            "l": "Niedersachsen"
        }
    ],
    "31707": [
        {
            "o": "Bad Eilsen",
            "l": "Niedersachsen"
        },
        {
            "o": "Heeßen",
            "l": "Niedersachsen"
        }
    ],
    "31708": [
        {
            "o": "Ahnsen",
            "l": "Niedersachsen"
        }
    ],
    "31710": [
        {
            "o": "Buchholz",
            "l": "Niedersachsen"
        }
    ],
    "31711": [
        {
            "o": "Luhden",
            "l": "Niedersachsen"
        }
    ],
    "31712": [
        {
            "o": "Niedernwöhren",
            "l": "Niedersachsen"
        }
    ],
    "31714": [
        {
            "o": "Lauenhagen",
            "l": "Niedersachsen"
        }
    ],
    "31715": [
        {
            "o": "Meerbeck",
            "l": "Niedersachsen"
        }
    ],
    "31717": [
        {
            "o": "Nordsehl",
            "l": "Niedersachsen"
        }
    ],
    "31718": [
        {
            "o": "Pollhagen",
            "l": "Niedersachsen"
        }
    ],
    "31719": [
        {
            "o": "Wiedensahl",
            "l": "Niedersachsen"
        }
    ],
    "31737": [
        {
            "o": "Rinteln",
            "l": "Niedersachsen"
        }
    ],
    "31749": [
        {
            "o": "Auetal",
            "l": "Niedersachsen"
        }
    ],
    "31785": [
        {
            "o": "Hameln",
            "l": "Niedersachsen"
        }
    ],
    "31787": [
        {
            "o": "Hameln",
            "l": "Niedersachsen"
        }
    ],
    "31789": [
        {
            "o": "Hameln",
            "l": "Niedersachsen"
        }
    ],
    "31812": [
        {
            "o": "Bad Pyrmont",
            "l": "Niedersachsen"
        }
    ],
    "31832": [
        {
            "o": "Springe",
            "l": "Niedersachsen"
        }
    ],
    "31840": [
        {
            "o": "Hessisch Oldendorf",
            "l": "Niedersachsen"
        }
    ],
    "31848": [
        {
            "o": "Bad Münder am Deister",
            "l": "Niedersachsen"
        }
    ],
    "31855": [
        {
            "o": "Aerzen",
            "l": "Niedersachsen"
        }
    ],
    "31860": [
        {
            "o": "Emmerthal",
            "l": "Niedersachsen"
        }
    ],
    "31863": [
        {
            "o": "Coppenbrügge",
            "l": "Niedersachsen"
        }
    ],
    "31867": [
        {
            "o": "Hülsede",
            "l": "Niedersachsen"
        },
        {
            "o": "Lauenau",
            "l": "Niedersachsen"
        },
        {
            "o": "Messenkamp",
            "l": "Niedersachsen"
        },
        {
            "o": "Pohle",
            "l": "Niedersachsen"
        }
    ],
    "31868": [
        {
            "o": "Ottenstein",
            "l": "Niedersachsen"
        }
    ],
    "32049": [
        {
            "o": "Herford",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32051": [
        {
            "o": "Herford",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32052": [
        {
            "o": "Herford",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32105": [
        {
            "o": "Bad Salzuflen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32107": [
        {
            "o": "Bad Salzuflen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32108": [
        {
            "o": "Bad Salzuflen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32120": [
        {
            "o": "Hiddenhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32130": [
        {
            "o": "Enger",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32139": [
        {
            "o": "Spenge",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32257": [
        {
            "o": "Bünde",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32278": [
        {
            "o": "Kirchlengern",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32289": [
        {
            "o": "Rödinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32312": [
        {
            "o": "Lübbecke",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32339": [
        {
            "o": "Espelkamp",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32351": [
        {
            "o": "Stemwede",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32361": [
        {
            "o": "Preußisch Oldendorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32369": [
        {
            "o": "Rahden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32423": [
        {
            "o": "Minden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32425": [
        {
            "o": "Minden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32427": [
        {
            "o": "Minden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32429": [
        {
            "o": "Minden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32457": [
        {
            "o": "Porta Westfalica",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32469": [
        {
            "o": "Petershagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32479": [
        {
            "o": "Hille",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32545": [
        {
            "o": "Bad Oeynhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32547": [
        {
            "o": "Bad Oeynhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32549": [
        {
            "o": "Bad Oeynhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32584": [
        {
            "o": "Löhne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32602": [
        {
            "o": "Vlotho",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32609": [
        {
            "o": "Hüllhorst",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32657": [
        {
            "o": "Lemgo",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32676": [
        {
            "o": "Lügde",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32683": [
        {
            "o": "Barntrup",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32689": [
        {
            "o": "Kalletal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32694": [
        {
            "o": "Dörentrup",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32699": [
        {
            "o": "Extertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32756": [
        {
            "o": "Detmold",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32758": [
        {
            "o": "Detmold",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32760": [
        {
            "o": "Detmold",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32791": [
        {
            "o": "Lage",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32805": [
        {
            "o": "Horn-Bad Meinberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32816": [
        {
            "o": "Schieder-Schwalenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32825": [
        {
            "o": "Blomberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32832": [
        {
            "o": "Augustdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "32839": [
        {
            "o": "Steinheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33014": [
        {
            "o": "Bad Driburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33034": [
        {
            "o": "Brakel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33039": [
        {
            "o": "Nieheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33098": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33100": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33102": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33104": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33106": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33129": [
        {
            "o": "Delbrück",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33142": [
        {
            "o": "Büren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33154": [
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Salzkotten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33161": [
        {
            "o": "Hövelhof",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Paderborn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33165": [
        {
            "o": "Lichtenau",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33175": [
        {
            "o": "Bad Lippspringe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33178": [
        {
            "o": "Borchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33181": [
        {
            "o": "Bad Wünnenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33184": [
        {
            "o": "Altenbeken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33189": [
        {
            "o": "Schlangen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33330": [
        {
            "o": "Gütersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33332": [
        {
            "o": "Gütersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33333": [
        {
            "o": "Gütersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33334": [
        {
            "o": "Gütersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33335": [
        {
            "o": "Gütersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33378": [
        {
            "o": "Rheda-Wiedenbrück",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33397": [
        {
            "o": "Rietberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33415": [
        {
            "o": "Verl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33428": [
        {
            "o": "Harsewinkel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33442": [
        {
            "o": "Herzebrock-Clarholz",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33449": [
        {
            "o": "Langenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33602": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33604": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33605": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33607": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33609": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33611": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33613": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33615": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33617": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33619": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33647": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33649": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33659": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33689": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33699": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33719": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33729": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33739": [
        {
            "o": "Bielefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33758": [
        {
            "o": "Schloß Holte-Stukenbrock",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33775": [
        {
            "o": "Versmold",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33790": [
        {
            "o": "Halle (Westf.)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33803": [
        {
            "o": "Steinhagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33813": [
        {
            "o": "Oerlinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33818": [
        {
            "o": "Leopoldshöhe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33824": [
        {
            "o": "Werther",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "33829": [
        {
            "o": "Borgholzhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "34117": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34119": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34121": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34123": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34125": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34127": [
        {
            "o": "Fuldatal",
            "l": "Hessen"
        },
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34128": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34130": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34131": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34132": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34134": [
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34212": [
        {
            "o": "Melsungen",
            "l": "Hessen"
        }
    ],
    "34225": [
        {
            "o": "Baunatal",
            "l": "Hessen"
        }
    ],
    "34233": [
        {
            "o": "Fuldatal",
            "l": "Hessen"
        },
        {
            "o": "Kassel",
            "l": "Hessen"
        }
    ],
    "34246": [
        {
            "o": "Vellmar",
            "l": "Hessen"
        }
    ],
    "34253": [
        {
            "o": "Lohfelden",
            "l": "Hessen"
        }
    ],
    "34260": [
        {
            "o": "Kaufungen",
            "l": "Hessen"
        }
    ],
    "34266": [
        {
            "o": "Niestetal",
            "l": "Hessen"
        }
    ],
    "34270": [
        {
            "o": "Schauenburg",
            "l": "Hessen"
        }
    ],
    "34277": [
        {
            "o": "Fuldabrück",
            "l": "Hessen"
        }
    ],
    "34281": [
        {
            "o": "Gudensberg",
            "l": "Hessen"
        }
    ],
    "34286": [
        {
            "o": "Spangenberg",
            "l": "Hessen"
        }
    ],
    "34289": [
        {
            "o": "Zierenberg",
            "l": "Hessen"
        }
    ],
    "34292": [
        {
            "o": "Ahnatal",
            "l": "Hessen"
        }
    ],
    "34295": [
        {
            "o": "Edermünde",
            "l": "Hessen"
        }
    ],
    "34298": [
        {
            "o": "Helsa",
            "l": "Hessen"
        }
    ],
    "34302": [
        {
            "o": "Guxhagen",
            "l": "Hessen"
        }
    ],
    "34305": [
        {
            "o": "Niedenstein",
            "l": "Hessen"
        }
    ],
    "34308": [
        {
            "o": "Bad Emstal",
            "l": "Hessen"
        }
    ],
    "34311": [
        {
            "o": "Naumburg",
            "l": "Hessen"
        }
    ],
    "34314": [
        {
            "o": "Espenau",
            "l": "Hessen"
        }
    ],
    "34317": [
        {
            "o": "Habichtswald",
            "l": "Hessen"
        }
    ],
    "34320": [
        {
            "o": "Söhrewald",
            "l": "Hessen"
        }
    ],
    "34323": [
        {
            "o": "Malsfeld",
            "l": "Hessen"
        }
    ],
    "34326": [
        {
            "o": "Morschen",
            "l": "Hessen"
        }
    ],
    "34327": [
        {
            "o": "Körle",
            "l": "Hessen"
        }
    ],
    "34329": [
        {
            "o": "Nieste",
            "l": "Hessen"
        }
    ],
    "34346": [
        {
            "o": "Hann. Münden",
            "l": "Niedersachsen"
        }
    ],
    "34355": [
        {
            "o": "Kassel",
            "l": "Hessen"
        },
        {
            "o": "Staufenberg",
            "l": "Niedersachsen"
        }
    ],
    "34359": [
        {
            "o": "Reinhardshagen",
            "l": "Hessen"
        }
    ],
    "34369": [
        {
            "o": "Hofgeismar",
            "l": "Hessen"
        }
    ],
    "34376": [
        {
            "o": "Immenhausen",
            "l": "Hessen"
        }
    ],
    "34379": [
        {
            "o": "Calden",
            "l": "Hessen"
        }
    ],
    "34385": [
        {
            "o": "Bad Karlshafen",
            "l": "Hessen"
        }
    ],
    "34388": [
        {
            "o": "Trendelburg",
            "l": "Hessen"
        }
    ],
    "34393": [
        {
            "o": "Grebenstein",
            "l": "Hessen"
        }
    ],
    "34396": [
        {
            "o": "Liebenau",
            "l": "Hessen"
        }
    ],
    "34399": [
        {
            "o": "Oberweser",
            "l": "Hessen"
        }
    ],
    "34414": [
        {
            "o": "Warburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "34431": [
        {
            "o": "Marsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "34434": [
        {
            "o": "Borgentreich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "34439": [
        {
            "o": "Willebadessen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "34454": [
        {
            "o": "Bad Arolsen",
            "l": "Hessen"
        }
    ],
    "34466": [
        {
            "o": "Wolfhagen",
            "l": "Hessen"
        }
    ],
    "34471": [
        {
            "o": "Volkmarsen",
            "l": "Hessen"
        }
    ],
    "34474": [
        {
            "o": "Diemelstadt",
            "l": "Hessen"
        }
    ],
    "34477": [
        {
            "o": "Twistetal",
            "l": "Hessen"
        }
    ],
    "34479": [
        {
            "o": "Breuna",
            "l": "Hessen"
        }
    ],
    "34497": [
        {
            "o": "Korbach",
            "l": "Hessen"
        }
    ],
    "34508": [
        {
            "o": "Willingen (Upland)",
            "l": "Hessen"
        }
    ],
    "34513": [
        {
            "o": "Korbach",
            "l": "Hessen"
        },
        {
            "o": "Waldeck",
            "l": "Hessen"
        }
    ],
    "34516": [
        {
            "o": "Vöhl",
            "l": "Hessen"
        }
    ],
    "34519": [
        {
            "o": "Diemelsee",
            "l": "Hessen"
        }
    ],
    "34537": [
        {
            "o": "Bad Wildungen",
            "l": "Hessen"
        }
    ],
    "34549": [
        {
            "o": "Edertal",
            "l": "Hessen"
        }
    ],
    "34560": [
        {
            "o": "Fritzlar",
            "l": "Hessen"
        }
    ],
    "34576": [
        {
            "o": "Homberg",
            "l": "Hessen"
        }
    ],
    "34582": [
        {
            "o": "Borken",
            "l": "Hessen"
        }
    ],
    "34587": [
        {
            "o": "Felsberg",
            "l": "Hessen"
        }
    ],
    "34590": [
        {
            "o": "Wabern",
            "l": "Hessen"
        }
    ],
    "34593": [
        {
            "o": "Knüllwald",
            "l": "Hessen"
        }
    ],
    "34596": [
        {
            "o": "Bad Zwesten",
            "l": "Hessen"
        }
    ],
    "34599": [
        {
            "o": "Neuental",
            "l": "Hessen"
        }
    ],
    "34613": [
        {
            "o": "Schwalmstadt",
            "l": "Hessen"
        }
    ],
    "34621": [
        {
            "o": "Frielendorf",
            "l": "Hessen"
        }
    ],
    "34626": [
        {
            "o": "Neukirchen",
            "l": "Hessen"
        }
    ],
    "34628": [
        {
            "o": "Willingshausen",
            "l": "Hessen"
        }
    ],
    "34630": [
        {
            "o": "Gilserberg",
            "l": "Hessen"
        }
    ],
    "34632": [
        {
            "o": "Jesberg",
            "l": "Hessen"
        }
    ],
    "34633": [
        {
            "o": "Ottrau",
            "l": "Hessen"
        }
    ],
    "34637": [
        {
            "o": "Schrecksbach",
            "l": "Hessen"
        }
    ],
    "34639": [
        {
            "o": "Schwarzenborn",
            "l": "Hessen"
        }
    ],
    "35037": [
        {
            "o": "Marburg",
            "l": "Hessen"
        }
    ],
    "35039": [
        {
            "o": "Marburg",
            "l": "Hessen"
        }
    ],
    "35041": [
        {
            "o": "Marburg",
            "l": "Hessen"
        }
    ],
    "35043": [
        {
            "o": "Marburg",
            "l": "Hessen"
        }
    ],
    "35066": [
        {
            "o": "Frankenberg",
            "l": "Hessen"
        }
    ],
    "35075": [
        {
            "o": "Gladenbach",
            "l": "Hessen"
        }
    ],
    "35080": [
        {
            "o": "Bad Endbach",
            "l": "Hessen"
        }
    ],
    "35083": [
        {
            "o": "Wetter",
            "l": "Hessen"
        }
    ],
    "35085": [
        {
            "o": "Ebsdorfergrund",
            "l": "Hessen"
        }
    ],
    "35088": [
        {
            "o": "Battenberg",
            "l": "Hessen"
        }
    ],
    "35091": [
        {
            "o": "Cölbe",
            "l": "Hessen"
        }
    ],
    "35094": [
        {
            "o": "Lahntal",
            "l": "Hessen"
        },
        {
            "o": "Marburg",
            "l": "Hessen"
        }
    ],
    "35096": [
        {
            "o": "Weimar (Lahn)",
            "l": "Hessen"
        }
    ],
    "35099": [
        {
            "o": "Burgwald",
            "l": "Hessen"
        }
    ],
    "35102": [
        {
            "o": "Lohra",
            "l": "Hessen"
        }
    ],
    "35104": [
        {
            "o": "Lichtenfels",
            "l": "Hessen"
        }
    ],
    "35108": [
        {
            "o": "Allendorf",
            "l": "Hessen"
        }
    ],
    "35110": [
        {
            "o": "Frankenau",
            "l": "Hessen"
        }
    ],
    "35112": [
        {
            "o": "Fronhausen",
            "l": "Hessen"
        }
    ],
    "35114": [
        {
            "o": "Haina",
            "l": "Hessen"
        }
    ],
    "35116": [
        {
            "o": "Hatzfeld",
            "l": "Hessen"
        }
    ],
    "35117": [
        {
            "o": "Münchhausen",
            "l": "Hessen"
        }
    ],
    "35119": [
        {
            "o": "Rosenthal",
            "l": "Hessen"
        }
    ],
    "35216": [
        {
            "o": "Biedenkopf",
            "l": "Hessen"
        }
    ],
    "35232": [
        {
            "o": "Dautphetal",
            "l": "Hessen"
        }
    ],
    "35236": [
        {
            "o": "Breidenbach",
            "l": "Hessen"
        }
    ],
    "35239": [
        {
            "o": "Steffenberg",
            "l": "Hessen"
        }
    ],
    "35260": [
        {
            "o": "Stadtallendorf",
            "l": "Hessen"
        }
    ],
    "35274": [
        {
            "o": "Kirchhain",
            "l": "Hessen"
        }
    ],
    "35279": [
        {
            "o": "Neustadt",
            "l": "Hessen"
        }
    ],
    "35282": [
        {
            "o": "Rauschenberg",
            "l": "Hessen"
        }
    ],
    "35285": [
        {
            "o": "Gemünden",
            "l": "Hessen"
        }
    ],
    "35287": [
        {
            "o": "Amöneburg",
            "l": "Hessen"
        }
    ],
    "35288": [
        {
            "o": "Wohratal",
            "l": "Hessen"
        }
    ],
    "35305": [
        {
            "o": "Grünberg",
            "l": "Hessen"
        }
    ],
    "35315": [
        {
            "o": "Homberg (Ohm)",
            "l": "Hessen"
        }
    ],
    "35321": [
        {
            "o": "Laubach",
            "l": "Hessen"
        }
    ],
    "35325": [
        {
            "o": "Mücke",
            "l": "Hessen"
        }
    ],
    "35327": [
        {
            "o": "Ulrichstein",
            "l": "Hessen"
        }
    ],
    "35329": [
        {
            "o": "Gemünden (Felda)",
            "l": "Hessen"
        }
    ],
    "35390": [
        {
            "o": "Gießen",
            "l": "Hessen"
        }
    ],
    "35392": [
        {
            "o": "Gießen",
            "l": "Hessen"
        }
    ],
    "35394": [
        {
            "o": "Gießen",
            "l": "Hessen"
        }
    ],
    "35396": [
        {
            "o": "Gießen",
            "l": "Hessen"
        }
    ],
    "35398": [
        {
            "o": "Gießen",
            "l": "Hessen"
        }
    ],
    "35410": [
        {
            "o": "Hungen",
            "l": "Hessen"
        }
    ],
    "35415": [
        {
            "o": "Pohlheim",
            "l": "Hessen"
        }
    ],
    "35418": [
        {
            "o": "Buseck",
            "l": "Hessen"
        }
    ],
    "35423": [
        {
            "o": "Lich",
            "l": "Hessen"
        }
    ],
    "35428": [
        {
            "o": "Langgöns",
            "l": "Hessen"
        }
    ],
    "35435": [
        {
            "o": "Wettenberg",
            "l": "Hessen"
        }
    ],
    "35440": [
        {
            "o": "Linden",
            "l": "Hessen"
        }
    ],
    "35444": [
        {
            "o": "Biebertal",
            "l": "Hessen"
        }
    ],
    "35447": [
        {
            "o": "Reiskirchen",
            "l": "Hessen"
        }
    ],
    "35452": [
        {
            "o": "Heuchelheim",
            "l": "Hessen"
        }
    ],
    "35457": [
        {
            "o": "Lollar",
            "l": "Hessen"
        }
    ],
    "35460": [
        {
            "o": "Staufenberg",
            "l": "Hessen"
        }
    ],
    "35463": [
        {
            "o": "Fernwald",
            "l": "Hessen"
        }
    ],
    "35466": [
        {
            "o": "Rabenau",
            "l": "Hessen"
        }
    ],
    "35469": [
        {
            "o": "Allendorf (Lumda)",
            "l": "Hessen"
        }
    ],
    "35510": [
        {
            "o": "Butzbach",
            "l": "Hessen"
        }
    ],
    "35516": [
        {
            "o": "Münzenberg",
            "l": "Hessen"
        }
    ],
    "35519": [
        {
            "o": "Rockenberg",
            "l": "Hessen"
        }
    ],
    "35576": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35578": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35579": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35580": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35581": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35582": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35583": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35584": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35585": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35586": [
        {
            "o": "Wetzlar",
            "l": "Hessen"
        }
    ],
    "35606": [
        {
            "o": "Solms",
            "l": "Hessen"
        }
    ],
    "35614": [
        {
            "o": "Aßlar",
            "l": "Hessen"
        }
    ],
    "35619": [
        {
            "o": "Braunfels",
            "l": "Hessen"
        }
    ],
    "35625": [
        {
            "o": "Hüttenberg",
            "l": "Hessen"
        }
    ],
    "35630": [
        {
            "o": "Ehringshausen",
            "l": "Hessen"
        }
    ],
    "35633": [
        {
            "o": "Lahnau",
            "l": "Hessen"
        }
    ],
    "35638": [
        {
            "o": "Leun",
            "l": "Hessen"
        }
    ],
    "35641": [
        {
            "o": "Schöffengrund",
            "l": "Hessen"
        }
    ],
    "35644": [
        {
            "o": "Hohenahr",
            "l": "Hessen"
        }
    ],
    "35647": [
        {
            "o": "Waldsolms",
            "l": "Hessen"
        }
    ],
    "35649": [
        {
            "o": "Bischoffen",
            "l": "Hessen"
        }
    ],
    "35683": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35684": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35685": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35686": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35687": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35688": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35689": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35690": [
        {
            "o": "Dillenburg",
            "l": "Hessen"
        }
    ],
    "35708": [
        {
            "o": "Haiger",
            "l": "Hessen"
        }
    ],
    "35713": [
        {
            "o": "Eschenburg",
            "l": "Hessen"
        }
    ],
    "35716": [
        {
            "o": "Dietzhölztal",
            "l": "Hessen"
        }
    ],
    "35719": [
        {
            "o": "Angelburg",
            "l": "Hessen"
        }
    ],
    "35745": [
        {
            "o": "Herborn",
            "l": "Hessen"
        }
    ],
    "35753": [
        {
            "o": "Greifenstein",
            "l": "Hessen"
        }
    ],
    "35756": [
        {
            "o": "Mittenaar",
            "l": "Hessen"
        }
    ],
    "35759": [
        {
            "o": "Driedorf",
            "l": "Hessen"
        }
    ],
    "35764": [
        {
            "o": "Sinn",
            "l": "Hessen"
        }
    ],
    "35767": [
        {
            "o": "Breitscheid",
            "l": "Hessen"
        }
    ],
    "35768": [
        {
            "o": "Siegbach",
            "l": "Hessen"
        }
    ],
    "35781": [
        {
            "o": "Weilburg",
            "l": "Hessen"
        }
    ],
    "35789": [
        {
            "o": "Weilmünster",
            "l": "Hessen"
        }
    ],
    "35792": [
        {
            "o": "Löhnberg",
            "l": "Hessen"
        }
    ],
    "35794": [
        {
            "o": "Mengerskirchen",
            "l": "Hessen"
        }
    ],
    "35796": [
        {
            "o": "Weinbach",
            "l": "Hessen"
        }
    ],
    "35799": [
        {
            "o": "Merenberg",
            "l": "Hessen"
        }
    ],
    "36037": [
        {
            "o": "Fulda",
            "l": "Hessen"
        }
    ],
    "36039": [
        {
            "o": "Fulda",
            "l": "Hessen"
        }
    ],
    "36041": [
        {
            "o": "Fulda",
            "l": "Hessen"
        }
    ],
    "36043": [
        {
            "o": "Fulda",
            "l": "Hessen"
        }
    ],
    "36088": [
        {
            "o": "Hünfeld",
            "l": "Hessen"
        }
    ],
    "36093": [
        {
            "o": "Künzell",
            "l": "Hessen"
        }
    ],
    "36100": [
        {
            "o": "Petersberg",
            "l": "Hessen"
        }
    ],
    "36103": [
        {
            "o": "Flieden",
            "l": "Hessen"
        }
    ],
    "36110": [
        {
            "o": "Schlitz",
            "l": "Hessen"
        }
    ],
    "36115": [
        {
            "o": "Ehrenberg",
            "l": "Hessen"
        },
        {
            "o": "Hilders",
            "l": "Hessen"
        }
    ],
    "36119": [
        {
            "o": "Neuhof",
            "l": "Hessen"
        }
    ],
    "36124": [
        {
            "o": "Eichenzell",
            "l": "Hessen"
        }
    ],
    "36129": [
        {
            "o": "Gersfeld",
            "l": "Hessen"
        }
    ],
    "36132": [
        {
            "o": "Eiterfeld",
            "l": "Hessen"
        }
    ],
    "36137": [
        {
            "o": "Großenlüder",
            "l": "Hessen"
        }
    ],
    "36142": [
        {
            "o": "Tann",
            "l": "Hessen"
        }
    ],
    "36145": [
        {
            "o": "Hofbieber",
            "l": "Hessen"
        }
    ],
    "36148": [
        {
            "o": "Kalbach",
            "l": "Hessen"
        }
    ],
    "36151": [
        {
            "o": "Burghaun",
            "l": "Hessen"
        }
    ],
    "36154": [
        {
            "o": "Hosenfeld",
            "l": "Hessen"
        }
    ],
    "36157": [
        {
            "o": "Ebersburg",
            "l": "Hessen"
        }
    ],
    "36160": [
        {
            "o": "Dipperz",
            "l": "Hessen"
        }
    ],
    "36163": [
        {
            "o": "Poppenhausen",
            "l": "Hessen"
        }
    ],
    "36166": [
        {
            "o": "Haunetal",
            "l": "Hessen"
        }
    ],
    "36167": [
        {
            "o": "Nüsttal",
            "l": "Hessen"
        }
    ],
    "36169": [
        {
            "o": "Rasdorf",
            "l": "Hessen"
        }
    ],
    "36179": [
        {
            "o": "Bebra",
            "l": "Hessen"
        }
    ],
    "36199": [
        {
            "o": "Rotenburg an der Fulda",
            "l": "Hessen"
        }
    ],
    "36205": [
        {
            "o": "Sontra",
            "l": "Hessen"
        }
    ],
    "36208": [
        {
            "o": "Wildeck",
            "l": "Hessen"
        }
    ],
    "36211": [
        {
            "o": "Alheim",
            "l": "Hessen"
        }
    ],
    "36214": [
        {
            "o": "Nentershausen",
            "l": "Hessen"
        }
    ],
    "36217": [
        {
            "o": "Ronshausen",
            "l": "Hessen"
        }
    ],
    "36219": [
        {
            "o": "Cornberg",
            "l": "Hessen"
        }
    ],
    "36251": [
        {
            "o": "Bad Hersfeld",
            "l": "Hessen"
        },
        {
            "o": "Ludwigsau",
            "l": "Hessen"
        }
    ],
    "36266": [
        {
            "o": "Heringen",
            "l": "Hessen"
        }
    ],
    "36269": [
        {
            "o": "Philippsthal",
            "l": "Hessen"
        }
    ],
    "36272": [
        {
            "o": "Niederaula",
            "l": "Hessen"
        }
    ],
    "36275": [
        {
            "o": "Kirchheim",
            "l": "Hessen"
        }
    ],
    "36277": [
        {
            "o": "Schenklengsfeld",
            "l": "Hessen"
        }
    ],
    "36280": [
        {
            "o": "Oberaula",
            "l": "Hessen"
        }
    ],
    "36282": [
        {
            "o": "Hauneck",
            "l": "Hessen"
        }
    ],
    "36284": [
        {
            "o": "Hohenroda",
            "l": "Hessen"
        }
    ],
    "36286": [
        {
            "o": "Neuenstein",
            "l": "Hessen"
        }
    ],
    "36287": [
        {
            "o": "Breitenbach am Herzberg",
            "l": "Hessen"
        }
    ],
    "36289": [
        {
            "o": "Friedewald",
            "l": "Hessen"
        }
    ],
    "36304": [
        {
            "o": "Alsfeld",
            "l": "Hessen"
        }
    ],
    "36318": [
        {
            "o": "Schwalmtal",
            "l": "Hessen"
        }
    ],
    "36320": [
        {
            "o": "Kirtorf",
            "l": "Hessen"
        }
    ],
    "36323": [
        {
            "o": "Grebenau",
            "l": "Hessen"
        }
    ],
    "36325": [
        {
            "o": "Feldatal",
            "l": "Hessen"
        }
    ],
    "36326": [
        {
            "o": "Antrifttal",
            "l": "Hessen"
        }
    ],
    "36329": [
        {
            "o": "Romrod",
            "l": "Hessen"
        }
    ],
    "36341": [
        {
            "o": "Lauterbach",
            "l": "Hessen"
        }
    ],
    "36355": [
        {
            "o": "Grebenhain",
            "l": "Hessen"
        }
    ],
    "36358": [
        {
            "o": "Herbstein",
            "l": "Hessen"
        }
    ],
    "36364": [
        {
            "o": "Bad Salzschlirf",
            "l": "Hessen"
        }
    ],
    "36367": [
        {
            "o": "Wartenberg",
            "l": "Hessen"
        }
    ],
    "36369": [
        {
            "o": "Lautertal",
            "l": "Hessen"
        }
    ],
    "36381": [
        {
            "o": "Schlüchtern",
            "l": "Hessen"
        }
    ],
    "36391": [
        {
            "o": "Sinntal",
            "l": "Hessen"
        }
    ],
    "36396": [
        {
            "o": "Steinau an der Straße",
            "l": "Hessen"
        }
    ],
    "36399": [
        {
            "o": "Freiensteinau",
            "l": "Hessen"
        }
    ],
    "36404": [
        {
            "o": "Dermbach",
            "l": "Thüringen"
        },
        {
            "o": "Oechsen",
            "l": "Thüringen"
        },
        {
            "o": "Unterbreizbach",
            "l": "Thüringen"
        },
        {
            "o": "Vacha",
            "l": "Thüringen"
        }
    ],
    "36414": [
        {
            "o": "Unterbreizbach",
            "l": "Thüringen"
        }
    ],
    "36419": [
        {
            "o": "Buttlar",
            "l": "Thüringen"
        },
        {
            "o": "Geisa",
            "l": "Thüringen"
        },
        {
            "o": "Gerstengrund",
            "l": "Thüringen"
        },
        {
            "o": "Schleid",
            "l": "Thüringen"
        }
    ],
    "36433": [
        {
            "o": "Bad Salzungen",
            "l": "Thüringen"
        },
        {
            "o": "Leimbach",
            "l": "Thüringen"
        },
        {
            "o": "Moorgrund",
            "l": "Thüringen"
        }
    ],
    "36448": [
        {
            "o": "Bad Liebenstein",
            "l": "Thüringen"
        }
    ],
    "36452": [
        {
            "o": "Dermbach",
            "l": "Thüringen"
        },
        {
            "o": "Empfertshausen",
            "l": "Thüringen"
        },
        {
            "o": "Kaltennordheim",
            "l": "Thüringen"
        }
    ],
    "36456": [
        {
            "o": "Barchfeld-Immelborn",
            "l": "Thüringen"
        }
    ],
    "36457": [
        {
            "o": "Dermbach",
            "l": "Thüringen"
        },
        {
            "o": "Weilar",
            "l": "Thüringen"
        }
    ],
    "36460": [
        {
            "o": "Bad Salzungen",
            "l": "Thüringen"
        },
        {
            "o": "Krayenberggemeinde",
            "l": "Thüringen"
        }
    ],
    "36466": [
        {
            "o": "Dermbach",
            "l": "Thüringen"
        },
        {
            "o": "Wiesenthal",
            "l": "Thüringen"
        }
    ],
    "36469": [
        {
            "o": "Bad Salzungen",
            "l": "Thüringen"
        }
    ],
    "37073": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37075": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37077": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37079": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37081": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37083": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37085": [
        {
            "o": "Göttingen",
            "l": "Niedersachsen"
        }
    ],
    "37115": [
        {
            "o": "Duderstadt",
            "l": "Niedersachsen"
        }
    ],
    "37120": [
        {
            "o": "Bovenden",
            "l": "Niedersachsen"
        }
    ],
    "37124": [
        {
            "o": "Rosdorf",
            "l": "Niedersachsen"
        }
    ],
    "37127": [
        {
            "o": "Bühren",
            "l": "Niedersachsen"
        },
        {
            "o": "Dransfeld",
            "l": "Niedersachsen"
        },
        {
            "o": "Jühnde",
            "l": "Niedersachsen"
        },
        {
            "o": "Niemetal",
            "l": "Niedersachsen"
        },
        {
            "o": "Scheden",
            "l": "Niedersachsen"
        }
    ],
    "37130": [
        {
            "o": "Gleichen",
            "l": "Niedersachsen"
        }
    ],
    "37133": [
        {
            "o": "Friedland",
            "l": "Niedersachsen"
        }
    ],
    "37136": [
        {
            "o": "Ebergötzen",
            "l": "Niedersachsen"
        },
        {
            "o": "Landolfshausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Seeburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Seulingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Waake",
            "l": "Niedersachsen"
        }
    ],
    "37139": [
        {
            "o": "Adelebsen",
            "l": "Niedersachsen"
        }
    ],
    "37154": [
        {
            "o": "Northeim",
            "l": "Niedersachsen"
        }
    ],
    "37170": [
        {
            "o": "Uslar",
            "l": "Niedersachsen"
        }
    ],
    "37176": [
        {
            "o": "Nörten-Hardenberg",
            "l": "Niedersachsen"
        }
    ],
    "37181": [
        {
            "o": "Hardegsen",
            "l": "Niedersachsen"
        }
    ],
    "37186": [
        {
            "o": "Moringen",
            "l": "Niedersachsen"
        }
    ],
    "37191": [
        {
            "o": "Katlenburg-Lindau",
            "l": "Niedersachsen"
        }
    ],
    "37194": [
        {
            "o": "Bodenfelde",
            "l": "Niedersachsen"
        },
        {
            "o": "Wahlsburg",
            "l": "Hessen"
        }
    ],
    "37197": [
        {
            "o": "Hattorf am Harz",
            "l": "Niedersachsen"
        }
    ],
    "37199": [
        {
            "o": "Wulften am Harz",
            "l": "Niedersachsen"
        }
    ],
    "37213": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37214": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37215": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37216": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37217": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37218": [
        {
            "o": "Witzenhausen",
            "l": "Hessen"
        }
    ],
    "37235": [
        {
            "o": "Hessisch Lichtenau",
            "l": "Hessen"
        }
    ],
    "37242": [
        {
            "o": "Bad Sooden-Allendorf",
            "l": "Hessen"
        }
    ],
    "37247": [
        {
            "o": "Großalmerode",
            "l": "Hessen"
        }
    ],
    "37249": [
        {
            "o": "Neu-Eichenberg",
            "l": "Hessen"
        }
    ],
    "37269": [
        {
            "o": "Eschwege",
            "l": "Hessen"
        }
    ],
    "37276": [
        {
            "o": "Meinhard",
            "l": "Hessen"
        }
    ],
    "37281": [
        {
            "o": "Wanfried",
            "l": "Hessen"
        }
    ],
    "37284": [
        {
            "o": "Waldkappel",
            "l": "Hessen"
        }
    ],
    "37287": [
        {
            "o": "Wehretal",
            "l": "Hessen"
        }
    ],
    "37290": [
        {
            "o": "Meißner",
            "l": "Hessen"
        }
    ],
    "37293": [
        {
            "o": "Herleshausen",
            "l": "Hessen"
        }
    ],
    "37296": [
        {
            "o": "Ringgau",
            "l": "Hessen"
        }
    ],
    "37297": [
        {
            "o": "Berkatal",
            "l": "Hessen"
        }
    ],
    "37299": [
        {
            "o": "Weißenborn",
            "l": "Hessen"
        }
    ],
    "37308": [
        {
            "o": "Bodenrode-Westhausen",
            "l": "Thüringen"
        },
        {
            "o": "Geisleden",
            "l": "Thüringen"
        },
        {
            "o": "Geismar",
            "l": "Thüringen"
        },
        {
            "o": "Glasehausen",
            "l": "Thüringen"
        },
        {
            "o": "Heilbad Heiligenstadt",
            "l": "Thüringen"
        },
        {
            "o": "Heuthen",
            "l": "Thüringen"
        },
        {
            "o": "Hohes Kreuz",
            "l": "Thüringen"
        },
        {
            "o": "Kella",
            "l": "Thüringen"
        },
        {
            "o": "Krombach",
            "l": "Thüringen"
        },
        {
            "o": "Pfaffschwende",
            "l": "Thüringen"
        },
        {
            "o": "Reinholterode",
            "l": "Thüringen"
        },
        {
            "o": "Schimberg",
            "l": "Thüringen"
        },
        {
            "o": "Sickerode",
            "l": "Thüringen"
        },
        {
            "o": "Steinbach",
            "l": "Thüringen"
        },
        {
            "o": "Volkerode",
            "l": "Thüringen"
        },
        {
            "o": "Wiesenfeld",
            "l": "Thüringen"
        }
    ],
    "37318": [
        {
            "o": "Arenshausen",
            "l": "Thüringen"
        },
        {
            "o": "Asbach-Sickenberg",
            "l": "Thüringen"
        },
        {
            "o": "Birkenfelde",
            "l": "Thüringen"
        },
        {
            "o": "Bornhagen",
            "l": "Thüringen"
        },
        {
            "o": "Burgwalde",
            "l": "Thüringen"
        },
        {
            "o": "Dieterode",
            "l": "Thüringen"
        },
        {
            "o": "Dietzenrode/Vatterode",
            "l": "Thüringen"
        },
        {
            "o": "Eichstruth",
            "l": "Thüringen"
        },
        {
            "o": "Freienhagen",
            "l": "Thüringen"
        },
        {
            "o": "Fretterode",
            "l": "Thüringen"
        },
        {
            "o": "Gerbershausen",
            "l": "Thüringen"
        },
        {
            "o": "Hohengandern",
            "l": "Thüringen"
        },
        {
            "o": "Kirchgandern",
            "l": "Thüringen"
        },
        {
            "o": "Lenterode",
            "l": "Thüringen"
        },
        {
            "o": "Lindewerra",
            "l": "Thüringen"
        },
        {
            "o": "Lutter",
            "l": "Thüringen"
        },
        {
            "o": "Mackenrode",
            "l": "Thüringen"
        },
        {
            "o": "Marth",
            "l": "Thüringen"
        },
        {
            "o": "Rohrberg",
            "l": "Thüringen"
        },
        {
            "o": "Röhrig",
            "l": "Thüringen"
        },
        {
            "o": "Rustenfelde",
            "l": "Thüringen"
        },
        {
            "o": "Schachtebich",
            "l": "Thüringen"
        },
        {
            "o": "Schönhagen",
            "l": "Thüringen"
        },
        {
            "o": "Schwobfeld",
            "l": "Thüringen"
        },
        {
            "o": "Steinheuterode",
            "l": "Thüringen"
        },
        {
            "o": "Thalwenden",
            "l": "Thüringen"
        },
        {
            "o": "Uder",
            "l": "Thüringen"
        },
        {
            "o": "Wahlhausen",
            "l": "Thüringen"
        },
        {
            "o": "Wüstheuterode",
            "l": "Thüringen"
        }
    ],
    "37327": [
        {
            "o": "Leinefelde-Worbis",
            "l": "Thüringen"
        },
        {
            "o": "Niederorschel",
            "l": "Thüringen"
        },
        {
            "o": "Wingerode",
            "l": "Thüringen"
        }
    ],
    "37339": [
        {
            "o": "Berlingerode",
            "l": "Thüringen"
        },
        {
            "o": "Brehme",
            "l": "Thüringen"
        },
        {
            "o": "Breitenworbis",
            "l": "Thüringen"
        },
        {
            "o": "Buhla",
            "l": "Thüringen"
        },
        {
            "o": "Ecklingerode",
            "l": "Thüringen"
        },
        {
            "o": "Ferna",
            "l": "Thüringen"
        },
        {
            "o": "Gernrode",
            "l": "Thüringen"
        },
        {
            "o": "Haynrode",
            "l": "Thüringen"
        },
        {
            "o": "Kirchworbis",
            "l": "Thüringen"
        },
        {
            "o": "Leinefelde-Worbis",
            "l": "Thüringen"
        },
        {
            "o": "Tastungen",
            "l": "Thüringen"
        },
        {
            "o": "Teistungen",
            "l": "Thüringen"
        },
        {
            "o": "Wehnde",
            "l": "Thüringen"
        }
    ],
    "37345": [
        {
            "o": "Am Ohmberg",
            "l": "Thüringen"
        },
        {
            "o": "Sonnenstein",
            "l": "Thüringen"
        }
    ],
    "37351": [
        {
            "o": "Dingelstädt",
            "l": "Thüringen"
        }
    ],
    "37355": [
        {
            "o": "Niederorschel",
            "l": "Thüringen"
        }
    ],
    "37359": [
        {
            "o": "Büttstedt",
            "l": "Thüringen"
        },
        {
            "o": "Effelder",
            "l": "Thüringen"
        },
        {
            "o": "Großbartloff",
            "l": "Thüringen"
        },
        {
            "o": "Küllstedt",
            "l": "Thüringen"
        },
        {
            "o": "Wachstedt",
            "l": "Thüringen"
        }
    ],
    "37412": [
        {
            "o": "Elbingerode",
            "l": "Niedersachsen"
        },
        {
            "o": "Herzberg am Harz",
            "l": "Niedersachsen"
        },
        {
            "o": "Hörden am Harz",
            "l": "Niedersachsen"
        }
    ],
    "37431": [
        {
            "o": "Bad Lauterberg",
            "l": "Niedersachsen"
        }
    ],
    "37434": [
        {
            "o": "Bilshausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Bodensee",
            "l": "Niedersachsen"
        },
        {
            "o": "Gieboldehausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Herzberg am Harz",
            "l": "Niedersachsen"
        },
        {
            "o": "Krebeck",
            "l": "Niedersachsen"
        },
        {
            "o": "Obernfeld",
            "l": "Niedersachsen"
        },
        {
            "o": "Rhumspringe",
            "l": "Niedersachsen"
        },
        {
            "o": "Rollshausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Rüdershausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Wollbrandshausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Wollershausen",
            "l": "Niedersachsen"
        }
    ],
    "37441": [
        {
            "o": "Bad Sachsa",
            "l": "Niedersachsen"
        }
    ],
    "37444": [
        {
            "o": "Braunlage",
            "l": "Niedersachsen"
        }
    ],
    "37445": [
        {
            "o": "Walkenried",
            "l": "Niedersachsen"
        }
    ],
    "37520": [
        {
            "o": "Osterode",
            "l": "Niedersachsen"
        }
    ],
    "37539": [
        {
            "o": "Bad Grund",
            "l": "Niedersachsen"
        }
    ],
    "37574": [
        {
            "o": "Einbeck",
            "l": "Niedersachsen"
        }
    ],
    "37581": [
        {
            "o": "Bad Gandersheim",
            "l": "Niedersachsen"
        }
    ],
    "37586": [
        {
            "o": "Dassel",
            "l": "Niedersachsen"
        }
    ],
    "37589": [
        {
            "o": "Kalefeld",
            "l": "Niedersachsen"
        }
    ],
    "37603": [
        {
            "o": "Holzminden",
            "l": "Niedersachsen"
        }
    ],
    "37619": [
        {
            "o": "Bodenwerder",
            "l": "Niedersachsen"
        },
        {
            "o": "Hehlen",
            "l": "Niedersachsen"
        },
        {
            "o": "Heyen",
            "l": "Niedersachsen"
        },
        {
            "o": "Kirchbrak",
            "l": "Niedersachsen"
        },
        {
            "o": "Pegestorf",
            "l": "Niedersachsen"
        }
    ],
    "37620": [
        {
            "o": "Halle",
            "l": "Niedersachsen"
        }
    ],
    "37627": [
        {
            "o": "Arholzen",
            "l": "Niedersachsen"
        },
        {
            "o": "Deensen",
            "l": "Niedersachsen"
        },
        {
            "o": "Heinade",
            "l": "Niedersachsen"
        },
        {
            "o": "Lenne",
            "l": "Niedersachsen"
        },
        {
            "o": "Stadtoldendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Wangelnstedt",
            "l": "Niedersachsen"
        }
    ],
    "37632": [
        {
            "o": "Eimen",
            "l": "Niedersachsen"
        },
        {
            "o": "Eschershausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Holzen",
            "l": "Niedersachsen"
        }
    ],
    "37633": [
        {
            "o": "Dielmissen",
            "l": "Niedersachsen"
        }
    ],
    "37635": [
        {
            "o": "Lüerdissen",
            "l": "Niedersachsen"
        }
    ],
    "37639": [
        {
            "o": "Bevern",
            "l": "Niedersachsen"
        }
    ],
    "37640": [
        {
            "o": "Golmbach",
            "l": "Niedersachsen"
        }
    ],
    "37642": [
        {
            "o": "Holenberg",
            "l": "Niedersachsen"
        }
    ],
    "37643": [
        {
            "o": "Negenborn",
            "l": "Niedersachsen"
        }
    ],
    "37647": [
        {
            "o": "Brevörde",
            "l": "Niedersachsen"
        },
        {
            "o": "Polle",
            "l": "Niedersachsen"
        },
        {
            "o": "Vahlbruch",
            "l": "Niedersachsen"
        }
    ],
    "37649": [
        {
            "o": "Heinsen",
            "l": "Niedersachsen"
        }
    ],
    "37671": [
        {
            "o": "Höxter",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "37688": [
        {
            "o": "Beverungen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "37691": [
        {
            "o": "Boffzen",
            "l": "Niedersachsen"
        },
        {
            "o": "Derental",
            "l": "Niedersachsen"
        }
    ],
    "37696": [
        {
            "o": "Marienmünster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "37697": [
        {
            "o": "Lauenförde",
            "l": "Niedersachsen"
        }
    ],
    "37699": [
        {
            "o": "Fürstenberg",
            "l": "Niedersachsen"
        }
    ],
    "38100": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38102": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38104": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38106": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38108": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38110": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38112": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38114": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38116": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38118": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38120": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38122": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38124": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38126": [
        {
            "o": "Braunschweig",
            "l": "Niedersachsen"
        }
    ],
    "38154": [
        {
            "o": "Königslutter am Elm",
            "l": "Niedersachsen"
        }
    ],
    "38159": [
        {
            "o": "Vechelde",
            "l": "Niedersachsen"
        }
    ],
    "38162": [
        {
            "o": "Cremlingen",
            "l": "Niedersachsen"
        }
    ],
    "38165": [
        {
            "o": "Lehre",
            "l": "Niedersachsen"
        }
    ],
    "38170": [
        {
            "o": "Dahlum",
            "l": "Niedersachsen"
        },
        {
            "o": "Kneitlingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Schöppenstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Uehrde",
            "l": "Niedersachsen"
        },
        {
            "o": "Vahlberg",
            "l": "Niedersachsen"
        },
        {
            "o": "Winnigstedt",
            "l": "Niedersachsen"
        }
    ],
    "38173": [
        {
            "o": "Dettum",
            "l": "Niedersachsen"
        },
        {
            "o": "Erkerode",
            "l": "Niedersachsen"
        },
        {
            "o": "Evessen",
            "l": "Niedersachsen"
        },
        {
            "o": "Sickte",
            "l": "Niedersachsen"
        },
        {
            "o": "Veltheim",
            "l": "Niedersachsen"
        }
    ],
    "38176": [
        {
            "o": "Wendeburg",
            "l": "Niedersachsen"
        }
    ],
    "38179": [
        {
            "o": "Schwülper",
            "l": "Niedersachsen"
        }
    ],
    "38226": [
        {
            "o": "Salzgitter",
            "l": "Niedersachsen"
        }
    ],
    "38228": [
        {
            "o": "Salzgitter",
            "l": "Niedersachsen"
        }
    ],
    "38229": [
        {
            "o": "Salzgitter",
            "l": "Niedersachsen"
        }
    ],
    "38239": [
        {
            "o": "Salzgitter",
            "l": "Niedersachsen"
        }
    ],
    "38259": [
        {
            "o": "Salzgitter",
            "l": "Niedersachsen"
        }
    ],
    "38268": [
        {
            "o": "Lengede",
            "l": "Niedersachsen"
        }
    ],
    "38271": [
        {
            "o": "Baddeckenstedt",
            "l": "Niedersachsen"
        }
    ],
    "38272": [
        {
            "o": "Burgdorf",
            "l": "Niedersachsen"
        }
    ],
    "38274": [
        {
            "o": "Elbe",
            "l": "Niedersachsen"
        }
    ],
    "38275": [
        {
            "o": "Haverlah",
            "l": "Niedersachsen"
        }
    ],
    "38277": [
        {
            "o": "Heere",
            "l": "Niedersachsen"
        }
    ],
    "38279": [
        {
            "o": "Sehlde",
            "l": "Niedersachsen"
        }
    ],
    "38300": [
        {
            "o": "Wolfenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38302": [
        {
            "o": "Wolfenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38304": [
        {
            "o": "Wolfenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38312": [
        {
            "o": "Börßum",
            "l": "Niedersachsen"
        },
        {
            "o": "Cramme",
            "l": "Niedersachsen"
        },
        {
            "o": "Dorstadt",
            "l": "Niedersachsen"
        },
        {
            "o": "Flöthe",
            "l": "Niedersachsen"
        },
        {
            "o": "Heiningen",
            "l": "Niedersachsen"
        },
        {
            "o": "Ohrum",
            "l": "Niedersachsen"
        }
    ],
    "38315": [
        {
            "o": "Schladen-Werla",
            "l": "Niedersachsen"
        }
    ],
    "38319": [
        {
            "o": "Remlingen-Semmenstedt",
            "l": "Niedersachsen"
        }
    ],
    "38321": [
        {
            "o": "Denkte",
            "l": "Niedersachsen"
        }
    ],
    "38322": [
        {
            "o": "Hedeper",
            "l": "Niedersachsen"
        }
    ],
    "38324": [
        {
            "o": "Kissenbrück",
            "l": "Niedersachsen"
        }
    ],
    "38325": [
        {
            "o": "Roklum",
            "l": "Niedersachsen"
        }
    ],
    "38327": [
        {
            "o": "Remlingen-Semmenstedt",
            "l": "Niedersachsen"
        }
    ],
    "38329": [
        {
            "o": "Wittmar",
            "l": "Niedersachsen"
        }
    ],
    "38350": [
        {
            "o": "Helmstedt",
            "l": "Niedersachsen"
        }
    ],
    "38364": [
        {
            "o": "Schöningen",
            "l": "Niedersachsen"
        }
    ],
    "38368": [
        {
            "o": "Grasleben",
            "l": "Niedersachsen"
        },
        {
            "o": "Mariental",
            "l": "Niedersachsen"
        },
        {
            "o": "Querenhorst",
            "l": "Niedersachsen"
        },
        {
            "o": "Rennau",
            "l": "Niedersachsen"
        }
    ],
    "38372": [
        {
            "o": "Helmstedt",
            "l": "Niedersachsen"
        }
    ],
    "38373": [
        {
            "o": "Frellstedt",
            "l": "Niedersachsen"
        },
        {
            "o": "Süpplingen",
            "l": "Niedersachsen"
        }
    ],
    "38375": [
        {
            "o": "Räbke",
            "l": "Niedersachsen"
        }
    ],
    "38376": [
        {
            "o": "Süpplingenburg",
            "l": "Niedersachsen"
        }
    ],
    "38378": [
        {
            "o": "Warberg",
            "l": "Niedersachsen"
        }
    ],
    "38379": [
        {
            "o": "Wolsdorf",
            "l": "Niedersachsen"
        }
    ],
    "38381": [
        {
            "o": "Jerxheim",
            "l": "Niedersachsen"
        }
    ],
    "38382": [
        {
            "o": "Beierstedt",
            "l": "Niedersachsen"
        }
    ],
    "38384": [
        {
            "o": "Gevensleben",
            "l": "Niedersachsen"
        }
    ],
    "38387": [
        {
            "o": "Söllingen",
            "l": "Niedersachsen"
        }
    ],
    "38440": [
        {
            "o": "Wolfsburg",
            "l": "Niedersachsen"
        }
    ],
    "38442": [
        {
            "o": "Wolfsburg",
            "l": "Niedersachsen"
        }
    ],
    "38444": [
        {
            "o": "Wolfsburg",
            "l": "Niedersachsen"
        }
    ],
    "38446": [
        {
            "o": "Wolfsburg",
            "l": "Niedersachsen"
        }
    ],
    "38448": [
        {
            "o": "Wolfsburg",
            "l": "Niedersachsen"
        }
    ],
    "38458": [
        {
            "o": "Velpke",
            "l": "Niedersachsen"
        }
    ],
    "38459": [
        {
            "o": "Bahrdorf",
            "l": "Niedersachsen"
        }
    ],
    "38461": [
        {
            "o": "Danndorf",
            "l": "Niedersachsen"
        }
    ],
    "38462": [
        {
            "o": "Grafhorst",
            "l": "Niedersachsen"
        }
    ],
    "38464": [
        {
            "o": "Groß Twülpstedt",
            "l": "Niedersachsen"
        }
    ],
    "38465": [
        {
            "o": "Brome",
            "l": "Niedersachsen"
        }
    ],
    "38467": [
        {
            "o": "Bergfeld",
            "l": "Niedersachsen"
        }
    ],
    "38468": [
        {
            "o": "Ehra-Lessien",
            "l": "Niedersachsen"
        }
    ],
    "38470": [
        {
            "o": "Parsau",
            "l": "Niedersachsen"
        }
    ],
    "38471": [
        {
            "o": "Rühen",
            "l": "Niedersachsen"
        }
    ],
    "38473": [
        {
            "o": "Tiddische",
            "l": "Niedersachsen"
        }
    ],
    "38474": [
        {
            "o": "Tülau",
            "l": "Niedersachsen"
        }
    ],
    "38476": [
        {
            "o": "Barwedel",
            "l": "Niedersachsen"
        }
    ],
    "38477": [
        {
            "o": "Jembke",
            "l": "Niedersachsen"
        }
    ],
    "38479": [
        {
            "o": "Tappenbeck",
            "l": "Niedersachsen"
        }
    ],
    "38486": [
        {
            "o": "Apenburg-Winterfeld",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Klötze",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38489": [
        {
            "o": "Beetzendorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Jübar",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Rohrberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38518": [
        {
            "o": "Gifhorn",
            "l": "Niedersachsen"
        }
    ],
    "38524": [
        {
            "o": "Sassenburg",
            "l": "Niedersachsen"
        }
    ],
    "38527": [
        {
            "o": "Meine",
            "l": "Niedersachsen"
        }
    ],
    "38528": [
        {
            "o": "Adenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38530": [
        {
            "o": "Didderse",
            "l": "Niedersachsen"
        }
    ],
    "38531": [
        {
            "o": "Rötgesbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38533": [
        {
            "o": "Vordorf",
            "l": "Niedersachsen"
        }
    ],
    "38536": [
        {
            "o": "Meinersen",
            "l": "Niedersachsen"
        }
    ],
    "38539": [
        {
            "o": "Müden (Aller)",
            "l": "Niedersachsen"
        }
    ],
    "38542": [
        {
            "o": "Leiferde",
            "l": "Niedersachsen"
        }
    ],
    "38543": [
        {
            "o": "Hillerse",
            "l": "Niedersachsen"
        }
    ],
    "38547": [
        {
            "o": "Calberlah",
            "l": "Niedersachsen"
        }
    ],
    "38550": [
        {
            "o": "Isenbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38551": [
        {
            "o": "Ribbesbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38553": [
        {
            "o": "Wasbüttel",
            "l": "Niedersachsen"
        }
    ],
    "38554": [
        {
            "o": "Weyhausen",
            "l": "Niedersachsen"
        }
    ],
    "38556": [
        {
            "o": "Bokensdorf",
            "l": "Niedersachsen"
        }
    ],
    "38557": [
        {
            "o": "Osloß",
            "l": "Niedersachsen"
        }
    ],
    "38559": [
        {
            "o": "Gifhorn",
            "l": "Niedersachsen"
        },
        {
            "o": "Wagenhoff",
            "l": "Niedersachsen"
        }
    ],
    "38640": [
        {
            "o": "Goslar",
            "l": "Niedersachsen"
        }
    ],
    "38642": [
        {
            "o": "Goslar",
            "l": "Niedersachsen"
        }
    ],
    "38644": [
        {
            "o": "Goslar",
            "l": "Niedersachsen"
        }
    ],
    "38667": [
        {
            "o": "Bad Harzburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Clausthal-Zellerfeld",
            "l": "Niedersachsen"
        }
    ],
    "38678": [
        {
            "o": "Clausthal-Zellerfeld",
            "l": "Niedersachsen"
        }
    ],
    "38685": [
        {
            "o": "Langelsheim",
            "l": "Niedersachsen"
        }
    ],
    "38690": [
        {
            "o": "Goslar",
            "l": "Niedersachsen"
        }
    ],
    "38700": [
        {
            "o": "Braunlage",
            "l": "Niedersachsen"
        }
    ],
    "38704": [
        {
            "o": "Liebenburg",
            "l": "Niedersachsen"
        }
    ],
    "38707": [
        {
            "o": "Clausthal-Zellerfeld",
            "l": "Niedersachsen"
        }
    ],
    "38709": [
        {
            "o": "Clausthal-Zellerfeld",
            "l": "Niedersachsen"
        }
    ],
    "38723": [
        {
            "o": "Seesen",
            "l": "Niedersachsen"
        }
    ],
    "38729": [
        {
            "o": "Hahausen",
            "l": "Niedersachsen"
        },
        {
            "o": "Lutter am Barenberge",
            "l": "Niedersachsen"
        },
        {
            "o": "Wallmoden",
            "l": "Niedersachsen"
        }
    ],
    "38820": [
        {
            "o": "Halberstadt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38822": [
        {
            "o": "Groß Quenstedt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Halberstadt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38828": [
        {
            "o": "Wegeleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38829": [
        {
            "o": "Harsleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38835": [
        {
            "o": "Osterwieck",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38836": [
        {
            "o": "Huy",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Osterwieck",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38838": [
        {
            "o": "Huy",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38855": [
        {
            "o": "Nordharz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wernigerode",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38871": [
        {
            "o": "Ilsenburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Nordharz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38875": [
        {
            "o": "Oberharz am Brocken",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wernigerode",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38877": [
        {
            "o": "Oberharz am Brocken",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38879": [
        {
            "o": "Wernigerode",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38889": [
        {
            "o": "Blankenburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Oberharz am Brocken",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38895": [
        {
            "o": "Blankenburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Halberstadt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "38899": [
        {
            "o": "Oberharz am Brocken",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39104": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39106": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39108": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39110": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39112": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39114": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39116": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39118": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39120": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39122": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39124": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39126": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39128": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39130": [
        {
            "o": "Magdeburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39164": [
        {
            "o": "Wanzleben-Börde",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39167": [
        {
            "o": "Hohe Börde",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39171": [
        {
            "o": "Sülzetal",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39175": [
        {
            "o": "Biederitz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Gommern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Möser",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39179": [
        {
            "o": "Barleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39217": [
        {
            "o": "Schönebeck (Elbe)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39218": [
        {
            "o": "Schönebeck (Elbe)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39221": [
        {
            "o": "Bördeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39240": [
        {
            "o": "Barby",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Calbe (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39245": [
        {
            "o": "Gommern",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39249": [
        {
            "o": "Barby",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39261": [
        {
            "o": "Zerbst/Anhalt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39264": [
        {
            "o": "Gommern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Zerbst/Anhalt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39279": [
        {
            "o": "Gommern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Möckern",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39288": [
        {
            "o": "Burg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39291": [
        {
            "o": "Genthin",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Gommern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Möckern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Möser",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39307": [
        {
            "o": "Elbe-Parey",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Genthin",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Jerichow",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39317": [
        {
            "o": "Elbe-Parey",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39319": [
        {
            "o": "Jerichow",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39326": [
        {
            "o": "Angern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Colbitz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hohe Börde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Loitsche-Heinrichsberg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Niedere Börde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Rogätz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wolmirstedt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Zielitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39340": [
        {
            "o": "Haldensleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39343": [
        {
            "o": "Altenhausen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Beendorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Erxleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Haldensleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hohe Börde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Ingersleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Oebisfelde-Weferlingen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Westheide",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39345": [
        {
            "o": "Bülstringen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Flechtingen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Haldensleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Niedere Börde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Westheide",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39356": [
        {
            "o": "Flechtingen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Oebisfelde-Weferlingen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39359": [
        {
            "o": "Calvörde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Flechtingen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Oebisfelde-Weferlingen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39365": [
        {
            "o": "Eilsleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Harbke",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Sommersdorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Ummendorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wefensleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39387": [
        {
            "o": "Oschersleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39393": [
        {
            "o": "Am Großen Bruch",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Ausleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hötensleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Völpke",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39397": [
        {
            "o": "Gröningen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Kroppenstedt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Schwanebeck",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39418": [
        {
            "o": "Staßfurt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39435": [
        {
            "o": "Bördeaue",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Borne",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Egeln",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wolmirsleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39439": [
        {
            "o": "Güsten",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39443": [
        {
            "o": "Staßfurt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39444": [
        {
            "o": "Hecklingen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39446": [
        {
            "o": "Staßfurt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39448": [
        {
            "o": "Börde-Hakel",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39517": [
        {
            "o": "Angern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Burgstall",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Tangerhütte",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39524": [
        {
            "o": "Kamern",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Klietz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Sandau (Elbe)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Schönhausen (Elbe)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wust-Fischbeck",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39539": [
        {
            "o": "Havelberg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Werben (Elbe)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39576": [
        {
            "o": "Stendal",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39579": [
        {
            "o": "Rochau",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Tangerhütte",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39590": [
        {
            "o": "Tangermünde",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39596": [
        {
            "o": "Arneburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Eichstedt (Altmark)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Goldbeck",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hassel",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hohenberg-Krusemark",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39606": [
        {
            "o": "Altmärkische Höhe",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Iden",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Osterburg (Altmark)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39615": [
        {
            "o": "Aland",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Altmärkische Höhe",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Altmärkische Wische",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Seehausen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Werben (Elbe)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Zehrental",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39619": [
        {
            "o": "Arendsee (Altmark)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39624": [
        {
            "o": "Kalbe (Milde)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39628": [
        {
            "o": "Bismark (Altmark)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39629": [
        {
            "o": "Bismark (Altmark)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39638": [
        {
            "o": "Calvörde",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Gardelegen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39646": [
        {
            "o": "Gardelegen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Oebisfelde-Weferlingen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "39649": [
        {
            "o": "Gardelegen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "40210": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40211": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40212": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40213": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40215": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40217": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40219": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40221": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40223": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40225": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40227": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40229": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40231": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40233": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40235": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40237": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40239": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40468": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40470": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40472": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40474": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40476": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40477": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40479": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40489": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40545": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40547": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40549": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40589": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40591": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40593": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40595": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40597": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40599": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40625": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40627": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40629": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40667": [
        {
            "o": "Meerbusch",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40668": [
        {
            "o": "Meerbusch",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40670": [
        {
            "o": "Meerbusch",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40699": [
        {
            "o": "Erkrath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40721": [
        {
            "o": "Düsseldorf",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Hilden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40723": [
        {
            "o": "Hilden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40724": [
        {
            "o": "Hilden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40764": [
        {
            "o": "Langenfeld (Rheinland)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40789": [
        {
            "o": "Monheim am Rhein",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40822": [
        {
            "o": "Mettmann",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40878": [
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40880": [
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40882": [
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40883": [
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "40885": [
        {
            "o": "Ratingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41061": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41063": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41065": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41066": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41068": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41069": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41169": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41179": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41189": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41199": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41236": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41238": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41239": [
        {
            "o": "Mönchengladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41334": [
        {
            "o": "Nettetal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41352": [
        {
            "o": "Korschenbroich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41363": [
        {
            "o": "Jüchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41366": [
        {
            "o": "Schwalmtal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41372": [
        {
            "o": "Niederkrüchten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41379": [
        {
            "o": "Brüggen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41460": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41462": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41464": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41466": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41468": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41469": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41470": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41472": [
        {
            "o": "Neuss",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41515": [
        {
            "o": "Grevenbroich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41516": [
        {
            "o": "Grevenbroich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41517": [
        {
            "o": "Grevenbroich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41539": [
        {
            "o": "Dormagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41540": [
        {
            "o": "Dormagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41541": [
        {
            "o": "Dormagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41542": [
        {
            "o": "Dormagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41564": [
        {
            "o": "Kaarst",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41569": [
        {
            "o": "Rommerskirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41747": [
        {
            "o": "Viersen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41748": [
        {
            "o": "Viersen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41749": [
        {
            "o": "Viersen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41751": [
        {
            "o": "Viersen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41812": [
        {
            "o": "Erkelenz",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41836": [
        {
            "o": "Hückelhoven",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41844": [
        {
            "o": "Wegberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "41849": [
        {
            "o": "Wassenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42103": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42105": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42107": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42109": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42111": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42113": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42115": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42117": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42119": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42275": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42277": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42279": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42281": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42283": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42285": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42287": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42289": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42327": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42329": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42349": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42369": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42389": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42399": [
        {
            "o": "Wuppertal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42477": [
        {
            "o": "Radevormwald",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42489": [
        {
            "o": "Wülfrath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42499": [
        {
            "o": "Hückeswagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42549": [
        {
            "o": "Velbert",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42551": [
        {
            "o": "Velbert",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42553": [
        {
            "o": "Velbert",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42555": [
        {
            "o": "Velbert",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42579": [
        {
            "o": "Heiligenhaus",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42651": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42653": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42655": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42657": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42659": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42697": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42699": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42719": [
        {
            "o": "Solingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42781": [
        {
            "o": "Haan",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42799": [
        {
            "o": "Leichlingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42853": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42855": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42857": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42859": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42897": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42899": [
        {
            "o": "Remscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "42929": [
        {
            "o": "Wermelskirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44135": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44137": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44139": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44141": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44143": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44145": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44147": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44149": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44225": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44227": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44229": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44263": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44265": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44267": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44269": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44287": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44289": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44309": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44319": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44328": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44329": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44339": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44357": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44359": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44369": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44379": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44388": [
        {
            "o": "Dortmund",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44532": [
        {
            "o": "Lünen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44534": [
        {
            "o": "Lünen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44536": [
        {
            "o": "Lünen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44575": [
        {
            "o": "Castrop-Rauxel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44577": [
        {
            "o": "Castrop-Rauxel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44579": [
        {
            "o": "Castrop-Rauxel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44581": [
        {
            "o": "Castrop-Rauxel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44623": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44625": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44627": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44628": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44629": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44649": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44651": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44652": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44653": [
        {
            "o": "Herne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44787": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44789": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44791": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44793": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44795": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44797": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44799": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44801": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44803": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44805": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44807": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44809": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44866": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44867": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44869": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44879": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44892": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "44894": [
        {
            "o": "Bochum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45127": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45128": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45130": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45131": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45133": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45134": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45136": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45138": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45139": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45141": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45143": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45144": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45145": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45147": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45149": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45219": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45239": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45257": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45259": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45276": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45277": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45279": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45289": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45307": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45309": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45326": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45327": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45329": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45355": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45356": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45357": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45359": [
        {
            "o": "Essen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45468": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45470": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45472": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45473": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45475": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45476": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45478": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45479": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45481": [
        {
            "o": "Mülheim an der Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45525": [
        {
            "o": "Hattingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45527": [
        {
            "o": "Hattingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45529": [
        {
            "o": "Hattingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45549": [
        {
            "o": "Sprockhövel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45657": [
        {
            "o": "Recklinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45659": [
        {
            "o": "Recklinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45661": [
        {
            "o": "Recklinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45663": [
        {
            "o": "Recklinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45665": [
        {
            "o": "Recklinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45699": [
        {
            "o": "Herten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45701": [
        {
            "o": "Herten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45711": [
        {
            "o": "Datteln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45721": [
        {
            "o": "Haltern am See",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45731": [
        {
            "o": "Waltrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45739": [
        {
            "o": "Oer-Erkenschwick",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45768": [
        {
            "o": "Marl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45770": [
        {
            "o": "Marl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45772": [
        {
            "o": "Marl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45879": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45881": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45883": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45884": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45886": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45888": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45889": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45891": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45892": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45894": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45896": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45897": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45899": [
        {
            "o": "Gelsenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45964": [
        {
            "o": "Gladbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45966": [
        {
            "o": "Gladbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "45968": [
        {
            "o": "Gladbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46045": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46047": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46049": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46117": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46119": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46145": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46147": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46149": [
        {
            "o": "Oberhausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46236": [
        {
            "o": "Bottrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46238": [
        {
            "o": "Bottrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46240": [
        {
            "o": "Bottrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46242": [
        {
            "o": "Bottrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46244": [
        {
            "o": "Bottrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46282": [
        {
            "o": "Dorsten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46284": [
        {
            "o": "Dorsten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46286": [
        {
            "o": "Dorsten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46325": [
        {
            "o": "Borken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46342": [
        {
            "o": "Velen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46348": [
        {
            "o": "Raesfeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46354": [
        {
            "o": "Südlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46359": [
        {
            "o": "Heiden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46395": [
        {
            "o": "Bocholt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46397": [
        {
            "o": "Bocholt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46399": [
        {
            "o": "Bocholt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46414": [
        {
            "o": "Rhede",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46419": [
        {
            "o": "Isselburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46446": [
        {
            "o": "Emmerich am Rhein",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46459": [
        {
            "o": "Rees",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46483": [
        {
            "o": "Wesel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46485": [
        {
            "o": "Wesel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46487": [
        {
            "o": "Wesel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46499": [
        {
            "o": "Hamminkeln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46509": [
        {
            "o": "Xanten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46514": [
        {
            "o": "Schermbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46519": [
        {
            "o": "Alpen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46535": [
        {
            "o": "Dinslaken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46537": [
        {
            "o": "Dinslaken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46539": [
        {
            "o": "Dinslaken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46562": [
        {
            "o": "Voerde (Niederrhein)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "46569": [
        {
            "o": "Hünxe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47051": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47053": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47055": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47057": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47058": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47059": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47119": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47137": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47138": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47139": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47166": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47167": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47169": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47178": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47179": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47198": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47199": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47226": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47228": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47229": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47239": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47249": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47259": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47269": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47279": [
        {
            "o": "Duisburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47441": [
        {
            "o": "Moers",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47443": [
        {
            "o": "Moers",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47445": [
        {
            "o": "Moers",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47447": [
        {
            "o": "Moers",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47475": [
        {
            "o": "Kamp-Lintfort",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47495": [
        {
            "o": "Rheinberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47506": [
        {
            "o": "Neukirchen-Vluyn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47509": [
        {
            "o": "Rheurdt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47533": [
        {
            "o": "Kleve",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47546": [
        {
            "o": "Kalkar",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47551": [
        {
            "o": "Bedburg-Hau",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47559": [
        {
            "o": "Kranenburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47574": [
        {
            "o": "Goch",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47589": [
        {
            "o": "Uedem",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47608": [
        {
            "o": "Geldern",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47623": [
        {
            "o": "Kevelaer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47624": [
        {
            "o": "Kevelaer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47625": [
        {
            "o": "Kevelaer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47626": [
        {
            "o": "Kevelaer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47627": [
        {
            "o": "Kevelaer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47638": [
        {
            "o": "Straelen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47647": [
        {
            "o": "Kerken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47652": [
        {
            "o": "Weeze",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47661": [
        {
            "o": "Issum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47665": [
        {
            "o": "Sonsbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47669": [
        {
            "o": "Wachtendonk",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47798": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47799": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47800": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47802": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47803": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47804": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47805": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47807": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47809": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47829": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47839": [
        {
            "o": "Krefeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47877": [
        {
            "o": "Willich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47906": [
        {
            "o": "Kempen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47918": [
        {
            "o": "Tönisvorst",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "47929": [
        {
            "o": "Grefrath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48143": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48145": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48147": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48149": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48151": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48153": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48155": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48157": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48159": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48161": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48163": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48165": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48167": [
        {
            "o": "Münster",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48231": [
        {
            "o": "Warendorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48249": [
        {
            "o": "Dülmen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48268": [
        {
            "o": "Greven",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48282": [
        {
            "o": "Emsdetten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48291": [
        {
            "o": "Telgte",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48301": [
        {
            "o": "Nottuln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48308": [
        {
            "o": "Senden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48317": [
        {
            "o": "Drensteinfurt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48324": [
        {
            "o": "Sendenhorst",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48329": [
        {
            "o": "Havixbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48336": [
        {
            "o": "Sassenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48341": [
        {
            "o": "Altenberge",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48346": [
        {
            "o": "Ostbevern",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48351": [
        {
            "o": "Everswinkel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48356": [
        {
            "o": "Nordwalde",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48361": [
        {
            "o": "Beelen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48366": [
        {
            "o": "Laer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48369": [
        {
            "o": "Saerbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48429": [
        {
            "o": "Rheine",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48431": [
        {
            "o": "Rheine",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48432": [
        {
            "o": "Rheine",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48455": [
        {
            "o": "Bad Bentheim",
            "l": "Niedersachsen"
        }
    ],
    "48465": [
        {
            "o": "Engden",
            "l": "Niedersachsen"
        },
        {
            "o": "Isterberg",
            "l": "Niedersachsen"
        },
        {
            "o": "Ohne",
            "l": "Niedersachsen"
        },
        {
            "o": "Quendorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Samern",
            "l": "Niedersachsen"
        },
        {
            "o": "Schüttorf",
            "l": "Niedersachsen"
        }
    ],
    "48477": [
        {
            "o": "Hörstel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48480": [
        {
            "o": "Lünne",
            "l": "Niedersachsen"
        },
        {
            "o": "Schapen",
            "l": "Niedersachsen"
        },
        {
            "o": "Spelle",
            "l": "Niedersachsen"
        }
    ],
    "48485": [
        {
            "o": "Neuenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48488": [
        {
            "o": "Emsbüren",
            "l": "Niedersachsen"
        }
    ],
    "48493": [
        {
            "o": "Wettringen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48496": [
        {
            "o": "Hopsten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48499": [
        {
            "o": "Salzbergen",
            "l": "Niedersachsen"
        }
    ],
    "48527": [
        {
            "o": "Nordhorn",
            "l": "Niedersachsen"
        }
    ],
    "48529": [
        {
            "o": "Nordhorn",
            "l": "Niedersachsen"
        }
    ],
    "48531": [
        {
            "o": "Nordhorn",
            "l": "Niedersachsen"
        }
    ],
    "48565": [
        {
            "o": "Steinfurt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48599": [
        {
            "o": "Gronau",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48607": [
        {
            "o": "Ochtrup",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48612": [
        {
            "o": "Horstmar",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48619": [
        {
            "o": "Heek",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48624": [
        {
            "o": "Schöppingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48629": [
        {
            "o": "Metelen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48653": [
        {
            "o": "Coesfeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48683": [
        {
            "o": "Ahaus",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48691": [
        {
            "o": "Vreden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48703": [
        {
            "o": "Stadtlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48712": [
        {
            "o": "Gescher",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48720": [
        {
            "o": "Rosendahl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48727": [
        {
            "o": "Billerbeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48734": [
        {
            "o": "Reken",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "48739": [
        {
            "o": "Legden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49074": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49076": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49078": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49080": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49082": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49084": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49086": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49088": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49090": [
        {
            "o": "Osnabrück",
            "l": "Niedersachsen"
        }
    ],
    "49124": [
        {
            "o": "Georgsmarienhütte",
            "l": "Niedersachsen"
        }
    ],
    "49134": [
        {
            "o": "Wallenhorst",
            "l": "Niedersachsen"
        }
    ],
    "49143": [
        {
            "o": "Bissendorf",
            "l": "Niedersachsen"
        }
    ],
    "49152": [
        {
            "o": "Bad Essen",
            "l": "Niedersachsen"
        }
    ],
    "49163": [
        {
            "o": "Bohmte",
            "l": "Niedersachsen"
        }
    ],
    "49170": [
        {
            "o": "Hagen am Teutoburger Wald",
            "l": "Niedersachsen"
        }
    ],
    "49176": [
        {
            "o": "Hilter",
            "l": "Niedersachsen"
        }
    ],
    "49179": [
        {
            "o": "Ostercappeln",
            "l": "Niedersachsen"
        }
    ],
    "49186": [
        {
            "o": "Bad Iburg",
            "l": "Niedersachsen"
        }
    ],
    "49191": [
        {
            "o": "Belm",
            "l": "Niedersachsen"
        }
    ],
    "49196": [
        {
            "o": "Bad Laer",
            "l": "Niedersachsen"
        }
    ],
    "49201": [
        {
            "o": "Dissen",
            "l": "Niedersachsen"
        }
    ],
    "49205": [
        {
            "o": "Hasbergen",
            "l": "Niedersachsen"
        }
    ],
    "49214": [
        {
            "o": "Bad Rothenfelde",
            "l": "Niedersachsen"
        }
    ],
    "49219": [
        {
            "o": "Glandorf",
            "l": "Niedersachsen"
        }
    ],
    "49324": [
        {
            "o": "Melle",
            "l": "Niedersachsen"
        }
    ],
    "49326": [
        {
            "o": "Melle",
            "l": "Niedersachsen"
        }
    ],
    "49328": [
        {
            "o": "Melle",
            "l": "Niedersachsen"
        }
    ],
    "49356": [
        {
            "o": "Diepholz",
            "l": "Niedersachsen"
        }
    ],
    "49377": [
        {
            "o": "Vechta",
            "l": "Niedersachsen"
        }
    ],
    "49393": [
        {
            "o": "Lohne",
            "l": "Niedersachsen"
        }
    ],
    "49401": [
        {
            "o": "Damme",
            "l": "Niedersachsen"
        }
    ],
    "49406": [
        {
            "o": "Barnstorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Drentwede",
            "l": "Niedersachsen"
        },
        {
            "o": "Eydelstedt",
            "l": "Niedersachsen"
        }
    ],
    "49413": [
        {
            "o": "Dinklage",
            "l": "Niedersachsen"
        }
    ],
    "49419": [
        {
            "o": "Wagenfeld",
            "l": "Niedersachsen"
        }
    ],
    "49424": [
        {
            "o": "Goldenstedt",
            "l": "Niedersachsen"
        }
    ],
    "49429": [
        {
            "o": "Visbek",
            "l": "Niedersachsen"
        }
    ],
    "49434": [
        {
            "o": "Neuenkirchen-Vörden",
            "l": "Niedersachsen"
        }
    ],
    "49439": [
        {
            "o": "Steinfeld",
            "l": "Niedersachsen"
        }
    ],
    "49448": [
        {
            "o": "Brockum",
            "l": "Niedersachsen"
        },
        {
            "o": "Hüde",
            "l": "Niedersachsen"
        },
        {
            "o": "Lemförde",
            "l": "Niedersachsen"
        },
        {
            "o": "Marl",
            "l": "Niedersachsen"
        },
        {
            "o": "Quernheim",
            "l": "Niedersachsen"
        },
        {
            "o": "Stemshorn",
            "l": "Niedersachsen"
        }
    ],
    "49451": [
        {
            "o": "Holdorf",
            "l": "Niedersachsen"
        }
    ],
    "49453": [
        {
            "o": "Barver",
            "l": "Niedersachsen"
        },
        {
            "o": "Dickel",
            "l": "Niedersachsen"
        },
        {
            "o": "Hemsloh",
            "l": "Niedersachsen"
        },
        {
            "o": "Rehden",
            "l": "Niedersachsen"
        },
        {
            "o": "Wehrbleck",
            "l": "Niedersachsen"
        },
        {
            "o": "Wetschen",
            "l": "Niedersachsen"
        }
    ],
    "49456": [
        {
            "o": "Bakum",
            "l": "Niedersachsen"
        }
    ],
    "49457": [
        {
            "o": "Drebber",
            "l": "Niedersachsen"
        }
    ],
    "49459": [
        {
            "o": "Lembruch",
            "l": "Niedersachsen"
        }
    ],
    "49477": [
        {
            "o": "Ibbenbüren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49479": [
        {
            "o": "Ibbenbüren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49492": [
        {
            "o": "Westerkappeln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49497": [
        {
            "o": "Mettingen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49504": [
        {
            "o": "Lotte",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49509": [
        {
            "o": "Recke",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49525": [
        {
            "o": "Lengerich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49536": [
        {
            "o": "Lienen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49545": [
        {
            "o": "Tecklenburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49549": [
        {
            "o": "Ladbergen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "49565": [
        {
            "o": "Bramsche",
            "l": "Niedersachsen"
        }
    ],
    "49577": [
        {
            "o": "Ankum",
            "l": "Niedersachsen"
        },
        {
            "o": "Eggermühlen",
            "l": "Niedersachsen"
        },
        {
            "o": "Kettenkamp",
            "l": "Niedersachsen"
        }
    ],
    "49584": [
        {
            "o": "Fürstenau",
            "l": "Niedersachsen"
        }
    ],
    "49586": [
        {
            "o": "Merzen",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuenkirchen",
            "l": "Niedersachsen"
        }
    ],
    "49593": [
        {
            "o": "Bersenbrück",
            "l": "Niedersachsen"
        }
    ],
    "49594": [
        {
            "o": "Alfhausen",
            "l": "Niedersachsen"
        }
    ],
    "49596": [
        {
            "o": "Gehrde",
            "l": "Niedersachsen"
        }
    ],
    "49597": [
        {
            "o": "Rieste",
            "l": "Niedersachsen"
        }
    ],
    "49599": [
        {
            "o": "Voltlage",
            "l": "Niedersachsen"
        }
    ],
    "49610": [
        {
            "o": "Quakenbrück",
            "l": "Niedersachsen"
        }
    ],
    "49624": [
        {
            "o": "Löningen",
            "l": "Niedersachsen"
        }
    ],
    "49626": [
        {
            "o": "Berge",
            "l": "Niedersachsen"
        },
        {
            "o": "Bippen",
            "l": "Niedersachsen"
        }
    ],
    "49632": [
        {
            "o": "Essen (Oldenburg)",
            "l": "Niedersachsen"
        }
    ],
    "49635": [
        {
            "o": "Badbergen",
            "l": "Niedersachsen"
        }
    ],
    "49637": [
        {
            "o": "Menslage",
            "l": "Niedersachsen"
        }
    ],
    "49638": [
        {
            "o": "Nortrup",
            "l": "Niedersachsen"
        }
    ],
    "49661": [
        {
            "o": "Cloppenburg",
            "l": "Niedersachsen"
        }
    ],
    "49681": [
        {
            "o": "Garrel",
            "l": "Niedersachsen"
        }
    ],
    "49685": [
        {
            "o": "Emstek",
            "l": "Niedersachsen"
        }
    ],
    "49688": [
        {
            "o": "Lastrup",
            "l": "Niedersachsen"
        }
    ],
    "49692": [
        {
            "o": "Cappeln (Oldenburg)",
            "l": "Niedersachsen"
        }
    ],
    "49696": [
        {
            "o": "Molbergen",
            "l": "Niedersachsen"
        }
    ],
    "49699": [
        {
            "o": "Lindern (Oldenburg)",
            "l": "Niedersachsen"
        }
    ],
    "49716": [
        {
            "o": "Meppen",
            "l": "Niedersachsen"
        }
    ],
    "49733": [
        {
            "o": "Haren (Ems)",
            "l": "Niedersachsen"
        }
    ],
    "49740": [
        {
            "o": "Haselünne",
            "l": "Niedersachsen"
        }
    ],
    "49744": [
        {
            "o": "Geeste",
            "l": "Niedersachsen"
        }
    ],
    "49751": [
        {
            "o": "Hüven",
            "l": "Niedersachsen"
        },
        {
            "o": "Sögel",
            "l": "Niedersachsen"
        },
        {
            "o": "Spahnharrenstätte",
            "l": "Niedersachsen"
        },
        {
            "o": "Werpeloh",
            "l": "Niedersachsen"
        }
    ],
    "49757": [
        {
            "o": "Lahn",
            "l": "Niedersachsen"
        },
        {
            "o": "Vrees",
            "l": "Niedersachsen"
        },
        {
            "o": "Werlte",
            "l": "Niedersachsen"
        }
    ],
    "49762": [
        {
            "o": "Fresenburg",
            "l": "Niedersachsen"
        },
        {
            "o": "Lathen",
            "l": "Niedersachsen"
        },
        {
            "o": "Renkenberge",
            "l": "Niedersachsen"
        },
        {
            "o": "Sustrum",
            "l": "Niedersachsen"
        }
    ],
    "49767": [
        {
            "o": "Twist",
            "l": "Niedersachsen"
        }
    ],
    "49770": [
        {
            "o": "Dohren",
            "l": "Niedersachsen"
        },
        {
            "o": "Herzlake",
            "l": "Niedersachsen"
        }
    ],
    "49774": [
        {
            "o": "Lähden",
            "l": "Niedersachsen"
        }
    ],
    "49777": [
        {
            "o": "Groß Berßen",
            "l": "Niedersachsen"
        },
        {
            "o": "Klein Berßen",
            "l": "Niedersachsen"
        },
        {
            "o": "Stavern",
            "l": "Niedersachsen"
        }
    ],
    "49779": [
        {
            "o": "Niederlangen",
            "l": "Niedersachsen"
        },
        {
            "o": "Oberlangen",
            "l": "Niedersachsen"
        }
    ],
    "49808": [
        {
            "o": "Lingen (Ems)",
            "l": "Niedersachsen"
        }
    ],
    "49809": [
        {
            "o": "Lingen (Ems)",
            "l": "Niedersachsen"
        }
    ],
    "49811": [
        {
            "o": "Lingen (Ems)",
            "l": "Niedersachsen"
        }
    ],
    "49824": [
        {
            "o": "Emlichheim",
            "l": "Niedersachsen"
        },
        {
            "o": "Laar",
            "l": "Niedersachsen"
        },
        {
            "o": "Ringe",
            "l": "Niedersachsen"
        }
    ],
    "49828": [
        {
            "o": "Esche",
            "l": "Niedersachsen"
        },
        {
            "o": "Georgsdorf",
            "l": "Niedersachsen"
        },
        {
            "o": "Lage",
            "l": "Niedersachsen"
        },
        {
            "o": "Neuenhaus",
            "l": "Niedersachsen"
        },
        {
            "o": "Osterwald",
            "l": "Niedersachsen"
        }
    ],
    "49832": [
        {
            "o": "Andervenne",
            "l": "Niedersachsen"
        },
        {
            "o": "Beesten",
            "l": "Niedersachsen"
        },
        {
            "o": "Freren",
            "l": "Niedersachsen"
        },
        {
            "o": "Messingen",
            "l": "Niedersachsen"
        },
        {
            "o": "Thuine",
            "l": "Niedersachsen"
        }
    ],
    "49835": [
        {
            "o": "Wietmarschen",
            "l": "Niedersachsen"
        }
    ],
    "49838": [
        {
            "o": "Gersten",
            "l": "Niedersachsen"
        },
        {
            "o": "Handrup",
            "l": "Niedersachsen"
        },
        {
            "o": "Langen",
            "l": "Niedersachsen"
        },
        {
            "o": "Lengerich",
            "l": "Niedersachsen"
        },
        {
            "o": "Wettrup",
            "l": "Niedersachsen"
        }
    ],
    "49843": [
        {
            "o": "Getelo",
            "l": "Niedersachsen"
        },
        {
            "o": "Gölenkamp",
            "l": "Niedersachsen"
        },
        {
            "o": "Halle",
            "l": "Niedersachsen"
        },
        {
            "o": "Uelsen",
            "l": "Niedersachsen"
        }
    ],
    "49844": [
        {
            "o": "Bawinkel",
            "l": "Niedersachsen"
        }
    ],
    "49846": [
        {
            "o": "Hoogstede",
            "l": "Niedersachsen"
        }
    ],
    "49847": [
        {
            "o": "Itterbeck",
            "l": "Niedersachsen"
        },
        {
            "o": "Wielen",
            "l": "Niedersachsen"
        }
    ],
    "49849": [
        {
            "o": "Wilsum",
            "l": "Niedersachsen"
        }
    ],
    "50126": [
        {
            "o": "Bergheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50127": [
        {
            "o": "Bergheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50129": [
        {
            "o": "Bergheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50169": [
        {
            "o": "Kerpen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50170": [
        {
            "o": "Kerpen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50171": [
        {
            "o": "Kerpen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50181": [
        {
            "o": "Bedburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50189": [
        {
            "o": "Elsdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50226": [
        {
            "o": "Frechen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50259": [
        {
            "o": "Pulheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50321": [
        {
            "o": "Brühl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50354": [
        {
            "o": "Hürth",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50374": [
        {
            "o": "Erftstadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50389": [
        {
            "o": "Wesseling",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50667": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50668": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50670": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50672": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50674": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50676": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50677": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50678": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50679": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50733": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50735": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50737": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50739": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50765": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50767": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50769": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50823": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50825": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50827": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50829": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50858": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50859": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50931": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50933": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50935": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50937": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50939": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50968": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50969": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50996": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50997": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "50999": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51061": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51063": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51065": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51067": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51069": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51103": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51105": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51107": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51109": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51143": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51145": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51147": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51149": [
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51371": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51373": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51375": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51377": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51379": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51381": [
        {
            "o": "Leverkusen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51399": [
        {
            "o": "Burscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51427": [
        {
            "o": "Bergisch Gladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51429": [
        {
            "o": "Bergisch Gladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51465": [
        {
            "o": "Bergisch Gladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51467": [
        {
            "o": "Bergisch Gladbach",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Köln",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51469": [
        {
            "o": "Bergisch Gladbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51491": [
        {
            "o": "Overath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51503": [
        {
            "o": "Rösrath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51515": [
        {
            "o": "Kürten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51519": [
        {
            "o": "Odenthal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51545": [
        {
            "o": "Waldbröl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51570": [
        {
            "o": "Windeck",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51580": [
        {
            "o": "Reichshof",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51588": [
        {
            "o": "Nümbrecht",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51597": [
        {
            "o": "Morsbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51598": [
        {
            "o": "Friesenhagen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "51643": [
        {
            "o": "Gummersbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51645": [
        {
            "o": "Gummersbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51647": [
        {
            "o": "Gummersbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51674": [
        {
            "o": "Wiehl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51688": [
        {
            "o": "Wipperfürth",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51702": [
        {
            "o": "Bergneustadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51709": [
        {
            "o": "Marienheide",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51766": [
        {
            "o": "Engelskirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "51789": [
        {
            "o": "Lindlar",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52062": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52064": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52066": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52068": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52070": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52072": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52074": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52076": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52078": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52080": [
        {
            "o": "Aachen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52134": [
        {
            "o": "Herzogenrath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52146": [
        {
            "o": "Würselen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52152": [
        {
            "o": "Simmerath",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52156": [
        {
            "o": "Monschau",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52159": [
        {
            "o": "Roetgen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52222": [
        {
            "o": "Stolberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52223": [
        {
            "o": "Stolberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52224": [
        {
            "o": "Stolberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52249": [
        {
            "o": "Eschweiler",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52349": [
        {
            "o": "Düren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52351": [
        {
            "o": "Düren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52353": [
        {
            "o": "Düren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52355": [
        {
            "o": "Düren",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52372": [
        {
            "o": "Kreuzau",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52379": [
        {
            "o": "Langerwehe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52382": [
        {
            "o": "Niederzier",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52385": [
        {
            "o": "Nideggen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52388": [
        {
            "o": "Nörvenich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52391": [
        {
            "o": "Vettweiß",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52393": [
        {
            "o": "Hürtgenwald",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52396": [
        {
            "o": "Heimbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52399": [
        {
            "o": "Merzenich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52428": [
        {
            "o": "Jülich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52441": [
        {
            "o": "Linnich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52445": [
        {
            "o": "Titz",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52457": [
        {
            "o": "Aldenhoven",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52459": [
        {
            "o": "Inden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52477": [
        {
            "o": "Alsdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52499": [
        {
            "o": "Baesweiler",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52511": [
        {
            "o": "Geilenkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52525": [
        {
            "o": "Heinsberg",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Waldfeucht",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52531": [
        {
            "o": "Übach-Palenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "52538": [
        {
            "o": "Gangelt",
            "l": "Nordrhein-Westfalen"
        },
        {
            "o": "Selfkant",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53111": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53113": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53115": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53117": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53119": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53121": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53123": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53125": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53127": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53129": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53173": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53175": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53177": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53179": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53225": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53227": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53229": [
        {
            "o": "Bonn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53332": [
        {
            "o": "Bornheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53340": [
        {
            "o": "Meckenheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53343": [
        {
            "o": "Wachtberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53347": [
        {
            "o": "Alfter",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53359": [
        {
            "o": "Rheinbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53424": [
        {
            "o": "Remagen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53426": [
        {
            "o": "Dedenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Königsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schalkenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53474": [
        {
            "o": "Bad Neuenahr-Ahrweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53489": [
        {
            "o": "Sinzig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53498": [
        {
            "o": "Bad Breisig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gönnersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53501": [
        {
            "o": "Grafschaft",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53505": [
        {
            "o": "Altenahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kalenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirchsahr",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53506": [
        {
            "o": "Ahrbrück",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heckenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hönningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kesseling",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lind",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rech",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53507": [
        {
            "o": "Dernau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53508": [
        {
            "o": "Mayschoß",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53518": [
        {
            "o": "Adenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herschbroich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Honerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kottenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Quiddelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wimbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53520": [
        {
            "o": "Dankerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Drees",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dümpelfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Harscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hümmel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Insul",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaltenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meuspath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Müllenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nürburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ohlenhard",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reifferscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rodder",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schuld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Senscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sierscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trierscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wershofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winnerath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53533": [
        {
            "o": "Antweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Aremberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dorsel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eichenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fuchshofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Müsch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53534": [
        {
            "o": "Barweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bauler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hoffeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pomster",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiesemscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wirft",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53539": [
        {
            "o": "Bodenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bongard",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Borler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brücktal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gelenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kelberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reimerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welcherath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53545": [
        {
            "o": "Linz am Rhein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ockenfels",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53547": [
        {
            "o": "Bad Hönningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breitscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dattenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hausen (Wied)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hümmerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kasbach-Ohlenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leubsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roßbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53557": [
        {
            "o": "Bad Hönningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53560": [
        {
            "o": "Linz am Rhein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vettelschoß",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53562": [
        {
            "o": "Sankt Katharinen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53567": [
        {
            "o": "Asbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buchholz (Westerwald)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53572": [
        {
            "o": "Bruchhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Unkel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53577": [
        {
            "o": "Neustadt (Wied)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53578": [
        {
            "o": "Windhagen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53579": [
        {
            "o": "Erpel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53604": [
        {
            "o": "Bad Honnef",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53619": [
        {
            "o": "Rheinbreitbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "53639": [
        {
            "o": "Königswinter",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53721": [
        {
            "o": "Siegburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53757": [
        {
            "o": "Sankt Augustin",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53773": [
        {
            "o": "Hennef (Sieg)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53783": [
        {
            "o": "Eitorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53797": [
        {
            "o": "Lohmar",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53804": [
        {
            "o": "Much",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53809": [
        {
            "o": "Ruppichteroth",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53819": [
        {
            "o": "Neunkirchen-Seelscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53840": [
        {
            "o": "Troisdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53842": [
        {
            "o": "Troisdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53844": [
        {
            "o": "Troisdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53859": [
        {
            "o": "Niederkassel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53879": [
        {
            "o": "Euskirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53881": [
        {
            "o": "Euskirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53894": [
        {
            "o": "Mechernich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53902": [
        {
            "o": "Bad Münstereifel",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53909": [
        {
            "o": "Zülpich",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53913": [
        {
            "o": "Swisttal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53919": [
        {
            "o": "Weilerswist",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53925": [
        {
            "o": "Kall",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53937": [
        {
            "o": "Schleiden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53940": [
        {
            "o": "Hellenthal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53945": [
        {
            "o": "Blankenheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53947": [
        {
            "o": "Nettersheim",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "53949": [
        {
            "o": "Dahlem",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "54290": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54292": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54293": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54294": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54295": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54296": [
        {
            "o": "Trier",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54298": [
        {
            "o": "Aach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eisenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gilzem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Igel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederweis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Orenhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welschbillig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54306": [
        {
            "o": "Kordel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54308": [
        {
            "o": "Langsur",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54309": [
        {
            "o": "Newel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54310": [
        {
            "o": "Menningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Minden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ralingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54311": [
        {
            "o": "Trierweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54313": [
        {
            "o": "Zemmer",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54314": [
        {
            "o": "Baldringen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Greimerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hentern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Paschel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schömerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vierherrenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zerf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54316": [
        {
            "o": "Bonerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Franzenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hinzenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hockweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holzerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lampaden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ollmuth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pluwig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schöndorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54317": [
        {
            "o": "Farschweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gusterath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gutweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kasel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Korlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lorscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Morscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Osburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Riveris",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sommerau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thomm",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54318": [
        {
            "o": "Mertesdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54320": [
        {
            "o": "Waldrach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54329": [
        {
            "o": "Konz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54331": [
        {
            "o": "Oberbillig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pellingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54332": [
        {
            "o": "Wasserliesch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54338": [
        {
            "o": "Longen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54340": [
        {
            "o": "Bekond",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Detzem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ensch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Klüsserath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Köwerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leiwen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Longuich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Naurath (Eifel)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pölich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Riol",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schleich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thörnich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54341": [
        {
            "o": "Fell",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54343": [
        {
            "o": "Föhren",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54344": [
        {
            "o": "Kenn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54346": [
        {
            "o": "Mehring",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54347": [
        {
            "o": "Neumagen-Dhron",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54349": [
        {
            "o": "Trittenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54411": [
        {
            "o": "Deuselbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hermeskeil",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rorodt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54413": [
        {
            "o": "Bescheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Beuren (Hochwald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Damflos",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Geisfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Grimburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gusenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rascheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54421": [
        {
            "o": "Hinzert-Pölert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reinsfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54422": [
        {
            "o": "Börfink",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuhütten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Züsch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54424": [
        {
            "o": "Burtscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Etgert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gielert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lückenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thalfang",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54426": [
        {
            "o": "Berglicht",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breit",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Büdlich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dhronecken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gräfendhron",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heidenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hilscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Immert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Malborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Naurath (Wald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neunkirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Talling",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54427": [
        {
            "o": "Kell am See",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54429": [
        {
            "o": "Heddert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mandern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schillingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54439": [
        {
            "o": "Fisch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merzkirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Palzem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Saarburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54441": [
        {
            "o": "Ayl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kanzem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kastel-Staadt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mannebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ockfen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schoden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Taben-Rodt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Temmels",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trassem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wawern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wellen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54450": [
        {
            "o": "Freudenburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54451": [
        {
            "o": "Irsch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54453": [
        {
            "o": "Nittel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54455": [
        {
            "o": "Serrig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54456": [
        {
            "o": "Onsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Tawern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54457": [
        {
            "o": "Wincheringen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54459": [
        {
            "o": "Wiltingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54470": [
        {
            "o": "Bernkastel-Kues",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brauneberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Graach an der Mosel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lieser",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54472": [
        {
            "o": "Brauneberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burgen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gornhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hochscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kommen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Longkamp",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Monzelfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Veldenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54483": [
        {
            "o": "Kleinich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54484": [
        {
            "o": "Maring-Noviand",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54486": [
        {
            "o": "Mülheim an der Mosel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54487": [
        {
            "o": "Wintrich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54492": [
        {
            "o": "Bernkastel-Kues",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lösnich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zeltingen-Rachtig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54497": [
        {
            "o": "Horath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Morbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54498": [
        {
            "o": "Piesport",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54516": [
        {
            "o": "Flußbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wittlich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54518": [
        {
            "o": "Altrich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Arenrath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bergweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Binsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dodenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dreis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Esch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gladbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heckenmünster",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heidweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hupperath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kesten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Minderlittgen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Minheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Osann-Monzel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Platten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Plein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rivenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sehlem",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54523": [
        {
            "o": "Dierscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heckenmünster",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hetzerath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54524": [
        {
            "o": "Klausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54526": [
        {
            "o": "Landscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54528": [
        {
            "o": "Salmtal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54529": [
        {
            "o": "Spangdahlem",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54531": [
        {
            "o": "Eckfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Manderscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meerfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pantenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54533": [
        {
            "o": "Bettenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dierfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eisenschmitt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gipperath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gransdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Greimerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hasborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laufeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederöfflingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederscheidweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberkail",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberöfflingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberscheidweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwarzenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Willwerscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54534": [
        {
            "o": "Großlittgen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Karl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Musweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schladt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54536": [
        {
            "o": "Kröv",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54538": [
        {
            "o": "Bausendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bengel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Diefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hontheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kinderbeuern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kinheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54539": [
        {
            "o": "Ürzig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zeltingen-Rachtig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54550": [
        {
            "o": "Daun",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54552": [
        {
            "o": "Beinhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Boxberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brockscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Darscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Demerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dockweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dreis-Brück",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ellscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gefell",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hörscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hörschhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Immerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Katzwinkel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kradenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mehren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neichen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nerdlen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sarmersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schalkenmehren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steineberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steiningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Strotzbüsch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Üdersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Udler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Utzerath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54558": [
        {
            "o": "Gillenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mückeln",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Saxler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Strohn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winkel (Eifel)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54568": [
        {
            "o": "Gerolstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54570": [
        {
            "o": "Berlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Betteldorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bleckhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Densborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Deudesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hinterweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hohenfels-Essingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kalenborn-Scheuern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirchweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meisburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mürlenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederstadtfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberstadtfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pelm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rockeskyll",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Salm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schutz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weidenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54574": [
        {
            "o": "Birresborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kopp",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54576": [
        {
            "o": "Dohm-Lammersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hillesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54578": [
        {
            "o": "Basberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berndorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kerpen (Eifel)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nohn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberbettingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberehe-Stroheich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Walsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiesbaum",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54579": [
        {
            "o": "Üxheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54584": [
        {
            "o": "Feusdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gönnersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jünkerath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54585": [
        {
            "o": "Esch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54586": [
        {
            "o": "Schüller",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54587": [
        {
            "o": "Birgel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lissendorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54589": [
        {
            "o": "Kerschenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stadtkyll",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54595": [
        {
            "o": "Gondenbrett",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Orlenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pittenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Prüm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Watzerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weinsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54597": [
        {
            "o": "Auw bei Prüm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Balesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Duppach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Euscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Feuerscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fleringen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Habscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kinzenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinlangenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lascheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lierfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lünebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Masthorn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Matzerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merlscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuheilenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Olzheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ormont",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Plütscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pronsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reuth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rommersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth bei Prüm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwirzheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seiwerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steffeln",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Strickscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54608": [
        {
            "o": "Bleialf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brandscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buchet",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großlangenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mützenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberlascheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sellerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54610": [
        {
            "o": "Büdesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54611": [
        {
            "o": "Hallschlag",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Scheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54612": [
        {
            "o": "Lasel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nimshuscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wawern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54614": [
        {
            "o": "Dingdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Giesdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heisdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederlauch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nimsreuland",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberlauch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönecken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winringen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54616": [
        {
            "o": "Winterspelt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54617": [
        {
            "o": "Harspelt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lützkampen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sevenig (Our)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54619": [
        {
            "o": "Eschfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großkampenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heckhuscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herzfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leidenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lichtenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reiff",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sengerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Üttfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54634": [
        {
            "o": "Birtlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bitburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Metterich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederstedem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberstedem",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54636": [
        {
            "o": "Altscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Baustert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bickendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Biersdorf am See",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brecht",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dahlem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dockendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Echtershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ehlenz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eßlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Feilsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fließem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hamm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heilenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hütterscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hüttingen an der Kyll",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Idenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Idesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ingendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ließem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meckel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Messerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mülbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nattenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberweis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rittersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Röhl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Scharfbillig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schleid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seffern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sefferweich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sülm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trimport",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weidingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiersdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wißmannsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wolsfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54646": [
        {
            "o": "Bettingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brimingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Enzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Halsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niehl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Olsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stockem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wettlingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54647": [
        {
            "o": "Dudeldorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gondorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pickließem",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54649": [
        {
            "o": "Dackscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eilscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hargarten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lambertsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lauperath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Manderscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mauel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederpierscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberpierscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pintesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waxweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54655": [
        {
            "o": "Etteldorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kyllburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kyllburgweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Malberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Malbergweich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Orsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Thomas",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seinsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Usch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wilsecker",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zendscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54657": [
        {
            "o": "Badem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gindorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neidenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54662": [
        {
            "o": "Beilingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herforst",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Philippsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Speicher",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54664": [
        {
            "o": "Auw an der Kyll",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hosten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Preist",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54666": [
        {
            "o": "Irrel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54668": [
        {
            "o": "Alsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Echternacherbrück",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ernzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ferschweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holsthum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaschenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederweis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Peffingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Prümzurlay",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schankweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54669": [
        {
            "o": "Bollendorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54673": [
        {
            "o": "Ammeldingen bei Neuerburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bauler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berkoth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dauwelshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Emmelbaum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gemünd",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heilbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herbstmühle",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hütten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Karlshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Keppeshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Koxhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krautscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Muxerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nasingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuerburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Plascheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Preischeid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rodershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Scheitenkorb",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Scheuern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sevenig bei Neuerburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Uppershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldhof-Falkenstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zweifelscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54675": [
        {
            "o": "Ammeldingen an der Our",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Biesdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fischbach-Oberraden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Geichlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gentingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hommerdingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hüttingen bei Lahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Körperich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kruchten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mettendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedergeckler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederraden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nusbaum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obergeckler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth an der Our",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sinspelt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Utscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallendorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54687": [
        {
            "o": "Arzfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "54689": [
        {
            "o": "Affler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dahnen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Daleiden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dasburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Irrhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jucken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kickeshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Olmscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Preischeid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reipeldingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Übereisenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55116": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55118": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55120": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55122": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55124": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55126": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55127": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55128": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55129": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55130": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55131": [
        {
            "o": "Mainz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55218": [
        {
            "o": "Ingelheim am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55232": [
        {
            "o": "Alzey",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ensheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55234": [
        {
            "o": "Albig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bechenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bechtolsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bermersheim vor der Höhe",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Biebelnheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dintesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eppelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erbes-Büdesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Esselborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Flomborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Framersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freimersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gau-Heppenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hangen-Weisheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hochborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kettenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Monzernheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nack",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nieder-Wiesen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ober-Flörsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Offenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wahlheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wendelsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55237": [
        {
            "o": "Bornheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Flonheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lonsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55239": [
        {
            "o": "Gau-Odernheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55246": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "55252": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "55257": [
        {
            "o": "Budenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55262": [
        {
            "o": "Ingelheim am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55263": [
        {
            "o": "Ingelheim am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55268": [
        {
            "o": "Nieder-Olm",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55270": [
        {
            "o": "Bubenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Engelstadt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Essenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jugenheim in Rheinhessen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Klein-Winternheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ober-Olm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwabenheim an der Selz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sörgenloch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zornheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55271": [
        {
            "o": "Stadecken-Elsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55276": [
        {
            "o": "Dienheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oppenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55278": [
        {
            "o": "Dalheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dexheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dolgesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eimsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Friesenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahnheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Köngernheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ludwigshöhe",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mommenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Selzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Uelversheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Undenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weinolsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55283": [
        {
            "o": "Nierstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55286": [
        {
            "o": "Sulzheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wörrstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55288": [
        {
            "o": "Armsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gabsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Partenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schornsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spiesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Udenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55291": [
        {
            "o": "Saulheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55294": [
        {
            "o": "Bodenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55296": [
        {
            "o": "Gau-Bischofsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Harxheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lörzweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55299": [
        {
            "o": "Nackenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55411": [
        {
            "o": "Bingen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55413": [
        {
            "o": "Manubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederheimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberdiebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberheimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trechtingshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weiler bei Bingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55422": [
        {
            "o": "Bacharach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breitscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55424": [
        {
            "o": "Münster-Sarmsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55425": [
        {
            "o": "Waldalgesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55430": [
        {
            "o": "Oberwesel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Perscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Urbar",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55432": [
        {
            "o": "Damscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55435": [
        {
            "o": "Gau-Algesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55437": [
        {
            "o": "Appenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nieder-Hilbersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ober-Hilbersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ockenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55442": [
        {
            "o": "Daxweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stromberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Warmsroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55444": [
        {
            "o": "Dörrebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eckenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schöneberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweppenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seibersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldlaubersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55450": [
        {
            "o": "Langenlonsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55452": [
        {
            "o": "Dorsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Guldental",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hergenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laubenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rümmelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Windesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55457": [
        {
            "o": "Gensingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horrweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55459": [
        {
            "o": "Aspisheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Grolsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55469": [
        {
            "o": "Altweidelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Belgweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bergenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Budenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Klosterkumbd",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mutterschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nannhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederkumbd",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ohlweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oppertshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pleizenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rayerschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Riegenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Simmern/Hunsrück",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55471": [
        {
            "o": "Biebern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fronhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Keidelheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Külz (Hunsrück)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kümbdchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuerkirch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ravengiersburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sargenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Tiefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wüschheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55481": [
        {
            "o": "Dillendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hecken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirchberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kludenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lindenschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Maitzborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Metzenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nieder Kostenz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ober Kostenz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reckershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rödern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwarzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Todenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Womrath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55483": [
        {
            "o": "Bärenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dickenschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heinzenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hirschfeld (Hunsrück)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horbruch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kappel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krummenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lautzenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schlierschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Unzenberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55487": [
        {
            "o": "Dill",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laufersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedersohren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sohren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sohrschied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55490": [
        {
            "o": "Gehlweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gemünden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Henau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mengerschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rohrbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Woppenroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55491": [
        {
            "o": "Büchenbeuren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wahlenau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55494": [
        {
            "o": "Benzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dichtelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Liebshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rheinböllen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wahlbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55496": [
        {
            "o": "Argenthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55497": [
        {
            "o": "Ellern (Hunsrück)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schnorbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55499": [
        {
            "o": "Riesweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55543": [
        {
            "o": "Bad Kreuznach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55545": [
        {
            "o": "Bad Kreuznach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55546": [
        {
            "o": "Biebelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frei-Laubersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fürfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hackenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neu-Bamberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pfaffen-Schwabenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Tiefenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Volxheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55559": [
        {
            "o": "Bretzenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55566": [
        {
            "o": "Bad Sobernheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Daubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ippenschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirschroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meddersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rehbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55568": [
        {
            "o": "Abtweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lauschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Staudernheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55569": [
        {
            "o": "Auen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Monzingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nußbaum",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55571": [
        {
            "o": "Odernheim am Glan",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55576": [
        {
            "o": "Badenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pleitersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sprendlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welgesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zotzenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55578": [
        {
            "o": "Gau-Weinheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Johann",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vendersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallertheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wolfsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55583": [
        {
            "o": "Bad Kreuznach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55585": [
        {
            "o": "Altenbamberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Duchroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hochstätten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Norheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhausen an der Nahe",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55590": [
        {
            "o": "Meisenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55592": [
        {
            "o": "Breitenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Desloch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jeckenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Raumbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rehborn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55593": [
        {
            "o": "Rüdesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55595": [
        {
            "o": "Allenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Argenschwang",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bockenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Boos",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Braunweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burgsponheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dalberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gebroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gutenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hargesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hüffelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mandel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Münchwald",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roxheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Katharinen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sommerloch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spabrücken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spall",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sponheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Traisen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weinsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55596": [
        {
            "o": "Oberstreit",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schloßböckelheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldböckelheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55597": [
        {
            "o": "Gumbsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wöllstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55599": [
        {
            "o": "Eckelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gau-Bickelheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Siefersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stein-Bockenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wonsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55606": [
        {
            "o": "Bärweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brauweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahnenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heimweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heinzenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hochstetten-Dhaun",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kellenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Königsau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Limbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meckenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhausen bei Kirn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Otzweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55608": [
        {
            "o": "Becherbach bei Kirn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bergen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berschweiler bei Kirn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Griebelschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schneppenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55618": [
        {
            "o": "Simmertal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55619": [
        {
            "o": "Hennweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55621": [
        {
            "o": "Hundsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55624": [
        {
            "o": "Bollenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gösenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberkirn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rhaunen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwerbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weitersbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55626": [
        {
            "o": "Bundenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55627": [
        {
            "o": "Martinstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merxheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weiler bei Monzingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55629": [
        {
            "o": "Schwarzerden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seesbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weitersborn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55743": [
        {
            "o": "Fischbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gerach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hintertiefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Idar-Oberstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirschweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55756": [
        {
            "o": "Herrstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55758": [
        {
            "o": "Allenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Asbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bärenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breitenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruchweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dickesbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hellertshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hettenrodt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hottenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kempfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mackenrodt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mittelreidenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhosenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederwörresbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhosenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberreidenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberwörresbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schauren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmidthachenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sensweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sien",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sienhachenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sonnschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stipshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sulzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Veitsrodt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vollmersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weiden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wickenrodt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wirschweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55765": [
        {
            "o": "Birkenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dienstweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Elchweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ellenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ellweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rimsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmißberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55767": [
        {
            "o": "Abentheuer",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Achtelsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brücken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buhlenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gimbweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gollenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hattgenstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kronweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leisel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meckenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederbrombach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nohen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberbrombach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rinzenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rötsweiler-Nockenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwollen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Siesbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sonnenberg-Winnenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wilzenberg-Hußweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55768": [
        {
            "o": "Hoppstädten-Weiersbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55774": [
        {
            "o": "Baumholder",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55776": [
        {
            "o": "Berglangenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frauenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahnweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reichenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rohrbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rückweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruschberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55777": [
        {
            "o": "Berschweiler bei Baumholder",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eckersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fohren-Linden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mettweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "55779": [
        {
            "o": "Heimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leitzweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56068": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56070": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56072": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56073": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56075": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56076": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56077": [
        {
            "o": "Koblenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56112": [
        {
            "o": "Lahnstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56130": [
        {
            "o": "Bad Ems",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lahnstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56132": [
        {
            "o": "Becheln",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dausenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frücht",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kemmenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Miellen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nievern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56133": [
        {
            "o": "Fachbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56154": [
        {
            "o": "Boppard",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56170": [
        {
            "o": "Bendorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56179": [
        {
            "o": "Niederwerth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vallendar",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56182": [
        {
            "o": "Urbar",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56191": [
        {
            "o": "Weitersburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56203": [
        {
            "o": "Höhr-Grenzhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56204": [
        {
            "o": "Hillscheid",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56206": [
        {
            "o": "Hilgert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kammerforst",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56218": [
        {
            "o": "Mülheim-Kärlich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56220": [
        {
            "o": "Bassenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaltenengers",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kettig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Sebastian",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Urmitz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56235": [
        {
            "o": "Hundsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ransbach-Baumbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56237": [
        {
            "o": "Alsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breitenau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Caan",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Deesen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nauort",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhaid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sessenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wirscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wittgert",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56242": [
        {
            "o": "Ellenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Marienrachdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nordhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Quirnbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Selters (Westerwald)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56244": [
        {
            "o": "Arnshöfen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ettinghausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ewighausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freilingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freirachdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Goddert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahn am See",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hartenfels",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Helferskirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krümmel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kuhnhöfen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leuterod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Maxsain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedersayn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ötzingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rückeroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schenkelberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sessenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vielbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weidenhahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wölferlingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56249": [
        {
            "o": "Herschbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56253": [
        {
            "o": "Treis-Karden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56254": [
        {
            "o": "Moselkern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Müden (Mosel)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56269": [
        {
            "o": "Dierdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Marienhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56271": [
        {
            "o": "Isenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinmaischeid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Maroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mündersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roßbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56276": [
        {
            "o": "Großmaischeid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stebach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56281": [
        {
            "o": "Dörth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Emmelshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hungenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Karbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwall",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56283": [
        {
            "o": "Beulich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gondershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Halsenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kratzenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mermuth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Morshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ney",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nörtershausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56288": [
        {
            "o": "Alterkülz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bell (Hunsrück)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Braunshorn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hasselbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hollnich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kastellaun",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Korweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Michelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spesenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zilshausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56290": [
        {
            "o": "Beltheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dommershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gödenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lieg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lütz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Macken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Uhler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56291": [
        {
            "o": "Badenhard",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bickenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Birkheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hausbay",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kisselbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laudert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leiningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lingerhahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Maisborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mühlpfad",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Norath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pfalzfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thörlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Utzenhain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiebelsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56294": [
        {
            "o": "Gappenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gierschnach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kalt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Münstermaifeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wierschem",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56295": [
        {
            "o": "Kerben",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lonnig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rüber",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56299": [
        {
            "o": "Ochtendung",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56305": [
        {
            "o": "Döttesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Puderbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56307": [
        {
            "o": "Dernbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dürrholz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Harschbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56316": [
        {
            "o": "Hanroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Raubach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56317": [
        {
            "o": "Linkenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Urbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56321": [
        {
            "o": "Brey",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rhens",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56322": [
        {
            "o": "Spay",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56323": [
        {
            "o": "Rhens",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldesch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56329": [
        {
            "o": "Sankt Goar",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56330": [
        {
            "o": "Kobern-Gondorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56332": [
        {
            "o": "Alken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brodenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burgen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dieblich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hatzenport",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lehmen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Löf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederfell",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberfell",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wolken",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56333": [
        {
            "o": "Winningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56335": [
        {
            "o": "Neuhäusel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56337": [
        {
            "o": "Arzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eitelborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kadenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Simmern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56338": [
        {
            "o": "Braubach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56340": [
        {
            "o": "Dachsenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Osterspai",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56341": [
        {
            "o": "Filsen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kamp-Bornhofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56346": [
        {
            "o": "Lykershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Prath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Goarshausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56348": [
        {
            "o": "Bornich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dahlheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dörscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kestert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Patersberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weisel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56349": [
        {
            "o": "Kaub",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56355": [
        {
            "o": "Bettendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Diethardt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Endlichhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hunzel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kehlbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lautert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nastätten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberbachheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weidenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterwerb",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56357": [
        {
            "o": "Auel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bogel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dessighofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dornholzhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ehr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Geisig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gemmerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hainau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Himmighofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holzhausen an der Haide",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kasdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lierschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lipporn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lollschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Marienfels",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Miehlen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederbachheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederwallmenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nochern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obertiefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberwallmenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oelsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pohl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reichenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reitzenhain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rettershain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruppertshofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Strüth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welterod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weyer",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56368": [
        {
            "o": "Berghausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ergeshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herold",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Katzenelnbogen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Klingelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedertiefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56370": [
        {
            "o": "Allendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Attenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berndroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Biebrich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bremberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dörsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ebertshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eisighofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gutenacker",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kördorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mittelfischbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberfischbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reckenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rettert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wasenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56377": [
        {
            "o": "Dörnberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Misselberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nassau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweighausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seelbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56379": [
        {
            "o": "Charlottenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dienethal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dörnberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Geilnau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holzappel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hömberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laurenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obernhof",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberwies",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Scheidt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Singhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sulzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weinähr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zimmerschied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56410": [
        {
            "o": "Montabaur",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56412": [
        {
            "o": "Boden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Daubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gackenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Girod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Görgeshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großholbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heilberscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heiligenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hübingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nentershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederelbert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedererbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nomborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberelbert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruppach-Goldhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stahlhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Untershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welschneudorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56414": [
        {
            "o": "Berod bei Wallmerod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bilkheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dreikirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hundsangen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meudt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Molsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obererbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Salz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinefrenz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallmerod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zehnhausen bei Wallmerod",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56422": [
        {
            "o": "Wirges",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56424": [
        {
            "o": "Bannberscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ebernhahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mogendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Moschheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Staudt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56427": [
        {
            "o": "Siershahn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56428": [
        {
            "o": "Dernbach (Westerwald)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56457": [
        {
            "o": "Halbs",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hergenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Westerburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56459": [
        {
            "o": "Ailertchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bellingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berzhahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brandscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Elbingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gemünden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Girkenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Guckheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Härtlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kölbingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenhahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mähren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pottum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rotenhain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rothenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stahlhofen am Wiesensee",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stockum-Püschen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weltersburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Willmenrod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winnen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56462": [
        {
            "o": "Höhn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56470": [
        {
            "o": "Bad Marienberg (Westerwald)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56472": [
        {
            "o": "Dreisbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fehl-Ritzhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großseifen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahn bei Marienberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hardt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hof",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lautzenbrücken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nisterau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nisterberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stockhausen-Illfurth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56477": [
        {
            "o": "Nister-Möhrendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rennerod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waigandshain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zehnhausen bei Rennerod",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56479": [
        {
            "o": "Bretthausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Elsoff (Westerwald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hellenhahn-Schellenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Homberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hüblingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Irmtraut",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Liebenscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neunkirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neustadt/ Westerwald",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederroßbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberrod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberroßbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rehe",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Salzburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seck",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stein-Neukirch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldmühlen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Westernohe",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Willingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56564": [
        {
            "o": "Neuwied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56566": [
        {
            "o": "Neuwied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56567": [
        {
            "o": "Neuwied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56575": [
        {
            "o": "Weißenthurm",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56579": [
        {
            "o": "Bonefeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hardert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rengsdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56581": [
        {
            "o": "Ehlscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kurtscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Melsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56584": [
        {
            "o": "Anhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Meinborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rüscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thalhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56587": [
        {
            "o": "Oberhonnefeld-Gierend",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberraden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Straßenhaus",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56588": [
        {
            "o": "Hausen (Wied)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldbreitbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56589": [
        {
            "o": "Datzeroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederbreitbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56593": [
        {
            "o": "Bürdenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Güllesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horhausen (Westerwald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krunkel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedersteinebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obersteinebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pleckhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56594": [
        {
            "o": "Willroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56598": [
        {
            "o": "Hammerstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rheinbrohl",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56599": [
        {
            "o": "Leutesdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56626": [
        {
            "o": "Andernach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56630": [
        {
            "o": "Kretz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56637": [
        {
            "o": "Plaidt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56642": [
        {
            "o": "Kruft",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56645": [
        {
            "o": "Nickenich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56648": [
        {
            "o": "Saffig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56651": [
        {
            "o": "Brenk",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Galenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederdürenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederzissen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberdürenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberzissen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56653": [
        {
            "o": "Glees",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wassenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wehr",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56656": [
        {
            "o": "Brohl-Lützing",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56659": [
        {
            "o": "Burgbrohl",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56727": [
        {
            "o": "Mayen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reudelsterz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Johann",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56729": [
        {
            "o": "Acht",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Anschau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Arft",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Baar",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bermel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Boos",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ditscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ettringen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herresbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hirten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kehrig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirchwald",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lind",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Luxem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mayen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Monreal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Münk",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nachtsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nitz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Siebenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Virneburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welschenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56736": [
        {
            "o": "Kottenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56743": [
        {
            "o": "Mendig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thür",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56745": [
        {
            "o": "Bell",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hausten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rieden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Volkesfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weibern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56746": [
        {
            "o": "Hohenleimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kempenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spessart",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56751": [
        {
            "o": "Einig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gering",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kollig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Polch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56753": [
        {
            "o": "Mertloch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Naunheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pillig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trimbs",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welling",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56754": [
        {
            "o": "Binningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brohl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dünfus",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Forst (Eifel)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Möntenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roes",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56759": [
        {
            "o": "Eppenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaisersesch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kalenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leienkaul",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56761": [
        {
            "o": "Brachtendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Düngenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eulgem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gamlen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hambuch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hauroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaifenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Masburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Müllenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Urmersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zettingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56766": [
        {
            "o": "Auderath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Berenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Filz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horperath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ulmen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56767": [
        {
            "o": "Gunderath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Höchstberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaperich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kolverath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kötterichen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lirstal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mosbruch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberelz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sassen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Uersfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ueß",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56769": [
        {
            "o": "Arbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bereborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mannebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Retterath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56812": [
        {
            "o": "Cochem",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dohr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Valwig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56814": [
        {
            "o": "Beilstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bremm",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruttig-Fankel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ediger-Eller",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ernst",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Faid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Greimersburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Illerich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Landkern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wirfus",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56818": [
        {
            "o": "Klotten",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56820": [
        {
            "o": "Briedern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mesenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nehren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Senheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56821": [
        {
            "o": "Ellenz-Poltersdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56823": [
        {
            "o": "Büchel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56825": [
        {
            "o": "Beuren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gevenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gillenbeuren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kliding",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmitt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Urschmitt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56826": [
        {
            "o": "Lutzerath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wagenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wollmerath",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56828": [
        {
            "o": "Alflen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56829": [
        {
            "o": "Brieden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kail",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pommern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56841": [
        {
            "o": "Traben-Trarbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56843": [
        {
            "o": "Burg (Mosel)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Irmenach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lötzbeuren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Starkenburg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56850": [
        {
            "o": "Briedel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Enkirch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Raversbeuren",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56856": [
        {
            "o": "Zell (Mosel)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56858": [
        {
            "o": "Altlay",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Altstrimmig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Belg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Forst (Hunsrück)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Grenderich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Haserich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Liesenich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mittelstrimmig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neef",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Peterswald-Löffelscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rödelhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Aldegund",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sosberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Tellig",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Würrich",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zell (Mosel)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56859": [
        {
            "o": "Alf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bullay",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zell (Mosel)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56861": [
        {
            "o": "Reil",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56862": [
        {
            "o": "Pünderich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56864": [
        {
            "o": "Bad Bertrich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56865": [
        {
            "o": "Blankenrath",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hesweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Moritzheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Panzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reidenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schauren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Walhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56867": [
        {
            "o": "Briedel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "56869": [
        {
            "o": "Mastershausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57072": [
        {
            "o": "Siegen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57074": [
        {
            "o": "Siegen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57076": [
        {
            "o": "Siegen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57078": [
        {
            "o": "Siegen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57080": [
        {
            "o": "Siegen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57223": [
        {
            "o": "Kreuztal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57234": [
        {
            "o": "Wilnsdorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57250": [
        {
            "o": "Netphen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57258": [
        {
            "o": "Freudenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57271": [
        {
            "o": "Hilchenbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57290": [
        {
            "o": "Neunkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57299": [
        {
            "o": "Burbach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57319": [
        {
            "o": "Bad Berleburg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57334": [
        {
            "o": "Bad Laasphe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57339": [
        {
            "o": "Erndtebrück",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57368": [
        {
            "o": "Lennestadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57392": [
        {
            "o": "Schmallenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57399": [
        {
            "o": "Kirchhundem",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57413": [
        {
            "o": "Finnentrop",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57439": [
        {
            "o": "Attendorn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57462": [
        {
            "o": "Olpe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57482": [
        {
            "o": "Wenden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57489": [
        {
            "o": "Drolshagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "57518": [
        {
            "o": "Alsdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Betzdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steineroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57520": [
        {
            "o": "Derschen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dickendorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Emmerzhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Friedewald",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Grünebach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenbach bei Kirburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mauden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Molzhain",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neunkhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederdreisbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rosenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schutzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinebach/Sieg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57537": [
        {
            "o": "Forst",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hövels",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mittelhof",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Selbach (Sieg)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wissen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57539": [
        {
            "o": "Bitzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breitscheidt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruchertseifen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Etzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fürthen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57548": [
        {
            "o": "Kirchen (Sieg)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57555": [
        {
            "o": "Brachbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mudersbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57562": [
        {
            "o": "Herdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57567": [
        {
            "o": "Daaden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57572": [
        {
            "o": "Harbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederfischbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57577": [
        {
            "o": "Hamm (Sieg)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seelbach bei Hamm (Sieg)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57578": [
        {
            "o": "Elkenroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57580": [
        {
            "o": "Elben",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fensdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gebhardshain",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57581": [
        {
            "o": "Katzwinkel (Sieg)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57583": [
        {
            "o": "Mörlen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nauroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57584": [
        {
            "o": "Scheuerfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallmenroth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57586": [
        {
            "o": "Weitefeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57587": [
        {
            "o": "Birken-Honigsessen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57589": [
        {
            "o": "Birkenbeul",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederirsen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pracht",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57610": [
        {
            "o": "Almersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Altenkirchen (Westerwald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bachenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gieleroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ingelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Michelbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57612": [
        {
            "o": "Birnbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Busenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eichelhardt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Giesenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Helmenzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Helmeroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hemmelzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heupelzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hilgenroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Idelberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Isert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kettenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kroppach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obererbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ölsen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Racksen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Volkerzen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57614": [
        {
            "o": "Berod bei Hachenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Borod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fluterschen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mudenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederwambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberwambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ratzert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steimel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stürzelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wahlrod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Woldert",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57627": [
        {
            "o": "Astert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gehlert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hachenburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heuzert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Marzhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57629": [
        {
            "o": "Atzelgift",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dreifelden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heimborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Höchstenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kundert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Limbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Linden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lochum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Luckenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Malberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merkelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Müschenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Norken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinebach an der Wied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stein-Wingert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Streithausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wied",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57632": [
        {
            "o": "Berzhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burglahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eichen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eulenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Flammersfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Giershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kescheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Orfgen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Peterslahr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reiferscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rott",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schürdt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seelbach (Westerwald)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seifen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Walterschen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ziegenhain",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57635": [
        {
            "o": "Ersfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fiersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Forstmehren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hasselbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hirz-Maulsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kircheib",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kraam",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mehren",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberirsen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rettersen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Werkhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weyerbusch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wölmersen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57636": [
        {
            "o": "Mammelzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sörth",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57638": [
        {
            "o": "Neitersen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obernau",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schöneberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57639": [
        {
            "o": "Oberdreis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rodenbach bei Puderbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57641": [
        {
            "o": "Oberlahr",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57642": [
        {
            "o": "Alpenrod",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57644": [
        {
            "o": "Hattert",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welkenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winkelbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57645": [
        {
            "o": "Nister",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57647": [
        {
            "o": "Alpenrod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Enspel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nistertal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "57648": [
        {
            "o": "Bölsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Unnau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "58089": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58091": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58093": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58095": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58097": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58099": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58119": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58135": [
        {
            "o": "Hagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58239": [
        {
            "o": "Schwerte",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58256": [
        {
            "o": "Ennepetal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58285": [
        {
            "o": "Gevelsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58300": [
        {
            "o": "Wetter (Ruhr)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58313": [
        {
            "o": "Herdecke",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58332": [
        {
            "o": "Schwelm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58339": [
        {
            "o": "Breckerfeld",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58452": [
        {
            "o": "Witten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58453": [
        {
            "o": "Witten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58454": [
        {
            "o": "Witten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58455": [
        {
            "o": "Witten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58456": [
        {
            "o": "Witten",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58507": [
        {
            "o": "Lüdenscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58509": [
        {
            "o": "Lüdenscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58511": [
        {
            "o": "Lüdenscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58513": [
        {
            "o": "Lüdenscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58515": [
        {
            "o": "Lüdenscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58540": [
        {
            "o": "Meinerzhagen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58553": [
        {
            "o": "Halver",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58566": [
        {
            "o": "Kierspe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58579": [
        {
            "o": "Schalksmühle",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58636": [
        {
            "o": "Iserlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58638": [
        {
            "o": "Iserlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58640": [
        {
            "o": "Iserlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58642": [
        {
            "o": "Iserlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58644": [
        {
            "o": "Iserlohn",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58675": [
        {
            "o": "Hemer",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58706": [
        {
            "o": "Menden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58708": [
        {
            "o": "Menden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58710": [
        {
            "o": "Menden",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58730": [
        {
            "o": "Fröndenberg/Ruhr",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58739": [
        {
            "o": "Wickede (Ruhr)",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58762": [
        {
            "o": "Altena",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58769": [
        {
            "o": "Nachrodt-Wiblingwerde",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58791": [
        {
            "o": "Werdohl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58802": [
        {
            "o": "Balve",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58809": [
        {
            "o": "Neuenrade",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58840": [
        {
            "o": "Plettenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "58849": [
        {
            "o": "Herscheid",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59063": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59065": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59067": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59069": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59071": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59073": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59075": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59077": [
        {
            "o": "Hamm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59174": [
        {
            "o": "Kamen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59192": [
        {
            "o": "Bergkamen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59199": [
        {
            "o": "Bönen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59227": [
        {
            "o": "Ahlen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59229": [
        {
            "o": "Ahlen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59269": [
        {
            "o": "Beckum",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59302": [
        {
            "o": "Oelde",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59320": [
        {
            "o": "Ennigerloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59329": [
        {
            "o": "Wadersloh",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59348": [
        {
            "o": "Lüdinghausen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59368": [
        {
            "o": "Werne",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59379": [
        {
            "o": "Selm",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59387": [
        {
            "o": "Ascheberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59394": [
        {
            "o": "Nordkirchen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59399": [
        {
            "o": "Olfen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59423": [
        {
            "o": "Unna",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59425": [
        {
            "o": "Unna",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59427": [
        {
            "o": "Unna",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59439": [
        {
            "o": "Holzwickede",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59457": [
        {
            "o": "Werl",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59469": [
        {
            "o": "Ense",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59494": [
        {
            "o": "Soest",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59505": [
        {
            "o": "Bad Sassendorf",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59510": [
        {
            "o": "Lippetal",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59514": [
        {
            "o": "Welver",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59519": [
        {
            "o": "Möhnesee",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59555": [
        {
            "o": "Lippstadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59556": [
        {
            "o": "Lippstadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59557": [
        {
            "o": "Lippstadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59558": [
        {
            "o": "Lippstadt",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59581": [
        {
            "o": "Warstein",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59590": [
        {
            "o": "Geseke",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59597": [
        {
            "o": "Erwitte",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59602": [
        {
            "o": "Rüthen",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59609": [
        {
            "o": "Anröchte",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59755": [
        {
            "o": "Arnsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59757": [
        {
            "o": "Arnsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59759": [
        {
            "o": "Arnsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59821": [
        {
            "o": "Arnsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59823": [
        {
            "o": "Arnsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59846": [
        {
            "o": "Sundern",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59872": [
        {
            "o": "Meschede",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59889": [
        {
            "o": "Eslohe",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59909": [
        {
            "o": "Bestwig",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59929": [
        {
            "o": "Brilon",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59939": [
        {
            "o": "Olsberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59955": [
        {
            "o": "Winterberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59964": [
        {
            "o": "Medebach",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "59969": [
        {
            "o": "Bromskirchen",
            "l": "Hessen"
        },
        {
            "o": "Hallenberg",
            "l": "Nordrhein-Westfalen"
        }
    ],
    "60306": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60308": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60310": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60311": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60313": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60314": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60316": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60318": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60320": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60322": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60323": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60325": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60326": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60327": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60329": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60385": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60386": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60388": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60389": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60431": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60433": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60435": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60437": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60438": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60439": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60486": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60487": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60488": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60489": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60528": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60529": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60549": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60594": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60596": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60598": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "60599": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "61118": [
        {
            "o": "Bad Vilbel",
            "l": "Hessen"
        }
    ],
    "61130": [
        {
            "o": "Nidderau",
            "l": "Hessen"
        }
    ],
    "61137": [
        {
            "o": "Schöneck",
            "l": "Hessen"
        }
    ],
    "61138": [
        {
            "o": "Niederdorfelden",
            "l": "Hessen"
        }
    ],
    "61169": [
        {
            "o": "Friedberg (Hessen)",
            "l": "Hessen"
        }
    ],
    "61184": [
        {
            "o": "Karben",
            "l": "Hessen"
        }
    ],
    "61191": [
        {
            "o": "Rosbach v. d. Höhe",
            "l": "Hessen"
        }
    ],
    "61194": [
        {
            "o": "Niddatal",
            "l": "Hessen"
        }
    ],
    "61197": [
        {
            "o": "Florstadt",
            "l": "Hessen"
        }
    ],
    "61200": [
        {
            "o": "Echzell",
            "l": "Hessen"
        },
        {
            "o": "Wölfersheim",
            "l": "Hessen"
        }
    ],
    "61203": [
        {
            "o": "Reichelsheim",
            "l": "Hessen"
        }
    ],
    "61206": [
        {
            "o": "Wöllstadt",
            "l": "Hessen"
        }
    ],
    "61209": [
        {
            "o": "Echzell",
            "l": "Hessen"
        }
    ],
    "61231": [
        {
            "o": "Bad Nauheim",
            "l": "Hessen"
        }
    ],
    "61239": [
        {
            "o": "Ober-Mörlen",
            "l": "Hessen"
        }
    ],
    "61250": [
        {
            "o": "Usingen",
            "l": "Hessen"
        }
    ],
    "61267": [
        {
            "o": "Neu-Anspach",
            "l": "Hessen"
        }
    ],
    "61273": [
        {
            "o": "Friedrichsdorf",
            "l": "Hessen"
        },
        {
            "o": "Wehrheim",
            "l": "Hessen"
        }
    ],
    "61276": [
        {
            "o": "Weilrod",
            "l": "Hessen"
        }
    ],
    "61279": [
        {
            "o": "Grävenwiesbach",
            "l": "Hessen"
        }
    ],
    "61348": [
        {
            "o": "Bad Homburg vor der Höhe",
            "l": "Hessen"
        }
    ],
    "61350": [
        {
            "o": "Bad Homburg vor der Höhe",
            "l": "Hessen"
        }
    ],
    "61352": [
        {
            "o": "Bad Homburg vor der Höhe",
            "l": "Hessen"
        }
    ],
    "61381": [
        {
            "o": "Friedrichsdorf",
            "l": "Hessen"
        }
    ],
    "61389": [
        {
            "o": "Schmitten",
            "l": "Hessen"
        }
    ],
    "61440": [
        {
            "o": "Oberursel",
            "l": "Hessen"
        }
    ],
    "61449": [
        {
            "o": "Steinbach",
            "l": "Hessen"
        }
    ],
    "61462": [
        {
            "o": "Königstein",
            "l": "Hessen"
        }
    ],
    "61476": [
        {
            "o": "Kronberg",
            "l": "Hessen"
        }
    ],
    "61479": [
        {
            "o": "Glashütten",
            "l": "Hessen"
        }
    ],
    "63065": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63067": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63069": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63071": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63073": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63075": [
        {
            "o": "Offenbach am Main",
            "l": "Hessen"
        }
    ],
    "63110": [
        {
            "o": "Rodgau",
            "l": "Hessen"
        }
    ],
    "63128": [
        {
            "o": "Dietzenbach",
            "l": "Hessen"
        }
    ],
    "63150": [
        {
            "o": "Heusenstamm",
            "l": "Hessen"
        }
    ],
    "63165": [
        {
            "o": "Mühlheim am Main",
            "l": "Hessen"
        }
    ],
    "63179": [
        {
            "o": "Obertshausen",
            "l": "Hessen"
        }
    ],
    "63225": [
        {
            "o": "Langen",
            "l": "Hessen"
        }
    ],
    "63263": [
        {
            "o": "Neu-Isenburg",
            "l": "Hessen"
        }
    ],
    "63303": [
        {
            "o": "Dreieich",
            "l": "Hessen"
        }
    ],
    "63322": [
        {
            "o": "Rödermark",
            "l": "Hessen"
        }
    ],
    "63329": [
        {
            "o": "Egelsbach",
            "l": "Hessen"
        }
    ],
    "63450": [
        {
            "o": "Hanau",
            "l": "Hessen"
        }
    ],
    "63452": [
        {
            "o": "Hanau",
            "l": "Hessen"
        }
    ],
    "63454": [
        {
            "o": "Hanau",
            "l": "Hessen"
        }
    ],
    "63456": [
        {
            "o": "Hanau",
            "l": "Hessen"
        }
    ],
    "63457": [
        {
            "o": "Hanau",
            "l": "Hessen"
        }
    ],
    "63477": [
        {
            "o": "Maintal",
            "l": "Hessen"
        }
    ],
    "63486": [
        {
            "o": "Bruchköbel",
            "l": "Hessen"
        }
    ],
    "63500": [
        {
            "o": "Seligenstadt",
            "l": "Hessen"
        }
    ],
    "63505": [
        {
            "o": "Langenselbold",
            "l": "Hessen"
        }
    ],
    "63512": [
        {
            "o": "Hainburg",
            "l": "Hessen"
        }
    ],
    "63517": [
        {
            "o": "Rodenbach",
            "l": "Hessen"
        }
    ],
    "63526": [
        {
            "o": "Erlensee",
            "l": "Hessen"
        }
    ],
    "63533": [
        {
            "o": "Mainhausen",
            "l": "Hessen"
        }
    ],
    "63538": [
        {
            "o": "Großkrotzenburg",
            "l": "Hessen"
        }
    ],
    "63543": [
        {
            "o": "Neuberg",
            "l": "Hessen"
        }
    ],
    "63546": [
        {
            "o": "Hammersbach",
            "l": "Hessen"
        }
    ],
    "63549": [
        {
            "o": "Ronneburg",
            "l": "Hessen"
        }
    ],
    "63571": [
        {
            "o": "Gelnhausen",
            "l": "Hessen"
        }
    ],
    "63579": [
        {
            "o": "Freigericht",
            "l": "Hessen"
        }
    ],
    "63584": [
        {
            "o": "Gründau",
            "l": "Hessen"
        }
    ],
    "63589": [
        {
            "o": "Gelnhausen",
            "l": "Hessen"
        },
        {
            "o": "Linsengericht",
            "l": "Hessen"
        }
    ],
    "63594": [
        {
            "o": "Hasselroth",
            "l": "Hessen"
        }
    ],
    "63599": [
        {
            "o": "Biebergemünd",
            "l": "Hessen"
        }
    ],
    "63607": [
        {
            "o": "Wächtersbach",
            "l": "Hessen"
        }
    ],
    "63619": [
        {
            "o": "Bad Orb",
            "l": "Hessen"
        }
    ],
    "63628": [
        {
            "o": "Bad Soden-Salmünster",
            "l": "Hessen"
        }
    ],
    "63633": [
        {
            "o": "Birstein",
            "l": "Hessen"
        }
    ],
    "63636": [
        {
            "o": "Brachttal",
            "l": "Hessen"
        }
    ],
    "63637": [
        {
            "o": "Jossgrund",
            "l": "Hessen"
        }
    ],
    "63639": [
        {
            "o": "Flörsbachtal",
            "l": "Hessen"
        }
    ],
    "63654": [
        {
            "o": "Büdingen",
            "l": "Hessen"
        }
    ],
    "63667": [
        {
            "o": "Nidda",
            "l": "Hessen"
        }
    ],
    "63674": [
        {
            "o": "Altenstadt",
            "l": "Hessen"
        }
    ],
    "63679": [
        {
            "o": "Schotten",
            "l": "Hessen"
        }
    ],
    "63683": [
        {
            "o": "Ortenberg",
            "l": "Hessen"
        }
    ],
    "63688": [
        {
            "o": "Gedern",
            "l": "Hessen"
        }
    ],
    "63691": [
        {
            "o": "Ranstadt",
            "l": "Hessen"
        }
    ],
    "63694": [
        {
            "o": "Limeshain",
            "l": "Hessen"
        }
    ],
    "63695": [
        {
            "o": "Glauburg",
            "l": "Hessen"
        }
    ],
    "63697": [
        {
            "o": "Hirzenhain",
            "l": "Hessen"
        }
    ],
    "63699": [
        {
            "o": "Kefenrod",
            "l": "Hessen"
        }
    ],
    "63739": [
        {
            "o": "Aschaffenburg",
            "l": "Bayern"
        }
    ],
    "63741": [
        {
            "o": "Aschaffenburg",
            "l": "Bayern"
        }
    ],
    "63743": [
        {
            "o": "Aschaffenburg",
            "l": "Bayern"
        }
    ],
    "63755": [
        {
            "o": "Alzenau",
            "l": "Bayern"
        }
    ],
    "63762": [
        {
            "o": "Großostheim",
            "l": "Bayern"
        }
    ],
    "63768": [
        {
            "o": "Hösbach",
            "l": "Bayern"
        }
    ],
    "63773": [
        {
            "o": "Goldbach",
            "l": "Bayern"
        }
    ],
    "63776": [
        {
            "o": "Mömbris",
            "l": "Bayern"
        }
    ],
    "63785": [
        {
            "o": "Obernburg am Main",
            "l": "Bayern"
        }
    ],
    "63791": [
        {
            "o": "Karlstein am Main",
            "l": "Bayern"
        }
    ],
    "63796": [
        {
            "o": "Kahl am Main",
            "l": "Bayern"
        }
    ],
    "63801": [
        {
            "o": "Kleinostheim",
            "l": "Bayern"
        }
    ],
    "63808": [
        {
            "o": "Haibach",
            "l": "Bayern"
        }
    ],
    "63811": [
        {
            "o": "Stockstadt am Main",
            "l": "Bayern"
        }
    ],
    "63814": [
        {
            "o": "Mainaschaff",
            "l": "Bayern"
        }
    ],
    "63820": [
        {
            "o": "Elsenfeld",
            "l": "Bayern"
        }
    ],
    "63825": [
        {
            "o": "Blankenbach",
            "l": "Bayern"
        },
        {
            "o": "Schöllkrippen",
            "l": "Bayern"
        },
        {
            "o": "Sommerkahl",
            "l": "Bayern"
        },
        {
            "o": "Westerngrund",
            "l": "Bayern"
        }
    ],
    "63826": [
        {
            "o": "Geiselbach",
            "l": "Bayern"
        }
    ],
    "63828": [
        {
            "o": "Kleinkahl",
            "l": "Bayern"
        }
    ],
    "63829": [
        {
            "o": "Krombach",
            "l": "Bayern"
        }
    ],
    "63831": [
        {
            "o": "Wiesen",
            "l": "Bayern"
        }
    ],
    "63834": [
        {
            "o": "Sulzbach am Main",
            "l": "Bayern"
        }
    ],
    "63839": [
        {
            "o": "Kleinwallstadt",
            "l": "Bayern"
        }
    ],
    "63840": [
        {
            "o": "Hausen",
            "l": "Bayern"
        }
    ],
    "63843": [
        {
            "o": "Niedernberg",
            "l": "Bayern"
        }
    ],
    "63846": [
        {
            "o": "Laufach",
            "l": "Bayern"
        }
    ],
    "63849": [
        {
            "o": "Leidersbach",
            "l": "Bayern"
        }
    ],
    "63853": [
        {
            "o": "Mömlingen",
            "l": "Bayern"
        }
    ],
    "63856": [
        {
            "o": "Bessenbach",
            "l": "Bayern"
        }
    ],
    "63857": [
        {
            "o": "Waldaschaff",
            "l": "Bayern"
        }
    ],
    "63860": [
        {
            "o": "Rothenbuch",
            "l": "Bayern"
        }
    ],
    "63863": [
        {
            "o": "Eschau",
            "l": "Bayern"
        }
    ],
    "63864": [
        {
            "o": "Glattbach",
            "l": "Bayern"
        }
    ],
    "63867": [
        {
            "o": "Johannesberg",
            "l": "Bayern"
        }
    ],
    "63868": [
        {
            "o": "Großwallstadt",
            "l": "Bayern"
        }
    ],
    "63869": [
        {
            "o": "Heigenbrücken",
            "l": "Bayern"
        }
    ],
    "63871": [
        {
            "o": "Heinrichsthal",
            "l": "Bayern"
        }
    ],
    "63872": [
        {
            "o": "Heimbuchenthal",
            "l": "Bayern"
        }
    ],
    "63874": [
        {
            "o": "Dammbach",
            "l": "Bayern"
        }
    ],
    "63875": [
        {
            "o": "Mespelbrunn",
            "l": "Bayern"
        }
    ],
    "63877": [
        {
            "o": "Sailauf",
            "l": "Bayern"
        }
    ],
    "63879": [
        {
            "o": "Weibersbrunn",
            "l": "Bayern"
        }
    ],
    "63897": [
        {
            "o": "Miltenberg",
            "l": "Bayern"
        }
    ],
    "63906": [
        {
            "o": "Erlenbach a.Main",
            "l": "Bayern"
        }
    ],
    "63911": [
        {
            "o": "Klingenberg am Main",
            "l": "Bayern"
        }
    ],
    "63916": [
        {
            "o": "Amorbach",
            "l": "Bayern"
        }
    ],
    "63920": [
        {
            "o": "Großheubach",
            "l": "Bayern"
        }
    ],
    "63924": [
        {
            "o": "Kleinheubach",
            "l": "Bayern"
        },
        {
            "o": "Rüdenau",
            "l": "Bayern"
        }
    ],
    "63925": [
        {
            "o": "Laudenbach",
            "l": "Bayern"
        }
    ],
    "63927": [
        {
            "o": "Bürgstadt",
            "l": "Bayern"
        }
    ],
    "63928": [
        {
            "o": "Eichenbühl",
            "l": "Bayern"
        },
        {
            "o": "Walldürn",
            "l": "Baden-Württemberg"
        }
    ],
    "63930": [
        {
            "o": "Neunkirchen",
            "l": "Bayern"
        }
    ],
    "63931": [
        {
            "o": "Kirchzell",
            "l": "Bayern"
        }
    ],
    "63933": [
        {
            "o": "Mönchberg",
            "l": "Bayern"
        }
    ],
    "63934": [
        {
            "o": "Röllbach",
            "l": "Bayern"
        }
    ],
    "63936": [
        {
            "o": "Schneeberg",
            "l": "Bayern"
        }
    ],
    "63937": [
        {
            "o": "Weilbach",
            "l": "Bayern"
        }
    ],
    "63939": [
        {
            "o": "Wörth am Main",
            "l": "Bayern"
        }
    ],
    "64283": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64285": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64287": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64289": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64291": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64293": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64295": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64297": [
        {
            "o": "Darmstadt",
            "l": "Hessen"
        }
    ],
    "64319": [
        {
            "o": "Pfungstadt",
            "l": "Hessen"
        }
    ],
    "64331": [
        {
            "o": "Weiterstadt",
            "l": "Hessen"
        }
    ],
    "64342": [
        {
            "o": "Seeheim-Jugenheim",
            "l": "Hessen"
        }
    ],
    "64347": [
        {
            "o": "Griesheim",
            "l": "Hessen"
        }
    ],
    "64354": [
        {
            "o": "Reinheim",
            "l": "Hessen"
        }
    ],
    "64367": [
        {
            "o": "Mühltal",
            "l": "Hessen"
        }
    ],
    "64372": [
        {
            "o": "Ober-Ramstadt",
            "l": "Hessen"
        }
    ],
    "64380": [
        {
            "o": "Roßdorf",
            "l": "Hessen"
        }
    ],
    "64385": [
        {
            "o": "Reichelsheim (Odenwald)",
            "l": "Hessen"
        }
    ],
    "64390": [
        {
            "o": "Erzhausen",
            "l": "Hessen"
        }
    ],
    "64395": [
        {
            "o": "Brensbach",
            "l": "Hessen"
        },
        {
            "o": "Groß-Bieberau",
            "l": "Hessen"
        }
    ],
    "64397": [
        {
            "o": "Modautal",
            "l": "Hessen"
        }
    ],
    "64401": [
        {
            "o": "Groß-Bieberau",
            "l": "Hessen"
        }
    ],
    "64404": [
        {
            "o": "Bickenbach",
            "l": "Hessen"
        }
    ],
    "64405": [
        {
            "o": "Fischbachtal",
            "l": "Hessen"
        }
    ],
    "64407": [
        {
            "o": "Fränkisch-Crumbach",
            "l": "Hessen"
        }
    ],
    "64409": [
        {
            "o": "Messel",
            "l": "Hessen"
        }
    ],
    "64521": [
        {
            "o": "Groß-Gerau",
            "l": "Hessen"
        }
    ],
    "64546": [
        {
            "o": "Mörfelden-Walldorf",
            "l": "Hessen"
        }
    ],
    "64560": [
        {
            "o": "Riedstadt",
            "l": "Hessen"
        }
    ],
    "64569": [
        {
            "o": "Nauheim",
            "l": "Hessen"
        }
    ],
    "64572": [
        {
            "o": "Büttelborn",
            "l": "Hessen"
        }
    ],
    "64579": [
        {
            "o": "Gernsheim",
            "l": "Hessen"
        }
    ],
    "64584": [
        {
            "o": "Biebesheim",
            "l": "Hessen"
        }
    ],
    "64589": [
        {
            "o": "Stockstadt",
            "l": "Hessen"
        }
    ],
    "64625": [
        {
            "o": "Bensheim",
            "l": "Hessen"
        }
    ],
    "64646": [
        {
            "o": "Heppenheim",
            "l": "Hessen"
        }
    ],
    "64653": [
        {
            "o": "Lorsch",
            "l": "Hessen"
        }
    ],
    "64658": [
        {
            "o": "Fürth",
            "l": "Hessen"
        }
    ],
    "64665": [
        {
            "o": "Alsbach-Hähnlein",
            "l": "Hessen"
        }
    ],
    "64668": [
        {
            "o": "Rimbach",
            "l": "Hessen"
        }
    ],
    "64673": [
        {
            "o": "Zwingenberg",
            "l": "Hessen"
        }
    ],
    "64678": [
        {
            "o": "Lindenfels",
            "l": "Hessen"
        }
    ],
    "64683": [
        {
            "o": "Einhausen",
            "l": "Hessen"
        }
    ],
    "64686": [
        {
            "o": "Lautertal",
            "l": "Hessen"
        }
    ],
    "64689": [
        {
            "o": "Grasellenbach",
            "l": "Hessen"
        }
    ],
    "64711": [
        {
            "o": "Erbach",
            "l": "Hessen"
        }
    ],
    "64720": [
        {
            "o": "Michelstadt",
            "l": "Hessen"
        }
    ],
    "64732": [
        {
            "o": "Bad König",
            "l": "Hessen"
        }
    ],
    "64739": [
        {
            "o": "Höchst im Odenwald",
            "l": "Hessen"
        }
    ],
    "64747": [
        {
            "o": "Breuberg",
            "l": "Hessen"
        }
    ],
    "64750": [
        {
            "o": "Lützelbach",
            "l": "Hessen"
        }
    ],
    "64753": [
        {
            "o": "Brombachtal",
            "l": "Hessen"
        }
    ],
    "64754": [
        {
            "o": "Eberbach",
            "l": "Baden-Württemberg"
        }
    ],
    "64756": [
        {
            "o": "Mossautal",
            "l": "Hessen"
        }
    ],
    "64757": [
        {
            "o": "Hirschhorn",
            "l": "Hessen"
        }
    ],
    "64760": [
        {
            "o": "Oberzent",
            "l": "Hessen"
        }
    ],
    "64807": [
        {
            "o": "Dieburg",
            "l": "Hessen"
        }
    ],
    "64823": [
        {
            "o": "Groß-Umstadt",
            "l": "Hessen"
        }
    ],
    "64832": [
        {
            "o": "Babenhausen",
            "l": "Hessen"
        }
    ],
    "64839": [
        {
            "o": "Münster (Hessen)",
            "l": "Hessen"
        }
    ],
    "64846": [
        {
            "o": "Groß-Zimmern",
            "l": "Hessen"
        }
    ],
    "64850": [
        {
            "o": "Schaafheim",
            "l": "Hessen"
        }
    ],
    "64853": [
        {
            "o": "Otzberg",
            "l": "Hessen"
        }
    ],
    "64859": [
        {
            "o": "Eppertshausen",
            "l": "Hessen"
        }
    ],
    "65183": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65185": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65187": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65189": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65191": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65193": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65195": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65197": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65199": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65201": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65203": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65205": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65207": [
        {
            "o": "Wiesbaden",
            "l": "Hessen"
        }
    ],
    "65232": [
        {
            "o": "Taunusstein",
            "l": "Hessen"
        }
    ],
    "65239": [
        {
            "o": "Hochheim",
            "l": "Hessen"
        }
    ],
    "65307": [
        {
            "o": "Bad Schwalbach",
            "l": "Hessen"
        }
    ],
    "65321": [
        {
            "o": "Heidenrod",
            "l": "Hessen"
        }
    ],
    "65326": [
        {
            "o": "Aarbergen",
            "l": "Hessen"
        }
    ],
    "65329": [
        {
            "o": "Hohenstein",
            "l": "Hessen"
        }
    ],
    "65343": [
        {
            "o": "Eltville am Rhein",
            "l": "Hessen"
        }
    ],
    "65344": [
        {
            "o": "Eltville am Rhein",
            "l": "Hessen"
        }
    ],
    "65345": [
        {
            "o": "Eltville am Rhein",
            "l": "Hessen"
        }
    ],
    "65346": [
        {
            "o": "Eltville am Rhein",
            "l": "Hessen"
        }
    ],
    "65347": [
        {
            "o": "Eltville am Rhein",
            "l": "Hessen"
        }
    ],
    "65366": [
        {
            "o": "Geisenheim",
            "l": "Hessen"
        }
    ],
    "65375": [
        {
            "o": "Oestrich-Winkel",
            "l": "Hessen"
        }
    ],
    "65385": [
        {
            "o": "Rüdesheim am Rhein",
            "l": "Hessen"
        }
    ],
    "65388": [
        {
            "o": "Schlangenbad",
            "l": "Hessen"
        }
    ],
    "65391": [
        {
            "o": "Lorch",
            "l": "Hessen"
        },
        {
            "o": "Sauerthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65396": [
        {
            "o": "Walluf",
            "l": "Hessen"
        }
    ],
    "65399": [
        {
            "o": "Kiedrich",
            "l": "Hessen"
        }
    ],
    "65428": [
        {
            "o": "Rüsselsheim am Main",
            "l": "Hessen"
        }
    ],
    "65439": [
        {
            "o": "Flörsheim",
            "l": "Hessen"
        }
    ],
    "65451": [
        {
            "o": "Kelsterbach",
            "l": "Hessen"
        }
    ],
    "65462": [
        {
            "o": "Ginsheim-Gustavsburg",
            "l": "Hessen"
        }
    ],
    "65468": [
        {
            "o": "Trebur",
            "l": "Hessen"
        }
    ],
    "65474": [
        {
            "o": "Bischofsheim",
            "l": "Hessen"
        }
    ],
    "65479": [
        {
            "o": "Raunheim",
            "l": "Hessen"
        }
    ],
    "65510": [
        {
            "o": "Hünstetten",
            "l": "Hessen"
        },
        {
            "o": "Idstein",
            "l": "Hessen"
        }
    ],
    "65520": [
        {
            "o": "Bad Camberg",
            "l": "Hessen"
        }
    ],
    "65527": [
        {
            "o": "Niedernhausen",
            "l": "Hessen"
        }
    ],
    "65529": [
        {
            "o": "Waldems",
            "l": "Hessen"
        }
    ],
    "65549": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65550": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65551": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65552": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65553": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65554": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65555": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65556": [
        {
            "o": "Limburg a. d. Lahn",
            "l": "Hessen"
        }
    ],
    "65558": [
        {
            "o": "Balduinstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Burgschwalbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Cramberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eppenrod",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Flacht",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gückingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heistenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hirschberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Holzheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Isselbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kaltenholzhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenscheid",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lohrheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberneisen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65582": [
        {
            "o": "Aull",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Diez",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hambach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65589": [
        {
            "o": "Hadamar",
            "l": "Hessen"
        }
    ],
    "65594": [
        {
            "o": "Runkel",
            "l": "Hessen"
        }
    ],
    "65597": [
        {
            "o": "Hünfelden",
            "l": "Hessen"
        }
    ],
    "65599": [
        {
            "o": "Dornburg",
            "l": "Hessen"
        }
    ],
    "65604": [
        {
            "o": "Elz",
            "l": "Hessen"
        }
    ],
    "65606": [
        {
            "o": "Villmar",
            "l": "Hessen"
        }
    ],
    "65611": [
        {
            "o": "Brechen",
            "l": "Hessen"
        }
    ],
    "65614": [
        {
            "o": "Beselich",
            "l": "Hessen"
        }
    ],
    "65618": [
        {
            "o": "Selters",
            "l": "Hessen"
        }
    ],
    "65620": [
        {
            "o": "Waldbrunn",
            "l": "Hessen"
        }
    ],
    "65623": [
        {
            "o": "Hahnstätten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mudershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Netzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schiesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65624": [
        {
            "o": "Altendiez",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65626": [
        {
            "o": "Birlenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65627": [
        {
            "o": "Elbtal",
            "l": "Hessen"
        }
    ],
    "65629": [
        {
            "o": "Niederneisen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "65719": [
        {
            "o": "Hofheim am Taunus",
            "l": "Hessen"
        }
    ],
    "65760": [
        {
            "o": "Eschborn",
            "l": "Hessen"
        }
    ],
    "65779": [
        {
            "o": "Kelkheim",
            "l": "Hessen"
        }
    ],
    "65795": [
        {
            "o": "Hattersheim",
            "l": "Hessen"
        }
    ],
    "65812": [
        {
            "o": "Bad Soden",
            "l": "Hessen"
        }
    ],
    "65817": [
        {
            "o": "Eppstein",
            "l": "Hessen"
        }
    ],
    "65824": [
        {
            "o": "Schwalbach",
            "l": "Hessen"
        }
    ],
    "65830": [
        {
            "o": "Kriftel",
            "l": "Hessen"
        }
    ],
    "65835": [
        {
            "o": "Liederbach",
            "l": "Hessen"
        }
    ],
    "65843": [
        {
            "o": "Sulzbach",
            "l": "Hessen"
        }
    ],
    "65929": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "65931": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "65933": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "65934": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "65936": [
        {
            "o": "Frankfurt am Main",
            "l": "Hessen"
        }
    ],
    "66111": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66113": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66115": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66117": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66119": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66121": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66123": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66125": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66126": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66127": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66128": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66129": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66130": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66131": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66132": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66133": [
        {
            "o": "Saarbrücken",
            "l": "Saarland"
        }
    ],
    "66265": [
        {
            "o": "Heusweiler",
            "l": "Saarland"
        }
    ],
    "66271": [
        {
            "o": "Kleinblittersdorf",
            "l": "Saarland"
        }
    ],
    "66280": [
        {
            "o": "Sulzbach/Saar",
            "l": "Saarland"
        }
    ],
    "66287": [
        {
            "o": "Quierschied",
            "l": "Saarland"
        }
    ],
    "66292": [
        {
            "o": "Riegelsberg",
            "l": "Saarland"
        }
    ],
    "66299": [
        {
            "o": "Friedrichsthal",
            "l": "Saarland"
        }
    ],
    "66333": [
        {
            "o": "Völklingen",
            "l": "Saarland"
        }
    ],
    "66346": [
        {
            "o": "Püttlingen",
            "l": "Saarland"
        }
    ],
    "66352": [
        {
            "o": "Großrosseln",
            "l": "Saarland"
        }
    ],
    "66359": [
        {
            "o": "Bous",
            "l": "Saarland"
        }
    ],
    "66386": [
        {
            "o": "Sankt Ingbert",
            "l": "Saarland"
        }
    ],
    "66399": [
        {
            "o": "Mandelbachtal",
            "l": "Saarland"
        }
    ],
    "66424": [
        {
            "o": "Homburg",
            "l": "Saarland"
        }
    ],
    "66440": [
        {
            "o": "Blieskastel",
            "l": "Saarland"
        }
    ],
    "66450": [
        {
            "o": "Bexbach",
            "l": "Saarland"
        }
    ],
    "66453": [
        {
            "o": "Gersheim",
            "l": "Saarland"
        }
    ],
    "66459": [
        {
            "o": "Kirkel",
            "l": "Saarland"
        }
    ],
    "66482": [
        {
            "o": "Zweibrücken",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66484": [
        {
            "o": "Althornbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Battweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dietrichingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großsteinhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinsteinhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Riedelberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmitshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Walshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterbach (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66497": [
        {
            "o": "Contwig",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66500": [
        {
            "o": "Hornbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mauschbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66501": [
        {
            "o": "Großbundenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinbundenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66503": [
        {
            "o": "Dellfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66504": [
        {
            "o": "Bottenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66506": [
        {
            "o": "Maßweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66507": [
        {
            "o": "Reifenberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66509": [
        {
            "o": "Rieschweiler-Mühlbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66538": [
        {
            "o": "Neunkirchen",
            "l": "Saarland"
        }
    ],
    "66539": [
        {
            "o": "Neunkirchen",
            "l": "Saarland"
        }
    ],
    "66540": [
        {
            "o": "Neunkirchen",
            "l": "Saarland"
        }
    ],
    "66557": [
        {
            "o": "Illingen",
            "l": "Saarland"
        }
    ],
    "66564": [
        {
            "o": "Ottweiler",
            "l": "Saarland"
        }
    ],
    "66571": [
        {
            "o": "Eppelborn",
            "l": "Saarland"
        }
    ],
    "66578": [
        {
            "o": "Schiffweiler",
            "l": "Saarland"
        }
    ],
    "66583": [
        {
            "o": "Spiesen-Elversberg",
            "l": "Saarland"
        }
    ],
    "66589": [
        {
            "o": "Merchweiler",
            "l": "Saarland"
        }
    ],
    "66606": [
        {
            "o": "Sankt Wendel",
            "l": "Saarland"
        }
    ],
    "66620": [
        {
            "o": "Nonnweiler",
            "l": "Saarland"
        }
    ],
    "66625": [
        {
            "o": "Nohfelden",
            "l": "Saarland"
        }
    ],
    "66629": [
        {
            "o": "Freisen",
            "l": "Saarland"
        }
    ],
    "66636": [
        {
            "o": "Tholey",
            "l": "Saarland"
        }
    ],
    "66640": [
        {
            "o": "Namborn",
            "l": "Saarland"
        }
    ],
    "66646": [
        {
            "o": "Marpingen",
            "l": "Saarland"
        }
    ],
    "66649": [
        {
            "o": "Oberthal",
            "l": "Saarland"
        }
    ],
    "66663": [
        {
            "o": "Merzig",
            "l": "Saarland"
        }
    ],
    "66679": [
        {
            "o": "Losheim am See",
            "l": "Saarland"
        }
    ],
    "66687": [
        {
            "o": "Wadern",
            "l": "Saarland"
        }
    ],
    "66693": [
        {
            "o": "Mettlach",
            "l": "Saarland"
        }
    ],
    "66701": [
        {
            "o": "Beckingen",
            "l": "Saarland"
        }
    ],
    "66706": [
        {
            "o": "Perl",
            "l": "Saarland"
        }
    ],
    "66709": [
        {
            "o": "Weiskirchen",
            "l": "Saarland"
        }
    ],
    "66740": [
        {
            "o": "Saarlouis",
            "l": "Saarland"
        }
    ],
    "66763": [
        {
            "o": "Dillingen/Saar",
            "l": "Saarland"
        }
    ],
    "66773": [
        {
            "o": "Schwalbach",
            "l": "Saarland"
        }
    ],
    "66780": [
        {
            "o": "Rehlingen-Siersburg",
            "l": "Saarland"
        }
    ],
    "66787": [
        {
            "o": "Wadgassen",
            "l": "Saarland"
        }
    ],
    "66793": [
        {
            "o": "Saarwellingen",
            "l": "Saarland"
        }
    ],
    "66798": [
        {
            "o": "Wallerfangen",
            "l": "Saarland"
        }
    ],
    "66802": [
        {
            "o": "Überherrn",
            "l": "Saarland"
        }
    ],
    "66806": [
        {
            "o": "Ensdorf/Saar",
            "l": "Saarland"
        }
    ],
    "66809": [
        {
            "o": "Nalbach",
            "l": "Saarland"
        }
    ],
    "66822": [
        {
            "o": "Lebach",
            "l": "Saarland"
        }
    ],
    "66839": [
        {
            "o": "Schmelz",
            "l": "Saarland"
        }
    ],
    "66849": [
        {
            "o": "Landstuhl",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66851": [
        {
            "o": "Bann",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gerhardsbrunn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hauptstuhl",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Linden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mittelbrunn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberarnbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Queidersbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinalben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66862": [
        {
            "o": "Kindsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66869": [
        {
            "o": "Blaubach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kusel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruthweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schellweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66871": [
        {
            "o": "Albessen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dennweiler-Frohnbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ehweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Etschberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Haschbach am Remigiusberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herchweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Konken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Körborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberalben",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pfeffelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reichweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Selchenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thallichtenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Theisbergstegen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66877": [
        {
            "o": "Ramstein-Miesenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66879": [
        {
            "o": "Kollweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kottweiler-Schwanden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedermohr",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederstaufenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberstaufenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reichenbach-Steegen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinwenden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66882": [
        {
            "o": "Hütschenhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66885": [
        {
            "o": "Altenglan",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bedesbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66887": [
        {
            "o": "Bosenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Elzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erdesbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Föckelberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Glanbrücken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Horschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jettenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neunkirchen am Potzberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederalben",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rammelsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rathsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rutsweiler am Glan",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Julian",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ulmet",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Welchweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66892": [
        {
            "o": "Bruchmühlbach-Miesau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66894": [
        {
            "o": "Bechhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gerhardsbrunn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Käshofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krähenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lambsborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langwieden",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Martinshöhe",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rosenkopf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiesbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66901": [
        {
            "o": "Schönenberg-Kübelberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66903": [
        {
            "o": "Altenkirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dittweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frohnhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gries",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ohmbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66904": [
        {
            "o": "Börsborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Brücken (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66907": [
        {
            "o": "Glan-Münchweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rehweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66909": [
        {
            "o": "Henschtal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herschweiler-Pettersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hüffler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Krottelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Langenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Matzenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nanzdietschweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Quirnbach/Pfalz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinbach am Glan",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wahnwegen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66914": [
        {
            "o": "Waldmohr",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66916": [
        {
            "o": "Breitenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dunzweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66917": [
        {
            "o": "Biedershausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herschberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Knopp-Labach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmitshausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wallhalben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66919": [
        {
            "o": "Hermersberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herschberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hettenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obernheim-Kirchenarnbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Saalstadt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schauerberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weselberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66953": [
        {
            "o": "Pirmasens",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66954": [
        {
            "o": "Pirmasens",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66955": [
        {
            "o": "Münchweiler an der Rodalb",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pirmasens",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66957": [
        {
            "o": "Eppenbrunn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hilst",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kröppen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obersimten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruppertsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweix",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trulben",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vinningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66969": [
        {
            "o": "Lemberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66976": [
        {
            "o": "Rodalben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66978": [
        {
            "o": "Clausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Donsieders",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leimen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merzalben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66981": [
        {
            "o": "Münchweiler an der Rodalb",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66987": [
        {
            "o": "Herschberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Thaleischweiler-Fröschen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66989": [
        {
            "o": "Höheinöd",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Höheischweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Höhfröschen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nünschweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Petersberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66994": [
        {
            "o": "Dahn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66996": [
        {
            "o": "Busenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erfweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Fischbach bei Dahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hirschthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ludwigswinkel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schindhard",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönau (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "66999": [
        {
            "o": "Hinterweidenthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67059": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67061": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67063": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67065": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67067": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67069": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67071": [
        {
            "o": "Ludwigshafen am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67098": [
        {
            "o": "Bad Dürkheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freinsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67105": [
        {
            "o": "Schifferstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67112": [
        {
            "o": "Mutterstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67117": [
        {
            "o": "Limburgerhof",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67122": [
        {
            "o": "Altrip",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67125": [
        {
            "o": "Dannstadt-Schauernheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67126": [
        {
            "o": "Hochdorf-Assenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67127": [
        {
            "o": "Rödersheim-Gronau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67133": [
        {
            "o": "Maxdorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67134": [
        {
            "o": "Birkenheide",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67136": [
        {
            "o": "Fußgönheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67141": [
        {
            "o": "Neuhofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67146": [
        {
            "o": "Deidesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67147": [
        {
            "o": "Forst an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67149": [
        {
            "o": "Meckenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67150": [
        {
            "o": "Niederkirchen bei Deidesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67152": [
        {
            "o": "Ruppertsberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67157": [
        {
            "o": "Deidesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wachenheim an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67158": [
        {
            "o": "Ellerstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67159": [
        {
            "o": "Friedelsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67161": [
        {
            "o": "Gönnheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67165": [
        {
            "o": "Waldsee",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67166": [
        {
            "o": "Otterstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67167": [
        {
            "o": "Erpolzheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67169": [
        {
            "o": "Kallstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67227": [
        {
            "o": "Frankenthal (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67229": [
        {
            "o": "Gerolsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großkarlbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Laumersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67240": [
        {
            "o": "Bobenheim-Roxheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67245": [
        {
            "o": "Lambsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67246": [
        {
            "o": "Dirmstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67251": [
        {
            "o": "Freinsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67256": [
        {
            "o": "Weisenheim am Sand",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67258": [
        {
            "o": "Heßheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67259": [
        {
            "o": "Beindersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großniedesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heuchelheim bei Frankenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinniedesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67269": [
        {
            "o": "Grünstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67271": [
        {
            "o": "Battenberg (Pfalz)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kindenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinkarlbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mertesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neuleiningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obersülzen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67273": [
        {
            "o": "Bobenheim am Berg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dackenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herxheim am Berg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weisenheim am Berg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67278": [
        {
            "o": "Bockenheim an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67280": [
        {
            "o": "Ebertsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Quirnheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67281": [
        {
            "o": "Bissersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirchheim an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67283": [
        {
            "o": "Obrigheim (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67292": [
        {
            "o": "Kirchheimbolanden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67294": [
        {
            "o": "Bischheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gauersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ilbesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mauchenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Morschheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberwiesen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Orbis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rittersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stetten",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67295": [
        {
            "o": "Bolanden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67297": [
        {
            "o": "Marnheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67304": [
        {
            "o": "Eisenberg (Pfalz)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kerzenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67305": [
        {
            "o": "Ramsen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67307": [
        {
            "o": "Göllheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67308": [
        {
            "o": "Albisheim (Pfrimm)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Biedesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bubenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Einselthum",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Immesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lautersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ottersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rüssingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Zellertal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67310": [
        {
            "o": "Hettenleidelheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67311": [
        {
            "o": "Tiefenthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67316": [
        {
            "o": "Altleiningen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Carlsberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67317": [
        {
            "o": "Altleiningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67319": [
        {
            "o": "Wattenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67346": [
        {
            "o": "Speyer",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67354": [
        {
            "o": "Römerberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67360": [
        {
            "o": "Lingenfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67361": [
        {
            "o": "Freisbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67363": [
        {
            "o": "Lustadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67365": [
        {
            "o": "Schwegenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67366": [
        {
            "o": "Weingarten (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67368": [
        {
            "o": "Westheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67373": [
        {
            "o": "Dudenhofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67374": [
        {
            "o": "Hanhofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67376": [
        {
            "o": "Harthausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67377": [
        {
            "o": "Gommersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67378": [
        {
            "o": "Zeiskam",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67433": [
        {
            "o": "Neustadt an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67434": [
        {
            "o": "Neustadt an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67435": [
        {
            "o": "Deidesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neustadt an der Weinstraße",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67454": [
        {
            "o": "Haßloch",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67459": [
        {
            "o": "Böhl-Iggelheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67466": [
        {
            "o": "Deidesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Esthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lambrecht",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67468": [
        {
            "o": "Esthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frankeneck",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frankenstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Neidenfels",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67471": [
        {
            "o": "Elmstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67472": [
        {
            "o": "Esthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67473": [
        {
            "o": "Lindenberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67475": [
        {
            "o": "Weidenthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67480": [
        {
            "o": "Edenkoben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67482": [
        {
            "o": "Altdorf",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Böbingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freimersheim (Pfalz)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Venningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67483": [
        {
            "o": "Edesheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Großfischlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kleinfischlingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67487": [
        {
            "o": "Maikammer",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Martin",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67489": [
        {
            "o": "Kirrweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67547": [
        {
            "o": "Worms",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67549": [
        {
            "o": "Worms",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67550": [
        {
            "o": "Worms",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67551": [
        {
            "o": "Worms",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67574": [
        {
            "o": "Osthofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67575": [
        {
            "o": "Eich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67577": [
        {
            "o": "Alsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67578": [
        {
            "o": "Gimbsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67580": [
        {
            "o": "Hamm am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67582": [
        {
            "o": "Mettenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67583": [
        {
            "o": "Guntersblum",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67585": [
        {
            "o": "Dorn-Dürkheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67586": [
        {
            "o": "Hillesheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67587": [
        {
            "o": "Wintersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67590": [
        {
            "o": "Monsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67591": [
        {
            "o": "Hohen-Sülzen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mölsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörstadt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Offstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wachenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67592": [
        {
            "o": "Flörsheim-Dalsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67593": [
        {
            "o": "Bermersheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Westhofen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67595": [
        {
            "o": "Bechtheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67596": [
        {
            "o": "Dittelsheim-Heßloch",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frettenheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67598": [
        {
            "o": "Gundersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67599": [
        {
            "o": "Gundheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67655": [
        {
            "o": "Kaiserslautern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67657": [
        {
            "o": "Kaiserslautern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67659": [
        {
            "o": "Kaiserslautern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67661": [
        {
            "o": "Kaiserslautern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67663": [
        {
            "o": "Kaiserslautern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67677": [
        {
            "o": "Enkenbach-Alsenborn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67678": [
        {
            "o": "Mehlingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67680": [
        {
            "o": "Neuhemsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67681": [
        {
            "o": "Sembach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wartenberg-Rohrbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67685": [
        {
            "o": "Erzenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eulenbis",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwedelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weilerbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67686": [
        {
            "o": "Mackenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67688": [
        {
            "o": "Rodenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67691": [
        {
            "o": "Hochspeyer",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67693": [
        {
            "o": "Fischbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldleiningen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67697": [
        {
            "o": "Otterberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67699": [
        {
            "o": "Heiligenmoschel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schneckenhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67700": [
        {
            "o": "Niederkirchen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67701": [
        {
            "o": "Schallodenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67705": [
        {
            "o": "Stelzenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Trippstadt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67706": [
        {
            "o": "Krickenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Linden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67707": [
        {
            "o": "Schopp",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67714": [
        {
            "o": "Waldfischbach-Burgalben",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67715": [
        {
            "o": "Geiselberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67716": [
        {
            "o": "Heltersberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67718": [
        {
            "o": "Schmalenberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67722": [
        {
            "o": "Winnweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67724": [
        {
            "o": "Gehrweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gonbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gundersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Höringen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67725": [
        {
            "o": "Börrstadt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Breunigweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Imsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67727": [
        {
            "o": "Lohnsfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67728": [
        {
            "o": "Münchweiler an der Alsenz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67729": [
        {
            "o": "Sippersfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67731": [
        {
            "o": "Otterbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67732": [
        {
            "o": "Hirschhorn/ Pfalz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67734": [
        {
            "o": "Katzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sulzbachtal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67735": [
        {
            "o": "Mehlbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67737": [
        {
            "o": "Frankelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Olsbrücken",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67742": [
        {
            "o": "Adenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Buborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Deimberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ginsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hausweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heinzenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herren-Sulzbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Homberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lauterecken",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67744": [
        {
            "o": "Cronenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hohenöllen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Homberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hoppstädten",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kappeln",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kirrweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lohnweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Löllbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Medard",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rathskirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweinschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Seelen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wiesweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67745": [
        {
            "o": "Grumbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67746": [
        {
            "o": "Langweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Merzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Unterjeckenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67748": [
        {
            "o": "Odenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67749": [
        {
            "o": "Nerzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Offenbach-Hundheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67752": [
        {
            "o": "Oberweiler-Tiefenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rutsweiler an der Lauter",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wolfstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67753": [
        {
            "o": "Aschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Einöllen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hefersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reipoltskirchen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Relsberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rothselberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67754": [
        {
            "o": "Eßweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67756": [
        {
            "o": "Hinzweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberweiler im Tal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67757": [
        {
            "o": "Kreimbach-Kaulbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67759": [
        {
            "o": "Nußbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reichsthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67806": [
        {
            "o": "Bisterschied",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dörrmoschel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Imsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Katzenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rockenhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Teschenmoschel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67808": [
        {
            "o": "Bayerfeld-Steckweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bennhausen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Falkenstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Imsbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Imsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mörsfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ransweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ruppertsecken",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schiersfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schönborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweisweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Stahlberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinbach am Donnersberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weitersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Würzweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67811": [
        {
            "o": "Dielkirchen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67813": [
        {
            "o": "Gerbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Alban",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67814": [
        {
            "o": "Dannenfels",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Jakobsweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67816": [
        {
            "o": "Dreisen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Standenbühl",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67817": [
        {
            "o": "Imsbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67819": [
        {
            "o": "Kriegsfeld",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67821": [
        {
            "o": "Alsenz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberndorf",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67822": [
        {
            "o": "Bayerfeld-Steckweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Finkenbach-Gersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gaugrehweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kalkofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Mannweiler-Cölln",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Münsterappel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhausen an der Appel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niedermoschel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhausen an der Appel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sankt Alban",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldgrehweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winterborn",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67823": [
        {
            "o": "Lettweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Obermoschel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schiersfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Sitters",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Unkenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67824": [
        {
            "o": "Feilbingert",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67826": [
        {
            "o": "Duchroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hallgarten",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67827": [
        {
            "o": "Becherbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "67829": [
        {
            "o": "Callbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Reiffelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schmittweiler",
            "l": "Rheinland-Pfalz"
        }
    ],
    "68159": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68161": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68163": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68165": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68167": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68169": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68199": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68219": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68229": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68239": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68259": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68305": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68307": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68309": [
        {
            "o": "Mannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68519": [
        {
            "o": "Viernheim",
            "l": "Hessen"
        }
    ],
    "68526": [
        {
            "o": "Ladenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "68535": [
        {
            "o": "Edingen-Neckarhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "68542": [
        {
            "o": "Heddesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68549": [
        {
            "o": "Ilvesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68623": [
        {
            "o": "Lampertheim",
            "l": "Hessen"
        }
    ],
    "68642": [
        {
            "o": "Bürstadt",
            "l": "Hessen"
        }
    ],
    "68647": [
        {
            "o": "Biblis",
            "l": "Hessen"
        }
    ],
    "68649": [
        {
            "o": "Groß-Rohrheim",
            "l": "Hessen"
        }
    ],
    "68723": [
        {
            "o": "Oftersheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Plankstadt",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schwetzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "68753": [
        {
            "o": "Waghäusel",
            "l": "Baden-Württemberg"
        }
    ],
    "68766": [
        {
            "o": "Hockenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68775": [
        {
            "o": "Ketsch",
            "l": "Baden-Württemberg"
        }
    ],
    "68782": [
        {
            "o": "Brühl",
            "l": "Baden-Württemberg"
        }
    ],
    "68789": [
        {
            "o": "St. Leon-Rot",
            "l": "Baden-Württemberg"
        }
    ],
    "68794": [
        {
            "o": "Oberhausen-Rheinhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "68799": [
        {
            "o": "Reilingen",
            "l": "Baden-Württemberg"
        }
    ],
    "68804": [
        {
            "o": "Altlußheim",
            "l": "Baden-Württemberg"
        }
    ],
    "68809": [
        {
            "o": "Neulußheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69115": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69117": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69118": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69120": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69121": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69123": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69124": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69126": [
        {
            "o": "Heidelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69151": [
        {
            "o": "Neckargemünd",
            "l": "Baden-Württemberg"
        }
    ],
    "69168": [
        {
            "o": "Wiesloch",
            "l": "Baden-Württemberg"
        }
    ],
    "69181": [
        {
            "o": "Leimen",
            "l": "Baden-Württemberg"
        }
    ],
    "69190": [
        {
            "o": "Walldorf",
            "l": "Baden-Württemberg"
        }
    ],
    "69198": [
        {
            "o": "Schriesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69207": [
        {
            "o": "Sandhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "69214": [
        {
            "o": "Eppelheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69221": [
        {
            "o": "Dossenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69226": [
        {
            "o": "Nußloch",
            "l": "Baden-Württemberg"
        }
    ],
    "69231": [
        {
            "o": "Rauenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69234": [
        {
            "o": "Dielheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69239": [
        {
            "o": "Neckarsteinach",
            "l": "Hessen"
        }
    ],
    "69242": [
        {
            "o": "Mühlhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "69245": [
        {
            "o": "Bammental",
            "l": "Baden-Württemberg"
        }
    ],
    "69250": [
        {
            "o": "Schönau",
            "l": "Baden-Württemberg"
        }
    ],
    "69251": [
        {
            "o": "Gaiberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69253": [
        {
            "o": "Heiligkreuzsteinach",
            "l": "Baden-Württemberg"
        }
    ],
    "69254": [
        {
            "o": "Malsch",
            "l": "Baden-Württemberg"
        }
    ],
    "69256": [
        {
            "o": "Mauer",
            "l": "Baden-Württemberg"
        }
    ],
    "69257": [
        {
            "o": "Wiesenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "69259": [
        {
            "o": "Wilhelmsfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "69412": [
        {
            "o": "Eberbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hirschhorn",
            "l": "Hessen"
        }
    ],
    "69427": [
        {
            "o": "Mudau",
            "l": "Baden-Württemberg"
        }
    ],
    "69429": [
        {
            "o": "Eberbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Waldbrunn",
            "l": "Baden-Württemberg"
        }
    ],
    "69434": [
        {
            "o": "Eberbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Heddesbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hirschhorn",
            "l": "Hessen"
        }
    ],
    "69436": [
        {
            "o": "Schönbrunn",
            "l": "Baden-Württemberg"
        }
    ],
    "69437": [
        {
            "o": "Neckargerach",
            "l": "Baden-Württemberg"
        }
    ],
    "69439": [
        {
            "o": "Zwingenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "69469": [
        {
            "o": "Weinheim",
            "l": "Baden-Württemberg"
        }
    ],
    "69483": [
        {
            "o": "Wald-Michelbach",
            "l": "Hessen"
        }
    ],
    "69488": [
        {
            "o": "Birkenau",
            "l": "Hessen"
        }
    ],
    "69493": [
        {
            "o": "Hirschberg an der Bergstraße",
            "l": "Baden-Württemberg"
        }
    ],
    "69502": [
        {
            "o": "Hemsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "69509": [
        {
            "o": "Mörlenbach",
            "l": "Hessen"
        }
    ],
    "69514": [
        {
            "o": "Laudenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "69517": [
        {
            "o": "Gorxheimertal",
            "l": "Hessen"
        }
    ],
    "69518": [
        {
            "o": "Abtsteinach",
            "l": "Hessen"
        }
    ],
    "70173": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70174": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70176": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70178": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70180": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70182": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70184": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70186": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70188": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70190": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70191": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70192": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70193": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70195": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70197": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70199": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70327": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70329": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70372": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70374": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70376": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70378": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70435": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70437": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70439": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70469": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70499": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70563": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70565": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70567": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70569": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70597": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70599": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70619": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70629": [
        {
            "o": "Stuttgart",
            "l": "Baden-Württemberg"
        }
    ],
    "70734": [
        {
            "o": "Fellbach",
            "l": "Baden-Württemberg"
        }
    ],
    "70736": [
        {
            "o": "Fellbach",
            "l": "Baden-Württemberg"
        }
    ],
    "70771": [
        {
            "o": "Leinfelden-Echterdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "70794": [
        {
            "o": "Filderstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "70806": [
        {
            "o": "Kornwestheim",
            "l": "Baden-Württemberg"
        }
    ],
    "70825": [
        {
            "o": "Korntal-Münchingen",
            "l": "Baden-Württemberg"
        }
    ],
    "70839": [
        {
            "o": "Gerlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71032": [
        {
            "o": "Böblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71034": [
        {
            "o": "Böblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71063": [
        {
            "o": "Sindelfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71065": [
        {
            "o": "Sindelfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71067": [
        {
            "o": "Sindelfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71069": [
        {
            "o": "Sindelfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71083": [
        {
            "o": "Herrenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "71088": [
        {
            "o": "Holzgerlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71093": [
        {
            "o": "Weil im Schönbuch",
            "l": "Baden-Württemberg"
        }
    ],
    "71101": [
        {
            "o": "Schönaich",
            "l": "Baden-Württemberg"
        }
    ],
    "71106": [
        {
            "o": "Magstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "71111": [
        {
            "o": "Filderstadt",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Waldenbuch",
            "l": "Baden-Württemberg"
        }
    ],
    "71116": [
        {
            "o": "Gärtringen",
            "l": "Baden-Württemberg"
        }
    ],
    "71120": [
        {
            "o": "Grafenau",
            "l": "Baden-Württemberg"
        }
    ],
    "71126": [
        {
            "o": "Gäufelden",
            "l": "Baden-Württemberg"
        }
    ],
    "71131": [
        {
            "o": "Jettingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71134": [
        {
            "o": "Aidlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71139": [
        {
            "o": "Ehningen",
            "l": "Baden-Württemberg"
        }
    ],
    "71144": [
        {
            "o": "Steinenbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "71149": [
        {
            "o": "Bondorf",
            "l": "Baden-Württemberg"
        }
    ],
    "71154": [
        {
            "o": "Nufringen",
            "l": "Baden-Württemberg"
        }
    ],
    "71155": [
        {
            "o": "Altdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "71157": [
        {
            "o": "Hildrizhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "71159": [
        {
            "o": "Mötzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71229": [
        {
            "o": "Leonberg",
            "l": "Baden-Württemberg"
        }
    ],
    "71254": [
        {
            "o": "Ditzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71263": [
        {
            "o": "Weil der Stadt",
            "l": "Baden-Württemberg"
        }
    ],
    "71272": [
        {
            "o": "Renningen",
            "l": "Baden-Württemberg"
        }
    ],
    "71277": [
        {
            "o": "Rutesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71282": [
        {
            "o": "Hemmingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71287": [
        {
            "o": "Weissach",
            "l": "Baden-Württemberg"
        }
    ],
    "71292": [
        {
            "o": "Friolzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71296": [
        {
            "o": "Heimsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71297": [
        {
            "o": "Mönsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71299": [
        {
            "o": "Wimsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71332": [
        {
            "o": "Waiblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71334": [
        {
            "o": "Waiblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71336": [
        {
            "o": "Waiblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71364": [
        {
            "o": "Winnenden",
            "l": "Baden-Württemberg"
        }
    ],
    "71384": [
        {
            "o": "Weinstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "71394": [
        {
            "o": "Kernen im Remstal",
            "l": "Baden-Württemberg"
        }
    ],
    "71397": [
        {
            "o": "Leutenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "71404": [
        {
            "o": "Korb",
            "l": "Baden-Württemberg"
        }
    ],
    "71409": [
        {
            "o": "Schwaikheim",
            "l": "Baden-Württemberg"
        }
    ],
    "71522": [
        {
            "o": "Backnang",
            "l": "Baden-Württemberg"
        }
    ],
    "71540": [
        {
            "o": "Murrhardt",
            "l": "Baden-Württemberg"
        }
    ],
    "71543": [
        {
            "o": "Beilstein",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wüstenrot",
            "l": "Baden-Württemberg"
        }
    ],
    "71546": [
        {
            "o": "Aspach",
            "l": "Baden-Württemberg"
        }
    ],
    "71549": [
        {
            "o": "Auenwald",
            "l": "Baden-Württemberg"
        }
    ],
    "71554": [
        {
            "o": "Weissach im Tal",
            "l": "Baden-Württemberg"
        }
    ],
    "71560": [
        {
            "o": "Oppenweiler",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Sulzbach an der Murr",
            "l": "Baden-Württemberg"
        }
    ],
    "71563": [
        {
            "o": "Affalterbach",
            "l": "Baden-Württemberg"
        }
    ],
    "71566": [
        {
            "o": "Althütte",
            "l": "Baden-Württemberg"
        }
    ],
    "71570": [
        {
            "o": "Oppenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "71573": [
        {
            "o": "Allmersbach im Tal",
            "l": "Baden-Württemberg"
        }
    ],
    "71576": [
        {
            "o": "Burgstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "71577": [
        {
            "o": "Großerlach",
            "l": "Baden-Württemberg"
        }
    ],
    "71579": [
        {
            "o": "Spiegelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "71634": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "71636": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "71638": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "71640": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "71642": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "71665": [
        {
            "o": "Vaihingen an der Enz",
            "l": "Baden-Württemberg"
        }
    ],
    "71672": [
        {
            "o": "Ludwigsburg",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Marbach am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "71679": [
        {
            "o": "Asperg",
            "l": "Baden-Württemberg"
        }
    ],
    "71686": [
        {
            "o": "Remseck am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "71691": [
        {
            "o": "Freiberg am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "71696": [
        {
            "o": "Möglingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71701": [
        {
            "o": "Schwieberdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71706": [
        {
            "o": "Markgröningen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schwieberdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71711": [
        {
            "o": "Marbach am Neckar",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Murr",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Steinheim an der Murr",
            "l": "Baden-Württemberg"
        }
    ],
    "71717": [
        {
            "o": "Beilstein",
            "l": "Baden-Württemberg"
        }
    ],
    "71720": [
        {
            "o": "Oberstenfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "71723": [
        {
            "o": "Großbottwar",
            "l": "Baden-Württemberg"
        }
    ],
    "71726": [
        {
            "o": "Benningen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "71729": [
        {
            "o": "Erdmannhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "71732": [
        {
            "o": "Tamm",
            "l": "Baden-Württemberg"
        }
    ],
    "71735": [
        {
            "o": "Eberdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "71737": [
        {
            "o": "Kirchberg an der Murr",
            "l": "Baden-Württemberg"
        }
    ],
    "71739": [
        {
            "o": "Oberriexingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72070": [
        {
            "o": "Tübingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72072": [
        {
            "o": "Tübingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72074": [
        {
            "o": "Tübingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72076": [
        {
            "o": "Tübingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72108": [
        {
            "o": "Rottenburg am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "72116": [
        {
            "o": "Mössingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72119": [
        {
            "o": "Ammerbuch",
            "l": "Baden-Württemberg"
        }
    ],
    "72124": [
        {
            "o": "Pliezhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72127": [
        {
            "o": "Kusterdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72131": [
        {
            "o": "Ofterdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72135": [
        {
            "o": "Dettenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72138": [
        {
            "o": "Kirchentellinsfurt",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Kusterdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72141": [
        {
            "o": "Walddorfhäslach",
            "l": "Baden-Württemberg"
        }
    ],
    "72144": [
        {
            "o": "Dußlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72145": [
        {
            "o": "Hirrlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72147": [
        {
            "o": "Nehren",
            "l": "Baden-Württemberg"
        }
    ],
    "72149": [
        {
            "o": "Neustetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72160": [
        {
            "o": "Horb am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "72172": [
        {
            "o": "Sulz am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "72175": [
        {
            "o": "Dornhan",
            "l": "Baden-Württemberg"
        }
    ],
    "72178": [
        {
            "o": "Waldachtal",
            "l": "Baden-Württemberg"
        }
    ],
    "72181": [
        {
            "o": "Starzach",
            "l": "Baden-Württemberg"
        }
    ],
    "72184": [
        {
            "o": "Eutingen im Gäu",
            "l": "Baden-Württemberg"
        }
    ],
    "72186": [
        {
            "o": "Empfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72189": [
        {
            "o": "Vöhringen",
            "l": "Baden-Württemberg"
        }
    ],
    "72202": [
        {
            "o": "Nagold",
            "l": "Baden-Württemberg"
        }
    ],
    "72213": [
        {
            "o": "Altensteig",
            "l": "Baden-Württemberg"
        }
    ],
    "72218": [
        {
            "o": "Wildberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72221": [
        {
            "o": "Haiterbach",
            "l": "Baden-Württemberg"
        }
    ],
    "72224": [
        {
            "o": "Ebhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72226": [
        {
            "o": "Simmersfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "72227": [
        {
            "o": "Egenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72229": [
        {
            "o": "Rohrdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "72250": [
        {
            "o": "Bad Peterstal-Griesbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Freudenstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "72270": [
        {
            "o": "Baiersbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "72275": [
        {
            "o": "Alpirsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "72280": [
        {
            "o": "Dornstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72285": [
        {
            "o": "Pfalzgrafenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "72290": [
        {
            "o": "Loßburg",
            "l": "Baden-Württemberg"
        }
    ],
    "72293": [
        {
            "o": "Glatten",
            "l": "Baden-Württemberg"
        }
    ],
    "72294": [
        {
            "o": "Grömbach",
            "l": "Baden-Württemberg"
        }
    ],
    "72296": [
        {
            "o": "Schopfloch",
            "l": "Baden-Württemberg"
        }
    ],
    "72297": [
        {
            "o": "Grömbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Seewald",
            "l": "Baden-Württemberg"
        }
    ],
    "72299": [
        {
            "o": "Wörnersberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72336": [
        {
            "o": "Balingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72348": [
        {
            "o": "Rosenfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "72351": [
        {
            "o": "Geislingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72355": [
        {
            "o": "Schömberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72356": [
        {
            "o": "Dautmergen",
            "l": "Baden-Württemberg"
        }
    ],
    "72358": [
        {
            "o": "Dormettingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72359": [
        {
            "o": "Dotternhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72361": [
        {
            "o": "Hausen am Tann",
            "l": "Baden-Württemberg"
        }
    ],
    "72362": [
        {
            "o": "Nusplingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72364": [
        {
            "o": "Obernheim",
            "l": "Baden-Württemberg"
        }
    ],
    "72365": [
        {
            "o": "Ratshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72367": [
        {
            "o": "Weilen unter den Rinnen",
            "l": "Baden-Württemberg"
        }
    ],
    "72369": [
        {
            "o": "Zimmern unter der Burg",
            "l": "Baden-Württemberg"
        }
    ],
    "72379": [
        {
            "o": "Hechingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72393": [
        {
            "o": "Burladingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72401": [
        {
            "o": "Haigerloch",
            "l": "Baden-Württemberg"
        }
    ],
    "72406": [
        {
            "o": "Bisingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72411": [
        {
            "o": "Bodelshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72414": [
        {
            "o": "Rangendingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72415": [
        {
            "o": "Grosselfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72417": [
        {
            "o": "Jungingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72419": [
        {
            "o": "Neufra",
            "l": "Baden-Württemberg"
        }
    ],
    "72458": [
        {
            "o": "Albstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "72459": [
        {
            "o": "Albstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "72461": [
        {
            "o": "Albstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "72469": [
        {
            "o": "Meßstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72474": [
        {
            "o": "Winterlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72475": [
        {
            "o": "Bitz",
            "l": "Baden-Württemberg"
        }
    ],
    "72477": [
        {
            "o": "Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "72479": [
        {
            "o": "Straßberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72488": [
        {
            "o": "Sigmaringen",
            "l": "Baden-Württemberg"
        }
    ],
    "72501": [
        {
            "o": "Gammertingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72505": [
        {
            "o": "Krauchenwies",
            "l": "Baden-Württemberg"
        }
    ],
    "72510": [
        {
            "o": "Stetten am kalten Markt",
            "l": "Baden-Württemberg"
        }
    ],
    "72511": [
        {
            "o": "Bingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72513": [
        {
            "o": "Hettingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72514": [
        {
            "o": "Inzigkofen",
            "l": "Baden-Württemberg"
        }
    ],
    "72516": [
        {
            "o": "Scheer",
            "l": "Baden-Württemberg"
        }
    ],
    "72517": [
        {
            "o": "Sigmaringendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "72519": [
        {
            "o": "Veringenstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "72525": [
        {
            "o": "Münsingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72531": [
        {
            "o": "Hohenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "72532": [
        {
            "o": "Gomadingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72534": [
        {
            "o": "Hayingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72535": [
        {
            "o": "Heroldstatt",
            "l": "Baden-Württemberg"
        }
    ],
    "72537": [
        {
            "o": "Mehrstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72539": [
        {
            "o": "Pfronstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72555": [
        {
            "o": "Metzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72574": [
        {
            "o": "Bad Urach",
            "l": "Baden-Württemberg"
        }
    ],
    "72581": [
        {
            "o": "Dettingen an der Erms",
            "l": "Baden-Württemberg"
        }
    ],
    "72582": [
        {
            "o": "Grabenstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "72584": [
        {
            "o": "Hülben",
            "l": "Baden-Württemberg"
        }
    ],
    "72585": [
        {
            "o": "Riederich",
            "l": "Baden-Württemberg"
        }
    ],
    "72587": [
        {
            "o": "Römerstein",
            "l": "Baden-Württemberg"
        }
    ],
    "72589": [
        {
            "o": "Westerheim",
            "l": "Baden-Württemberg"
        }
    ],
    "72622": [
        {
            "o": "Nürtingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72631": [
        {
            "o": "Aichtal",
            "l": "Baden-Württemberg"
        }
    ],
    "72636": [
        {
            "o": "Frickenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "72639": [
        {
            "o": "Neuffen",
            "l": "Baden-Württemberg"
        }
    ],
    "72644": [
        {
            "o": "Oberboihingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72649": [
        {
            "o": "Wolfschlugen",
            "l": "Baden-Württemberg"
        }
    ],
    "72654": [
        {
            "o": "Neckartenzlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72655": [
        {
            "o": "Altdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "72657": [
        {
            "o": "Altenriet",
            "l": "Baden-Württemberg"
        }
    ],
    "72658": [
        {
            "o": "Bempflingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72660": [
        {
            "o": "Beuren",
            "l": "Baden-Württemberg"
        }
    ],
    "72661": [
        {
            "o": "Grafenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72663": [
        {
            "o": "Großbettlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72664": [
        {
            "o": "Kohlberg",
            "l": "Baden-Württemberg"
        }
    ],
    "72666": [
        {
            "o": "Neckartailfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72667": [
        {
            "o": "Schlaitdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "72669": [
        {
            "o": "Unterensingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72760": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72762": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72764": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72766": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72768": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72770": [
        {
            "o": "Reutlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72793": [
        {
            "o": "Pfullingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72800": [
        {
            "o": "Eningen unter Achalm",
            "l": "Baden-Württemberg"
        }
    ],
    "72805": [
        {
            "o": "Lichtenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "72810": [
        {
            "o": "Gomaringen",
            "l": "Baden-Württemberg"
        }
    ],
    "72813": [
        {
            "o": "St. Johann",
            "l": "Baden-Württemberg"
        }
    ],
    "72818": [
        {
            "o": "Trochtelfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "72820": [
        {
            "o": "Sonnenbühl",
            "l": "Baden-Württemberg"
        }
    ],
    "72827": [
        {
            "o": "Wannweil",
            "l": "Baden-Württemberg"
        }
    ],
    "72829": [
        {
            "o": "Engstingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73033": [
        {
            "o": "Göppingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73035": [
        {
            "o": "Göppingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73037": [
        {
            "o": "Eschenbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Göppingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Heiningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73054": [
        {
            "o": "Eislingen/Fils",
            "l": "Baden-Württemberg"
        }
    ],
    "73061": [
        {
            "o": "Ebersbach an der Fils",
            "l": "Baden-Württemberg"
        }
    ],
    "73066": [
        {
            "o": "Uhingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73072": [
        {
            "o": "Donzdorf",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        }
    ],
    "73079": [
        {
            "o": "Süßen",
            "l": "Baden-Württemberg"
        }
    ],
    "73084": [
        {
            "o": "Salach",
            "l": "Baden-Württemberg"
        }
    ],
    "73087": [
        {
            "o": "Bad Boll",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Zell unter Aichelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73092": [
        {
            "o": "Heiningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73095": [
        {
            "o": "Albershausen",
            "l": "Baden-Württemberg"
        }
    ],
    "73098": [
        {
            "o": "Rechberghausen",
            "l": "Baden-Württemberg"
        }
    ],
    "73099": [
        {
            "o": "Adelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73101": [
        {
            "o": "Aichelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73102": [
        {
            "o": "Birenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73104": [
        {
            "o": "Börtlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73105": [
        {
            "o": "Dürnau",
            "l": "Baden-Württemberg"
        }
    ],
    "73107": [
        {
            "o": "Eschenbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Heiningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73108": [
        {
            "o": "Gammelshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "73110": [
        {
            "o": "Hattenhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "73111": [
        {
            "o": "Lauterstein",
            "l": "Baden-Württemberg"
        }
    ],
    "73113": [
        {
            "o": "Ottenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73114": [
        {
            "o": "Schlat",
            "l": "Baden-Württemberg"
        }
    ],
    "73116": [
        {
            "o": "Göppingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wäschenbeuren",
            "l": "Baden-Württemberg"
        }
    ],
    "73117": [
        {
            "o": "Wangen",
            "l": "Baden-Württemberg"
        }
    ],
    "73119": [
        {
            "o": "Zell unter Aichelberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73207": [
        {
            "o": "Plochingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73230": [
        {
            "o": "Kirchheim unter Teck",
            "l": "Baden-Württemberg"
        }
    ],
    "73235": [
        {
            "o": "Weilheim an der Teck",
            "l": "Baden-Württemberg"
        }
    ],
    "73240": [
        {
            "o": "Wendlingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73249": [
        {
            "o": "Wernau (Neckar)",
            "l": "Baden-Württemberg"
        }
    ],
    "73252": [
        {
            "o": "Lenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73257": [
        {
            "o": "Köngen",
            "l": "Baden-Württemberg"
        }
    ],
    "73262": [
        {
            "o": "Reichenbach an der Fils",
            "l": "Baden-Württemberg"
        }
    ],
    "73265": [
        {
            "o": "Dettingen unter Teck",
            "l": "Baden-Württemberg"
        }
    ],
    "73266": [
        {
            "o": "Bissingen an der Teck",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Lenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73268": [
        {
            "o": "Erkenbrechtsweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "73269": [
        {
            "o": "Hochdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "73271": [
        {
            "o": "Holzmaden",
            "l": "Baden-Württemberg"
        }
    ],
    "73272": [
        {
            "o": "Neidlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73274": [
        {
            "o": "Notzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73275": [
        {
            "o": "Ohmden",
            "l": "Baden-Württemberg"
        }
    ],
    "73277": [
        {
            "o": "Owen",
            "l": "Baden-Württemberg"
        }
    ],
    "73278": [
        {
            "o": "Schlierbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73312": [
        {
            "o": "Bad Ditzenbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Deggingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Geislingen an der Steige",
            "l": "Baden-Württemberg"
        }
    ],
    "73326": [
        {
            "o": "Deggingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73329": [
        {
            "o": "Kuchen",
            "l": "Baden-Württemberg"
        }
    ],
    "73333": [
        {
            "o": "Gingen an der Fils",
            "l": "Baden-Württemberg"
        }
    ],
    "73337": [
        {
            "o": "Bad Überkingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73340": [
        {
            "o": "Amstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "73342": [
        {
            "o": "Bad Ditzenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73344": [
        {
            "o": "Gruibingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73345": [
        {
            "o": "Bad Ditzenbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Drackenstein",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hohenstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "73347": [
        {
            "o": "Mühlhausen im Täle",
            "l": "Baden-Württemberg"
        }
    ],
    "73349": [
        {
            "o": "Wiesensteig",
            "l": "Baden-Württemberg"
        }
    ],
    "73430": [
        {
            "o": "Aalen",
            "l": "Baden-Württemberg"
        }
    ],
    "73431": [
        {
            "o": "Aalen",
            "l": "Baden-Württemberg"
        }
    ],
    "73432": [
        {
            "o": "Aalen",
            "l": "Baden-Württemberg"
        }
    ],
    "73433": [
        {
            "o": "Aalen",
            "l": "Baden-Württemberg"
        }
    ],
    "73434": [
        {
            "o": "Aalen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hüttlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73441": [
        {
            "o": "Bopfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73447": [
        {
            "o": "Oberkochen",
            "l": "Baden-Württemberg"
        }
    ],
    "73450": [
        {
            "o": "Neresheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73453": [
        {
            "o": "Abtsgmünd",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hüttlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73457": [
        {
            "o": "Essingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73460": [
        {
            "o": "Hüttlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73463": [
        {
            "o": "Westhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "73466": [
        {
            "o": "Lauchheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73467": [
        {
            "o": "Kirchheim am Ries",
            "l": "Baden-Württemberg"
        }
    ],
    "73469": [
        {
            "o": "Riesbürg",
            "l": "Baden-Württemberg"
        }
    ],
    "73479": [
        {
            "o": "Ellwangen (Jagst)",
            "l": "Baden-Württemberg"
        }
    ],
    "73485": [
        {
            "o": "Unterschneidheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73486": [
        {
            "o": "Adelmannsfelden",
            "l": "Baden-Württemberg"
        }
    ],
    "73488": [
        {
            "o": "Ellenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73489": [
        {
            "o": "Jagstzell",
            "l": "Baden-Württemberg"
        }
    ],
    "73491": [
        {
            "o": "Neuler",
            "l": "Baden-Württemberg"
        }
    ],
    "73492": [
        {
            "o": "Rainau",
            "l": "Baden-Württemberg"
        }
    ],
    "73494": [
        {
            "o": "Rosenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "73495": [
        {
            "o": "Stödtlen",
            "l": "Baden-Württemberg"
        }
    ],
    "73497": [
        {
            "o": "Tannhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "73499": [
        {
            "o": "Wört",
            "l": "Baden-Württemberg"
        }
    ],
    "73525": [
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        }
    ],
    "73527": [
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Täferrot",
            "l": "Baden-Württemberg"
        }
    ],
    "73529": [
        {
            "o": "Ottenbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        }
    ],
    "73540": [
        {
            "o": "Heubach",
            "l": "Baden-Württemberg"
        }
    ],
    "73547": [
        {
            "o": "Lorch",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wäschenbeuren",
            "l": "Baden-Württemberg"
        }
    ],
    "73550": [
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Waldstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "73553": [
        {
            "o": "Alfdorf",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Kaisersbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schwäbisch Gmünd",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Welzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73557": [
        {
            "o": "Mutlangen",
            "l": "Baden-Württemberg"
        }
    ],
    "73560": [
        {
            "o": "Böbingen an der Rems",
            "l": "Baden-Württemberg"
        }
    ],
    "73563": [
        {
            "o": "Essingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Mögglingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73565": [
        {
            "o": "Spraitbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73566": [
        {
            "o": "Bartholomä",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Essingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Steinheim am Albuch",
            "l": "Baden-Württemberg"
        }
    ],
    "73568": [
        {
            "o": "Durlangen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Spraitbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73569": [
        {
            "o": "Eschach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Obergröningen",
            "l": "Baden-Württemberg"
        }
    ],
    "73571": [
        {
            "o": "Göggingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73572": [
        {
            "o": "Heuchlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73574": [
        {
            "o": "Iggingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73575": [
        {
            "o": "Leinzell",
            "l": "Baden-Württemberg"
        }
    ],
    "73577": [
        {
            "o": "Ruppertshofen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Spraitbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73579": [
        {
            "o": "Schechingen",
            "l": "Baden-Württemberg"
        }
    ],
    "73614": [
        {
            "o": "Schorndorf",
            "l": "Baden-Württemberg"
        }
    ],
    "73630": [
        {
            "o": "Remshalden",
            "l": "Baden-Württemberg"
        }
    ],
    "73635": [
        {
            "o": "Rudersberg",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Welzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73642": [
        {
            "o": "Kaisersbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Plüderhausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Welzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73650": [
        {
            "o": "Winterbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73655": [
        {
            "o": "Alfdorf",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Plüderhausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Urbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73660": [
        {
            "o": "Urbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73663": [
        {
            "o": "Berglen",
            "l": "Baden-Württemberg"
        }
    ],
    "73666": [
        {
            "o": "Baltmannsweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "73667": [
        {
            "o": "Kaisersbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Welzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "73669": [
        {
            "o": "Lichtenwald",
            "l": "Baden-Württemberg"
        }
    ],
    "73728": [
        {
            "o": "Esslingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73730": [
        {
            "o": "Esslingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73732": [
        {
            "o": "Esslingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73733": [
        {
            "o": "Esslingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73734": [
        {
            "o": "Esslingen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "73760": [
        {
            "o": "Ostfildern",
            "l": "Baden-Württemberg"
        }
    ],
    "73765": [
        {
            "o": "Neuhausen auf den Fildern",
            "l": "Baden-Württemberg"
        }
    ],
    "73770": [
        {
            "o": "Denkendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "73773": [
        {
            "o": "Aichwald",
            "l": "Baden-Württemberg"
        }
    ],
    "73776": [
        {
            "o": "Altbach",
            "l": "Baden-Württemberg"
        }
    ],
    "73779": [
        {
            "o": "Deizisau",
            "l": "Baden-Württemberg"
        }
    ],
    "74072": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74074": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74076": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74078": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74080": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74081": [
        {
            "o": "Heilbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74172": [
        {
            "o": "Neckarsulm",
            "l": "Baden-Württemberg"
        }
    ],
    "74177": [
        {
            "o": "Bad Friedrichshall",
            "l": "Baden-Württemberg"
        }
    ],
    "74182": [
        {
            "o": "Obersulm",
            "l": "Baden-Württemberg"
        }
    ],
    "74189": [
        {
            "o": "Weinsberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74193": [
        {
            "o": "Schwaigern",
            "l": "Baden-Württemberg"
        }
    ],
    "74196": [
        {
            "o": "Neuenstadt am Kocher",
            "l": "Baden-Württemberg"
        }
    ],
    "74199": [
        {
            "o": "Untergruppenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74206": [
        {
            "o": "Bad Wimpfen",
            "l": "Baden-Württemberg"
        }
    ],
    "74211": [
        {
            "o": "Leingarten",
            "l": "Baden-Württemberg"
        }
    ],
    "74214": [
        {
            "o": "Schöntal",
            "l": "Baden-Württemberg"
        }
    ],
    "74219": [
        {
            "o": "Möckmühl",
            "l": "Baden-Württemberg"
        }
    ],
    "74223": [
        {
            "o": "Flein",
            "l": "Baden-Württemberg"
        }
    ],
    "74226": [
        {
            "o": "Nordheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74229": [
        {
            "o": "Oedheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74232": [
        {
            "o": "Abstatt",
            "l": "Baden-Württemberg"
        }
    ],
    "74235": [
        {
            "o": "Erlenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74238": [
        {
            "o": "Krautheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74239": [
        {
            "o": "Hardthausen am Kocher",
            "l": "Baden-Württemberg"
        }
    ],
    "74243": [
        {
            "o": "Langenbrettach",
            "l": "Baden-Württemberg"
        }
    ],
    "74245": [
        {
            "o": "Löwenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "74246": [
        {
            "o": "Eberstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "74248": [
        {
            "o": "Ellhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "74249": [
        {
            "o": "Jagsthausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74251": [
        {
            "o": "Lehrensteinsfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "74252": [
        {
            "o": "Massenbachhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74254": [
        {
            "o": "Offenau",
            "l": "Baden-Württemberg"
        }
    ],
    "74255": [
        {
            "o": "Roigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74257": [
        {
            "o": "Untereisesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74259": [
        {
            "o": "Widdern",
            "l": "Baden-Württemberg"
        }
    ],
    "74321": [
        {
            "o": "Bietigheim-Bissingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74336": [
        {
            "o": "Brackenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74343": [
        {
            "o": "Sachsenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74348": [
        {
            "o": "Lauffen am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "74354": [
        {
            "o": "Besigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74357": [
        {
            "o": "Bönnigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74360": [
        {
            "o": "Ilsfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "74363": [
        {
            "o": "Güglingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74366": [
        {
            "o": "Kirchheim am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "74369": [
        {
            "o": "Löchgau",
            "l": "Baden-Württemberg"
        }
    ],
    "74372": [
        {
            "o": "Sersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74374": [
        {
            "o": "Zaberfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "74376": [
        {
            "o": "Gemmrigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74379": [
        {
            "o": "Ingersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74382": [
        {
            "o": "Neckarwestheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74385": [
        {
            "o": "Pleidelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74388": [
        {
            "o": "Talheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74389": [
        {
            "o": "Cleebronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74391": [
        {
            "o": "Erligheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74392": [
        {
            "o": "Freudental",
            "l": "Baden-Württemberg"
        }
    ],
    "74394": [
        {
            "o": "Hessigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74395": [
        {
            "o": "Mundelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74397": [
        {
            "o": "Pfaffenhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "74399": [
        {
            "o": "Walheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74405": [
        {
            "o": "Gaildorf",
            "l": "Baden-Württemberg"
        }
    ],
    "74417": [
        {
            "o": "Gschwend",
            "l": "Baden-Württemberg"
        }
    ],
    "74420": [
        {
            "o": "Oberrot",
            "l": "Baden-Württemberg"
        }
    ],
    "74423": [
        {
            "o": "Obersontheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74424": [
        {
            "o": "Bühlertann",
            "l": "Baden-Württemberg"
        }
    ],
    "74426": [
        {
            "o": "Bühlerzell",
            "l": "Baden-Württemberg"
        }
    ],
    "74427": [
        {
            "o": "Fichtenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74429": [
        {
            "o": "Sulzbach-Laufen",
            "l": "Baden-Württemberg"
        }
    ],
    "74523": [
        {
            "o": "Schwäbisch Hall",
            "l": "Baden-Württemberg"
        }
    ],
    "74532": [
        {
            "o": "Ilshofen",
            "l": "Baden-Württemberg"
        }
    ],
    "74535": [
        {
            "o": "Mainhardt",
            "l": "Baden-Württemberg"
        }
    ],
    "74538": [
        {
            "o": "Rosengarten",
            "l": "Baden-Württemberg"
        }
    ],
    "74541": [
        {
            "o": "Vellberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74542": [
        {
            "o": "Braunsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74544": [
        {
            "o": "Michelbach an der Bilz",
            "l": "Baden-Württemberg"
        }
    ],
    "74545": [
        {
            "o": "Michelfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "74547": [
        {
            "o": "Untermünkheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74549": [
        {
            "o": "Wolpertshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74564": [
        {
            "o": "Crailsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74572": [
        {
            "o": "Blaufelden",
            "l": "Baden-Württemberg"
        }
    ],
    "74575": [
        {
            "o": "Schrozberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74579": [
        {
            "o": "Fichtenau",
            "l": "Baden-Württemberg"
        }
    ],
    "74582": [
        {
            "o": "Gerabronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74585": [
        {
            "o": "Rot am See",
            "l": "Baden-Württemberg"
        }
    ],
    "74586": [
        {
            "o": "Frankenhardt",
            "l": "Baden-Württemberg"
        }
    ],
    "74589": [
        {
            "o": "Satteldorf",
            "l": "Baden-Württemberg"
        }
    ],
    "74592": [
        {
            "o": "Kirchberg an der Jagst",
            "l": "Baden-Württemberg"
        }
    ],
    "74594": [
        {
            "o": "Kreßberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74595": [
        {
            "o": "Langenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "74597": [
        {
            "o": "Crailsheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Stimpfach",
            "l": "Baden-Württemberg"
        }
    ],
    "74599": [
        {
            "o": "Wallhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74613": [
        {
            "o": "Öhringen",
            "l": "Baden-Württemberg"
        }
    ],
    "74626": [
        {
            "o": "Bretzfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "74629": [
        {
            "o": "Pfedelbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74632": [
        {
            "o": "Neuenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "74635": [
        {
            "o": "Kupferzell",
            "l": "Baden-Württemberg"
        }
    ],
    "74638": [
        {
            "o": "Waldenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "74639": [
        {
            "o": "Zweiflingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74653": [
        {
            "o": "Ingelfingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Künzelsau",
            "l": "Baden-Württemberg"
        }
    ],
    "74670": [
        {
            "o": "Forchtenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74673": [
        {
            "o": "Mulfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74676": [
        {
            "o": "Niedernhall",
            "l": "Baden-Württemberg"
        }
    ],
    "74677": [
        {
            "o": "Dörzbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74679": [
        {
            "o": "Weißbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74706": [
        {
            "o": "Osterburken",
            "l": "Baden-Württemberg"
        }
    ],
    "74722": [
        {
            "o": "Buchen (Odenwald)",
            "l": "Baden-Württemberg"
        }
    ],
    "74731": [
        {
            "o": "Eichenbühl",
            "l": "Bayern"
        },
        {
            "o": "Walldürn",
            "l": "Baden-Württemberg"
        }
    ],
    "74736": [
        {
            "o": "Hardheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74740": [
        {
            "o": "Adelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74743": [
        {
            "o": "Seckach",
            "l": "Baden-Württemberg"
        }
    ],
    "74744": [
        {
            "o": "Ahorn",
            "l": "Baden-Württemberg"
        }
    ],
    "74746": [
        {
            "o": "Höpfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74747": [
        {
            "o": "Ravenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "74749": [
        {
            "o": "Rosenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "74821": [
        {
            "o": "Mosbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74831": [
        {
            "o": "Gundelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74834": [
        {
            "o": "Elztal",
            "l": "Baden-Württemberg"
        }
    ],
    "74838": [
        {
            "o": "Limbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74842": [
        {
            "o": "Billigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74847": [
        {
            "o": "Obrigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74850": [
        {
            "o": "Schefflenz",
            "l": "Baden-Württemberg"
        }
    ],
    "74855": [
        {
            "o": "Haßmersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74858": [
        {
            "o": "Aglasterhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74861": [
        {
            "o": "Neudenau",
            "l": "Baden-Württemberg"
        }
    ],
    "74862": [
        {
            "o": "Binau",
            "l": "Baden-Württemberg"
        }
    ],
    "74864": [
        {
            "o": "Fahrenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74865": [
        {
            "o": "Neckarzimmern",
            "l": "Baden-Württemberg"
        }
    ],
    "74867": [
        {
            "o": "Neunkirchen",
            "l": "Baden-Württemberg"
        }
    ],
    "74869": [
        {
            "o": "Schwarzach",
            "l": "Baden-Württemberg"
        }
    ],
    "74889": [
        {
            "o": "Sinsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74906": [
        {
            "o": "Bad Rappenau",
            "l": "Baden-Württemberg"
        }
    ],
    "74909": [
        {
            "o": "Meckesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74912": [
        {
            "o": "Kirchardt",
            "l": "Baden-Württemberg"
        }
    ],
    "74915": [
        {
            "o": "Waibstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "74918": [
        {
            "o": "Angelbachtal",
            "l": "Baden-Württemberg"
        }
    ],
    "74921": [
        {
            "o": "Helmstadt-Bargen",
            "l": "Baden-Württemberg"
        }
    ],
    "74924": [
        {
            "o": "Neckarbischofsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "74925": [
        {
            "o": "Epfenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74927": [
        {
            "o": "Eschelbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "74928": [
        {
            "o": "Hüffenhardt",
            "l": "Baden-Württemberg"
        }
    ],
    "74930": [
        {
            "o": "Ittlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "74931": [
        {
            "o": "Lobbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74933": [
        {
            "o": "Neidenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "74934": [
        {
            "o": "Reichartshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "74936": [
        {
            "o": "Siegelsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74937": [
        {
            "o": "Spechbach",
            "l": "Baden-Württemberg"
        }
    ],
    "74939": [
        {
            "o": "Zuzenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "75015": [
        {
            "o": "Bretten",
            "l": "Baden-Württemberg"
        }
    ],
    "75031": [
        {
            "o": "Eppingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75038": [
        {
            "o": "Oberderdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75045": [
        {
            "o": "Walzbachtal",
            "l": "Baden-Württemberg"
        }
    ],
    "75050": [
        {
            "o": "Gemmingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75053": [
        {
            "o": "Gondelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75056": [
        {
            "o": "Sulzfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "75057": [
        {
            "o": "Kürnbach",
            "l": "Baden-Württemberg"
        }
    ],
    "75059": [
        {
            "o": "Zaisenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "75172": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75173": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75175": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75177": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75179": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75180": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75181": [
        {
            "o": "Pforzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75196": [
        {
            "o": "Remchingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75203": [
        {
            "o": "Königsbach-Stein",
            "l": "Baden-Württemberg"
        }
    ],
    "75210": [
        {
            "o": "Keltern",
            "l": "Baden-Württemberg"
        }
    ],
    "75217": [
        {
            "o": "Birkenfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "75223": [
        {
            "o": "Niefern-Öschelbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "75228": [
        {
            "o": "Ispringen",
            "l": "Baden-Württemberg"
        }
    ],
    "75233": [
        {
            "o": "Tiefenbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "75236": [
        {
            "o": "Kämpfelbach",
            "l": "Baden-Württemberg"
        }
    ],
    "75239": [
        {
            "o": "Eisingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75242": [
        {
            "o": "Neuhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "75245": [
        {
            "o": "Neulingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75248": [
        {
            "o": "Ölbronn-Dürrn",
            "l": "Baden-Württemberg"
        }
    ],
    "75249": [
        {
            "o": "Kieselbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "75305": [
        {
            "o": "Neuenbürg",
            "l": "Baden-Württemberg"
        }
    ],
    "75323": [
        {
            "o": "Bad Wildbad",
            "l": "Baden-Württemberg"
        }
    ],
    "75328": [
        {
            "o": "Schömberg",
            "l": "Baden-Württemberg"
        }
    ],
    "75331": [
        {
            "o": "Engelsbrand",
            "l": "Baden-Württemberg"
        }
    ],
    "75334": [
        {
            "o": "Straubenhardt",
            "l": "Baden-Württemberg"
        }
    ],
    "75335": [
        {
            "o": "Dobel",
            "l": "Baden-Württemberg"
        }
    ],
    "75337": [
        {
            "o": "Enzklösterle",
            "l": "Baden-Württemberg"
        }
    ],
    "75339": [
        {
            "o": "Höfen an der Enz",
            "l": "Baden-Württemberg"
        }
    ],
    "75365": [
        {
            "o": "Calw",
            "l": "Baden-Württemberg"
        }
    ],
    "75378": [
        {
            "o": "Bad Liebenzell",
            "l": "Baden-Württemberg"
        }
    ],
    "75382": [
        {
            "o": "Althengstett",
            "l": "Baden-Württemberg"
        }
    ],
    "75385": [
        {
            "o": "Bad Teinach-Zavelstein",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Neubulach",
            "l": "Baden-Württemberg"
        }
    ],
    "75387": [
        {
            "o": "Neubulach",
            "l": "Baden-Württemberg"
        }
    ],
    "75389": [
        {
            "o": "Neuweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "75391": [
        {
            "o": "Gechingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75392": [
        {
            "o": "Deckenpfronn",
            "l": "Baden-Württemberg"
        }
    ],
    "75394": [
        {
            "o": "Oberreichenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "75395": [
        {
            "o": "Ostelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75397": [
        {
            "o": "Simmozheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75399": [
        {
            "o": "Unterreichenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "75417": [
        {
            "o": "Mühlacker",
            "l": "Baden-Württemberg"
        }
    ],
    "75428": [
        {
            "o": "Illingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75433": [
        {
            "o": "Maulbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "75438": [
        {
            "o": "Knittlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "75443": [
        {
            "o": "Ötisheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75446": [
        {
            "o": "Wiernsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "75447": [
        {
            "o": "Sternenfels",
            "l": "Baden-Württemberg"
        }
    ],
    "75449": [
        {
            "o": "Wurmberg",
            "l": "Baden-Württemberg"
        }
    ],
    "76131": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76133": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76135": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76137": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76139": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76149": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76185": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76187": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76189": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76199": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76227": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76228": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76229": [
        {
            "o": "Karlsruhe",
            "l": "Baden-Württemberg"
        }
    ],
    "76275": [
        {
            "o": "Ettlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "76287": [
        {
            "o": "Rheinstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "76297": [
        {
            "o": "Stutensee",
            "l": "Baden-Württemberg"
        }
    ],
    "76307": [
        {
            "o": "Karlsbad",
            "l": "Baden-Württemberg"
        }
    ],
    "76316": [
        {
            "o": "Malsch",
            "l": "Baden-Württemberg"
        }
    ],
    "76327": [
        {
            "o": "Pfinztal",
            "l": "Baden-Württemberg"
        }
    ],
    "76332": [
        {
            "o": "Bad Herrenalb",
            "l": "Baden-Württemberg"
        }
    ],
    "76337": [
        {
            "o": "Waldbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "76344": [
        {
            "o": "Eggenstein-Leopoldshafen",
            "l": "Baden-Württemberg"
        }
    ],
    "76351": [
        {
            "o": "Linkenheim-Hochstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "76356": [
        {
            "o": "Weingarten",
            "l": "Baden-Württemberg"
        }
    ],
    "76359": [
        {
            "o": "Marxzell",
            "l": "Baden-Württemberg"
        }
    ],
    "76437": [
        {
            "o": "Rastatt",
            "l": "Baden-Württemberg"
        }
    ],
    "76448": [
        {
            "o": "Durmersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76456": [
        {
            "o": "Kuppenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76461": [
        {
            "o": "Muggensturm",
            "l": "Baden-Württemberg"
        }
    ],
    "76467": [
        {
            "o": "Bietigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76470": [
        {
            "o": "Ötigheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76473": [
        {
            "o": "Iffezheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76474": [
        {
            "o": "Au am Rhein",
            "l": "Baden-Württemberg"
        }
    ],
    "76476": [
        {
            "o": "Bischweier",
            "l": "Baden-Württemberg"
        }
    ],
    "76477": [
        {
            "o": "Elchesheim-Illingen",
            "l": "Baden-Württemberg"
        }
    ],
    "76479": [
        {
            "o": "Steinmauern",
            "l": "Baden-Württemberg"
        }
    ],
    "76530": [
        {
            "o": "Baden-Baden",
            "l": "Baden-Württemberg"
        }
    ],
    "76532": [
        {
            "o": "Baden-Baden",
            "l": "Baden-Württemberg"
        }
    ],
    "76534": [
        {
            "o": "Baden-Baden",
            "l": "Baden-Württemberg"
        }
    ],
    "76547": [
        {
            "o": "Sinzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76549": [
        {
            "o": "Hügelsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76571": [
        {
            "o": "Gaggenau",
            "l": "Baden-Württemberg"
        }
    ],
    "76593": [
        {
            "o": "Gernsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "76596": [
        {
            "o": "Forbach",
            "l": "Baden-Württemberg"
        }
    ],
    "76597": [
        {
            "o": "Loffenau",
            "l": "Baden-Württemberg"
        }
    ],
    "76599": [
        {
            "o": "Weisenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "76646": [
        {
            "o": "Bruchsal",
            "l": "Baden-Württemberg"
        }
    ],
    "76661": [
        {
            "o": "Philippsburg",
            "l": "Baden-Württemberg"
        }
    ],
    "76669": [
        {
            "o": "Bad Schönborn",
            "l": "Baden-Württemberg"
        }
    ],
    "76676": [
        {
            "o": "Graben-Neudorf",
            "l": "Baden-Württemberg"
        }
    ],
    "76684": [
        {
            "o": "Östringen",
            "l": "Baden-Württemberg"
        }
    ],
    "76689": [
        {
            "o": "Karlsdorf-Neuthard",
            "l": "Baden-Württemberg"
        }
    ],
    "76694": [
        {
            "o": "Forst",
            "l": "Baden-Württemberg"
        }
    ],
    "76698": [
        {
            "o": "Ubstadt-Weiher",
            "l": "Baden-Württemberg"
        }
    ],
    "76703": [
        {
            "o": "Kraichtal",
            "l": "Baden-Württemberg"
        }
    ],
    "76706": [
        {
            "o": "Dettenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "76707": [
        {
            "o": "Hambrücken",
            "l": "Baden-Württemberg"
        }
    ],
    "76709": [
        {
            "o": "Kronau",
            "l": "Baden-Württemberg"
        }
    ],
    "76726": [
        {
            "o": "Germersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76744": [
        {
            "o": "Vollmersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wörth am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76751": [
        {
            "o": "Jockgrim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76756": [
        {
            "o": "Bellheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76761": [
        {
            "o": "Rülzheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76764": [
        {
            "o": "Rheinzabern",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76767": [
        {
            "o": "Hagenbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76768": [
        {
            "o": "Berg (Pfalz)",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76770": [
        {
            "o": "Hatzenbühl",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76771": [
        {
            "o": "Hördt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76773": [
        {
            "o": "Kuhardt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76774": [
        {
            "o": "Leimersheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76776": [
        {
            "o": "Neuburg am Rhein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76777": [
        {
            "o": "Neupotz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76779": [
        {
            "o": "Scheibenhardt",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76829": [
        {
            "o": "Landau in der Pfalz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Leinsweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ramberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ranschbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76831": [
        {
            "o": "Billigheim-Ingenheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Birkweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Göcklingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Heuchelheim-Klingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ilbesheim bei Landau in der Pfalz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Impflingen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76833": [
        {
            "o": "Böchingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Frankweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Knöringen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Siebeldingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Walsheim",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76835": [
        {
            "o": "Burrweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Flemlingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gleisweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hainfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rhodt unter Rietburg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Roschbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Weyher in der Pfalz",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76846": [
        {
            "o": "Hauenstein",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76848": [
        {
            "o": "Darstein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dimbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Lug",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schwanheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Spirkelbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wilgartswiesen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76855": [
        {
            "o": "Annweiler am Trifels",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76857": [
        {
            "o": "Albersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dernbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Eußerthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gossersweiler-Stein",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Münchweiler am Klingbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ramberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rinnthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Silz",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Völkersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldhambach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Waldrohrbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Wernersberg",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76863": [
        {
            "o": "Herxheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Herxheimweyher",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76865": [
        {
            "o": "Insheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rohrbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76870": [
        {
            "o": "Kandel",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76872": [
        {
            "o": "Erlenbach bei Kandel",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Freckenfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hergersweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Minfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Winden",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76877": [
        {
            "o": "Offenbach an der Queich",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76879": [
        {
            "o": "Bornheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Essingen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Hochstadt (Pfalz)",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Knittelsheim",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Ottersheim bei Landau",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76887": [
        {
            "o": "Bad Bergzabern",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Böllenborn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberhausen",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76889": [
        {
            "o": "Barbelroth",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Birkenhördt",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dierbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Dörrenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Gleiszellen-Gleishorbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kapellen-Drusweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Kapsweyer",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Klingenmünster",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederhorbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederotterbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberotterbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Oberschlettenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Pleisweiler-Oberhofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweigen-Rechtenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Schweighofen",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Steinfeld",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Vorderweidenthal",
            "l": "Rheinland-Pfalz"
        }
    ],
    "76891": [
        {
            "o": "Bobenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bruchweiler-Bärenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Bundenthal",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Busenberg",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Erlenbach bei Dahn",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Niederschlettenbach",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Nothweiler",
            "l": "Rheinland-Pfalz"
        },
        {
            "o": "Rumbach",
            "l": "Rheinland-Pfalz"
        }
    ],
    "77652": [
        {
            "o": "Offenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "77654": [
        {
            "o": "Offenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "77656": [
        {
            "o": "Offenburg",
            "l": "Baden-Württemberg"
        }
    ],
    "77694": [
        {
            "o": "Kehl",
            "l": "Baden-Württemberg"
        }
    ],
    "77704": [
        {
            "o": "Oberkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "77709": [
        {
            "o": "Oberwolfach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wolfach",
            "l": "Baden-Württemberg"
        }
    ],
    "77716": [
        {
            "o": "Fischerbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Haslach im Kinzigtal",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hofstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "77723": [
        {
            "o": "Gengenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77728": [
        {
            "o": "Oppenau",
            "l": "Baden-Württemberg"
        }
    ],
    "77731": [
        {
            "o": "Willstätt",
            "l": "Baden-Württemberg"
        }
    ],
    "77736": [
        {
            "o": "Zell am Harmersbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77740": [
        {
            "o": "Bad Peterstal-Griesbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77743": [
        {
            "o": "Neuried",
            "l": "Baden-Württemberg"
        }
    ],
    "77746": [
        {
            "o": "Schutterwald",
            "l": "Baden-Württemberg"
        }
    ],
    "77749": [
        {
            "o": "Hohberg",
            "l": "Baden-Württemberg"
        }
    ],
    "77756": [
        {
            "o": "Hausach",
            "l": "Baden-Württemberg"
        }
    ],
    "77761": [
        {
            "o": "Schiltach",
            "l": "Baden-Württemberg"
        }
    ],
    "77767": [
        {
            "o": "Appenweier",
            "l": "Baden-Württemberg"
        }
    ],
    "77770": [
        {
            "o": "Durbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77773": [
        {
            "o": "Schenkenzell",
            "l": "Baden-Württemberg"
        }
    ],
    "77776": [
        {
            "o": "Bad Rippoldsau-Schapbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77781": [
        {
            "o": "Biberach",
            "l": "Baden-Württemberg"
        }
    ],
    "77784": [
        {
            "o": "Oberharmersbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77787": [
        {
            "o": "Nordrach",
            "l": "Baden-Württemberg"
        }
    ],
    "77790": [
        {
            "o": "Steinach",
            "l": "Baden-Württemberg"
        }
    ],
    "77791": [
        {
            "o": "Berghaupten",
            "l": "Baden-Württemberg"
        }
    ],
    "77793": [
        {
            "o": "Gutach (Schwarzwaldbahn)",
            "l": "Baden-Württemberg"
        }
    ],
    "77794": [
        {
            "o": "Lautenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77796": [
        {
            "o": "Mühlenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77797": [
        {
            "o": "Ohlsbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77799": [
        {
            "o": "Ortenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "77815": [
        {
            "o": "Bühl",
            "l": "Baden-Württemberg"
        }
    ],
    "77830": [
        {
            "o": "Bühlertal",
            "l": "Baden-Württemberg"
        }
    ],
    "77833": [
        {
            "o": "Ottersweier",
            "l": "Baden-Württemberg"
        }
    ],
    "77836": [
        {
            "o": "Rheinmünster",
            "l": "Baden-Württemberg"
        }
    ],
    "77839": [
        {
            "o": "Lichtenau",
            "l": "Baden-Württemberg"
        }
    ],
    "77855": [
        {
            "o": "Achern",
            "l": "Baden-Württemberg"
        }
    ],
    "77866": [
        {
            "o": "Rheinau",
            "l": "Baden-Württemberg"
        }
    ],
    "77871": [
        {
            "o": "Renchen",
            "l": "Baden-Württemberg"
        }
    ],
    "77876": [
        {
            "o": "Kappelrodeck",
            "l": "Baden-Württemberg"
        }
    ],
    "77880": [
        {
            "o": "Sasbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77883": [
        {
            "o": "Ottenhöfen im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "77886": [
        {
            "o": "Lauf",
            "l": "Baden-Württemberg"
        }
    ],
    "77887": [
        {
            "o": "Sasbachwalden",
            "l": "Baden-Württemberg"
        }
    ],
    "77889": [
        {
            "o": "Seebach",
            "l": "Baden-Württemberg"
        }
    ],
    "77933": [
        {
            "o": "Lahr/Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "77948": [
        {
            "o": "Friesenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "77955": [
        {
            "o": "Ettenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "77960": [
        {
            "o": "Seelbach",
            "l": "Baden-Württemberg"
        }
    ],
    "77963": [
        {
            "o": "Schwanau",
            "l": "Baden-Württemberg"
        }
    ],
    "77966": [
        {
            "o": "Kappel-Grafenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "77971": [
        {
            "o": "Kippenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "77972": [
        {
            "o": "Mahlberg",
            "l": "Baden-Württemberg"
        }
    ],
    "77974": [
        {
            "o": "Meißenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "77975": [
        {
            "o": "Ringsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "77977": [
        {
            "o": "Rust",
            "l": "Baden-Württemberg"
        }
    ],
    "77978": [
        {
            "o": "Schuttertal",
            "l": "Baden-Württemberg"
        }
    ],
    "78048": [
        {
            "o": "Villingen-Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78050": [
        {
            "o": "Villingen-Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78052": [
        {
            "o": "Villingen-Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78054": [
        {
            "o": "Villingen-Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78056": [
        {
            "o": "Villingen-Schwenningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78073": [
        {
            "o": "Bad Dürrheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78078": [
        {
            "o": "Niedereschach",
            "l": "Baden-Württemberg"
        }
    ],
    "78083": [
        {
            "o": "Dauchingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78086": [
        {
            "o": "Brigachtal",
            "l": "Baden-Württemberg"
        }
    ],
    "78087": [
        {
            "o": "Mönchweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "78089": [
        {
            "o": "Unterkirnach",
            "l": "Baden-Württemberg"
        }
    ],
    "78098": [
        {
            "o": "Triberg im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78112": [
        {
            "o": "St. Georgen im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78120": [
        {
            "o": "Furtwangen im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78126": [
        {
            "o": "Königsfeld im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78132": [
        {
            "o": "Hornberg",
            "l": "Baden-Württemberg"
        }
    ],
    "78136": [
        {
            "o": "Schonach im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78141": [
        {
            "o": "Schönwald im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "78144": [
        {
            "o": "Schramberg",
            "l": "Baden-Württemberg"
        }
    ],
    "78147": [
        {
            "o": "Vöhrenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "78148": [
        {
            "o": "Gütenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "78166": [
        {
            "o": "Donaueschingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78176": [
        {
            "o": "Blumberg",
            "l": "Baden-Württemberg"
        }
    ],
    "78183": [
        {
            "o": "Hüfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78187": [
        {
            "o": "Geisingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78194": [
        {
            "o": "Immendingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78199": [
        {
            "o": "Bräunlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78224": [
        {
            "o": "Singen (Hohentwiel)",
            "l": "Baden-Württemberg"
        }
    ],
    "78234": [
        {
            "o": "Engen",
            "l": "Baden-Württemberg"
        }
    ],
    "78239": [
        {
            "o": "Rielasingen-Worblingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78244": [
        {
            "o": "Gottmadingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78247": [
        {
            "o": "Hilzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78250": [
        {
            "o": "Tengen",
            "l": "Baden-Württemberg"
        }
    ],
    "78253": [
        {
            "o": "Eigeltingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78256": [
        {
            "o": "Steißlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78259": [
        {
            "o": "Mühlhausen-Ehingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78262": [
        {
            "o": "Gailingen am Hochrhein",
            "l": "Baden-Württemberg"
        }
    ],
    "78266": [
        {
            "o": "Büsingen am Hochrhein",
            "l": "Baden-Württemberg"
        }
    ],
    "78267": [
        {
            "o": "Aach",
            "l": "Baden-Württemberg"
        }
    ],
    "78269": [
        {
            "o": "Volkertshausen",
            "l": "Baden-Württemberg"
        }
    ],
    "78315": [
        {
            "o": "Radolfzell",
            "l": "Baden-Württemberg"
        }
    ],
    "78333": [
        {
            "o": "Stockach",
            "l": "Baden-Württemberg"
        }
    ],
    "78337": [
        {
            "o": "Öhningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78343": [
        {
            "o": "Gaienhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "78345": [
        {
            "o": "Moos",
            "l": "Baden-Württemberg"
        }
    ],
    "78351": [
        {
            "o": "Bodman-Ludwigshafen",
            "l": "Baden-Württemberg"
        }
    ],
    "78354": [
        {
            "o": "Sipplingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78355": [
        {
            "o": "Hohenfels",
            "l": "Baden-Württemberg"
        }
    ],
    "78357": [
        {
            "o": "Mühlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78359": [
        {
            "o": "Orsingen-Nenzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78462": [
        {
            "o": "Konstanz",
            "l": "Baden-Württemberg"
        }
    ],
    "78464": [
        {
            "o": "Konstanz",
            "l": "Baden-Württemberg"
        }
    ],
    "78465": [
        {
            "o": "Konstanz",
            "l": "Baden-Württemberg"
        }
    ],
    "78467": [
        {
            "o": "Konstanz",
            "l": "Baden-Württemberg"
        }
    ],
    "78476": [
        {
            "o": "Allensbach",
            "l": "Baden-Württemberg"
        }
    ],
    "78479": [
        {
            "o": "Reichenau",
            "l": "Baden-Württemberg"
        }
    ],
    "78532": [
        {
            "o": "Tuttlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78549": [
        {
            "o": "Spaichingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78554": [
        {
            "o": "Aldingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Denkingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78559": [
        {
            "o": "Gosheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78564": [
        {
            "o": "Reichenbach am Heuberg",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wehingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78567": [
        {
            "o": "Buchheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Fridingen an der Donau",
            "l": "Baden-Württemberg"
        }
    ],
    "78570": [
        {
            "o": "Mühlheim an der Donau",
            "l": "Baden-Württemberg"
        }
    ],
    "78573": [
        {
            "o": "Wurmlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78576": [
        {
            "o": "Emmingen-Liptingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78579": [
        {
            "o": "Neuhausen ob Eck",
            "l": "Baden-Württemberg"
        }
    ],
    "78580": [
        {
            "o": "Bärenthal",
            "l": "Baden-Württemberg"
        }
    ],
    "78582": [
        {
            "o": "Balgheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78583": [
        {
            "o": "Böttingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78585": [
        {
            "o": "Bubsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78586": [
        {
            "o": "Deilingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78588": [
        {
            "o": "Denkingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78589": [
        {
            "o": "Dürbheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78591": [
        {
            "o": "Durchhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "78592": [
        {
            "o": "Egesheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78594": [
        {
            "o": "Gunningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78595": [
        {
            "o": "Hausen ob Verena",
            "l": "Baden-Württemberg"
        }
    ],
    "78597": [
        {
            "o": "Irndorf",
            "l": "Baden-Württemberg"
        }
    ],
    "78598": [
        {
            "o": "Königsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78600": [
        {
            "o": "Kolbingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78601": [
        {
            "o": "Mahlstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "78603": [
        {
            "o": "Renquishausen",
            "l": "Baden-Württemberg"
        }
    ],
    "78604": [
        {
            "o": "Rietheim-Weilheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78606": [
        {
            "o": "Seitingen-Oberflacht",
            "l": "Baden-Württemberg"
        }
    ],
    "78607": [
        {
            "o": "Talheim",
            "l": "Baden-Württemberg"
        }
    ],
    "78609": [
        {
            "o": "Tuningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78628": [
        {
            "o": "Rottweil",
            "l": "Baden-Württemberg"
        }
    ],
    "78647": [
        {
            "o": "Trossingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78652": [
        {
            "o": "Deißlingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Zimmern ob Rottweil",
            "l": "Baden-Württemberg"
        }
    ],
    "78655": [
        {
            "o": "Dunningen",
            "l": "Baden-Württemberg"
        }
    ],
    "78658": [
        {
            "o": "Zimmern ob Rottweil",
            "l": "Baden-Württemberg"
        }
    ],
    "78661": [
        {
            "o": "Dietingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78662": [
        {
            "o": "Bösingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78664": [
        {
            "o": "Eschbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "78665": [
        {
            "o": "Frittlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78667": [
        {
            "o": "Villingendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "78669": [
        {
            "o": "Wellendingen",
            "l": "Baden-Württemberg"
        }
    ],
    "78713": [
        {
            "o": "Dunningen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schramberg",
            "l": "Baden-Württemberg"
        }
    ],
    "78727": [
        {
            "o": "Oberndorf am Neckar",
            "l": "Baden-Württemberg"
        }
    ],
    "78730": [
        {
            "o": "Lauterbach",
            "l": "Baden-Württemberg"
        }
    ],
    "78733": [
        {
            "o": "Aichhalden",
            "l": "Baden-Württemberg"
        }
    ],
    "78736": [
        {
            "o": "Epfendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "78737": [
        {
            "o": "Fluorn-Winzeln",
            "l": "Baden-Württemberg"
        }
    ],
    "78739": [
        {
            "o": "Hardt",
            "l": "Baden-Württemberg"
        }
    ],
    "79098": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79100": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79102": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79104": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79106": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79108": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79110": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79111": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79112": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79114": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79115": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79117": [
        {
            "o": "Freiburg im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79183": [
        {
            "o": "Waldkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "79189": [
        {
            "o": "Bad Krozingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79194": [
        {
            "o": "Gundelfingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Heuweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "79199": [
        {
            "o": "Kirchzarten",
            "l": "Baden-Württemberg"
        }
    ],
    "79206": [
        {
            "o": "Breisach am Rhein",
            "l": "Baden-Württemberg"
        }
    ],
    "79211": [
        {
            "o": "Denzlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79215": [
        {
            "o": "Biederbach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Elzach",
            "l": "Baden-Württemberg"
        }
    ],
    "79219": [
        {
            "o": "Staufen im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79224": [
        {
            "o": "Umkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "79227": [
        {
            "o": "Schallstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "79232": [
        {
            "o": "March",
            "l": "Baden-Württemberg"
        }
    ],
    "79235": [
        {
            "o": "Vogtsburg im Kaiserstuhl",
            "l": "Baden-Württemberg"
        }
    ],
    "79238": [
        {
            "o": "Ehrenkirchen",
            "l": "Baden-Württemberg"
        }
    ],
    "79241": [
        {
            "o": "Ihringen",
            "l": "Baden-Württemberg"
        }
    ],
    "79244": [
        {
            "o": "Münstertal/Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "79249": [
        {
            "o": "Merzhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "79252": [
        {
            "o": "Stegen",
            "l": "Baden-Württemberg"
        }
    ],
    "79254": [
        {
            "o": "Oberried",
            "l": "Baden-Württemberg"
        }
    ],
    "79256": [
        {
            "o": "Buchenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "79258": [
        {
            "o": "Hartheim am Rhein",
            "l": "Baden-Württemberg"
        }
    ],
    "79261": [
        {
            "o": "Gutach im Breisgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79263": [
        {
            "o": "Simonswald",
            "l": "Baden-Württemberg"
        }
    ],
    "79268": [
        {
            "o": "Bötzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79271": [
        {
            "o": "Sankt Peter",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Simonswald",
            "l": "Baden-Württemberg"
        }
    ],
    "79274": [
        {
            "o": "St. Märgen",
            "l": "Baden-Württemberg"
        }
    ],
    "79276": [
        {
            "o": "Reute",
            "l": "Baden-Württemberg"
        }
    ],
    "79279": [
        {
            "o": "Vörstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "79280": [
        {
            "o": "Au",
            "l": "Baden-Württemberg"
        }
    ],
    "79282": [
        {
            "o": "Ballrechten-Dottingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79283": [
        {
            "o": "Bollschweil",
            "l": "Baden-Württemberg"
        }
    ],
    "79285": [
        {
            "o": "Ebringen",
            "l": "Baden-Württemberg"
        }
    ],
    "79286": [
        {
            "o": "Glottertal",
            "l": "Baden-Württemberg"
        }
    ],
    "79288": [
        {
            "o": "Gottenheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79289": [
        {
            "o": "Horben",
            "l": "Baden-Württemberg"
        }
    ],
    "79291": [
        {
            "o": "Merdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79292": [
        {
            "o": "Pfaffenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "79294": [
        {
            "o": "Sölden",
            "l": "Baden-Württemberg"
        }
    ],
    "79295": [
        {
            "o": "Sulzburg",
            "l": "Baden-Württemberg"
        }
    ],
    "79297": [
        {
            "o": "Winden im Elztal",
            "l": "Baden-Württemberg"
        }
    ],
    "79299": [
        {
            "o": "Wittnau",
            "l": "Baden-Württemberg"
        }
    ],
    "79312": [
        {
            "o": "Emmendingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Teningen",
            "l": "Baden-Württemberg"
        }
    ],
    "79331": [
        {
            "o": "Teningen",
            "l": "Baden-Württemberg"
        }
    ],
    "79336": [
        {
            "o": "Herbolzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79341": [
        {
            "o": "Kenzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79346": [
        {
            "o": "Endingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79348": [
        {
            "o": "Freiamt",
            "l": "Baden-Württemberg"
        }
    ],
    "79350": [
        {
            "o": "Sexau",
            "l": "Baden-Württemberg"
        }
    ],
    "79353": [
        {
            "o": "Bahlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79356": [
        {
            "o": "Eichstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "79359": [
        {
            "o": "Riegel",
            "l": "Baden-Württemberg"
        }
    ],
    "79361": [
        {
            "o": "Sasbach am Kaiserstuhl",
            "l": "Baden-Württemberg"
        }
    ],
    "79362": [
        {
            "o": "Forchheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79364": [
        {
            "o": "Malterdingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79365": [
        {
            "o": "Rheinhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "79367": [
        {
            "o": "Weisweil",
            "l": "Baden-Württemberg"
        }
    ],
    "79369": [
        {
            "o": "Wyhl",
            "l": "Baden-Württemberg"
        }
    ],
    "79379": [
        {
            "o": "Müllheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79395": [
        {
            "o": "Neuenburg am Rhein",
            "l": "Baden-Württemberg"
        }
    ],
    "79400": [
        {
            "o": "Kandern",
            "l": "Baden-Württemberg"
        }
    ],
    "79410": [
        {
            "o": "Badenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "79415": [
        {
            "o": "Bad Bellingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79418": [
        {
            "o": "Schliengen",
            "l": "Baden-Württemberg"
        }
    ],
    "79423": [
        {
            "o": "Heitersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79424": [
        {
            "o": "Auggen",
            "l": "Baden-Württemberg"
        }
    ],
    "79426": [
        {
            "o": "Buggingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79427": [
        {
            "o": "Eschbach",
            "l": "Baden-Württemberg"
        }
    ],
    "79429": [
        {
            "o": "Malsburg-Marzell",
            "l": "Baden-Württemberg"
        }
    ],
    "79539": [
        {
            "o": "Lörrach",
            "l": "Baden-Württemberg"
        }
    ],
    "79540": [
        {
            "o": "Lörrach",
            "l": "Baden-Württemberg"
        }
    ],
    "79541": [
        {
            "o": "Lörrach",
            "l": "Baden-Württemberg"
        }
    ],
    "79576": [
        {
            "o": "Weil am Rhein",
            "l": "Baden-Württemberg"
        }
    ],
    "79585": [
        {
            "o": "Steinen",
            "l": "Baden-Württemberg"
        }
    ],
    "79588": [
        {
            "o": "Efringen-Kirchen",
            "l": "Baden-Württemberg"
        }
    ],
    "79589": [
        {
            "o": "Binzen",
            "l": "Baden-Württemberg"
        }
    ],
    "79591": [
        {
            "o": "Eimeldingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79592": [
        {
            "o": "Fischingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79594": [
        {
            "o": "Inzlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79595": [
        {
            "o": "Rümmingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79597": [
        {
            "o": "Schallbach",
            "l": "Baden-Württemberg"
        }
    ],
    "79599": [
        {
            "o": "Wittlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79618": [
        {
            "o": "Rheinfelden (Baden)",
            "l": "Baden-Württemberg"
        }
    ],
    "79639": [
        {
            "o": "Grenzach-Wyhlen",
            "l": "Baden-Württemberg"
        }
    ],
    "79650": [
        {
            "o": "Schopfheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79664": [
        {
            "o": "Wehr",
            "l": "Baden-Württemberg"
        }
    ],
    "79669": [
        {
            "o": "Zell im Wiesental",
            "l": "Baden-Württemberg"
        }
    ],
    "79674": [
        {
            "o": "Todtnau",
            "l": "Baden-Württemberg"
        }
    ],
    "79677": [
        {
            "o": "Aitern",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Böllen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Fröhnd",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schönau im Schwarzwald",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Schönenberg",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Tunau",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wembach",
            "l": "Baden-Württemberg"
        }
    ],
    "79682": [
        {
            "o": "Todtmoos",
            "l": "Baden-Württemberg"
        }
    ],
    "79685": [
        {
            "o": "Häg-Ehrsberg",
            "l": "Baden-Württemberg"
        }
    ],
    "79686": [
        {
            "o": "Hasel",
            "l": "Baden-Württemberg"
        }
    ],
    "79688": [
        {
            "o": "Hausen im Wiesental",
            "l": "Baden-Württemberg"
        }
    ],
    "79689": [
        {
            "o": "Maulburg",
            "l": "Baden-Württemberg"
        }
    ],
    "79692": [
        {
            "o": "Kleines Wiesental",
            "l": "Baden-Württemberg"
        }
    ],
    "79694": [
        {
            "o": "Utzenfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "79695": [
        {
            "o": "Wieden",
            "l": "Baden-Württemberg"
        }
    ],
    "79713": [
        {
            "o": "Bad Säckingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79725": [
        {
            "o": "Laufenburg (Baden)",
            "l": "Baden-Württemberg"
        }
    ],
    "79730": [
        {
            "o": "Murg",
            "l": "Baden-Württemberg"
        }
    ],
    "79733": [
        {
            "o": "Görwihl",
            "l": "Baden-Württemberg"
        }
    ],
    "79736": [
        {
            "o": "Rickenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "79737": [
        {
            "o": "Herrischried",
            "l": "Baden-Württemberg"
        }
    ],
    "79739": [
        {
            "o": "Schwörstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "79761": [
        {
            "o": "Waldshut-Tiengen",
            "l": "Baden-Württemberg"
        }
    ],
    "79771": [
        {
            "o": "Klettgau",
            "l": "Baden-Württemberg"
        }
    ],
    "79774": [
        {
            "o": "Albbruck",
            "l": "Baden-Württemberg"
        }
    ],
    "79777": [
        {
            "o": "Ühlingen-Birkendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "79780": [
        {
            "o": "Stühlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79787": [
        {
            "o": "Lauchringen",
            "l": "Baden-Württemberg"
        }
    ],
    "79790": [
        {
            "o": "Küssaberg",
            "l": "Baden-Württemberg"
        }
    ],
    "79793": [
        {
            "o": "Wutöschingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79798": [
        {
            "o": "Jestetten",
            "l": "Baden-Württemberg"
        }
    ],
    "79801": [
        {
            "o": "Hohentengen am Hochrhein",
            "l": "Baden-Württemberg"
        }
    ],
    "79802": [
        {
            "o": "Dettighofen",
            "l": "Baden-Württemberg"
        }
    ],
    "79804": [
        {
            "o": "Dogern",
            "l": "Baden-Württemberg"
        }
    ],
    "79805": [
        {
            "o": "Eggingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79807": [
        {
            "o": "Lottstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "79809": [
        {
            "o": "Weilheim",
            "l": "Baden-Württemberg"
        }
    ],
    "79822": [
        {
            "o": "Titisee-Neustadt",
            "l": "Baden-Württemberg"
        }
    ],
    "79837": [
        {
            "o": "Dachsberg (Südschwarzwald)",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Häusern",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Ibach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "St. Blasien",
            "l": "Baden-Württemberg"
        }
    ],
    "79843": [
        {
            "o": "Löffingen",
            "l": "Baden-Württemberg"
        }
    ],
    "79848": [
        {
            "o": "Bonndorf im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "79853": [
        {
            "o": "Lenzkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "79856": [
        {
            "o": "Hinterzarten",
            "l": "Baden-Württemberg"
        }
    ],
    "79859": [
        {
            "o": "Schluchsee",
            "l": "Baden-Württemberg"
        }
    ],
    "79862": [
        {
            "o": "Höchenschwand",
            "l": "Baden-Württemberg"
        }
    ],
    "79865": [
        {
            "o": "Grafenhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "79868": [
        {
            "o": "Feldberg",
            "l": "Baden-Württemberg"
        }
    ],
    "79871": [
        {
            "o": "Eisenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "79872": [
        {
            "o": "Bernau im Schwarzwald",
            "l": "Baden-Württemberg"
        }
    ],
    "79874": [
        {
            "o": "Breitnau",
            "l": "Baden-Württemberg"
        }
    ],
    "79875": [
        {
            "o": "Dachsberg (Südschwarzwald)",
            "l": "Baden-Württemberg"
        }
    ],
    "79877": [
        {
            "o": "Friedenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "79879": [
        {
            "o": "Wutach",
            "l": "Baden-Württemberg"
        }
    ],
    "80331": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80333": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80335": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80336": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80337": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80339": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80469": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80538": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80539": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80634": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80636": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80637": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80638": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80639": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80686": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80687": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80689": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80796": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80797": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80798": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80799": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80801": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80802": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80803": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80804": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80805": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80807": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80809": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80933": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80935": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80937": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80939": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80992": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80993": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80995": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80997": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "80999": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81241": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81243": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81245": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81247": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81249": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81369": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81371": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81373": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81375": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81377": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81379": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81475": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81476": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81477": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81479": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81539": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81541": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81543": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81545": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81547": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81549": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81667": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81669": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81671": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81673": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81675": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81677": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81679": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81735": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81737": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81739": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81825": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81827": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81829": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81925": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81927": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "81929": [
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "82008": [
        {
            "o": "Unterhaching",
            "l": "Bayern"
        }
    ],
    "82024": [
        {
            "o": "Taufkirchen",
            "l": "Bayern"
        }
    ],
    "82031": [
        {
            "o": "Grünwald",
            "l": "Bayern"
        }
    ],
    "82041": [
        {
            "o": "Oberhaching",
            "l": "Bayern"
        }
    ],
    "82049": [
        {
            "o": "Pullach im Isartal",
            "l": "Bayern"
        }
    ],
    "82054": [
        {
            "o": "Sauerlach",
            "l": "Bayern"
        }
    ],
    "82057": [
        {
            "o": "Icking",
            "l": "Bayern"
        }
    ],
    "82061": [
        {
            "o": "Neuried",
            "l": "Bayern"
        }
    ],
    "82064": [
        {
            "o": "Grünwald",
            "l": "Bayern"
        },
        {
            "o": "Oberhaching",
            "l": "Bayern"
        },
        {
            "o": "Straßlach-Dingharting",
            "l": "Bayern"
        }
    ],
    "82065": [
        {
            "o": "Baierbrunn",
            "l": "Bayern"
        }
    ],
    "82067": [
        {
            "o": "Schäftlarn",
            "l": "Bayern"
        }
    ],
    "82069": [
        {
            "o": "Schäftlarn",
            "l": "Bayern"
        }
    ],
    "82110": [
        {
            "o": "Germering",
            "l": "Bayern"
        }
    ],
    "82131": [
        {
            "o": "Gauting",
            "l": "Bayern"
        },
        {
            "o": "Neuried",
            "l": "Bayern"
        }
    ],
    "82140": [
        {
            "o": "Olching",
            "l": "Bayern"
        }
    ],
    "82152": [
        {
            "o": "Krailling",
            "l": "Bayern"
        },
        {
            "o": "Planegg",
            "l": "Bayern"
        }
    ],
    "82166": [
        {
            "o": "Gräfelfing",
            "l": "Bayern"
        }
    ],
    "82178": [
        {
            "o": "Puchheim",
            "l": "Bayern"
        }
    ],
    "82194": [
        {
            "o": "Gröbenzell",
            "l": "Bayern"
        }
    ],
    "82205": [
        {
            "o": "Gilching",
            "l": "Bayern"
        }
    ],
    "82211": [
        {
            "o": "Herrsching am Ammersee",
            "l": "Bayern"
        }
    ],
    "82216": [
        {
            "o": "Maisach",
            "l": "Bayern"
        }
    ],
    "82223": [
        {
            "o": "Eichenau",
            "l": "Bayern"
        }
    ],
    "82229": [
        {
            "o": "Seefeld",
            "l": "Bayern"
        }
    ],
    "82234": [
        {
            "o": "Weßling",
            "l": "Bayern"
        }
    ],
    "82237": [
        {
            "o": "Wörthsee",
            "l": "Bayern"
        }
    ],
    "82239": [
        {
            "o": "Alling",
            "l": "Bayern"
        }
    ],
    "82256": [
        {
            "o": "Fürstenfeldbruck",
            "l": "Bayern"
        }
    ],
    "82266": [
        {
            "o": "Inning am Ammersee",
            "l": "Bayern"
        }
    ],
    "82269": [
        {
            "o": "Geltendorf",
            "l": "Bayern"
        }
    ],
    "82272": [
        {
            "o": "Moorenweis",
            "l": "Bayern"
        }
    ],
    "82275": [
        {
            "o": "Emmering",
            "l": "Bayern"
        }
    ],
    "82276": [
        {
            "o": "Adelshofen",
            "l": "Bayern"
        }
    ],
    "82278": [
        {
            "o": "Althegnenberg",
            "l": "Bayern"
        }
    ],
    "82279": [
        {
            "o": "Eching am Ammersee",
            "l": "Bayern"
        }
    ],
    "82281": [
        {
            "o": "Egenhofen",
            "l": "Bayern"
        }
    ],
    "82284": [
        {
            "o": "Grafrath",
            "l": "Bayern"
        }
    ],
    "82285": [
        {
            "o": "Hattenhofen",
            "l": "Bayern"
        }
    ],
    "82287": [
        {
            "o": "Jesenwang",
            "l": "Bayern"
        }
    ],
    "82288": [
        {
            "o": "Kottgeisering",
            "l": "Bayern"
        }
    ],
    "82290": [
        {
            "o": "Landsberied",
            "l": "Bayern"
        }
    ],
    "82291": [
        {
            "o": "Mammendorf",
            "l": "Bayern"
        }
    ],
    "82293": [
        {
            "o": "Mittelstetten",
            "l": "Bayern"
        }
    ],
    "82294": [
        {
            "o": "Oberschweinbach",
            "l": "Bayern"
        }
    ],
    "82296": [
        {
            "o": "Schöngeising",
            "l": "Bayern"
        }
    ],
    "82297": [
        {
            "o": "Steindorf",
            "l": "Bayern"
        }
    ],
    "82299": [
        {
            "o": "Türkenfeld",
            "l": "Bayern"
        }
    ],
    "82319": [
        {
            "o": "Starnberg",
            "l": "Bayern"
        }
    ],
    "82327": [
        {
            "o": "Tutzing",
            "l": "Bayern"
        }
    ],
    "82335": [
        {
            "o": "Berg",
            "l": "Bayern"
        }
    ],
    "82340": [
        {
            "o": "Feldafing",
            "l": "Bayern"
        }
    ],
    "82343": [
        {
            "o": "Pöcking",
            "l": "Bayern"
        }
    ],
    "82346": [
        {
            "o": "Andechs",
            "l": "Bayern"
        }
    ],
    "82347": [
        {
            "o": "Bernried am Starnberger See",
            "l": "Bayern"
        }
    ],
    "82349": [
        {
            "o": "Krailling",
            "l": "Bayern"
        }
    ],
    "82362": [
        {
            "o": "Weilheim in Oberbayern",
            "l": "Bayern"
        }
    ],
    "82377": [
        {
            "o": "Penzberg",
            "l": "Bayern"
        }
    ],
    "82380": [
        {
            "o": "Peißenberg",
            "l": "Bayern"
        }
    ],
    "82383": [
        {
            "o": "Hohenpeißenberg",
            "l": "Bayern"
        }
    ],
    "82386": [
        {
            "o": "Huglfing",
            "l": "Bayern"
        },
        {
            "o": "Oberhausen",
            "l": "Bayern"
        }
    ],
    "82387": [
        {
            "o": "Antdorf",
            "l": "Bayern"
        }
    ],
    "82389": [
        {
            "o": "Böbing",
            "l": "Bayern"
        }
    ],
    "82390": [
        {
            "o": "Eberfing",
            "l": "Bayern"
        }
    ],
    "82392": [
        {
            "o": "Habach",
            "l": "Bayern"
        }
    ],
    "82393": [
        {
            "o": "Iffeldorf",
            "l": "Bayern"
        }
    ],
    "82395": [
        {
            "o": "Obersöchering",
            "l": "Bayern"
        }
    ],
    "82396": [
        {
            "o": "Pähl",
            "l": "Bayern"
        }
    ],
    "82398": [
        {
            "o": "Polling",
            "l": "Bayern"
        }
    ],
    "82399": [
        {
            "o": "Raisting",
            "l": "Bayern"
        }
    ],
    "82401": [
        {
            "o": "Rottenbuch",
            "l": "Bayern"
        }
    ],
    "82402": [
        {
            "o": "Seeshaupt",
            "l": "Bayern"
        }
    ],
    "82404": [
        {
            "o": "Sindelsdorf",
            "l": "Bayern"
        }
    ],
    "82405": [
        {
            "o": "Wessobrunn",
            "l": "Bayern"
        }
    ],
    "82407": [
        {
            "o": "Wielenbach",
            "l": "Bayern"
        }
    ],
    "82409": [
        {
            "o": "Wildsteig",
            "l": "Bayern"
        }
    ],
    "82418": [
        {
            "o": "Murnau am Staffelsee",
            "l": "Bayern"
        },
        {
            "o": "Riegsee",
            "l": "Bayern"
        },
        {
            "o": "Seehausen am Staffelsee",
            "l": "Bayern"
        },
        {
            "o": "Spatzenhausen",
            "l": "Bayern"
        }
    ],
    "82431": [
        {
            "o": "Kochel am See",
            "l": "Bayern"
        }
    ],
    "82432": [
        {
            "o": "Kochel am See",
            "l": "Bayern"
        }
    ],
    "82433": [
        {
            "o": "Bad Kohlgrub",
            "l": "Bayern"
        }
    ],
    "82435": [
        {
            "o": "Bad Bayersoien",
            "l": "Bayern"
        }
    ],
    "82436": [
        {
            "o": "Eglfing",
            "l": "Bayern"
        }
    ],
    "82438": [
        {
            "o": "Eschenlohe",
            "l": "Bayern"
        }
    ],
    "82439": [
        {
            "o": "Großweil",
            "l": "Bayern"
        },
        {
            "o": "Schlehdorf",
            "l": "Bayern"
        }
    ],
    "82441": [
        {
            "o": "Ohlstadt",
            "l": "Bayern"
        }
    ],
    "82442": [
        {
            "o": "Saulgrub",
            "l": "Bayern"
        }
    ],
    "82444": [
        {
            "o": "Schlehdorf",
            "l": "Bayern"
        }
    ],
    "82445": [
        {
            "o": "Schwaigen",
            "l": "Bayern"
        }
    ],
    "82447": [
        {
            "o": "Spatzenhausen",
            "l": "Bayern"
        }
    ],
    "82449": [
        {
            "o": "Uffing am Staffelsee",
            "l": "Bayern"
        }
    ],
    "82467": [
        {
            "o": "Garmisch-Partenkirchen",
            "l": "Bayern"
        },
        {
            "o": "Krün",
            "l": "Bayern"
        }
    ],
    "82475": [
        {
            "o": "Garmisch-Partenkirchen",
            "l": "Bayern"
        }
    ],
    "82481": [
        {
            "o": "Mittenwald",
            "l": "Bayern"
        }
    ],
    "82487": [
        {
            "o": "Oberammergau",
            "l": "Bayern"
        }
    ],
    "82488": [
        {
            "o": "Ettal",
            "l": "Bayern"
        }
    ],
    "82490": [
        {
            "o": "Farchant",
            "l": "Bayern"
        }
    ],
    "82491": [
        {
            "o": "Grainau",
            "l": "Bayern"
        }
    ],
    "82493": [
        {
            "o": "Krün",
            "l": "Bayern"
        }
    ],
    "82494": [
        {
            "o": "Krün",
            "l": "Bayern"
        }
    ],
    "82496": [
        {
            "o": "Oberau",
            "l": "Bayern"
        }
    ],
    "82497": [
        {
            "o": "Unterammergau",
            "l": "Bayern"
        }
    ],
    "82499": [
        {
            "o": "Wallgau",
            "l": "Bayern"
        }
    ],
    "82515": [
        {
            "o": "Wolfratshausen",
            "l": "Bayern"
        }
    ],
    "82538": [
        {
            "o": "Geretsried",
            "l": "Bayern"
        }
    ],
    "82541": [
        {
            "o": "Münsing",
            "l": "Bayern"
        }
    ],
    "82544": [
        {
            "o": "Egling",
            "l": "Bayern"
        }
    ],
    "82547": [
        {
            "o": "Eurasburg",
            "l": "Bayern"
        }
    ],
    "82549": [
        {
            "o": "Königsdorf",
            "l": "Bayern"
        }
    ],
    "83022": [
        {
            "o": "Rosenheim",
            "l": "Bayern"
        }
    ],
    "83024": [
        {
            "o": "Rosenheim",
            "l": "Bayern"
        }
    ],
    "83026": [
        {
            "o": "Rosenheim",
            "l": "Bayern"
        }
    ],
    "83043": [
        {
            "o": "Bad Aibling",
            "l": "Bayern"
        }
    ],
    "83052": [
        {
            "o": "Bruckmühl",
            "l": "Bayern"
        }
    ],
    "83059": [
        {
            "o": "Kolbermoor",
            "l": "Bayern"
        }
    ],
    "83064": [
        {
            "o": "Raubling",
            "l": "Bayern"
        }
    ],
    "83071": [
        {
            "o": "Stephanskirchen",
            "l": "Bayern"
        }
    ],
    "83075": [
        {
            "o": "Bad Feilnbach",
            "l": "Bayern"
        }
    ],
    "83080": [
        {
            "o": "Oberaudorf",
            "l": "Bayern"
        }
    ],
    "83083": [
        {
            "o": "Riedering",
            "l": "Bayern"
        }
    ],
    "83088": [
        {
            "o": "Kiefersfelden",
            "l": "Bayern"
        }
    ],
    "83093": [
        {
            "o": "Bad Endorf",
            "l": "Bayern"
        }
    ],
    "83098": [
        {
            "o": "Brannenburg",
            "l": "Bayern"
        }
    ],
    "83101": [
        {
            "o": "Rohrdorf",
            "l": "Bayern"
        }
    ],
    "83104": [
        {
            "o": "Tuntenhausen",
            "l": "Bayern"
        }
    ],
    "83109": [
        {
            "o": "Großkarolinenfeld",
            "l": "Bayern"
        }
    ],
    "83112": [
        {
            "o": "Frasdorf",
            "l": "Bayern"
        }
    ],
    "83115": [
        {
            "o": "Neubeuern",
            "l": "Bayern"
        }
    ],
    "83119": [
        {
            "o": "Obing",
            "l": "Bayern"
        }
    ],
    "83122": [
        {
            "o": "Samerberg",
            "l": "Bayern"
        }
    ],
    "83123": [
        {
            "o": "Amerang",
            "l": "Bayern"
        }
    ],
    "83125": [
        {
            "o": "Eggstätt",
            "l": "Bayern"
        }
    ],
    "83126": [
        {
            "o": "Flintsbach am Inn",
            "l": "Bayern"
        }
    ],
    "83128": [
        {
            "o": "Halfing",
            "l": "Bayern"
        }
    ],
    "83129": [
        {
            "o": "Höslwang",
            "l": "Bayern"
        }
    ],
    "83131": [
        {
            "o": "Nußdorf am Inn",
            "l": "Bayern"
        }
    ],
    "83132": [
        {
            "o": "Pittenhart",
            "l": "Bayern"
        }
    ],
    "83134": [
        {
            "o": "Prutting",
            "l": "Bayern"
        }
    ],
    "83135": [
        {
            "o": "Schechen",
            "l": "Bayern"
        }
    ],
    "83137": [
        {
            "o": "Schonstett",
            "l": "Bayern"
        }
    ],
    "83139": [
        {
            "o": "Söchtenau",
            "l": "Bayern"
        }
    ],
    "83209": [
        {
            "o": "Chiemsee",
            "l": "Bayern"
        },
        {
            "o": "Prien am Chiemsee",
            "l": "Bayern"
        }
    ],
    "83224": [
        {
            "o": "Grassau",
            "l": "Bayern"
        },
        {
            "o": "Staudach-Egerndach",
            "l": "Bayern"
        }
    ],
    "83229": [
        {
            "o": "Aschau im Chiemgau",
            "l": "Bayern"
        }
    ],
    "83233": [
        {
            "o": "Bernau am Chiemsee",
            "l": "Bayern"
        }
    ],
    "83236": [
        {
            "o": "Übersee",
            "l": "Bayern"
        }
    ],
    "83242": [
        {
            "o": "Reit im Winkl",
            "l": "Bayern"
        }
    ],
    "83246": [
        {
            "o": "Unterwössen",
            "l": "Bayern"
        }
    ],
    "83250": [
        {
            "o": "Marquartstein",
            "l": "Bayern"
        }
    ],
    "83253": [
        {
            "o": "Rimsting",
            "l": "Bayern"
        }
    ],
    "83254": [
        {
            "o": "Breitbrunn am Chiemsee",
            "l": "Bayern"
        }
    ],
    "83256": [
        {
            "o": "Chiemsee",
            "l": "Bayern"
        }
    ],
    "83257": [
        {
            "o": "Gstadt am Chiemsee",
            "l": "Bayern"
        }
    ],
    "83259": [
        {
            "o": "Schleching",
            "l": "Bayern"
        }
    ],
    "83278": [
        {
            "o": "Traunstein",
            "l": "Bayern"
        }
    ],
    "83301": [
        {
            "o": "Traunreut",
            "l": "Bayern"
        }
    ],
    "83308": [
        {
            "o": "Trostberg",
            "l": "Bayern"
        }
    ],
    "83313": [
        {
            "o": "Siegsdorf",
            "l": "Bayern"
        }
    ],
    "83317": [
        {
            "o": "Teisendorf",
            "l": "Bayern"
        }
    ],
    "83324": [
        {
            "o": "Ruhpolding",
            "l": "Bayern"
        }
    ],
    "83329": [
        {
            "o": "Kirchanschöring",
            "l": "Bayern"
        },
        {
            "o": "Waging am See",
            "l": "Bayern"
        }
    ],
    "83334": [
        {
            "o": "Inzell",
            "l": "Bayern"
        }
    ],
    "83339": [
        {
            "o": "Chieming",
            "l": "Bayern"
        }
    ],
    "83342": [
        {
            "o": "Tacherting",
            "l": "Bayern"
        }
    ],
    "83346": [
        {
            "o": "Bergen",
            "l": "Bayern"
        }
    ],
    "83349": [
        {
            "o": "Palling",
            "l": "Bayern"
        }
    ],
    "83352": [
        {
            "o": "Altenmarkt an der Alz",
            "l": "Bayern"
        }
    ],
    "83355": [
        {
            "o": "Grabenstätt",
            "l": "Bayern"
        }
    ],
    "83358": [
        {
            "o": "Seeon-Seebruck",
            "l": "Bayern"
        }
    ],
    "83361": [
        {
            "o": "Kienberg",
            "l": "Bayern"
        }
    ],
    "83362": [
        {
            "o": "Surberg",
            "l": "Bayern"
        }
    ],
    "83364": [
        {
            "o": "Teisendorf",
            "l": "Bayern"
        }
    ],
    "83365": [
        {
            "o": "Nußdorf",
            "l": "Bayern"
        }
    ],
    "83367": [
        {
            "o": "Petting",
            "l": "Bayern"
        }
    ],
    "83368": [
        {
            "o": "Traunreut",
            "l": "Bayern"
        }
    ],
    "83370": [
        {
            "o": "Seeon-Seebruck",
            "l": "Bayern"
        }
    ],
    "83371": [
        {
            "o": "Traunreut",
            "l": "Bayern"
        }
    ],
    "83373": [
        {
            "o": "Taching am See",
            "l": "Bayern"
        }
    ],
    "83374": [
        {
            "o": "Traunreut",
            "l": "Bayern"
        }
    ],
    "83376": [
        {
            "o": "Seeon-Seebruck",
            "l": "Bayern"
        }
    ],
    "83377": [
        {
            "o": "Vachendorf",
            "l": "Bayern"
        }
    ],
    "83379": [
        {
            "o": "Wonneberg",
            "l": "Bayern"
        }
    ],
    "83395": [
        {
            "o": "Freilassing",
            "l": "Bayern"
        }
    ],
    "83404": [
        {
            "o": "Ainring",
            "l": "Bayern"
        }
    ],
    "83410": [
        {
            "o": "Laufen",
            "l": "Bayern"
        }
    ],
    "83413": [
        {
            "o": "Fridolfing",
            "l": "Bayern"
        }
    ],
    "83416": [
        {
            "o": "Saaldorf-Surheim",
            "l": "Bayern"
        }
    ],
    "83417": [
        {
            "o": "Kirchanschöring",
            "l": "Bayern"
        }
    ],
    "83435": [
        {
            "o": "Bad Reichenhall",
            "l": "Bayern"
        }
    ],
    "83451": [
        {
            "o": "Piding",
            "l": "Bayern"
        }
    ],
    "83454": [
        {
            "o": "Anger",
            "l": "Bayern"
        }
    ],
    "83457": [
        {
            "o": "Bayerisch Gmain",
            "l": "Bayern"
        }
    ],
    "83458": [
        {
            "o": "Schneizlreuth",
            "l": "Bayern"
        }
    ],
    "83471": [
        {
            "o": "Berchtesgaden",
            "l": "Bayern"
        },
        {
            "o": "Schönau am Königssee",
            "l": "Bayern"
        }
    ],
    "83483": [
        {
            "o": "Bischofswiesen",
            "l": "Bayern"
        }
    ],
    "83486": [
        {
            "o": "Ramsau bei Berchtesgaden",
            "l": "Bayern"
        }
    ],
    "83487": [
        {
            "o": "Marktschellenberg",
            "l": "Bayern"
        }
    ],
    "83512": [
        {
            "o": "Wasserburg am Inn",
            "l": "Bayern"
        }
    ],
    "83527": [
        {
            "o": "Haag in Oberbayern",
            "l": "Bayern"
        },
        {
            "o": "Kirchdorf",
            "l": "Bayern"
        }
    ],
    "83530": [
        {
            "o": "Schnaitsee",
            "l": "Bayern"
        }
    ],
    "83533": [
        {
            "o": "Edling",
            "l": "Bayern"
        }
    ],
    "83536": [
        {
            "o": "Gars am Inn",
            "l": "Bayern"
        }
    ],
    "83539": [
        {
            "o": "Pfaffing",
            "l": "Bayern"
        }
    ],
    "83543": [
        {
            "o": "Rott am Inn",
            "l": "Bayern"
        }
    ],
    "83544": [
        {
            "o": "Albaching",
            "l": "Bayern"
        }
    ],
    "83546": [
        {
            "o": "Gars am Inn",
            "l": "Bayern"
        }
    ],
    "83547": [
        {
            "o": "Babensham",
            "l": "Bayern"
        }
    ],
    "83549": [
        {
            "o": "Eiselfing",
            "l": "Bayern"
        }
    ],
    "83550": [
        {
            "o": "Emmering",
            "l": "Bayern"
        }
    ],
    "83553": [
        {
            "o": "Frauenneuharting",
            "l": "Bayern"
        }
    ],
    "83555": [
        {
            "o": "Gars am Inn",
            "l": "Bayern"
        }
    ],
    "83556": [
        {
            "o": "Griesstätt",
            "l": "Bayern"
        }
    ],
    "83558": [
        {
            "o": "Maitenbeth",
            "l": "Bayern"
        }
    ],
    "83559": [
        {
            "o": "Gars am Inn",
            "l": "Bayern"
        }
    ],
    "83561": [
        {
            "o": "Ramerberg",
            "l": "Bayern"
        }
    ],
    "83562": [
        {
            "o": "Rechtmehring",
            "l": "Bayern"
        }
    ],
    "83564": [
        {
            "o": "Soyen",
            "l": "Bayern"
        }
    ],
    "83567": [
        {
            "o": "Unterreit",
            "l": "Bayern"
        }
    ],
    "83569": [
        {
            "o": "Vogtareuth",
            "l": "Bayern"
        }
    ],
    "83607": [
        {
            "o": "Holzkirchen",
            "l": "Bayern"
        }
    ],
    "83620": [
        {
            "o": "Feldkirchen-Westerham",
            "l": "Bayern"
        }
    ],
    "83623": [
        {
            "o": "Dietramszell",
            "l": "Bayern"
        }
    ],
    "83624": [
        {
            "o": "Otterfing",
            "l": "Bayern"
        }
    ],
    "83626": [
        {
            "o": "Valley",
            "l": "Bayern"
        }
    ],
    "83627": [
        {
            "o": "Warngau",
            "l": "Bayern"
        }
    ],
    "83629": [
        {
            "o": "Weyarn",
            "l": "Bayern"
        }
    ],
    "83646": [
        {
            "o": "Bad Tölz",
            "l": "Bayern"
        },
        {
            "o": "Wackersberg",
            "l": "Bayern"
        }
    ],
    "83661": [
        {
            "o": "Lenggries",
            "l": "Bayern"
        }
    ],
    "83666": [
        {
            "o": "Waakirchen",
            "l": "Bayern"
        }
    ],
    "83670": [
        {
            "o": "Bad Heilbrunn",
            "l": "Bayern"
        }
    ],
    "83671": [
        {
            "o": "Benediktbeuern",
            "l": "Bayern"
        }
    ],
    "83673": [
        {
            "o": "Bichl",
            "l": "Bayern"
        }
    ],
    "83674": [
        {
            "o": "Gaißach",
            "l": "Bayern"
        }
    ],
    "83676": [
        {
            "o": "Jachenau",
            "l": "Bayern"
        }
    ],
    "83677": [
        {
            "o": "Greiling",
            "l": "Bayern"
        },
        {
            "o": "Reichersbeuern",
            "l": "Bayern"
        }
    ],
    "83679": [
        {
            "o": "Sachsenkam",
            "l": "Bayern"
        }
    ],
    "83684": [
        {
            "o": "Tegernsee",
            "l": "Bayern"
        }
    ],
    "83700": [
        {
            "o": "Rottach-Egern",
            "l": "Bayern"
        }
    ],
    "83703": [
        {
            "o": "Gmund",
            "l": "Bayern"
        }
    ],
    "83707": [
        {
            "o": "Bad Wiessee",
            "l": "Bayern"
        }
    ],
    "83708": [
        {
            "o": "Kreuth",
            "l": "Bayern"
        }
    ],
    "83714": [
        {
            "o": "Miesbach",
            "l": "Bayern"
        }
    ],
    "83727": [
        {
            "o": "Schliersee",
            "l": "Bayern"
        }
    ],
    "83730": [
        {
            "o": "Fischbachau",
            "l": "Bayern"
        }
    ],
    "83734": [
        {
            "o": "Hausham",
            "l": "Bayern"
        }
    ],
    "83735": [
        {
            "o": "Bayrischzell",
            "l": "Bayern"
        }
    ],
    "83737": [
        {
            "o": "Irschenberg",
            "l": "Bayern"
        }
    ],
    "84028": [
        {
            "o": "Landshut",
            "l": "Bayern"
        }
    ],
    "84030": [
        {
            "o": "Ergolding",
            "l": "Bayern"
        },
        {
            "o": "Landshut",
            "l": "Bayern"
        }
    ],
    "84032": [
        {
            "o": "Altdorf",
            "l": "Bayern"
        },
        {
            "o": "Landshut",
            "l": "Bayern"
        }
    ],
    "84034": [
        {
            "o": "Landshut",
            "l": "Bayern"
        }
    ],
    "84036": [
        {
            "o": "Kumhausen",
            "l": "Bayern"
        },
        {
            "o": "Landshut",
            "l": "Bayern"
        }
    ],
    "84048": [
        {
            "o": "Mainburg",
            "l": "Bayern"
        }
    ],
    "84051": [
        {
            "o": "Essenbach",
            "l": "Bayern"
        }
    ],
    "84056": [
        {
            "o": "Rottenburg a. d. Laaber",
            "l": "Bayern"
        }
    ],
    "84061": [
        {
            "o": "Ergoldsbach",
            "l": "Bayern"
        }
    ],
    "84066": [
        {
            "o": "Mallersdorf-Pfaffenberg",
            "l": "Bayern"
        }
    ],
    "84069": [
        {
            "o": "Schierling",
            "l": "Bayern"
        }
    ],
    "84072": [
        {
            "o": "Au in der Hallertau",
            "l": "Bayern"
        }
    ],
    "84076": [
        {
            "o": "Pfeffenhausen",
            "l": "Bayern"
        }
    ],
    "84079": [
        {
            "o": "Bruckberg",
            "l": "Bayern"
        }
    ],
    "84082": [
        {
            "o": "Laberweinting",
            "l": "Bayern"
        }
    ],
    "84085": [
        {
            "o": "Langquaid",
            "l": "Bayern"
        }
    ],
    "84088": [
        {
            "o": "Neufahrn in Niederbayern",
            "l": "Bayern"
        }
    ],
    "84089": [
        {
            "o": "Aiglsbach",
            "l": "Bayern"
        }
    ],
    "84091": [
        {
            "o": "Attenhofen",
            "l": "Bayern"
        }
    ],
    "84092": [
        {
            "o": "Bayerbach b. Ergoldsbach",
            "l": "Bayern"
        }
    ],
    "84094": [
        {
            "o": "Elsendorf",
            "l": "Bayern"
        }
    ],
    "84095": [
        {
            "o": "Furth",
            "l": "Bayern"
        }
    ],
    "84097": [
        {
            "o": "Herrngiersdorf",
            "l": "Bayern"
        }
    ],
    "84098": [
        {
            "o": "Hohenthann",
            "l": "Bayern"
        }
    ],
    "84100": [
        {
            "o": "Niederaichbach",
            "l": "Bayern"
        }
    ],
    "84101": [
        {
            "o": "Obersüßbach",
            "l": "Bayern"
        }
    ],
    "84103": [
        {
            "o": "Postau",
            "l": "Bayern"
        }
    ],
    "84104": [
        {
            "o": "Rudelzhausen",
            "l": "Bayern"
        }
    ],
    "84106": [
        {
            "o": "Volkenschwand",
            "l": "Bayern"
        }
    ],
    "84107": [
        {
            "o": "Weihmichl",
            "l": "Bayern"
        }
    ],
    "84109": [
        {
            "o": "Wörth a.d. Isar",
            "l": "Bayern"
        }
    ],
    "84130": [
        {
            "o": "Dingolfing",
            "l": "Bayern"
        }
    ],
    "84137": [
        {
            "o": "Vilsbiburg",
            "l": "Bayern"
        }
    ],
    "84140": [
        {
            "o": "Gangkofen",
            "l": "Bayern"
        }
    ],
    "84144": [
        {
            "o": "Geisenhausen",
            "l": "Bayern"
        }
    ],
    "84149": [
        {
            "o": "Velden",
            "l": "Bayern"
        }
    ],
    "84152": [
        {
            "o": "Mengkofen",
            "l": "Bayern"
        }
    ],
    "84155": [
        {
            "o": "Bodenkirchen",
            "l": "Bayern"
        }
    ],
    "84160": [
        {
            "o": "Frontenhausen",
            "l": "Bayern"
        }
    ],
    "84163": [
        {
            "o": "Marklkofen",
            "l": "Bayern"
        }
    ],
    "84164": [
        {
            "o": "Moosthenning",
            "l": "Bayern"
        }
    ],
    "84166": [
        {
            "o": "Adlkofen",
            "l": "Bayern"
        }
    ],
    "84168": [
        {
            "o": "Aham",
            "l": "Bayern"
        }
    ],
    "84169": [
        {
            "o": "Altfraunhofen",
            "l": "Bayern"
        }
    ],
    "84171": [
        {
            "o": "Baierbach",
            "l": "Bayern"
        }
    ],
    "84172": [
        {
            "o": "Buch am Erlbach",
            "l": "Bayern"
        }
    ],
    "84174": [
        {
            "o": "Eching",
            "l": "Bayern"
        }
    ],
    "84175": [
        {
            "o": "Gerzen",
            "l": "Bayern"
        },
        {
            "o": "Schalkham",
            "l": "Bayern"
        }
    ],
    "84177": [
        {
            "o": "Gottfrieding",
            "l": "Bayern"
        }
    ],
    "84178": [
        {
            "o": "Kröning",
            "l": "Bayern"
        }
    ],
    "84180": [
        {
            "o": "Loiching",
            "l": "Bayern"
        }
    ],
    "84181": [
        {
            "o": "Neufraunhofen",
            "l": "Bayern"
        }
    ],
    "84183": [
        {
            "o": "Niederviehbach",
            "l": "Bayern"
        }
    ],
    "84184": [
        {
            "o": "Tiefenbach",
            "l": "Bayern"
        }
    ],
    "84186": [
        {
            "o": "Vilsheim",
            "l": "Bayern"
        }
    ],
    "84187": [
        {
            "o": "Weng",
            "l": "Bayern"
        }
    ],
    "84189": [
        {
            "o": "Wurmsham",
            "l": "Bayern"
        }
    ],
    "84307": [
        {
            "o": "Eggenfelden",
            "l": "Bayern"
        }
    ],
    "84323": [
        {
            "o": "Massing",
            "l": "Bayern"
        }
    ],
    "84326": [
        {
            "o": "Falkenberg",
            "l": "Bayern"
        },
        {
            "o": "Rimbach",
            "l": "Bayern"
        }
    ],
    "84329": [
        {
            "o": "Wurmannsquick",
            "l": "Bayern"
        }
    ],
    "84332": [
        {
            "o": "Hebertsfelden",
            "l": "Bayern"
        }
    ],
    "84333": [
        {
            "o": "Malgersdorf",
            "l": "Bayern"
        }
    ],
    "84335": [
        {
            "o": "Mitterskirchen",
            "l": "Bayern"
        }
    ],
    "84337": [
        {
            "o": "Schönau",
            "l": "Bayern"
        }
    ],
    "84339": [
        {
            "o": "Unterdietfurt",
            "l": "Bayern"
        }
    ],
    "84347": [
        {
            "o": "Pfarrkirchen",
            "l": "Bayern"
        }
    ],
    "84359": [
        {
            "o": "Simbach",
            "l": "Bayern"
        }
    ],
    "84364": [
        {
            "o": "Bad Birnbach",
            "l": "Bayern"
        }
    ],
    "84367": [
        {
            "o": "Reut",
            "l": "Bayern"
        },
        {
            "o": "Tann",
            "l": "Bayern"
        },
        {
            "o": "Zeilarn",
            "l": "Bayern"
        }
    ],
    "84371": [
        {
            "o": "Triftern",
            "l": "Bayern"
        }
    ],
    "84375": [
        {
            "o": "Kirchdorf a.Inn",
            "l": "Bayern"
        }
    ],
    "84378": [
        {
            "o": "Dietersburg",
            "l": "Bayern"
        }
    ],
    "84381": [
        {
            "o": "Johanniskirchen",
            "l": "Bayern"
        }
    ],
    "84384": [
        {
            "o": "Wittibreut",
            "l": "Bayern"
        }
    ],
    "84385": [
        {
            "o": "Egglham",
            "l": "Bayern"
        }
    ],
    "84387": [
        {
            "o": "Julbach",
            "l": "Bayern"
        }
    ],
    "84389": [
        {
            "o": "Postmünster",
            "l": "Bayern"
        }
    ],
    "84405": [
        {
            "o": "Dorfen",
            "l": "Bayern"
        }
    ],
    "84416": [
        {
            "o": "Inning am Holz",
            "l": "Bayern"
        },
        {
            "o": "Taufkirchen",
            "l": "Bayern"
        }
    ],
    "84419": [
        {
            "o": "Obertaufkirchen",
            "l": "Bayern"
        },
        {
            "o": "Schwindegg",
            "l": "Bayern"
        }
    ],
    "84424": [
        {
            "o": "Isen",
            "l": "Bayern"
        }
    ],
    "84427": [
        {
            "o": "Sankt Wolfgang",
            "l": "Bayern"
        }
    ],
    "84428": [
        {
            "o": "Buchbach",
            "l": "Bayern"
        }
    ],
    "84431": [
        {
            "o": "Heldenstein",
            "l": "Bayern"
        },
        {
            "o": "Rattenkirchen",
            "l": "Bayern"
        }
    ],
    "84432": [
        {
            "o": "Hohenpolding",
            "l": "Bayern"
        }
    ],
    "84434": [
        {
            "o": "Kirchberg",
            "l": "Bayern"
        }
    ],
    "84435": [
        {
            "o": "Lengdorf",
            "l": "Bayern"
        }
    ],
    "84437": [
        {
            "o": "Reichertsheim",
            "l": "Bayern"
        }
    ],
    "84439": [
        {
            "o": "Steinkirchen",
            "l": "Bayern"
        }
    ],
    "84453": [
        {
            "o": "Mühldorf a. Inn",
            "l": "Bayern"
        }
    ],
    "84478": [
        {
            "o": "Waldkraiburg",
            "l": "Bayern"
        }
    ],
    "84489": [
        {
            "o": "Burghausen",
            "l": "Bayern"
        }
    ],
    "84494": [
        {
            "o": "Lohkirchen",
            "l": "Bayern"
        },
        {
            "o": "Neumarkt-Sankt Veit",
            "l": "Bayern"
        },
        {
            "o": "Niederbergkirchen",
            "l": "Bayern"
        },
        {
            "o": "Niedertaufkirchen",
            "l": "Bayern"
        }
    ],
    "84503": [
        {
            "o": "Altötting",
            "l": "Bayern"
        }
    ],
    "84508": [
        {
            "o": "Burgkirchen an der Alz",
            "l": "Bayern"
        }
    ],
    "84513": [
        {
            "o": "Erharting",
            "l": "Bayern"
        },
        {
            "o": "Töging a.Inn",
            "l": "Bayern"
        }
    ],
    "84518": [
        {
            "o": "Garching an der Alz",
            "l": "Bayern"
        }
    ],
    "84524": [
        {
            "o": "Neuötting",
            "l": "Bayern"
        }
    ],
    "84529": [
        {
            "o": "Tittmoning",
            "l": "Bayern"
        }
    ],
    "84533": [
        {
            "o": "Haiming",
            "l": "Bayern"
        },
        {
            "o": "Marktl",
            "l": "Bayern"
        },
        {
            "o": "Stammham",
            "l": "Bayern"
        }
    ],
    "84539": [
        {
            "o": "Ampfing",
            "l": "Bayern"
        },
        {
            "o": "Zangberg",
            "l": "Bayern"
        }
    ],
    "84543": [
        {
            "o": "Winhöring",
            "l": "Bayern"
        }
    ],
    "84544": [
        {
            "o": "Aschau am Inn",
            "l": "Bayern"
        }
    ],
    "84546": [
        {
            "o": "Egglkofen",
            "l": "Bayern"
        }
    ],
    "84547": [
        {
            "o": "Emmerting",
            "l": "Bayern"
        }
    ],
    "84549": [
        {
            "o": "Engelsberg",
            "l": "Bayern"
        }
    ],
    "84550": [
        {
            "o": "Feichten an der Alz",
            "l": "Bayern"
        }
    ],
    "84552": [
        {
            "o": "Geratskirchen",
            "l": "Bayern"
        }
    ],
    "84553": [
        {
            "o": "Halsbach",
            "l": "Bayern"
        }
    ],
    "84555": [
        {
            "o": "Jettenbach",
            "l": "Bayern"
        }
    ],
    "84556": [
        {
            "o": "Kastl",
            "l": "Bayern"
        }
    ],
    "84558": [
        {
            "o": "Kirchweidach",
            "l": "Bayern"
        },
        {
            "o": "Tyrlaching",
            "l": "Bayern"
        }
    ],
    "84559": [
        {
            "o": "Kraiburg",
            "l": "Bayern"
        }
    ],
    "84561": [
        {
            "o": "Mehring",
            "l": "Bayern"
        }
    ],
    "84562": [
        {
            "o": "Mettenheim",
            "l": "Bayern"
        }
    ],
    "84564": [
        {
            "o": "Oberbergkirchen",
            "l": "Bayern"
        }
    ],
    "84565": [
        {
            "o": "Oberneukirchen",
            "l": "Bayern"
        }
    ],
    "84567": [
        {
            "o": "Erlbach",
            "l": "Bayern"
        },
        {
            "o": "Perach",
            "l": "Bayern"
        }
    ],
    "84568": [
        {
            "o": "Pleiskirchen",
            "l": "Bayern"
        }
    ],
    "84570": [
        {
            "o": "Polling",
            "l": "Bayern"
        }
    ],
    "84571": [
        {
            "o": "Reischach",
            "l": "Bayern"
        }
    ],
    "84573": [
        {
            "o": "Schönberg",
            "l": "Bayern"
        }
    ],
    "84574": [
        {
            "o": "Taufkirchen",
            "l": "Bayern"
        }
    ],
    "84576": [
        {
            "o": "Teising",
            "l": "Bayern"
        }
    ],
    "84577": [
        {
            "o": "Tüßling",
            "l": "Bayern"
        }
    ],
    "84579": [
        {
            "o": "Unterneukirchen",
            "l": "Bayern"
        }
    ],
    "85049": [
        {
            "o": "Ingolstadt",
            "l": "Bayern"
        }
    ],
    "85051": [
        {
            "o": "Ingolstadt",
            "l": "Bayern"
        }
    ],
    "85053": [
        {
            "o": "Ingolstadt",
            "l": "Bayern"
        }
    ],
    "85055": [
        {
            "o": "Ingolstadt",
            "l": "Bayern"
        }
    ],
    "85057": [
        {
            "o": "Ingolstadt",
            "l": "Bayern"
        }
    ],
    "85072": [
        {
            "o": "Eichstätt",
            "l": "Bayern"
        }
    ],
    "85077": [
        {
            "o": "Manching",
            "l": "Bayern"
        }
    ],
    "85080": [
        {
            "o": "Gaimersheim",
            "l": "Bayern"
        }
    ],
    "85084": [
        {
            "o": "Reichertshofen",
            "l": "Bayern"
        }
    ],
    "85088": [
        {
            "o": "Vohburg a.d.Donau",
            "l": "Bayern"
        }
    ],
    "85092": [
        {
            "o": "Kösching",
            "l": "Bayern"
        },
        {
            "o": "Stammham",
            "l": "Bayern"
        }
    ],
    "85095": [
        {
            "o": "Denkendorf",
            "l": "Bayern"
        }
    ],
    "85098": [
        {
            "o": "Großmehring",
            "l": "Bayern"
        }
    ],
    "85101": [
        {
            "o": "Lenting",
            "l": "Bayern"
        }
    ],
    "85104": [
        {
            "o": "Pförring",
            "l": "Bayern"
        }
    ],
    "85107": [
        {
            "o": "Baar-Ebenhausen",
            "l": "Bayern"
        }
    ],
    "85110": [
        {
            "o": "Kipfenberg",
            "l": "Bayern"
        }
    ],
    "85111": [
        {
            "o": "Adelschlag",
            "l": "Bayern"
        }
    ],
    "85113": [
        {
            "o": "Böhmfeld",
            "l": "Bayern"
        }
    ],
    "85114": [
        {
            "o": "Buxheim",
            "l": "Bayern"
        }
    ],
    "85116": [
        {
            "o": "Egweil",
            "l": "Bayern"
        }
    ],
    "85117": [
        {
            "o": "Eitensheim",
            "l": "Bayern"
        }
    ],
    "85119": [
        {
            "o": "Ernsgaden",
            "l": "Bayern"
        }
    ],
    "85120": [
        {
            "o": "Hepberg",
            "l": "Bayern"
        }
    ],
    "85122": [
        {
            "o": "Hitzhofen",
            "l": "Bayern"
        }
    ],
    "85123": [
        {
            "o": "Karlskron",
            "l": "Bayern"
        }
    ],
    "85125": [
        {
            "o": "Kinding",
            "l": "Bayern"
        }
    ],
    "85126": [
        {
            "o": "Münchsmünster",
            "l": "Bayern"
        }
    ],
    "85128": [
        {
            "o": "Nassenfels",
            "l": "Bayern"
        }
    ],
    "85129": [
        {
            "o": "Oberdolling",
            "l": "Bayern"
        }
    ],
    "85131": [
        {
            "o": "Pollenfeld",
            "l": "Bayern"
        }
    ],
    "85132": [
        {
            "o": "Schernfeld",
            "l": "Bayern"
        }
    ],
    "85134": [
        {
            "o": "Stammham",
            "l": "Bayern"
        }
    ],
    "85135": [
        {
            "o": "Titting",
            "l": "Bayern"
        }
    ],
    "85137": [
        {
            "o": "Walting",
            "l": "Bayern"
        }
    ],
    "85139": [
        {
            "o": "Wettstetten",
            "l": "Bayern"
        }
    ],
    "85221": [
        {
            "o": "Dachau",
            "l": "Bayern"
        }
    ],
    "85229": [
        {
            "o": "Markt Indersdorf",
            "l": "Bayern"
        }
    ],
    "85232": [
        {
            "o": "Bergkirchen",
            "l": "Bayern"
        }
    ],
    "85235": [
        {
            "o": "Odelzhausen",
            "l": "Bayern"
        },
        {
            "o": "Pfaffenhofen an der Glonn",
            "l": "Bayern"
        }
    ],
    "85238": [
        {
            "o": "Petershausen",
            "l": "Bayern"
        }
    ],
    "85241": [
        {
            "o": "Hebertshausen",
            "l": "Bayern"
        }
    ],
    "85244": [
        {
            "o": "Röhrmoos",
            "l": "Bayern"
        }
    ],
    "85247": [
        {
            "o": "Schwabhausen",
            "l": "Bayern"
        }
    ],
    "85250": [
        {
            "o": "Altomünster",
            "l": "Bayern"
        }
    ],
    "85253": [
        {
            "o": "Erdweg",
            "l": "Bayern"
        }
    ],
    "85254": [
        {
            "o": "Sulzemoos",
            "l": "Bayern"
        }
    ],
    "85256": [
        {
            "o": "Vierkirchen",
            "l": "Bayern"
        }
    ],
    "85258": [
        {
            "o": "Weichs",
            "l": "Bayern"
        }
    ],
    "85259": [
        {
            "o": "Sulzemoos",
            "l": "Bayern"
        }
    ],
    "85276": [
        {
            "o": "Hettenshausen",
            "l": "Bayern"
        },
        {
            "o": "Pfaffenhofen an der Ilm",
            "l": "Bayern"
        }
    ],
    "85283": [
        {
            "o": "Wolnzach",
            "l": "Bayern"
        }
    ],
    "85290": [
        {
            "o": "Geisenfeld",
            "l": "Bayern"
        }
    ],
    "85293": [
        {
            "o": "Reichertshausen",
            "l": "Bayern"
        }
    ],
    "85296": [
        {
            "o": "Rohrbach",
            "l": "Bayern"
        }
    ],
    "85298": [
        {
            "o": "Scheyern",
            "l": "Bayern"
        }
    ],
    "85301": [
        {
            "o": "Schweitenkirchen",
            "l": "Bayern"
        }
    ],
    "85302": [
        {
            "o": "Gerolsbach",
            "l": "Bayern"
        }
    ],
    "85304": [
        {
            "o": "Hettenshausen",
            "l": "Bayern"
        },
        {
            "o": "Ilmmünster",
            "l": "Bayern"
        }
    ],
    "85305": [
        {
            "o": "Jetzendorf",
            "l": "Bayern"
        }
    ],
    "85307": [
        {
            "o": "Hettenshausen",
            "l": "Bayern"
        },
        {
            "o": "Paunzhausen",
            "l": "Bayern"
        }
    ],
    "85309": [
        {
            "o": "Pörnbach",
            "l": "Bayern"
        }
    ],
    "85354": [
        {
            "o": "Freising",
            "l": "Bayern"
        }
    ],
    "85356": [
        {
            "o": "Freising",
            "l": "Bayern"
        }
    ],
    "85368": [
        {
            "o": "Moosburg",
            "l": "Bayern"
        },
        {
            "o": "Wang",
            "l": "Bayern"
        }
    ],
    "85375": [
        {
            "o": "Neufahrn bei Freising",
            "l": "Bayern"
        }
    ],
    "85376": [
        {
            "o": "Neufahrn bei Freising",
            "l": "Bayern"
        }
    ],
    "85386": [
        {
            "o": "Eching",
            "l": "Bayern"
        }
    ],
    "85391": [
        {
            "o": "Allershausen",
            "l": "Bayern"
        }
    ],
    "85395": [
        {
            "o": "Attenkirchen",
            "l": "Bayern"
        },
        {
            "o": "Nandlstadt",
            "l": "Bayern"
        },
        {
            "o": "Wolfersdorf",
            "l": "Bayern"
        }
    ],
    "85399": [
        {
            "o": "Hallbergmoos",
            "l": "Bayern"
        }
    ],
    "85402": [
        {
            "o": "Kranzberg",
            "l": "Bayern"
        }
    ],
    "85405": [
        {
            "o": "Nandlstadt",
            "l": "Bayern"
        }
    ],
    "85406": [
        {
            "o": "Zolling",
            "l": "Bayern"
        }
    ],
    "85408": [
        {
            "o": "Gammelsdorf",
            "l": "Bayern"
        }
    ],
    "85410": [
        {
            "o": "Haag an der Amper",
            "l": "Bayern"
        }
    ],
    "85411": [
        {
            "o": "Hohenkammer",
            "l": "Bayern"
        }
    ],
    "85413": [
        {
            "o": "Hörgertshausen",
            "l": "Bayern"
        }
    ],
    "85414": [
        {
            "o": "Kirchdorf an der Amper",
            "l": "Bayern"
        }
    ],
    "85416": [
        {
            "o": "Langenbach",
            "l": "Bayern"
        }
    ],
    "85417": [
        {
            "o": "Marzling",
            "l": "Bayern"
        }
    ],
    "85419": [
        {
            "o": "Mauern",
            "l": "Bayern"
        }
    ],
    "85435": [
        {
            "o": "Erding",
            "l": "Bayern"
        }
    ],
    "85445": [
        {
            "o": "Oberding",
            "l": "Bayern"
        }
    ],
    "85447": [
        {
            "o": "Fraunberg",
            "l": "Bayern"
        }
    ],
    "85452": [
        {
            "o": "Moosinning",
            "l": "Bayern"
        }
    ],
    "85456": [
        {
            "o": "Wartenberg",
            "l": "Bayern"
        }
    ],
    "85457": [
        {
            "o": "Wörth",
            "l": "Bayern"
        }
    ],
    "85459": [
        {
            "o": "Berglern",
            "l": "Bayern"
        }
    ],
    "85461": [
        {
            "o": "Bockhorn",
            "l": "Bayern"
        }
    ],
    "85462": [
        {
            "o": "Eitting",
            "l": "Bayern"
        }
    ],
    "85464": [
        {
            "o": "Finsing",
            "l": "Bayern"
        }
    ],
    "85465": [
        {
            "o": "Langenpreising",
            "l": "Bayern"
        }
    ],
    "85467": [
        {
            "o": "Neuching",
            "l": "Bayern"
        }
    ],
    "85469": [
        {
            "o": "Walpertskirchen",
            "l": "Bayern"
        }
    ],
    "85521": [
        {
            "o": "Hohenbrunn",
            "l": "Bayern"
        },
        {
            "o": "Ottobrunn",
            "l": "Bayern"
        }
    ],
    "85540": [
        {
            "o": "Haar",
            "l": "Bayern"
        },
        {
            "o": "München",
            "l": "Bayern"
        }
    ],
    "85551": [
        {
            "o": "Kirchheim bei München",
            "l": "Bayern"
        }
    ],
    "85560": [
        {
            "o": "Ebersberg",
            "l": "Bayern"
        }
    ],
    "85567": [
        {
            "o": "Bruck",
            "l": "Bayern"
        },
        {
            "o": "Grafing",
            "l": "Bayern"
        }
    ],
    "85570": [
        {
            "o": "Markt Schwaben",
            "l": "Bayern"
        },
        {
            "o": "Ottenhofen",
            "l": "Bayern"
        }
    ],
    "85579": [
        {
            "o": "Neubiberg",
            "l": "Bayern"
        }
    ],
    "85586": [
        {
            "o": "Poing",
            "l": "Bayern"
        }
    ],
    "85591": [
        {
            "o": "Vaterstetten",
            "l": "Bayern"
        }
    ],
    "85598": [
        {
            "o": "Vaterstetten",
            "l": "Bayern"
        }
    ],
    "85599": [
        {
            "o": "Vaterstetten",
            "l": "Bayern"
        }
    ],
    "85604": [
        {
            "o": "Zorneding",
            "l": "Bayern"
        }
    ],
    "85609": [
        {
            "o": "Aschheim",
            "l": "Bayern"
        }
    ],
    "85614": [
        {
            "o": "Kirchseeon",
            "l": "Bayern"
        }
    ],
    "85617": [
        {
            "o": "Aßling",
            "l": "Bayern"
        }
    ],
    "85622": [
        {
            "o": "Feldkirchen",
            "l": "Bayern"
        },
        {
            "o": "Vaterstetten",
            "l": "Bayern"
        }
    ],
    "85625": [
        {
            "o": "Baiern",
            "l": "Bayern"
        },
        {
            "o": "Glonn",
            "l": "Bayern"
        }
    ],
    "85630": [
        {
            "o": "Grasbrunn",
            "l": "Bayern"
        }
    ],
    "85635": [
        {
            "o": "Höhenkirchen-Siegertsbrunn",
            "l": "Bayern"
        }
    ],
    "85640": [
        {
            "o": "Putzbrunn",
            "l": "Bayern"
        }
    ],
    "85643": [
        {
            "o": "Steinhöring",
            "l": "Bayern"
        }
    ],
    "85646": [
        {
            "o": "Anzing",
            "l": "Bayern"
        },
        {
            "o": "Vaterstetten",
            "l": "Bayern"
        }
    ],
    "85649": [
        {
            "o": "Brunnthal",
            "l": "Bayern"
        }
    ],
    "85652": [
        {
            "o": "Pliening",
            "l": "Bayern"
        }
    ],
    "85653": [
        {
            "o": "Aying",
            "l": "Bayern"
        }
    ],
    "85656": [
        {
            "o": "Buch am Buchrain",
            "l": "Bayern"
        }
    ],
    "85658": [
        {
            "o": "Egmating",
            "l": "Bayern"
        }
    ],
    "85659": [
        {
            "o": "Forstern",
            "l": "Bayern"
        }
    ],
    "85661": [
        {
            "o": "Forstinning",
            "l": "Bayern"
        }
    ],
    "85662": [
        {
            "o": "Hohenbrunn",
            "l": "Bayern"
        }
    ],
    "85664": [
        {
            "o": "Hohenlinden",
            "l": "Bayern"
        }
    ],
    "85665": [
        {
            "o": "Moosach",
            "l": "Bayern"
        }
    ],
    "85667": [
        {
            "o": "Oberpframmern",
            "l": "Bayern"
        }
    ],
    "85669": [
        {
            "o": "Pastetten",
            "l": "Bayern"
        }
    ],
    "85716": [
        {
            "o": "Unterschleißheim",
            "l": "Bayern"
        }
    ],
    "85737": [
        {
            "o": "Ismaning",
            "l": "Bayern"
        }
    ],
    "85748": [
        {
            "o": "Garching bei München",
            "l": "Bayern"
        }
    ],
    "85757": [
        {
            "o": "Karlsfeld",
            "l": "Bayern"
        }
    ],
    "85764": [
        {
            "o": "Hebertshausen",
            "l": "Bayern"
        },
        {
            "o": "Oberschleißheim",
            "l": "Bayern"
        }
    ],
    "85774": [
        {
            "o": "Unterföhring",
            "l": "Bayern"
        }
    ],
    "85777": [
        {
            "o": "Fahrenzhausen",
            "l": "Bayern"
        }
    ],
    "85778": [
        {
            "o": "Haimhausen",
            "l": "Bayern"
        }
    ],
    "86150": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86152": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86153": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86154": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86156": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86157": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86159": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86161": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86163": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86165": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86167": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86169": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86179": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86199": [
        {
            "o": "Augsburg",
            "l": "Bayern"
        }
    ],
    "86316": [
        {
            "o": "Friedberg",
            "l": "Bayern"
        }
    ],
    "86343": [
        {
            "o": "Königsbrunn",
            "l": "Bayern"
        }
    ],
    "86356": [
        {
            "o": "Neusäß",
            "l": "Bayern"
        }
    ],
    "86368": [
        {
            "o": "Gersthofen",
            "l": "Bayern"
        }
    ],
    "86381": [
        {
            "o": "Krumbach (Schwaben)",
            "l": "Bayern"
        }
    ],
    "86391": [
        {
            "o": "Stadtbergen",
            "l": "Bayern"
        }
    ],
    "86399": [
        {
            "o": "Bobingen",
            "l": "Bayern"
        }
    ],
    "86405": [
        {
            "o": "Meitingen",
            "l": "Bayern"
        }
    ],
    "86415": [
        {
            "o": "Mering",
            "l": "Bayern"
        }
    ],
    "86420": [
        {
            "o": "Diedorf",
            "l": "Bayern"
        }
    ],
    "86424": [
        {
            "o": "Dinkelscherben",
            "l": "Bayern"
        }
    ],
    "86438": [
        {
            "o": "Kissing",
            "l": "Bayern"
        }
    ],
    "86441": [
        {
            "o": "Zusmarshausen",
            "l": "Bayern"
        }
    ],
    "86444": [
        {
            "o": "Affing",
            "l": "Bayern"
        }
    ],
    "86447": [
        {
            "o": "Aindling",
            "l": "Bayern"
        },
        {
            "o": "Todtenweis",
            "l": "Bayern"
        }
    ],
    "86450": [
        {
            "o": "Altenmünster",
            "l": "Bayern"
        }
    ],
    "86453": [
        {
            "o": "Dasing",
            "l": "Bayern"
        }
    ],
    "86456": [
        {
            "o": "Gablingen",
            "l": "Bayern"
        }
    ],
    "86459": [
        {
            "o": "Gessertshausen",
            "l": "Bayern"
        }
    ],
    "86462": [
        {
            "o": "Langweid am Lech",
            "l": "Bayern"
        }
    ],
    "86465": [
        {
            "o": "Heretsried",
            "l": "Bayern"
        },
        {
            "o": "Welden",
            "l": "Bayern"
        }
    ],
    "86470": [
        {
            "o": "Thannhausen",
            "l": "Bayern"
        }
    ],
    "86473": [
        {
            "o": "Ziemetshausen",
            "l": "Bayern"
        }
    ],
    "86476": [
        {
            "o": "Neuburg a.d.Kammel",
            "l": "Bayern"
        }
    ],
    "86477": [
        {
            "o": "Adelsried",
            "l": "Bayern"
        }
    ],
    "86479": [
        {
            "o": "Aichen",
            "l": "Bayern"
        }
    ],
    "86480": [
        {
            "o": "Aletshausen",
            "l": "Bayern"
        },
        {
            "o": "Waltenhausen",
            "l": "Bayern"
        }
    ],
    "86482": [
        {
            "o": "Aystetten",
            "l": "Bayern"
        }
    ],
    "86483": [
        {
            "o": "Balzhausen",
            "l": "Bayern"
        }
    ],
    "86485": [
        {
            "o": "Biberbach",
            "l": "Bayern"
        }
    ],
    "86486": [
        {
            "o": "Bonstetten",
            "l": "Bayern"
        }
    ],
    "86488": [
        {
            "o": "Breitenthal",
            "l": "Bayern"
        }
    ],
    "86489": [
        {
            "o": "Deisenhausen",
            "l": "Bayern"
        }
    ],
    "86491": [
        {
            "o": "Ebershausen",
            "l": "Bayern"
        }
    ],
    "86492": [
        {
            "o": "Egling an der Paar",
            "l": "Bayern"
        }
    ],
    "86494": [
        {
            "o": "Emersacker",
            "l": "Bayern"
        }
    ],
    "86495": [
        {
            "o": "Eurasburg",
            "l": "Bayern"
        }
    ],
    "86497": [
        {
            "o": "Horgau",
            "l": "Bayern"
        }
    ],
    "86498": [
        {
            "o": "Kettershausen",
            "l": "Bayern"
        }
    ],
    "86500": [
        {
            "o": "Kutzenhausen",
            "l": "Bayern"
        }
    ],
    "86502": [
        {
            "o": "Laugna",
            "l": "Bayern"
        }
    ],
    "86504": [
        {
            "o": "Merching",
            "l": "Bayern"
        }
    ],
    "86505": [
        {
            "o": "Münsterhausen",
            "l": "Bayern"
        }
    ],
    "86507": [
        {
            "o": "Kleinaitingen",
            "l": "Bayern"
        },
        {
            "o": "Oberottmarshausen",
            "l": "Bayern"
        }
    ],
    "86508": [
        {
            "o": "Rehling",
            "l": "Bayern"
        }
    ],
    "86510": [
        {
            "o": "Ried",
            "l": "Bayern"
        }
    ],
    "86511": [
        {
            "o": "Schmiechen",
            "l": "Bayern"
        }
    ],
    "86513": [
        {
            "o": "Ursberg",
            "l": "Bayern"
        }
    ],
    "86514": [
        {
            "o": "Ustersbach",
            "l": "Bayern"
        }
    ],
    "86517": [
        {
            "o": "Wehringen",
            "l": "Bayern"
        }
    ],
    "86519": [
        {
            "o": "Wiesenbach",
            "l": "Bayern"
        }
    ],
    "86529": [
        {
            "o": "Schrobenhausen",
            "l": "Bayern"
        }
    ],
    "86551": [
        {
            "o": "Aichach",
            "l": "Bayern"
        }
    ],
    "86554": [
        {
            "o": "Pöttmes",
            "l": "Bayern"
        }
    ],
    "86556": [
        {
            "o": "Kühbach",
            "l": "Bayern"
        }
    ],
    "86558": [
        {
            "o": "Hohenwart",
            "l": "Bayern"
        }
    ],
    "86559": [
        {
            "o": "Adelzhausen",
            "l": "Bayern"
        }
    ],
    "86561": [
        {
            "o": "Aresing",
            "l": "Bayern"
        }
    ],
    "86562": [
        {
            "o": "Berg im Gau",
            "l": "Bayern"
        }
    ],
    "86564": [
        {
            "o": "Brunnen",
            "l": "Bayern"
        }
    ],
    "86565": [
        {
            "o": "Gachenbach",
            "l": "Bayern"
        }
    ],
    "86567": [
        {
            "o": "Hilgertshausen-Tandern",
            "l": "Bayern"
        }
    ],
    "86568": [
        {
            "o": "Hollenbach",
            "l": "Bayern"
        }
    ],
    "86570": [
        {
            "o": "Inchenhofen",
            "l": "Bayern"
        }
    ],
    "86571": [
        {
            "o": "Langenmosen",
            "l": "Bayern"
        }
    ],
    "86573": [
        {
            "o": "Obergriesbach",
            "l": "Bayern"
        }
    ],
    "86574": [
        {
            "o": "Petersdorf",
            "l": "Bayern"
        }
    ],
    "86576": [
        {
            "o": "Schiltberg",
            "l": "Bayern"
        }
    ],
    "86577": [
        {
            "o": "Sielenbach",
            "l": "Bayern"
        }
    ],
    "86579": [
        {
            "o": "Waidhofen",
            "l": "Bayern"
        }
    ],
    "86609": [
        {
            "o": "Donauwörth",
            "l": "Bayern"
        }
    ],
    "86633": [
        {
            "o": "Neuburg an der Donau",
            "l": "Bayern"
        }
    ],
    "86637": [
        {
            "o": "Binswangen",
            "l": "Bayern"
        },
        {
            "o": "Villenbach",
            "l": "Bayern"
        },
        {
            "o": "Wertingen",
            "l": "Bayern"
        },
        {
            "o": "Zusamaltheim",
            "l": "Bayern"
        }
    ],
    "86641": [
        {
            "o": "Rain",
            "l": "Bayern"
        }
    ],
    "86643": [
        {
            "o": "Rennertshofen",
            "l": "Bayern"
        }
    ],
    "86647": [
        {
            "o": "Buttenwiesen",
            "l": "Bayern"
        }
    ],
    "86650": [
        {
            "o": "Wemding",
            "l": "Bayern"
        }
    ],
    "86653": [
        {
            "o": "Daiting",
            "l": "Bayern"
        },
        {
            "o": "Monheim",
            "l": "Bayern"
        }
    ],
    "86655": [
        {
            "o": "Harburg (Schwaben)",
            "l": "Bayern"
        }
    ],
    "86657": [
        {
            "o": "Bissingen",
            "l": "Bayern"
        }
    ],
    "86660": [
        {
            "o": "Tapfheim",
            "l": "Bayern"
        }
    ],
    "86663": [
        {
            "o": "Asbach-Bäumenheim",
            "l": "Bayern"
        }
    ],
    "86666": [
        {
            "o": "Burgheim",
            "l": "Bayern"
        }
    ],
    "86668": [
        {
            "o": "Karlshuld",
            "l": "Bayern"
        }
    ],
    "86669": [
        {
            "o": "Königsmoos",
            "l": "Bayern"
        }
    ],
    "86672": [
        {
            "o": "Thierhaupten",
            "l": "Bayern"
        }
    ],
    "86673": [
        {
            "o": "Bergheim",
            "l": "Bayern"
        }
    ],
    "86674": [
        {
            "o": "Baar",
            "l": "Bayern"
        }
    ],
    "86675": [
        {
            "o": "Buchdorf",
            "l": "Bayern"
        }
    ],
    "86676": [
        {
            "o": "Ehekirchen",
            "l": "Bayern"
        }
    ],
    "86678": [
        {
            "o": "Ehingen",
            "l": "Bayern"
        }
    ],
    "86679": [
        {
            "o": "Ellgau",
            "l": "Bayern"
        }
    ],
    "86681": [
        {
            "o": "Fünfstetten",
            "l": "Bayern"
        }
    ],
    "86682": [
        {
            "o": "Genderkingen",
            "l": "Bayern"
        }
    ],
    "86684": [
        {
            "o": "Holzheim",
            "l": "Bayern"
        }
    ],
    "86685": [
        {
            "o": "Huisheim",
            "l": "Bayern"
        }
    ],
    "86687": [
        {
            "o": "Kaisheim",
            "l": "Bayern"
        }
    ],
    "86688": [
        {
            "o": "Marxheim",
            "l": "Bayern"
        }
    ],
    "86690": [
        {
            "o": "Mertingen",
            "l": "Bayern"
        }
    ],
    "86692": [
        {
            "o": "Münster",
            "l": "Bayern"
        }
    ],
    "86694": [
        {
            "o": "Niederschönenfeld",
            "l": "Bayern"
        }
    ],
    "86695": [
        {
            "o": "Allmannshofen",
            "l": "Bayern"
        },
        {
            "o": "Nordendorf",
            "l": "Bayern"
        }
    ],
    "86697": [
        {
            "o": "Oberhausen",
            "l": "Bayern"
        }
    ],
    "86698": [
        {
            "o": "Oberndorf a.Lech",
            "l": "Bayern"
        }
    ],
    "86700": [
        {
            "o": "Otting",
            "l": "Bayern"
        }
    ],
    "86701": [
        {
            "o": "Rohrenfels",
            "l": "Bayern"
        }
    ],
    "86703": [
        {
            "o": "Rögling",
            "l": "Bayern"
        }
    ],
    "86704": [
        {
            "o": "Tagmersheim",
            "l": "Bayern"
        }
    ],
    "86706": [
        {
            "o": "Weichering",
            "l": "Bayern"
        }
    ],
    "86707": [
        {
            "o": "Kühlenthal",
            "l": "Bayern"
        },
        {
            "o": "Westendorf",
            "l": "Bayern"
        }
    ],
    "86709": [
        {
            "o": "Wolferstadt",
            "l": "Bayern"
        }
    ],
    "86720": [
        {
            "o": "Nördlingen",
            "l": "Bayern"
        }
    ],
    "86732": [
        {
            "o": "Oettingen i.Bay.",
            "l": "Bayern"
        }
    ],
    "86733": [
        {
            "o": "Alerheim",
            "l": "Bayern"
        }
    ],
    "86735": [
        {
            "o": "Amerdingen",
            "l": "Bayern"
        },
        {
            "o": "Forheim",
            "l": "Bayern"
        }
    ],
    "86736": [
        {
            "o": "Auhausen",
            "l": "Bayern"
        }
    ],
    "86738": [
        {
            "o": "Deiningen",
            "l": "Bayern"
        }
    ],
    "86739": [
        {
            "o": "Ederheim",
            "l": "Bayern"
        }
    ],
    "86741": [
        {
            "o": "Ehingen a.Ries",
            "l": "Bayern"
        }
    ],
    "86742": [
        {
            "o": "Fremdingen",
            "l": "Bayern"
        }
    ],
    "86744": [
        {
            "o": "Hainsfarth",
            "l": "Bayern"
        }
    ],
    "86745": [
        {
            "o": "Hohenaltheim",
            "l": "Bayern"
        }
    ],
    "86747": [
        {
            "o": "Maihingen",
            "l": "Bayern"
        }
    ],
    "86748": [
        {
            "o": "Marktoffingen",
            "l": "Bayern"
        }
    ],
    "86750": [
        {
            "o": "Megesheim",
            "l": "Bayern"
        }
    ],
    "86751": [
        {
            "o": "Mönchsdeggingen",
            "l": "Bayern"
        }
    ],
    "86753": [
        {
            "o": "Möttingen",
            "l": "Bayern"
        }
    ],
    "86754": [
        {
            "o": "Munningen",
            "l": "Bayern"
        }
    ],
    "86756": [
        {
            "o": "Reimlingen",
            "l": "Bayern"
        }
    ],
    "86757": [
        {
            "o": "Wallerstein",
            "l": "Bayern"
        }
    ],
    "86759": [
        {
            "o": "Wechingen",
            "l": "Bayern"
        }
    ],
    "86807": [
        {
            "o": "Buchloe",
            "l": "Bayern"
        }
    ],
    "86825": [
        {
            "o": "Bad Wörishofen",
            "l": "Bayern"
        }
    ],
    "86830": [
        {
            "o": "Schwabmünchen",
            "l": "Bayern"
        }
    ],
    "86833": [
        {
            "o": "Ettringen",
            "l": "Bayern"
        }
    ],
    "86836": [
        {
            "o": "Graben",
            "l": "Bayern"
        },
        {
            "o": "Klosterlechfeld",
            "l": "Bayern"
        },
        {
            "o": "Obermeitingen",
            "l": "Bayern"
        },
        {
            "o": "Untermeitingen",
            "l": "Bayern"
        }
    ],
    "86842": [
        {
            "o": "Türkheim",
            "l": "Bayern"
        }
    ],
    "86845": [
        {
            "o": "Großaitingen",
            "l": "Bayern"
        }
    ],
    "86850": [
        {
            "o": "Fischach",
            "l": "Bayern"
        }
    ],
    "86853": [
        {
            "o": "Langerringen",
            "l": "Bayern"
        }
    ],
    "86854": [
        {
            "o": "Amberg",
            "l": "Bayern"
        }
    ],
    "86856": [
        {
            "o": "Hiltenfingen",
            "l": "Bayern"
        }
    ],
    "86857": [
        {
            "o": "Hurlach",
            "l": "Bayern"
        }
    ],
    "86859": [
        {
            "o": "Igling",
            "l": "Bayern"
        }
    ],
    "86860": [
        {
            "o": "Jengen",
            "l": "Bayern"
        }
    ],
    "86862": [
        {
            "o": "Lamerdingen",
            "l": "Bayern"
        }
    ],
    "86863": [
        {
            "o": "Langenneufnach",
            "l": "Bayern"
        }
    ],
    "86865": [
        {
            "o": "Markt Wald",
            "l": "Bayern"
        }
    ],
    "86866": [
        {
            "o": "Mickhausen",
            "l": "Bayern"
        }
    ],
    "86868": [
        {
            "o": "Mittelneufnach",
            "l": "Bayern"
        }
    ],
    "86869": [
        {
            "o": "Oberostendorf",
            "l": "Bayern"
        }
    ],
    "86871": [
        {
            "o": "Rammingen",
            "l": "Bayern"
        }
    ],
    "86872": [
        {
            "o": "Scherstetten",
            "l": "Bayern"
        }
    ],
    "86874": [
        {
            "o": "Tussenhausen",
            "l": "Bayern"
        }
    ],
    "86875": [
        {
            "o": "Waal",
            "l": "Bayern"
        }
    ],
    "86877": [
        {
            "o": "Walkertshofen",
            "l": "Bayern"
        }
    ],
    "86879": [
        {
            "o": "Wiedergeltingen",
            "l": "Bayern"
        }
    ],
    "86899": [
        {
            "o": "Landsberg am Lech",
            "l": "Bayern"
        }
    ],
    "86911": [
        {
            "o": "Dießen am Ammersee",
            "l": "Bayern"
        }
    ],
    "86916": [
        {
            "o": "Kaufering",
            "l": "Bayern"
        }
    ],
    "86919": [
        {
            "o": "Utting am Ammersee",
            "l": "Bayern"
        }
    ],
    "86920": [
        {
            "o": "Denklingen",
            "l": "Bayern"
        }
    ],
    "86922": [
        {
            "o": "Eresing",
            "l": "Bayern"
        }
    ],
    "86923": [
        {
            "o": "Finning",
            "l": "Bayern"
        }
    ],
    "86925": [
        {
            "o": "Fuchstal",
            "l": "Bayern"
        }
    ],
    "86926": [
        {
            "o": "Eresing",
            "l": "Bayern"
        },
        {
            "o": "Greifenberg",
            "l": "Bayern"
        }
    ],
    "86928": [
        {
            "o": "Hofstetten",
            "l": "Bayern"
        }
    ],
    "86929": [
        {
            "o": "Penzing",
            "l": "Bayern"
        }
    ],
    "86931": [
        {
            "o": "Prittriching",
            "l": "Bayern"
        }
    ],
    "86932": [
        {
            "o": "Landsberg am Lech",
            "l": "Bayern"
        },
        {
            "o": "Pürgen",
            "l": "Bayern"
        }
    ],
    "86934": [
        {
            "o": "Reichling",
            "l": "Bayern"
        }
    ],
    "86935": [
        {
            "o": "Rott",
            "l": "Bayern"
        }
    ],
    "86937": [
        {
            "o": "Scheuring",
            "l": "Bayern"
        }
    ],
    "86938": [
        {
            "o": "Schondorf am Ammersee",
            "l": "Bayern"
        }
    ],
    "86940": [
        {
            "o": "Schwifting",
            "l": "Bayern"
        }
    ],
    "86941": [
        {
            "o": "Eresing",
            "l": "Bayern"
        }
    ],
    "86943": [
        {
            "o": "Thaining",
            "l": "Bayern"
        }
    ],
    "86944": [
        {
            "o": "Unterdießen",
            "l": "Bayern"
        }
    ],
    "86946": [
        {
            "o": "Vilgertshofen",
            "l": "Bayern"
        }
    ],
    "86947": [
        {
            "o": "Weil",
            "l": "Bayern"
        }
    ],
    "86949": [
        {
            "o": "Windach",
            "l": "Bayern"
        }
    ],
    "86956": [
        {
            "o": "Schongau",
            "l": "Bayern"
        }
    ],
    "86971": [
        {
            "o": "Peiting",
            "l": "Bayern"
        }
    ],
    "86972": [
        {
            "o": "Altenstadt",
            "l": "Bayern"
        }
    ],
    "86974": [
        {
            "o": "Apfeldorf",
            "l": "Bayern"
        }
    ],
    "86975": [
        {
            "o": "Bernbeuren",
            "l": "Bayern"
        }
    ],
    "86977": [
        {
            "o": "Burggen",
            "l": "Bayern"
        }
    ],
    "86978": [
        {
            "o": "Hohenfurch",
            "l": "Bayern"
        }
    ],
    "86980": [
        {
            "o": "Ingenried",
            "l": "Bayern"
        }
    ],
    "86981": [
        {
            "o": "Kinsau",
            "l": "Bayern"
        }
    ],
    "86983": [
        {
            "o": "Lechbruck a.See",
            "l": "Bayern"
        }
    ],
    "86984": [
        {
            "o": "Prem",
            "l": "Bayern"
        }
    ],
    "86986": [
        {
            "o": "Schwabbruck",
            "l": "Bayern"
        }
    ],
    "86987": [
        {
            "o": "Schwabsoien",
            "l": "Bayern"
        }
    ],
    "86989": [
        {
            "o": "Steingaden",
            "l": "Bayern"
        }
    ],
    "87435": [
        {
            "o": "Kempten (Allgäu)",
            "l": "Bayern"
        }
    ],
    "87437": [
        {
            "o": "Kempten (Allgäu)",
            "l": "Bayern"
        }
    ],
    "87439": [
        {
            "o": "Kempten (Allgäu)",
            "l": "Bayern"
        }
    ],
    "87448": [
        {
            "o": "Waltenhofen",
            "l": "Bayern"
        }
    ],
    "87452": [
        {
            "o": "Altusried",
            "l": "Bayern"
        }
    ],
    "87459": [
        {
            "o": "Pfronten",
            "l": "Bayern"
        }
    ],
    "87463": [
        {
            "o": "Dietmannsried",
            "l": "Bayern"
        }
    ],
    "87466": [
        {
            "o": "Oy-Mittelberg",
            "l": "Bayern"
        }
    ],
    "87471": [
        {
            "o": "Durach",
            "l": "Bayern"
        }
    ],
    "87474": [
        {
            "o": "Buchenberg",
            "l": "Bayern"
        }
    ],
    "87477": [
        {
            "o": "Sulzberg",
            "l": "Bayern"
        }
    ],
    "87480": [
        {
            "o": "Weitnau",
            "l": "Bayern"
        }
    ],
    "87484": [
        {
            "o": "Nesselwang",
            "l": "Bayern"
        }
    ],
    "87487": [
        {
            "o": "Wiggensbach",
            "l": "Bayern"
        }
    ],
    "87488": [
        {
            "o": "Betzigau",
            "l": "Bayern"
        }
    ],
    "87490": [
        {
            "o": "Haldenwang",
            "l": "Bayern"
        }
    ],
    "87493": [
        {
            "o": "Lauben",
            "l": "Bayern"
        }
    ],
    "87494": [
        {
            "o": "Rückholz",
            "l": "Bayern"
        }
    ],
    "87496": [
        {
            "o": "Untrasried",
            "l": "Bayern"
        }
    ],
    "87497": [
        {
            "o": "Wertach",
            "l": "Bayern"
        }
    ],
    "87499": [
        {
            "o": "Wildpoldsried",
            "l": "Bayern"
        }
    ],
    "87509": [
        {
            "o": "Immenstadt im Allgäu",
            "l": "Bayern"
        }
    ],
    "87527": [
        {
            "o": "Ofterschwang",
            "l": "Bayern"
        },
        {
            "o": "Sonthofen",
            "l": "Bayern"
        }
    ],
    "87534": [
        {
            "o": "Oberstaufen",
            "l": "Bayern"
        }
    ],
    "87538": [
        {
            "o": "Balderschwang",
            "l": "Bayern"
        },
        {
            "o": "Bolsterlang",
            "l": "Bayern"
        },
        {
            "o": "Fischen im Allgäu",
            "l": "Bayern"
        },
        {
            "o": "Obermaiselstein",
            "l": "Bayern"
        }
    ],
    "87541": [
        {
            "o": "Bad Hindelang",
            "l": "Bayern"
        }
    ],
    "87544": [
        {
            "o": "Blaichach",
            "l": "Bayern"
        }
    ],
    "87545": [
        {
            "o": "Burgberg i.Allgäu",
            "l": "Bayern"
        }
    ],
    "87547": [
        {
            "o": "Missen-Wilhams",
            "l": "Bayern"
        }
    ],
    "87549": [
        {
            "o": "Rettenberg",
            "l": "Bayern"
        }
    ],
    "87561": [
        {
            "o": "Oberstdorf",
            "l": "Bayern"
        }
    ],
    "87600": [
        {
            "o": "Kaufbeuren",
            "l": "Bayern"
        }
    ],
    "87616": [
        {
            "o": "Marktoberdorf",
            "l": "Bayern"
        },
        {
            "o": "Wald",
            "l": "Bayern"
        }
    ],
    "87629": [
        {
            "o": "Füssen",
            "l": "Bayern"
        }
    ],
    "87634": [
        {
            "o": "Günzach",
            "l": "Bayern"
        },
        {
            "o": "Obergünzburg",
            "l": "Bayern"
        }
    ],
    "87637": [
        {
            "o": "Eisenberg",
            "l": "Bayern"
        },
        {
            "o": "Seeg",
            "l": "Bayern"
        }
    ],
    "87640": [
        {
            "o": "Biessenhofen",
            "l": "Bayern"
        }
    ],
    "87642": [
        {
            "o": "Halblech",
            "l": "Bayern"
        }
    ],
    "87645": [
        {
            "o": "Schwangau",
            "l": "Bayern"
        }
    ],
    "87647": [
        {
            "o": "Kraftisried",
            "l": "Bayern"
        },
        {
            "o": "Unterthingau",
            "l": "Bayern"
        }
    ],
    "87648": [
        {
            "o": "Aitrang",
            "l": "Bayern"
        }
    ],
    "87650": [
        {
            "o": "Baisweil",
            "l": "Bayern"
        }
    ],
    "87651": [
        {
            "o": "Bidingen",
            "l": "Bayern"
        }
    ],
    "87653": [
        {
            "o": "Eggenthal",
            "l": "Bayern"
        }
    ],
    "87654": [
        {
            "o": "Friesenried",
            "l": "Bayern"
        }
    ],
    "87656": [
        {
            "o": "Germaringen",
            "l": "Bayern"
        }
    ],
    "87657": [
        {
            "o": "Görisried",
            "l": "Bayern"
        }
    ],
    "87659": [
        {
            "o": "Hopferau",
            "l": "Bayern"
        }
    ],
    "87660": [
        {
            "o": "Irsee",
            "l": "Bayern"
        }
    ],
    "87662": [
        {
            "o": "Kaltental",
            "l": "Bayern"
        },
        {
            "o": "Osterzell",
            "l": "Bayern"
        }
    ],
    "87663": [
        {
            "o": "Lengenwang",
            "l": "Bayern"
        }
    ],
    "87665": [
        {
            "o": "Mauerstetten",
            "l": "Bayern"
        }
    ],
    "87666": [
        {
            "o": "Pforzen",
            "l": "Bayern"
        }
    ],
    "87668": [
        {
            "o": "Rieden",
            "l": "Bayern"
        }
    ],
    "87669": [
        {
            "o": "Rieden am Forggensee",
            "l": "Bayern"
        }
    ],
    "87671": [
        {
            "o": "Ronsberg",
            "l": "Bayern"
        }
    ],
    "87672": [
        {
            "o": "Roßhaupten",
            "l": "Bayern"
        }
    ],
    "87674": [
        {
            "o": "Ruderatshofen",
            "l": "Bayern"
        }
    ],
    "87675": [
        {
            "o": "Rettenbach a.Auerberg",
            "l": "Bayern"
        },
        {
            "o": "Stötten a.Auerberg",
            "l": "Bayern"
        }
    ],
    "87677": [
        {
            "o": "Stöttwang",
            "l": "Bayern"
        }
    ],
    "87679": [
        {
            "o": "Westendorf",
            "l": "Bayern"
        }
    ],
    "87700": [
        {
            "o": "Memmingen",
            "l": "Bayern"
        }
    ],
    "87719": [
        {
            "o": "Mindelheim",
            "l": "Bayern"
        }
    ],
    "87724": [
        {
            "o": "Ottobeuren",
            "l": "Bayern"
        }
    ],
    "87727": [
        {
            "o": "Babenhausen",
            "l": "Bayern"
        }
    ],
    "87730": [
        {
            "o": "Bad Grönenbach",
            "l": "Bayern"
        }
    ],
    "87733": [
        {
            "o": "Markt Rettenbach",
            "l": "Bayern"
        }
    ],
    "87734": [
        {
            "o": "Benningen",
            "l": "Bayern"
        }
    ],
    "87736": [
        {
            "o": "Böhen",
            "l": "Bayern"
        }
    ],
    "87737": [
        {
            "o": "Boos",
            "l": "Bayern"
        }
    ],
    "87739": [
        {
            "o": "Breitenbrunn",
            "l": "Bayern"
        }
    ],
    "87740": [
        {
            "o": "Buxheim",
            "l": "Bayern"
        }
    ],
    "87742": [
        {
            "o": "Apfeltrach",
            "l": "Bayern"
        },
        {
            "o": "Dirlewang",
            "l": "Bayern"
        }
    ],
    "87743": [
        {
            "o": "Egg an der Günz",
            "l": "Bayern"
        }
    ],
    "87745": [
        {
            "o": "Eppishausen",
            "l": "Bayern"
        }
    ],
    "87746": [
        {
            "o": "Erkheim",
            "l": "Bayern"
        }
    ],
    "87748": [
        {
            "o": "Fellheim",
            "l": "Bayern"
        }
    ],
    "87749": [
        {
            "o": "Hawangen",
            "l": "Bayern"
        }
    ],
    "87751": [
        {
            "o": "Heimertingen",
            "l": "Bayern"
        }
    ],
    "87752": [
        {
            "o": "Holzgünz",
            "l": "Bayern"
        }
    ],
    "87754": [
        {
            "o": "Kammlach",
            "l": "Bayern"
        }
    ],
    "87755": [
        {
            "o": "Kirchhaslach",
            "l": "Bayern"
        }
    ],
    "87757": [
        {
            "o": "Kirchheim in Schwaben",
            "l": "Bayern"
        }
    ],
    "87758": [
        {
            "o": "Kronburg",
            "l": "Bayern"
        }
    ],
    "87760": [
        {
            "o": "Lachen",
            "l": "Bayern"
        }
    ],
    "87761": [
        {
            "o": "Lauben",
            "l": "Bayern"
        }
    ],
    "87763": [
        {
            "o": "Lautrach",
            "l": "Bayern"
        }
    ],
    "87764": [
        {
            "o": "Legau",
            "l": "Bayern"
        }
    ],
    "87766": [
        {
            "o": "Memmingerberg",
            "l": "Bayern"
        }
    ],
    "87767": [
        {
            "o": "Niederrieden",
            "l": "Bayern"
        }
    ],
    "87769": [
        {
            "o": "Oberrieden",
            "l": "Bayern"
        }
    ],
    "87770": [
        {
            "o": "Oberschönegg",
            "l": "Bayern"
        }
    ],
    "87772": [
        {
            "o": "Pfaffenhausen",
            "l": "Bayern"
        }
    ],
    "87773": [
        {
            "o": "Pleß",
            "l": "Bayern"
        }
    ],
    "87775": [
        {
            "o": "Salgen",
            "l": "Bayern"
        }
    ],
    "87776": [
        {
            "o": "Sontheim",
            "l": "Bayern"
        }
    ],
    "87778": [
        {
            "o": "Stetten",
            "l": "Bayern"
        }
    ],
    "87779": [
        {
            "o": "Trunkelsberg",
            "l": "Bayern"
        }
    ],
    "87781": [
        {
            "o": "Ungerhausen",
            "l": "Bayern"
        }
    ],
    "87782": [
        {
            "o": "Dirlewang",
            "l": "Bayern"
        },
        {
            "o": "Unteregg",
            "l": "Bayern"
        }
    ],
    "87784": [
        {
            "o": "Westerheim",
            "l": "Bayern"
        }
    ],
    "87785": [
        {
            "o": "Winterrieden",
            "l": "Bayern"
        }
    ],
    "87787": [
        {
            "o": "Wolfertschwenden",
            "l": "Bayern"
        }
    ],
    "87789": [
        {
            "o": "Woringen",
            "l": "Bayern"
        }
    ],
    "88045": [
        {
            "o": "Friedrichshafen",
            "l": "Baden-Württemberg"
        }
    ],
    "88046": [
        {
            "o": "Friedrichshafen",
            "l": "Baden-Württemberg"
        }
    ],
    "88048": [
        {
            "o": "Friedrichshafen",
            "l": "Baden-Württemberg"
        }
    ],
    "88069": [
        {
            "o": "Tettnang",
            "l": "Baden-Württemberg"
        }
    ],
    "88074": [
        {
            "o": "Meckenbeuren",
            "l": "Baden-Württemberg"
        }
    ],
    "88079": [
        {
            "o": "Kressbronn am Bodensee",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Tettnang",
            "l": "Baden-Württemberg"
        }
    ],
    "88085": [
        {
            "o": "Langenargen",
            "l": "Baden-Württemberg"
        }
    ],
    "88090": [
        {
            "o": "Immenstaad am Bodensee",
            "l": "Baden-Württemberg"
        }
    ],
    "88094": [
        {
            "o": "Oberteuringen",
            "l": "Baden-Württemberg"
        }
    ],
    "88097": [
        {
            "o": "Eriskirch",
            "l": "Baden-Württemberg"
        }
    ],
    "88099": [
        {
            "o": "Neukirch",
            "l": "Baden-Württemberg"
        }
    ],
    "88131": [
        {
            "o": "Bodolz",
            "l": "Bayern"
        },
        {
            "o": "Lindau (Bodensee)",
            "l": "Bayern"
        }
    ],
    "88138": [
        {
            "o": "Hergensweiler",
            "l": "Bayern"
        },
        {
            "o": "Sigmarszell",
            "l": "Bayern"
        },
        {
            "o": "Weißensberg",
            "l": "Bayern"
        }
    ],
    "88142": [
        {
            "o": "Wasserburg (Bodensee)",
            "l": "Bayern"
        }
    ],
    "88145": [
        {
            "o": "Hergatz",
            "l": "Bayern"
        },
        {
            "o": "Opfenbach",
            "l": "Bayern"
        }
    ],
    "88147": [
        {
            "o": "Achberg",
            "l": "Baden-Württemberg"
        }
    ],
    "88149": [
        {
            "o": "Nonnenhorn",
            "l": "Bayern"
        }
    ],
    "88161": [
        {
            "o": "Lindenberg im Allgäu",
            "l": "Bayern"
        }
    ],
    "88167": [
        {
            "o": "Gestratz",
            "l": "Bayern"
        },
        {
            "o": "Grünenbach",
            "l": "Bayern"
        },
        {
            "o": "Maierhöfen",
            "l": "Bayern"
        },
        {
            "o": "Röthenbach (Allgäu)",
            "l": "Bayern"
        },
        {
            "o": "Stiefenhofen",
            "l": "Bayern"
        }
    ],
    "88171": [
        {
            "o": "Weiler-Simmerberg",
            "l": "Bayern"
        }
    ],
    "88175": [
        {
            "o": "Scheidegg",
            "l": "Bayern"
        }
    ],
    "88178": [
        {
            "o": "Heimenkirch",
            "l": "Bayern"
        }
    ],
    "88179": [
        {
            "o": "Oberreute",
            "l": "Bayern"
        }
    ],
    "88212": [
        {
            "o": "Ravensburg",
            "l": "Baden-Württemberg"
        }
    ],
    "88213": [
        {
            "o": "Ravensburg",
            "l": "Baden-Württemberg"
        }
    ],
    "88214": [
        {
            "o": "Ravensburg",
            "l": "Baden-Württemberg"
        }
    ],
    "88239": [
        {
            "o": "Wangen im Allgäu",
            "l": "Baden-Württemberg"
        }
    ],
    "88250": [
        {
            "o": "Weingarten",
            "l": "Baden-Württemberg"
        }
    ],
    "88255": [
        {
            "o": "Baienfurt",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Baindt",
            "l": "Baden-Württemberg"
        }
    ],
    "88260": [
        {
            "o": "Argenbühl",
            "l": "Baden-Württemberg"
        }
    ],
    "88263": [
        {
            "o": "Horgenzell",
            "l": "Baden-Württemberg"
        }
    ],
    "88267": [
        {
            "o": "Vogt",
            "l": "Baden-Württemberg"
        }
    ],
    "88271": [
        {
            "o": "Wilhelmsdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88273": [
        {
            "o": "Fronreute",
            "l": "Baden-Württemberg"
        }
    ],
    "88276": [
        {
            "o": "Berg",
            "l": "Baden-Württemberg"
        }
    ],
    "88279": [
        {
            "o": "Amtzell",
            "l": "Baden-Württemberg"
        }
    ],
    "88281": [
        {
            "o": "Schlier",
            "l": "Baden-Württemberg"
        }
    ],
    "88284": [
        {
            "o": "Wolpertswende",
            "l": "Baden-Württemberg"
        }
    ],
    "88285": [
        {
            "o": "Bodnegg",
            "l": "Baden-Württemberg"
        }
    ],
    "88287": [
        {
            "o": "Grünkraut",
            "l": "Baden-Württemberg"
        }
    ],
    "88289": [
        {
            "o": "Waldburg",
            "l": "Baden-Württemberg"
        }
    ],
    "88299": [
        {
            "o": "Leutkirch im Allgäu",
            "l": "Baden-Württemberg"
        }
    ],
    "88316": [
        {
            "o": "Isny im Allgäu",
            "l": "Baden-Württemberg"
        }
    ],
    "88317": [
        {
            "o": "Aichstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "88319": [
        {
            "o": "Aitrach",
            "l": "Baden-Württemberg"
        }
    ],
    "88326": [
        {
            "o": "Aulendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88339": [
        {
            "o": "Bad Waldsee",
            "l": "Baden-Württemberg"
        }
    ],
    "88348": [
        {
            "o": "Allmannsweiler",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Bad Saulgau",
            "l": "Baden-Württemberg"
        }
    ],
    "88353": [
        {
            "o": "Kißlegg",
            "l": "Baden-Württemberg"
        }
    ],
    "88356": [
        {
            "o": "Ostrach",
            "l": "Baden-Württemberg"
        }
    ],
    "88361": [
        {
            "o": "Altshausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Boms",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Eichstegen",
            "l": "Baden-Württemberg"
        }
    ],
    "88364": [
        {
            "o": "Wolfegg",
            "l": "Baden-Württemberg"
        }
    ],
    "88367": [
        {
            "o": "Hohentengen",
            "l": "Baden-Württemberg"
        }
    ],
    "88368": [
        {
            "o": "Bergatreute",
            "l": "Baden-Württemberg"
        }
    ],
    "88370": [
        {
            "o": "Ebenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "88371": [
        {
            "o": "Ebersbach-Musbach",
            "l": "Baden-Württemberg"
        }
    ],
    "88373": [
        {
            "o": "Fleischwangen",
            "l": "Baden-Württemberg"
        }
    ],
    "88374": [
        {
            "o": "Hoßkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "88376": [
        {
            "o": "Königseggwald",
            "l": "Baden-Württemberg"
        }
    ],
    "88377": [
        {
            "o": "Riedhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "88379": [
        {
            "o": "Guggenhausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Unterwaldhausen",
            "l": "Baden-Württemberg"
        }
    ],
    "88400": [
        {
            "o": "Biberach an der Riß",
            "l": "Baden-Württemberg"
        }
    ],
    "88410": [
        {
            "o": "Bad Wurzach",
            "l": "Baden-Württemberg"
        }
    ],
    "88416": [
        {
            "o": "Erlenmoos",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Ochsenhausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Steinhausen an der Rottum",
            "l": "Baden-Württemberg"
        }
    ],
    "88422": [
        {
            "o": "Alleshausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Bad Buchau",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Betzenweiler",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Dürnau",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Kanzach",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Moosburg",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Oggelshausen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Seekirch",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Tiefenbach",
            "l": "Baden-Württemberg"
        }
    ],
    "88427": [
        {
            "o": "Bad Schussenried",
            "l": "Baden-Württemberg"
        }
    ],
    "88430": [
        {
            "o": "Rot an der Rot",
            "l": "Baden-Württemberg"
        }
    ],
    "88433": [
        {
            "o": "Schemmerhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "88436": [
        {
            "o": "Eberhardzell",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hochdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88437": [
        {
            "o": "Maselheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88441": [
        {
            "o": "Mittelbiberach",
            "l": "Baden-Württemberg"
        }
    ],
    "88444": [
        {
            "o": "Ummendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88447": [
        {
            "o": "Warthausen",
            "l": "Baden-Württemberg"
        }
    ],
    "88448": [
        {
            "o": "Attenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "88450": [
        {
            "o": "Berkheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88451": [
        {
            "o": "Dettingen an der Iller",
            "l": "Baden-Württemberg"
        }
    ],
    "88453": [
        {
            "o": "Erolzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88454": [
        {
            "o": "Hochdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88456": [
        {
            "o": "Ingoldingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88457": [
        {
            "o": "Kirchdorf an der Iller",
            "l": "Baden-Württemberg"
        }
    ],
    "88459": [
        {
            "o": "Tannheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88471": [
        {
            "o": "Laupheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88477": [
        {
            "o": "Schwendi",
            "l": "Baden-Württemberg"
        }
    ],
    "88480": [
        {
            "o": "Achstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "88481": [
        {
            "o": "Balzheim",
            "l": "Baden-Württemberg"
        }
    ],
    "88483": [
        {
            "o": "Burgrieden",
            "l": "Baden-Württemberg"
        }
    ],
    "88484": [
        {
            "o": "Gutenzell-Hürbel",
            "l": "Baden-Württemberg"
        }
    ],
    "88486": [
        {
            "o": "Kirchberg an der Iller",
            "l": "Baden-Württemberg"
        }
    ],
    "88487": [
        {
            "o": "Mietingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88489": [
        {
            "o": "Wain",
            "l": "Baden-Württemberg"
        }
    ],
    "88499": [
        {
            "o": "Altheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Emeringen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Riedlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88512": [
        {
            "o": "Mengen",
            "l": "Baden-Württemberg"
        }
    ],
    "88515": [
        {
            "o": "Langenenslingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88518": [
        {
            "o": "Herbertingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88521": [
        {
            "o": "Ertingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88524": [
        {
            "o": "Uttenweiler",
            "l": "Baden-Württemberg"
        }
    ],
    "88525": [
        {
            "o": "Dürmentingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88527": [
        {
            "o": "Unlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88529": [
        {
            "o": "Zwiefalten",
            "l": "Baden-Württemberg"
        }
    ],
    "88605": [
        {
            "o": "Meßkirch",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Sauldorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88630": [
        {
            "o": "Pfullendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88631": [
        {
            "o": "Beuron",
            "l": "Baden-Württemberg"
        }
    ],
    "88633": [
        {
            "o": "Heiligenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "88634": [
        {
            "o": "Herdwangen-Schönach",
            "l": "Baden-Württemberg"
        }
    ],
    "88636": [
        {
            "o": "Illmensee",
            "l": "Baden-Württemberg"
        }
    ],
    "88637": [
        {
            "o": "Buchheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Leibertingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88639": [
        {
            "o": "Wald",
            "l": "Baden-Württemberg"
        }
    ],
    "88662": [
        {
            "o": "Überlingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88677": [
        {
            "o": "Markdorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88682": [
        {
            "o": "Salem",
            "l": "Baden-Württemberg"
        }
    ],
    "88690": [
        {
            "o": "Uhldingen-Mühlhofen",
            "l": "Baden-Württemberg"
        }
    ],
    "88693": [
        {
            "o": "Deggenhausertal",
            "l": "Baden-Württemberg"
        }
    ],
    "88696": [
        {
            "o": "Owingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88697": [
        {
            "o": "Bermatingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88699": [
        {
            "o": "Frickingen",
            "l": "Baden-Württemberg"
        }
    ],
    "88709": [
        {
            "o": "Hagnau am Bodensee",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Meersburg",
            "l": "Baden-Württemberg"
        }
    ],
    "88718": [
        {
            "o": "Daisendorf",
            "l": "Baden-Württemberg"
        }
    ],
    "88719": [
        {
            "o": "Stetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89073": [
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89075": [
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89077": [
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89079": [
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89081": [
        {
            "o": "Elchingen",
            "l": "Bayern"
        },
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89129": [
        {
            "o": "Langenau",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Nerenstetten",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Öllingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Setzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89134": [
        {
            "o": "Blaustein",
            "l": "Baden-Württemberg"
        }
    ],
    "89143": [
        {
            "o": "Blaubeuren",
            "l": "Baden-Württemberg"
        }
    ],
    "89150": [
        {
            "o": "Laichingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89155": [
        {
            "o": "Erbach",
            "l": "Baden-Württemberg"
        }
    ],
    "89160": [
        {
            "o": "Dornstadt",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Ulm",
            "l": "Baden-Württemberg"
        }
    ],
    "89165": [
        {
            "o": "Dietenheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Wain",
            "l": "Baden-Württemberg"
        }
    ],
    "89168": [
        {
            "o": "Niederstotzingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89171": [
        {
            "o": "Illerkirchberg",
            "l": "Baden-Württemberg"
        }
    ],
    "89173": [
        {
            "o": "Lonsee",
            "l": "Baden-Württemberg"
        }
    ],
    "89174": [
        {
            "o": "Altheim (Alb)",
            "l": "Baden-Württemberg"
        }
    ],
    "89176": [
        {
            "o": "Asselfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89177": [
        {
            "o": "Ballendorf",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Börslingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89179": [
        {
            "o": "Beimerstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89180": [
        {
            "o": "Berghülen",
            "l": "Baden-Württemberg"
        }
    ],
    "89182": [
        {
            "o": "Bernstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "89183": [
        {
            "o": "Breitingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Holzkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "89185": [
        {
            "o": "Hüttisheim",
            "l": "Baden-Württemberg"
        }
    ],
    "89186": [
        {
            "o": "Illerrieden",
            "l": "Baden-Württemberg"
        }
    ],
    "89188": [
        {
            "o": "Merklingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89189": [
        {
            "o": "Neenstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89191": [
        {
            "o": "Nellingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89192": [
        {
            "o": "Rammingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89194": [
        {
            "o": "Schnürpflingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89195": [
        {
            "o": "Staig",
            "l": "Baden-Württemberg"
        }
    ],
    "89197": [
        {
            "o": "Weidenstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89198": [
        {
            "o": "Westerstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89231": [
        {
            "o": "Neu-Ulm",
            "l": "Bayern"
        }
    ],
    "89233": [
        {
            "o": "Neu-Ulm",
            "l": "Bayern"
        }
    ],
    "89250": [
        {
            "o": "Senden",
            "l": "Bayern"
        }
    ],
    "89257": [
        {
            "o": "Illertissen",
            "l": "Bayern"
        }
    ],
    "89264": [
        {
            "o": "Weißenhorn",
            "l": "Bayern"
        }
    ],
    "89269": [
        {
            "o": "Vöhringen",
            "l": "Bayern"
        }
    ],
    "89275": [
        {
            "o": "Elchingen",
            "l": "Bayern"
        }
    ],
    "89278": [
        {
            "o": "Nersingen",
            "l": "Bayern"
        }
    ],
    "89281": [
        {
            "o": "Altenstadt",
            "l": "Bayern"
        }
    ],
    "89284": [
        {
            "o": "Pfaffenhofen a.d.Roth",
            "l": "Bayern"
        }
    ],
    "89287": [
        {
            "o": "Bellenberg",
            "l": "Bayern"
        }
    ],
    "89290": [
        {
            "o": "Buch",
            "l": "Bayern"
        }
    ],
    "89291": [
        {
            "o": "Holzheim",
            "l": "Bayern"
        }
    ],
    "89293": [
        {
            "o": "Kellmünz a.d.Iller",
            "l": "Bayern"
        }
    ],
    "89294": [
        {
            "o": "Oberroth",
            "l": "Bayern"
        }
    ],
    "89296": [
        {
            "o": "Osterberg",
            "l": "Bayern"
        }
    ],
    "89297": [
        {
            "o": "Roggenburg",
            "l": "Bayern"
        }
    ],
    "89299": [
        {
            "o": "Unterroth",
            "l": "Bayern"
        }
    ],
    "89312": [
        {
            "o": "Günzburg",
            "l": "Bayern"
        }
    ],
    "89331": [
        {
            "o": "Burgau",
            "l": "Bayern"
        }
    ],
    "89335": [
        {
            "o": "Ichenhausen",
            "l": "Bayern"
        }
    ],
    "89340": [
        {
            "o": "Leipheim",
            "l": "Bayern"
        }
    ],
    "89343": [
        {
            "o": "Jettingen-Scheppach",
            "l": "Bayern"
        }
    ],
    "89344": [
        {
            "o": "Aislingen",
            "l": "Bayern"
        }
    ],
    "89346": [
        {
            "o": "Bibertal",
            "l": "Bayern"
        }
    ],
    "89347": [
        {
            "o": "Bubesheim",
            "l": "Bayern"
        }
    ],
    "89349": [
        {
            "o": "Burtenbach",
            "l": "Bayern"
        }
    ],
    "89350": [
        {
            "o": "Dürrlauingen",
            "l": "Bayern"
        }
    ],
    "89352": [
        {
            "o": "Ellzee",
            "l": "Bayern"
        }
    ],
    "89353": [
        {
            "o": "Glött",
            "l": "Bayern"
        }
    ],
    "89355": [
        {
            "o": "Gundremmingen",
            "l": "Bayern"
        }
    ],
    "89356": [
        {
            "o": "Haldenwang",
            "l": "Bayern"
        }
    ],
    "89358": [
        {
            "o": "Kammeltal",
            "l": "Bayern"
        }
    ],
    "89359": [
        {
            "o": "Kötz",
            "l": "Bayern"
        }
    ],
    "89361": [
        {
            "o": "Landensberg",
            "l": "Bayern"
        }
    ],
    "89362": [
        {
            "o": "Offingen",
            "l": "Bayern"
        }
    ],
    "89364": [
        {
            "o": "Rettenbach",
            "l": "Bayern"
        }
    ],
    "89365": [
        {
            "o": "Röfingen",
            "l": "Bayern"
        }
    ],
    "89367": [
        {
            "o": "Waldstetten",
            "l": "Bayern"
        }
    ],
    "89368": [
        {
            "o": "Winterbach",
            "l": "Bayern"
        }
    ],
    "89407": [
        {
            "o": "Dillingen a.d.Donau",
            "l": "Bayern"
        }
    ],
    "89415": [
        {
            "o": "Lauingen (Donau)",
            "l": "Bayern"
        }
    ],
    "89420": [
        {
            "o": "Höchstädt a.d.Donau",
            "l": "Bayern"
        }
    ],
    "89423": [
        {
            "o": "Gundelfingen a.d.Donau",
            "l": "Bayern"
        }
    ],
    "89426": [
        {
            "o": "Mödingen",
            "l": "Bayern"
        },
        {
            "o": "Wittislingen",
            "l": "Bayern"
        }
    ],
    "89428": [
        {
            "o": "Syrgenstein",
            "l": "Bayern"
        }
    ],
    "89429": [
        {
            "o": "Bachhagel",
            "l": "Bayern"
        }
    ],
    "89431": [
        {
            "o": "Bächingen a.d.Brenz",
            "l": "Bayern"
        }
    ],
    "89434": [
        {
            "o": "Blindheim",
            "l": "Bayern"
        }
    ],
    "89435": [
        {
            "o": "Finningen",
            "l": "Bayern"
        }
    ],
    "89437": [
        {
            "o": "Haunsheim",
            "l": "Bayern"
        }
    ],
    "89438": [
        {
            "o": "Holzheim",
            "l": "Bayern"
        }
    ],
    "89440": [
        {
            "o": "Lutzingen",
            "l": "Bayern"
        }
    ],
    "89441": [
        {
            "o": "Medlingen",
            "l": "Bayern"
        }
    ],
    "89443": [
        {
            "o": "Schwenningen",
            "l": "Bayern"
        }
    ],
    "89446": [
        {
            "o": "Ziertheim",
            "l": "Bayern"
        }
    ],
    "89447": [
        {
            "o": "Zöschingen",
            "l": "Bayern"
        }
    ],
    "89518": [
        {
            "o": "Heidenheim an der Brenz",
            "l": "Baden-Württemberg"
        }
    ],
    "89520": [
        {
            "o": "Heidenheim an der Brenz",
            "l": "Baden-Württemberg"
        }
    ],
    "89522": [
        {
            "o": "Giengen an der Brenz",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Heidenheim an der Brenz",
            "l": "Baden-Württemberg"
        }
    ],
    "89537": [
        {
            "o": "Giengen an der Brenz",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Hermaringen",
            "l": "Baden-Württemberg"
        }
    ],
    "89542": [
        {
            "o": "Herbrechtingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89547": [
        {
            "o": "Gerstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "89551": [
        {
            "o": "Königsbronn",
            "l": "Baden-Württemberg"
        }
    ],
    "89555": [
        {
            "o": "Steinheim am Albuch",
            "l": "Baden-Württemberg"
        }
    ],
    "89558": [
        {
            "o": "Böhmenkirch",
            "l": "Baden-Württemberg"
        }
    ],
    "89561": [
        {
            "o": "Dischingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89564": [
        {
            "o": "Nattheim",
            "l": "Baden-Württemberg"
        }
    ],
    "89567": [
        {
            "o": "Sontheim an der Brenz",
            "l": "Baden-Württemberg"
        }
    ],
    "89568": [
        {
            "o": "Hermaringen",
            "l": "Baden-Württemberg"
        }
    ],
    "89584": [
        {
            "o": "Ehingen (Donau)",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Lauterach",
            "l": "Baden-Württemberg"
        }
    ],
    "89597": [
        {
            "o": "Hausen am Bussen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Munderkingen",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Unterwachingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89601": [
        {
            "o": "Schelklingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89604": [
        {
            "o": "Allmendingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89605": [
        {
            "o": "Altheim",
            "l": "Baden-Württemberg"
        }
    ],
    "89607": [
        {
            "o": "Emerkingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89608": [
        {
            "o": "Griesingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89610": [
        {
            "o": "Oberdischingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89611": [
        {
            "o": "Obermarchtal",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Rechtenstein",
            "l": "Baden-Württemberg"
        }
    ],
    "89613": [
        {
            "o": "Grundsheim",
            "l": "Baden-Württemberg"
        },
        {
            "o": "Oberstadion",
            "l": "Baden-Württemberg"
        }
    ],
    "89614": [
        {
            "o": "Öpfingen",
            "l": "Baden-Württemberg"
        }
    ],
    "89616": [
        {
            "o": "Rottenacker",
            "l": "Baden-Württemberg"
        }
    ],
    "89617": [
        {
            "o": "Untermarchtal",
            "l": "Baden-Württemberg"
        }
    ],
    "89619": [
        {
            "o": "Unterstadion",
            "l": "Baden-Württemberg"
        }
    ],
    "90402": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90403": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90408": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90409": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90411": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90419": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90425": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90427": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90429": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90431": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90439": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90441": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90443": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90449": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90451": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90453": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90455": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90459": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90461": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90469": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90471": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90473": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90475": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90478": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90480": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90482": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90489": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90491": [
        {
            "o": "Nürnberg",
            "l": "Bayern"
        }
    ],
    "90513": [
        {
            "o": "Zirndorf",
            "l": "Bayern"
        }
    ],
    "90518": [
        {
            "o": "Altdorf bei Nürnberg",
            "l": "Bayern"
        }
    ],
    "90522": [
        {
            "o": "Oberasbach",
            "l": "Bayern"
        }
    ],
    "90530": [
        {
            "o": "Wendelstein",
            "l": "Bayern"
        }
    ],
    "90537": [
        {
            "o": "Feucht",
            "l": "Bayern"
        }
    ],
    "90542": [
        {
            "o": "Eckental",
            "l": "Bayern"
        }
    ],
    "90547": [
        {
            "o": "Stein",
            "l": "Bayern"
        }
    ],
    "90552": [
        {
            "o": "Röthenbach an der Pegnitz",
            "l": "Bayern"
        }
    ],
    "90556": [
        {
            "o": "Cadolzburg",
            "l": "Bayern"
        },
        {
            "o": "Seukendorf",
            "l": "Bayern"
        }
    ],
    "90559": [
        {
            "o": "Burgthann",
            "l": "Bayern"
        }
    ],
    "90562": [
        {
            "o": "Heroldsberg",
            "l": "Bayern"
        },
        {
            "o": "Kalchreuth",
            "l": "Bayern"
        }
    ],
    "90571": [
        {
            "o": "Schwaig bei Nürnberg",
            "l": "Bayern"
        }
    ],
    "90574": [
        {
            "o": "Roßtal",
            "l": "Bayern"
        }
    ],
    "90579": [
        {
            "o": "Langenzenn",
            "l": "Bayern"
        }
    ],
    "90584": [
        {
            "o": "Allersberg",
            "l": "Bayern"
        }
    ],
    "90587": [
        {
            "o": "Obermichelbach",
            "l": "Bayern"
        },
        {
            "o": "Tuchenbach",
            "l": "Bayern"
        },
        {
            "o": "Veitsbronn",
            "l": "Bayern"
        }
    ],
    "90592": [
        {
            "o": "Schwarzenbruck",
            "l": "Bayern"
        }
    ],
    "90596": [
        {
            "o": "Schwanstetten",
            "l": "Bayern"
        }
    ],
    "90599": [
        {
            "o": "Dietenhofen",
            "l": "Bayern"
        }
    ],
    "90602": [
        {
            "o": "Pyrbaum",
            "l": "Bayern"
        }
    ],
    "90607": [
        {
            "o": "Rückersdorf",
            "l": "Bayern"
        }
    ],
    "90610": [
        {
            "o": "Winkelhaid",
            "l": "Bayern"
        }
    ],
    "90613": [
        {
            "o": "Großhabersdorf",
            "l": "Bayern"
        }
    ],
    "90614": [
        {
            "o": "Ammerndorf",
            "l": "Bayern"
        }
    ],
    "90616": [
        {
            "o": "Neuhof a.d.Zenn",
            "l": "Bayern"
        }
    ],
    "90617": [
        {
            "o": "Puschendorf",
            "l": "Bayern"
        }
    ],
    "90619": [
        {
            "o": "Trautskirchen",
            "l": "Bayern"
        }
    ],
    "90762": [
        {
            "o": "Fürth",
            "l": "Bayern"
        }
    ],
    "90763": [
        {
            "o": "Fürth",
            "l": "Bayern"
        }
    ],
    "90765": [
        {
            "o": "Fürth",
            "l": "Bayern"
        }
    ],
    "90766": [
        {
            "o": "Fürth",
            "l": "Bayern"
        }
    ],
    "90768": [
        {
            "o": "Fürth",
            "l": "Bayern"
        }
    ],
    "91052": [
        {
            "o": "Erlangen",
            "l": "Bayern"
        }
    ],
    "91054": [
        {
            "o": "Buckenhof",
            "l": "Bayern"
        },
        {
            "o": "Erlangen",
            "l": "Bayern"
        }
    ],
    "91056": [
        {
            "o": "Erlangen",
            "l": "Bayern"
        }
    ],
    "91058": [
        {
            "o": "Erlangen",
            "l": "Bayern"
        }
    ],
    "91074": [
        {
            "o": "Herzogenaurach",
            "l": "Bayern"
        }
    ],
    "91077": [
        {
            "o": "Dormitz",
            "l": "Bayern"
        },
        {
            "o": "Hetzles",
            "l": "Bayern"
        },
        {
            "o": "Kleinsendelbach",
            "l": "Bayern"
        },
        {
            "o": "Neunkirchen a.Brand",
            "l": "Bayern"
        }
    ],
    "91080": [
        {
            "o": "Marloffstein",
            "l": "Bayern"
        },
        {
            "o": "Spardorf",
            "l": "Bayern"
        },
        {
            "o": "Uttenreuth",
            "l": "Bayern"
        }
    ],
    "91083": [
        {
            "o": "Baiersdorf",
            "l": "Bayern"
        }
    ],
    "91085": [
        {
            "o": "Weisendorf",
            "l": "Bayern"
        }
    ],
    "91086": [
        {
            "o": "Aurachtal",
            "l": "Bayern"
        }
    ],
    "91088": [
        {
            "o": "Bubenreuth",
            "l": "Bayern"
        }
    ],
    "91090": [
        {
            "o": "Effeltrich",
            "l": "Bayern"
        }
    ],
    "91091": [
        {
            "o": "Großenseebach",
            "l": "Bayern"
        }
    ],
    "91093": [
        {
            "o": "Heßdorf",
            "l": "Bayern"
        }
    ],
    "91094": [
        {
            "o": "Langensendelbach",
            "l": "Bayern"
        }
    ],
    "91096": [
        {
            "o": "Möhrendorf",
            "l": "Bayern"
        }
    ],
    "91097": [
        {
            "o": "Oberreichenbach",
            "l": "Bayern"
        }
    ],
    "91099": [
        {
            "o": "Poxdorf",
            "l": "Bayern"
        }
    ],
    "91126": [
        {
            "o": "Kammerstein",
            "l": "Bayern"
        },
        {
            "o": "Rednitzhembach",
            "l": "Bayern"
        },
        {
            "o": "Schwabach",
            "l": "Bayern"
        }
    ],
    "91154": [
        {
            "o": "Roth",
            "l": "Bayern"
        }
    ],
    "91161": [
        {
            "o": "Hilpoltstein",
            "l": "Bayern"
        }
    ],
    "91166": [
        {
            "o": "Georgensgmünd",
            "l": "Bayern"
        }
    ],
    "91171": [
        {
            "o": "Greding",
            "l": "Bayern"
        }
    ],
    "91174": [
        {
            "o": "Spalt",
            "l": "Bayern"
        }
    ],
    "91177": [
        {
            "o": "Thalmässing",
            "l": "Bayern"
        }
    ],
    "91180": [
        {
            "o": "Heideck",
            "l": "Bayern"
        }
    ],
    "91183": [
        {
            "o": "Abenberg",
            "l": "Bayern"
        }
    ],
    "91186": [
        {
            "o": "Büchenbach",
            "l": "Bayern"
        }
    ],
    "91187": [
        {
            "o": "Röttenbach",
            "l": "Bayern"
        }
    ],
    "91189": [
        {
            "o": "Rohr",
            "l": "Bayern"
        }
    ],
    "91207": [
        {
            "o": "Lauf an der Pegnitz",
            "l": "Bayern"
        }
    ],
    "91217": [
        {
            "o": "Hersbruck",
            "l": "Bayern"
        }
    ],
    "91220": [
        {
            "o": "Schnaittach",
            "l": "Bayern"
        }
    ],
    "91224": [
        {
            "o": "Pommelsbrunn",
            "l": "Bayern"
        }
    ],
    "91227": [
        {
            "o": "Leinburg",
            "l": "Bayern"
        }
    ],
    "91230": [
        {
            "o": "Happurg",
            "l": "Bayern"
        }
    ],
    "91233": [
        {
            "o": "Neunkirchen a. Sand",
            "l": "Bayern"
        }
    ],
    "91235": [
        {
            "o": "Hartenstein",
            "l": "Bayern"
        },
        {
            "o": "Velden",
            "l": "Bayern"
        }
    ],
    "91236": [
        {
            "o": "Alfeld",
            "l": "Bayern"
        }
    ],
    "91238": [
        {
            "o": "Engelthal",
            "l": "Bayern"
        },
        {
            "o": "Offenhausen",
            "l": "Bayern"
        }
    ],
    "91239": [
        {
            "o": "Henfenfeld",
            "l": "Bayern"
        }
    ],
    "91241": [
        {
            "o": "Kirchensittenbach",
            "l": "Bayern"
        }
    ],
    "91242": [
        {
            "o": "Ottensoos",
            "l": "Bayern"
        }
    ],
    "91244": [
        {
            "o": "Reichenschwand",
            "l": "Bayern"
        }
    ],
    "91245": [
        {
            "o": "Simmelsdorf",
            "l": "Bayern"
        }
    ],
    "91247": [
        {
            "o": "Vorra",
            "l": "Bayern"
        }
    ],
    "91249": [
        {
            "o": "Weigendorf",
            "l": "Bayern"
        }
    ],
    "91257": [
        {
            "o": "Pegnitz",
            "l": "Bayern"
        }
    ],
    "91275": [
        {
            "o": "Auerbach in der Oberpfalz",
            "l": "Bayern"
        }
    ],
    "91278": [
        {
            "o": "Pottenstein",
            "l": "Bayern"
        }
    ],
    "91281": [
        {
            "o": "Kirchenthumbach",
            "l": "Bayern"
        }
    ],
    "91282": [
        {
            "o": "Betzenstein",
            "l": "Bayern"
        }
    ],
    "91284": [
        {
            "o": "Neuhaus a.d.Pegnitz",
            "l": "Bayern"
        }
    ],
    "91286": [
        {
            "o": "Obertrubach",
            "l": "Bayern"
        }
    ],
    "91287": [
        {
            "o": "Plech",
            "l": "Bayern"
        }
    ],
    "91289": [
        {
            "o": "Schnabelwaid",
            "l": "Bayern"
        }
    ],
    "91301": [
        {
            "o": "Forchheim",
            "l": "Bayern"
        }
    ],
    "91315": [
        {
            "o": "Höchstadt a.d.Aisch",
            "l": "Bayern"
        }
    ],
    "91320": [
        {
            "o": "Ebermannstadt",
            "l": "Bayern"
        }
    ],
    "91322": [
        {
            "o": "Gräfenberg",
            "l": "Bayern"
        }
    ],
    "91325": [
        {
            "o": "Adelsdorf",
            "l": "Bayern"
        }
    ],
    "91327": [
        {
            "o": "Gößweinstein",
            "l": "Bayern"
        }
    ],
    "91330": [
        {
            "o": "Eggolsheim",
            "l": "Bayern"
        }
    ],
    "91332": [
        {
            "o": "Heiligenstadt",
            "l": "Bayern"
        }
    ],
    "91334": [
        {
            "o": "Hemhofen",
            "l": "Bayern"
        }
    ],
    "91336": [
        {
            "o": "Heroldsbach",
            "l": "Bayern"
        }
    ],
    "91338": [
        {
            "o": "Igensdorf",
            "l": "Bayern"
        }
    ],
    "91341": [
        {
            "o": "Röttenbach",
            "l": "Bayern"
        }
    ],
    "91344": [
        {
            "o": "Waischenfeld",
            "l": "Bayern"
        }
    ],
    "91346": [
        {
            "o": "Wiesenttal",
            "l": "Bayern"
        }
    ],
    "91347": [
        {
            "o": "Aufseß",
            "l": "Bayern"
        }
    ],
    "91349": [
        {
            "o": "Egloffstein",
            "l": "Bayern"
        }
    ],
    "91350": [
        {
            "o": "Gremsdorf",
            "l": "Bayern"
        }
    ],
    "91352": [
        {
            "o": "Hallerndorf",
            "l": "Bayern"
        }
    ],
    "91353": [
        {
            "o": "Hausen",
            "l": "Bayern"
        }
    ],
    "91355": [
        {
            "o": "Hiltpoltstein",
            "l": "Bayern"
        }
    ],
    "91356": [
        {
            "o": "Kirchehrenbach",
            "l": "Bayern"
        }
    ],
    "91358": [
        {
            "o": "Kunreuth",
            "l": "Bayern"
        }
    ],
    "91359": [
        {
            "o": "Leutenbach",
            "l": "Bayern"
        }
    ],
    "91361": [
        {
            "o": "Pinzberg",
            "l": "Bayern"
        }
    ],
    "91362": [
        {
            "o": "Pretzfeld",
            "l": "Bayern"
        }
    ],
    "91364": [
        {
            "o": "Unterleinleiter",
            "l": "Bayern"
        }
    ],
    "91365": [
        {
            "o": "Weilersbach",
            "l": "Bayern"
        }
    ],
    "91367": [
        {
            "o": "Weißenohe",
            "l": "Bayern"
        }
    ],
    "91369": [
        {
            "o": "Wiesenthau",
            "l": "Bayern"
        }
    ],
    "91413": [
        {
            "o": "Neustadt an der Aisch",
            "l": "Bayern"
        }
    ],
    "91438": [
        {
            "o": "Bad Windsheim",
            "l": "Bayern"
        },
        {
            "o": "Sugenheim",
            "l": "Bayern"
        }
    ],
    "91443": [
        {
            "o": "Scheinfeld",
            "l": "Bayern"
        }
    ],
    "91448": [
        {
            "o": "Emskirchen",
            "l": "Bayern"
        }
    ],
    "91452": [
        {
            "o": "Wilhermsdorf",
            "l": "Bayern"
        }
    ],
    "91456": [
        {
            "o": "Diespeck",
            "l": "Bayern"
        }
    ],
    "91459": [
        {
            "o": "Markt Erlbach",
            "l": "Bayern"
        }
    ],
    "91460": [
        {
            "o": "Baudenbach",
            "l": "Bayern"
        }
    ],
    "91462": [
        {
            "o": "Dachsbach",
            "l": "Bayern"
        }
    ],
    "91463": [
        {
            "o": "Dietersheim",
            "l": "Bayern"
        }
    ],
    "91465": [
        {
            "o": "Ergersheim",
            "l": "Bayern"
        }
    ],
    "91466": [
        {
            "o": "Gerhardshofen",
            "l": "Bayern"
        }
    ],
    "91468": [
        {
            "o": "Gutenstetten",
            "l": "Bayern"
        }
    ],
    "91469": [
        {
            "o": "Hagenbüchach",
            "l": "Bayern"
        }
    ],
    "91471": [
        {
            "o": "Illesheim",
            "l": "Bayern"
        }
    ],
    "91472": [
        {
            "o": "Ipsheim",
            "l": "Bayern"
        }
    ],
    "91474": [
        {
            "o": "Langenfeld",
            "l": "Bayern"
        }
    ],
    "91475": [
        {
            "o": "Lonnerstadt",
            "l": "Bayern"
        }
    ],
    "91477": [
        {
            "o": "Markt Bibart",
            "l": "Bayern"
        }
    ],
    "91478": [
        {
            "o": "Markt Nordheim",
            "l": "Bayern"
        }
    ],
    "91480": [
        {
            "o": "Markt Taschendorf",
            "l": "Bayern"
        }
    ],
    "91481": [
        {
            "o": "Münchsteinach",
            "l": "Bayern"
        }
    ],
    "91483": [
        {
            "o": "Oberscheinfeld",
            "l": "Bayern"
        }
    ],
    "91484": [
        {
            "o": "Sugenheim",
            "l": "Bayern"
        }
    ],
    "91486": [
        {
            "o": "Uehlfeld",
            "l": "Bayern"
        }
    ],
    "91487": [
        {
            "o": "Vestenbergsgreuth",
            "l": "Bayern"
        }
    ],
    "91489": [
        {
            "o": "Wilhelmsdorf",
            "l": "Bayern"
        }
    ],
    "91522": [
        {
            "o": "Ansbach",
            "l": "Bayern"
        }
    ],
    "91541": [
        {
            "o": "Rothenburg ob der Tauber",
            "l": "Bayern"
        }
    ],
    "91550": [
        {
            "o": "Dinkelsbühl",
            "l": "Bayern"
        }
    ],
    "91555": [
        {
            "o": "Feuchtwangen",
            "l": "Bayern"
        }
    ],
    "91560": [
        {
            "o": "Heilsbronn",
            "l": "Bayern"
        }
    ],
    "91564": [
        {
            "o": "Neuendettelsau",
            "l": "Bayern"
        }
    ],
    "91567": [
        {
            "o": "Herrieden",
            "l": "Bayern"
        }
    ],
    "91572": [
        {
            "o": "Bechhofen",
            "l": "Bayern"
        }
    ],
    "91575": [
        {
            "o": "Windsbach",
            "l": "Bayern"
        }
    ],
    "91578": [
        {
            "o": "Leutershausen",
            "l": "Bayern"
        }
    ],
    "91580": [
        {
            "o": "Petersaurach",
            "l": "Bayern"
        }
    ],
    "91583": [
        {
            "o": "Diebach",
            "l": "Bayern"
        },
        {
            "o": "Schillingsfürst",
            "l": "Bayern"
        }
    ],
    "91586": [
        {
            "o": "Lichtenau",
            "l": "Bayern"
        }
    ],
    "91587": [
        {
            "o": "Adelshofen",
            "l": "Bayern"
        }
    ],
    "91589": [
        {
            "o": "Aurach",
            "l": "Bayern"
        }
    ],
    "91590": [
        {
            "o": "Bruckberg",
            "l": "Bayern"
        }
    ],
    "91592": [
        {
            "o": "Buch am Wald",
            "l": "Bayern"
        }
    ],
    "91593": [
        {
            "o": "Burgbernheim",
            "l": "Bayern"
        }
    ],
    "91595": [
        {
            "o": "Burgoberbach",
            "l": "Bayern"
        }
    ],
    "91596": [
        {
            "o": "Burk",
            "l": "Bayern"
        }
    ],
    "91598": [
        {
            "o": "Colmberg",
            "l": "Bayern"
        }
    ],
    "91599": [
        {
            "o": "Dentlein am Forst",
            "l": "Bayern"
        }
    ],
    "91601": [
        {
            "o": "Dombühl",
            "l": "Bayern"
        }
    ],
    "91602": [
        {
            "o": "Dürrwangen",
            "l": "Bayern"
        }
    ],
    "91604": [
        {
            "o": "Flachslanden",
            "l": "Bayern"
        }
    ],
    "91605": [
        {
            "o": "Gallmersgarten",
            "l": "Bayern"
        }
    ],
    "91607": [
        {
            "o": "Gebsattel",
            "l": "Bayern"
        }
    ],
    "91608": [
        {
            "o": "Geslau",
            "l": "Bayern"
        }
    ],
    "91610": [
        {
            "o": "Insingen",
            "l": "Bayern"
        }
    ],
    "91611": [
        {
            "o": "Lehrberg",
            "l": "Bayern"
        }
    ],
    "91613": [
        {
            "o": "Marktbergel",
            "l": "Bayern"
        }
    ],
    "91614": [
        {
            "o": "Mönchsroth",
            "l": "Bayern"
        }
    ],
    "91616": [
        {
            "o": "Neusitz",
            "l": "Bayern"
        }
    ],
    "91617": [
        {
            "o": "Oberdachstetten",
            "l": "Bayern"
        }
    ],
    "91619": [
        {
            "o": "Obernzenn",
            "l": "Bayern"
        }
    ],
    "91620": [
        {
            "o": "Ohrenbach",
            "l": "Bayern"
        }
    ],
    "91622": [
        {
            "o": "Rügland",
            "l": "Bayern"
        }
    ],
    "91623": [
        {
            "o": "Sachsen bei Ansbach",
            "l": "Bayern"
        }
    ],
    "91625": [
        {
            "o": "Schnelldorf",
            "l": "Bayern"
        }
    ],
    "91626": [
        {
            "o": "Schopfloch",
            "l": "Bayern"
        }
    ],
    "91628": [
        {
            "o": "Steinsfeld",
            "l": "Bayern"
        }
    ],
    "91629": [
        {
            "o": "Weihenzell",
            "l": "Bayern"
        }
    ],
    "91631": [
        {
            "o": "Wettringen",
            "l": "Bayern"
        }
    ],
    "91632": [
        {
            "o": "Wieseth",
            "l": "Bayern"
        }
    ],
    "91634": [
        {
            "o": "Wilburgstetten",
            "l": "Bayern"
        }
    ],
    "91635": [
        {
            "o": "Windelsbach",
            "l": "Bayern"
        }
    ],
    "91637": [
        {
            "o": "Wörnitz",
            "l": "Bayern"
        }
    ],
    "91639": [
        {
            "o": "Wolframs-Eschenbach",
            "l": "Bayern"
        }
    ],
    "91710": [
        {
            "o": "Gunzenhausen",
            "l": "Bayern"
        }
    ],
    "91717": [
        {
            "o": "Wassertrüdingen",
            "l": "Bayern"
        }
    ],
    "91719": [
        {
            "o": "Heidenheim",
            "l": "Bayern"
        }
    ],
    "91720": [
        {
            "o": "Absberg",
            "l": "Bayern"
        }
    ],
    "91722": [
        {
            "o": "Arberg",
            "l": "Bayern"
        }
    ],
    "91723": [
        {
            "o": "Dittenheim",
            "l": "Bayern"
        }
    ],
    "91725": [
        {
            "o": "Ehingen",
            "l": "Bayern"
        }
    ],
    "91726": [
        {
            "o": "Gerolfingen",
            "l": "Bayern"
        }
    ],
    "91728": [
        {
            "o": "Gnotzheim",
            "l": "Bayern"
        }
    ],
    "91729": [
        {
            "o": "Haundorf",
            "l": "Bayern"
        }
    ],
    "91731": [
        {
            "o": "Langfurth",
            "l": "Bayern"
        }
    ],
    "91732": [
        {
            "o": "Merkendorf",
            "l": "Bayern"
        }
    ],
    "91734": [
        {
            "o": "Mitteleschenbach",
            "l": "Bayern"
        }
    ],
    "91735": [
        {
            "o": "Muhr am See",
            "l": "Bayern"
        }
    ],
    "91737": [
        {
            "o": "Ornbau",
            "l": "Bayern"
        }
    ],
    "91738": [
        {
            "o": "Pfofeld",
            "l": "Bayern"
        }
    ],
    "91740": [
        {
            "o": "Röckingen",
            "l": "Bayern"
        }
    ],
    "91741": [
        {
            "o": "Theilenhofen",
            "l": "Bayern"
        }
    ],
    "91743": [
        {
            "o": "Unterschwaningen",
            "l": "Bayern"
        }
    ],
    "91744": [
        {
            "o": "Weiltingen",
            "l": "Bayern"
        }
    ],
    "91746": [
        {
            "o": "Weidenbach",
            "l": "Bayern"
        }
    ],
    "91747": [
        {
            "o": "Westheim",
            "l": "Bayern"
        }
    ],
    "91749": [
        {
            "o": "Wittelshofen",
            "l": "Bayern"
        }
    ],
    "91757": [
        {
            "o": "Treuchtlingen",
            "l": "Bayern"
        }
    ],
    "91781": [
        {
            "o": "Weißenburg i. Bay.",
            "l": "Bayern"
        }
    ],
    "91785": [
        {
            "o": "Pleinfeld",
            "l": "Bayern"
        }
    ],
    "91788": [
        {
            "o": "Pappenheim",
            "l": "Bayern"
        }
    ],
    "91790": [
        {
            "o": "Bergen",
            "l": "Bayern"
        },
        {
            "o": "Burgsalach",
            "l": "Bayern"
        },
        {
            "o": "Nennslingen",
            "l": "Bayern"
        },
        {
            "o": "Raitenbuch",
            "l": "Bayern"
        }
    ],
    "91792": [
        {
            "o": "Ellingen",
            "l": "Bayern"
        }
    ],
    "91793": [
        {
            "o": "Alesheim",
            "l": "Bayern"
        }
    ],
    "91795": [
        {
            "o": "Dollnstein",
            "l": "Bayern"
        }
    ],
    "91796": [
        {
            "o": "Ettenstatt",
            "l": "Bayern"
        }
    ],
    "91798": [
        {
            "o": "Höttingen",
            "l": "Bayern"
        }
    ],
    "91799": [
        {
            "o": "Langenaltheim",
            "l": "Bayern"
        }
    ],
    "91801": [
        {
            "o": "Markt Berolzheim",
            "l": "Bayern"
        }
    ],
    "91802": [
        {
            "o": "Meinheim",
            "l": "Bayern"
        }
    ],
    "91804": [
        {
            "o": "Mörnsheim",
            "l": "Bayern"
        }
    ],
    "91805": [
        {
            "o": "Polsingen",
            "l": "Bayern"
        }
    ],
    "91807": [
        {
            "o": "Solnhofen",
            "l": "Bayern"
        }
    ],
    "91809": [
        {
            "o": "Wellheim",
            "l": "Bayern"
        }
    ],
    "92224": [
        {
            "o": "Amberg",
            "l": "Bayern"
        }
    ],
    "92237": [
        {
            "o": "Sulzbach-Rosenberg",
            "l": "Bayern"
        }
    ],
    "92242": [
        {
            "o": "Hirschau",
            "l": "Bayern"
        }
    ],
    "92245": [
        {
            "o": "Kümmersbruck",
            "l": "Bayern"
        }
    ],
    "92249": [
        {
            "o": "Vilseck",
            "l": "Bayern"
        }
    ],
    "92253": [
        {
            "o": "Schnaittenbach",
            "l": "Bayern"
        }
    ],
    "92256": [
        {
            "o": "Hahnbach",
            "l": "Bayern"
        }
    ],
    "92259": [
        {
            "o": "Neukirchen bei Sulzbach-Rosenberg",
            "l": "Bayern"
        }
    ],
    "92260": [
        {
            "o": "Ammerthal",
            "l": "Bayern"
        }
    ],
    "92262": [
        {
            "o": "Birgland",
            "l": "Bayern"
        }
    ],
    "92263": [
        {
            "o": "Ebermannsdorf",
            "l": "Bayern"
        }
    ],
    "92265": [
        {
            "o": "Edelsfeld",
            "l": "Bayern"
        }
    ],
    "92266": [
        {
            "o": "Ensdorf",
            "l": "Bayern"
        }
    ],
    "92268": [
        {
            "o": "Etzelwang",
            "l": "Bayern"
        }
    ],
    "92269": [
        {
            "o": "Fensterbach",
            "l": "Bayern"
        }
    ],
    "92271": [
        {
            "o": "Freihung",
            "l": "Bayern"
        }
    ],
    "92272": [
        {
            "o": "Freudenberg",
            "l": "Bayern"
        }
    ],
    "92274": [
        {
            "o": "Gebenbach",
            "l": "Bayern"
        }
    ],
    "92275": [
        {
            "o": "Hirschbach",
            "l": "Bayern"
        }
    ],
    "92277": [
        {
            "o": "Hohenburg",
            "l": "Bayern"
        }
    ],
    "92278": [
        {
            "o": "Illschwang",
            "l": "Bayern"
        }
    ],
    "92280": [
        {
            "o": "Kastl",
            "l": "Bayern"
        }
    ],
    "92281": [
        {
            "o": "Königstein",
            "l": "Bayern"
        }
    ],
    "92283": [
        {
            "o": "Lauterhofen",
            "l": "Bayern"
        }
    ],
    "92284": [
        {
            "o": "Poppenricht",
            "l": "Bayern"
        }
    ],
    "92286": [
        {
            "o": "Rieden",
            "l": "Bayern"
        }
    ],
    "92287": [
        {
            "o": "Schmidmühlen",
            "l": "Bayern"
        }
    ],
    "92289": [
        {
            "o": "Ursensollen",
            "l": "Bayern"
        }
    ],
    "92318": [
        {
            "o": "Neumarkt i.d.OPf.",
            "l": "Bayern"
        }
    ],
    "92331": [
        {
            "o": "Lupburg",
            "l": "Bayern"
        },
        {
            "o": "Parsberg",
            "l": "Bayern"
        }
    ],
    "92334": [
        {
            "o": "Berching",
            "l": "Bayern"
        }
    ],
    "92339": [
        {
            "o": "Beilngries",
            "l": "Bayern"
        }
    ],
    "92342": [
        {
            "o": "Freystadt",
            "l": "Bayern"
        }
    ],
    "92345": [
        {
            "o": "Dietfurt an der Altmühl",
            "l": "Bayern"
        }
    ],
    "92348": [
        {
            "o": "Berg b.Neumarkt i.d.OPf.",
            "l": "Bayern"
        },
        {
            "o": "Neumarkt i.d.OPf.",
            "l": "Bayern"
        }
    ],
    "92353": [
        {
            "o": "Postbauer-Heng",
            "l": "Bayern"
        }
    ],
    "92355": [
        {
            "o": "Velburg",
            "l": "Bayern"
        }
    ],
    "92358": [
        {
            "o": "Seubersdorf in der Oberpfalz",
            "l": "Bayern"
        }
    ],
    "92360": [
        {
            "o": "Mühlhausen",
            "l": "Bayern"
        }
    ],
    "92361": [
        {
            "o": "Berngau",
            "l": "Bayern"
        }
    ],
    "92363": [
        {
            "o": "Breitenbrunn",
            "l": "Bayern"
        }
    ],
    "92364": [
        {
            "o": "Deining",
            "l": "Bayern"
        }
    ],
    "92366": [
        {
            "o": "Hohenfels",
            "l": "Bayern"
        }
    ],
    "92367": [
        {
            "o": "Pilsach",
            "l": "Bayern"
        }
    ],
    "92369": [
        {
            "o": "Sengenthal",
            "l": "Bayern"
        }
    ],
    "92421": [
        {
            "o": "Schwandorf",
            "l": "Bayern"
        }
    ],
    "92431": [
        {
            "o": "Neunburg vorm Wald",
            "l": "Bayern"
        }
    ],
    "92436": [
        {
            "o": "Bruck i.d.OPf.",
            "l": "Bayern"
        }
    ],
    "92439": [
        {
            "o": "Bodenwöhr",
            "l": "Bayern"
        }
    ],
    "92442": [
        {
            "o": "Wackersdorf",
            "l": "Bayern"
        }
    ],
    "92444": [
        {
            "o": "Rötz",
            "l": "Bayern"
        }
    ],
    "92445": [
        {
            "o": "Neukirchen-Balbini",
            "l": "Bayern"
        }
    ],
    "92447": [
        {
            "o": "Schwarzhofen",
            "l": "Bayern"
        }
    ],
    "92449": [
        {
            "o": "Steinberg am See",
            "l": "Bayern"
        }
    ],
    "92507": [
        {
            "o": "Nabburg",
            "l": "Bayern"
        }
    ],
    "92521": [
        {
            "o": "Schwarzenfeld",
            "l": "Bayern"
        }
    ],
    "92526": [
        {
            "o": "Oberviechtach",
            "l": "Bayern"
        }
    ],
    "92533": [
        {
            "o": "Wernberg-Köblitz",
            "l": "Bayern"
        }
    ],
    "92536": [
        {
            "o": "Pfreimd",
            "l": "Bayern"
        }
    ],
    "92539": [
        {
            "o": "Schönsee",
            "l": "Bayern"
        }
    ],
    "92540": [
        {
            "o": "Altendorf",
            "l": "Bayern"
        }
    ],
    "92542": [
        {
            "o": "Dieterskirchen",
            "l": "Bayern"
        }
    ],
    "92543": [
        {
            "o": "Guteneck",
            "l": "Bayern"
        }
    ],
    "92545": [
        {
            "o": "Niedermurach",
            "l": "Bayern"
        }
    ],
    "92546": [
        {
            "o": "Schmidgaden",
            "l": "Bayern"
        }
    ],
    "92548": [
        {
            "o": "Schwarzach b.Nabburg",
            "l": "Bayern"
        }
    ],
    "92549": [
        {
            "o": "Stadlern",
            "l": "Bayern"
        }
    ],
    "92551": [
        {
            "o": "Stulln",
            "l": "Bayern"
        }
    ],
    "92552": [
        {
            "o": "Teunz",
            "l": "Bayern"
        }
    ],
    "92554": [
        {
            "o": "Thanstein",
            "l": "Bayern"
        }
    ],
    "92555": [
        {
            "o": "Trausnitz",
            "l": "Bayern"
        }
    ],
    "92557": [
        {
            "o": "Weiding",
            "l": "Bayern"
        }
    ],
    "92559": [
        {
            "o": "Winklarn",
            "l": "Bayern"
        }
    ],
    "92637": [
        {
            "o": "Theisseil",
            "l": "Bayern"
        },
        {
            "o": "Weiden in der Oberpfalz",
            "l": "Bayern"
        }
    ],
    "92648": [
        {
            "o": "Vohenstrauß",
            "l": "Bayern"
        }
    ],
    "92655": [
        {
            "o": "Grafenwöhr",
            "l": "Bayern"
        }
    ],
    "92660": [
        {
            "o": "Neustadt an der Waldnaab",
            "l": "Bayern"
        }
    ],
    "92665": [
        {
            "o": "Altenstadt an der Waldnaab",
            "l": "Bayern"
        },
        {
            "o": "Kirchendemenreuth",
            "l": "Bayern"
        }
    ],
    "92670": [
        {
            "o": "Windischeschenbach",
            "l": "Bayern"
        }
    ],
    "92676": [
        {
            "o": "Eschenbach i.d.OPf.",
            "l": "Bayern"
        },
        {
            "o": "Speinshart",
            "l": "Bayern"
        }
    ],
    "92681": [
        {
            "o": "Erbendorf",
            "l": "Bayern"
        }
    ],
    "92685": [
        {
            "o": "Floß",
            "l": "Bayern"
        }
    ],
    "92690": [
        {
            "o": "Pressath",
            "l": "Bayern"
        }
    ],
    "92693": [
        {
            "o": "Eslarn",
            "l": "Bayern"
        }
    ],
    "92694": [
        {
            "o": "Etzenricht",
            "l": "Bayern"
        }
    ],
    "92696": [
        {
            "o": "Flossenbürg",
            "l": "Bayern"
        }
    ],
    "92697": [
        {
            "o": "Georgenberg",
            "l": "Bayern"
        }
    ],
    "92699": [
        {
            "o": "Bechtsrieth",
            "l": "Bayern"
        },
        {
            "o": "Irchenrieth",
            "l": "Bayern"
        }
    ],
    "92700": [
        {
            "o": "Weiherhammer",
            "l": "Bayern"
        }
    ],
    "92702": [
        {
            "o": "Kohlberg",
            "l": "Bayern"
        }
    ],
    "92703": [
        {
            "o": "Krummennaab",
            "l": "Bayern"
        }
    ],
    "92705": [
        {
            "o": "Leuchtenberg",
            "l": "Bayern"
        }
    ],
    "92706": [
        {
            "o": "Luhe-Wildenau",
            "l": "Bayern"
        }
    ],
    "92708": [
        {
            "o": "Mantel",
            "l": "Bayern"
        }
    ],
    "92709": [
        {
            "o": "Moosbach",
            "l": "Bayern"
        }
    ],
    "92711": [
        {
            "o": "Parkstein",
            "l": "Bayern"
        }
    ],
    "92712": [
        {
            "o": "Pirk",
            "l": "Bayern"
        }
    ],
    "92714": [
        {
            "o": "Pleystein",
            "l": "Bayern"
        }
    ],
    "92715": [
        {
            "o": "Püchersreuth",
            "l": "Bayern"
        }
    ],
    "92717": [
        {
            "o": "Reuth b.Erbendorf",
            "l": "Bayern"
        }
    ],
    "92718": [
        {
            "o": "Schirmitz",
            "l": "Bayern"
        }
    ],
    "92720": [
        {
            "o": "Schwarzenbach",
            "l": "Bayern"
        }
    ],
    "92721": [
        {
            "o": "Störnstein",
            "l": "Bayern"
        }
    ],
    "92723": [
        {
            "o": "Gleiritsch",
            "l": "Bayern"
        },
        {
            "o": "Tännesberg",
            "l": "Bayern"
        }
    ],
    "92724": [
        {
            "o": "Trabitz",
            "l": "Bayern"
        }
    ],
    "92726": [
        {
            "o": "Waidhaus",
            "l": "Bayern"
        }
    ],
    "92727": [
        {
            "o": "Waldthurn",
            "l": "Bayern"
        }
    ],
    "92729": [
        {
            "o": "Weiherhammer",
            "l": "Bayern"
        }
    ],
    "93047": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93049": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93051": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93053": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93055": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93057": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93059": [
        {
            "o": "Regensburg",
            "l": "Bayern"
        }
    ],
    "93073": [
        {
            "o": "Neutraubling",
            "l": "Bayern"
        }
    ],
    "93077": [
        {
            "o": "Bad Abbach",
            "l": "Bayern"
        }
    ],
    "93080": [
        {
            "o": "Pentling",
            "l": "Bayern"
        }
    ],
    "93083": [
        {
            "o": "Obertraubling",
            "l": "Bayern"
        }
    ],
    "93086": [
        {
            "o": "Wörth an der Donau",
            "l": "Bayern"
        }
    ],
    "93087": [
        {
            "o": "Alteglofsheim",
            "l": "Bayern"
        }
    ],
    "93089": [
        {
            "o": "Aufhausen",
            "l": "Bayern"
        }
    ],
    "93090": [
        {
            "o": "Bach an der Donau",
            "l": "Bayern"
        }
    ],
    "93092": [
        {
            "o": "Barbing",
            "l": "Bayern"
        }
    ],
    "93093": [
        {
            "o": "Donaustauf",
            "l": "Bayern"
        }
    ],
    "93095": [
        {
            "o": "Hagelstadt",
            "l": "Bayern"
        }
    ],
    "93096": [
        {
            "o": "Köfering",
            "l": "Bayern"
        }
    ],
    "93098": [
        {
            "o": "Mintraching",
            "l": "Bayern"
        }
    ],
    "93099": [
        {
            "o": "Mötzing",
            "l": "Bayern"
        }
    ],
    "93101": [
        {
            "o": "Pfakofen",
            "l": "Bayern"
        }
    ],
    "93102": [
        {
            "o": "Pfatter",
            "l": "Bayern"
        }
    ],
    "93104": [
        {
            "o": "Riekofen",
            "l": "Bayern"
        },
        {
            "o": "Sünching",
            "l": "Bayern"
        }
    ],
    "93105": [
        {
            "o": "Tegernheim",
            "l": "Bayern"
        }
    ],
    "93107": [
        {
            "o": "Thalmassing",
            "l": "Bayern"
        }
    ],
    "93109": [
        {
            "o": "Wiesent",
            "l": "Bayern"
        }
    ],
    "93128": [
        {
            "o": "Regenstauf",
            "l": "Bayern"
        }
    ],
    "93133": [
        {
            "o": "Burglengenfeld",
            "l": "Bayern"
        }
    ],
    "93138": [
        {
            "o": "Lappersdorf",
            "l": "Bayern"
        }
    ],
    "93142": [
        {
            "o": "Maxhütte-Haidhof",
            "l": "Bayern"
        }
    ],
    "93149": [
        {
            "o": "Nittenau",
            "l": "Bayern"
        }
    ],
    "93152": [
        {
            "o": "Nittendorf",
            "l": "Bayern"
        }
    ],
    "93155": [
        {
            "o": "Hemau",
            "l": "Bayern"
        }
    ],
    "93158": [
        {
            "o": "Teublitz",
            "l": "Bayern"
        }
    ],
    "93161": [
        {
            "o": "Sinzing",
            "l": "Bayern"
        }
    ],
    "93164": [
        {
            "o": "Brunn",
            "l": "Bayern"
        },
        {
            "o": "Laaber",
            "l": "Bayern"
        }
    ],
    "93167": [
        {
            "o": "Falkenstein",
            "l": "Bayern"
        }
    ],
    "93170": [
        {
            "o": "Bernhardswald",
            "l": "Bayern"
        }
    ],
    "93173": [
        {
            "o": "Wenzenbach",
            "l": "Bayern"
        }
    ],
    "93176": [
        {
            "o": "Beratzhausen",
            "l": "Bayern"
        }
    ],
    "93177": [
        {
            "o": "Altenthann",
            "l": "Bayern"
        }
    ],
    "93179": [
        {
            "o": "Brennberg",
            "l": "Bayern"
        }
    ],
    "93180": [
        {
            "o": "Deuerling",
            "l": "Bayern"
        }
    ],
    "93182": [
        {
            "o": "Duggendorf",
            "l": "Bayern"
        }
    ],
    "93183": [
        {
            "o": "Holzheim a.Forst",
            "l": "Bayern"
        },
        {
            "o": "Kallmünz",
            "l": "Bayern"
        }
    ],
    "93185": [
        {
            "o": "Michelsneukirchen",
            "l": "Bayern"
        }
    ],
    "93186": [
        {
            "o": "Pettendorf",
            "l": "Bayern"
        }
    ],
    "93188": [
        {
            "o": "Pielenhofen",
            "l": "Bayern"
        }
    ],
    "93189": [
        {
            "o": "Reichenbach",
            "l": "Bayern"
        }
    ],
    "93191": [
        {
            "o": "Rettenbach",
            "l": "Bayern"
        }
    ],
    "93192": [
        {
            "o": "Wald",
            "l": "Bayern"
        }
    ],
    "93194": [
        {
            "o": "Walderbach",
            "l": "Bayern"
        }
    ],
    "93195": [
        {
            "o": "Duggendorf",
            "l": "Bayern"
        },
        {
            "o": "Wolfsegg",
            "l": "Bayern"
        }
    ],
    "93197": [
        {
            "o": "Zeitlarn",
            "l": "Bayern"
        }
    ],
    "93199": [
        {
            "o": "Zell",
            "l": "Bayern"
        }
    ],
    "93309": [
        {
            "o": "Kelheim",
            "l": "Bayern"
        }
    ],
    "93326": [
        {
            "o": "Abensberg",
            "l": "Bayern"
        }
    ],
    "93333": [
        {
            "o": "Neustadt a.d.Donau",
            "l": "Bayern"
        }
    ],
    "93336": [
        {
            "o": "Altmannstein",
            "l": "Bayern"
        }
    ],
    "93339": [
        {
            "o": "Riedenburg",
            "l": "Bayern"
        }
    ],
    "93342": [
        {
            "o": "Saal a.d.Donau",
            "l": "Bayern"
        }
    ],
    "93343": [
        {
            "o": "Essing",
            "l": "Bayern"
        }
    ],
    "93345": [
        {
            "o": "Hausen",
            "l": "Bayern"
        }
    ],
    "93346": [
        {
            "o": "Ihrlerstein",
            "l": "Bayern"
        }
    ],
    "93348": [
        {
            "o": "Kirchdorf",
            "l": "Bayern"
        }
    ],
    "93349": [
        {
            "o": "Mindelstetten",
            "l": "Bayern"
        }
    ],
    "93351": [
        {
            "o": "Painten",
            "l": "Bayern"
        }
    ],
    "93352": [
        {
            "o": "Rohr i.NB",
            "l": "Bayern"
        }
    ],
    "93354": [
        {
            "o": "Biburg",
            "l": "Bayern"
        },
        {
            "o": "Siegenburg",
            "l": "Bayern"
        }
    ],
    "93356": [
        {
            "o": "Teugn",
            "l": "Bayern"
        }
    ],
    "93358": [
        {
            "o": "Train",
            "l": "Bayern"
        }
    ],
    "93359": [
        {
            "o": "Wildenberg",
            "l": "Bayern"
        }
    ],
    "93413": [
        {
            "o": "Cham",
            "l": "Bayern"
        }
    ],
    "93426": [
        {
            "o": "Roding",
            "l": "Bayern"
        }
    ],
    "93437": [
        {
            "o": "Furth im Wald",
            "l": "Bayern"
        }
    ],
    "93444": [
        {
            "o": "Bad Kötzting",
            "l": "Bayern"
        }
    ],
    "93449": [
        {
            "o": "Waldmünchen",
            "l": "Bayern"
        }
    ],
    "93453": [
        {
            "o": "Neukirchen beim Heiligen Blut",
            "l": "Bayern"
        }
    ],
    "93455": [
        {
            "o": "Traitsching",
            "l": "Bayern"
        }
    ],
    "93458": [
        {
            "o": "Eschlkam",
            "l": "Bayern"
        }
    ],
    "93462": [
        {
            "o": "Lam",
            "l": "Bayern"
        }
    ],
    "93464": [
        {
            "o": "Tiefenbach",
            "l": "Bayern"
        }
    ],
    "93466": [
        {
            "o": "Chamerau",
            "l": "Bayern"
        }
    ],
    "93468": [
        {
            "o": "Miltach",
            "l": "Bayern"
        }
    ],
    "93470": [
        {
            "o": "Lohberg",
            "l": "Bayern"
        }
    ],
    "93471": [
        {
            "o": "Arnbruck",
            "l": "Bayern"
        }
    ],
    "93473": [
        {
            "o": "Arnschwang",
            "l": "Bayern"
        }
    ],
    "93474": [
        {
            "o": "Arrach",
            "l": "Bayern"
        }
    ],
    "93476": [
        {
            "o": "Blaibach",
            "l": "Bayern"
        }
    ],
    "93477": [
        {
            "o": "Gleißenberg",
            "l": "Bayern"
        }
    ],
    "93479": [
        {
            "o": "Grafenwiesen",
            "l": "Bayern"
        }
    ],
    "93480": [
        {
            "o": "Hohenwarth",
            "l": "Bayern"
        }
    ],
    "93482": [
        {
            "o": "Pemfling",
            "l": "Bayern"
        }
    ],
    "93483": [
        {
            "o": "Pösing",
            "l": "Bayern"
        }
    ],
    "93485": [
        {
            "o": "Rimbach",
            "l": "Bayern"
        }
    ],
    "93486": [
        {
            "o": "Runding",
            "l": "Bayern"
        }
    ],
    "93488": [
        {
            "o": "Schönthal",
            "l": "Bayern"
        }
    ],
    "93489": [
        {
            "o": "Schorndorf",
            "l": "Bayern"
        }
    ],
    "93491": [
        {
            "o": "Stamsried",
            "l": "Bayern"
        }
    ],
    "93492": [
        {
            "o": "Treffelstein",
            "l": "Bayern"
        }
    ],
    "93494": [
        {
            "o": "Waffenbrunn",
            "l": "Bayern"
        }
    ],
    "93495": [
        {
            "o": "Weiding",
            "l": "Bayern"
        }
    ],
    "93497": [
        {
            "o": "Willmering",
            "l": "Bayern"
        }
    ],
    "93499": [
        {
            "o": "Zandt",
            "l": "Bayern"
        }
    ],
    "94032": [
        {
            "o": "Passau",
            "l": "Bayern"
        }
    ],
    "94034": [
        {
            "o": "Passau",
            "l": "Bayern"
        }
    ],
    "94036": [
        {
            "o": "Neuburg a.Inn",
            "l": "Bayern"
        },
        {
            "o": "Passau",
            "l": "Bayern"
        }
    ],
    "94051": [
        {
            "o": "Hauzenberg",
            "l": "Bayern"
        }
    ],
    "94060": [
        {
            "o": "Pocking",
            "l": "Bayern"
        }
    ],
    "94065": [
        {
            "o": "Waldkirchen",
            "l": "Bayern"
        }
    ],
    "94072": [
        {
            "o": "Bad Füssing",
            "l": "Bayern"
        }
    ],
    "94078": [
        {
            "o": "Freyung",
            "l": "Bayern"
        }
    ],
    "94081": [
        {
            "o": "Fürstenzell",
            "l": "Bayern"
        }
    ],
    "94086": [
        {
            "o": "Bad Griesbach i.Rottal",
            "l": "Bayern"
        }
    ],
    "94089": [
        {
            "o": "Neureichenau",
            "l": "Bayern"
        }
    ],
    "94094": [
        {
            "o": "Malching",
            "l": "Bayern"
        },
        {
            "o": "Rotthalmünster",
            "l": "Bayern"
        }
    ],
    "94099": [
        {
            "o": "Ruhstorf a.d.Rott",
            "l": "Bayern"
        }
    ],
    "94104": [
        {
            "o": "Tittling",
            "l": "Bayern"
        },
        {
            "o": "Witzmannsberg",
            "l": "Bayern"
        }
    ],
    "94107": [
        {
            "o": "Obernzell",
            "l": "Bayern"
        },
        {
            "o": "Untergriesbach",
            "l": "Bayern"
        }
    ],
    "94110": [
        {
            "o": "Wegscheid",
            "l": "Bayern"
        }
    ],
    "94113": [
        {
            "o": "Tiefenbach",
            "l": "Bayern"
        }
    ],
    "94116": [
        {
            "o": "Hutthurm",
            "l": "Bayern"
        }
    ],
    "94118": [
        {
            "o": "Jandelsbrunn",
            "l": "Bayern"
        }
    ],
    "94121": [
        {
            "o": "Salzweg",
            "l": "Bayern"
        }
    ],
    "94124": [
        {
            "o": "Büchlberg",
            "l": "Bayern"
        }
    ],
    "94127": [
        {
            "o": "Neuburg a.Inn",
            "l": "Bayern"
        }
    ],
    "94130": [
        {
            "o": "Obernzell",
            "l": "Bayern"
        }
    ],
    "94133": [
        {
            "o": "Röhrnbach",
            "l": "Bayern"
        }
    ],
    "94136": [
        {
            "o": "Thyrnau",
            "l": "Bayern"
        }
    ],
    "94137": [
        {
            "o": "Bayerbach",
            "l": "Bayern"
        }
    ],
    "94139": [
        {
            "o": "Breitenberg",
            "l": "Bayern"
        }
    ],
    "94140": [
        {
            "o": "Ering",
            "l": "Bayern"
        }
    ],
    "94142": [
        {
            "o": "Fürsteneck",
            "l": "Bayern"
        }
    ],
    "94143": [
        {
            "o": "Grainet",
            "l": "Bayern"
        }
    ],
    "94145": [
        {
            "o": "Haidmühle",
            "l": "Bayern"
        }
    ],
    "94146": [
        {
            "o": "Hinterschmiding",
            "l": "Bayern"
        }
    ],
    "94148": [
        {
            "o": "Kirchham",
            "l": "Bayern"
        }
    ],
    "94149": [
        {
            "o": "Kößlarn",
            "l": "Bayern"
        }
    ],
    "94151": [
        {
            "o": "Mauth",
            "l": "Bayern"
        }
    ],
    "94152": [
        {
            "o": "Neuhaus am Inn",
            "l": "Bayern"
        }
    ],
    "94154": [
        {
            "o": "Neukirchen vorm Wald",
            "l": "Bayern"
        }
    ],
    "94157": [
        {
            "o": "Perlesreut",
            "l": "Bayern"
        }
    ],
    "94158": [
        {
            "o": "Philippsreut",
            "l": "Bayern"
        }
    ],
    "94160": [
        {
            "o": "Ringelai",
            "l": "Bayern"
        }
    ],
    "94161": [
        {
            "o": "Ruderting",
            "l": "Bayern"
        }
    ],
    "94163": [
        {
            "o": "Saldenburg",
            "l": "Bayern"
        }
    ],
    "94164": [
        {
            "o": "Sonnen",
            "l": "Bayern"
        }
    ],
    "94166": [
        {
            "o": "Stubenberg",
            "l": "Bayern"
        }
    ],
    "94167": [
        {
            "o": "Tettenweis",
            "l": "Bayern"
        }
    ],
    "94169": [
        {
            "o": "Thurmansbang",
            "l": "Bayern"
        }
    ],
    "94209": [
        {
            "o": "Regen",
            "l": "Bayern"
        }
    ],
    "94227": [
        {
            "o": "Lindberg",
            "l": "Bayern"
        },
        {
            "o": "Zwiesel",
            "l": "Bayern"
        }
    ],
    "94234": [
        {
            "o": "Viechtach",
            "l": "Bayern"
        }
    ],
    "94239": [
        {
            "o": "Gotteszell",
            "l": "Bayern"
        },
        {
            "o": "Ruhmannsfelden",
            "l": "Bayern"
        },
        {
            "o": "Zachenberg",
            "l": "Bayern"
        }
    ],
    "94244": [
        {
            "o": "Geiersthal",
            "l": "Bayern"
        },
        {
            "o": "Teisnach",
            "l": "Bayern"
        }
    ],
    "94249": [
        {
            "o": "Bodenmais",
            "l": "Bayern"
        }
    ],
    "94250": [
        {
            "o": "Achslach",
            "l": "Bayern"
        }
    ],
    "94252": [
        {
            "o": "Bayerisch Eisenstein",
            "l": "Bayern"
        }
    ],
    "94253": [
        {
            "o": "Bischofsmais",
            "l": "Bayern"
        }
    ],
    "94255": [
        {
            "o": "Böbrach",
            "l": "Bayern"
        }
    ],
    "94256": [
        {
            "o": "Drachselsried",
            "l": "Bayern"
        }
    ],
    "94258": [
        {
            "o": "Frauenau",
            "l": "Bayern"
        }
    ],
    "94259": [
        {
            "o": "Kirchberg i.Wald",
            "l": "Bayern"
        }
    ],
    "94261": [
        {
            "o": "Kirchdorf i.Wald",
            "l": "Bayern"
        }
    ],
    "94262": [
        {
            "o": "Kollnburg",
            "l": "Bayern"
        }
    ],
    "94264": [
        {
            "o": "Langdorf",
            "l": "Bayern"
        }
    ],
    "94265": [
        {
            "o": "Patersdorf",
            "l": "Bayern"
        }
    ],
    "94267": [
        {
            "o": "Prackenbach",
            "l": "Bayern"
        }
    ],
    "94269": [
        {
            "o": "Rinchnach",
            "l": "Bayern"
        }
    ],
    "94315": [
        {
            "o": "Straubing",
            "l": "Bayern"
        }
    ],
    "94327": [
        {
            "o": "Bogen",
            "l": "Bayern"
        }
    ],
    "94330": [
        {
            "o": "Aiterhofen",
            "l": "Bayern"
        },
        {
            "o": "Salching",
            "l": "Bayern"
        }
    ],
    "94333": [
        {
            "o": "Geiselhöring",
            "l": "Bayern"
        }
    ],
    "94336": [
        {
            "o": "Hunderdorf",
            "l": "Bayern"
        },
        {
            "o": "Windberg",
            "l": "Bayern"
        }
    ],
    "94339": [
        {
            "o": "Leiblfing",
            "l": "Bayern"
        }
    ],
    "94342": [
        {
            "o": "Irlbach",
            "l": "Bayern"
        },
        {
            "o": "Straßkirchen",
            "l": "Bayern"
        }
    ],
    "94344": [
        {
            "o": "Wiesenfelden",
            "l": "Bayern"
        }
    ],
    "94345": [
        {
            "o": "Aholfing",
            "l": "Bayern"
        }
    ],
    "94347": [
        {
            "o": "Ascha",
            "l": "Bayern"
        }
    ],
    "94348": [
        {
            "o": "Atting",
            "l": "Bayern"
        }
    ],
    "94350": [
        {
            "o": "Falkenfels",
            "l": "Bayern"
        }
    ],
    "94351": [
        {
            "o": "Feldkirchen",
            "l": "Bayern"
        }
    ],
    "94353": [
        {
            "o": "Haibach",
            "l": "Bayern"
        }
    ],
    "94354": [
        {
            "o": "Haselbach",
            "l": "Bayern"
        }
    ],
    "94356": [
        {
            "o": "Kirchroth",
            "l": "Bayern"
        }
    ],
    "94357": [
        {
            "o": "Konzell",
            "l": "Bayern"
        }
    ],
    "94359": [
        {
            "o": "Loitzendorf",
            "l": "Bayern"
        }
    ],
    "94360": [
        {
            "o": "Mitterfels",
            "l": "Bayern"
        }
    ],
    "94362": [
        {
            "o": "Neukirchen",
            "l": "Bayern"
        }
    ],
    "94363": [
        {
            "o": "Oberschneiding",
            "l": "Bayern"
        }
    ],
    "94365": [
        {
            "o": "Parkstetten",
            "l": "Bayern"
        }
    ],
    "94366": [
        {
            "o": "Perasdorf",
            "l": "Bayern"
        }
    ],
    "94368": [
        {
            "o": "Perkam",
            "l": "Bayern"
        }
    ],
    "94369": [
        {
            "o": "Rain",
            "l": "Bayern"
        }
    ],
    "94371": [
        {
            "o": "Rattenberg",
            "l": "Bayern"
        }
    ],
    "94372": [
        {
            "o": "Rattiszell",
            "l": "Bayern"
        }
    ],
    "94374": [
        {
            "o": "Schwarzach",
            "l": "Bayern"
        }
    ],
    "94375": [
        {
            "o": "Stallwang",
            "l": "Bayern"
        }
    ],
    "94377": [
        {
            "o": "Steinach",
            "l": "Bayern"
        }
    ],
    "94379": [
        {
            "o": "Sankt Englmar",
            "l": "Bayern"
        }
    ],
    "94405": [
        {
            "o": "Landau an der Isar",
            "l": "Bayern"
        }
    ],
    "94419": [
        {
            "o": "Reisbach",
            "l": "Bayern"
        }
    ],
    "94424": [
        {
            "o": "Arnstorf",
            "l": "Bayern"
        }
    ],
    "94428": [
        {
            "o": "Eichendorf",
            "l": "Bayern"
        }
    ],
    "94431": [
        {
            "o": "Pilsting",
            "l": "Bayern"
        }
    ],
    "94436": [
        {
            "o": "Simbach",
            "l": "Bayern"
        }
    ],
    "94437": [
        {
            "o": "Mamming",
            "l": "Bayern"
        }
    ],
    "94439": [
        {
            "o": "Roßbach",
            "l": "Bayern"
        }
    ],
    "94447": [
        {
            "o": "Plattling",
            "l": "Bayern"
        }
    ],
    "94469": [
        {
            "o": "Deggendorf",
            "l": "Bayern"
        }
    ],
    "94474": [
        {
            "o": "Vilshofen an der Donau",
            "l": "Bayern"
        }
    ],
    "94481": [
        {
            "o": "Grafenau",
            "l": "Bayern"
        }
    ],
    "94486": [
        {
            "o": "Osterhofen",
            "l": "Bayern"
        }
    ],
    "94491": [
        {
            "o": "Hengersberg",
            "l": "Bayern"
        }
    ],
    "94496": [
        {
            "o": "Ortenburg",
            "l": "Bayern"
        }
    ],
    "94501": [
        {
            "o": "Aidenbach",
            "l": "Bayern"
        },
        {
            "o": "Aldersbach",
            "l": "Bayern"
        },
        {
            "o": "Beutelsbach",
            "l": "Bayern"
        },
        {
            "o": "Haarbach",
            "l": "Bayern"
        }
    ],
    "94505": [
        {
            "o": "Bernried",
            "l": "Bayern"
        }
    ],
    "94508": [
        {
            "o": "Schöllnach",
            "l": "Bayern"
        }
    ],
    "94513": [
        {
            "o": "Schönberg",
            "l": "Bayern"
        }
    ],
    "94518": [
        {
            "o": "Spiegelau",
            "l": "Bayern"
        }
    ],
    "94522": [
        {
            "o": "Wallersdorf",
            "l": "Bayern"
        }
    ],
    "94526": [
        {
            "o": "Metten",
            "l": "Bayern"
        }
    ],
    "94527": [
        {
            "o": "Aholming",
            "l": "Bayern"
        }
    ],
    "94529": [
        {
            "o": "Aicha vorm Wald",
            "l": "Bayern"
        }
    ],
    "94530": [
        {
            "o": "Auerbach",
            "l": "Bayern"
        }
    ],
    "94532": [
        {
            "o": "Außernzell",
            "l": "Bayern"
        }
    ],
    "94533": [
        {
            "o": "Buchhofen",
            "l": "Bayern"
        }
    ],
    "94535": [
        {
            "o": "Eging a.See",
            "l": "Bayern"
        }
    ],
    "94536": [
        {
            "o": "Eppenschlag",
            "l": "Bayern"
        }
    ],
    "94538": [
        {
            "o": "Fürstenstein",
            "l": "Bayern"
        }
    ],
    "94539": [
        {
            "o": "Grafling",
            "l": "Bayern"
        }
    ],
    "94541": [
        {
            "o": "Grattersdorf",
            "l": "Bayern"
        }
    ],
    "94542": [
        {
            "o": "Haarbach",
            "l": "Bayern"
        }
    ],
    "94544": [
        {
            "o": "Hofkirchen",
            "l": "Bayern"
        }
    ],
    "94545": [
        {
            "o": "Hohenau",
            "l": "Bayern"
        }
    ],
    "94547": [
        {
            "o": "Iggensbach",
            "l": "Bayern"
        }
    ],
    "94548": [
        {
            "o": "Innernzell",
            "l": "Bayern"
        }
    ],
    "94550": [
        {
            "o": "Künzing",
            "l": "Bayern"
        }
    ],
    "94551": [
        {
            "o": "Hunding",
            "l": "Bayern"
        },
        {
            "o": "Lalling",
            "l": "Bayern"
        }
    ],
    "94553": [
        {
            "o": "Mariaposching",
            "l": "Bayern"
        }
    ],
    "94554": [
        {
            "o": "Moos",
            "l": "Bayern"
        }
    ],
    "94556": [
        {
            "o": "Neuschönau",
            "l": "Bayern"
        }
    ],
    "94557": [
        {
            "o": "Niederalteich",
            "l": "Bayern"
        }
    ],
    "94559": [
        {
            "o": "Niederwinkling",
            "l": "Bayern"
        }
    ],
    "94560": [
        {
            "o": "Offenberg",
            "l": "Bayern"
        }
    ],
    "94562": [
        {
            "o": "Oberpöring",
            "l": "Bayern"
        }
    ],
    "94563": [
        {
            "o": "Otzing",
            "l": "Bayern"
        }
    ],
    "94566": [
        {
            "o": "Sankt Oswald-Riedlhütte",
            "l": "Bayern"
        }
    ],
    "94568": [
        {
            "o": "Sankt Oswald-Riedlhütte",
            "l": "Bayern"
        }
    ],
    "94569": [
        {
            "o": "Stephansposching",
            "l": "Bayern"
        }
    ],
    "94571": [
        {
            "o": "Schaufling",
            "l": "Bayern"
        }
    ],
    "94572": [
        {
            "o": "Schöfweg",
            "l": "Bayern"
        }
    ],
    "94574": [
        {
            "o": "Wallerfing",
            "l": "Bayern"
        }
    ],
    "94575": [
        {
            "o": "Windorf",
            "l": "Bayern"
        }
    ],
    "94577": [
        {
            "o": "Winzer",
            "l": "Bayern"
        }
    ],
    "94579": [
        {
            "o": "Zenting",
            "l": "Bayern"
        }
    ],
    "95028": [
        {
            "o": "Hof",
            "l": "Bayern"
        }
    ],
    "95030": [
        {
            "o": "Hof",
            "l": "Bayern"
        }
    ],
    "95032": [
        {
            "o": "Hof",
            "l": "Bayern"
        }
    ],
    "95100": [
        {
            "o": "Selb",
            "l": "Bayern"
        }
    ],
    "95111": [
        {
            "o": "Rehau",
            "l": "Bayern"
        }
    ],
    "95119": [
        {
            "o": "Naila",
            "l": "Bayern"
        }
    ],
    "95126": [
        {
            "o": "Schwarzenbach a.d.Saale",
            "l": "Bayern"
        }
    ],
    "95131": [
        {
            "o": "Schwarzenbach a.Wald",
            "l": "Bayern"
        }
    ],
    "95138": [
        {
            "o": "Bad Steben",
            "l": "Bayern"
        }
    ],
    "95145": [
        {
            "o": "Oberkotzau",
            "l": "Bayern"
        }
    ],
    "95152": [
        {
            "o": "Selbitz",
            "l": "Bayern"
        }
    ],
    "95158": [
        {
            "o": "Kirchenlamitz",
            "l": "Bayern"
        }
    ],
    "95163": [
        {
            "o": "Weißenstadt",
            "l": "Bayern"
        }
    ],
    "95168": [
        {
            "o": "Marktleuthen",
            "l": "Bayern"
        }
    ],
    "95173": [
        {
            "o": "Schönwald",
            "l": "Bayern"
        }
    ],
    "95176": [
        {
            "o": "Konradsreuth",
            "l": "Bayern"
        }
    ],
    "95179": [
        {
            "o": "Geroldsgrün",
            "l": "Bayern"
        }
    ],
    "95180": [
        {
            "o": "Berg",
            "l": "Bayern"
        }
    ],
    "95182": [
        {
            "o": "Döhlau",
            "l": "Bayern"
        }
    ],
    "95183": [
        {
            "o": "Feilitzsch",
            "l": "Bayern"
        },
        {
            "o": "Töpen",
            "l": "Bayern"
        },
        {
            "o": "Trogen",
            "l": "Bayern"
        }
    ],
    "95185": [
        {
            "o": "Gattendorf",
            "l": "Bayern"
        }
    ],
    "95186": [
        {
            "o": "Höchstädt im Fichtelgebirge",
            "l": "Bayern"
        }
    ],
    "95188": [
        {
            "o": "Issigau",
            "l": "Bayern"
        }
    ],
    "95189": [
        {
            "o": "Köditz",
            "l": "Bayern"
        }
    ],
    "95191": [
        {
            "o": "Leupoldsgrün",
            "l": "Bayern"
        }
    ],
    "95192": [
        {
            "o": "Lichtenberg",
            "l": "Bayern"
        }
    ],
    "95194": [
        {
            "o": "Regnitzlosau",
            "l": "Bayern"
        }
    ],
    "95195": [
        {
            "o": "Röslau",
            "l": "Bayern"
        }
    ],
    "95197": [
        {
            "o": "Schauenstein",
            "l": "Bayern"
        }
    ],
    "95199": [
        {
            "o": "Thierstein",
            "l": "Bayern"
        }
    ],
    "95213": [
        {
            "o": "Münchberg",
            "l": "Bayern"
        }
    ],
    "95233": [
        {
            "o": "Helmbrechts",
            "l": "Bayern"
        }
    ],
    "95234": [
        {
            "o": "Sparneck",
            "l": "Bayern"
        }
    ],
    "95236": [
        {
            "o": "Stammbach",
            "l": "Bayern"
        }
    ],
    "95237": [
        {
            "o": "Weißdorf",
            "l": "Bayern"
        }
    ],
    "95239": [
        {
            "o": "Zell im Fichtelgebirge",
            "l": "Bayern"
        }
    ],
    "95326": [
        {
            "o": "Kulmbach",
            "l": "Bayern"
        }
    ],
    "95336": [
        {
            "o": "Mainleus",
            "l": "Bayern"
        }
    ],
    "95339": [
        {
            "o": "Neuenmarkt",
            "l": "Bayern"
        },
        {
            "o": "Wirsberg",
            "l": "Bayern"
        }
    ],
    "95346": [
        {
            "o": "Stadtsteinach",
            "l": "Bayern"
        }
    ],
    "95349": [
        {
            "o": "Thurnau",
            "l": "Bayern"
        }
    ],
    "95352": [
        {
            "o": "Marktleugast",
            "l": "Bayern"
        }
    ],
    "95355": [
        {
            "o": "Presseck",
            "l": "Bayern"
        }
    ],
    "95356": [
        {
            "o": "Grafengehaig",
            "l": "Bayern"
        }
    ],
    "95358": [
        {
            "o": "Guttenberg",
            "l": "Bayern"
        }
    ],
    "95359": [
        {
            "o": "Kasendorf",
            "l": "Bayern"
        }
    ],
    "95361": [
        {
            "o": "Ködnitz",
            "l": "Bayern"
        }
    ],
    "95362": [
        {
            "o": "Kupferberg",
            "l": "Bayern"
        }
    ],
    "95364": [
        {
            "o": "Ludwigschorgast",
            "l": "Bayern"
        }
    ],
    "95365": [
        {
            "o": "Rugendorf",
            "l": "Bayern"
        }
    ],
    "95367": [
        {
            "o": "Trebgast",
            "l": "Bayern"
        }
    ],
    "95369": [
        {
            "o": "Untersteinach",
            "l": "Bayern"
        }
    ],
    "95444": [
        {
            "o": "Bayreuth",
            "l": "Bayern"
        }
    ],
    "95445": [
        {
            "o": "Bayreuth",
            "l": "Bayern"
        }
    ],
    "95447": [
        {
            "o": "Bayreuth",
            "l": "Bayern"
        }
    ],
    "95448": [
        {
            "o": "Bayreuth",
            "l": "Bayern"
        }
    ],
    "95460": [
        {
            "o": "Bad Berneck im Fichtelgebirge",
            "l": "Bayern"
        }
    ],
    "95463": [
        {
            "o": "Bindlach",
            "l": "Bayern"
        }
    ],
    "95466": [
        {
            "o": "Kirchenpingarten",
            "l": "Bayern"
        },
        {
            "o": "Weidenberg",
            "l": "Bayern"
        }
    ],
    "95469": [
        {
            "o": "Speichersdorf",
            "l": "Bayern"
        }
    ],
    "95473": [
        {
            "o": "Creußen",
            "l": "Bayern"
        },
        {
            "o": "Haag",
            "l": "Bayern"
        },
        {
            "o": "Prebitz",
            "l": "Bayern"
        }
    ],
    "95478": [
        {
            "o": "Kemnath",
            "l": "Bayern"
        }
    ],
    "95482": [
        {
            "o": "Gefrees",
            "l": "Bayern"
        }
    ],
    "95485": [
        {
            "o": "Warmensteinach",
            "l": "Bayern"
        }
    ],
    "95488": [
        {
            "o": "Eckersdorf",
            "l": "Bayern"
        }
    ],
    "95490": [
        {
            "o": "Mistelgau",
            "l": "Bayern"
        }
    ],
    "95491": [
        {
            "o": "Ahorntal",
            "l": "Bayern"
        }
    ],
    "95493": [
        {
            "o": "Bischofsgrün",
            "l": "Bayern"
        }
    ],
    "95494": [
        {
            "o": "Gesees",
            "l": "Bayern"
        }
    ],
    "95496": [
        {
            "o": "Glashütten",
            "l": "Bayern"
        }
    ],
    "95497": [
        {
            "o": "Goldkronach",
            "l": "Bayern"
        }
    ],
    "95499": [
        {
            "o": "Harsdorf",
            "l": "Bayern"
        }
    ],
    "95500": [
        {
            "o": "Heinersreuth",
            "l": "Bayern"
        }
    ],
    "95502": [
        {
            "o": "Himmelkron",
            "l": "Bayern"
        }
    ],
    "95503": [
        {
            "o": "Hummeltal",
            "l": "Bayern"
        }
    ],
    "95505": [
        {
            "o": "Immenreuth",
            "l": "Bayern"
        }
    ],
    "95506": [
        {
            "o": "Kastl",
            "l": "Bayern"
        }
    ],
    "95508": [
        {
            "o": "Kulmain",
            "l": "Bayern"
        }
    ],
    "95509": [
        {
            "o": "Marktschorgast",
            "l": "Bayern"
        }
    ],
    "95511": [
        {
            "o": "Mistelbach",
            "l": "Bayern"
        }
    ],
    "95512": [
        {
            "o": "Neudrossenfeld",
            "l": "Bayern"
        }
    ],
    "95514": [
        {
            "o": "Neustadt am Kulm",
            "l": "Bayern"
        }
    ],
    "95515": [
        {
            "o": "Plankenfels",
            "l": "Bayern"
        }
    ],
    "95517": [
        {
            "o": "Emtmannsberg",
            "l": "Bayern"
        },
        {
            "o": "Seybothenreuth",
            "l": "Bayern"
        }
    ],
    "95519": [
        {
            "o": "Schlammersdorf",
            "l": "Bayern"
        },
        {
            "o": "Vorbach",
            "l": "Bayern"
        }
    ],
    "95615": [
        {
            "o": "Marktredwitz",
            "l": "Bayern"
        }
    ],
    "95632": [
        {
            "o": "Wunsiedel",
            "l": "Bayern"
        }
    ],
    "95643": [
        {
            "o": "Tirschenreuth",
            "l": "Bayern"
        }
    ],
    "95652": [
        {
            "o": "Waldsassen",
            "l": "Bayern"
        }
    ],
    "95659": [
        {
            "o": "Arzberg",
            "l": "Bayern"
        }
    ],
    "95666": [
        {
            "o": "Leonberg",
            "l": "Bayern"
        },
        {
            "o": "Mitterteich",
            "l": "Bayern"
        }
    ],
    "95671": [
        {
            "o": "Bärnau",
            "l": "Bayern"
        }
    ],
    "95676": [
        {
            "o": "Wiesau",
            "l": "Bayern"
        }
    ],
    "95679": [
        {
            "o": "Waldershof",
            "l": "Bayern"
        }
    ],
    "95680": [
        {
            "o": "Bad Alexandersbad",
            "l": "Bayern"
        }
    ],
    "95682": [
        {
            "o": "Brand",
            "l": "Bayern"
        }
    ],
    "95683": [
        {
            "o": "Ebnath",
            "l": "Bayern"
        }
    ],
    "95685": [
        {
            "o": "Falkenberg",
            "l": "Bayern"
        }
    ],
    "95686": [
        {
            "o": "Fichtelberg",
            "l": "Bayern"
        }
    ],
    "95688": [
        {
            "o": "Friedenfels",
            "l": "Bayern"
        }
    ],
    "95689": [
        {
            "o": "Fuchsmühl",
            "l": "Bayern"
        }
    ],
    "95691": [
        {
            "o": "Hohenberg an der Eger",
            "l": "Bayern"
        }
    ],
    "95692": [
        {
            "o": "Konnersreuth",
            "l": "Bayern"
        }
    ],
    "95694": [
        {
            "o": "Mehlmeisel",
            "l": "Bayern"
        }
    ],
    "95695": [
        {
            "o": "Mähring",
            "l": "Bayern"
        }
    ],
    "95697": [
        {
            "o": "Nagel",
            "l": "Bayern"
        }
    ],
    "95698": [
        {
            "o": "Neualbenreuth",
            "l": "Bayern"
        }
    ],
    "95700": [
        {
            "o": "Neusorg",
            "l": "Bayern"
        }
    ],
    "95701": [
        {
            "o": "Pechbrunn",
            "l": "Bayern"
        }
    ],
    "95703": [
        {
            "o": "Plößberg",
            "l": "Bayern"
        }
    ],
    "95704": [
        {
            "o": "Pullenreuth",
            "l": "Bayern"
        }
    ],
    "95706": [
        {
            "o": "Schirnding",
            "l": "Bayern"
        }
    ],
    "95707": [
        {
            "o": "Thiersheim",
            "l": "Bayern"
        }
    ],
    "95709": [
        {
            "o": "Tröstau",
            "l": "Bayern"
        }
    ],
    "96047": [
        {
            "o": "Bamberg",
            "l": "Bayern"
        }
    ],
    "96049": [
        {
            "o": "Bamberg",
            "l": "Bayern"
        }
    ],
    "96050": [
        {
            "o": "Bamberg",
            "l": "Bayern"
        }
    ],
    "96052": [
        {
            "o": "Bamberg",
            "l": "Bayern"
        }
    ],
    "96103": [
        {
            "o": "Hallstadt",
            "l": "Bayern"
        }
    ],
    "96106": [
        {
            "o": "Ebern",
            "l": "Bayern"
        }
    ],
    "96110": [
        {
            "o": "Scheßlitz",
            "l": "Bayern"
        }
    ],
    "96114": [
        {
            "o": "Hirschaid",
            "l": "Bayern"
        }
    ],
    "96117": [
        {
            "o": "Memmelsdorf",
            "l": "Bayern"
        }
    ],
    "96120": [
        {
            "o": "Bischberg",
            "l": "Bayern"
        }
    ],
    "96123": [
        {
            "o": "Litzendorf",
            "l": "Bayern"
        }
    ],
    "96126": [
        {
            "o": "Ermershausen",
            "l": "Bayern"
        },
        {
            "o": "Maroldsweisach",
            "l": "Bayern"
        }
    ],
    "96129": [
        {
            "o": "Strullendorf",
            "l": "Bayern"
        }
    ],
    "96132": [
        {
            "o": "Schlüsselfeld",
            "l": "Bayern"
        }
    ],
    "96135": [
        {
            "o": "Stegaurach",
            "l": "Bayern"
        }
    ],
    "96138": [
        {
            "o": "Burgebrach",
            "l": "Bayern"
        }
    ],
    "96142": [
        {
            "o": "Hollfeld",
            "l": "Bayern"
        }
    ],
    "96145": [
        {
            "o": "Seßlach",
            "l": "Bayern"
        }
    ],
    "96146": [
        {
            "o": "Altendorf",
            "l": "Bayern"
        }
    ],
    "96148": [
        {
            "o": "Baunach",
            "l": "Bayern"
        }
    ],
    "96149": [
        {
            "o": "Breitengüßbach",
            "l": "Bayern"
        }
    ],
    "96151": [
        {
            "o": "Breitbrunn",
            "l": "Bayern"
        }
    ],
    "96152": [
        {
            "o": "Burghaslach",
            "l": "Bayern"
        }
    ],
    "96154": [
        {
            "o": "Burgwindheim",
            "l": "Bayern"
        }
    ],
    "96155": [
        {
            "o": "Buttenheim",
            "l": "Bayern"
        }
    ],
    "96157": [
        {
            "o": "Ebrach",
            "l": "Bayern"
        }
    ],
    "96158": [
        {
            "o": "Frensdorf",
            "l": "Bayern"
        }
    ],
    "96160": [
        {
            "o": "Geiselwind",
            "l": "Bayern"
        }
    ],
    "96161": [
        {
            "o": "Gerach",
            "l": "Bayern"
        }
    ],
    "96163": [
        {
            "o": "Gundelsheim",
            "l": "Bayern"
        }
    ],
    "96164": [
        {
            "o": "Kemmern",
            "l": "Bayern"
        }
    ],
    "96166": [
        {
            "o": "Kirchlauter",
            "l": "Bayern"
        }
    ],
    "96167": [
        {
            "o": "Königsfeld",
            "l": "Bayern"
        }
    ],
    "96169": [
        {
            "o": "Lauter",
            "l": "Bayern"
        }
    ],
    "96170": [
        {
            "o": "Lisberg",
            "l": "Bayern"
        },
        {
            "o": "Priesendorf",
            "l": "Bayern"
        }
    ],
    "96172": [
        {
            "o": "Mühlhausen",
            "l": "Bayern"
        }
    ],
    "96173": [
        {
            "o": "Oberhaid",
            "l": "Bayern"
        }
    ],
    "96175": [
        {
            "o": "Pettstadt",
            "l": "Bayern"
        }
    ],
    "96176": [
        {
            "o": "Pfarrweisach",
            "l": "Bayern"
        }
    ],
    "96178": [
        {
            "o": "Pommersfelden",
            "l": "Bayern"
        }
    ],
    "96179": [
        {
            "o": "Rattelsdorf",
            "l": "Bayern"
        }
    ],
    "96181": [
        {
            "o": "Rauhenebrach",
            "l": "Bayern"
        }
    ],
    "96182": [
        {
            "o": "Reckendorf",
            "l": "Bayern"
        }
    ],
    "96184": [
        {
            "o": "Rentweinsdorf",
            "l": "Bayern"
        }
    ],
    "96185": [
        {
            "o": "Schönbrunn i.Steigerwald",
            "l": "Bayern"
        }
    ],
    "96187": [
        {
            "o": "Stadelhofen",
            "l": "Bayern"
        }
    ],
    "96188": [
        {
            "o": "Stettfeld",
            "l": "Bayern"
        }
    ],
    "96190": [
        {
            "o": "Untermerzbach",
            "l": "Bayern"
        }
    ],
    "96191": [
        {
            "o": "Viereth-Trunstadt",
            "l": "Bayern"
        }
    ],
    "96193": [
        {
            "o": "Wachenroth",
            "l": "Bayern"
        }
    ],
    "96194": [
        {
            "o": "Walsdorf",
            "l": "Bayern"
        }
    ],
    "96196": [
        {
            "o": "Wattendorf",
            "l": "Bayern"
        }
    ],
    "96197": [
        {
            "o": "Wonsees",
            "l": "Bayern"
        }
    ],
    "96199": [
        {
            "o": "Zapfendorf",
            "l": "Bayern"
        }
    ],
    "96215": [
        {
            "o": "Lichtenfels",
            "l": "Bayern"
        }
    ],
    "96224": [
        {
            "o": "Burgkunstadt",
            "l": "Bayern"
        }
    ],
    "96231": [
        {
            "o": "Bad Staffelstein",
            "l": "Bayern"
        }
    ],
    "96237": [
        {
            "o": "Ebersdorf b.Coburg",
            "l": "Bayern"
        }
    ],
    "96242": [
        {
            "o": "Sonnefeld",
            "l": "Bayern"
        }
    ],
    "96247": [
        {
            "o": "Michelau in Oberfranken",
            "l": "Bayern"
        }
    ],
    "96250": [
        {
            "o": "Ebensfeld",
            "l": "Bayern"
        }
    ],
    "96253": [
        {
            "o": "Untersiemau",
            "l": "Bayern"
        }
    ],
    "96257": [
        {
            "o": "Marktgraitz",
            "l": "Bayern"
        },
        {
            "o": "Redwitz a.d.Rodach",
            "l": "Bayern"
        }
    ],
    "96260": [
        {
            "o": "Weismain",
            "l": "Bayern"
        }
    ],
    "96264": [
        {
            "o": "Altenkunstadt",
            "l": "Bayern"
        }
    ],
    "96268": [
        {
            "o": "Mitwitz",
            "l": "Bayern"
        }
    ],
    "96269": [
        {
            "o": "Großheirath",
            "l": "Bayern"
        }
    ],
    "96271": [
        {
            "o": "Grub a.Forst",
            "l": "Bayern"
        }
    ],
    "96272": [
        {
            "o": "Hochstadt a.Main",
            "l": "Bayern"
        }
    ],
    "96274": [
        {
            "o": "Itzgrund",
            "l": "Bayern"
        }
    ],
    "96275": [
        {
            "o": "Marktzeuln",
            "l": "Bayern"
        }
    ],
    "96277": [
        {
            "o": "Schneckenlohe",
            "l": "Bayern"
        }
    ],
    "96279": [
        {
            "o": "Weidhausen b.Coburg",
            "l": "Bayern"
        }
    ],
    "96317": [
        {
            "o": "Kronach",
            "l": "Bayern"
        }
    ],
    "96328": [
        {
            "o": "Küps",
            "l": "Bayern"
        }
    ],
    "96332": [
        {
            "o": "Pressig",
            "l": "Bayern"
        }
    ],
    "96337": [
        {
            "o": "Ludwigsstadt",
            "l": "Bayern"
        }
    ],
    "96342": [
        {
            "o": "Stockheim",
            "l": "Bayern"
        }
    ],
    "96346": [
        {
            "o": "Wallenfels",
            "l": "Bayern"
        }
    ],
    "96349": [
        {
            "o": "Steinwiesen",
            "l": "Bayern"
        }
    ],
    "96352": [
        {
            "o": "Wilhelmsthal",
            "l": "Bayern"
        }
    ],
    "96355": [
        {
            "o": "Tettau",
            "l": "Bayern"
        }
    ],
    "96358": [
        {
            "o": "Reichenbach",
            "l": "Bayern"
        },
        {
            "o": "Teuschnitz",
            "l": "Bayern"
        }
    ],
    "96361": [
        {
            "o": "Steinbach am Wald",
            "l": "Bayern"
        }
    ],
    "96364": [
        {
            "o": "Marktrodach",
            "l": "Bayern"
        }
    ],
    "96365": [
        {
            "o": "Nordhalben",
            "l": "Bayern"
        }
    ],
    "96367": [
        {
            "o": "Tschirn",
            "l": "Bayern"
        }
    ],
    "96369": [
        {
            "o": "Kulmbach",
            "l": "Bayern"
        },
        {
            "o": "Weißenbrunn",
            "l": "Bayern"
        }
    ],
    "96450": [
        {
            "o": "Coburg",
            "l": "Bayern"
        }
    ],
    "96465": [
        {
            "o": "Neustadt bei Coburg",
            "l": "Bayern"
        }
    ],
    "96472": [
        {
            "o": "Rödental",
            "l": "Bayern"
        }
    ],
    "96476": [
        {
            "o": "Bad Rodach",
            "l": "Bayern"
        }
    ],
    "96479": [
        {
            "o": "Weitramsdorf",
            "l": "Bayern"
        }
    ],
    "96482": [
        {
            "o": "Ahorn",
            "l": "Bayern"
        }
    ],
    "96484": [
        {
            "o": "Meeder",
            "l": "Bayern"
        }
    ],
    "96486": [
        {
            "o": "Lautertal",
            "l": "Bayern"
        }
    ],
    "96487": [
        {
            "o": "Dörfles-Esbach",
            "l": "Bayern"
        }
    ],
    "96489": [
        {
            "o": "Niederfüllbach",
            "l": "Bayern"
        }
    ],
    "96515": [
        {
            "o": "Sonneberg",
            "l": "Thüringen"
        }
    ],
    "96523": [
        {
            "o": "Steinach",
            "l": "Thüringen"
        }
    ],
    "96524": [
        {
            "o": "Föritztal",
            "l": "Thüringen"
        }
    ],
    "96528": [
        {
            "o": "Bachfeld",
            "l": "Thüringen"
        },
        {
            "o": "Frankenblick",
            "l": "Thüringen"
        },
        {
            "o": "Schalkau",
            "l": "Thüringen"
        }
    ],
    "97070": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97072": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97074": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97076": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97078": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97080": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97082": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97084": [
        {
            "o": "Würzburg",
            "l": "Bayern"
        }
    ],
    "97199": [
        {
            "o": "Ochsenfurt",
            "l": "Bayern"
        }
    ],
    "97204": [
        {
            "o": "Höchberg",
            "l": "Bayern"
        }
    ],
    "97209": [
        {
            "o": "Veitshöchheim",
            "l": "Bayern"
        }
    ],
    "97215": [
        {
            "o": "Simmershofen",
            "l": "Bayern"
        },
        {
            "o": "Uffenheim",
            "l": "Bayern"
        },
        {
            "o": "Weigenheim",
            "l": "Bayern"
        }
    ],
    "97218": [
        {
            "o": "Gerbrunn",
            "l": "Bayern"
        }
    ],
    "97222": [
        {
            "o": "Rimpar",
            "l": "Bayern"
        }
    ],
    "97225": [
        {
            "o": "Zellingen",
            "l": "Bayern"
        }
    ],
    "97228": [
        {
            "o": "Rottendorf",
            "l": "Bayern"
        }
    ],
    "97230": [
        {
            "o": "Estenfeld",
            "l": "Bayern"
        }
    ],
    "97232": [
        {
            "o": "Giebelstadt",
            "l": "Bayern"
        }
    ],
    "97234": [
        {
            "o": "Reichenberg",
            "l": "Bayern"
        }
    ],
    "97236": [
        {
            "o": "Randersacker",
            "l": "Bayern"
        }
    ],
    "97237": [
        {
            "o": "Altertheim",
            "l": "Bayern"
        }
    ],
    "97239": [
        {
            "o": "Aub",
            "l": "Bayern"
        }
    ],
    "97241": [
        {
            "o": "Bergtheim",
            "l": "Bayern"
        },
        {
            "o": "Oberpleichfeld",
            "l": "Bayern"
        }
    ],
    "97243": [
        {
            "o": "Bieberehren",
            "l": "Bayern"
        }
    ],
    "97244": [
        {
            "o": "Bütthard",
            "l": "Bayern"
        }
    ],
    "97246": [
        {
            "o": "Eibelstadt",
            "l": "Bayern"
        }
    ],
    "97247": [
        {
            "o": "Eisenheim",
            "l": "Bayern"
        }
    ],
    "97249": [
        {
            "o": "Eisingen",
            "l": "Bayern"
        }
    ],
    "97250": [
        {
            "o": "Erlabrunn",
            "l": "Bayern"
        }
    ],
    "97252": [
        {
            "o": "Frickenhausen a.Main",
            "l": "Bayern"
        }
    ],
    "97253": [
        {
            "o": "Gaukönigshofen",
            "l": "Bayern"
        }
    ],
    "97255": [
        {
            "o": "Gelchsheim",
            "l": "Bayern"
        },
        {
            "o": "Sonderhofen",
            "l": "Bayern"
        }
    ],
    "97256": [
        {
            "o": "Geroldshausen",
            "l": "Bayern"
        }
    ],
    "97258": [
        {
            "o": "Gollhofen",
            "l": "Bayern"
        },
        {
            "o": "Hemmersheim",
            "l": "Bayern"
        },
        {
            "o": "Ippesheim",
            "l": "Bayern"
        },
        {
            "o": "Oberickelsheim",
            "l": "Bayern"
        }
    ],
    "97259": [
        {
            "o": "Greußenheim",
            "l": "Bayern"
        }
    ],
    "97261": [
        {
            "o": "Güntersleben",
            "l": "Bayern"
        }
    ],
    "97262": [
        {
            "o": "Hausen b. Würzburg",
            "l": "Bayern"
        }
    ],
    "97264": [
        {
            "o": "Helmstadt",
            "l": "Bayern"
        }
    ],
    "97265": [
        {
            "o": "Hettstadt",
            "l": "Bayern"
        }
    ],
    "97267": [
        {
            "o": "Himmelstadt",
            "l": "Bayern"
        }
    ],
    "97268": [
        {
            "o": "Kirchheim",
            "l": "Bayern"
        }
    ],
    "97270": [
        {
            "o": "Kist",
            "l": "Bayern"
        }
    ],
    "97271": [
        {
            "o": "Kleinrinderfeld",
            "l": "Bayern"
        }
    ],
    "97273": [
        {
            "o": "Kürnach",
            "l": "Bayern"
        }
    ],
    "97274": [
        {
            "o": "Leinach",
            "l": "Bayern"
        }
    ],
    "97276": [
        {
            "o": "Margetshöchheim",
            "l": "Bayern"
        }
    ],
    "97277": [
        {
            "o": "Neubrunn",
            "l": "Bayern"
        }
    ],
    "97279": [
        {
            "o": "Prosselsheim",
            "l": "Bayern"
        }
    ],
    "97280": [
        {
            "o": "Remlingen",
            "l": "Bayern"
        }
    ],
    "97282": [
        {
            "o": "Retzstadt",
            "l": "Bayern"
        }
    ],
    "97283": [
        {
            "o": "Riedenheim",
            "l": "Bayern"
        }
    ],
    "97285": [
        {
            "o": "Röttingen",
            "l": "Bayern"
        },
        {
            "o": "Tauberrettersheim",
            "l": "Bayern"
        }
    ],
    "97286": [
        {
            "o": "Sommerhausen",
            "l": "Bayern"
        },
        {
            "o": "Winterhausen",
            "l": "Bayern"
        }
    ],
    "97288": [
        {
            "o": "Theilheim",
            "l": "Bayern"
        }
    ],
    "97289": [
        {
            "o": "Thüngen",
            "l": "Bayern"
        }
    ],
    "97291": [
        {
            "o": "Thüngersheim",
            "l": "Bayern"
        }
    ],
    "97292": [
        {
            "o": "Holzkirchen",
            "l": "Bayern"
        },
        {
            "o": "Uettingen",
            "l": "Bayern"
        }
    ],
    "97294": [
        {
            "o": "Unterpleichfeld",
            "l": "Bayern"
        }
    ],
    "97295": [
        {
            "o": "Waldbrunn",
            "l": "Bayern"
        }
    ],
    "97297": [
        {
            "o": "Waldbüttelbrunn",
            "l": "Bayern"
        }
    ],
    "97299": [
        {
            "o": "Zell am Main",
            "l": "Bayern"
        }
    ],
    "97318": [
        {
            "o": "Biebelried",
            "l": "Bayern"
        },
        {
            "o": "Kitzingen",
            "l": "Bayern"
        }
    ],
    "97320": [
        {
            "o": "Albertshofen",
            "l": "Bayern"
        },
        {
            "o": "Buchbrunn",
            "l": "Bayern"
        },
        {
            "o": "Großlangheim",
            "l": "Bayern"
        },
        {
            "o": "Mainstockheim",
            "l": "Bayern"
        },
        {
            "o": "Sulzfeld am Main",
            "l": "Bayern"
        }
    ],
    "97332": [
        {
            "o": "Volkach",
            "l": "Bayern"
        }
    ],
    "97334": [
        {
            "o": "Nordheim a.Main",
            "l": "Bayern"
        },
        {
            "o": "Sommerach",
            "l": "Bayern"
        }
    ],
    "97337": [
        {
            "o": "Dettelbach",
            "l": "Bayern"
        }
    ],
    "97340": [
        {
            "o": "Marktbreit",
            "l": "Bayern"
        },
        {
            "o": "Martinsheim",
            "l": "Bayern"
        },
        {
            "o": "Segnitz",
            "l": "Bayern"
        }
    ],
    "97342": [
        {
            "o": "Marktsteft",
            "l": "Bayern"
        },
        {
            "o": "Obernbreit",
            "l": "Bayern"
        },
        {
            "o": "Seinsheim",
            "l": "Bayern"
        }
    ],
    "97346": [
        {
            "o": "Iphofen",
            "l": "Bayern"
        }
    ],
    "97348": [
        {
            "o": "Markt Einersheim",
            "l": "Bayern"
        },
        {
            "o": "Rödelsee",
            "l": "Bayern"
        },
        {
            "o": "Willanzheim",
            "l": "Bayern"
        }
    ],
    "97350": [
        {
            "o": "Mainbernheim",
            "l": "Bayern"
        }
    ],
    "97353": [
        {
            "o": "Wiesentheid",
            "l": "Bayern"
        }
    ],
    "97355": [
        {
            "o": "Abtswind",
            "l": "Bayern"
        },
        {
            "o": "Castell",
            "l": "Bayern"
        },
        {
            "o": "Kleinlangheim",
            "l": "Bayern"
        },
        {
            "o": "Rüdenhausen",
            "l": "Bayern"
        },
        {
            "o": "Wiesenbronn",
            "l": "Bayern"
        }
    ],
    "97357": [
        {
            "o": "Prichsenstadt",
            "l": "Bayern"
        }
    ],
    "97359": [
        {
            "o": "Schwarzach am Main",
            "l": "Bayern"
        }
    ],
    "97421": [
        {
            "o": "Schweinfurt",
            "l": "Bayern"
        }
    ],
    "97422": [
        {
            "o": "Schweinfurt",
            "l": "Bayern"
        }
    ],
    "97424": [
        {
            "o": "Schweinfurt",
            "l": "Bayern"
        }
    ],
    "97437": [
        {
            "o": "Haßfurt",
            "l": "Bayern"
        }
    ],
    "97440": [
        {
            "o": "Werneck",
            "l": "Bayern"
        }
    ],
    "97447": [
        {
            "o": "Frankenwinheim",
            "l": "Bayern"
        },
        {
            "o": "Gerolzhofen",
            "l": "Bayern"
        }
    ],
    "97450": [
        {
            "o": "Arnstein",
            "l": "Bayern"
        }
    ],
    "97453": [
        {
            "o": "Schonungen",
            "l": "Bayern"
        }
    ],
    "97456": [
        {
            "o": "Dittelbrunn",
            "l": "Bayern"
        }
    ],
    "97461": [
        {
            "o": "Hofheim in Unterfranken",
            "l": "Bayern"
        }
    ],
    "97464": [
        {
            "o": "Niederwerrn",
            "l": "Bayern"
        }
    ],
    "97469": [
        {
            "o": "Gochsheim",
            "l": "Bayern"
        }
    ],
    "97475": [
        {
            "o": "Zeil am Main",
            "l": "Bayern"
        }
    ],
    "97478": [
        {
            "o": "Knetzgau",
            "l": "Bayern"
        }
    ],
    "97483": [
        {
            "o": "Eltmann",
            "l": "Bayern"
        }
    ],
    "97486": [
        {
            "o": "Königsberg in Bayern",
            "l": "Bayern"
        }
    ],
    "97488": [
        {
            "o": "Stadtlauringen",
            "l": "Bayern"
        }
    ],
    "97490": [
        {
            "o": "Poppenhausen",
            "l": "Bayern"
        }
    ],
    "97491": [
        {
            "o": "Aidhausen",
            "l": "Bayern"
        }
    ],
    "97493": [
        {
            "o": "Bergrheinfeld",
            "l": "Bayern"
        }
    ],
    "97494": [
        {
            "o": "Bundorf",
            "l": "Bayern"
        }
    ],
    "97496": [
        {
            "o": "Burgpreppach",
            "l": "Bayern"
        }
    ],
    "97497": [
        {
            "o": "Dingolshausen",
            "l": "Bayern"
        }
    ],
    "97499": [
        {
            "o": "Donnersdorf",
            "l": "Bayern"
        }
    ],
    "97500": [
        {
            "o": "Ebelsbach",
            "l": "Bayern"
        }
    ],
    "97502": [
        {
            "o": "Euerbach",
            "l": "Bayern"
        }
    ],
    "97503": [
        {
            "o": "Gädheim",
            "l": "Bayern"
        }
    ],
    "97505": [
        {
            "o": "Geldersheim",
            "l": "Bayern"
        }
    ],
    "97506": [
        {
            "o": "Grafenrheinfeld",
            "l": "Bayern"
        }
    ],
    "97508": [
        {
            "o": "Grettstadt",
            "l": "Bayern"
        }
    ],
    "97509": [
        {
            "o": "Kolitzheim",
            "l": "Bayern"
        }
    ],
    "97511": [
        {
            "o": "Lülsfeld",
            "l": "Bayern"
        }
    ],
    "97513": [
        {
            "o": "Michelau i.Steigerwald",
            "l": "Bayern"
        }
    ],
    "97514": [
        {
            "o": "Oberaurach",
            "l": "Bayern"
        }
    ],
    "97516": [
        {
            "o": "Oberschwarzach",
            "l": "Bayern"
        }
    ],
    "97517": [
        {
            "o": "Rannungen",
            "l": "Bayern"
        }
    ],
    "97519": [
        {
            "o": "Riedbach",
            "l": "Bayern"
        }
    ],
    "97520": [
        {
            "o": "Röthlein",
            "l": "Bayern"
        }
    ],
    "97522": [
        {
            "o": "Sand a.Main",
            "l": "Bayern"
        }
    ],
    "97523": [
        {
            "o": "Schwanfeld",
            "l": "Bayern"
        }
    ],
    "97525": [
        {
            "o": "Schwebheim",
            "l": "Bayern"
        }
    ],
    "97526": [
        {
            "o": "Sennfeld",
            "l": "Bayern"
        }
    ],
    "97528": [
        {
            "o": "Sulzdorf a.d.Lederhecke",
            "l": "Bayern"
        }
    ],
    "97529": [
        {
            "o": "Sulzheim",
            "l": "Bayern"
        }
    ],
    "97531": [
        {
            "o": "Theres",
            "l": "Bayern"
        }
    ],
    "97532": [
        {
            "o": "Üchtelhausen",
            "l": "Bayern"
        }
    ],
    "97534": [
        {
            "o": "Waigolshausen",
            "l": "Bayern"
        }
    ],
    "97535": [
        {
            "o": "Wasserlosen",
            "l": "Bayern"
        }
    ],
    "97537": [
        {
            "o": "Wipfeld",
            "l": "Bayern"
        }
    ],
    "97539": [
        {
            "o": "Wonfurt",
            "l": "Bayern"
        }
    ],
    "97616": [
        {
            "o": "Bad Neustadt an der Saale",
            "l": "Bayern"
        },
        {
            "o": "Salz",
            "l": "Bayern"
        }
    ],
    "97618": [
        {
            "o": "Heustreu",
            "l": "Bayern"
        },
        {
            "o": "Hohenroth",
            "l": "Bayern"
        },
        {
            "o": "Hollstadt",
            "l": "Bayern"
        },
        {
            "o": "Niederlauer",
            "l": "Bayern"
        },
        {
            "o": "Rödelmaier",
            "l": "Bayern"
        },
        {
            "o": "Strahlungen",
            "l": "Bayern"
        },
        {
            "o": "Unsleben",
            "l": "Bayern"
        },
        {
            "o": "Wollbach",
            "l": "Bayern"
        },
        {
            "o": "Wülfershausen an der Saale",
            "l": "Bayern"
        }
    ],
    "97631": [
        {
            "o": "Bad Königshofen im Grabfeld",
            "l": "Bayern"
        }
    ],
    "97633": [
        {
            "o": "Aubstadt",
            "l": "Bayern"
        },
        {
            "o": "Großbardorf",
            "l": "Bayern"
        },
        {
            "o": "Großeibstadt",
            "l": "Bayern"
        },
        {
            "o": "Herbstadt",
            "l": "Bayern"
        },
        {
            "o": "Höchheim",
            "l": "Bayern"
        },
        {
            "o": "Saal a.d.Saale",
            "l": "Bayern"
        },
        {
            "o": "Sulzfeld",
            "l": "Bayern"
        },
        {
            "o": "Trappstadt",
            "l": "Bayern"
        }
    ],
    "97638": [
        {
            "o": "Mellrichstadt",
            "l": "Bayern"
        }
    ],
    "97640": [
        {
            "o": "Hendungen",
            "l": "Bayern"
        },
        {
            "o": "Oberstreu",
            "l": "Bayern"
        },
        {
            "o": "Stockheim",
            "l": "Bayern"
        }
    ],
    "97645": [
        {
            "o": "Ostheim v.d.Rhön",
            "l": "Bayern"
        }
    ],
    "97647": [
        {
            "o": "Hausen",
            "l": "Bayern"
        },
        {
            "o": "Nordheim v.d.Rhön",
            "l": "Bayern"
        },
        {
            "o": "Sondheim v.d.Rhön",
            "l": "Bayern"
        },
        {
            "o": "Willmars",
            "l": "Bayern"
        }
    ],
    "97650": [
        {
            "o": "Fladungen",
            "l": "Bayern"
        }
    ],
    "97653": [
        {
            "o": "Bischofsheim a.d.Rhön",
            "l": "Bayern"
        }
    ],
    "97654": [
        {
            "o": "Bastheim",
            "l": "Bayern"
        }
    ],
    "97656": [
        {
            "o": "Oberelsbach",
            "l": "Bayern"
        }
    ],
    "97657": [
        {
            "o": "Sandberg",
            "l": "Bayern"
        }
    ],
    "97659": [
        {
            "o": "Schönau a.d.Brend",
            "l": "Bayern"
        }
    ],
    "97688": [
        {
            "o": "Bad Kissingen",
            "l": "Bayern"
        }
    ],
    "97702": [
        {
            "o": "Münnerstadt",
            "l": "Bayern"
        }
    ],
    "97705": [
        {
            "o": "Burkardroth",
            "l": "Bayern"
        }
    ],
    "97708": [
        {
            "o": "Bad Bocklet",
            "l": "Bayern"
        }
    ],
    "97711": [
        {
            "o": "Maßbach",
            "l": "Bayern"
        },
        {
            "o": "Thundorf i.UFr.",
            "l": "Bayern"
        }
    ],
    "97714": [
        {
            "o": "Oerlenbach",
            "l": "Bayern"
        }
    ],
    "97717": [
        {
            "o": "Aura a.d.Saale",
            "l": "Bayern"
        },
        {
            "o": "Euerdorf",
            "l": "Bayern"
        },
        {
            "o": "Sulzthal",
            "l": "Bayern"
        }
    ],
    "97720": [
        {
            "o": "Nüdlingen",
            "l": "Bayern"
        }
    ],
    "97723": [
        {
            "o": "Oberthulba",
            "l": "Bayern"
        }
    ],
    "97724": [
        {
            "o": "Burglauer",
            "l": "Bayern"
        }
    ],
    "97725": [
        {
            "o": "Elfershausen",
            "l": "Bayern"
        }
    ],
    "97727": [
        {
            "o": "Fuchsstadt",
            "l": "Bayern"
        }
    ],
    "97729": [
        {
            "o": "Ramsthal",
            "l": "Bayern"
        }
    ],
    "97737": [
        {
            "o": "Gemünden am Main",
            "l": "Bayern"
        }
    ],
    "97753": [
        {
            "o": "Karlstadt",
            "l": "Bayern"
        }
    ],
    "97762": [
        {
            "o": "Hammelburg",
            "l": "Bayern"
        }
    ],
    "97769": [
        {
            "o": "Bad Brückenau",
            "l": "Bayern"
        }
    ],
    "97772": [
        {
            "o": "Wildflecken",
            "l": "Bayern"
        }
    ],
    "97773": [
        {
            "o": "Aura im Sinngrund",
            "l": "Bayern"
        }
    ],
    "97775": [
        {
            "o": "Burgsinn",
            "l": "Bayern"
        }
    ],
    "97776": [
        {
            "o": "Eußenheim",
            "l": "Bayern"
        }
    ],
    "97778": [
        {
            "o": "Fellen",
            "l": "Bayern"
        }
    ],
    "97779": [
        {
            "o": "Geroda",
            "l": "Bayern"
        }
    ],
    "97780": [
        {
            "o": "Gössenheim",
            "l": "Bayern"
        }
    ],
    "97782": [
        {
            "o": "Gräfendorf",
            "l": "Bayern"
        }
    ],
    "97783": [
        {
            "o": "Karsbach",
            "l": "Bayern"
        }
    ],
    "97785": [
        {
            "o": "Mittelsinn",
            "l": "Bayern"
        }
    ],
    "97786": [
        {
            "o": "Motten",
            "l": "Bayern"
        }
    ],
    "97788": [
        {
            "o": "Neuendorf",
            "l": "Bayern"
        }
    ],
    "97789": [
        {
            "o": "Oberleichtersbach",
            "l": "Bayern"
        }
    ],
    "97791": [
        {
            "o": "Obersinn",
            "l": "Bayern"
        }
    ],
    "97792": [
        {
            "o": "Riedenberg",
            "l": "Bayern"
        }
    ],
    "97794": [
        {
            "o": "Rieneck",
            "l": "Bayern"
        }
    ],
    "97795": [
        {
            "o": "Schondra",
            "l": "Bayern"
        }
    ],
    "97797": [
        {
            "o": "Wartmannsroth",
            "l": "Bayern"
        }
    ],
    "97799": [
        {
            "o": "Zeitlofs",
            "l": "Bayern"
        }
    ],
    "97816": [
        {
            "o": "Lohr am Main",
            "l": "Bayern"
        }
    ],
    "97828": [
        {
            "o": "Marktheidenfeld",
            "l": "Bayern"
        }
    ],
    "97833": [
        {
            "o": "Frammersbach",
            "l": "Bayern"
        }
    ],
    "97834": [
        {
            "o": "Birkenfeld",
            "l": "Bayern"
        }
    ],
    "97836": [
        {
            "o": "Bischbrunn",
            "l": "Bayern"
        }
    ],
    "97837": [
        {
            "o": "Erlenbach b.Marktheidenfeld",
            "l": "Bayern"
        }
    ],
    "97839": [
        {
            "o": "Esselbach",
            "l": "Bayern"
        }
    ],
    "97840": [
        {
            "o": "Hafenlohr",
            "l": "Bayern"
        },
        {
            "o": "Rothenbuch",
            "l": "Bayern"
        }
    ],
    "97842": [
        {
            "o": "Karbach",
            "l": "Bayern"
        }
    ],
    "97843": [
        {
            "o": "Neuhütten",
            "l": "Bayern"
        }
    ],
    "97845": [
        {
            "o": "Neustadt a.Main",
            "l": "Bayern"
        }
    ],
    "97846": [
        {
            "o": "Partenstein",
            "l": "Bayern"
        }
    ],
    "97848": [
        {
            "o": "Rechtenbach",
            "l": "Bayern"
        }
    ],
    "97849": [
        {
            "o": "Roden",
            "l": "Bayern"
        }
    ],
    "97851": [
        {
            "o": "Rothenfels",
            "l": "Bayern"
        }
    ],
    "97852": [
        {
            "o": "Bischbrunn",
            "l": "Bayern"
        },
        {
            "o": "Schollbrunn",
            "l": "Bayern"
        }
    ],
    "97854": [
        {
            "o": "Steinfeld",
            "l": "Bayern"
        }
    ],
    "97855": [
        {
            "o": "Triefenstein",
            "l": "Bayern"
        }
    ],
    "97857": [
        {
            "o": "Urspringen",
            "l": "Bayern"
        }
    ],
    "97859": [
        {
            "o": "Wiesthal",
            "l": "Bayern"
        }
    ],
    "97877": [
        {
            "o": "Wertheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97892": [
        {
            "o": "Kreuzwertheim",
            "l": "Bayern"
        }
    ],
    "97896": [
        {
            "o": "Collenberg",
            "l": "Bayern"
        },
        {
            "o": "Eichenbühl",
            "l": "Bayern"
        },
        {
            "o": "Freudenberg",
            "l": "Baden-Württemberg"
        }
    ],
    "97900": [
        {
            "o": "Külsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97901": [
        {
            "o": "Altenbuch",
            "l": "Bayern"
        }
    ],
    "97903": [
        {
            "o": "Collenberg",
            "l": "Bayern"
        }
    ],
    "97904": [
        {
            "o": "Dorfprozelten",
            "l": "Bayern"
        }
    ],
    "97906": [
        {
            "o": "Faulbach",
            "l": "Bayern"
        }
    ],
    "97907": [
        {
            "o": "Hasloch",
            "l": "Bayern"
        },
        {
            "o": "Schollbrunn",
            "l": "Bayern"
        }
    ],
    "97909": [
        {
            "o": "Stadtprozelten",
            "l": "Bayern"
        }
    ],
    "97922": [
        {
            "o": "Lauda-Königshofen",
            "l": "Baden-Württemberg"
        }
    ],
    "97941": [
        {
            "o": "Tauberbischofsheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97944": [
        {
            "o": "Boxberg",
            "l": "Baden-Württemberg"
        }
    ],
    "97947": [
        {
            "o": "Grünsfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "97950": [
        {
            "o": "Großrinderfeld",
            "l": "Baden-Württemberg"
        }
    ],
    "97953": [
        {
            "o": "Königheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97956": [
        {
            "o": "Werbach",
            "l": "Baden-Württemberg"
        }
    ],
    "97957": [
        {
            "o": "Wittighausen",
            "l": "Baden-Württemberg"
        }
    ],
    "97959": [
        {
            "o": "Assamstadt",
            "l": "Baden-Württemberg"
        }
    ],
    "97980": [
        {
            "o": "Bad Mergentheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97990": [
        {
            "o": "Weikersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "97993": [
        {
            "o": "Creglingen",
            "l": "Baden-Württemberg"
        }
    ],
    "97996": [
        {
            "o": "Niederstetten",
            "l": "Baden-Württemberg"
        }
    ],
    "97999": [
        {
            "o": "Igersheim",
            "l": "Baden-Württemberg"
        }
    ],
    "98527": [
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98528": [
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98529": [
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98530": [
        {
            "o": "Dillstädt",
            "l": "Thüringen"
        },
        {
            "o": "Grub",
            "l": "Thüringen"
        },
        {
            "o": "Marisfeld",
            "l": "Thüringen"
        },
        {
            "o": "Oberstadt",
            "l": "Thüringen"
        },
        {
            "o": "Rohr",
            "l": "Thüringen"
        },
        {
            "o": "Schmeheim",
            "l": "Thüringen"
        },
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98544": [
        {
            "o": "Zella-Mehlis",
            "l": "Thüringen"
        }
    ],
    "98547": [
        {
            "o": "Christes",
            "l": "Thüringen"
        },
        {
            "o": "Kühndorf",
            "l": "Thüringen"
        },
        {
            "o": "Schwarza",
            "l": "Thüringen"
        }
    ],
    "98553": [
        {
            "o": "Ahlstädt",
            "l": "Thüringen"
        },
        {
            "o": "Bischofrod",
            "l": "Thüringen"
        },
        {
            "o": "Eichenberg",
            "l": "Thüringen"
        },
        {
            "o": "Schleusingen",
            "l": "Thüringen"
        }
    ],
    "98554": [
        {
            "o": "Zella-Mehlis",
            "l": "Thüringen"
        }
    ],
    "98559": [
        {
            "o": "Oberhof",
            "l": "Thüringen"
        },
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98574": [
        {
            "o": "Schmalkalden",
            "l": "Thüringen"
        }
    ],
    "98587": [
        {
            "o": "Schmalkalden",
            "l": "Thüringen"
        },
        {
            "o": "Steinbach-Hallenberg",
            "l": "Thüringen"
        }
    ],
    "98590": [
        {
            "o": "Rosa",
            "l": "Thüringen"
        },
        {
            "o": "Roßdorf",
            "l": "Thüringen"
        },
        {
            "o": "Schwallungen",
            "l": "Thüringen"
        }
    ],
    "98593": [
        {
            "o": "Floh-Seligenthal",
            "l": "Thüringen"
        }
    ],
    "98596": [
        {
            "o": "Brotterode-Trusetal",
            "l": "Thüringen"
        }
    ],
    "98597": [
        {
            "o": "Breitungen/Werra",
            "l": "Thüringen"
        },
        {
            "o": "Fambach",
            "l": "Thüringen"
        }
    ],
    "98617": [
        {
            "o": "Belrieth",
            "l": "Thüringen"
        },
        {
            "o": "Einhausen",
            "l": "Thüringen"
        },
        {
            "o": "Ellingshausen",
            "l": "Thüringen"
        },
        {
            "o": "Grabfeld",
            "l": "Thüringen"
        },
        {
            "o": "Leutersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Meiningen",
            "l": "Thüringen"
        },
        {
            "o": "Neubrunn",
            "l": "Thüringen"
        },
        {
            "o": "Obermaßfeld-Grimmenthal",
            "l": "Thüringen"
        },
        {
            "o": "Rhönblick",
            "l": "Thüringen"
        },
        {
            "o": "Ritschenhausen",
            "l": "Thüringen"
        },
        {
            "o": "Stepfershausen",
            "l": "Thüringen"
        },
        {
            "o": "Sülzfeld",
            "l": "Thüringen"
        },
        {
            "o": "Untermaßfeld",
            "l": "Thüringen"
        },
        {
            "o": "Utendorf",
            "l": "Thüringen"
        },
        {
            "o": "Vachdorf",
            "l": "Thüringen"
        }
    ],
    "98630": [
        {
            "o": "Römhild",
            "l": "Thüringen"
        }
    ],
    "98631": [
        {
            "o": "Grabfeld",
            "l": "Thüringen"
        }
    ],
    "98634": [
        {
            "o": "Birx",
            "l": "Thüringen"
        },
        {
            "o": "Erbenhausen",
            "l": "Thüringen"
        },
        {
            "o": "Frankenheim/Rhön",
            "l": "Thüringen"
        },
        {
            "o": "Friedelshausen",
            "l": "Thüringen"
        },
        {
            "o": "Kaltennordheim",
            "l": "Thüringen"
        },
        {
            "o": "Mehmels",
            "l": "Thüringen"
        },
        {
            "o": "Oberweid",
            "l": "Thüringen"
        },
        {
            "o": "Wasungen",
            "l": "Thüringen"
        }
    ],
    "98639": [
        {
            "o": "Meiningen",
            "l": "Thüringen"
        },
        {
            "o": "Rippershausen",
            "l": "Thüringen"
        },
        {
            "o": "Wasungen",
            "l": "Thüringen"
        }
    ],
    "98646": [
        {
            "o": "Dingsleben",
            "l": "Thüringen"
        },
        {
            "o": "Hildburghausen",
            "l": "Thüringen"
        },
        {
            "o": "Reurieth",
            "l": "Thüringen"
        },
        {
            "o": "Straufhain",
            "l": "Thüringen"
        }
    ],
    "98660": [
        {
            "o": "Beinerstadt",
            "l": "Thüringen"
        },
        {
            "o": "Ehrenberg",
            "l": "Thüringen"
        },
        {
            "o": "Grimmelshausen",
            "l": "Thüringen"
        },
        {
            "o": "Henfstädt",
            "l": "Thüringen"
        },
        {
            "o": "Kloster Veßra",
            "l": "Thüringen"
        },
        {
            "o": "Lengfeld",
            "l": "Thüringen"
        },
        {
            "o": "Sankt Bernhard",
            "l": "Thüringen"
        },
        {
            "o": "Themar",
            "l": "Thüringen"
        }
    ],
    "98663": [
        {
            "o": "Heldburg",
            "l": "Thüringen"
        },
        {
            "o": "Schlechtsart",
            "l": "Thüringen"
        },
        {
            "o": "Schweickershausen",
            "l": "Thüringen"
        },
        {
            "o": "Ummerstadt",
            "l": "Thüringen"
        },
        {
            "o": "Westhausen",
            "l": "Thüringen"
        }
    ],
    "98666": [
        {
            "o": "Masserberg",
            "l": "Thüringen"
        },
        {
            "o": "Schleusegrund",
            "l": "Thüringen"
        }
    ],
    "98667": [
        {
            "o": "Schleusegrund",
            "l": "Thüringen"
        }
    ],
    "98669": [
        {
            "o": "Veilsdorf",
            "l": "Thüringen"
        }
    ],
    "98673": [
        {
            "o": "Auengrund",
            "l": "Thüringen"
        },
        {
            "o": "Brünn/Thür.",
            "l": "Thüringen"
        },
        {
            "o": "Eisfeld",
            "l": "Thüringen"
        }
    ],
    "98693": [
        {
            "o": "Ilmenau",
            "l": "Thüringen"
        },
        {
            "o": "Martinroda",
            "l": "Thüringen"
        }
    ],
    "98694": [
        {
            "o": "Ilmenau",
            "l": "Thüringen"
        }
    ],
    "98701": [
        {
            "o": "Großbreitenbach",
            "l": "Thüringen"
        }
    ],
    "98711": [
        {
            "o": "Suhl",
            "l": "Thüringen"
        }
    ],
    "98716": [
        {
            "o": "Elgersburg",
            "l": "Thüringen"
        },
        {
            "o": "Geratal",
            "l": "Thüringen"
        }
    ],
    "98724": [
        {
            "o": "Lauscha",
            "l": "Thüringen"
        },
        {
            "o": "Neuhaus am Rennweg",
            "l": "Thüringen"
        }
    ],
    "98743": [
        {
            "o": "Gräfenthal",
            "l": "Thüringen"
        }
    ],
    "98744": [
        {
            "o": "Cursdorf",
            "l": "Thüringen"
        },
        {
            "o": "Deesbach",
            "l": "Thüringen"
        },
        {
            "o": "Meura",
            "l": "Thüringen"
        },
        {
            "o": "Schwarzatal",
            "l": "Thüringen"
        },
        {
            "o": "Unterweißbach",
            "l": "Thüringen"
        }
    ],
    "98746": [
        {
            "o": "Goldisthal",
            "l": "Thüringen"
        },
        {
            "o": "Katzhütte",
            "l": "Thüringen"
        }
    ],
    "99084": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99085": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99086": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99087": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99089": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99090": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99091": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99092": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99094": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99095": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99096": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99097": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99098": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99099": [
        {
            "o": "Erfurt",
            "l": "Thüringen"
        }
    ],
    "99100": [
        {
            "o": "Bienstädt",
            "l": "Thüringen"
        },
        {
            "o": "Dachwig",
            "l": "Thüringen"
        },
        {
            "o": "Döllstädt",
            "l": "Thüringen"
        },
        {
            "o": "Gierstädt",
            "l": "Thüringen"
        },
        {
            "o": "Großfahner",
            "l": "Thüringen"
        },
        {
            "o": "Zimmernsupra",
            "l": "Thüringen"
        }
    ],
    "99102": [
        {
            "o": "Klettbach",
            "l": "Thüringen"
        },
        {
            "o": "Rockhausen",
            "l": "Thüringen"
        }
    ],
    "99189": [
        {
            "o": "Andisleben",
            "l": "Thüringen"
        },
        {
            "o": "Elxleben",
            "l": "Thüringen"
        },
        {
            "o": "Gebesee",
            "l": "Thüringen"
        },
        {
            "o": "Haßleben",
            "l": "Thüringen"
        },
        {
            "o": "Ringleben",
            "l": "Thüringen"
        },
        {
            "o": "Walschleben",
            "l": "Thüringen"
        },
        {
            "o": "Witterda",
            "l": "Thüringen"
        }
    ],
    "99192": [
        {
            "o": "Nesse-Apfelstädt",
            "l": "Thüringen"
        },
        {
            "o": "Nottleben",
            "l": "Thüringen"
        }
    ],
    "99195": [
        {
            "o": "Alperstedt",
            "l": "Thüringen"
        },
        {
            "o": "Eckstedt",
            "l": "Thüringen"
        },
        {
            "o": "Großrudestedt",
            "l": "Thüringen"
        },
        {
            "o": "Markvippach",
            "l": "Thüringen"
        },
        {
            "o": "Nöda",
            "l": "Thüringen"
        },
        {
            "o": "Riethnordhausen",
            "l": "Thüringen"
        },
        {
            "o": "Schloßvippach",
            "l": "Thüringen"
        }
    ],
    "99198": [
        {
            "o": "Großmölsen",
            "l": "Thüringen"
        },
        {
            "o": "Kleinmölsen",
            "l": "Thüringen"
        },
        {
            "o": "Mönchenholzhausen",
            "l": "Thüringen"
        },
        {
            "o": "Ollendorf",
            "l": "Thüringen"
        },
        {
            "o": "Udestedt",
            "l": "Thüringen"
        }
    ],
    "99310": [
        {
            "o": "Alkersleben",
            "l": "Thüringen"
        },
        {
            "o": "Arnstadt",
            "l": "Thüringen"
        },
        {
            "o": "Bösleben-Wüllersleben",
            "l": "Thüringen"
        },
        {
            "o": "Dornheim",
            "l": "Thüringen"
        },
        {
            "o": "Osthausen-Wülfershausen",
            "l": "Thüringen"
        },
        {
            "o": "Witzleben",
            "l": "Thüringen"
        }
    ],
    "99326": [
        {
            "o": "Stadtilm",
            "l": "Thüringen"
        }
    ],
    "99330": [
        {
            "o": "Geratal",
            "l": "Thüringen"
        }
    ],
    "99334": [
        {
            "o": "Amt Wachsenburg",
            "l": "Thüringen"
        },
        {
            "o": "Elleben",
            "l": "Thüringen"
        },
        {
            "o": "Elxleben",
            "l": "Thüringen"
        }
    ],
    "99338": [
        {
            "o": "Angelroda",
            "l": "Thüringen"
        },
        {
            "o": "Geratal",
            "l": "Thüringen"
        },
        {
            "o": "Plaue",
            "l": "Thüringen"
        }
    ],
    "99423": [
        {
            "o": "Weimar",
            "l": "Thüringen"
        }
    ],
    "99425": [
        {
            "o": "Weimar",
            "l": "Thüringen"
        }
    ],
    "99427": [
        {
            "o": "Weimar",
            "l": "Thüringen"
        }
    ],
    "99428": [
        {
            "o": "Bechstedtstraß",
            "l": "Thüringen"
        },
        {
            "o": "Daasdorf am Berge",
            "l": "Thüringen"
        },
        {
            "o": "Hopfgarten",
            "l": "Thüringen"
        },
        {
            "o": "Isseroda",
            "l": "Thüringen"
        },
        {
            "o": "Niederzimmern",
            "l": "Thüringen"
        },
        {
            "o": "Nohra",
            "l": "Thüringen"
        },
        {
            "o": "Ottstedt am Berge",
            "l": "Thüringen"
        },
        {
            "o": "Weimar",
            "l": "Thüringen"
        }
    ],
    "99438": [
        {
            "o": "Bad Berka",
            "l": "Thüringen"
        },
        {
            "o": "Buchfart",
            "l": "Thüringen"
        },
        {
            "o": "Hetschburg",
            "l": "Thüringen"
        },
        {
            "o": "Oettern",
            "l": "Thüringen"
        },
        {
            "o": "Tonndorf",
            "l": "Thüringen"
        },
        {
            "o": "Troistedt",
            "l": "Thüringen"
        },
        {
            "o": "Vollersroda",
            "l": "Thüringen"
        }
    ],
    "99439": [
        {
            "o": "Am Ettersberg",
            "l": "Thüringen"
        },
        {
            "o": "Ballstedt",
            "l": "Thüringen"
        },
        {
            "o": "Ettersburg",
            "l": "Thüringen"
        },
        {
            "o": "Neumark",
            "l": "Thüringen"
        }
    ],
    "99441": [
        {
            "o": "Döbritschen",
            "l": "Thüringen"
        },
        {
            "o": "Frankendorf",
            "l": "Thüringen"
        },
        {
            "o": "Großschwabhausen",
            "l": "Thüringen"
        },
        {
            "o": "Hammerstedt",
            "l": "Thüringen"
        },
        {
            "o": "Kiliansroda",
            "l": "Thüringen"
        },
        {
            "o": "Kleinschwabhausen",
            "l": "Thüringen"
        },
        {
            "o": "Lehnstedt",
            "l": "Thüringen"
        },
        {
            "o": "Magdala",
            "l": "Thüringen"
        },
        {
            "o": "Mechelroda",
            "l": "Thüringen"
        },
        {
            "o": "Mellingen",
            "l": "Thüringen"
        },
        {
            "o": "Umpferstedt",
            "l": "Thüringen"
        }
    ],
    "99444": [
        {
            "o": "Blankenhain",
            "l": "Thüringen"
        }
    ],
    "99448": [
        {
            "o": "Hohenfelden",
            "l": "Thüringen"
        },
        {
            "o": "Kranichfeld",
            "l": "Thüringen"
        },
        {
            "o": "Nauendorf",
            "l": "Thüringen"
        },
        {
            "o": "Rittersdorf",
            "l": "Thüringen"
        }
    ],
    "99510": [
        {
            "o": "Apolda",
            "l": "Thüringen"
        },
        {
            "o": "Ilmtal-Weinstraße",
            "l": "Thüringen"
        },
        {
            "o": "Kapellendorf",
            "l": "Thüringen"
        },
        {
            "o": "Obertrebra",
            "l": "Thüringen"
        },
        {
            "o": "Saaleplatte",
            "l": "Thüringen"
        },
        {
            "o": "Wiegendorf",
            "l": "Thüringen"
        }
    ],
    "99518": [
        {
            "o": "Bad Sulza",
            "l": "Thüringen"
        },
        {
            "o": "Eberstedt",
            "l": "Thüringen"
        },
        {
            "o": "Großheringen",
            "l": "Thüringen"
        },
        {
            "o": "Niedertrebra",
            "l": "Thüringen"
        },
        {
            "o": "Rannstedt",
            "l": "Thüringen"
        },
        {
            "o": "Schmiedehausen",
            "l": "Thüringen"
        }
    ],
    "99610": [
        {
            "o": "Sömmerda",
            "l": "Thüringen"
        },
        {
            "o": "Sprötau",
            "l": "Thüringen"
        },
        {
            "o": "Vogelsberg",
            "l": "Thüringen"
        },
        {
            "o": "Wundersleben",
            "l": "Thüringen"
        }
    ],
    "99625": [
        {
            "o": "Großneuhausen",
            "l": "Thüringen"
        },
        {
            "o": "Kleinneuhausen",
            "l": "Thüringen"
        },
        {
            "o": "Kölleda",
            "l": "Thüringen"
        },
        {
            "o": "Sömmerda",
            "l": "Thüringen"
        }
    ],
    "99628": [
        {
            "o": "Buttstädt",
            "l": "Thüringen"
        }
    ],
    "99631": [
        {
            "o": "Günstedt",
            "l": "Thüringen"
        },
        {
            "o": "Weißensee",
            "l": "Thüringen"
        }
    ],
    "99634": [
        {
            "o": "Gangloffsömmern",
            "l": "Thüringen"
        },
        {
            "o": "Henschleben",
            "l": "Thüringen"
        },
        {
            "o": "Schwerstedt",
            "l": "Thüringen"
        },
        {
            "o": "Straußfurt",
            "l": "Thüringen"
        },
        {
            "o": "Werningshausen",
            "l": "Thüringen"
        }
    ],
    "99636": [
        {
            "o": "Ostramondra",
            "l": "Thüringen"
        },
        {
            "o": "Rastenberg",
            "l": "Thüringen"
        }
    ],
    "99638": [
        {
            "o": "Büchel",
            "l": "Thüringen"
        },
        {
            "o": "Griefstedt",
            "l": "Thüringen"
        },
        {
            "o": "Kindelbrück",
            "l": "Thüringen"
        },
        {
            "o": "Riethgen",
            "l": "Thüringen"
        }
    ],
    "99706": [
        {
            "o": "Sondershausen",
            "l": "Thüringen"
        }
    ],
    "99707": [
        {
            "o": "Kyffhäuserland",
            "l": "Thüringen"
        }
    ],
    "99713": [
        {
            "o": "Abtsbessingen",
            "l": "Thüringen"
        },
        {
            "o": "Bellstedt",
            "l": "Thüringen"
        },
        {
            "o": "Ebeleben",
            "l": "Thüringen"
        },
        {
            "o": "Freienbessingen",
            "l": "Thüringen"
        },
        {
            "o": "Helbedündorf",
            "l": "Thüringen"
        },
        {
            "o": "Holzsußra",
            "l": "Thüringen"
        },
        {
            "o": "Rockstedt",
            "l": "Thüringen"
        },
        {
            "o": "Thüringenhausen",
            "l": "Thüringen"
        },
        {
            "o": "Wolferschwenda",
            "l": "Thüringen"
        }
    ],
    "99718": [
        {
            "o": "Clingen",
            "l": "Thüringen"
        },
        {
            "o": "Greußen",
            "l": "Thüringen"
        },
        {
            "o": "Großenehrich",
            "l": "Thüringen"
        },
        {
            "o": "Niederbösa",
            "l": "Thüringen"
        },
        {
            "o": "Oberbösa",
            "l": "Thüringen"
        },
        {
            "o": "Topfstedt",
            "l": "Thüringen"
        },
        {
            "o": "Trebra",
            "l": "Thüringen"
        },
        {
            "o": "Wasserthaleben",
            "l": "Thüringen"
        },
        {
            "o": "Westgreußen",
            "l": "Thüringen"
        }
    ],
    "99734": [
        {
            "o": "Nordhausen",
            "l": "Thüringen"
        }
    ],
    "99735": [
        {
            "o": "Bleicherode",
            "l": "Thüringen"
        },
        {
            "o": "Kleinfurra",
            "l": "Thüringen"
        },
        {
            "o": "Werther",
            "l": "Thüringen"
        }
    ],
    "99752": [
        {
            "o": "Bleicherode",
            "l": "Thüringen"
        },
        {
            "o": "Kehmstedt",
            "l": "Thüringen"
        },
        {
            "o": "Lipprechterode",
            "l": "Thüringen"
        }
    ],
    "99755": [
        {
            "o": "Ellrich",
            "l": "Thüringen"
        },
        {
            "o": "Hohenstein",
            "l": "Thüringen"
        }
    ],
    "99759": [
        {
            "o": "Bleicherode",
            "l": "Thüringen"
        },
        {
            "o": "Großlohra",
            "l": "Thüringen"
        },
        {
            "o": "Niedergebra",
            "l": "Thüringen"
        },
        {
            "o": "Sollstedt",
            "l": "Thüringen"
        }
    ],
    "99765": [
        {
            "o": "Görsbach",
            "l": "Thüringen"
        },
        {
            "o": "Heringen/Helme",
            "l": "Thüringen"
        },
        {
            "o": "Urbach",
            "l": "Thüringen"
        }
    ],
    "99768": [
        {
            "o": "Harztor",
            "l": "Thüringen"
        }
    ],
    "99817": [
        {
            "o": "Eisenach",
            "l": "Thüringen"
        }
    ],
    "99819": [
        {
            "o": "Bad Salzungen",
            "l": "Thüringen"
        },
        {
            "o": "Gerstungen",
            "l": "Thüringen"
        },
        {
            "o": "Krauthausen",
            "l": "Thüringen"
        }
    ],
    "99820": [
        {
            "o": "Hörselberg-Hainich",
            "l": "Thüringen"
        }
    ],
    "99826": [
        {
            "o": "Berka vor dem Hainich",
            "l": "Thüringen"
        },
        {
            "o": "Bischofroda",
            "l": "Thüringen"
        },
        {
            "o": "Ebenshausen",
            "l": "Thüringen"
        },
        {
            "o": "Frankenroda",
            "l": "Thüringen"
        },
        {
            "o": "Hallungen",
            "l": "Thüringen"
        },
        {
            "o": "Lauterbach",
            "l": "Thüringen"
        },
        {
            "o": "Mihla",
            "l": "Thüringen"
        },
        {
            "o": "Nazza",
            "l": "Thüringen"
        }
    ],
    "99830": [
        {
            "o": "Treffurt",
            "l": "Thüringen"
        }
    ],
    "99831": [
        {
            "o": "Creuzburg",
            "l": "Thüringen"
        }
    ],
    "99834": [
        {
            "o": "Gerstungen",
            "l": "Thüringen"
        }
    ],
    "99837": [
        {
            "o": "Werra-Suhl-Tal",
            "l": "Thüringen"
        }
    ],
    "99842": [
        {
            "o": "Ruhla",
            "l": "Thüringen"
        }
    ],
    "99846": [
        {
            "o": "Seebach",
            "l": "Thüringen"
        }
    ],
    "99848": [
        {
            "o": "Wutha-Farnroda",
            "l": "Thüringen"
        }
    ],
    "99867": [
        {
            "o": "Gotha",
            "l": "Thüringen"
        }
    ],
    "99869": [
        {
            "o": "Drei Gleichen",
            "l": "Thüringen"
        },
        {
            "o": "Emleben",
            "l": "Thüringen"
        },
        {
            "o": "Eschenbergen",
            "l": "Thüringen"
        },
        {
            "o": "Friemar",
            "l": "Thüringen"
        },
        {
            "o": "Hörsel",
            "l": "Thüringen"
        },
        {
            "o": "Molschleben",
            "l": "Thüringen"
        },
        {
            "o": "Nessetal",
            "l": "Thüringen"
        },
        {
            "o": "Pferdingsleben",
            "l": "Thüringen"
        },
        {
            "o": "Schwabhausen",
            "l": "Thüringen"
        },
        {
            "o": "Sonneborn",
            "l": "Thüringen"
        },
        {
            "o": "Tröchtelborn",
            "l": "Thüringen"
        },
        {
            "o": "Tüttleben",
            "l": "Thüringen"
        }
    ],
    "99880": [
        {
            "o": "Hörsel",
            "l": "Thüringen"
        },
        {
            "o": "Waltershausen",
            "l": "Thüringen"
        }
    ],
    "99885": [
        {
            "o": "Luisenthal",
            "l": "Thüringen"
        },
        {
            "o": "Ohrdruf",
            "l": "Thüringen"
        }
    ],
    "99887": [
        {
            "o": "Georgenthal",
            "l": "Thüringen"
        },
        {
            "o": "Herrenhof",
            "l": "Thüringen"
        },
        {
            "o": "Hohenkirchen",
            "l": "Thüringen"
        },
        {
            "o": "Petriroda",
            "l": "Thüringen"
        }
    ],
    "99891": [
        {
            "o": "Bad Tabarz",
            "l": "Thüringen"
        }
    ],
    "99894": [
        {
            "o": "Friedrichroda",
            "l": "Thüringen"
        },
        {
            "o": "Leinatal",
            "l": "Thüringen"
        }
    ],
    "99897": [
        {
            "o": "Tambach-Dietharz",
            "l": "Thüringen"
        }
    ],
    "99947": [
        {
            "o": "Bad Langensalza",
            "l": "Thüringen"
        },
        {
            "o": "Bothenheilingen",
            "l": "Thüringen"
        },
        {
            "o": "Issersheilingen",
            "l": "Thüringen"
        },
        {
            "o": "Kirchheilingen",
            "l": "Thüringen"
        },
        {
            "o": "Kleinwelsbach",
            "l": "Thüringen"
        },
        {
            "o": "Neunheilingen",
            "l": "Thüringen"
        },
        {
            "o": "Schönstedt",
            "l": "Thüringen"
        },
        {
            "o": "Sundhausen",
            "l": "Thüringen"
        },
        {
            "o": "Tottleben",
            "l": "Thüringen"
        },
        {
            "o": "Unstrut-Hainich",
            "l": "Thüringen"
        }
    ],
    "99955": [
        {
            "o": "Bad Tennstedt",
            "l": "Thüringen"
        },
        {
            "o": "Ballhausen",
            "l": "Thüringen"
        },
        {
            "o": "Blankenburg",
            "l": "Thüringen"
        },
        {
            "o": "Bruchstedt",
            "l": "Thüringen"
        },
        {
            "o": "Haussömmern",
            "l": "Thüringen"
        },
        {
            "o": "Herbsleben",
            "l": "Thüringen"
        },
        {
            "o": "Hornsömmern",
            "l": "Thüringen"
        },
        {
            "o": "Kutzleben",
            "l": "Thüringen"
        },
        {
            "o": "Mittelsömmern",
            "l": "Thüringen"
        },
        {
            "o": "Urleben",
            "l": "Thüringen"
        }
    ],
    "99958": [
        {
            "o": "Großvargula",
            "l": "Thüringen"
        },
        {
            "o": "Tonna",
            "l": "Thüringen"
        }
    ],
    "99974": [
        {
            "o": "Mühlhausen",
            "l": "Thüringen"
        },
        {
            "o": "Unstruttal",
            "l": "Thüringen"
        }
    ],
    "99976": [
        {
            "o": "Anrode",
            "l": "Thüringen"
        },
        {
            "o": "Dünwald",
            "l": "Thüringen"
        },
        {
            "o": "Menteroda",
            "l": "Thüringen"
        },
        {
            "o": "Rodeberg",
            "l": "Thüringen"
        },
        {
            "o": "Südeichsfeld",
            "l": "Thüringen"
        },
        {
            "o": "Unstruttal",
            "l": "Thüringen"
        }
    ],
    "99986": [
        {
            "o": "Kammerforst",
            "l": "Thüringen"
        },
        {
            "o": "Oppershausen",
            "l": "Thüringen"
        },
        {
            "o": "Unstrut-Hainich",
            "l": "Thüringen"
        },
        {
            "o": "Vogtei",
            "l": "Thüringen"
        }
    ],
    "99988": [
        {
            "o": "Südeichsfeld",
            "l": "Thüringen"
        }
    ],
    "99991": [
        {
            "o": "Unstrut-Hainich",
            "l": "Thüringen"
        }
    ],
    "99994": [
        {
            "o": "Marolterode",
            "l": "Thüringen"
        },
        {
            "o": "Schlotheim",
            "l": "Thüringen"
        }
    ],
    "99996": [
        {
            "o": "Menteroda",
            "l": "Thüringen"
        },
        {
            "o": "Obermehler",
            "l": "Thüringen"
        }
    ],
    "99998": [
        {
            "o": "Körner",
            "l": "Thüringen"
        },
        {
            "o": "Mühlhausen",
            "l": "Thüringen"
        }
    ],
    "08626": [
        {
            "o": "Adorf/Vogtland",
            "l": "Sachsen"
        },
        {
            "o": "Eichigt",
            "l": "Sachsen"
        },
        {
            "o": "Mühlental",
            "l": "Sachsen"
        }
    ],
    "06313": [
        {
            "o": "Ahlsdorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hergisdorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wimmelburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06385": [
        {
            "o": "Aken (Elbe)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06386": [
        {
            "o": "Aken (Elbe)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Osternienburger Land",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Südliches Anhalt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07646": [
        {
            "o": "Albersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Bobeck",
            "l": "Thüringen"
        },
        {
            "o": "Bremsnitz",
            "l": "Thüringen"
        },
        {
            "o": "Eineborn",
            "l": "Thüringen"
        },
        {
            "o": "Geisenhain",
            "l": "Thüringen"
        },
        {
            "o": "Gneus",
            "l": "Thüringen"
        },
        {
            "o": "Großbockedra",
            "l": "Thüringen"
        },
        {
            "o": "Karlsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Kleinbockedra",
            "l": "Thüringen"
        },
        {
            "o": "Kleinebersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Laasdorf",
            "l": "Thüringen"
        },
        {
            "o": "Lippersdorf-Erdmannsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Meusebach",
            "l": "Thüringen"
        },
        {
            "o": "Möckern",
            "l": "Thüringen"
        },
        {
            "o": "Mörsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Oberbodnitz",
            "l": "Thüringen"
        },
        {
            "o": "Ottendorf",
            "l": "Thüringen"
        },
        {
            "o": "Rattelsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Rausdorf",
            "l": "Thüringen"
        },
        {
            "o": "Renthendorf",
            "l": "Thüringen"
        },
        {
            "o": "Ruttersdorf-Lotschen",
            "l": "Thüringen"
        },
        {
            "o": "Scheiditz",
            "l": "Thüringen"
        },
        {
            "o": "Schlöben",
            "l": "Thüringen"
        },
        {
            "o": "Schöngleina",
            "l": "Thüringen"
        },
        {
            "o": "Stadtroda",
            "l": "Thüringen"
        },
        {
            "o": "Tautendorf",
            "l": "Thüringen"
        },
        {
            "o": "Tissa",
            "l": "Thüringen"
        },
        {
            "o": "Tröbnitz",
            "l": "Thüringen"
        },
        {
            "o": "Trockenborn-Wolfersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Unterbodnitz",
            "l": "Thüringen"
        },
        {
            "o": "Waldeck",
            "l": "Thüringen"
        },
        {
            "o": "Waltersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Weißbach",
            "l": "Thüringen"
        }
    ],
    "07426": [
        {
            "o": "Allendorf",
            "l": "Thüringen"
        },
        {
            "o": "Bechstedt",
            "l": "Thüringen"
        },
        {
            "o": "Königsee",
            "l": "Thüringen"
        }
    ],
    "06542": [
        {
            "o": "Allstedt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06425": [
        {
            "o": "Alsleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Plötzkau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "03229": [
        {
            "o": "Altdöbern",
            "l": "Brandenburg"
        },
        {
            "o": "Luckaitztal",
            "l": "Brandenburg"
        }
    ],
    "01773": [
        {
            "o": "Altenberg",
            "l": "Sachsen"
        }
    ],
    "01778": [
        {
            "o": "Altenberg",
            "l": "Sachsen"
        }
    ],
    "07768": [
        {
            "o": "Altenberga",
            "l": "Thüringen"
        },
        {
            "o": "Bibra",
            "l": "Thüringen"
        },
        {
            "o": "Eichenberg",
            "l": "Thüringen"
        },
        {
            "o": "Freienorla",
            "l": "Thüringen"
        },
        {
            "o": "Großeutersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Gumperda",
            "l": "Thüringen"
        },
        {
            "o": "Hummelshain",
            "l": "Thüringen"
        },
        {
            "o": "Kahla",
            "l": "Thüringen"
        },
        {
            "o": "Kleineutersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Lindig",
            "l": "Thüringen"
        },
        {
            "o": "Orlamünde",
            "l": "Thüringen"
        },
        {
            "o": "Reinstädt",
            "l": "Thüringen"
        },
        {
            "o": "Schöps",
            "l": "Thüringen"
        },
        {
            "o": "Seitenroda",
            "l": "Thüringen"
        }
    ],
    "07338": [
        {
            "o": "Altenbeuthen",
            "l": "Thüringen"
        },
        {
            "o": "Drognitz",
            "l": "Thüringen"
        },
        {
            "o": "Hohenwarte",
            "l": "Thüringen"
        },
        {
            "o": "Kaulsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Leutenberg",
            "l": "Thüringen"
        }
    ],
    "04600": [
        {
            "o": "Altenburg",
            "l": "Thüringen"
        }
    ],
    "09648": [
        {
            "o": "Altmittweida",
            "l": "Sachsen"
        },
        {
            "o": "Kriebstein",
            "l": "Sachsen"
        },
        {
            "o": "Mittweida",
            "l": "Sachsen"
        }
    ],
    "09439": [
        {
            "o": "Amtsberg",
            "l": "Sachsen"
        }
    ],
    "06647": [
        {
            "o": "An der Poststraße",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Bad Bibra",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Finne",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Finneland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06556": [
        {
            "o": "An der Schmücke",
            "l": "Thüringen"
        },
        {
            "o": "Artern",
            "l": "Thüringen"
        },
        {
            "o": "Borxleben",
            "l": "Thüringen"
        },
        {
            "o": "Kalbsrieth",
            "l": "Thüringen"
        },
        {
            "o": "Mönchpfiffel-Nikolausrieth",
            "l": "Thüringen"
        },
        {
            "o": "Reinsdorf",
            "l": "Thüringen"
        }
    ],
    "06577": [
        {
            "o": "An der Schmücke",
            "l": "Thüringen"
        },
        {
            "o": "Etzleben",
            "l": "Thüringen"
        },
        {
            "o": "Oberheldrungen",
            "l": "Thüringen"
        }
    ],
    "06578": [
        {
            "o": "An der Schmücke",
            "l": "Thüringen"
        },
        {
            "o": "Kindelbrück",
            "l": "Thüringen"
        }
    ],
    "09456": [
        {
            "o": "Annaberg-Buchholz",
            "l": "Sachsen"
        },
        {
            "o": "Mildenau",
            "l": "Sachsen"
        }
    ],
    "06925": [
        {
            "o": "Annaburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01477": [
        {
            "o": "Arnsdorf",
            "l": "Sachsen"
        }
    ],
    "06456": [
        {
            "o": "Arnstein",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04886": [
        {
            "o": "Arzberg",
            "l": "Sachsen"
        },
        {
            "o": "Beilrode",
            "l": "Sachsen"
        }
    ],
    "06449": [
        {
            "o": "Aschersleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Giersleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Seeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08280": [
        {
            "o": "Aue-Bad Schlema",
            "l": "Sachsen"
        }
    ],
    "08301": [
        {
            "o": "Aue-Bad Schlema",
            "l": "Sachsen"
        }
    ],
    "09392": [
        {
            "o": "Auerbach",
            "l": "Sachsen"
        }
    ],
    "08209": [
        {
            "o": "Auerbach/Vogtland",
            "l": "Sachsen"
        }
    ],
    "09573": [
        {
            "o": "Augustusburg",
            "l": "Sachsen"
        },
        {
            "o": "Gornau/Erzgebirge",
            "l": "Sachsen"
        },
        {
            "o": "Leubsdorf",
            "l": "Sachsen"
        }
    ],
    "07955": [
        {
            "o": "Auma-Weidatal",
            "l": "Thüringen"
        }
    ],
    "07422": [
        {
            "o": "Bad Blankenburg",
            "l": "Thüringen"
        }
    ],
    "08648": [
        {
            "o": "Bad Brambach",
            "l": "Sachsen"
        }
    ],
    "04849": [
        {
            "o": "Bad Düben",
            "l": "Sachsen"
        },
        {
            "o": "Laußig",
            "l": "Sachsen"
        }
    ],
    "06231": [
        {
            "o": "Bad Dürrenberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08645": [
        {
            "o": "Bad Elster",
            "l": "Sachsen"
        }
    ],
    "06567": [
        {
            "o": "Bad Frankenhausen/Kyffhäuser",
            "l": "Thüringen"
        }
    ],
    "01816": [
        {
            "o": "Bad Gottleuba-Berggießhübel",
            "l": "Sachsen"
        }
    ],
    "07639": [
        {
            "o": "Bad Klosterlausnitz",
            "l": "Thüringen"
        },
        {
            "o": "Tautenhain",
            "l": "Thüringen"
        },
        {
            "o": "Weißenborn",
            "l": "Thüringen"
        }
    ],
    "07586": [
        {
            "o": "Bad Köstritz",
            "l": "Thüringen"
        },
        {
            "o": "Caaschwitz",
            "l": "Thüringen"
        },
        {
            "o": "Hartmannsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Kraftsdorf",
            "l": "Thüringen"
        }
    ],
    "04651": [
        {
            "o": "Bad Lausick",
            "l": "Sachsen"
        }
    ],
    "04895": [
        {
            "o": "Bad Liebenwerda",
            "l": "Brandenburg"
        },
        {
            "o": "Falkenberg/Elster",
            "l": "Brandenburg"
        },
        {
            "o": "Mühlberg/Elbe",
            "l": "Brandenburg"
        },
        {
            "o": "Uebigau-Wahrenbrück",
            "l": "Brandenburg"
        }
    ],
    "04924": [
        {
            "o": "Bad Liebenwerda",
            "l": "Brandenburg"
        },
        {
            "o": "Uebigau-Wahrenbrück",
            "l": "Brandenburg"
        }
    ],
    "04931": [
        {
            "o": "Bad Liebenwerda",
            "l": "Brandenburg"
        },
        {
            "o": "Mühlberg/Elbe",
            "l": "Brandenburg"
        }
    ],
    "07356": [
        {
            "o": "Bad Lobenstein",
            "l": "Thüringen"
        }
    ],
    "02953": [
        {
            "o": "Bad Muskau - Mužakow",
            "l": "Sachsen"
        },
        {
            "o": "Gablenz - Jabłońc",
            "l": "Sachsen"
        },
        {
            "o": "Groß Düben - Dźěwin",
            "l": "Sachsen"
        }
    ],
    "01814": [
        {
            "o": "Bad Schandau",
            "l": "Sachsen"
        },
        {
            "o": "Rathmannsdorf",
            "l": "Sachsen"
        },
        {
            "o": "Reinhardtsdorf-Schöna",
            "l": "Sachsen"
        }
    ],
    "06905": [
        {
            "o": "Bad Schmiedeberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01819": [
        {
            "o": "Bahretal",
            "l": "Sachsen"
        }
    ],
    "06632": [
        {
            "o": "Balgstädt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Freyburg (Unstrut)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Gleina",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Mücheln (Geiseltal)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06493": [
        {
            "o": "Ballenstedt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Harzgerode",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01728": [
        {
            "o": "Bannewitz",
            "l": "Sachsen"
        }
    ],
    "09471": [
        {
            "o": "Bärenstein",
            "l": "Sachsen"
        },
        {
            "o": "Königswalde",
            "l": "Sachsen"
        }
    ],
    "06268": [
        {
            "o": "Barnstädt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Mücheln (Geiseltal)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Nemsdorf-Göhrendorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Obhausen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Querfurt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Steigra",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02625": [
        {
            "o": "Bautzen - Budyšin",
            "l": "Sachsen"
        }
    ],
    "02736": [
        {
            "o": "Beiersdorf",
            "l": "Sachsen"
        },
        {
            "o": "Oppach",
            "l": "Sachsen"
        }
    ],
    "04874": [
        {
            "o": "Belgern-Schildau",
            "l": "Sachsen"
        }
    ],
    "04889": [
        {
            "o": "Belgern-Schildau",
            "l": "Sachsen"
        }
    ],
    "04683": [
        {
            "o": "Belgershain",
            "l": "Sachsen"
        },
        {
            "o": "Naunhof",
            "l": "Sachsen"
        }
    ],
    "06308": [
        {
            "o": "Benndorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Klostermansfeld",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04828": [
        {
            "o": "Bennewitz",
            "l": "Sachsen"
        }
    ],
    "06536": [
        {
            "o": "Berga",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Südharz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07980": [
        {
            "o": "Berga/Elster",
            "l": "Thüringen"
        },
        {
            "o": "Kühdorf",
            "l": "Thüringen"
        },
        {
            "o": "Neumühle/Elster",
            "l": "Thüringen"
        }
    ],
    "08239": [
        {
            "o": "Bergen",
            "l": "Sachsen"
        },
        {
            "o": "Falkenstein/Vogtland",
            "l": "Sachsen"
        }
    ],
    "06406": [
        {
            "o": "Bernburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09337": [
        {
            "o": "Bernsdorf",
            "l": "Sachsen"
        },
        {
            "o": "Callenberg",
            "l": "Sachsen"
        },
        {
            "o": "Hohenstein-Ernstthal",
            "l": "Sachsen"
        }
    ],
    "02994": [
        {
            "o": "Bernsdorf",
            "l": "Sachsen"
        }
    ],
    "02748": [
        {
            "o": "Bernstadt auf dem Eigen",
            "l": "Sachsen"
        }
    ],
    "02763": [
        {
            "o": "Bertsdorf-Hörnitz",
            "l": "Sachsen"
        },
        {
            "o": "Mittelherwigsdorf",
            "l": "Sachsen"
        },
        {
            "o": "Zittau",
            "l": "Sachsen"
        }
    ],
    "07554": [
        {
            "o": "Bethenhausen",
            "l": "Thüringen"
        },
        {
            "o": "Brahmenau",
            "l": "Thüringen"
        },
        {
            "o": "Gera",
            "l": "Thüringen"
        },
        {
            "o": "Hirschfeld",
            "l": "Thüringen"
        },
        {
            "o": "Kauern",
            "l": "Thüringen"
        },
        {
            "o": "Korbußen",
            "l": "Thüringen"
        },
        {
            "o": "Pölzig",
            "l": "Thüringen"
        },
        {
            "o": "Schwaara",
            "l": "Thüringen"
        }
    ],
    "01877": [
        {
            "o": "Bischofswerda",
            "l": "Sachsen"
        },
        {
            "o": "Demitz-Thumitz",
            "l": "Sachsen"
        },
        {
            "o": "Doberschau-Gaußig - Dobruša-Huska",
            "l": "Sachsen"
        },
        {
            "o": "Rammenau",
            "l": "Sachsen"
        },
        {
            "o": "Schmölln-Putzkau",
            "l": "Sachsen"
        }
    ],
    "06749": [
        {
            "o": "Bitterfeld-Wolfen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06766": [
        {
            "o": "Bitterfeld-Wolfen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06803": [
        {
            "o": "Bitterfeld-Wolfen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06808": [
        {
            "o": "Bitterfeld-Wolfen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06502": [
        {
            "o": "Blankenburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Thale",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06528": [
        {
            "o": "Blankenheim",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Brücken-Hackpfüffel",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Edersleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wallhausen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09627": [
        {
            "o": "Bobritzsch-Hilbersdorf",
            "l": "Sachsen"
        }
    ],
    "07589": [
        {
            "o": "Bocka",
            "l": "Thüringen"
        },
        {
            "o": "Lederhose",
            "l": "Thüringen"
        },
        {
            "o": "Lindenkreuz",
            "l": "Thüringen"
        },
        {
            "o": "Münchenbernsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Saara",
            "l": "Thüringen"
        },
        {
            "o": "Schwarzbach",
            "l": "Thüringen"
        }
    ],
    "08324": [
        {
            "o": "Bockau",
            "l": "Sachsen"
        }
    ],
    "07381": [
        {
            "o": "Bodelwitz",
            "l": "Thüringen"
        },
        {
            "o": "Döbritz",
            "l": "Thüringen"
        },
        {
            "o": "Langenorla",
            "l": "Thüringen"
        },
        {
            "o": "Moxa",
            "l": "Thüringen"
        },
        {
            "o": "Nimritz",
            "l": "Thüringen"
        },
        {
            "o": "Oberoppurg",
            "l": "Thüringen"
        },
        {
            "o": "Oppurg",
            "l": "Thüringen"
        },
        {
            "o": "Paska",
            "l": "Thüringen"
        },
        {
            "o": "Pößneck",
            "l": "Thüringen"
        },
        {
            "o": "Solkwitz",
            "l": "Thüringen"
        },
        {
            "o": "Wernburg",
            "l": "Thüringen"
        }
    ],
    "04564": [
        {
            "o": "Böhlen",
            "l": "Sachsen"
        }
    ],
    "04552": [
        {
            "o": "Borna",
            "l": "Sachsen"
        }
    ],
    "09437": [
        {
            "o": "Börnichen/Erzgebirge",
            "l": "Sachsen"
        },
        {
            "o": "Gornau/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "06295": [
        {
            "o": "Bornstedt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Lutherstadt Eisleben",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04451": [
        {
            "o": "Borsdorf",
            "l": "Sachsen"
        }
    ],
    "08606": [
        {
            "o": "Bösenbrunn",
            "l": "Sachsen"
        },
        {
            "o": "Oelsnitz/Vogtl.",
            "l": "Sachsen"
        },
        {
            "o": "Tirpersdorf",
            "l": "Sachsen"
        },
        {
            "o": "Triebel",
            "l": "Sachsen"
        }
    ],
    "02943": [
        {
            "o": "Boxberg/O.L. - Hamor",
            "l": "Sachsen"
        },
        {
            "o": "Weißwasser/O.L. - Běła Woda",
            "l": "Sachsen"
        }
    ],
    "09618": [
        {
            "o": "Brand-Erbisdorf",
            "l": "Sachsen"
        },
        {
            "o": "Großhartmannsdorf",
            "l": "Sachsen"
        }
    ],
    "04821": [
        {
            "o": "Brandis",
            "l": "Sachsen"
        }
    ],
    "04824": [
        {
            "o": "Brandis",
            "l": "Sachsen"
        }
    ],
    "07580": [
        {
            "o": "Braunichswalde",
            "l": "Thüringen"
        },
        {
            "o": "Gauern",
            "l": "Thüringen"
        },
        {
            "o": "Großenstein",
            "l": "Thüringen"
        },
        {
            "o": "Hilbersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Linda b. Weida",
            "l": "Thüringen"
        },
        {
            "o": "Paitzdorf",
            "l": "Thüringen"
        },
        {
            "o": "Reichstädt",
            "l": "Thüringen"
        },
        {
            "o": "Ronneburg",
            "l": "Thüringen"
        },
        {
            "o": "Rückersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Seelingstädt",
            "l": "Thüringen"
        }
    ],
    "06242": [
        {
            "o": "Braunsbedra",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06259": [
        {
            "o": "Braunsbedra",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08359": [
        {
            "o": "Breitenbrunn/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "03096": [
        {
            "o": "Briesen",
            "l": "Brandenburg"
        },
        {
            "o": "Burg (Spreewald)",
            "l": "Brandenburg"
        },
        {
            "o": "Dissen-Striesow",
            "l": "Brandenburg"
        },
        {
            "o": "Guhrow",
            "l": "Brandenburg"
        },
        {
            "o": "Schmogrow-Fehrow",
            "l": "Brandenburg"
        },
        {
            "o": "Werben",
            "l": "Brandenburg"
        }
    ],
    "03205": [
        {
            "o": "Bronkow",
            "l": "Brandenburg"
        },
        {
            "o": "Calau",
            "l": "Brandenburg"
        }
    ],
    "07751": [
        {
            "o": "Bucha",
            "l": "Thüringen"
        },
        {
            "o": "Golmsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Großlöbichau",
            "l": "Thüringen"
        },
        {
            "o": "Großpürschütz",
            "l": "Thüringen"
        },
        {
            "o": "Jena",
            "l": "Thüringen"
        },
        {
            "o": "Jenalöbnitz",
            "l": "Thüringen"
        },
        {
            "o": "Löberschütz",
            "l": "Thüringen"
        },
        {
            "o": "Milda",
            "l": "Thüringen"
        },
        {
            "o": "Rothenstein",
            "l": "Thüringen"
        },
        {
            "o": "Sulza",
            "l": "Thüringen"
        },
        {
            "o": "Zöllnitz",
            "l": "Thüringen"
        }
    ],
    "07616": [
        {
            "o": "Bürgel",
            "l": "Thüringen"
        },
        {
            "o": "Graitschen bei Bürgel",
            "l": "Thüringen"
        },
        {
            "o": "Nausnitz",
            "l": "Thüringen"
        },
        {
            "o": "Petersberg",
            "l": "Thüringen"
        },
        {
            "o": "Poxdorf",
            "l": "Thüringen"
        },
        {
            "o": "Rauschwitz",
            "l": "Thüringen"
        },
        {
            "o": "Serba",
            "l": "Thüringen"
        }
    ],
    "07907": [
        {
            "o": "Burgk",
            "l": "Thüringen"
        },
        {
            "o": "Dittersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Görkwitz",
            "l": "Thüringen"
        },
        {
            "o": "Göschitz",
            "l": "Thüringen"
        },
        {
            "o": "Löhma",
            "l": "Thüringen"
        },
        {
            "o": "Moßbach",
            "l": "Thüringen"
        },
        {
            "o": "Oettersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Plothen",
            "l": "Thüringen"
        },
        {
            "o": "Pörmitz",
            "l": "Thüringen"
        },
        {
            "o": "Schleiz",
            "l": "Thüringen"
        },
        {
            "o": "Tegau",
            "l": "Thüringen"
        }
    ],
    "09217": [
        {
            "o": "Burgstädt",
            "l": "Sachsen"
        }
    ],
    "01906": [
        {
            "o": "Burkau - Porchow",
            "l": "Sachsen"
        }
    ],
    "09235": [
        {
            "o": "Burkhardtsdorf",
            "l": "Sachsen"
        }
    ],
    "04758": [
        {
            "o": "Cavertitz",
            "l": "Sachsen"
        },
        {
            "o": "Liebschützberg",
            "l": "Sachsen"
        },
        {
            "o": "Naundorf",
            "l": "Sachsen"
        },
        {
            "o": "Oschatz",
            "l": "Sachsen"
        }
    ],
    "09111": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09112": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09113": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09114": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09116": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09117": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09119": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09120": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09122": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09123": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09125": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09126": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09127": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09128": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09130": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09131": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09224": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09228": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09247": [
        {
            "o": "Chemnitz",
            "l": "Sachsen"
        }
    ],
    "09236": [
        {
            "o": "Claußnitz",
            "l": "Sachsen"
        }
    ],
    "04680": [
        {
            "o": "Colditz",
            "l": "Sachsen"
        }
    ],
    "01640": [
        {
            "o": "Coswig",
            "l": "Sachsen"
        }
    ],
    "06868": [
        {
            "o": "Coswig (Anhalt)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06869": [
        {
            "o": "Coswig (Anhalt)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "03042": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03044": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03046": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03048": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03050": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03051": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03052": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03053": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03054": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "03055": [
        {
            "o": "Cottbus - Chóśebuz",
            "l": "Brandenburg"
        }
    ],
    "07557": [
        {
            "o": "Crimla",
            "l": "Thüringen"
        },
        {
            "o": "Gera",
            "l": "Thüringen"
        },
        {
            "o": "Hundhaupten",
            "l": "Thüringen"
        },
        {
            "o": "Zedlitz",
            "l": "Thüringen"
        }
    ],
    "08451": [
        {
            "o": "Crimmitschau",
            "l": "Sachsen"
        }
    ],
    "03246": [
        {
            "o": "Crinitz",
            "l": "Brandenburg"
        },
        {
            "o": "Massen-Niederlausitz",
            "l": "Brandenburg"
        }
    ],
    "08147": [
        {
            "o": "Crinitzberg",
            "l": "Sachsen"
        }
    ],
    "07613": [
        {
            "o": "Crossen an der Elster",
            "l": "Thüringen"
        },
        {
            "o": "Hartmannsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Heideland",
            "l": "Thüringen"
        },
        {
            "o": "Rauda",
            "l": "Thüringen"
        },
        {
            "o": "Silbitz",
            "l": "Thüringen"
        },
        {
            "o": "Walpernhain",
            "l": "Thüringen"
        }
    ],
    "01920": [
        {
            "o": "Crostwitz - Chrósćicy",
            "l": "Sachsen"
        },
        {
            "o": "Elstra - Halštrow",
            "l": "Sachsen"
        },
        {
            "o": "Haselbachtal",
            "l": "Sachsen"
        },
        {
            "o": "Nebelschütz - Njebjelčicy",
            "l": "Sachsen"
        },
        {
            "o": "Oßling - Wóslink",
            "l": "Sachsen"
        },
        {
            "o": "Panschwitz-Kuckau - Panćicy-Kukow",
            "l": "Sachsen"
        },
        {
            "o": "Räckelwitz - Worklecy",
            "l": "Sachsen"
        },
        {
            "o": "Ralbitz-Rosenthal - Ralbicy-Róžant",
            "l": "Sachsen"
        },
        {
            "o": "Steina",
            "l": "Sachsen"
        }
    ],
    "09474": [
        {
            "o": "Crottendorf",
            "l": "Sachsen"
        }
    ],
    "02733": [
        {
            "o": "Cunewalde",
            "l": "Sachsen"
        }
    ],
    "04774": [
        {
            "o": "Dahlen",
            "l": "Sachsen"
        }
    ],
    "04509": [
        {
            "o": "Delitzsch",
            "l": "Sachsen"
        },
        {
            "o": "Krostitz",
            "l": "Sachsen"
        },
        {
            "o": "Löbnitz",
            "l": "Sachsen"
        },
        {
            "o": "Schönwölkau",
            "l": "Sachsen"
        },
        {
            "o": "Wiedemar",
            "l": "Sachsen"
        }
    ],
    "08393": [
        {
            "o": "Dennheritz",
            "l": "Sachsen"
        },
        {
            "o": "Meerane",
            "l": "Sachsen"
        },
        {
            "o": "Schönberg",
            "l": "Sachsen"
        }
    ],
    "06842": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06844": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06846": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06847": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06849": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06861": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06862": [
        {
            "o": "Dessau-Roßlau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09548": [
        {
            "o": "Deutschneudorf",
            "l": "Sachsen"
        },
        {
            "o": "Seiffen/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "01665": [
        {
            "o": "Diera-Zehren",
            "l": "Sachsen"
        },
        {
            "o": "Käbschütztal",
            "l": "Sachsen"
        },
        {
            "o": "Klipphausen",
            "l": "Sachsen"
        }
    ],
    "01744": [
        {
            "o": "Dippoldiswalde",
            "l": "Sachsen"
        }
    ],
    "06484": [
        {
            "o": "Ditfurt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Quedlinburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04720": [
        {
            "o": "Döbeln",
            "l": "Sachsen"
        },
        {
            "o": "Großweitzschen",
            "l": "Sachsen"
        },
        {
            "o": "Zschaitz-Ottewig",
            "l": "Sachsen"
        }
    ],
    "03253": [
        {
            "o": "Doberlug-Kirchhain",
            "l": "Brandenburg"
        },
        {
            "o": "Schilda",
            "l": "Brandenburg"
        },
        {
            "o": "Schönborn",
            "l": "Brandenburg"
        },
        {
            "o": "Tröbitz",
            "l": "Brandenburg"
        }
    ],
    "03159": [
        {
            "o": "Döbern",
            "l": "Brandenburg"
        },
        {
            "o": "Neiße-Malxetal",
            "l": "Brandenburg"
        }
    ],
    "02633": [
        {
            "o": "Doberschau-Gaußig - Dobruša-Huska",
            "l": "Sachsen"
        },
        {
            "o": "Göda - Hodźij",
            "l": "Sachsen"
        }
    ],
    "02692": [
        {
            "o": "Doberschau-Gaußig - Dobruša-Huska",
            "l": "Sachsen"
        },
        {
            "o": "Großpostwitz/O.L. - Budestecy",
            "l": "Sachsen"
        },
        {
            "o": "Obergurig - Hornja Hórka",
            "l": "Sachsen"
        }
    ],
    "04838": [
        {
            "o": "Doberschütz",
            "l": "Sachsen"
        },
        {
            "o": "Eilenburg",
            "l": "Sachsen"
        },
        {
            "o": "Jesewitz",
            "l": "Sachsen"
        },
        {
            "o": "Laußig",
            "l": "Sachsen"
        },
        {
            "o": "Zschepplin",
            "l": "Sachsen"
        }
    ],
    "04626": [
        {
            "o": "Dobitschen",
            "l": "Thüringen"
        },
        {
            "o": "Göllnitz",
            "l": "Thüringen"
        },
        {
            "o": "Heukewalde",
            "l": "Thüringen"
        },
        {
            "o": "Heyersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Jonaswalde",
            "l": "Thüringen"
        },
        {
            "o": "Löbichau",
            "l": "Thüringen"
        },
        {
            "o": "Mehna",
            "l": "Thüringen"
        },
        {
            "o": "Posterstein",
            "l": "Thüringen"
        },
        {
            "o": "Schmölln",
            "l": "Thüringen"
        },
        {
            "o": "Thonhausen",
            "l": "Thüringen"
        },
        {
            "o": "Vollmershain",
            "l": "Thüringen"
        }
    ],
    "01796": [
        {
            "o": "Dohma",
            "l": "Sachsen"
        },
        {
            "o": "Pirna",
            "l": "Sachsen"
        },
        {
            "o": "Struppen",
            "l": "Sachsen"
        }
    ],
    "01809": [
        {
            "o": "Dohna",
            "l": "Sachsen"
        },
        {
            "o": "Heidenau",
            "l": "Sachsen"
        },
        {
            "o": "Müglitztal",
            "l": "Sachsen"
        }
    ],
    "04880": [
        {
            "o": "Dommitzsch",
            "l": "Sachsen"
        },
        {
            "o": "Elsnig",
            "l": "Sachsen"
        },
        {
            "o": "Trossin",
            "l": "Sachsen"
        }
    ],
    "09619": [
        {
            "o": "Dorfchemnitz",
            "l": "Sachsen"
        },
        {
            "o": "Mulda/Sachsen",
            "l": "Sachsen"
        },
        {
            "o": "Sayda",
            "l": "Sachsen"
        }
    ],
    "01738": [
        {
            "o": "Dorfhain",
            "l": "Sachsen"
        }
    ],
    "07774": [
        {
            "o": "Dornburg-Camburg",
            "l": "Thüringen"
        },
        {
            "o": "Frauenprießnitz",
            "l": "Thüringen"
        },
        {
            "o": "Thierschneck",
            "l": "Thüringen"
        },
        {
            "o": "Wichmar",
            "l": "Thüringen"
        }
    ],
    "07429": [
        {
            "o": "Döschnitz",
            "l": "Thüringen"
        },
        {
            "o": "Rohrbach",
            "l": "Thüringen"
        },
        {
            "o": "Sitzendorf",
            "l": "Thüringen"
        }
    ],
    "03185": [
        {
            "o": "Drachhausen",
            "l": "Brandenburg"
        },
        {
            "o": "Drehnow",
            "l": "Brandenburg"
        },
        {
            "o": "Heinersbrück",
            "l": "Brandenburg"
        },
        {
            "o": "Peitz",
            "l": "Brandenburg"
        },
        {
            "o": "Tauer",
            "l": "Brandenburg"
        },
        {
            "o": "Teichland",
            "l": "Brandenburg"
        },
        {
            "o": "Turnow-Preilack",
            "l": "Brandenburg"
        }
    ],
    "07806": [
        {
            "o": "Dreba",
            "l": "Thüringen"
        },
        {
            "o": "Kospoda",
            "l": "Thüringen"
        },
        {
            "o": "Lausnitz",
            "l": "Thüringen"
        },
        {
            "o": "Neustadt an der Orla",
            "l": "Thüringen"
        },
        {
            "o": "Weira",
            "l": "Thüringen"
        }
    ],
    "09430": [
        {
            "o": "Drebach",
            "l": "Sachsen"
        }
    ],
    "03116": [
        {
            "o": "Drebkau",
            "l": "Brandenburg"
        }
    ],
    "04860": [
        {
            "o": "Dreiheide",
            "l": "Sachsen"
        },
        {
            "o": "Torgau",
            "l": "Sachsen"
        }
    ],
    "07819": [
        {
            "o": "Dreitzsch",
            "l": "Thüringen"
        },
        {
            "o": "Geroda",
            "l": "Thüringen"
        },
        {
            "o": "Lemnitz",
            "l": "Thüringen"
        },
        {
            "o": "Linda",
            "l": "Thüringen"
        },
        {
            "o": "Miesitz",
            "l": "Thüringen"
        },
        {
            "o": "Mittelpöllnitz",
            "l": "Thüringen"
        },
        {
            "o": "Rosendorf",
            "l": "Thüringen"
        },
        {
            "o": "Schmieritz",
            "l": "Thüringen"
        },
        {
            "o": "Tömmelsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Triptis",
            "l": "Thüringen"
        }
    ],
    "01067": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01069": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01097": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01099": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01108": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01109": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01127": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01129": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01139": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01156": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01157": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01159": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01169": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01187": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01189": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01217": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01219": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01237": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01239": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01257": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01259": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01277": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01279": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01307": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01309": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01324": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01326": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01328": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "01465": [
        {
            "o": "Dresden",
            "l": "Sachsen"
        }
    ],
    "06722": [
        {
            "o": "Droyßig",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wetterzeube",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02708": [
        {
            "o": "Dürrhennersdorf",
            "l": "Sachsen"
        },
        {
            "o": "Großschweidnitz",
            "l": "Sachsen"
        },
        {
            "o": "Kottmar",
            "l": "Sachsen"
        },
        {
            "o": "Lawalde",
            "l": "Sachsen"
        },
        {
            "o": "Löbau",
            "l": "Sachsen"
        },
        {
            "o": "Rosenbach",
            "l": "Sachsen"
        },
        {
            "o": "Schönbach",
            "l": "Sachsen"
        }
    ],
    "01833": [
        {
            "o": "Dürrröhrsdorf-Dittersbach",
            "l": "Sachsen"
        },
        {
            "o": "Stolpen",
            "l": "Sachsen"
        }
    ],
    "01561": [
        {
            "o": "Ebersbach",
            "l": "Sachsen"
        },
        {
            "o": "Großenhain",
            "l": "Sachsen"
        },
        {
            "o": "Lampertswalde",
            "l": "Sachsen"
        },
        {
            "o": "Priestewitz",
            "l": "Sachsen"
        },
        {
            "o": "Schönfeld",
            "l": "Sachsen"
        },
        {
            "o": "Thiendorf",
            "l": "Sachsen"
        }
    ],
    "02727": [
        {
            "o": "Ebersbach-Neugersdorf",
            "l": "Sachsen"
        }
    ],
    "02730": [
        {
            "o": "Ebersbach-Neugersdorf",
            "l": "Sachsen"
        }
    ],
    "06648": [
        {
            "o": "Eckartsberga",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09427": [
        {
            "o": "Ehrenfriedersdorf",
            "l": "Sachsen"
        }
    ],
    "08309": [
        {
            "o": "Eibenstock",
            "l": "Sachsen"
        }
    ],
    "07607": [
        {
            "o": "Eisenberg",
            "l": "Thüringen"
        },
        {
            "o": "Gösen",
            "l": "Thüringen"
        },
        {
            "o": "Hainspitz",
            "l": "Thüringen"
        }
    ],
    "08236": [
        {
            "o": "Ellefeld",
            "l": "Sachsen"
        }
    ],
    "06729": [
        {
            "o": "Elsteraue",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07985": [
        {
            "o": "Elsterberg",
            "l": "Sachsen"
        }
    ],
    "02979": [
        {
            "o": "Elsterheide - Halštrowska Hola",
            "l": "Sachsen"
        },
        {
            "o": "Spreetal - Sprjewiny Doł",
            "l": "Sachsen"
        }
    ],
    "04523": [
        {
            "o": "Elstertrebnitz",
            "l": "Sachsen"
        },
        {
            "o": "Pegau",
            "l": "Sachsen"
        }
    ],
    "04910": [
        {
            "o": "Elsterwerda",
            "l": "Brandenburg"
        }
    ],
    "09481": [
        {
            "o": "Elterlein",
            "l": "Sachsen"
        },
        {
            "o": "Scheibenberg",
            "l": "Sachsen"
        }
    ],
    "07570": [
        {
            "o": "Endschütz",
            "l": "Thüringen"
        },
        {
            "o": "Harth-Pöllnitz",
            "l": "Thüringen"
        },
        {
            "o": "Teichwitz",
            "l": "Thüringen"
        },
        {
            "o": "Weida",
            "l": "Thüringen"
        },
        {
            "o": "Wünschendorf/Elster",
            "l": "Thüringen"
        }
    ],
    "09575": [
        {
            "o": "Eppendorf",
            "l": "Sachsen"
        }
    ],
    "09306": [
        {
            "o": "Erlau",
            "l": "Sachsen"
        },
        {
            "o": "Königsfeld",
            "l": "Sachsen"
        },
        {
            "o": "Königshain-Wiederau",
            "l": "Sachsen"
        },
        {
            "o": "Rochlitz",
            "l": "Sachsen"
        },
        {
            "o": "Seelitz",
            "l": "Sachsen"
        },
        {
            "o": "Wechselburg",
            "l": "Sachsen"
        },
        {
            "o": "Zettlitz",
            "l": "Sachsen"
        }
    ],
    "07924": [
        {
            "o": "Eßbach",
            "l": "Thüringen"
        },
        {
            "o": "Neundorf",
            "l": "Thüringen"
        },
        {
            "o": "Schöndorf",
            "l": "Thüringen"
        },
        {
            "o": "Volkmannsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Ziegenrück",
            "l": "Thüringen"
        }
    ],
    "06333": [
        {
            "o": "Falkenstein/Harz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Hettstedt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06463": [
        {
            "o": "Falkenstein/Harz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06543": [
        {
            "o": "Falkenstein/Harz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08223": [
        {
            "o": "Falkenstein/Vogtland",
            "l": "Sachsen"
        },
        {
            "o": "Grünbach",
            "l": "Sachsen"
        },
        {
            "o": "Neustadt/Vogtland",
            "l": "Sachsen"
        },
        {
            "o": "Werda",
            "l": "Sachsen"
        }
    ],
    "06279": [
        {
            "o": "Farnstädt",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Schraplau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "03130": [
        {
            "o": "Felixsee",
            "l": "Brandenburg"
        },
        {
            "o": "Jämlitz-Klein Düben",
            "l": "Brandenburg"
        },
        {
            "o": "Spremberg",
            "l": "Brandenburg"
        },
        {
            "o": "Tschernitz",
            "l": "Brandenburg"
        }
    ],
    "04936": [
        {
            "o": "Fichtwald",
            "l": "Brandenburg"
        },
        {
            "o": "Hohenbucko",
            "l": "Brandenburg"
        },
        {
            "o": "Kremitzaue",
            "l": "Brandenburg"
        },
        {
            "o": "Lebusa",
            "l": "Brandenburg"
        },
        {
            "o": "Schlieben",
            "l": "Brandenburg"
        }
    ],
    "03238": [
        {
            "o": "Finsterwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Gorden-Staupitz",
            "l": "Brandenburg"
        },
        {
            "o": "Heideland",
            "l": "Brandenburg"
        },
        {
            "o": "Lichterfeld-Schacksdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Massen-Niederlausitz",
            "l": "Brandenburg"
        },
        {
            "o": "Rückersdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Sallgast",
            "l": "Brandenburg"
        }
    ],
    "09557": [
        {
            "o": "Flöha",
            "l": "Sachsen"
        }
    ],
    "04617": [
        {
            "o": "Fockendorf",
            "l": "Thüringen"
        },
        {
            "o": "Gerstenberg",
            "l": "Thüringen"
        },
        {
            "o": "Haselbach",
            "l": "Thüringen"
        },
        {
            "o": "Kriebitzsch",
            "l": "Thüringen"
        },
        {
            "o": "Lödla",
            "l": "Thüringen"
        },
        {
            "o": "Monstab",
            "l": "Thüringen"
        },
        {
            "o": "Rositz",
            "l": "Thüringen"
        },
        {
            "o": "Starkenberg",
            "l": "Thüringen"
        },
        {
            "o": "Treben",
            "l": "Thüringen"
        }
    ],
    "03149": [
        {
            "o": "Forst (Lausitz) - Baršć",
            "l": "Brandenburg"
        },
        {
            "o": "Groß Schacksdorf-Simmersdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Wiesengrund",
            "l": "Brandenburg"
        }
    ],
    "09669": [
        {
            "o": "Frankenberg/Sachsen",
            "l": "Sachsen"
        }
    ],
    "01909": [
        {
            "o": "Frankenthal",
            "l": "Sachsen"
        },
        {
            "o": "Großharthau",
            "l": "Sachsen"
        }
    ],
    "01945": [
        {
            "o": "Frauendorf",
            "l": "Brandenburg"
        },
        {
            "o": "Grünewald",
            "l": "Brandenburg"
        },
        {
            "o": "Guteborn",
            "l": "Brandenburg"
        },
        {
            "o": "Hermsdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Hohenbocka",
            "l": "Brandenburg"
        },
        {
            "o": "Kroppen",
            "l": "Brandenburg"
        },
        {
            "o": "Lindenau",
            "l": "Brandenburg"
        },
        {
            "o": "Ruhland",
            "l": "Brandenburg"
        },
        {
            "o": "Schwarzbach",
            "l": "Brandenburg"
        },
        {
            "o": "Senftenberg",
            "l": "Brandenburg"
        },
        {
            "o": "Tettau",
            "l": "Brandenburg"
        }
    ],
    "09623": [
        {
            "o": "Frauenstein",
            "l": "Sachsen"
        },
        {
            "o": "Rechenberg-Bienenmühle",
            "l": "Sachsen"
        }
    ],
    "08427": [
        {
            "o": "Fraureuth",
            "l": "Sachsen"
        }
    ],
    "09599": [
        {
            "o": "Freiberg",
            "l": "Sachsen"
        }
    ],
    "01705": [
        {
            "o": "Freital",
            "l": "Sachsen"
        }
    ],
    "04654": [
        {
            "o": "Frohburg",
            "l": "Sachsen"
        }
    ],
    "07926": [
        {
            "o": "Gefell",
            "l": "Thüringen"
        }
    ],
    "06571": [
        {
            "o": "Gehofen",
            "l": "Thüringen"
        },
        {
            "o": "Roßleben-Wiehe",
            "l": "Thüringen"
        }
    ],
    "04643": [
        {
            "o": "Geithain",
            "l": "Sachsen"
        }
    ],
    "09423": [
        {
            "o": "Gelenau/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "07545": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "07546": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "07548": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "07549": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "07551": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "07552": [
        {
            "o": "Gera",
            "l": "Thüringen"
        }
    ],
    "06347": [
        {
            "o": "Gerbstedt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09326": [
        {
            "o": "Geringswalde",
            "l": "Sachsen"
        }
    ],
    "09355": [
        {
            "o": "Gersdorf",
            "l": "Sachsen"
        }
    ],
    "07389": [
        {
            "o": "Gertewitz",
            "l": "Thüringen"
        },
        {
            "o": "Gössitz",
            "l": "Thüringen"
        },
        {
            "o": "Grobengereuth",
            "l": "Thüringen"
        },
        {
            "o": "Keila",
            "l": "Thüringen"
        },
        {
            "o": "Knau",
            "l": "Thüringen"
        },
        {
            "o": "Peuschen",
            "l": "Thüringen"
        },
        {
            "o": "Quaschwitz",
            "l": "Thüringen"
        },
        {
            "o": "Ranis",
            "l": "Thüringen"
        },
        {
            "o": "Schmorda",
            "l": "Thüringen"
        },
        {
            "o": "Seisla",
            "l": "Thüringen"
        },
        {
            "o": "Wilhelmsdorf",
            "l": "Thüringen"
        }
    ],
    "09468": [
        {
            "o": "Geyer",
            "l": "Sachsen"
        },
        {
            "o": "Tannenberg",
            "l": "Sachsen"
        }
    ],
    "01768": [
        {
            "o": "Glashütte",
            "l": "Sachsen"
        }
    ],
    "01612": [
        {
            "o": "Glaubitz",
            "l": "Sachsen"
        },
        {
            "o": "Nünchritz",
            "l": "Sachsen"
        }
    ],
    "08371": [
        {
            "o": "Glauchau",
            "l": "Sachsen"
        }
    ],
    "06246": [
        {
            "o": "Goethestadt Bad Lauchstädt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04603": [
        {
            "o": "Göhren",
            "l": "Thüringen"
        },
        {
            "o": "Nobitz",
            "l": "Thüringen"
        },
        {
            "o": "Windischleuba",
            "l": "Thüringen"
        }
    ],
    "01824": [
        {
            "o": "Gohrisch",
            "l": "Sachsen"
        },
        {
            "o": "Königstein",
            "l": "Sachsen"
        },
        {
            "o": "Rathen",
            "l": "Sachsen"
        },
        {
            "o": "Rosenthal-Bielatal",
            "l": "Sachsen"
        }
    ],
    "04618": [
        {
            "o": "Göpfersdorf",
            "l": "Thüringen"
        },
        {
            "o": "Langenleuba-Niederhain",
            "l": "Thüringen"
        }
    ],
    "02826": [
        {
            "o": "Görlitz",
            "l": "Sachsen"
        }
    ],
    "02827": [
        {
            "o": "Görlitz",
            "l": "Sachsen"
        }
    ],
    "02828": [
        {
            "o": "Görlitz",
            "l": "Sachsen"
        }
    ],
    "09405": [
        {
            "o": "Gornau/Erzgebirge",
            "l": "Sachsen"
        },
        {
            "o": "Zschopau",
            "l": "Sachsen"
        }
    ],
    "09390": [
        {
            "o": "Gornsdorf",
            "l": "Sachsen"
        }
    ],
    "06667": [
        {
            "o": "Goseck",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Stößen",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Weißenfels",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04639": [
        {
            "o": "Gößnitz",
            "l": "Thüringen"
        },
        {
            "o": "Ponitz",
            "l": "Thüringen"
        }
    ],
    "06772": [
        {
            "o": "Gräfenhainichen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06773": [
        {
            "o": "Gräfenhainichen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07973": [
        {
            "o": "Greiz",
            "l": "Thüringen"
        }
    ],
    "04668": [
        {
            "o": "Grimma",
            "l": "Sachsen"
        },
        {
            "o": "Otterwisch",
            "l": "Sachsen"
        },
        {
            "o": "Parthenstein",
            "l": "Sachsen"
        }
    ],
    "04932": [
        {
            "o": "Gröden",
            "l": "Brandenburg"
        },
        {
            "o": "Großthiemig",
            "l": "Brandenburg"
        },
        {
            "o": "Hirschfeld",
            "l": "Brandenburg"
        },
        {
            "o": "Merzdorf",
            "l": "Brandenburg"
        },
        {
            "o": "Röderland",
            "l": "Brandenburg"
        }
    ],
    "01609": [
        {
            "o": "Gröditz",
            "l": "Sachsen"
        },
        {
            "o": "Röderaue",
            "l": "Sachsen"
        },
        {
            "o": "Wülknitz",
            "l": "Sachsen"
        }
    ],
    "04539": [
        {
            "o": "Groitzsch",
            "l": "Sachsen"
        }
    ],
    "02959": [
        {
            "o": "Groß Düben - Dźěwin",
            "l": "Sachsen"
        },
        {
            "o": "Schleife - Slepo",
            "l": "Sachsen"
        },
        {
            "o": "Trebendorf - Trjebin",
            "l": "Sachsen"
        }
    ],
    "02694": [
        {
            "o": "Großdubrau - Wulka Dubrawa",
            "l": "Sachsen"
        },
        {
            "o": "Malschwitz - Malešecy",
            "l": "Sachsen"
        }
    ],
    "01558": [
        {
            "o": "Großenhain",
            "l": "Sachsen"
        }
    ],
    "01990": [
        {
            "o": "Großkmehlen",
            "l": "Brandenburg"
        },
        {
            "o": "Ortrand",
            "l": "Brandenburg"
        }
    ],
    "01936": [
        {
            "o": "Großnaundorf",
            "l": "Sachsen"
        },
        {
            "o": "Königsbrück",
            "l": "Sachsen"
        },
        {
            "o": "Laußnitz",
            "l": "Sachsen"
        },
        {
            "o": "Neukirch",
            "l": "Sachsen"
        },
        {
            "o": "Schwepnitz",
            "l": "Sachsen"
        }
    ],
    "09432": [
        {
            "o": "Großolbersdorf",
            "l": "Sachsen"
        }
    ],
    "04463": [
        {
            "o": "Großpösna",
            "l": "Sachsen"
        }
    ],
    "01983": [
        {
            "o": "Großräschen",
            "l": "Brandenburg"
        }
    ],
    "01900": [
        {
            "o": "Großröhrsdorf",
            "l": "Sachsen"
        }
    ],
    "09518": [
        {
            "o": "Großrückerswalde",
            "l": "Sachsen"
        }
    ],
    "09603": [
        {
            "o": "Großschirma",
            "l": "Sachsen"
        }
    ],
    "02779": [
        {
            "o": "Großschönau",
            "l": "Sachsen"
        },
        {
            "o": "Hainewalde",
            "l": "Sachsen"
        }
    ],
    "02799": [
        {
            "o": "Großschönau",
            "l": "Sachsen"
        }
    ],
    "08344": [
        {
            "o": "Grünhain-Beierfeld",
            "l": "Sachsen"
        }
    ],
    "09579": [
        {
            "o": "Grünhainichen",
            "l": "Sachsen"
        }
    ],
    "03172": [
        {
            "o": "Guben",
            "l": "Brandenburg"
        },
        {
            "o": "Jänschwalde",
            "l": "Brandenburg"
        },
        {
            "o": "Schenkendöbern",
            "l": "Brandenburg"
        }
    ],
    "06712": [
        {
            "o": "Gutenborn",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Kretzschau",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Schnaudertal",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Zeitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02923": [
        {
            "o": "Hähnichen",
            "l": "Sachsen"
        },
        {
            "o": "Horka",
            "l": "Sachsen"
        },
        {
            "o": "Kodersdorf",
            "l": "Sachsen"
        }
    ],
    "09661": [
        {
            "o": "Hainichen",
            "l": "Sachsen"
        },
        {
            "o": "Rossau",
            "l": "Sachsen"
        },
        {
            "o": "Striegistal",
            "l": "Sachsen"
        }
    ],
    "07778": [
        {
            "o": "Hainichen",
            "l": "Thüringen"
        },
        {
            "o": "Lehesten",
            "l": "Thüringen"
        },
        {
            "o": "Neuengönna",
            "l": "Thüringen"
        },
        {
            "o": "Tautenburg",
            "l": "Thüringen"
        },
        {
            "o": "Zimmern",
            "l": "Thüringen"
        }
    ],
    "06108": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06110": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06112": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06114": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06116": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06118": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06120": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06122": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06124": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06126": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06128": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06130": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06132": [
        {
            "o": "Halle (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09633": [
        {
            "o": "Halsbrücke",
            "l": "Sachsen"
        }
    ],
    "08118": [
        {
            "o": "Hartenstein",
            "l": "Sachsen"
        }
    ],
    "04746": [
        {
            "o": "Hartha",
            "l": "Sachsen"
        }
    ],
    "09232": [
        {
            "o": "Hartmannsdorf",
            "l": "Sachsen"
        }
    ],
    "08107": [
        {
            "o": "Hartmannsdorf bei Kirchberg",
            "l": "Sachsen"
        },
        {
            "o": "Kirchberg",
            "l": "Sachsen"
        }
    ],
    "01762": [
        {
            "o": "Hartmannsdorf-Reichenau",
            "l": "Sachsen"
        }
    ],
    "06458": [
        {
            "o": "Hedersleben",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Selke-Aue",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09526": [
        {
            "o": "Heidersdorf",
            "l": "Sachsen"
        },
        {
            "o": "Olbernhau",
            "l": "Sachsen"
        }
    ],
    "08468": [
        {
            "o": "Heinsdorfergrund",
            "l": "Sachsen"
        },
        {
            "o": "Limbach",
            "l": "Sachsen"
        },
        {
            "o": "Reichenbach",
            "l": "Sachsen"
        }
    ],
    "06311": [
        {
            "o": "Helbra",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07629": [
        {
            "o": "Hermsdorf",
            "l": "Thüringen"
        },
        {
            "o": "Reichenbach",
            "l": "Thüringen"
        },
        {
            "o": "Schleifreisen",
            "l": "Thüringen"
        },
        {
            "o": "St. Gangloff",
            "l": "Thüringen"
        }
    ],
    "01776": [
        {
            "o": "Hermsdorf/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "02747": [
        {
            "o": "Herrnhut",
            "l": "Sachsen"
        }
    ],
    "04916": [
        {
            "o": "Herzberg (Elster)",
            "l": "Brandenburg"
        },
        {
            "o": "Kremitzaue",
            "l": "Brandenburg"
        },
        {
            "o": "Schönewalde",
            "l": "Brandenburg"
        }
    ],
    "07927": [
        {
            "o": "Hirschberg",
            "l": "Thüringen"
        }
    ],
    "08144": [
        {
            "o": "Hirschfeld",
            "l": "Sachsen"
        }
    ],
    "01594": [
        {
            "o": "Hirschstein",
            "l": "Sachsen"
        },
        {
            "o": "Riesa",
            "l": "Sachsen"
        },
        {
            "o": "Stauchitz",
            "l": "Sachsen"
        }
    ],
    "02627": [
        {
            "o": "Hochkirch - Bukecy",
            "l": "Sachsen"
        },
        {
            "o": "Kubschütz - Kubšicy",
            "l": "Sachsen"
        },
        {
            "o": "Radibor - Radwor",
            "l": "Sachsen"
        },
        {
            "o": "Weißenberg - Wóspork",
            "l": "Sachsen"
        }
    ],
    "02906": [
        {
            "o": "Hohendubrau - Wysoka Dubrawa",
            "l": "Sachsen"
        },
        {
            "o": "Kreba-Neudorf - Chrjebja-Nowa Wjes",
            "l": "Sachsen"
        },
        {
            "o": "Mücka - Mikow",
            "l": "Sachsen"
        },
        {
            "o": "Niesky",
            "l": "Sachsen"
        },
        {
            "o": "Quitzdorf am See - Kwětanecy",
            "l": "Sachsen"
        },
        {
            "o": "Waldhufen",
            "l": "Sachsen"
        }
    ],
    "04934": [
        {
            "o": "Hohenleipisch",
            "l": "Brandenburg"
        }
    ],
    "07958": [
        {
            "o": "Hohenleuben",
            "l": "Thüringen"
        }
    ],
    "06679": [
        {
            "o": "Hohenmölsen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09394": [
        {
            "o": "Hohndorf",
            "l": "Sachsen"
        }
    ],
    "01848": [
        {
            "o": "Hohnstein",
            "l": "Sachsen"
        }
    ],
    "02977": [
        {
            "o": "Hoyerswerda - Wojerecy",
            "l": "Sachsen"
        }
    ],
    "06408": [
        {
            "o": "Ilberstedt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09387": [
        {
            "o": "Jahnsdorf/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "03197": [
        {
            "o": "Jänschwalde",
            "l": "Brandenburg"
        }
    ],
    "07743": [
        {
            "o": "Jena",
            "l": "Thüringen"
        }
    ],
    "07745": [
        {
            "o": "Jena",
            "l": "Thüringen"
        }
    ],
    "07747": [
        {
            "o": "Jena",
            "l": "Thüringen"
        }
    ],
    "07749": [
        {
            "o": "Jena",
            "l": "Thüringen"
        }
    ],
    "06917": [
        {
            "o": "Jessen (Elster)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08349": [
        {
            "o": "Johanngeorgenstadt",
            "l": "Sachsen"
        }
    ],
    "09477": [
        {
            "o": "Jöhstadt",
            "l": "Sachsen"
        }
    ],
    "02796": [
        {
            "o": "Jonsdorf",
            "l": "Sachsen"
        }
    ],
    "06184": [
        {
            "o": "Kabelsketal",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06642": [
        {
            "o": "Kaiserpfalz",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Nebra (Unstrut)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01917": [
        {
            "o": "Kamenz - Kamjenc",
            "l": "Sachsen"
        }
    ],
    "06638": [
        {
            "o": "Karsdorf",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06537": [
        {
            "o": "Kelbra (Kyffhäuser)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06901": [
        {
            "o": "Kemberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07919": [
        {
            "o": "Kirschkau",
            "l": "Thüringen"
        },
        {
            "o": "Pausa-Mühltroff",
            "l": "Sachsen"
        }
    ],
    "04567": [
        {
            "o": "Kitzscher",
            "l": "Sachsen"
        }
    ],
    "01774": [
        {
            "o": "Klingenberg",
            "l": "Sachsen"
        }
    ],
    "08248": [
        {
            "o": "Klingenthal",
            "l": "Sachsen"
        }
    ],
    "08267": [
        {
            "o": "Klingenthal",
            "l": "Sachsen"
        }
    ],
    "03099": [
        {
            "o": "Kolkwitz",
            "l": "Brandenburg"
        }
    ],
    "02829": [
        {
            "o": "Königshain",
            "l": "Sachsen"
        },
        {
            "o": "Markersdorf",
            "l": "Sachsen"
        },
        {
            "o": "Neißeaue",
            "l": "Sachsen"
        },
        {
            "o": "Schöpstal",
            "l": "Sachsen"
        }
    ],
    "02699": [
        {
            "o": "Königswartha - Rakecy",
            "l": "Sachsen"
        },
        {
            "o": "Neschwitz - Njeswačidło",
            "l": "Sachsen"
        },
        {
            "o": "Puschwitz - Bóšicy",
            "l": "Sachsen"
        }
    ],
    "06420": [
        {
            "o": "Könnern",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06366": [
        {
            "o": "Köthen (Anhalt)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06369": [
        {
            "o": "Köthen (Anhalt)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Südliches Anhalt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06388": [
        {
            "o": "Köthen (Anhalt)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Südliches Anhalt",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02739": [
        {
            "o": "Kottmar",
            "l": "Sachsen"
        }
    ],
    "02957": [
        {
            "o": "Krauschwitz - Krušwica",
            "l": "Sachsen"
        },
        {
            "o": "Weißkeißel - Wuskidź",
            "l": "Sachsen"
        }
    ],
    "01731": [
        {
            "o": "Kreischa",
            "l": "Sachsen"
        }
    ],
    "07387": [
        {
            "o": "Krölpa",
            "l": "Thüringen"
        }
    ],
    "06188": [
        {
            "o": "Landsberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08428": [
        {
            "o": "Langenbernsdorf",
            "l": "Sachsen"
        }
    ],
    "08134": [
        {
            "o": "Langenweißbach",
            "l": "Sachsen"
        },
        {
            "o": "Wildenfels",
            "l": "Sachsen"
        }
    ],
    "07957": [
        {
            "o": "Langenwetzendorf",
            "l": "Thüringen"
        }
    ],
    "07937": [
        {
            "o": "Langenwolschendorf",
            "l": "Thüringen"
        },
        {
            "o": "Zeulenroda-Triebes",
            "l": "Thüringen"
        }
    ],
    "06628": [
        {
            "o": "Lanitz-Hassel-Tal",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Naumburg (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06636": [
        {
            "o": "Laucha an der Unstrut",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01979": [
        {
            "o": "Lauchhammer",
            "l": "Brandenburg"
        }
    ],
    "02991": [
        {
            "o": "Lauta",
            "l": "Sachsen"
        }
    ],
    "08315": [
        {
            "o": "Lauter-Bernsbach",
            "l": "Sachsen"
        }
    ],
    "07349": [
        {
            "o": "Lehesten",
            "l": "Thüringen"
        }
    ],
    "04103": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04105": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04107": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04109": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04129": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04155": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04157": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04158": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04159": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04177": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04178": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04179": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04205": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04207": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04209": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04229": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04249": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04275": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04277": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04279": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04288": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04289": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04299": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04315": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04316": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04317": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04318": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04319": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04328": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04329": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04347": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04349": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04356": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04357": [
        {
            "o": "Leipzig",
            "l": "Sachsen"
        }
    ],
    "04703": [
        {
            "o": "Leisnig",
            "l": "Sachsen"
        }
    ],
    "08485": [
        {
            "o": "Lengenfeld",
            "l": "Sachsen"
        }
    ],
    "06237": [
        {
            "o": "Leuna",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02794": [
        {
            "o": "Leutersdorf",
            "l": "Sachsen"
        }
    ],
    "09244": [
        {
            "o": "Lichtenau",
            "l": "Sachsen"
        }
    ],
    "01896": [
        {
            "o": "Lichtenberg",
            "l": "Sachsen"
        },
        {
            "o": "Ohorn",
            "l": "Sachsen"
        },
        {
            "o": "Pulsnitz",
            "l": "Sachsen"
        }
    ],
    "09638": [
        {
            "o": "Lichtenberg/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "09350": [
        {
            "o": "Lichtenstein/Sachsen",
            "l": "Sachsen"
        }
    ],
    "08115": [
        {
            "o": "Lichtentanne",
            "l": "Sachsen"
        }
    ],
    "01825": [
        {
            "o": "Liebstadt",
            "l": "Sachsen"
        }
    ],
    "08491": [
        {
            "o": "Limbach",
            "l": "Sachsen"
        },
        {
            "o": "Netzschkau",
            "l": "Sachsen"
        },
        {
            "o": "Reichenbach",
            "l": "Sachsen"
        }
    ],
    "09212": [
        {
            "o": "Limbach-Oberfrohna",
            "l": "Sachsen"
        }
    ],
    "01847": [
        {
            "o": "Lohmen",
            "l": "Sachsen"
        }
    ],
    "02999": [
        {
            "o": "Lohsa - Łaz",
            "l": "Sachsen"
        }
    ],
    "01623": [
        {
            "o": "Lommatzsch",
            "l": "Sachsen"
        }
    ],
    "04808": [
        {
            "o": "Lossatal",
            "l": "Sachsen"
        },
        {
            "o": "Thallwitz",
            "l": "Sachsen"
        },
        {
            "o": "Wurzen",
            "l": "Sachsen"
        }
    ],
    "08294": [
        {
            "o": "Lößnitz",
            "l": "Sachsen"
        }
    ],
    "03222": [
        {
            "o": "Lübbenau/Spreewald",
            "l": "Brandenburg"
        }
    ],
    "04613": [
        {
            "o": "Lucka",
            "l": "Thüringen"
        }
    ],
    "09385": [
        {
            "o": "Lugau",
            "l": "Sachsen"
        }
    ],
    "09328": [
        {
            "o": "Lunzenau",
            "l": "Sachsen"
        }
    ],
    "06686": [
        {
            "o": "Lützen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04827": [
        {
            "o": "Machern",
            "l": "Sachsen"
        }
    ],
    "06343": [
        {
            "o": "Mansfeld",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09496": [
        {
            "o": "Marienberg",
            "l": "Sachsen"
        }
    ],
    "04416": [
        {
            "o": "Markkleeberg",
            "l": "Sachsen"
        }
    ],
    "08258": [
        {
            "o": "Markneukirchen",
            "l": "Sachsen"
        }
    ],
    "04420": [
        {
            "o": "Markranstädt",
            "l": "Sachsen"
        }
    ],
    "06721": [
        {
            "o": "Meineweh",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Osterfeld",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01662": [
        {
            "o": "Meißen",
            "l": "Sachsen"
        }
    ],
    "06217": [
        {
            "o": "Merseburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06618": [
        {
            "o": "Mertendorf",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Molauer Land",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Naumburg (Saale)",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Schönburg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wethau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "07619": [
        {
            "o": "Mertendorf",
            "l": "Thüringen"
        },
        {
            "o": "Schkölen",
            "l": "Thüringen"
        }
    ],
    "04610": [
        {
            "o": "Meuselwitz",
            "l": "Thüringen"
        }
    ],
    "04862": [
        {
            "o": "Mockrehna",
            "l": "Sachsen"
        }
    ],
    "07987": [
        {
            "o": "Mohlsdorf-Teichwolframsdorf",
            "l": "Thüringen"
        }
    ],
    "01468": [
        {
            "o": "Moritzburg",
            "l": "Sachsen"
        }
    ],
    "06249": [
        {
            "o": "Mücheln (Geiseltal)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06255": [
        {
            "o": "Mücheln (Geiseltal)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04769": [
        {
            "o": "Mügeln",
            "l": "Sachsen"
        },
        {
            "o": "Naundorf",
            "l": "Sachsen"
        }
    ],
    "09241": [
        {
            "o": "Mühlau",
            "l": "Sachsen"
        }
    ],
    "08262": [
        {
            "o": "Muldenhammer",
            "l": "Sachsen"
        }
    ],
    "06774": [
        {
            "o": "Muldestausee",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08132": [
        {
            "o": "Mülsen",
            "l": "Sachsen"
        }
    ],
    "08541": [
        {
            "o": "Neuensalz",
            "l": "Sachsen"
        },
        {
            "o": "Plauen",
            "l": "Sachsen"
        },
        {
            "o": "Theuma",
            "l": "Sachsen"
        }
    ],
    "09544": [
        {
            "o": "Neuhausen/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "03058": [
        {
            "o": "Neuhausen/Spree",
            "l": "Brandenburg"
        }
    ],
    "04575": [
        {
            "o": "Neukieritzsch",
            "l": "Sachsen"
        }
    ],
    "09221": [
        {
            "o": "Neukirchen/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "08459": [
        {
            "o": "Neukirchen/Pleiße",
            "l": "Sachsen"
        }
    ],
    "01904": [
        {
            "o": "Neukirch/Lausitz",
            "l": "Sachsen"
        },
        {
            "o": "Steinigtwolmsdorf",
            "l": "Sachsen"
        }
    ],
    "08496": [
        {
            "o": "Neumark",
            "l": "Sachsen"
        }
    ],
    "03103": [
        {
            "o": "Neupetershain",
            "l": "Brandenburg"
        },
        {
            "o": "Neu-Seeland",
            "l": "Brandenburg"
        }
    ],
    "02742": [
        {
            "o": "Neusalza-Spremberg",
            "l": "Sachsen"
        }
    ],
    "01844": [
        {
            "o": "Neustadt in Sachsen",
            "l": "Sachsen"
        }
    ],
    "01689": [
        {
            "o": "Niederau",
            "l": "Sachsen"
        },
        {
            "o": "Weinböhla",
            "l": "Sachsen"
        }
    ],
    "09366": [
        {
            "o": "Niederdorf",
            "l": "Sachsen"
        },
        {
            "o": "Stollberg/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "09243": [
        {
            "o": "Niederfrohna",
            "l": "Sachsen"
        }
    ],
    "09577": [
        {
            "o": "Niederwiesa",
            "l": "Sachsen"
        }
    ],
    "09399": [
        {
            "o": "Niederwürschnitz",
            "l": "Sachsen"
        }
    ],
    "06429": [
        {
            "o": "Nienburg (Saale)",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01683": [
        {
            "o": "Nossen",
            "l": "Sachsen"
        }
    ],
    "09353": [
        {
            "o": "Oberlungwitz",
            "l": "Sachsen"
        }
    ],
    "09600": [
        {
            "o": "Oberschöna",
            "l": "Sachsen"
        },
        {
            "o": "Weißenborn/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "08396": [
        {
            "o": "Oberwiera",
            "l": "Sachsen"
        },
        {
            "o": "Waldenburg",
            "l": "Sachsen"
        }
    ],
    "09484": [
        {
            "o": "Oberwiesenthal",
            "l": "Sachsen"
        }
    ],
    "02791": [
        {
            "o": "Oderwitz",
            "l": "Sachsen"
        }
    ],
    "09569": [
        {
            "o": "Oederan",
            "l": "Sachsen"
        }
    ],
    "09376": [
        {
            "o": "Oelsnitz/Erzgeb.",
            "l": "Sachsen"
        }
    ],
    "02785": [
        {
            "o": "Olbersdorf",
            "l": "Sachsen"
        }
    ],
    "06785": [
        {
            "o": "Oranienbaum-Wörlitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "04749": [
        {
            "o": "Ostrau",
            "l": "Sachsen"
        }
    ],
    "02899": [
        {
            "o": "Ostritz",
            "l": "Sachsen"
        },
        {
            "o": "Schönau-Berzdorf auf dem Eigen",
            "l": "Sachsen"
        }
    ],
    "01458": [
        {
            "o": "Ottendorf-Okrilla",
            "l": "Sachsen"
        }
    ],
    "02797": [
        {
            "o": "Oybin",
            "l": "Sachsen"
        }
    ],
    "07952": [
        {
            "o": "Pausa-Mühltroff",
            "l": "Sachsen"
        }
    ],
    "08539": [
        {
            "o": "Pausa-Mühltroff",
            "l": "Sachsen"
        },
        {
            "o": "Rosenbach/Vogtland",
            "l": "Sachsen"
        }
    ],
    "09322": [
        {
            "o": "Penig",
            "l": "Sachsen"
        }
    ],
    "06193": [
        {
            "o": "Petersberg",
            "l": "Sachsen-Anhalt"
        },
        {
            "o": "Wettin-Löbejün",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08523": [
        {
            "o": "Plauen",
            "l": "Sachsen"
        }
    ],
    "08525": [
        {
            "o": "Plauen",
            "l": "Sachsen"
        }
    ],
    "08527": [
        {
            "o": "Plauen",
            "l": "Sachsen"
        },
        {
            "o": "Rosenbach/Vogtland",
            "l": "Sachsen"
        }
    ],
    "08529": [
        {
            "o": "Plauen",
            "l": "Sachsen"
        }
    ],
    "08547": [
        {
            "o": "Plauen",
            "l": "Sachsen"
        }
    ],
    "04928": [
        {
            "o": "Plessa",
            "l": "Brandenburg"
        },
        {
            "o": "Schraden",
            "l": "Brandenburg"
        }
    ],
    "09509": [
        {
            "o": "Pockau-Lengefeld",
            "l": "Sachsen"
        }
    ],
    "09514": [
        {
            "o": "Pockau-Lengefeld",
            "l": "Sachsen"
        }
    ],
    "08543": [
        {
            "o": "Pöhl",
            "l": "Sachsen"
        }
    ],
    "07330": [
        {
            "o": "Probstzella",
            "l": "Thüringen"
        }
    ],
    "06485": [
        {
            "o": "Quedlinburg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01734": [
        {
            "o": "Rabenau",
            "l": "Sachsen"
        }
    ],
    "04519": [
        {
            "o": "Rackwitz",
            "l": "Sachsen"
        }
    ],
    "01454": [
        {
            "o": "Radeberg",
            "l": "Sachsen"
        },
        {
            "o": "Wachau",
            "l": "Sachsen"
        }
    ],
    "01445": [
        {
            "o": "Radebeul",
            "l": "Sachsen"
        }
    ],
    "01471": [
        {
            "o": "Radeburg",
            "l": "Sachsen"
        }
    ],
    "06779": [
        {
            "o": "Raguhn-Jeßnitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06800": [
        {
            "o": "Raguhn-Jeßnitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "08352": [
        {
            "o": "Raschau-Markersbach",
            "l": "Sachsen"
        }
    ],
    "04565": [
        {
            "o": "Regis-Breitingen",
            "l": "Sachsen"
        }
    ],
    "08499": [
        {
            "o": "Reichenbach",
            "l": "Sachsen"
        }
    ],
    "02894": [
        {
            "o": "Reichenbach/Oberlausitz",
            "l": "Sachsen"
        },
        {
            "o": "Vierkirchen",
            "l": "Sachsen"
        }
    ],
    "09629": [
        {
            "o": "Reinsberg",
            "l": "Sachsen"
        }
    ],
    "09634": [
        {
            "o": "Reinsberg",
            "l": "Sachsen"
        }
    ],
    "08141": [
        {
            "o": "Reinsdorf",
            "l": "Sachsen"
        }
    ],
    "07368": [
        {
            "o": "Remptendorf",
            "l": "Thüringen"
        }
    ],
    "08373": [
        {
            "o": "Remse",
            "l": "Sachsen"
        }
    ],
    "01587": [
        {
            "o": "Riesa",
            "l": "Sachsen"
        }
    ],
    "01589": [
        {
            "o": "Riesa",
            "l": "Sachsen"
        }
    ],
    "01591": [
        {
            "o": "Riesa",
            "l": "Sachsen"
        }
    ],
    "02956": [
        {
            "o": "Rietschen - Rěčicy",
            "l": "Sachsen"
        }
    ],
    "08228": [
        {
            "o": "Rodewisch",
            "l": "Sachsen"
        }
    ],
    "08548": [
        {
            "o": "Rosenbach/Vogtland",
            "l": "Sachsen"
        }
    ],
    "07366": [
        {
            "o": "Rosenthal am Rennsteig",
            "l": "Thüringen"
        }
    ],
    "04741": [
        {
            "o": "Roßwein",
            "l": "Sachsen"
        }
    ],
    "04571": [
        {
            "o": "Rötha",
            "l": "Sachsen"
        }
    ],
    "02929": [
        {
            "o": "Rothenburg/Oberlausitz",
            "l": "Sachsen"
        }
    ],
    "07407": [
        {
            "o": "Rudolstadt",
            "l": "Thüringen"
        },
        {
            "o": "Uhlstädt-Kirchhasel",
            "l": "Thüringen"
        }
    ],
    "07929": [
        {
            "o": "Saalburg-Ebersdorf",
            "l": "Thüringen"
        }
    ],
    "07318": [
        {
            "o": "Saalfeld/Saale",
            "l": "Thüringen"
        }
    ],
    "06198": [
        {
            "o": "Salzatal",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06792": [
        {
            "o": "Sandersdorf-Brehna",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06794": [
        {
            "o": "Sandersdorf-Brehna",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06796": [
        {
            "o": "Sandersdorf-Brehna",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06809": [
        {
            "o": "Sandersdorf-Brehna",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06526": [
        {
            "o": "Sangerhausen",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01968": [
        {
            "o": "Schipkau",
            "l": "Brandenburg"
        },
        {
            "o": "Senftenberg",
            "l": "Brandenburg"
        }
    ],
    "01993": [
        {
            "o": "Schipkau",
            "l": "Brandenburg"
        }
    ],
    "01994": [
        {
            "o": "Schipkau",
            "l": "Brandenburg"
        }
    ],
    "01998": [
        {
            "o": "Schipkau",
            "l": "Brandenburg"
        }
    ],
    "02681": [
        {
            "o": "Schirgiswalde-Kirschau",
            "l": "Sachsen"
        },
        {
            "o": "Wilthen",
            "l": "Sachsen"
        }
    ],
    "04435": [
        {
            "o": "Schkeuditz",
            "l": "Sachsen"
        }
    ],
    "06258": [
        {
            "o": "Schkopau",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09487": [
        {
            "o": "Schlettau",
            "l": "Sachsen"
        }
    ],
    "08289": [
        {
            "o": "Schneeberg",
            "l": "Sachsen"
        }
    ],
    "08261": [
        {
            "o": "Schöneck",
            "l": "Sachsen"
        }
    ],
    "08304": [
        {
            "o": "Schönheide",
            "l": "Sachsen"
        }
    ],
    "07427": [
        {
            "o": "Schwarzburg",
            "l": "Thüringen"
        }
    ],
    "08340": [
        {
            "o": "Schwarzenberg/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "01987": [
        {
            "o": "Schwarzheide",
            "l": "Brandenburg"
        }
    ],
    "01855": [
        {
            "o": "Sebnitz",
            "l": "Sachsen"
        }
    ],
    "06317": [
        {
            "o": "Seegebiet Mansfelder Land",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06464": [
        {
            "o": "Seeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06466": [
        {
            "o": "Seeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06467": [
        {
            "o": "Seeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06469": [
        {
            "o": "Seeland",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09465": [
        {
            "o": "Sehmatal",
            "l": "Sachsen"
        }
    ],
    "02782": [
        {
            "o": "Seifhennersdorf",
            "l": "Sachsen"
        }
    ],
    "01996": [
        {
            "o": "Senftenberg",
            "l": "Brandenburg"
        }
    ],
    "02689": [
        {
            "o": "Sohland an der Spree",
            "l": "Sachsen"
        }
    ],
    "03249": [
        {
            "o": "Sonnewalde",
            "l": "Brandenburg"
        }
    ],
    "09356": [
        {
            "o": "St. Egidien",
            "l": "Sachsen"
        }
    ],
    "08237": [
        {
            "o": "Steinberg",
            "l": "Sachsen"
        }
    ],
    "01616": [
        {
            "o": "Strehla",
            "l": "Sachsen"
        }
    ],
    "08328": [
        {
            "o": "Stützengrün",
            "l": "Sachsen"
        }
    ],
    "07922": [
        {
            "o": "Tanna",
            "l": "Thüringen"
        }
    ],
    "04425": [
        {
            "o": "Taucha",
            "l": "Sachsen"
        }
    ],
    "09249": [
        {
            "o": "Taura",
            "l": "Sachsen"
        }
    ],
    "06682": [
        {
            "o": "Teuchern",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06179": [
        {
            "o": "Teutschenthal",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09380": [
        {
            "o": "Thalheim/Erzgebirge",
            "l": "Sachsen"
        }
    ],
    "01737": [
        {
            "o": "Tharandt",
            "l": "Sachsen"
        },
        {
            "o": "Wilsdruff",
            "l": "Sachsen"
        }
    ],
    "09488": [
        {
            "o": "Thermalbad Wiesenbad",
            "l": "Sachsen"
        }
    ],
    "09419": [
        {
            "o": "Thum",
            "l": "Sachsen"
        }
    ],
    "04861": [
        {
            "o": "Torgau",
            "l": "Sachsen"
        }
    ],
    "04687": [
        {
            "o": "Trebsen/Mulde",
            "l": "Sachsen"
        }
    ],
    "08233": [
        {
            "o": "Treuen",
            "l": "Sachsen"
        }
    ],
    "04938": [
        {
            "o": "Uebigau-Wahrenbrück",
            "l": "Brandenburg"
        }
    ],
    "07333": [
        {
            "o": "Unterwellenborn",
            "l": "Thüringen"
        }
    ],
    "03226": [
        {
            "o": "Vetschau/Spreewald",
            "l": "Brandenburg"
        }
    ],
    "04736": [
        {
            "o": "Waldheim",
            "l": "Sachsen"
        }
    ],
    "01829": [
        {
            "o": "Wehlen",
            "l": "Sachsen"
        }
    ],
    "08538": [
        {
            "o": "Weischlitz",
            "l": "Sachsen"
        }
    ],
    "07950": [
        {
            "o": "Weißendorf",
            "l": "Thüringen"
        },
        {
            "o": "Zeulenroda-Triebes",
            "l": "Thüringen"
        }
    ],
    "06688": [
        {
            "o": "Weißenfels",
            "l": "Sachsen-Anhalt"
        }
    ],
    "03119": [
        {
            "o": "Welzow",
            "l": "Brandenburg"
        }
    ],
    "08412": [
        {
            "o": "Werdau",
            "l": "Sachsen"
        }
    ],
    "04779": [
        {
            "o": "Wermsdorf",
            "l": "Sachsen"
        }
    ],
    "08112": [
        {
            "o": "Wilkau-Haßlau",
            "l": "Sachsen"
        }
    ],
    "01723": [
        {
            "o": "Wilsdruff",
            "l": "Sachsen"
        }
    ],
    "06886": [
        {
            "o": "Wittenberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06888": [
        {
            "o": "Wittenberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "06889": [
        {
            "o": "Wittenberg",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02997": [
        {
            "o": "Wittichenau - Kulow",
            "l": "Sachsen"
        }
    ],
    "09429": [
        {
            "o": "Wolkenstein",
            "l": "Sachsen"
        }
    ],
    "07343": [
        {
            "o": "Wurzbach",
            "l": "Thüringen"
        }
    ],
    "06895": [
        {
            "o": "Zahna-Elster",
            "l": "Sachsen-Anhalt"
        }
    ],
    "01619": [
        {
            "o": "Zeithain",
            "l": "Sachsen"
        }
    ],
    "06711": [
        {
            "o": "Zeitz",
            "l": "Sachsen-Anhalt"
        }
    ],
    "02788": [
        {
            "o": "Zittau",
            "l": "Sachsen"
        }
    ],
    "06780": [
        {
            "o": "Zörbig",
            "l": "Sachsen-Anhalt"
        }
    ],
    "09434": [
        {
            "o": "Zschopau",
            "l": "Sachsen"
        }
    ],
    "08321": [
        {
            "o": "Zschorlau",
            "l": "Sachsen"
        }
    ],
    "04442": [
        {
            "o": "Zwenkau",
            "l": "Sachsen"
        }
    ],
    "08056": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08058": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08060": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08062": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08064": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08066": [
        {
            "o": "Zwickau",
            "l": "Sachsen"
        }
    ],
    "08297": [
        {
            "o": "Zwönitz",
            "l": "Sachsen"
        }
    ]
 
}

export default PlzOrt;