import * as React from 'react';
import { IVLDaten, IVlDatenEinzelperson } from '../types';
import { Row, Col, Button } from 'reactstrap';
import EnumInput from '../../common/aif/EnumInput';
import VlDatenEinzelperson from './VlDatenEinzelperson';
import { IEnum, EnumService } from '../../../services';
import { FaPlus } from 'react-icons/fa';
import { TPerson } from '../../common/Person/types';

interface IVlDatenProps {
    data?: IVLDaten
    person_liste: TPerson[]
    onChange?(data?: IVLDaten): void
}

interface IVlDatenState {
    data?: IVLDaten
    enums?: IEnum
}

export default class VlDaten extends React.Component<IVlDatenProps, IVlDatenState> {
    constructor(props: IVlDatenProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onVertragspartnerDatenChange = this.onVertragspartnerDatenChange.bind(this);
        this.onEhepartnerDatenChange = this.onEhepartnerDatenChange.bind(this);
        this.createVlDaten = this.createVlDaten.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.createEPVlDaten = this.createEPVlDaten.bind(this);
        this.onEhepartenrRemove = this.onEhepartenrRemove.bind(this);
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok })
        })
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state?.data)
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy });
    }

    onVertragspartnerDatenChange(data: IVlDatenEinzelperson) {
        const copy = Object.assign(this.state.data, { vertragspartner_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    onEhepartnerDatenChange(data: IVlDatenEinzelperson) {
        const copy = Object.assign(this.state.data, { ehepartner_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    createVlDaten() {
        const init: IVlDatenEinzelperson = {
            person_index: 0,
        }
        const copy = Object.assign(this.state.data || {}, { vertragspartner_daten: init });
        this.setState({ data: copy }, this.notify);
    }

    createEPVlDaten() {
        const init: IVlDatenEinzelperson = {
            person_index: 1,
        }
        const copy = Object.assign(this.state.data, { ehepartner_daten: init });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        this.setState({ data: undefined }, this.notify);
    }
    onEhepartenrRemove() {
        const copy = Object.assign(this.state.data, { ehepartner_daten: undefined });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        if (!this.state.data) return (
            <fieldset><Row>
                <Col className="text-right"><Button onClick={this.createVlDaten} color="info" outline size="sm"> <FaPlus /> VL</Button></Col>
            </Row></fieldset>
        )

        const partnerBtn = <Row><Col className="text-right"><Button color="info" outline size="sm" onClick={this.createEPVlDaten}><FaPlus /> Ehepartner</Button></Col></Row>;
        const partner = this.state.data.ehepartner_daten ? <VlDatenEinzelperson label="Ehepartner" person_liste={this.props.person_liste} data={this.state.data.ehepartner_daten} onChange={this.onEhepartnerDatenChange} onRemove={this.onEhepartenrRemove} /> : partnerBtn

        return (
            <fieldset className="VlDaten">
                <Row>
                    <Col>
                        <EnumInput required label="Art" name="vl_art" value={this.state.data.vl_art} onChange={this.onChange} />
                    </Col>
                    <Col className="text-right" md="1">

                    </Col>
                </Row>
                <VlDatenEinzelperson label="Vertragspartner" person_liste={this.props.person_liste} data={this.state.data.vertragspartner_daten} onChange={this.onVertragspartnerDatenChange} onRemove={this.onRemove} />
                {partner}
            </fieldset>);
    }
}
