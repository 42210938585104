import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IRegeleinzug } from '../../../types/Lastschrifteinzugdaten';
import { DateInput, EnumInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import Einzug from './Einzug';

interface IRegeleinzugProps {
    data?: IRegeleinzug
    onChange?(data?: IRegeleinzug): void
}

interface IRegeleinzugState {
    data: IRegeleinzug
}

export default class Regeleinzug extends React.Component<IRegeleinzugProps, IRegeleinzugState> {
    constructor(props: IRegeleinzugProps) {
        super(props);

        this.state = {
            data: props.data || {
                rueckstand_einziehen: false,
            }
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onEnzugChange = this.onEnzugChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onEnzugChange(einzug:any){
        let copy = Object.assign(this.state.data,einzug);
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        const data = this.state.data;
        return (
            <>
                <Row>
                    <Col>
                        <EnumInput required label="Rhythmus" name="rhythmus" value={data.rhythmus} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput required label="Einzug ab" name="einzug_ab" value={data.einzug_ab} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Einzug bis" name="einzug_bis" value={data.einzug_bis} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DateInput label="Aussetzun ab" name="aussetzung_ab" value={data.aussetzung_ab} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Aussetzun bis" name="aussetzung_bis" value={data.aussetzung_bis} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CheckBoxInput label="Rueckstand einziehen" name="rueckstand_einziehen" value={data.rueckstand_einziehen} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col className="border-right">
                        <p className="mt-2">Einzug Spar</p>
                        <Einzug einzug={data.einzug_spar} onChange={(data)=>this.onEnzugChange({einzug_spar:data})}/>
                    </Col>
                    <Col className="border-right">
                        <p className="mt-2">Einzug Kredit</p>
                        <Einzug einzug={data.einzug_kredit} onChange={(data)=>this.onEnzugChange({einzug_kredit:data})}/>
                    </Col>
                    <Col>
                        <p className="mt-2">Einzug Darlehen</p>
                        <Einzug einzug={data.einzug_darlehen} onChange={(data)=>this.onEnzugChange({einzug_darlehen:data})}/>
                    </Col>
                </Row>
            </>
        );
    }
}
