import * as React from 'react';
import './style.css';
type TColor = 'text' | 'default' | 'danger' | 'warning' | 'primary' | 'success' | 'info'
interface IAButtonProps {
    label?: string
    color?: TColor
    outline?: boolean
    type?: 'submit' | 'reset' | 'button'
    size?:'sm'
    onClick(ev: React.MouseEvent): void
   rounded?:boolean
}

export default class AButton extends React.Component<IAButtonProps> {
    myRef: React.RefObject<any>;
    constructor(props: IAButtonProps) {
        super(props);
        this.myRef = React.createRef();
        this.onClick = this.onClick.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }
    onClick(ev: any) {
        ev.preventDefault();
        let x = this.myRef.current.offsetLeft ;
        let y = this.myRef.current.offsetTop - this.myRef.current.offsetHeight;
        let ripples = document.createElement('div');
        ripples.className = 'bubble'
        ripples.style.left = x + 'px';
        ripples.style.top = y + 'px';
       // this.myRef.current.appendChild(ripples);
        //ev.target.parentNode().appendChild(ripples);
        //console.log(this.myRef, this.myRef.current.offsetLeft + ' :: ' + this.myRef.current.offsetTop);
        //console.log(ev.target.offsetLeft + ':'+ev.target.offsetTop)
        setTimeout(() => {
            ripples.remove();
        }, 500)
        this.props.onClick(ev);
    }

    onMouseMove(ev: any) {
        // console.log(this.myRef);
    }

    public render() {
        let color: TColor = this.props.color || 'default';
        let className = "AButton " + color +' '+this.props.size;
        if (this.props.outline) { className += ' outline' }
        if (this.props.rounded) { className += ' rounded' }

        return (
            <span ref={this.myRef} style={{ position: 'relative' }} onMouseOver={this.onMouseMove}>
                <div className={className} onClick={this.onClick} >
                    <div className="Label">{this.props.children}  {this.props.label}</div>
                </div>
            </span>
        );
    }
}
//type={this.props?.type||'button'}