import * as React from 'react';
import ASelect from '../aif/ASelect';
import ASelectListItem from '../aif/ASelect/ASelectListItem';
import { TPerson } from './types';

export interface IPersonSelectProps {
    label?: string
    person_liste: TPerson[]
    values: number[]
    onChange?(indexList: number[]): void
}

interface IPersonSelectState {
    values: number[]
}

export default class PersonSelect extends React.Component<IPersonSelectProps, IPersonSelectState> {
    constructor(props: IPersonSelectProps) {
        super(props);
        this.state = {
            values: this.props.values
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(id: number, selected: boolean) {
        let copy = [...this.state.values];
        if (selected) {
            copy.push(id);
        }
        else {
            let i = copy.indexOf(id);
            if (i !== -1) {
                copy.splice(i, 1);
            }
        }

        this.setState({ values: copy }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.values);
            }
        })
    }

    public render() {

        if (!this.props.values) { return <></> }
        const items = this.props.person_liste.map((p:any, i) => {
            const selected = this.state.values.indexOf(i) !== -1;
            const label =  p.vorname+' ' + p.nachname;
            return <ASelectListItem id={i} key={i} label={label} selected={selected} onChange={this.onChange} />
        })

        return (
            <ASelect label={this.props.label || "Person"}>
                {items}
            </ASelect>
        );
    }
}
