import * as React from 'react';
import { TextInput } from '..';

interface INumberInputProps {
  name: string
  label?: string
  value?: number
  min?: number
  max?: number
  required?: boolean
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
}

export default class NumberInput extends React.Component<INumberInputProps> {

  constructor(props: INumberInputProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    let v = parseFloat(ev.target.value) //|| '';
    ev.target = Object.assign(ev.target, { value: v });
    this.props.onChange(ev);
  }
  public render() {

    return (<TextInput type="number"
      name={this.props.name}
      value={this.props.value + ''}
      onChange={this.onChange}
      label={this.props.label}
    />)
  }

  /*
    public render() {
      let mainClass = 'AnimatedInput '
      let labelClass = this.props.value ? 'hasValue' : ''
  
      return (
        <div className={mainClass}>
          <input type="number"
            name={this.props.name}
            value={this.props.value||''}
            onChange={this.onChange}
          />
          <label htmlFor={this.props.name} className={labelClass}>
            <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''}</span>
          </label>
        </div>
      );
    }*/
}
