import React, { Component } from 'react'
import { FaComments, FaUser, FaMoneyCheck, FaClipboardList, FaCalculator, FaChild, FaPiggyBank, FaRegClipboard, FaFile } from 'react-icons/fa';
import { Fade, ButtonGroup, Button } from 'reactstrap';
import { IBausparantrag } from './types';

interface IBottomNavProps {
  active?: string
  data: IBausparantrag
}

interface IState {
  isOpen: boolean
}

export default class BottomNav extends Component<IBottomNavProps, IState> {

  constructor(props: IBottomNavProps) {
    super(props);
    this.togle = this.togle.bind(this);
    this.state = { isOpen: true }
    this.isActive = this.isActive.bind(this);
  }

  togle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  isActive(o: string) {
    return o === window.location.hash || o === this.props.active;
  }

  render() {

    let anfragen = this.props.data.nachrichten_verlauf?.filter(n => n.nachrichtentyp === 'Frage');
    let messageCount = anfragen?.length || 0;
    let messageBadge = messageCount > 0 ? <span className="badge badge-light">{messageCount}</span> : ''

    const readonly = false// (this.props.data.uid)?true:false;
    return (
      <div style={{ textAlign: "center" }}>
        <Fade>
          <ButtonGroup>
            <Button href="#BsvRechner" color="info" active={this.isActive('#BsvRechner')} disabled={readonly}><FaCalculator /><span className="d-none d-md-inline"> BSV Rechner</span> </Button>
            <Button href="#Person" color="info" active={this.isActive('#Person')} disabled={readonly}><FaUser /> <span className="d-none d-md-inline">Personendaten</span></Button>
            <Button href="#Spardaten" color="info" active={this.isActive('#Spardaten')} disabled={readonly}><FaPiggyBank /><span className="d-none d-md-inline"> Spardaten</span> </Button>
            <Button href="#Beguenstigung" color="info" active={this.isActive('#Beguenstigung')} disabled={readonly}><FaChild /><span className="d-none d-md-inline"> Begünstigter</span></Button>
            <Button href="#Lastschrift" color="info" active={this.isActive('#Lastschrift')} disabled={readonly}><FaMoneyCheck /><span className="d-none d-md-inline"> Lastschrift</span></Button>
            <Button href="#Dokumente" color="info" active={this.isActive('#Dokumente')} disabled={readonly}><FaFile /><span className="d-none d-md-inline"> Dokumente</span></Button>
            <Button href="#Protokoll" color="info" active={this.isActive('#Protokoll')} disabled={readonly}><FaRegClipboard /><span className="d-none d-md-inline"> Protokoll</span></Button>
            <Button href="#Comments" color="info" active={this.isActive('#Comments')}><FaComments /> {messageBadge} <span className="d-none d-md-inline">Anfragen</span></Button>
            <Button href="#Summary" color="light" active={this.isActive('#Summary')}><FaClipboardList /><span className="d-none d-md-inline"> Ihr Bausparvertrag</span></Button>
          </ButtonGroup>
        </Fade>
      </div>
    )
  }
}
