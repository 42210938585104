import * as React from 'react';
import { IAbteilung2 } from './types';
import { Row, Col } from 'reactstrap';
import EnumInput from '../../../common/aif/EnumInput';
import TextInput from '../../../common/aif/TextInput';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import { FaTrashAlt } from 'react-icons/fa';
import NumberInput from '../../../common/aif/NumberInput';
import ACollapse from '../../../common/aif/ACollapse';

interface IAbteilung2Props {
    data: IAbteilung2
    onRemove?(data: IAbteilung2): void
}

interface IAbteilung2State {
    data: IAbteilung2
}

export default class Abteilung2 extends React.Component<IAbteilung2Props, IAbteilung2State> {
    constructor(props: IAbteilung2Props) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IAbteilung2Props) {
        this.setState({ data: newProps.data || [] });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: newVal });
    }

    remove() {
        if (this.props.onRemove) { this.props.onRemove(this.props.data); }
    }

    public render() {
        return (
            <div className="Abteilung">
                <div className="text-right"><FaTrashAlt onClick={this.remove} className="removeIcon" /></div>
                <Row>
                    <Col>
                        <EnumInput enum="last_art" onChange={this.onChange} label="Lastenart" name="lastenart" value={this.state.data.lastenart + ''} />
                    </Col>
                    <Col><TextInput label="Laufende Nummer" name="laufende_nummer" onChange={this.onChange} value={this.state.data.laufende_nummer} /></Col>
                    <Col><CurrencyInput label="Betrag" name="betrag" onChange={this.onChange} value={this.state.data.betrag} /></Col>
                </Row>
                <ACollapse>
                    <Row>
                        <Col>
                            <TextInput label="Eingetragen für" name="eingetragen_fuer" onChange={this.onChange} value={this.state.data.eingetragen_fuer} />
                        </Col>
                        <Col>
                            <NumberInput label="Rang" name="rang" onChange={this.onChange} value={this.state.data.rang} />
                        </Col>
                        <Col>
                            <TextInput label="Grundbuch" name="grundbuch" onChange={this.onChange} value={this.state.data.grundbuch} />
                        </Col>
                    </Row>
                </ACollapse>
            </div>
        );
    }
}
