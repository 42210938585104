import * as React from 'react';
import SettingsService from '../../services/SettingsService';
import { syncRequest } from '../../services/SyncService';
import { cazeDB, ICazeStoreItem } from '../../stores/dexiestore';
import { ILastschrifteinzugantrag } from '../../types/Lastschrifteinzugantrag';
import Comments from '../common/Comments/Comments';
import { IPrivatperson } from '../common/Person/types';
import Summary from './Summary';
import TopNav from './TopNav';
import { RouteComponentProps } from 'react-router-dom'
import LEDaten from './LE-Daten';
import DokumentenListe from '../common/Dokumente';
import { IBezugsobjekt } from '../common/aif/BezugsObjektInput'
import { getPersonLabel } from '../common/Person/PersonUtils'


interface ILastschrifteinzugantragProps extends RouteComponentProps {

}

interface ILastschrifteinzugantragState {

    data: ILastschrifteinzugantrag
    _sync: boolean
    _idbid?: string
    _render?: boolean
}
const _initPerson = (): IPrivatperson => {
    return {
        person_art: 'Privat'
    }
}

const _init = (): ILastschrifteinzugantrag => {
    return {
        antrag_art: 'Lastschrifteinzugantrag',
        loesch_kz: false,
        person_liste: [_initPerson()],
        verbund_liste: [],
        antrag_informationen: {},
    }
}


export default class Lastschrifteinzugantrag extends React.Component<ILastschrifteinzugantragProps, ILastschrifteinzugantragState> {
    constructor(props: ILastschrifteinzugantragProps) {
        super(props);

        this.state = {
            data: _init(),
            _sync: false

        }
        this.onDataChange = this.onDataChange.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    componentDidMount() {
        //@ts-ignore
        const _id = this.props.match.params?._id;
        if (_id) {
            cazeDB.getCazeByKey(_id).then((ok: any) => {
                const xstate = ok as ILastschrifteinzugantragState;
                this.setState(xstate, () => { });
            })
        }
    }

    shouldComponentUpdate(nextProps: ILastschrifteinzugantragProps, nextState: ILastschrifteinzugantragState) {
        if (nextState._render === false) {
            delete nextState._render;
            return false;
        }
        return true;
    }

    onDataChange(obj: any) {
        const settings = SettingsService.getSettings();
        obj.vermittlerdaten = {
            firma: settings.vermittler?.firma,
            nachname: settings.vermittler?.nachname,
            vorname: settings.vermittler?.vorname,
            lbs_user_id: settings.vermittler?.lbs_user_id,
            email: settings.vermittler?.email,
            telefon: settings.vermittler?.telefon,
        }

        const copy = Object.assign(this.state.data, obj);
        this.setState({ data: copy, _render: false, _sync: false }, () => {
            this.saveData();
        });
    }
    saveData() {
        let p = Object.assign({}, this.state.data.person_liste[0]);
        if (!p.nachname) { return; }
        let dataPre = Object.assign(this.state, {timestamp: Date.now()});
        const data: ICazeStoreItem = Object.assign(dataPre);
        cazeDB.saveCaze(data);
        syncRequest(data._idbid);
    }

    public render() {
        const personListe = [];
        for (var index = 0; index < this.state.data.person_liste.length; index++){
            const currentItem = this.state.data.person_liste[index];
            let bezugsobjekt : IBezugsobjekt ={
                label : getPersonLabel(currentItem),
                index : index
            } 
            personListe[index] = bezugsobjekt;
        }

        const conf = {
            '#LEDaten': <LEDaten antrag={this.state.data} data={this.state.data.lastschrifteinzugdaten} onChange={this.onDataChange} />,
            '#Comments': <Comments backendId={this.state.data.antrag_id} messages={this.state.data?.nachrichten_verlauf}></Comments>,
            '#Dokumente':
                <DokumentenListe
                    antragId={this.state.data.antrag_id}
                    data={this.state.data.dokument_liste || []}
                    personListe={personListe}
                    onDataChange={this.onDataChange} />,
            '#Summary': <Summary data={this.state.data} _idbid={this.state._idbid} onDataChange={this.onDataChange} />,
        }

        const state = this.props.location?.hash || Object.keys(conf)[0];
        const i = Object.keys(conf).indexOf(state);
        const view = Object.values(conf)[i]

        return (
            <div>
                <TopNav data={this.state.data}></TopNav>
                <div style={{ maxHeight: '90vh', overflow: 'auto' }}>{view}</div>
            </div>
        );
    }
}
