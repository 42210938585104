import * as React from 'react';
import {IImmobilie} from './types';
import {Row, Col} from 'reactstrap';
import {IEnum, EnumService} from '../../../../services';
import EnumInput from '../../../common/aif/EnumInput';
import {FaCopy, FaTrashAlt} from 'react-icons/fa';
import MoreTabs from './MoreTabs';
import ACollapse from '../../../common/aif/ACollapse';
import PersonSelect from '../../../common/Person/PersonSelect';
import {TPerson} from '../../../common/Person/types';
import CheckBoxInput from '../../../common/aif/CheckBoxInput';
import './Immobilie.css'

export interface IImmobilieProps {
    data: IImmobilie
    person_liste: TPerson[]

    onChange?(data: IImmobilie): void

    onRemove?(data: IImmobilie): void

    onCopy?(data: IImmobilie): void
}

interface IState {
    enums?: IEnum
    data: IImmobilie
    expand: boolean
}

export default class Immobilie extends React.Component<IImmobilieProps, IState> {

    constructor(props: IImmobilieProps) {
        super(props);
        this.state = {data: this.props.data, expand: false};
        this.onChange = this.onChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.onOwnerListChange = this.onOwnerListChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IImmobilieProps) {
        this.setState({data: newProps.data})
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({enums: ok});
        })
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    toggle() {
        this.setState({expand: !this.state.expand});
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, {[ev.target.name]: ev.target.value});
        this.setState({data: newVal}, this.notify);
    }

    remove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.data);
        }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    onOwnerListChange(liste: number[]) {
        let copy = Object.assign(this.state.data, {eigentuemer_index_liste: liste});
        this.setState({data: copy}, this.notify);
    }

    public render() {
        if (!this.state.enums) {
            return <></>
        }
        return (
            <div className="border p-2 mb-2">
                <Row>
                    <Col>
                        <div className="h5 text-secondary">Immobilie</div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="Immobilie entfernen"/>
                        <FaCopy onClick={this.copy} className="copyIcon" title="Immobilie kopieren"/>
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>
                <Row>
                    <Col><EnumInput required name="finanzierungszweck" value={this.state.data.finanzierungszweck}
                                    onChange={this.onChange}/></Col>
                    <Col>
                        <EnumInput required label="Objektart" name="objekt_art" value={this.state.data.objekt_art}
                                   onChange={this.onChange}/>
                    </Col>
                    <Col>
                        <PersonSelect label="Eigentümer" onChange={this.onOwnerListChange}
                                      person_liste={this.props.person_liste}
                                      values={this.state.data.eigentuemer_index_liste}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EnumInput required label="Nutzung" name="nutzung_art" value={this.state.data.nutzung_art}
                                   onChange={this.onChange}/>
                        <EnumInput label="Verwendungs Kennzeichen" name="verwendungs_kennzeichen" enum={"verwendungs_kennzeichen"} value={this.state.data.verwendungs_kennzeichen}
                                   onChange={(ev) => {
                                       this.onChange(ev);
                                       if(ev.target.value === "Projektfinanzierung"){
                                           const newValue = Object.assign(this.state.data, {vorgesehene_besicherung: null})
                                           this.setState({data: newValue}, () => {
                                               this.notify()
                                           })
                                       }
                                   }}/>
                    </Col>
                    <Col>
                        <CheckBoxInput required label="Finanzierungsgegenstand" name="ist_finanzierungsgegenstand"
                                       value={this.state.data.ist_finanzierungsgegenstand} onChange={this.onChange}/>
                        <EnumInput label="Vorgesehene Besicherung" name="vorgesehene_besicherung" enum={"vorgesehene_besicherung"} value={this.state.data.vorgesehene_besicherung}
                                   onChange={(ev) => {
                                       this.onChange(ev);
                                       if(this.state.data.verwendungs_kennzeichen === "Projektfinanzierung"){
                                           const newValue = Object.assign(this.state.data, {verwendungs_kennzeichen: null})
                                           this.setState({data: newValue}, () => {
                                               this.notify()
                                           })

                                       }
                                   }}/>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ACollapse>
                            <MoreTabs data={this.state.data} onChange={this.notify}/>
                        </ACollapse>
                    </Col>
                </Row>
            </div>
        );
    }
}
