import * as React from 'react';

interface ISelectInputProps {
    name: string
    value?: string | number
    label?: string
    required?: boolean
    onChange(ev: React.ChangeEvent<HTMLInputElement>): void
    ref?: React.RefObject<HTMLSelectElement>
}

interface IState {
    value?: string | number
}


export default class SelectInput extends React.Component<ISelectInputProps, IState> {

    constructor(props: ISelectInputProps) {
        super(props);
        this.state = {
            value: props.value
        }
        this.onChange = this.onChange.bind(this);
    }
    UNSAFE_componentWillReceiveProps(newProps: ISelectInputProps) {
        this.setState({ value: newProps.value });
    }

    onChange(ev: any) {
        let evx: React.ChangeEvent<HTMLInputElement> = Object.assign({}, ev);
        if (this.props.onChange) {
            this.props.onChange(evx);
        }
    }

    public render() {
        let mainClass = 'AnimatedInput SelectInput'
        const value = this.state?.value
        const emptyValue = (value === undefined || value === null || value === '');
        let labelClass = emptyValue ? '' : 'hasValue'

        if (this.props.required && emptyValue) {
            mainClass += ' invalid'
        }

        return (
            <div className={mainClass}>
                <select
                    ref={this.props.ref}
                    name={this.props.name}
                    value={value || ''}
                    onChange={this.onChange}
                >
                    {this.props.children}
                </select>
                <label htmlFor={this.props.name} className={labelClass}>
                    <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''}</span>
                </label>
            </div>
        );
    }
}
