import React, {useState} from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown} from 'reactstrap';

interface StatusDropdownProps {
    onStatusSelect: (status: string) => void;
}

export function StatusDropdown(props: StatusDropdownProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleStatusSelect = (status: string) => {
        props.onStatusSelect(status);
    }

    return (
        <ButtonDropdown color="info" isOpen={dropdownOpen}
                        toggle={toggle}>
            <DropdownToggle caret color="info">
                Status
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={() => handleStatusSelect('In Planung')}>In Planung</DropdownItem>
                <DropdownItem onClick={() => handleStatusSelect('Offen')}>Offen</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
}