import * as React from 'react';
import { IBeguenstigungDaten } from '../types';
import { Row, Col, Button, Container, Fade } from 'reactstrap';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import TextInput from '../../common/aif/TextInput';
import DateInput from '../../common/aif/DateInput';
import { Ort } from '../../../inputValidations';

interface IBeguenstigterProps {
    data?: IBeguenstigungDaten
    onChange?(data?: any): void
}

interface IBeguenstigterState {
    data?: IBeguenstigungDaten
}

export default class Beguenstigter extends React.Component<IBeguenstigterProps, IBeguenstigterState> {
    constructor(props: IBeguenstigterProps) {
        super(props);

        this.state = {
            data: this.props.data
        }
        this.createBeguenstigter = this.createBeguenstigter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onAdressChange = this.onAdressChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IBeguenstigterProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange({ beguenstigung_daten: this.state.data });
        }
    }

    createBeguenstigter() {
        const init: IBeguenstigungDaten = {}
        this.setState({ data: init })
    }

    handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data || {}, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onAdressChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newAddress = Object.assign(this.state.data?.anschrift || {}, { [ev.target.name]: ev.target.value });
        const newState = Object.assign(this.state.data, { anschrift: newAddress });
        this.setState({ data: newState }, this.notify);
    }

    onRemove() {
        this.setState({ data: undefined }, this.notify);
    }

    public render() {
        if (!this.state.data) return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col className="text-right  pt-4"><Button onClick={this.createBeguenstigter} color="info" outline > <FaPlus /> Hinzufügen</Button></Col>
                    </Row>
                </Fade>
            </Container>
        )
        return (
            <Container className="mt-4">

                <Fade>
                    <Row className="mt-4">
                        <Col className="text-secondary">Begünstigter</Col>
                        <Col className="text-right">
                            <FaTrashAlt style={{ position: 'relative' }} className="removeIcon" onClick={this.onRemove} title="Entfernen" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <TextInput
                                value={this.state.data.vorname}
                                onChange={this.handleChange}
                                name="vorname"
                                required
                                label="Vorname"
                            />

                            <TextInput
                                value={this.state.data.name}
                                onChange={this.handleChange}
                                name="name"
                                required
                                label="Nachname"
                            />
                            <DateInput
                                value={this.state.data.geburtsdatum}
                                name="geburtsdatum"
                                required
                                onChange={this.handleChange} 
                                label="Geburtsdatum"
                                />
                        </Col>
                        <Col>
                            <Row>
                                <Col sm="8">
                                    <TextInput required label="Strasse" name='strasse' value={this.state.data.anschrift?.strasse} onChange={this.onAdressChange} /></Col>
                                <Col>
                                    <TextInput required label="Hausnummer" name='hausnummer' value={this.state.data.anschrift?.hausnummer} onChange={this.onAdressChange} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4">
                                    <TextInput required label="PLZ" name='postleitzahl' value={this.state.data.anschrift?.postleitzahl} onChange={this.onAdressChange} />
                                </Col>
                                <Col>
                                    <Ort required plz={this.state.data.anschrift?.postleitzahl || '0'} label="Ort" name='ort' value={this.state.data.anschrift?.ort} onChange={this.onAdressChange} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Fade>
            </Container>

        );
    }
}
