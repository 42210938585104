import * as React from 'react';
export interface ICurrencyInputProps {
  name: string
  label?: string
  value?: number
  required?: boolean
  /**
   * default DE-de
   */
  localeString?: string
  /**
   * default EUR
   */
  currency?: string
  append?: string
  onChange(ev: React.ChangeEvent<HTMLInputElement>): void
  onBlur?(): void
  autoFocus?: boolean
}

const null_value = '-.-';

export default class CurrencyInput extends React.Component<ICurrencyInputProps> {

  //theInput:any | null | undefined;
  cursor: number = 0;
  constructor(props: ICurrencyInputProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.toMaskedValue = this.toMaskedValue.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  toMaskedValue(): string {

    if (this.props.value !== undefined && this.props.value !== null) {
      const ret = parseFloat(this.props.value + '').toLocaleString(this.props.localeString || 'DE-de', {
        style: 'currency',
        currency: this.props.currency || 'EUR',
      })
      const digits = ret.match(/\d+/g);
      return (digits !== null) ? ret : null_value;
    }
    else {
      return null_value;
    }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const mval = ev.target.value;// || "0";

    try {
      if (mval) {
        const digits = mval.match(/\d+/g);
        let val = null;
        if (digits !== null) { val = parseFloat((digits ? digits.join('') : '0.00')) / 100; }
        ev.target = Object.assign(ev.target, { value: val });
        this.props.onChange(ev);
      }
      else {
        this.props.onChange(ev);
      }

    } catch (e) {
      console.error(e);
    }
  }

  onClick(ev: any) {
    const mval = ev.target.value;
    this.cursor = ev.target.selectionEnd || mval.length - 2;
  }
  maxCursor() {
    const val = Math.round((this.props.value || 0) * 100) / 100;
    return val.toFixed(2);
  }

  handleFocus(ev: React.FocusEvent<HTMLInputElement>) {
    this.cursor = ev.target.selectionEnd || this.maxCursor().toLocaleString().length;
    if (this.cursor > this.maxCursor().toLocaleString().length) {
      this.cursor = this.maxCursor().toLocaleString().length
    }
    ev.target.setSelectionRange(this.cursor, this.cursor);
  }

  /**
   * move cursor to right position
   *    
   * */
  handleKeyUp(el: any) {
    if (el.key === 'ArrowRight' || el.key === 'ArrowLeft' || el.key === 'Shift') {
      this.cursor = el.target.selectionStart;
    }
    else {
      this.cursor++
      if (this.cursor > this.maxCursor().toLocaleString().length) {
        this.cursor = this.maxCursor().toLocaleString().length
      }
      el.target.setSelectionRange(this.cursor, this.cursor);
    }
  }

  public render() {
    const value = this.toMaskedValue();
    let mainClass = 'AnimatedInput CurrencyInput'

    const hasValue = (value && value !== null_value) ? true : false;
    let labelClass = hasValue ? 'hasValue' : ''
    if (this.props.required && !hasValue) { mainClass += ' invalid'; }

    return (
      <div className={mainClass}>
        <input type="text"
          name={this.props.name}
          value={value}
          onChange={this.onChange}
          onKeyUp={this.handleKeyUp}
          onFocus={this.handleFocus}
          onBlur={this.props.onBlur}
          autoFocus={this.props.autoFocus}
        />
        <label htmlFor={this.props.name} className={labelClass}>
          <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''} {this.props.append}</span>
        </label>
      </div>
    );
  }
}
