import * as React from 'react';
import {IKapitaldienstdaten} from './types';
import {Container, Fade, Button} from 'reactstrap';
import {Kapitaldienstdaten} from './Kapitaldienstsdaten';
import {FaPlus} from 'react-icons/fa';
import {TPerson} from '../../common/Person/types';
import {IVerbund} from '../../common/Verbund/types';

export * from './Kapitaldienstsdaten';

export interface IKapitaldienstListeProps {
    data: IKapitaldienstdaten[]

    onChange?(data: any): void

    person_liste: TPerson[]
    verbund_liste: IVerbund[]
}

interface IState {
    data: IKapitaldienstdaten[]
}

export default class KapitaldienstListe extends React.Component<IKapitaldienstListeProps, IState> {
    constructor(props: IKapitaldienstListeProps) {
        super(props);
        this.state = {data: this.props.data}
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.onChange = this.onChange.bind(this);
        this.copyItem = this.copyItem.bind(this);
        this.notify = this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IKapitaldienstListeProps) {

        this.setState({data: newProps.data});
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange({kapitaldienstdaten_liste: this.state.data});
        }
    }

    addItem() {
        const newItem: IKapitaldienstdaten = {
            person_index: 0,
            ausgaben_liste: [],
            einnahmen_liste: [],
            korrekturen_liste: []
        }
        let copy: IKapitaldienstdaten[] = [...this.state.data];
        copy.push(newItem);
        this.setState({data: copy}, this.notify);
    }

    onChange(data: IKapitaldienstdaten) {
        const index = this.state.data.indexOf(data);
        if (index !== -1) {
            let copy = [...this.state.data];
            copy[index] = data;
            this.setState({data: copy}, this.notify);
        }
    }

    /*removeItem(index: number) {
      if (this.state.data[index]) {
        let copy = [...this.state.data];
        copy.splice(index, 1);
        this.setState({ data: copy }, this.notify);
      }
    }*/


    removeItem(data: IKapitaldienstdaten) {
        const index = this.state.data.indexOf(data);
        if (index !== -1) {
            let copy = [...this.state.data]
            copy.splice(index, 1);
            this.setState({data: copy}, this.notify);
        }
    }

    copyItem(data: IKapitaldienstdaten) {
        let copy = [...this.state.data]
        copy.push(data);
        this.setState({data: copy}, this.notify);
    }


    public render() {
        const items = this.state.data.map((k, i) => {
            return (<Kapitaldienstdaten data={k} verbund_liste={this.props.verbund_liste}
                                        person_liste={this.props.person_liste} key={i} onRemove={this.removeItem} onCopy={this.copyItem}
                                        onChange={this.onChange}/>)
        });

        return (
            <Container className="mt-4">
                <Fade>
                    <div className="text-right">
                        <Button onClick={this.addItem} color="info" outline> <FaPlus/> Neu</Button>
                    </div>
                    {items}
                </Fade>
            </Container>
        );
    }
}
