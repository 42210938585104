import * as React from 'react';
import {FaPlus} from 'react-icons/fa';
import {ButtonDropdown, Container, DropdownItem, DropdownMenu, DropdownToggle, Fade, ListGroup, ListGroupItem} from 'reactstrap';
import {TPerson} from '../../common/Person/types';
import { IVerbund } from '../../common/Verbund/types';
import FremderBausparvertrag from './FremderBausparvertrag';
import {TFremdeVereinbarung, IFremderFinanzierungsbaustein, IFremderBausparvertrag} from "./types";
import FremderFinanzierungsbaustein from './FremderFinanzierungsbaustein';
import { IFM_ListeItem } from '../../common/Riesterdaten';

interface IFremdeVereinbarungListeProps {
    data: TFremdeVereinbarung[]
    onChange?(data: any): void
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
}

interface IState {
    dropdownOpen: boolean
    liste: TFremdeVereinbarung[]
}

export default class FremdeVereinbarungListe extends React.Component<IFremdeVereinbarungListeProps, IState> {
    constructor(props: IFremdeVereinbarungListeProps) {
        super(props);
        this.state = { dropdownOpen: false, liste: props.data }
        this.addFinanzierungsbaustein = this.addFinanzierungsbaustein.bind(this);
        this.addBausparvertrag = this.addBausparvertrag.bind(this);
        this.toggleButtonDropdown = this.toggleButtonDropdown.bind(this);
        this.onListItemChange = this.onListItemChange.bind(this);
        this.removeListItem = this.removeListItem.bind(this);
        this.copyListItem = this.copyListItem.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IFremdeVereinbarungListeProps) {
        this.setState({ liste: newProps.data });
    }

    toggleButtonDropdown() {
        this.setState({dropdownOpen: !this.state.dropdownOpen})
    }

    addFinanzierungsbaustein() {
        const init: IFremderFinanzierungsbaustein = {
            fremde_vereinbarung_art: 'Fremder Finanzierungsbaustein',
            person_index: 0,
            rate: 0,
            rhythmus: 'Monatlich'
        }
        let copy = Object.assign({}, this.state);
        copy.liste.push(init);
        this.setState({ liste: copy.liste });
    }

    addBausparvertrag() {
        const init: IFremderBausparvertrag = {
            fremde_vereinbarung_art: 'Fremder Bausparvertrag',
            person_index: 0,
            rate: 0,
            rhythmus: 'Monatlich'
        }
        let copy = Object.assign({}, this.state);
        copy.liste.push(init);
        this.setState({ liste: copy.liste });
    }

    onListItemChange(index: number, item: TFremdeVereinbarung) {
        const copy = [...this.state.liste];
        copy[index] = item;
        this.setState({ liste: copy }, () => {
            if (this.props.onChange) {
                this.props.onChange({ fremde_vereinbarung_liste: this.state.liste });
            }
        });
    }

    removeListItem(index: number) {
        const copy = [...this.state.liste]
        copy.splice(index, 1);
        this.setState({ liste: copy }, () => {
            if (this.props.onChange) {
                this.props.onChange({ fremde_vereinbarung_liste: this.state.liste });
            }
        });
    }

    copyListItem(data: any) {
        const copy = [...this.state.liste]
        copy.push({...data})
        this.setState({liste: copy}, () => {
            if (this.props.onChange) {
                this.props.onChange({ fremde_vereinbarung_liste: this.state.liste });
            }
        });
    }

	public render() {
		const fv_liste: IFM_ListeItem[] = this.state.liste.map((fv, i) => {
			return { index: i, title: fv.fremde_vereinbarung_art }
		});

		const fremdeVereinbarungen = this.state.liste.map((fv, i) => {
		    const data: any = fv;
		    const getFv = () => {
                if (fv.fremde_vereinbarung_art === "Fremder Finanzierungsbaustein") {
                    return (<FremderFinanzierungsbaustein
                        onRemove={this.removeListItem}
                        onCopy={this.copyListItem}
                        onChange={this.onListItemChange}
                        data={data} index={i}
                        person_liste={this.props.person_liste}
                        verbund_liste={this.props.verbund_liste}
                        key={i}
                      />)
                } else if (fv.fremde_vereinbarung_art === "Fremder Bausparvertrag") {
                    return (<FremderBausparvertrag
                        onRemove={this.removeListItem}
                        onCopy={this.copyListItem}
                        onChange={this.onListItemChange}
                        data={data} key={i}
                        person_liste={this.props.person_liste}
                        verbund_liste={this.props.verbund_liste}
                        index={i}
                      />)
                }
            }
            return (<ListGroupItem key={i}>{getFv()}</ListGroupItem>)
        })

		return (
		  <Container className="mt-4" style={{ minHeight: '600px' }}>
            <Fade>
			  <div className="text-right mb-2">
				<ButtonDropdown color="info" isOpen={this.state.dropdownOpen} toggle={this.toggleButtonDropdown}>
				  <DropdownToggle caret color="info">
					<FaPlus /> Neu
				  </DropdownToggle>
				  <DropdownMenu>
                    <DropdownItem onClick={this.addFinanzierungsbaustein}>Fremder Finanzierungsbaustein</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={this.addBausparvertrag}>Fremder Bausparvertrag</DropdownItem>
				  </DropdownMenu>
				</ButtonDropdown>
			  </div>
			  <ListGroup>{fremdeVereinbarungen}</ListGroup>
			</Fade>
		  </Container>
		);
	}
}
