import * as React from 'react';
import {IBausparantrag} from './types';
import {Container, Fade, Button, Col, Row} from 'reactstrap';
import {FaSyncAlt, FaShare} from 'react-icons/fa';
import BackendService from '../../services/BackendService';
import {ToastEvents} from '../common/Toast';
import ReactJson from 'react-json-view'
import {TextInput} from '../common/aif';
import CheckBoxInput from '../common/aif/CheckBoxInput';
import {StatusDropdown} from "../common/Summary/StatusDropdown";
import {AntragLoeschenOderAendernButton} from "../common/Summary/AntragLoeschenOderAendernButton";
import AntragReloadButton from "../common/Summary/AntragReloadButton";

export interface ISummaryProps {
    data: IBausparantrag,

    onDataChange?(obj: any): void

    _idbid?: string
}

interface IState {
    loading: boolean
    data: IBausparantrag
    antragGespeichert: IBausparantrag
}

export default class Summary extends React.Component<ISummaryProps, IState> {
    constructor(props: ISummaryProps) {
        super(props)
        this.state = {loading: false, data: this.props.data, antragGespeichert: {...this.props.data}}
        this.sendBsv = this.sendBsv.bind(this);
        this.encodedURI = this.encodedURI.bind(this);
        this.onAntragInformationenChange = this.onAntragInformationenChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onDataChange) {
            this.props.onDataChange(this.state.data);
        }
    }

    onAntragInformationenChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let antragInformationen = Object.assign(this.state.data.antrag_informationen, {[ev.target.name]: ev.target.value});
        let copy = Object.assign(this.state.data, {antrag_informationen: antragInformationen});
        this.setState({data: copy}, this.notify);
    }

    UNSAFE_componentWillReceiveProps(newProps: ISummaryProps) {
        this.setState({data: newProps.data});
    }


    async sendBsv() {
        this.setState({loading: true});
        try {
            let sendBSV = Object.assign(this.props.data, {antrag_informationen:{extern_id: this.props._idbid}});
            if (this.props.data.lastschrifteinzugdaten
                && this.props.data.lastschrifteinzugdaten.abweichender_kontoinhaber_anschrift === undefined
                && this.props.data.lastschrifteinzugdaten.abweichender_kontoinhaber_name === undefined
                && this.props.data.lastschrifteinzugdaten.einmaleinzug === undefined
                && this.props.data.lastschrifteinzugdaten.iban === undefined
                && this.props.data.lastschrifteinzugdaten.regeleinzug === undefined
                && this.props.data.lastschrifteinzugdaten.unterschriftsdatum === undefined) {
                sendBSV = Object.assign(sendBSV, {lastschrifteinzugdaten: undefined});
            }
            let statusData = this.state.data.status;
            sendBSV.status = statusData;
            const ok = await BackendService.sendAntrag(sendBSV);
            const savedBsv: IBausparantrag = ok.data;
            this.handleAntragChange(savedBsv)

            if (this.props.onDataChange) {
                this.props.onDataChange(savedBsv);
            }
            ToastEvents.notify("Antrag übertragen")
        } catch (err) {
            ToastEvents.error('Fehler bei Antragübertragung! ' + err.message);
            if (err.response && err.response.status === 400) // Validation fails
            {
                if (err.response.data.messages) {
                    const msgs = err.response.data.messages;
                    msgs.forEach((m: any) => {
                        ToastEvents.error(m);
                    });
                } else {
                    ToastEvents.error(err.response.statusText);
                }
            }
        } finally {
            this.setState({loading: false});
        }
    }

    encodedURI(): string {
        const str = JSON.stringify(this.state.data, null, 2);
        const encodedURI = encodeURI('data:application/json;charset=utf-8,' + str);
        return encodedURI;
    }

    handleStatusSelect = (status: string) => {
        let data = {...this.state.data};
        data.status = status;
        this.setState({data: data});
    }

    handleAntragChange = (antrag: any) => {
        this.setState({data: antrag});
        this.setState({antragGespeichert: {...antrag}})
    }

    public render() {
        const icon = this.state.loading ? <FaSyncAlt className="runsync"/> : <FaShare/>;
        const sendBtn = <Button disabled={this.state.loading} onClick={this.sendBsv}
                                color="info">{icon} Absenden</Button>;
        const encURI = this.encodedURI();
        const dName = this.state.data.antrag_art + '.json';

        return (
            <Container className="mt-4">
                <Fade>
                    <p>{this.state.data.antrag_art}</p>
                    <Row>
                        <Col> <TextInput label="Transaktionsnummer (TAN)" name="tan"
                                         value={this.state.data.antrag_informationen.tan === undefined ? "" :this.state.data.antrag_informationen.tan}
                                         onChange={this.onAntragInformationenChange}/></Col>
                        <Col> <CheckBoxInput label="Druck" name="druck_kz"
                                             value={this.state.data.antrag_informationen.druck_kz === undefined ? false : this.state.data.antrag_informationen.druck_kz}
                                             onChange={this.onAntragInformationenChange}/></Col>
                        <Col> <StatusDropdown onStatusSelect={this.handleStatusSelect}/></Col>
                        <Col className="pt-2 text-right">
                            {sendBtn}
                            <a href={encURI} download={dName} className="btn btn-primary">Export</a>
                            <AntragLoeschenOderAendernButton antrag={this.state.data}
                                                             antragGespeichert={this.state.antragGespeichert}
                                                             onChange={this.handleAntragChange} isLoeschen={true}/>
                            <AntragLoeschenOderAendernButton antrag={this.state.data}
                                                             antragGespeichert={this.state.antragGespeichert}
                                                             onChange={this.handleAntragChange} isLoeschen={false}/>
                            <AntragReloadButton antragId={this.state.antragGespeichert.antrag_id === undefined ? "" : this.state.antragGespeichert.antrag_id} onAntragLoaded={this.handleAntragChange}/>
                        </Col>
                    </Row>
                    <ReactJson src={this.state.data} collapsed={1}></ReactJson>
                </Fade>

            </Container>
        );
    }
}

