import * as React from 'react';
import { IKapitaldienst } from './types';
import { Container, Fade, Row, Col } from 'reactstrap';
import NumberInput from '../../common/aif/NumberInput';
import KapitaldienstListe from './KapitaldienstListe';
import { TPerson } from '../../common/Person/types';
import { IVerbund } from '../../common/Verbund/types';
import KinderAlterInput from './Kinder';

interface IKapitaldienstProps {
    data: IKapitaldienst
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    onChange(obj: any): void
}

interface IKapitaldienstState {
    data: IKapitaldienst
}

export default class Kapitaldienst extends React.Component<IKapitaldienstProps, IKapitaldienstState> {
    constructor(props: IKapitaldienstProps) {
        super(props);
        this.state = {
            data: this.props.data
        }
        this.onChange = this.onChange.bind(this);
        this.onListeChange = this.onListeChange.bind(this);
        this.onKinderListeChange = this.onKinderListeChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        this.props.onChange({ kapitaldienst: this.state.data });
    }

    UNSAFE_componentWillReceiveProps(newProps: IKapitaldienstProps) {
        this.setState({ data: newProps.data });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: newVal }, this.notify);
    }

    onListeChange(obj: any) {
        let copy = Object.assign(this.state.data, obj);
        this.setState({ data: copy }, this.notify);
    }


    onKinderListeChange(liste: number[]) {
        let copy = Object.assign(this.state.data, {kind_alter_liste:liste});
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <Container className="mt-4" style={{ minHeight: '600px' }}>
                <Fade>
                    <Row>
                        <Col>
                            <NumberInput onChange={this.onChange} label="Haushaltsgrösse" name="haushaltsgroesse" value={this.state.data.haushaltsgroesse} />
                        </Col>
                        <Col>
                            <NumberInput onChange={this.onChange} label="Anzahl Kfz" name="anzahl_kfz" value={this.state.data.anzahl_kfz} />
                        </Col>
                        <Col>
                            <KinderAlterInput value={this.state.data.kind_alter_liste} onChange={this.onKinderListeChange}/>
                        </Col>
                    </Row>
                    <KapitaldienstListe onChange={this.onListeChange} data={this.state.data.kapitaldienstdaten_liste} person_liste={this.props.person_liste} verbund_liste={this.props.verbund_liste} />
                </Fade>
            </Container>
        );
    }
}

