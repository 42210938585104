import * as React from 'react';
import ATabs from '../../../common/aif/ATabs';
import ATab from '../../../common/aif/ATabs/ATab';
import ATubContent from '../../../common/aif/ATabs/ATabContent';
import ATabNav from '../../../common/aif/ATabs/ATabNav';
import Immoblienbewertung from './Immoblienbewertung';
import Modernisierungskosten from './Modernisierungskosten';
import Objektdaten from './Objektdaten';
import Objektkosten from './Objektkosten';
import {IImmobilie} from './types';

interface IMoreTabsProps {
    data: IImmobilie
    onChange?():void
}

interface IMoreTabsState {
    activeTab: number
}

export default class MoreTabs extends React.Component<IMoreTabsProps, IMoreTabsState> {
    render() {
        return (
            <ATabs>
                <ATabNav>
                    <ATab>Objektkosten</ATab>
                    <ATab>Objektdaten</ATab>
                    <ATab>Modernisierungskosten</ATab>
                    <ATab>Immoblienbewertung</ATab>
                </ATabNav>
                <ATubContent>
                    <Objektkosten data={this.props.data.objektkosten} onChange={this.props.onChange}/>
                </ATubContent>
                <ATubContent>
                    <Objektdaten data={this.props.data.objektdaten} onChange={this.props.onChange}/>
                </ATubContent>
                <ATubContent>
                    <Modernisierungskosten data={this.props.data.modernisierungskosten} onChange={this.props.onChange}/>
                </ATubContent>
                <ATubContent>
                    <Immoblienbewertung data={this.props.data.bewertung} onChange={this.props.onChange}/>
                </ATubContent>
            </ATabs>
        );
    }
}
