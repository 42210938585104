import * as React from 'react';
import { Container, Fade, Row, Col } from 'reactstrap';
import IKundenwunsch from './types';
import { EnumInput, NumberInput, CurrencyInput, PercentInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

export interface IKundenwunschProps {
    onDataChange(data: any): void,
    data?: IKundenwunsch
}

interface IKundenwunschState {
    data: IKundenwunsch
}

export default class Kundenwunsch extends React.Component<IKundenwunschProps, IKundenwunschState> {
    constructor(props: IKundenwunschProps) {
        super(props);
        this.state = {
            data: props.data || {}
        }

        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        this.props.onDataChange({ kundenwunsch: this.state.data });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const value = (ev.target.value === '' ? null : ev.target.value);
        let copy = Object.assign(this.state.data, { [ev.target.name]: value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col>
                            <EnumInput enum="kundenpraeferenz" label="Zinssicherheit" name="zinssicherheit" value={this.state.data.zinssicherheit} onChange={this.onChange} />
                            <EnumInput enum="kundenpraeferenz" label="Flexibilitaet" name="flexibilitaet" value={this.state.data.flexibilitaet} onChange={this.onChange} />
                            <NumberInput name="festschreibung_dauer" label="Dauer der Festschreibung" value={this.state.data.festschreibung_dauer} onChange={this.onChange} />
                            <PercentInput name="sondertilgung_hoehe" label="Höhe der Sondertilgung" value={this.state.data.sondertilgung_hoehe} onChange={this.onChange} />
                            <NumberInput name="rueckzahlung_bis" label="Rückzahlung bis" value={this.state.data.rueckzahlung_bis} onChange={this.onChange} />
                            <CheckBoxInput name="riester_bereits_vorhanden" label="Riester bereits vorhanden" value={this.state.data.riester_bereits_vorhanden} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <CurrencyInput name="wunschrate" label="Wunschrate" value={this.state.data.wunschrate} onChange={this.onChange} />
                            <EnumInput enum="kundenpraeferenz" label="Förderung" name="foerderung" value={this.state.data.foerderung} onChange={this.onChange} />
                            <EnumInput enum="kundenpraeferenz" label="Riester" name="riester" value={this.state.data.riester} onChange={this.onChange} />
                            <EnumInput enum="kundenpraeferenz" label="Immobilie absichern" name="absicherung_immo" value={this.state.data.absicherung_immo} onChange={this.onChange} />
                            <EnumInput enum="kundenpraeferenz" label="Familie absichern" name="absicherung_familie" value={this.state.data.absicherung_familie} onChange={this.onChange} />
                        </Col>
                    </Row>
                </Fade>
            </Container>
        );
    }
}


