import * as React from 'react';
import DateInput from '../../../common/aif/DateInput';
import {IEnergieausweis} from './types';
import {Button, Card, Col, Row} from "reactstrap";
import EnumInput from "../../../common/aif/EnumInput";
import {ToastEvents} from "../../../common/Toast";


export interface IEnergieausweisProps {
    data?: IEnergieausweis

    onChange?(data?: IEnergieausweis): void
}

interface IState {
    ea: IEnergieausweis
    isOpen: boolean
}

export default class Energieausweis extends React.Component<IEnergieausweisProps, IState> {

    constructor(props: IEnergieausweisProps) {
        super(props);
        let datenVorhanden = false
        if(this.props.data){
            datenVorhanden = true
        }
        this.state = {
            ea: this.props.data || {},
            isOpen: datenVorhanden,
        }
        this.onChange = this.onChange.bind(this);
        this.changeOpen = this.changeOpen.bind(this);
        this.safe = this.safe.bind(this);
    }

    safe(){
        console.log(this.state.ea)
        if(this.props.onChange){
            this.props.onChange(this.state.ea)
            ToastEvents.notify("Erfolgreich gespeichert")
        } else {
            ToastEvents.error("Konnte nicht gespeichert werden")
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {

        const newVal = {[ev.target.name]: ev.target.value}
        const newState: IEnergieausweis = Object.assign(this.state.ea, newVal);
        console.log("new State: " && newState)
        this.setState({ea: {...newState}});
    }

    changeOpen() {
        const isOpen = this.state.isOpen
        if (isOpen && this.props.onChange){
            this.props.onChange(undefined);
            this.setState({ea: {}});
        }
        this.setState({isOpen: !isOpen})
    }

    public render() {
        const ea = this.state.ea;
        const buttonName: string = this.state.isOpen ? "Lösche Energieausweis" : "Erstelle Energieausweis";
        const isSafeDisabled:boolean =ea.energieeffizienzklasse === undefined || ea.energieausweis_ausstellungsdatum === undefined;
        return (
            <>
                <Button onClick={this.changeOpen} color="info">{buttonName}</Button>

                <div className={this.state.isOpen ? undefined : "hidden"}>
                    <Card>
                        <Row>

                            <Col md={4}>
                                <DateInput
                                    name={"energieausweis_ausstellungsdatum"}
                                    label={"Austellungsdatum"}
                                    value={ea?.energieausweis_ausstellungsdatum}
                                    onChange={this.onChange}
                                    required={true}
                                />
                            </Col>
                            <Col md={4}>
                                <EnumInput
                                    required={true}
                                    onChange={this.onChange}
                                    value={ea?.energieeffizienzklasse}
                                    name="energieeffizienzklasse"
                                    label="Energieeffizienzklasse"
                                    enum="energieeffizienzklasse"
                                />
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                    </Card>
                    <Button onClick={this.safe} color="info" disabled={isSafeDisabled}>Speichern</Button>
                </div>
            </>
        );
    }
}

