import * as React from 'react';
import { IBausparantrag } from '../../Bausparantrag/types';
import { Badge} from 'reactstrap';
import { Link } from 'react-router-dom';

export interface IBsvListItemProps {
    data: any
    onSync?(): void
}

export default class BsvListItem extends React.Component<IBsvListItemProps> {
    public render() {
        const bsv: IBausparantrag = this.props.data.bsv;
        const title = bsv.person_liste.map((p: any, index) => {
            if (index > 2) return '...';
            return <span key={index}>{index === 0 ? '' : ' ,'} {p.vorname} {p.nachname}</span>
        })
        const path = '/bsve/' + this.props.data._idbid;
        return (
            <div className="InboxListItem">
                <div><Badge className={bsv.antrag_art}> Bsv </Badge>
                    <Link to={path}  > {title}</Link>
                </div>
                <div className="text-right">
                    {this.props.data.datum + " "}
                    {bsv.status}
                </div>
            </div>
        );
    }
}
