import React, { Component } from 'react';
import Start from '../../Beratung/index'
import Bsv from '../../Bausparantrag';
import Settings from '../Settings';
import Inbox from '../Inbox';
import ImmoList from '../../Immopool';
import { Route } from "react-router-dom";
import Tarife from '../Settings/Tarife/Tarife';
import Fbad from '../../Finanzierungsantrag'
import Dashboard from '../../Dashboard';
import BsvSimu from '../../BsvSimu'
import Lastschrifteinzugantrag from '../../Lastschrifteinzugantrag';
import Personendatenantrag from '../../Personendatenantrag';

class Routes extends Component {
    render() {
        return (

            <div>
                <Route path="/" exact component={Inbox}></Route>
                <Route path="/home" exact component={Inbox}></Route>
                <Route path="/bsve" exact component={Bsv}></Route>
                <Route path="/start" component={Start}></Route>
                <Route path="/bsve/:_id" exact component={Bsv}></Route>
                
                <Route path="/le" exact component={Lastschrifteinzugantrag}></Route>
                <Route path="/le/:_id" exact component={Lastschrifteinzugantrag}></Route>
                
                 <Route path="/pda" exact component={Personendatenantrag}></Route>
                <Route path="/pda/:_id" exact component={Personendatenantrag}></Route>
               
                <Route path="/inbox" component={Inbox}></Route>
                <Route path="/settings" component={Settings}></Route>
                <Route path="/immopool" component={ImmoList}></Route>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/tarife" component={Tarife} />
                <Route path="/fbad" exact component={Fbad} />
                <Route path="/bsvsimu" exact component={BsvSimu} />
                <Route path="/fbad/:_id" component={Fbad} />
            </div>
        );
    }
}

export default Routes;
