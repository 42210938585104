import * as React from 'react';

interface IATubProps {
}

interface IATubState {
}

export default class ATub extends React.Component<IATubProps, IATubState> {
  constructor(props: IATubProps) {
    super(props);

    this.state = { }
  }

  public render() {
    return (
      <div className="ATub">
        {this.props.children}
      </div>
    );
  }
}
