import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { NumberInput, PercentInput, CurrencyInput } from '../../common/aif';
import { IBsvProvisionVermittlerDaten } from '../types';
import { FaTrashAlt } from 'react-icons/fa';

interface IProvisionVermittlerDatenProps {
    data: IBsvProvisionVermittlerDaten
    onChange?(data: IBsvProvisionVermittlerDaten): void
    onRemove?(data: IBsvProvisionVermittlerDaten): void
}

interface IProvisionVermittlerDatenState {
    data: IBsvProvisionVermittlerDaten
}

export default class ProvisionVermittlerDaten extends React.Component<IProvisionVermittlerDatenProps, IProvisionVermittlerDatenState> {
    constructor(props: IProvisionVermittlerDatenProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.data);
        }
    }

    public render() {
        return (
            <Row>
                <Col>
                    <NumberInput required label="Vermittler Nr." name="vermittler_nr" value={this.state.data.vermittler_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <PercentInput label="Provision Satz" name="provision_satz" value={this.state.data.provision_satz} onChange={this.onChange} />
                </Col>
                <Col>
                    <CurrencyInput label="Betrag" name="provision_betrag" value={this.state.data.provision_betrag} onChange={this.onChange} />
                </Col>
                <Col md="1" className="text-right"><FaTrashAlt style={{ position: 'absolute', bottom: '1rem', right: '1rem' }} className="removeIcon" onClick={this.onRemove} title="Entfernen" />
                </Col>
            </Row>
        );
    }
}
