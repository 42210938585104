import * as React from 'react';
import { IFM_ListeItem, IRiesterdaten, IRiesterdatenEhepartner, IRiesterdatenKind } from './types';
import { Row, Col, Button } from 'reactstrap';
import { TPerson } from '../Person/types';
import Kind from './Kind';
import { CurrencyInput, DateInput, EnumInput, NumberInput } from '../aif';
import CheckBoxInput from '../aif/CheckBoxInput';
import { FaPlus } from 'react-icons/fa';
import RiesterdatenEhepartner from './RiesterdatenEhepartner';
import SelectInput from '../aif/SelectInput';

export * from './types'

interface IRiesterdatenProps {
  data?: IRiesterdaten
  person_liste: TPerson[]
  fm_liste: IFM_ListeItem[]
  onChange?(data: IRiesterdaten): void
}

interface IRiesterdatenState {
  data: IRiesterdaten
}

export default class Riesterdaten extends React.Component<IRiesterdatenProps, IRiesterdatenState> {

  constructor(props: IRiesterdatenProps) {
    super(props);

    this.state = {
      data: this.props.data || {
        zulagenberechtigung: 'mittelbar',
        monatliche_eigenleistung_fkue: false
      }
    }

    this.notify = this.notify.bind(this);
    this.addKind = this.addKind.bind(this);
    this.remKind = this.remKind.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onEhepartnerChange = this.onEhepartnerChange.bind(this);
  }

  notify() {
    if (this.props.onChange) {
      this.props.onChange(this.state.data);
    }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const val = ev.target.valueAsDate || ev.target.valueAsNumber || ev.target.value;
    const copy = Object.assign(this.state.data, { [ev.target.name]: val });
    this.setState({ data: copy }, this.notify);
  }

  addKind() {
    const liste = [...this.state.data.kind_liste || []];
    liste.push({});
    const copy = Object.assign(this.state.data, { kind_liste: liste });
    this.setState({ data: copy }, this.notify);
  }

  remKind(kind: IRiesterdatenKind) {
    const index = this.state.data.kind_liste?.indexOf(kind);
    if (index !== undefined && index !== -1) {
      const liste = [...this.state.data.kind_liste || []];
      liste.splice(index, 1);
      const copy = Object.assign(this.state.data, { kind_liste: liste });
      this.setState({ data: copy }, this.notify);
    }
  }

  onEhepartnerChange(data?: IRiesterdatenEhepartner | undefined) {
    const copy = Object.assign(this.state.data, { ehepartner_daten: data });
    this.setState({ data: copy }, this.notify);
  };

  public render() {
    const kinder = this.state.data.kind_liste?.map((k, i) => <Kind data={k} onRemove={this.remKind} key={i} />);

    const opts = this.props.fm_liste.map(li => <option value={li.index} key={li.index}>{li.title}</option>)

    return (
      <div>
        <Row>
          <Col>
            <EnumInput enum="riester_zulagenberechtigung" name="zulagenberechtigung" label="Zulagenberechtigung" value={this.state.data.zulagenberechtigung} onChange={this.onChange} />
          </Col>
          <Col>
            <NumberInput label="Renteneintrittsalter" name="renteneintrittsalter" value={this.state.data.renteneintrittsalter} onChange={this.onChange} />
          </Col>
          <Col>
            <CurrencyInput label="Bruttogehalt Vorjahr" name="bruttogehalt_vorjahr" value={this.state.data.bruttogehalt_vorjahr} onChange={this.onChange} />
          </Col>
          <Col>
            <EnumInput enum="riester_berechnungsziel" label="Berechnungsziel" name="berechnungsziel" value={this.state.data.berechnungsziel} onChange={this.onChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <CurrencyInput label="Monatliche Eigenleistung" name="monatliche_eigenleistung_betrag" value={this.state.data.monatliche_eigenleistung_betrag} onChange={this.onChange} />
          </Col>
          <Col>
            <DateInput label="Eigenleistung Termin" name="monatliche_eigenleistung_termin" value={this.state.data.monatliche_eigenleistung_termin} onChange={this.onChange} />
          </Col>
          <Col>
            <CheckBoxInput label="FKÜ Relevant" name="monatliche_eigenleistung_fkue" value={this.state.data.monatliche_eigenleistung_fkue} onChange={this.onChange} />
          </Col>
          <Col>
            <EnumInput label="Zusatzzahlung" enum="riester_zusatzzahlung" name="zusatzzahlung" value={this.state.data.zusatzzahlung} onChange={this.onChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <EnumInput label="Zulagenverwendung" enum="riester_zulagenverwendung" name="zulagenverwendung" value={this.state.data.zulagenverwendung} onChange={this.onChange} />
          </Col>
          <Col>
            <CurrencyInput label="Kapitaluebernahme" name="kapitaluebernahme_betrag" value={this.state.data.kapitaluebernahme_betrag} onChange={this.onChange} />
          </Col>
          <Col>
            <DateInput label="Kapitaluebernahme Termin" name="kapitaluebernahme_termin" value={this.state.data.kapitaluebernahme_termin} onChange={this.onChange} />
          </Col>
          <Col>
            <SelectInput label="Finanzierungsmittel" name="finanzierungsmittel_index" value={this.state.data.finanzierungsmittel_index} onChange={this.onChange}>
              <option></option>
              {opts}
            </SelectInput>
          </Col>
        </Row>
        <div className="mt-4"></div>
        <RiesterdatenEhepartner data={this.state.data.ehepartner_daten} onChange={this.onEhepartnerChange} />
        <div className="mb-2"></div>
        <Row>
          <Col className="text-right">
            <Button size="sm" outline color="info" onClick={this.addKind}><FaPlus /> Kinder</Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2"> {kinder}</Col>
        </Row>
      </div>
    );
  }
}
