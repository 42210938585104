import * as React from 'react';
import { IPrivatperson, IKommunikationsdaten, IDsgvoEinwilligung } from './types';
import { Fade, Row, Col } from 'reactstrap';
import ACollapse from '../aif/ACollapse';
import TextInput from '../aif/TextInput';
import EnumInput from '../aif/EnumInput';
import { Ort, DocNr } from '../../../inputValidations';
import DateInput from '../aif/DateInput';
import Kommunikationsdaten from './Kommunikationsdaten';
import SelectInput from '../aif/SelectInput';
import DsgvoEinwilligung from './DsgvoEinwilligung';
import CheckBoxInput from '../aif/CheckBoxInput';

export interface IPrivatpersonProps {
    person: IPrivatperson
    onChange?(data: IPrivatperson): void
}

interface IPrivatpersonState {
    person: IPrivatperson
}

export default class Privatperson extends React.Component<IPrivatpersonProps, IPrivatpersonState> {
    constructor(props: IPrivatpersonProps) {
        super(props);

        this.state = {
            person: props.person
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKommDataChange = this.onKommDataChange.bind(this);
        this.onDSGVOChange = this.onDSGVOChange.bind(this);
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.person);
        }
    }

    UNSAFE_componentWillReceiveProps(newPros: IPrivatpersonProps) {
        this.setState({ person: newPros.person });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let name = ev.target.name;
        let split = name.split(".");
        let xname: any = {};
        for (let i = split.length; i > 0; i--) {
            let k = split[i - 1];
            if (i === split.length) {
                xname[k] = ev.target.value === "" ? null : ev.target.value;
            } else {
                let person: any = Object.assign({}, this.state.person);
                let subobject = person[k] || {};
                const assign = Object.assign(subobject, xname);
                xname = { [k]: assign };
            }
        }
        const copy = Object.assign(this.state.person, xname)
        this.setState({ person: copy }, this.notify);
    }

    onKommDataChange(data: IKommunikationsdaten[]) {
        let copy = Object.assign(this.state.person, { kommunikationsdaten_liste: data });
        this.setState({ person: copy }, this.notify);
    }

    onDSGVOChange(data: IDsgvoEinwilligung) {
        const copy = Object.assign(this.state.person, { dsgvo_einwilligung: data });
        this.setState({ person: copy }, this.notify);
    }

    public render() {
        let plzValue = '';
        let ortValue = '';
        let hausnummerValue = '';
        let strasseValue = '';
        let landValue;
        let anschriftZusatzValue;
        if(this.state.person.haupt_anschrift !== undefined){
            plzValue = this.state.person.haupt_anschrift.postleitzahl;
            ortValue = this.state.person.haupt_anschrift.ort;
            hausnummerValue = this.state.person.haupt_anschrift.hausnummer;
            strasseValue = this.state.person.haupt_anschrift.strasse
            landValue = this.state.person.haupt_anschrift.land
            anschriftZusatzValue= this.state.person.haupt_anschrift.anschrift_zusatz
        }

        return (
            <div className="pb-4">
                <Fade>
                    <ACollapse expand={true} label="Person">
                        <Row>
                            <Col md="4">
                                <Row>
                                    <Col>
                                        <TextInput
                                            label="Personen Nr."
                                            name="person_nr"
                                            type="number"
                                            value={this.state.person.person_nr}
                                            onChange={this.onChange} />
                                        <EnumInput required label="Anrede" name="anrede" value={this.state.person.anrede} onChange={this.onChange} />
                                    </Col>
                                    <Col>
                                        <SelectInput
                                            name="person_art"
                                            value={this.state.person.person_art}
                                            onChange={this.onChange}
                                            label="Personenart"
                                            required
                                        >
                                            <option>Privat</option>
                                            <option>Geschäftlich</option>
                                            <option>Juristisch</option>

                                        </SelectInput>
                                        <EnumInput label="Titel" name="titel" value={this.state.person.titel} onChange={this.onChange} />
                                    </Col>
                                </Row>
                                <TextInput required label="Vorname" name="vorname" value={this.state.person.vorname} onChange={this.onChange} />
                                <TextInput required label="Nachname" name="nachname" value={this.state.person.nachname} onChange={this.onChange} />
                                <TextInput label="Namenserweiterung" name="namenserweiterung" value={this.state.person.namenserweiterung} onChange={this.onChange} />

                            </Col>
                            <Col md="4">
                                <TextInput label="Weitere Vornamen" name="weitere_vornamen" value={this.state.person.weitere_vornamen} onChange={this.onChange} />
                                <TextInput label="Geburtsname" name="geburtsname" value={this.state.person.geburtsname} onChange={this.onChange} />

                                <Row>
                                    <Col md="4">
                                        <TextInput
                                            value={plzValue}
                                            onChange={this.onChange}
                                            name="haupt_anschrift.postleitzahl"
                                            label="PLZ"
                                            required
                                            size={5}
                                        />
                                    </Col>
                                    <Col>
                                        <Ort
                                            plz={plzValue}
                                            value={ortValue}
                                            onChange={this.onChange}
                                            name="haupt_anschrift.ort"
                                            required={true}
                                        ></Ort>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <TextInput
                                            value={strasseValue}
                                            onChange={this.onChange}
                                            name="haupt_anschrift.strasse"
                                            label="Strasse"
                                            required
                                        ></TextInput>
                                    </Col>
                                    <Col>
                                        <TextInput
                                            label="HNr.:"
                                            value={hausnummerValue}
                                            onChange={this.onChange}
                                            name="haupt_anschrift.hausnummer"

                                        ></TextInput>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TextInput label="Anschriftzusatz" name="haupt_anschrift.anschrift_zusatz" value={anschriftZusatzValue} onChange={this.onChange} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <EnumInput label="Rolle" name="rolle" value={this.state.person.rolle} onChange={this.onChange} />
                                <EnumInput label="Familienstand" name="familienstand" value={this.state.person.familienstand} onChange={this.onChange} required />


                                <DateInput
                                    value={this.state.person.geburtsdatum}
                                    onChange={this.onChange}
                                    name="geburtsdatum"
                                    label="Geburtsdatum"
                                    required
                                ></DateInput>

                                <TextInput
                                    label="Geburtsort"
                                    name="geburtsort"
                                    onChange={this.onChange}
                                    value={this.state.person.geburtsort}
                                    required
                                />
                                <EnumInput label="Land" name="haupt_anschrift.land" value={landValue} enum="land" onChange={this.onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <EnumInput
                                    onChange={this.onChange}
                                    value={this.state.person.staatsangehoerigkeit}
                                    name="staatsangehoerigkeit"
                                    label="Staatsangehörigkeit"
                                    required
                                >
                                    <option value={0}> - - </option>
                                    <option value="Deutsch">Deutsch</option>
                                </EnumInput>
                            </Col>
                            <Col>
                                <CheckBoxInput label="Aktualisierung" name="haupt_anschrift.einfache_aktualisierung" value={this.state.person.haupt_anschrift?.einfache_aktualisierung} onChange={this.onChange} />
                            </Col>
                        </Row>
                    </ACollapse>

                    <ACollapse label="Post Anschrift">
                        <Row >
                            <Col md="4">
                                <EnumInput label="Land" name="post_anschrift.land" value={this.state.person.post_anschrift?.land} enum="land" onChange={this.onChange} />

                                <TextInput
                                    value={this.state.person.post_anschrift?.postleitzahl}
                                    onChange={this.onChange}
                                    name="post_anschrift.postleitzahl"
                                    label="PLZ"
                                    size={5}
                                />
                            </Col>
                            <Col>
                                <Ort
                                    plz={this.state.person.post_anschrift?.postleitzahl + ''}
                                    value={this.state.person.post_anschrift?.ort}
                                    onChange={this.onChange}
                                    name="post_anschrift.ort"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                <TextInput
                                    value={this.state.person.post_anschrift?.strasse}
                                    onChange={this.onChange}
                                    name="post_anschrift.strasse"
                                    label="Strasse"

                                ></TextInput>
                            </Col>
                            <Col>
                                <TextInput
                                    label="HNr.:"
                                    value={this.state.person.post_anschrift?.hausnummer}
                                    onChange={this.onChange}
                                    name="post_anschrift.hausnummer"
                                ></TextInput>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextInput label="Anschriftzusatz"
                                    name="post_anschrift.anschrift_zusatz"
                                    value={this.state.person.post_anschrift?.anschrift_zusatz} onChange={this.onChange} />
                            </Col>
                            <Col md={2}>
                                <CheckBoxInput label="Aktualisierung" name="post_anschrift.einfache_aktualisierung" value={this.state.person.post_anschrift?.einfache_aktualisierung} onChange={this.onChange} />
                            </Col>
                            <Col md={2}>
                                <CheckBoxInput label="Löschkennzeichen" name="post_anschrift.loesch_kz" value={this.state.person.post_anschrift?.loesch_kz} onChange={this.onChange} />
                            </Col>
                        </Row>
                    </ACollapse>
                    <ACollapse label="Berufliche Angaben">
                        <Row>
                            <Col md={4}>
                                <EnumInput
                                    label="Berufliche Stellung"
                                    name="berufliche_stellung"
                                    value={this.state.person.berufliche_stellung + ''}
                                    onChange={this.onChange} />
                                <TextInput
                                    label="Arbeitgeber"
                                    type="text"
                                    onChange={this.onChange}
                                    value={this.state.person.arbeitgeber_name || ''}
                                    name="arbeitgeber_name"
                                />
                                <DateInput
                                    onChange={this.onChange}
                                    value={this.state.person.beschaeftigt_seit}
                                    name="beschaeftigt_seit"
                                    label="Beschaeftigt seit"
                                />
                            </Col>
                            <Col md={4}>
                                <TextInput
                                    label="Beruf"
                                    onChange={this.onChange}
                                    value={this.state.person.beruf}
                                    name="beruf"
                                />
                                <EnumInput label="Wirtschaftszweig" enum="wirtschaftszweig" name="arbeitgeber_wirtschaftszweig" value={this.state.person.arbeitgeber_wirtschaftszweig} onChange={this.onChange}/>   
                            </Col>
                            <Col>
                                <TextInput
                                    label="Steuer-ID"
                                    onChange={this.onChange}
                                    value={this.state.person.steuer_id}
                                    name="steuer_id"
                                />
                                <EnumInput label="Branche" enum="branche" name="branche" value={this.state.person.branche} onChange={this.onChange}/>
                                
                            </Col>
                        </Row>

                    </ACollapse>

                    <ACollapse label="Legitimation">
                        <Row className="mt-4">
                            <Col md={6}>
                                

                                <EnumInput onChange={this.onChange}
                                    value={this.state.person.legitimation?.dokumenten_art}
                                    name="legitimation.dokumenten_art"
                                    enum="dokumenten_art"
                                    label="Dokument"
                                    required
                                />

                                <DocNr
                                    onChange={this.onChange}
                                    name="legitimation.dokumenten_nr"
                                    value={this.state.person.legitimation?.dokumenten_nr}
                                    art={this.state.person.legitimation?.dokumenten_art}
                                    label="Dokumentennummer"
                                    required
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    label="Ausstellende Behörde"
                                    onChange={this.onChange}
                                    value={this.state.person.legitimation?.ausstellende_behoerde}
                                    name="legitimation.ausstellende_behoerde"
                                    required
                                />
                                <Row>
                                    <Col md={6}>
                                        <DateInput
                                            onChange={this.onChange}
                                            value={this.state.person.legitimation?.ausstellungsdatum}
                                            name="legitimation.ausstellungsdatum"
                                            label="Ausstellungsdatum"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <DateInput
                                            onChange={this.onChange}
                                            value={this.state.person.legitimation?.ablaufdatum}
                                            name="legitimation.ablaufdatum"
                                            label="Ablaufdatum"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </ACollapse>
                    <ACollapse label="Kommunikationsdaten">
                        <Kommunikationsdaten onChange={this.onKommDataChange} data={this.state.person.kommunikationsdaten_liste} />
                    </ACollapse>
                    <ACollapse label="DSGVO">
                        <DsgvoEinwilligung data={this.state.person.dsgvo_einwilligung} onChange={this.onDSGVOChange} />
                    </ACollapse>
                </Fade>
            </div>
        );
    }
}
