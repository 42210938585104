import * as React from 'react';
import {  FaFileInvoiceDollar, FaUser, FaLeaf, FaBalanceScale, FaShieldAlt, FaComments, FaHandshake, FaMoneyBillAlt, FaClipboardList, FaDiagnoses, FaFile } from 'react-icons/fa';
import { Fade, ButtonGroup, Button } from 'reactstrap';
import { IFinanzierungsantrag } from './types';
import { ITextNachricht } from '../../types';

interface IState {

}
export interface ITopNavProps {
    active: string
    data: IFinanzierungsantrag
}

export default class TopNav extends React.Component<ITopNavProps> {
    isActive(o: string) {
        return o === window.location.hash || o === this.props.active;
    }

    public render() {
        let anfragen=this.props.data.nachrichten_verlauf?.filter((n:ITextNachricht)=> n.nachrichtentyp === 'Frage');
        let messageCount = anfragen?.length||0;
        //let messageCount = (this.props.data.nachrichten_verlauf) ? this.props.data.nachrichten_verlauf.length : 0;
        let messageBadge = messageCount > 0 ? <span className="badge badge-light">{messageCount}</span> : ''

        return (
            <div className="d-flex justify-content-center">
                <Fade>
                    <ButtonGroup>
                        <Button href="#Person" color="info" active={this.isActive('#Person')} ><FaUser /> <span className="d-none d-md-inline">Person</span></Button>
                        <Button href="#Kundenwunsch" color="info" active={this.isActive('#Kundenwunsch')}><FaDiagnoses /><span className="d-none d-md-inline"> Kundenwunsch</span></Button>
                        <Button href="#Vorhaben" color="info" active={this.isActive('#Vorhaben')}><FaLeaf /><span className="d-none d-md-inline"> Vorhaben</span></Button>
                        <Button href="#Finanzierung" color="info" active={this.isActive('#Finanzierung')}><FaFileInvoiceDollar /><span className="d-none d-md-inline"> Finanzieren</span></Button>
                        <Button href="#Kapitaldienst" color="info" active={this.isActive('#Kapitaldienst')}><FaBalanceScale /><span className="d-none d-md-inline"> Kapitaldienst</span></Button>
                        <Button href="#Sicherheiten" color="info" active={this.isActive('#Sicherheiten')}><FaShieldAlt /><span className="d-none d-md-inline"> Sicherheiten</span></Button>
                        <Button href="#Eigenmittel" color="info" active={this.isActive('#Eigenmittel')}><FaMoneyBillAlt /> <span className="d-none d-md-inline">Eigenmittel</span></Button>
                        <Button href="#FremdeVereinbarung" color="info" active={this.isActive('#FremdeVereinbarung')}><FaHandshake /> <span className="d-none d-md-inline"> Fremde Vereinb.</span></Button>
                        <Button href="#Dokumente" color="info" active={this.isActive('#Dokumente')}><FaFile /><span className="d-none d-md-inline"> Dokumente</span></Button>
                        <Button href="#Comments" color="info" active={this.isActive('#Comments')}><FaComments /> {messageBadge} <span className="d-none d-md-inline">Anfragen</span></Button>
                        <Button href="#Summary" color="light" active={this.isActive('#Summary')}><FaClipboardList /><span className="d-none d-md-inline"> Ihr Antrag</span></Button>
                    </ButtonGroup>
                </Fade>
            </div>
        );
    }
}
