import * as React from 'react';
import { AButton, ACollapse, CurrencyInput, DateInput } from '../common/aif';
import BSTarifSelect from '../common/BSTarivSelect';
import { IBsvSimuRequest } from './types'
import './style.css'
import { Row, Col } from 'reactstrap';
import TarifService from '../common/Settings/Tarife/TarifService';
import { FaPlus } from 'react-icons/fa';
import SparRate from './SparRate';
import BsvRechner from './lib/BsvRechner';
import SparPlan from './SparPlan';
import { ILBResult } from './lib/types';

interface IBsvSimuProps { }
interface IBsvSimuState {
    request: IBsvSimuRequest
    lbResult?: ILBResult
}

export default class BsvSimu extends React.Component<IBsvSimuProps, IBsvSimuState> {
    constructor(props: IBsvSimuProps) {
        super(props);
        let d = new Date();
        d.setDate(1);

        this.state = {
            request: { bausparsumme: 10000, spar_raten_liste: [], vertragsbeginn: d },
        }

        this.onRequestChange = this.onRequestChange.bind(this);
        this.onTarifChange = this.onTarifChange.bind(this);
        this.addSparRate = this.addSparRate.bind(this);
        this.removeSparRate = this.removeSparRate.bind(this);
        this.runSimu = this.runSimu.bind(this);
    }
    runSimu() {
        const request = Object.assign({}, this.state.request);
        const bsvRechner = new BsvRechner(request);
        const lbresult = bsvRechner.run();
        this.setState({ lbResult: lbresult });
    }

    onRequestChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.request, { [ev.target.name]: ev.target.value });
        this.setState({ request: copy });
    }

    onTarifChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const tnr = parseInt(ev.target.value);
        TarifService.getTarif(tnr).then(ok => {
            let copy = Object.assign(this.state.request, { tarif: ok, tarifnummer: ev.target.value });
            this.setState({ request: copy });
            console.log(ok);
        })
    }
    addSparRate() {
        let list = [...this.state.request.spar_raten_liste]
        list.push({
            betrag: 100,
            rhythmus: 'Monatlich',
            beginn_datum: this.state.request.vertragsbeginn || new Date(),
        });
        const copy = Object.assign(this.state.request, { spar_raten_liste: list });
        this.setState({ request: copy });
    }

    removeSparRate(index: number) {
        let list = [...this.state.request.spar_raten_liste]
        list.splice(index, 1);
        const copy = Object.assign(this.state.request, { spar_raten_liste: list });
        this.setState({ request: copy });
    }
    public render() {
        const raten = (this.state.request.spar_raten_liste).map((r, i) => <SparRate onRemove={this.removeSparRate} data={r} index={i} key={i} />)
        return (
            <div className="SimuContainer">
                <p>BSV Simulation</p>
                <ACollapse label="Eingabe" expand={true}>
                    <fieldset style={{ fontSize: '.8rem' }}>
                        <Row>
                            <Col>
                                <BSTarifSelect name="tarifnummer" required
                                    onChange={this.onTarifChange}
                                    value={this.state.request.tarifnummer}
                                />
                            </Col>
                            <Col>
                                <CurrencyInput name="bausparsumme" label="Bausparsumme" value={this.state.request.bausparsumme} onChange={this.onRequestChange} />
                            </Col>
                            <Col><DateInput label="Vertragsbeginn" name="vertragsbeginn" value={this.state.request.vertragsbeginn} onChange={this.onRequestChange} />
                            </Col>
                        </Row>

                        <fieldset>
                            <legend>Sparraten</legend>
                            <Row>
                                <Col></Col>
                                <Col className="text-right">  <AButton size="sm" onClick={this.addSparRate} color="info" outline> <FaPlus /> </AButton>
                                </Col>
                            </Row>
                            {raten}
                        </fieldset>
                    </fieldset>
                    <AButton onClick={this.runSimu} color="success">RUN</AButton>
                </ACollapse>
                <SparPlan data={this.state?.lbResult}></SparPlan>
            </div>
        );
    }
}
