import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IDsgvoEinwilligung } from './types';
import DateInput from '../aif/DateInput';
import TrivalentInput, { ITrivalent } from '../aif/TrivalentInput';
import { FaTrashAlt, FaPlus } from 'react-icons/fa';
import { AButton } from '../aif';

export interface IDsgvoEinwilligungProps {
    data?: IDsgvoEinwilligung
    onChange?(data?: IDsgvoEinwilligung): void
}

interface IDsgvoEinwilligungState {
    data?: IDsgvoEinwilligung
}

export default class DsgvoEinwilligung extends React.Component<IDsgvoEinwilligungProps, IDsgvoEinwilligungState> {
    constructor(props: IDsgvoEinwilligungProps) {
        super(props);
        this.state = {

            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTrivalentChange = this.onTrivalentChange.bind(this);
        this.reset = this.reset.bind(this);
        this.addData = this.addData.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IDsgvoEinwilligungProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onTrivalentChange(data: ITrivalent) {
        let copy = Object.assign(this.state.data, { [data.name]: data.value });
        this.setState({ data: copy }, this.notify);
    }


    reset() {
        if (this.props.onChange) {
            this.props.onChange(undefined);
        }
    }
    addData() {
        this.setState({ data: { } });
    }

    public render() {

        if (!this.state.data) {
            return (<Row>
                <Col className="text-right"><AButton size="sm" outline rounded color="info" onClick={this.addData}><FaPlus /></AButton></Col>
            </Row>)
        }

        return (<>
            <Row>
                <Col>
                    <Col className="text-right" ><FaTrashAlt onClick={this.reset} /> </Col>
                </Col>
            </Row>
            <Row>
                <Col>
                    <TrivalentInput label="Analyse Nutzungsdaten" name="analyse_nutzungsdaten" value={this.state.data.analyse_nutzungsdaten} onChange={this.onTrivalentChange} />
                    <TrivalentInput label="Analyse Personendaten" name="analyse_personendaten" value={this.state.data.analyse_personendaten} onChange={this.onTrivalentChange} />
                    <TrivalentInput label="Analyse Zahlungsverkehrsdaten" name="analyse_zahlungsverkehrsdaten" value={this.state.data.analyse_zahlungsverkehrsdaten} onChange={this.onTrivalentChange} />
                    <TrivalentInput label="Produktinformation elektronisch" name="produkt_information_elektronisch" value={this.state.data.produkt_information_elektronisch} onChange={this.onTrivalentChange} />
                </Col>
                <Col>
                    <TrivalentInput label="Produktinformation per Post" name="produkt_information_post" value={this.state.data.produkt_information_post} onChange={this.onTrivalentChange} />
                    <TrivalentInput label="Produktinformation per Telefon" name="produkt_information_telefon" value={this.state.data.produkt_information_telefon} onChange={this.onTrivalentChange} />
                    <TrivalentInput label="Sprach- und Bildschirmaufzeichnung" name="sprach_und_bildschirm_aufzeichnung" value={this.state.data.sprach_und_bildschirm_aufzeichnung} onChange={this.onTrivalentChange} />
                    <DateInput required label="Druckdatum" name="druckdatum" value={this.state.data.druckdatum} onChange={this.onChange} />
                </Col>
            </Row>

        </>
        );
    }
}
