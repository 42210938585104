import * as React from 'react';
import { IBeratungsprotokoll, IBsvProvision } from '../types';
import { Container, Fade, Row, Col } from 'reactstrap';
import EnumInput from '../../common/aif/EnumInput';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import { TextArea } from '../../common/aif';
import BsvProvision from '../BsvProvision';

type TReturn = { beratungsprotokoll: IBeratungsprotokoll };
interface IBeratungsprotokollProps {
    data?: IBeratungsprotokoll
    provision?: IBsvProvision
    onChange?(data: any): void
}

interface IBeratungsprotokollState {
    data: IBeratungsprotokoll
}

export default class Beratungsprotokoll extends React.Component<IBeratungsprotokollProps, IBeratungsprotokollState> {
    constructor(props: IBeratungsprotokollProps) {
        super(props);

        this.state = {
            data: props.data || {}
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onProvisionChange = this.onProvisionChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange({ beratungsprotokoll: this.state.data });
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onProvisionChange(data: IBsvProvision) {
        if (this.props.onChange) {
            this.props.onChange({ bsv_provision: data });
        }
    }

    public render() {
        return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col>
                            <TextArea label="Protokolltext" name="beratungsprotokoll_text" value={this.state.data.beratungsprotokoll_text} onChange={this.onChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <EnumInput label="Abschlussgrund" enum="abschluss_grund" name="abschluss_grund" value={this.state.data.abschluss_grund} onChange={this.onChange} />
                        </Col>

                        <Col>
                            <CheckBoxInput label="Finanzanalyse dokumentiert" name="finanzanalyse_dokumentiert" value={this.state.data.finanzanalyse_dokumentiert} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <CheckBoxInput label="Sparplan ausgehaendigt" name="sparplan_ausgehaendigt" value={this.state.data.sparplan_ausgehaendigt} onChange={this.onChange} />
                        </Col>
                    </Row>

                    <fieldset className="mt-2">
                        <label>Provision</label>
                        <BsvProvision onChange={this.onProvisionChange} data={this.props.provision}/>
                    </fieldset>
                </Fade>
            </Container>
        );
    }
}
