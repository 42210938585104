import * as React from 'react';
import {Col, Row} from 'reactstrap';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import DateInput from '../../../common/aif/DateInput';
import EnumInput from '../../../common/aif/EnumInput';
import NumberInput from '../../../common/aif/NumberInput';
import TextInput from '../../../common/aif/TextInput';
import {IObjektdaten} from './types';

export interface IObjektdatenProps {
    data: IObjektdaten
    onChange?(data: IObjektdaten): void
}

interface IState {
    od: IObjektdaten
}

export default class Objektdaten extends React.Component<IObjektdatenProps, IState> {

    constructor(props: IObjektdatenProps) {
        super(props);
        this.state = { od: this.props.data||{} }
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IObjektdaten = Object.assign(this.state.od, newVal);
        this.setState({ od: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.od);
            }
        });
    }



    public render() {
        const od = this.props.data||{};
        return (
            <>
            <Row>
                <Col md="4">
                    <EnumInput label="Gebaeudeart" name='gebaeude_art' value={od.gebaeude_art || ''} onChange={this.onChange} />
                    <TextInput label="Postleitzahl" name='plz' value={od.plz || ''} onChange={this.onChange} />
                    <TextInput label="Ort" name='ort' value={od.ort || ''} onChange={this.onChange} />
                    <TextInput label="Strasse" name='strasse' value={od.strasse || ''} onChange={this.onChange} />
                    <TextInput label="HNr.:" name='hausnummer' value={od.hausnummer || ''} onChange={this.onChange} />
                    <EnumInput label="Gebiet" name='gebiet' value={od.gebiet || ''} onChange={this.onChange} />
                    <EnumInput label="Austattung" name='ausstattungsstandard' value={od.ausstattungsstandard || ''} onChange={this.onChange} />
                    <NumberInput label="Anz. Zimmer" name="anzahl_zimmer" value={od.anzahl_zimmer} onChange={this.onChange} />
                    <NumberInput label="Baujahr" name="baujahr" value={od.baujahr} onChange={this.onChange} />
                    <DateInput label="Baubegin" name="baubeginn" value={od.baubeginn} onChange={this.onChange} />
                    <DateInput label="Einzug" name="einzug" value={od.einzug} onChange={this.onChange} />
                    <EnumInput label="Gebäudezustand" name='gebaeude_zustand' value={od.gebaeude_zustand || ''} onChange={this.onChange} />
                    <EnumInput name='lagebeurteilung' value={od.lagebeurteilung || ''} onChange={this.onChange} />
                    <DateInput label="Datum Kaufvertrag" name="kaufvertrag_datum" value={od.kaufvertrag_datum} onChange={this.onChange} />
                    <DateInput name="fertigstellung" value={od.fertigstellung} onChange={this.onChange} />
                    <DateInput label="Letzte Modernisierung" name="letzte_modernisierung" value={od.letzte_modernisierung} onChange={this.onChange} />
                    <EnumInput
                        onChange={this.onChange}
                        value={od.laender_kennzeichen}
                        name="laender_kennzeichen"
                        label="Länderkennzeichen"
                        enum="staatsangehoerigkeit"
                    >
                        <option value={0}> - - </option>
                        <option value="Deutsch">Deutsch</option>
                    </EnumInput>
                </Col>
                <Col md="4">
                    <NumberInput label="Anz. Häuser" name="anzahl_haeuser" value={od.anzahl_haeuser} onChange={this.onChange} />
                    <NumberInput label="Anz. Wohnungen Eigennutz" name="anzahl_wohnungen_eigengenutzt" value={od.anzahl_wohnungen_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Anz. Wohnungen Vermietet" name="anzahl_wohnungen_vermietet" value={od.anzahl_wohnungen_vermietet} onChange={this.onChange} />
                    <NumberInput label="Ant. Einheiten Eigennutzung" name="anzahl_einheiten_eigengenutzt" value={od.anzahl_einheiten_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Anz. Einheiten Vermietet" name="anzahl_einheiten_vermietet" value={od.anzahl_einheiten_vermietet} onChange={this.onChange} />
                    <NumberInput label="Umbauter Raum" name="umbauter_raum" value={od.umbauter_raum} onChange={this.onChange} />
                    <NumberInput label="Wohnfläche eigengenutzt" name="wohnflaeche_eigengenutzt" value={od.wohnflaeche_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Wohnfläche vermietet" name="wohnflaeche_vermietet" value={od.wohnflaeche_vermietet} onChange={this.onChange} />
                    <NumberInput label="Nutzfläche eigengenutzt" name="nutzflaeche_eigengenutzt" value={od.nutzflaeche_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Nutzfläche vermietet" name="nutzflaeche_vermietet" value={od.nutzflaeche_vermietet} onChange={this.onChange} />
                    <NumberInput label="Gewerbefläche eigengenutzt" name="gewerbeflaeche_eigengenutzt" value={od.gewerbeflaeche_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Gewerbefläche vermietet" name="gewerbeflaeche_vermietet" value={od.gewerbeflaeche_vermietet} onChange={this.onChange} />
                    <NumberInput label="Grundstück eigengenutzt" name="grundstueck_eigengenutzt" value={od.grundstueck_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Grundstück vermietet" name="grundstueck_vermietet" value={od.grundstueck_vermietet} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Wohnflaeche" append="/ m²" name="kaltmiete_Wohnflaeche_pro_qm" value={od.kaltmiete_Wohnflaeche_pro_qm} onChange={this.onChange} />


                </Col>
                <Col md="4">
                    <CurrencyInput label="Kaltmiete Nutzfläche" append="/ m²" name="kaltmiete_nutzflaeche_pro_qm" value={od.kaltmiete_nutzflaeche_pro_qm} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Gewerbefläche" append="/ m²" name="kaltmiete_gewerbeflaeche_pro_qm" value={od.kaltmiete_gewerbeflaeche_pro_qm} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Wohnfläche" append="/ Monat" name="kaltmiete_wohnflaeche_pro_monat" value={od.kaltmiete_wohnflaeche_pro_monat} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Nutzfläche" append="/ m²" name="kaltmiete_nutzflaeche_pro_monat" value={od.kaltmiete_nutzflaeche_pro_monat} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Gewerbefläche" append="/ Monat" name="kaltmiete_gewerbeflaeche_pro_monat" value={od.kaltmiete_gewerbeflaeche_pro_monat} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Grundstück" append="/ Monat" name="kaltmiete_grundstueck_pro_monat" value={od.kaltmiete_grundstueck_pro_monat} onChange={this.onChange} />
                    <NumberInput label="Anz. Garagen Eigennutzung" name="anzahl_garagen_eigengenutzt" value={od.anzahl_garagen_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Anz. Garagen Vermietet" name="anzahl_garagen_vermietet" value={od.anzahl_garagen_vermietet} onChange={this.onChange} />
                    <NumberInput label="Anz. Stellplätze Eigengenutzt" name="anzahl_stellplaetze_eigengenutzt" value={od.anzahl_stellplaetze_eigengenutzt} onChange={this.onChange} />
                    <NumberInput label="Anz. Stellplätze Vermietet" name="anzahl_stellplaetze_vermietet" value={od.anzahl_stellplaetze_vermietet} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Garage" append="/ Monat" name="kaltmiete_garage_pro_monat" value={od.kaltmiete_garage_pro_monat} onChange={this.onChange} />
                    <CurrencyInput label="Kaltmiete Stellplatz" append="/ Monat" name="kaltmiete_stellplatz_pro_monat" value={od.kaltmiete_stellplatz_pro_monat} onChange={this.onChange} />
                    <CurrencyInput label="BW-Kosten eigene Wohnfläche" append="/ m²" name="bewirtschaftungskosten_eigene_wohnflaeche_pro_qm" value={od.bewirtschaftungskosten_eigene_wohnflaeche_pro_qm} onChange={this.onChange} />
                    <CurrencyInput label="BW-Kosten vermietete Wohnfläche" append="/ m²" name="bewirtschaftungskosten_vermietete_wohnflaeche_pro_qm" value={od.bewirtschaftungskosten_vermietete_wohnflaeche_pro_qm} onChange={this.onChange} />
                    <CurrencyInput label="BW-Kosten eigene Wohnfläche" append="/ Monat" name="bewirtschaftungskosten_eigene_wohnflaeche" value={od.bewirtschaftungskosten_eigene_wohnflaeche} onChange={this.onChange} />
                    <CurrencyInput label="BW-Kosten vermietete Wohnflaeche" append="/ Monat" name="bewirtschaftungskosten_vermietete_wohnflaeche" value={od.bewirtschaftungskosten_vermietete_wohnflaeche} onChange={this.onChange} />

                </Col>

            </Row>


            </>
        );
    }
}

