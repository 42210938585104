import React, { Component } from 'react'
import { withToastManager } from 'react-toast-notifications';
import { ToastEvents, Types } from './ToastEvents'

class ToastListener extends Component {

    constructor(props) {
        super(props);
        this.toastManager = props.toastManager;
    }

    UNSAFE_componentWillMount() {
        const self = this;
        ToastEvents.getService().subscribe(ev => {
            if (!ev) { return }
            if (ev.type === Types.Message) {
                self.toastManager.add(ev.message, { appearance: 'success', autoDismiss: true });
            }
            else if (ev.type === Types.Error_Message) {
                self.toastManager.add(ev.message, { appearance: 'error', autoDismiss: false });
            }
        });
    }

    componentWillUnmount() {
        try {
            this.BackEndSubscription.unsubscribe();
        } catch (err) { 

        }
    }

    render() {
        return (
            <div></div>
        )
    }
}

const Toast = withToastManager(ToastListener);
export default Toast;
