import * as React from 'react';
import { ISpardaten } from '../types';
import {ILastschrifteinzugdaten} from '../../../types/Lastschrifteinzugdaten'
import { Container, Fade, Row, Col, FormText } from 'reactstrap';
import MoneySlider from '../../common/utils/MoneySlider';
import FormatedDate from '../../common/utils/FormatedDate';
import { toFormatedString } from '../../common/utils';
import TarifService, { ITarifData } from '../../common/Settings/Tarife/TarifService';
import BsvKalkulator, { IBsvRechnerInput } from './BsvRechner';
import SelectInput from '../../common/aif/SelectInput';
import DateInput from '../../common/aif/DateInput';

interface ISparObject {
    vl: number,
    spardauer: number
    montl_sparrate: number
    bausparsumme: number,
    /**
     * ISODate String
     */
    vertragsbeginn: string
    angebot: any
    tarif_nr: number
}

interface ISpardatenProps {
    data: ISpardaten
    onDataChange?(data: any): void
    lastschrifteinzugdaten?: ILastschrifteinzugdaten
}

interface IState {
    spardaten: ISpardaten
    o: ISparObject
    tarife?: ITarifData[]
}
const calcSparDauer = (props: ISpardatenProps): number => {
    let ret = 7
    /*    const bi = props.data.bausparinformationen.find(i => i.kennzeichen_vl === false);
      
        if (bi?.bausparrate_ausfuerungsdatum && bi.bausparrate_endedatum) {
            try {
                const end = new Date(bi.bausparrate_endedatum);
                const start = new Date(bi.bausparrate_ausfuerungsdatum);
                ret = end.getFullYear() - start.getFullYear();
                //console.log('xXx - ', end, start);
            }
            catch (err) {
                console.error(err);
            }
        }
      */
    return ret;
}

const props2O = (props: ISpardatenProps): ISparObject => {

    const ret: ISparObject = {
        spardauer: calcSparDauer(props),
        vl: 0,
        montl_sparrate:100,
        bausparsumme: props.data.bausparsumme || 50000,
        vertragsbeginn: props.data.vertragsbeginn || new Date().toISOString(),
        angebot: {},
        tarif_nr: props.data.tarif_nr || 0
    }
    return ret
}


export default class BsvRechner extends React.Component<ISpardatenProps, IState> {
    constructor(props: ISpardatenProps) {
        super(props);
        this.state = {
            spardaten: props.data,
            o: props2O(props)
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.calculate = this.calculate.bind(this);
        this.notify = this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: ISpardatenProps) {
        this.setState({ o: props2O(newProps) }, () => {
            this.calculate();
        });
    }

    UNSAFE_componentWillMount() {
        const tarifeService = new TarifService();
        tarifeService.getAll().then(ok => {
            if (this.state.o.tarif_nr === 0) {
                let copy = this.state.o;
                let x = ok[0]._idbid || 0;
                copy.tarif_nr = parseInt(x.toLocaleString(), 10);
                this.setState({ tarife: ok, o: copy });
            }
            else {
                this.setState({ tarife: ok }, () => { this.calculate() });
            }
        })
    }

    notify() {
        if (this.props.onDataChange) {
            const spardaten: ISpardaten = {
                bausparsumme: this.state.o.bausparsumme,
                tarif_nr: this.state.o.tarif_nr,
                vertragsbeginn: this.state.o.vertragsbeginn,
            }
            if (this.props.lastschrifteinzugdaten) {
                const lastschrifteinzugdaten = Object.assign({}, this.props.lastschrifteinzugdaten);
                this.props.onDataChange({ spardaten: spardaten, lastschrifteinzugdaten: lastschrifteinzugdaten });
            }
            else{
                this.props.onDataChange({ spardaten: spardaten});
                
            }
        }
    }

    handleSliderChange(fieldname: string, value: number) {
        let copy = Object.assign(this.state.o, { [fieldname]: value });
        this.setState({ o: copy }, this.calculate);
    }

    calculate() {
        if (!this.state.tarife) { return }
        const tarif = this.state.tarife.find(t => { return (t._idbid + '') === (this.state.o.tarif_nr + '') });
        if (!tarif) { return }
        const input: IBsvRechnerInput = {
            bausparsumme: this.state.o.bausparsumme,
            montl_sparrate: this.state.o.montl_sparrate,
            spardauer_monate: this.state.o.spardauer * 12,
            tarif: tarif,
            vertragsbeginn: new Date(this.state.o.vertragsbeginn).toString(),
            vl: this.state.o.vl
        }
        const bsvRechner = new BsvKalkulator(input);
        const result = bsvRechner.calculate();

        if (result) {
            let last = Object.assign({}, result.sparplan[result.sparplan.length - 1]);
            delete last.datum;
            const copy = Object.assign(this.state.o, { angebot: result.zuteilungstermin || last });
            this.setState({ o: copy }, this.notify);
        }
    }

    handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy: any = this.state.o;
        copy[ev.target.name] = ev.target.value;
        this.setState({ o: copy }, () => {
            this.calculate();
        });
    }

    public render() {
        if (!this.state.tarife) { return <></> }
        const tarife = this.state.tarife || []
        const tarife_opt = tarife.map(t => <option key={t._idbid} value={t._idbid}>{t.data.bezeichnung}</option>);
        const color = (this.state.o.angebot.datum) ? 'text-success' : 'text-muted'
        const style = 'text-right border-bottom ' + color;
        const tarif = tarife.find(t => { return t._idbid === this.state.o.tarif_nr; }) || tarife[0];

        return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col md="7" className="border-right pr-4">
                            <div className="text-center">Spardaten</div>
                            <MoneySlider
                                label="Bausparsumme"
                                name="bausparsumme"
                                min={10000}
                                max={300000}
                                step={1000}
                                value={this.state.o.bausparsumme}
                                onChange={this.handleSliderChange}
                            />
                            <MoneySlider
                                label="Monatliche Sparrate"
                                name="montl_sparrate"
                                min={0}
                                max={1000}
                                step={10}
                                value={this.state.o.montl_sparrate}
                                onChange={this.handleSliderChange}
                            />
                            <MoneySlider
                                label="Vermögenwirksame Leistungen"
                                name="vl"
                                min={0}
                                max={40}
                                step={.5}
                                value={this.state.o.vl}
                                onChange={this.handleSliderChange}
                            />
                            <MoneySlider
                                label="Spardauer"
                                name="spardauer"
                                append="Jahre"
                                min={5}
                                max={20}
                                step={1}
                                digits={0}
                                value={this.state.o.spardauer}
                                onChange={this.handleSliderChange}
                            />
                        </Col>
                        <Col className="mb4">
                            <div className="text-center">Angebot</div>
                            <SelectInput label="Tarif" name="tarif_nr" onChange={this.handleChange} value={this.state.o["tarif_nr"]}>
                                {tarife_opt}
                            </SelectInput>

                            <FormText color="muted">
                                Guthabenzins: {tarif.data.guthabenzins_in_prozent}%, Min. Bausparsumme: {toFormatedString(tarif.data.mindest_bausparsumme)}
                            </FormText>

                            <DateInput label="Vertragsbegin" name="vertragsbeginn" value={this.state.o.vertragsbeginn} onChange={this.handleChange}></DateInput>
                            <Row className="mb-4">
                                <Col>Zuteilungstermin</Col>
                                <Col className={style}>
                                    <FormatedDate value={this.state.o.angebot.datum} /></Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>Guthaben</Col>
                                <Col className={style}>{toFormatedString(this.state.o.angebot.guthaben || 0)}</Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>Anspargrad
                                <FormText color="muted">
                                        min.: {tarif.data.mindest_ansparguthaben_in_prozent} %
                                    </FormText>
                                </Col>
                                <Col className={style}>{this.state.o.angebot.anspargrad || 0} %</Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>Bewertungszahl
                                <FormText color="muted">
                                        min.: {tarif.data.mindest_bewertungszahl}
                                    </FormText>
                                </Col>
                                <Col className={style} >{this.state.o.angebot.bewertungszahl}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        );
    }
}
