import * as React from 'react';
import { IKorrektur } from './types';
import { Row, Col } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { TextInput, DateInput, CurrencyInput } from '../../common/aif';
import PercentInput from '../../common/aif/PercentInput';

interface IKorrekturProps {
    data: IKorrektur
    onChange?(data: IKorrektur): void
    onRemove?(data: IKorrektur): void
}

interface IKorrekturState {
    data: IKorrektur
}

export default class Korrektur extends React.Component<IKorrekturProps, IKorrekturState> {
    constructor(props: IKorrekturProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IKorrekturProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.data);
        }
    }

    public render() {
        return (
            <div className="border mb-2 p-1">
                <Row>
                    <Col>
                        <TextInput name="bezeichnung" label="Bezeichnung" value={this.state.data.bezeichnung} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Gültig ab" name="gueltig_ab" value={this.state.data.gueltig_ab} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Gültig bis" name="gueltig_bis" value={this.state.data.gueltig_bis} onChange={this.onChange} />
                    </Col>
                    <Col md="1">
                        <FaTrashAlt style={{ marginTop: '100%' }} className="removeIcon" onClick={this.onRemove} title="Entfernen" />
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <PercentInput label="Zins pro Monat" name="zins" value={this.state.data.zins} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CurrencyInput label="Tilgung pro Monat" name="tilgung" value={this.state.data.tilgung} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CurrencyInput required label="Betrag pro Monat" name="rate" value={this.state.data.rate} onChange={this.onChange} />
                    </Col>
                </Row>
            </div>
        );
    }
}
