import { TarifInt } from '../../../../types/Tarif';

const map = (item: any): TarifInt => {
    let tarif: TarifInt = {
        nummer: item['TARIF_NR'],
        bewertungszahl_zinsfaktor: item['BWZ_FAKTOR_ZSN'],
        bezeichnung: item['TARIF_BEZ_EXTERN'],
        darlehenszins_in_prozent: item['DZINS_PRZ'],
        gueltig_ab: item['BEZUG_DTM_AB'],
        gueltig_bis:item['BEZUG_DTM_BIS'],
        guthabenzins_in_prozent:item['GUTHABENZINS_PRZ'],
        mindest_ansparguthaben_in_prozent:item['MIN_ANS_O_VARW_PRZ'],
        mindest_bausparsumme:item['MIND_BAUSPARSUMME'],
        mindest_bewertungszahl:item['MIND_BWZ'],
        mindestalter:item['MINDESTALTER'],
        mindestsparzeit_in_monaten:item['MIN_S_ZEIT_ANZ_MO'],
        abschlussgebuehr_proz:item['ABSCHLUSS_GEB_PRZ'],
        raw:item
    }
    return tarif;
}

export class TarifMapper {
    static map(backEndTarife: any[]): TarifInt[] {
        let ret: TarifInt[] = []
        for (var i = 0; i < backEndTarife.length; i++) {
            ret.push(map(backEndTarife[i]));
        }
        return ret;
    }
}