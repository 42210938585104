import * as React from 'react';

export type TTrivalentValue = undefined | true | false;

export interface ITrivalent {
    name: string

    value: TTrivalentValue
}

interface ITrivalentInputProps {
    name: string
    value: TTrivalentValue
    label?: string
    onChange?(data: ITrivalent): void
}

interface ITrivalentInputState {
    value: TTrivalentValue
    range: TTrivalentValue[]
}

export default class TrivalentInput extends React.Component<ITrivalentInputProps, ITrivalentInputState> {

    constructor(props: ITrivalentInputProps) {
        super(props);
        this.state = {
            value: props.value,
            range: [undefined, true, false]
        }
        this.onClick = this.onClick.bind(this);
        this.notify = this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps:ITrivalentInputProps){
        this.setState({value:newProps.value});
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange({ name: this.props.name, value: this.state.value });
        }
    }

    onClick() {
        const value = this.state.value;
        let newValue = undefined

        switch (value) {
            case undefined:
                newValue = true
                break;
            case true:
                newValue = false
                break;
            case false:
                newValue = undefined
                break;
        }
        this.setState({ value: newValue }, this.notify);
    }

    public render() {
        const index = this.state.range.indexOf(this.state.value);
        const cssRange = ['ValueNull', 'ValueTrue', 'ValueFalse']
        const css = cssRange[index];

        const labelStyle = "Label " + (css === 'ValueFalse' ? css : '');

        return (
            <div className="TrivalentInput" onClick={this.onClick}>
                <div className={css || 'ValueNull'}></div>
                <div className={labelStyle}>{this.props.label || this.props.name}</div>
            </div>
        );
    }
}
