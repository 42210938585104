import * as React from 'react';
import { DashboardService } from '../../services/DashBoardService';
import { ToastEvents } from '../common/Toast';
import './style.css';
import { IListItem, IHashedItem, IDayFilterItem } from './types';
import DayTraffic from './Charts/DayTraffic';
import ListItem from './ListItem';
import { cazeDB } from '../../stores/dexiestore';
import { ACollapse } from '../common/aif';
import { Col, Row } from 'reactstrap';
import { filterService } from './rxjs_service';
import FilterItem from './FilterItem';
import FilterInput from './FilterInput';

interface IDashboardProps {
    history?: any[]// React Router
}

interface IDashboardState {
    loading: boolean
    list?: IHashedItem[]
    filtered_list?: IHashedItem[]
    q?: string
    redrawCharts: boolean
    from?: Date
    dayFilterList?: IDayFilterItem[]
}

const _list_items_count = 50;

export default class Dashboard extends React.Component<IDashboardProps, IDashboardState> {
    interval: NodeJS.Timeout;
    rxjsService: any;

    constructor(props: IDashboardProps) {
        super(props);
        let d = new Date();
        d.setMonth(d.getMonth() - 1);
        d.setDate(1);

        this.state = {
            loading: false,
            redrawCharts: false,
            list: [],
            from: d
        }
        
        this.loadData = this.loadData.bind(this);
        this.clearQ = this.clearQ.bind(this);
        this.importData = this.importData.bind(this);
        this.filterData = this.filterData.bind(this);
        this.onChartsExpand = this.onChartsExpand.bind(this);
        this.onChartsClose = this.onChartsClose.bind(this);
        this.onChartItemClick = this.onChartItemClick.bind(this);
        this.onFilterItemRemove = this.onFilterItemRemove.bind(this);
        this.interval = setInterval(this.loadData, 30000);
    }

    UNSAFE_componentWillMount() {
        this.loadData();
        this.rxjsService = filterService.getService().subscribe((ev: any) => {
            if (ev) {
                this.setState({ q: ev }, this.filterData);
            }
            else {
                this.setState({ q: undefined, filtered_list: undefined });
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.rxjsService.unsubscribe();
    }

    loadData() {
        this.setState({ loading: true });
        DashboardService.getDashboardData(this.state.from).then(ok => {
            const data: IListItem[] = ok.data;
            let from = undefined; //delta load
            if (data[0] && data[0]._cat) {
                from = data[0]._cat
            }
            const hashedList = data?.map((d, i) => {
                let hashedItem: IHashedItem = Object.assign({}, d);
                hashedItem.hash = d.requestHeader["x-ospe-blz"] || '';
                hashedItem.hash += ' ' + d.request?.vermittlerdaten?.firma || '';
                hashedItem.hash += ' ' + d.response?.data?.antrag_nr || '';
                hashedItem.hash += ' ' + d.request?.vermittlerdaten?.vorname + ' ' + d.request?.vermittlerdaten?.nachname;
                hashedItem.hash += ' ' + d.response?.status;
                const persons = d.request?.person_liste.map((p, i) => { return p.vorname + ' ' + p.nachname }) || []
                hashedItem.hash += ' ' + persons.join(' ');
                return hashedItem;
            });

            let deltalist = undefined;
            if (this.state.from) {
                deltalist = [...hashedList, ...this.state.list || []];
            }

            this.setState({ list: deltalist || hashedList, from: from || this.state.from });
        },
            err => {
                console.error(err);
                ToastEvents.error('Hopla! Kann Daten nicht laden !');
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    async importData(item: IListItem) {
        if (item._id) {
            const ok = await DashboardService.getDashboardEntryById(item._id);
            if (ok.data?.request) {
                const art = ok.data.request.antrag_art;
                if (art === 'Finanzierungsantrag') {
                    const item: any = { fbad: ok.data.request };
                    let saved_caze = await cazeDB.saveCaze(item);
                    this.props.history?.push('/fbad/' + saved_caze._idbid);
                }
                else if (art === 'Bausparantrag') {
                    const item: any = { bsv: ok.data.request };
                    let saved_caze = await cazeDB.saveCaze(item);
                    this.props.history?.push('/bsve/' + saved_caze._idbid);
                } 
                else if (art === 'Lastschrifteinzugantrag') {
                    const item: any = { data: ok.data.request };
                    let saved_caze = await cazeDB.saveCaze(item);
                    this.props.history?.push('/le/' + saved_caze._idbid);
                } else if (art === 'Personendatenantrag') {
                    const item: any = { data: ok.data.request };
                    let saved_caze = await cazeDB.saveCaze(item);
                    this.props.history?.push('/pda/' + saved_caze._idbid);
                }
                else {
                    ToastEvents.error('Hopla! Unbekanter Antragstyp!');
                }
            }
        }
    }

    filterData() {
        const q = this.state.q || ''
        const regex = new RegExp(q, 'gi');
        const filter = new Promise((resolve, reject) => {

            let source = this.state.list;
            if (this.state.dayFilterList) {

                const x = this.state.dayFilterList.map((e, i) => {
                    return source?.filter(f => {
                        var dx = new Date(f._cat);
                        const d_options:any = { year: 'numeric', month: 'short', day: 'numeric' };
                        let dx_label = dx.toLocaleDateString('de-DE', d_options).replace(/\./g, '');
                        //console.log(e.label, dx_label)
                        return (dx_label === e.label &&
                            e.art === f?.request?.antrag_art
                        );
                    })
                });

                if (x && x.length > 0) {
                    for (var i = 0; i < x.length; i++) {
                        if (i === 0) {
                            source = x[i];
                        }
                        else {
                            let l: any = x[i];
                            let nl = source?.concat(l);
                            source = nl;
                        }
                    }
                }
            }

            const filteredList = source?.filter((i: any) => i && i.hash?.match(regex));
            resolve(filteredList)
        });

        filter.then((ok: any) => {
            this.setState({ filtered_list: ok });
        })
    }

    onChartsExpand() {
        this.setState({ redrawCharts: true }, this.onChartsClose);
    }

    onChartsClose() {
        this.setState({ redrawCharts: false });
    }

    onChartItemClick(item: IDayFilterItem) {
        if (item) {
            const x_index = this.state.dayFilterList?.filter(i => (i.art === item.art && i.label === item.label));
            if (x_index === undefined || x_index.length === 0) {
                let list = [...this.state.dayFilterList || []];
                list.push(item);
                this.setState({ dayFilterList: list }, this.filterData)
            }
        }
    }

    onFilterItemRemove(item: IDayFilterItem) {
        const index = this.state.dayFilterList?.indexOf(item);
        if (index !== undefined && index !== -1) {
            let list = [...this.state.dayFilterList || []];
            list.splice(index, 1)
            this.setState({ dayFilterList: list }, this.filterData);
        }
    }

    clearQ() {
        this.setState({ q: '' });
    }

    public render() {

        const liste = (this.state.filtered_list || this.state.list)?.filter((e, i) => i < _list_items_count).map((e, i) => <ListItem key={i} item={e} onClick={this.importData} />);
        const loading = this.state.loading ? <span className='loading'></span> : ''
        const filterItems = this.state.dayFilterList?.map((f, i) => <FilterItem data={f} key={i} onRemove={this.onFilterItemRemove} />)

        return (
            <div className="DachboardContainer">
                {loading}
                <ACollapse label="Charts" expand onExpand={this.onChartsExpand} onClose={this.onChartsClose}>
                    <DayTraffic data={this.state?.list || []} redraw={this.state.redrawCharts} onChartItemClick={this.onChartItemClick} />
                </ACollapse>
                <ACollapse label="Anträge" expand>

                    <Row>
                        <Col>
                            <FilterInput />
                            <div className="FilterItems">{filterItems}</div>
                        </Col>
                    </Row>
                    <Row>

                    </Row>
                    <div className="CaseList">{liste}</div>
                </ACollapse>
            </div>
        );
    }
}
