import {IVermittlerdaten} from "../types/Vermittlerdaten";

const STORE_KEY_NAME = 'iBaufiSettings';

export interface ISettings {
    vermittler?: IVermittlerdaten
    api_url: string
    xhr_timeout: number
    'X-OSPE-BLZ': string
    apikey: string
}

export const initSettings: ISettings = {
    api_url: process.env.REACT_APP_API_HOST+'/fi-net/lbs-fibapi-env/lbs/olp/v6/',
    xhr_timeout: 10000,
    'X-OSPE-BLZ': '94059887',
    apikey: 'w0eyGwl4TMMKMjbPmpJoVHc1WgRmgbBn',
    vermittler: {
        vorname: 'Vorname',
        nachname: 'nachname',
        firma: 'iBaufi AG',
        lbs_user_id : 'LBS User'
    }
}


export default class SettingsService {

    static getSettings(): ISettings {
        var data = localStorage.getItem(STORE_KEY_NAME) || undefined;
        if (data) {
            return JSON.parse(data);
        }
        else {// init settings
            this.saveSetting(initSettings);
            console.info('%c .Baufi Settings initialized', 'color:blue');
            return initSettings;
        }
    }

    static saveSetting(settings: ISettings) {
        var s = JSON.stringify(settings);
        localStorage.setItem(STORE_KEY_NAME, s);
    }

}