import * as React from 'react';
import { IEnum, EnumService } from '../../../services';
import { IVerbund } from './types';
import PersonCard from './PersonCard';
import { PersonEventService } from '../Person/PersonEvents';
import { FaUsers } from 'react-icons/fa';
import { TPerson } from '../Person/types';
import { Row, Col } from 'reactstrap';
import { ACollapse, TextInput } from '../aif';
interface IVerbundProps {
    verbund: IVerbund
    index: number
    person_liste: TPerson[]
    onChange?(verbund: IVerbund, index: number): void
    onSelect?(index: number): void
    selected?: number
}

interface IState {
    enums?: IEnum
    verbund: IVerbund
    dragOver: boolean
}

export default class Verbund extends React.Component<IVerbundProps, IState> {
    subscription: any;

    constructor(props: IVerbundProps) {
        super(props);
        this.state = { dragOver: false, verbund: props.verbund };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.revokePerson = this.revokePerson.bind(this);
        this.onVerbundChange = this.onVerbundChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.verbund, this.props.index);
        }
    }
    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => this.setState({ enums: ok }));

        this.subscription = PersonEventService.getService().subscribe((ev) => {
            if (ev.type === 'person_removed' && ev.index !== undefined) {
                const ix = ev.index;
                this.revokePerson(ix);
                let copy = Object.assign({}, this.props.verbund);
                let newValue: number[] = []
                copy.person_index_liste.forEach(i => {
                    if (i === ix) { }
                    else if (i > ix) {
                        newValue.push(i--);
                    }
                    else { newValue.push(i) }
                })
                this.notify();
            }
        });
    }

    componentWillUnmount() {
        this.subscription.unsubscribe();
    }

    onDragOver(ev: any) {
        ev.preventDefault();
        this.setState({ dragOver: true });
    }

    onDragEnd(ev: any) {
        this.setState({ dragOver: false });
    }

    onDrop(ev: any) {
        ev.preventDefault();
        var data: number = Number.parseInt(ev.dataTransfer.getData("text"));

        if (this.props.onChange) {
            let copy = Object.assign({}, this.props.verbund);
            if (copy.person_index_liste.indexOf(data) === -1) {
                copy.person_index_liste.push(data);
                this.props.onChange(copy, this.props.index);
            }
        }

        this.setState({ dragOver: false });
    }

    revokePerson(p_index: number) {
        const index = this.props.verbund.person_index_liste.indexOf(p_index);
        if (index !== -1 && this.props.onChange) {
            let copy = Object.assign({}, this.props.verbund);
            copy.person_index_liste.splice(index, 1);
            this.notify();
        }
    }

    onVerbundChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.verbund, { [ev.target.name]: ev.target.value });
        this.setState({ verbund: copy }, this.notify);
    }


    public render() {

        if (!this.state.enums) return <></>;

        const lindex = Object.keys(this.state.enums?.verbund_art).indexOf(this.props.verbund.verbund_art);
        const label = Object.values(this.state.enums?.verbund_art)[lindex];


        let css = "Verbund"
        if (this.state.dragOver) {
            css += ' dragOver'
        }
        const personen = this.props.verbund.person_index_liste.map((p, i) => {
            const person = this.props.person_liste[p];
            return p !== undefined ? <PersonCard
                key={i}
                person={person}
                index={p}
                onSelect={this.props.onSelect}
                selected={this.props.selected}
                onRevokePerson={this.revokePerson}
            /> : '';
        });


        return (<div className={css}
            onDragOver={this.onDragOver}
            onDragEnd={this.onDragEnd}
            onDragLeave={this.onDragEnd}
            onDrop={this.onDrop}
            onDropCapture={this.onDrop}
        >
            <Row>
                <Col md="4">
                    <span className="VerbundIcon"><FaUsers color="secondary" /> </span>{label || this.props.verbund.verbund_art}
                </Col>
            </Row>
            <ACollapse>
                <Row>
                    <Col md="2">
                        <TextInput label="Verbund ID" value={this.state.verbund.verbund_id} name="verbund_id" onChange={this.onVerbundChange} />
                    </Col>
                    <Col>
                        <TextInput label="Bezeichnung 1" value={this.state.verbund.bezeichnung1} name="bezeichnung1" onChange={this.onVerbundChange} />
                    </Col>
                    <Col>
                        <TextInput label="Bezeichnung 2" value={this.state.verbund.bezeichnung2} name="bezeichnung2" onChange={this.onVerbundChange} />
                    </Col>
                </Row>
            </ACollapse>
            <div className="VerbundPersonen">{personen}</div>
        </div>)
    }
}
