import * as React from 'react';
import { ISonderkondition } from '.';
import { Row, Col } from 'reactstrap';
import { PercentInput, DateInput, TextInput } from '../../common/aif';
import BSTarifSelect from '../../common/BSTarivSelect';

interface ISonderkonditionProps {
    data?: ISonderkondition
    onChange?(data: ISonderkondition): void
}

interface ISonderkonditionState {
    data: ISonderkondition
}

export default class Sonderkondition extends React.Component<ISonderkonditionProps, ISonderkonditionState> {
    constructor(props: ISonderkonditionProps) {
        super(props);
        this.state = {
            data: this.props.data || {}
        }

        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }
    
    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <div>
                <Row>
                    <Col><PercentInput required label="Zinsabweichung" name="zins_abweichung" value={this.state.data.zins_abweichung} onChange={this.onChange} /></Col>
                    <Col><TextInput label="Abgestimmt mit" name="abgestimmt_mit" value={this.state.data.abgestimmt_mit} onChange={this.onChange} /></Col>
                    <Col><DateInput label="Abgestimmt am" name="abgestimmt_am" value={this.state.data.abgestimmt_am} onChange={this.onChange} /></Col>
                </Row>
            </div>
        );
    }
}
