import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { AButton } from '../common/aif';
import { ReactComponent as Logo } from './login.svg'
import './style.css';

export const Welcome = () => {
    const { keycloak } = useKeycloak();
    
    return (<div className="Welcome">

        <h1>Willkomen bei iBaufi</h1>
        <div className="Logo"  onClick={() => { keycloak.login() }} ><Logo style={{height:'400px'}} /></div>

        <h3>Bitte melden Sie sich an!</h3>
        <AButton onClick={() => { keycloak.login() }} color="info" >Anmelden</AButton>

    </div >);
}
