import React, { Component } from "react";
import TarifService, { TarifeInfo, ITarifData } from "./TarifService";
import { Row, Col, Container, FormText, Button, Fade } from "reactstrap";
import { FaSyncAlt } from "react-icons/fa";
import { TarifInt } from "../../../../types";
import { toFormatedString } from "../../utils/util";
import FormatedDate from "../../utils/FormatedDate";
import { ToastEvents } from "../../Toast";

interface Props {}

interface State {
  tarife?: Array<ITarifData>;
  info?: TarifeInfo;
  isLoading: boolean;
}

class Tarife extends Component<Props, State> {
  tarifService: TarifService;
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = { isLoading: false };
    this.tarifService = new TarifService();
    this.getAll();
  }

  async syncTarife() {
    this.setState({ isLoading: true });
    try {
     await TarifService.loadTarife();
     this.getAll();
      
     ToastEvents.notify(" Tarife aktualisiert!");
    } catch (err) {
      ToastEvents.error(
        "Fehler beim Aktualisieren der Tariffe! " + err.message
      );
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async getAll() {
    const ret = await Promise.all([
      this.tarifService.getAll(),
      this.tarifService.getInfo()
    ]);
    this.setState({ tarife: ret[0], info: ret[1] });
  }

  render() {
    if (!this.state.tarife || !this.state.info) {
      return <></>;
    }

    const syncBtn = this.state.isLoading ? (
      <Button color="info">
        <FaSyncAlt className="runsync" />
      </Button>
    ) : (
      <Button
        color="info"
        onClick={() => {
          this.syncTarife();
        }}
      >
        Aktualisieren
      </Button>
    );

    const list = this.state.tarife.map((d: any) => {
      const data: TarifInt = d.data;
      return (
        <Row key={data.nummer} className="border-bottom">
          <Col md={1}>{data.nummer}</Col>
          <Col md={4}>{data.bezeichnung}</Col>
          <Col md={2}>{data.gueltig_ab}</Col>
          <Col md={2}>{data.gueltig_bis}</Col>
          <Col md={1} className="text-right">
            {data.mindestalter}
          </Col>
          <Col md={2} className="text-right">
            {toFormatedString(data.mindest_bausparsumme)}
          </Col>
        </Row>
      );
    });

    return (
      <Container className="mt-4">
        <Fade>
          <Row>
            <Col>
              <b>{this.state.info.count} BSV Tarife</b>
              <FormText className="">
                {" "}
                letzte Aktualisierung:{" "}
                <FormatedDate value={this.state.info.last_sync} />{" "}
              </FormText>
            </Col>
            <Col className="text-right">{syncBtn}</Col>
          </Row>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row key={-1} className="font-weight-bold">
            <Col md={1}>Nummer</Col>
            <Col md={4}>Bezeichnung</Col>
            <Col md={2}>Gültig ab</Col>
            <Col md={2}>Gültig bis</Col>
            <Col md={1}>Min. Alter</Col>
            <Col md={2}>Min BSp. Summe</Col>
          </Row>
          {list}
        </Fade>
      </Container>
    );
  }
}

export default Tarife;
