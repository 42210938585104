import Dexie from "dexie";
import { ITarifData } from '../components/common/Settings/Tarife/TarifService';
import { IEnum } from '../services/EnumService';

export interface ICazeStoreItem {
  _idbid?: string;
  value: any;
}

const _ENUM_LIST_ID = "enumlist";

const generateId = () => {
  return (
    Math.random()
      .toString(36)
      .substring(2) + new Date()
        .getTime()
        .toString(36)
  );
};

class CaseDatabase extends Dexie {
  cases: Dexie.Table<ICazeStoreItem, string>;
  tarife: Dexie.Table<ITarifData, number>;
  enums: Dexie.Table<IEnum, number>;

  constructor() {
    super("iBaufi");
    this.version(1).stores({
      cases: "++_idbid,value",
      tarife: "++_idbid,value",
      enums: "++_idbid,value"
    });

    this.cases = this.table("cases");
    this.tarife = this.table("tarife");
    this.enums = this.table("enums");
    this.open();
  }

  async saveCaze(caze: ICazeStoreItem) {
    if (!caze._idbid) {
      caze._idbid = generateId();
      await this.cases.add(caze);
    } else {
      await this.cases.put(caze);
    }
    return caze;
  }

  saveTarif(tarif: ITarifData) {
    return this.tarife.add(tarif);
  }

  async saveEnums(enums: IEnum): Promise<any> {
    enums._idbid = _ENUM_LIST_ID;
    await this.enums.count((cnt: number) => {
      if (cnt === 0) {
        return this.enums.add(enums);
      }
      else {
        return this.enums.put(enums);
      }
    });
  }

  getCazeByKey(key: string) {
    return this.cases.get(key);
  }

  deleteCazeById(_idbid: string) {
    return this.cases.delete(_idbid);
  }
  
  async getAllCazes() {
    let ret = await this.cases.toArray();
    return ret;
  }

  async getAllTarife() {
    let ret = await this.tarife.toArray();
    return ret;
  }

  getEnums() {
    return this.enums.get({ _idbid: _ENUM_LIST_ID });
  }
}

const cazeDB = new CaseDatabase();
export { cazeDB };
