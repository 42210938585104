import * as React from 'react';

interface IAtabNavProps {
}

export default class ATabNav extends React.Component<IAtabNavProps> {
    public render() {
        return (
            <div className="AtabNav">
                {this.props.children}
            </div>
        );
    }
}
