import { BehaviorSubject } from 'rxjs';
import {debounceTime } from 'rxjs/operators';

const subject = new BehaviorSubject();
const spardatenSubject = new BehaviorSubject();

export const actions = {
    SAVE_PERSON: 'save_person',
    SAVE_DATA:'save_data'
}

export const bsveService = {
    addObject: (object) => subject.next(object),
    runAction:(action)=>{subject.next({action:action})},
    getService: () => subject.pipe(debounceTime(100)).asObservable(),
};


export const spardatenService = {
    addSpardatenEntity: (o) => spardatenSubject.next({ o }),
    getService: () => spardatenSubject.pipe(debounceTime(20)).asObservable(),
    getValue: () => spardatenSubject.getValue()
}
