import * as React from 'react';
import { IDayFilterItem } from './types';

interface IFilterItemProps {
    data: IDayFilterItem
    onRemove?(i: IDayFilterItem): void
}

interface IFilterItemState {
}

export default class FilterItem extends React.Component<IFilterItemProps, IFilterItemState> {

    constructor(props: IFilterItemProps) {
        super(props);

        this.state = {
        }
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.data);
        }
    }

    public render() {
        const className = this.props.data.art + " FilterItem"

        return (
            <div className={className} title={this.props.data.art}>
                {this.props.data.label}
                <div className="RemoveBtn" onClick={this.onRemove}> </div>
            </div>
        );
    }

}
