import * as React from 'react';

export interface IASelectListItemProps {
    id: string | number
    label: string
    selected: boolean
    onChange?(id: string | number, selected: boolean):void
}

interface IASelectListItemState {
    selected: boolean
}

export default class ASelectListItem extends React.Component<IASelectListItemProps, IASelectListItemState> {

    constructor(props: IASelectListItemProps) {
        super(props);
        this.state = {
            selected: props.selected
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ selected: !this.state.selected }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.id, this.state.selected);
            }
        });
    }

    public render() {
        const selected = this.state.selected ? 'selected' : '';
        return (
            <div className="ASelectListItem" onClick={this.toggle}>
                <div className={'Icon ' + selected}></div>
                <div className={'Label ' + selected}>{this.props.label} {}</div>
            </div>
        );
    }
}


/**
 * return (
           
        );
 */