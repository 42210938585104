import React, { Component } from 'react';
import { Form, FormGroup, Label, Container, CardFooter, Input, InputGroupAddon, InputGroup, CardHeader, CardBody, Col, Row, Card,Button } from 'reactstrap';
import { FaImage } from 'react-icons/fa';
import ImageCarousel from './ImageCarousel';


class Immo extends Component {

    constructor(props) {
        super(props);
        this.state = { images: [], data: '', imageinput: '' };
        this.handleImageUrlChange = this.handleImageUrlChange.bind(this);
        this.addImage = this.addImage.bind(this);
        this.handleChange=this.handleChange.bind(this);
    }

    handleImageUrlChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value });
    }

    addImage() {
        let url = this.state.imageinput;
        if (url.length < 10) { return; }
        let state = Object.assign({}, this.state);
        state.images.push(url);
        this.setState({ images: state.images, imageinput: 'x' }, () => {
            console.log(this.state);
        });
    }
    handleChange(ev){
        this.setState({data:{[ev.target.name]: ev.target.value}});
    }

    render() {
        return (
            <Container className="mt-4">
                <Row>
                    <Col md={6}>
                        <Card>
                            <CardHeader>Daten</CardHeader>
                            <CardBody>
                                <Form>

                                    <FormGroup>
                                        <Label>Titel</Label>
                                        <Input type="text" onChange={this.handleChange} value={this.state.data.titel} name="titel" placeholder="Apartment, studio, or floor" />
                                    </FormGroup>
                                    <Row form>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="exampleState">Strasse</Label>
                                                <Input onChange={this.handleChange} value={this.state.data.street} name="street" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="exampleZip">Zip</Label>
                                                <Input type="text" name="zip" onChange={this.handleChange} value={this.state.data.zip}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleCity">Stadt</Label>
                                                <Input type="text" name="city" onChange={this.handleChange} value={this.state.data.city}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>

                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Wohnfläche</Label>
                                                <Input type="number" name="wfl" onChange={this.handleChange} value={this.state.data.wfl} placeholder="m²" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Grundstück</Label>
                                                <Input type="number" name="grundstk" onChange={this.handleChange} value={this.state.data.grundstk} placeholder="m²" />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label>Etagenzahl</Label>
                                                <Input type="number" name="etagen" onChange={this.handleChange} value={this.state.data.etagen}/>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Label>Freitext</Label>
                                        <Input type="textarea" name="freitext" onChange={this.handleChange} value={this.state.data.freitext} placeholder=".. wie schön ist das Objekt" />
                                    </FormGroup>

                                </Form>
                            </CardBody>
                            <CardFooter>
                                <FormGroup>
                                    <Label>Kaufbetrag</Label>
                                    <Input type="number" name="kaufbetrag" onChange={this.handleChange} value={this.state.data.kaufbetrag} placeholder="" />
                                </FormGroup>
                            </CardFooter>
                        </Card>




                    </Col>
                    <Col md={6}>
                        <Card>
                            <CardHeader>Fotos</CardHeader>
                            <CardBody>

                                <ImageCarousel images={this.state.images}></ImageCarousel>

                            </CardBody>
                            <CardFooter>
                                <InputGroup>
                                    <Input type="url" name="imageinput" onChange={this.handleImageUrlChange} placeholder="https://..URL zum Image"></Input>
                                    <InputGroupAddon addonType="append" >
                                        <Button color="info" onClick={this.addImage} >
                                            <FaImage />
                                        </Button>
                                    </InputGroupAddon >
                                </InputGroup>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
                <Row>

                    <Col md={12}>
                        <div class="d-flex justify-content-end">
                            <Button color="info">Speichern</Button>
                        </div>

                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Immo;