import * as React from 'react';
import { Fade, Container, Card, CardDeck, CardBody, CardTitle, CardText, Button, ButtonGroup } from 'reactstrap';

import { ReactComponent as ComingHome } from './img/undraw_coming_home_52ir.svg'
import { ReactComponent as Saving } from './img/undraw_Savings_re_eq4w.svg'
import { ReactComponent as Persondata } from './img/undraw_Updated_resume_re_q1or.svg'
import { ReactComponent as TransferFiles } from './img/undraw_Business_decisions_re_84ag.svg'

export interface IBeratungProps {
    history?: any
}

export default class Beratung extends React.Component<IBeratungProps> {

    constructor(props: IBeratungProps) {
        super(props)
        this.goTo = this.goTo.bind(this);
    }

    goTo(ev: any) {
        if (this.props.history) {
            this.props.history.push(ev.target.value);
        }
    }

    public render() {
        return (
            <Container className="Beratung">
                <Fade>
                    <CardDeck >
                        <Card  lg="4" sm="6">
                            <CardTitle className="text-center mt-2"><h4 className="text-info"> Sparen</h4></CardTitle>
                            <Saving className="card-img-top mt-4" style={{ height: '300px' }} />
                            <CardBody>
                                <CardText style={{ minHeight: '100px' }}>
                                    Bauen Sie sich und Ihrer Familie eine Zukunft auf - mit LBS-Bausparen ins eigene Zuhause. Eine Sicherheit, die bleibt.
                                    </CardText>
                            </CardBody>
                            <ButtonGroup style={{ width: '100%', marginTop: '-1em' }}>
                                <Button size="lg" color="info" value="bsve" onClick={this.goTo}>LBS-Bausparen</Button>
                            </ButtonGroup>
                        </Card>
                        <Card lg="4" sm="6">
                            <CardTitle className="text-center mt-2 border-bootm"><h4 className="text-info">Finanzieren</h4></CardTitle>
                            <ComingHome className="card-img-top mt-4" style={{ height: '300px' }} />
                            <CardBody>
                                <CardText style={{ minHeight: '100px' }}>Profitieren Sie von unseren attraktiven Konditionen für Bauspardarlehen und für andere tolle Kredite.</CardText>
                            </CardBody>
                            <ButtonGroup style={{ width: '100%', marginTop: '-1em' }}>
                                <Button size="lg" color="info" value="/fbad" onClick={this.goTo}>Finanzieren Plus</Button>
                            </ButtonGroup>
                        </Card>
                        <Card  lg="4" md="6">
                            <CardTitle className="text-center mt-2 border-bootm"><h4 className="text-info">	Lastschrifteinzug</h4></CardTitle>
                            <TransferFiles className="card-img-top mt-4" style={{ height: '300px' }} />
                            <CardBody>
                                <CardText style={{ minHeight: '100px' }}>Machen Sie es sich einfacher, erfassen Sie noch heute einen Lastschrifteinzug</CardText>
                            </CardBody>
                            <ButtonGroup style={{ width: '100%', marginTop: '-1em' }}>
                                <Button size="lg" color="info" value="/le" onClick={this.goTo}>Antrag</Button>
                            </ButtonGroup>
                        </Card>   
                        <Card  lg="4" md="6">
                            <CardTitle className="text-center mt-2 border-bootm"><h4 className="text-info">	Personendaten</h4></CardTitle>
                            <Persondata className="card-img-top mt-4" style={{ height: '300px' }} />
                            <CardBody>
                                <CardText style={{ minHeight: '100px' }}>Ändern Sie hier ihre Personenstammdaten</CardText>
                            </CardBody>
                            <ButtonGroup style={{ width: '100%', marginTop: '-1em' }}>
                                <Button size="lg" color="info" value="/pda" onClick={this.goTo}>Antrag</Button>
                              
                            </ButtonGroup>
                        </Card>
                    </CardDeck>

                </Fade>
            </Container>
        );
    }
}
