import React, { Component } from 'react'
import { cazeDB, ICazeStoreItem } from '../../stores/dexiestore';
import BottomNav from './BottomNav';
import Comments from '../common/Comments/Comments';
import Summary from './Summary';
import { IBausparantrag } from './types';
import Lastschrifteinzug from './Lastschrifteinzug'
import SettingsService from '../../services/SettingsService';
import BsvRechner from './BSVRechner';
import TabSpardaten from './TabSpardaten';
import Beguenstigter from './Beguenstigter';
import { IPrivatperson } from '../common/Person/types';
import Beratungsprotokoll from './Beratungsprotokoll';
import Personen from './Personen';
import {syncRequest} from '../../services/SyncService';
import DokumentenListe from '../common/Dokumente';
import { IBezugsobjekt } from '../common/aif/BezugsObjektInput'
import { getPersonLabel } from '../common/Person/PersonUtils'

interface IBsvProps {
    location: any // from React Router
    match: any // from React Router
}

interface IState {
    bsv: IBausparantrag
    _render?: boolean
    _sync: boolean
    _idbid?: string
}

const _person: IPrivatperson = {

    "person_art": 'Privat',
    "rolle": "Antragssteller"
}


const _initBsv: IBausparantrag = {
    antrag_art: 'Bausparantrag',
    verbund_liste: [],
    person_liste: [_person],
    spardaten: {
        bausparsumme: 0,
        tarif_nr: 0,
        vertragsbeginn: new Date().toISOString(),
    },
    antrag_informationen: {},
    nachrichten_verlauf: [],
}

export default class Bsv extends Component<IBsvProps, IState> {
    subscription: any;
    constructor(props: IBsvProps) {
        super(props);
        let d = new Date();
        d.setDate(1);
        d.setMonth(d.getMonth() + 1);
        this.state = {
            bsv: Object.assign({}, _initBsv),
            _sync: false
        };
        this.onDataChange = this.onDataChange.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    shouldComponentUpdate(nextProps: IBsvProps, nextState: IState) {
        if (nextState._render === false) {
            delete nextState._render;
            return false;
        }
        return true;
    }

    saveData() {
        let p = Object.assign({}, this.state.bsv.person_liste[0]);
        if (!p.nachname) { return; }
        let dataPre = Object.assign(this.state, {timestamp: Date.now()});
        const data: ICazeStoreItem = Object.assign(dataPre);
        cazeDB.saveCaze(data);
        syncRequest(data._idbid);
    }

    onDataChange(obj: any) {
        const settings = SettingsService.getSettings();
        obj.vermittlerdaten = {
            firma: settings.vermittler?.firma,
            nachname: settings.vermittler?.nachname,
            vorname: settings.vermittler?.vorname,
            lbs_user_id: settings.vermittler?.lbs_user_id,
            email: settings.vermittler?.email,
            telefon: settings.vermittler?.telefon,
        }

        const copy = Object.assign(this.state.bsv, obj);
        this.setState({ bsv: copy, _render: false, _sync: false }, () => {
            this.saveData();
        });
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params._id) {
            cazeDB.getCazeByKey(this.props.match.params._id).then((ok: any) => {
                const xstate = ok as IState;
                this.setState(xstate, () => { });
            })
        }
    }

    render() {
        const personListe = [];
        for (var index = 0; index < this.state.bsv.person_liste.length; index++){
            const currentItem = this.state.bsv.person_liste[index];
            let bezugsobjekt : IBezugsobjekt ={
                label : getPersonLabel(currentItem),
                index : index
            } 
            personListe[index] = bezugsobjekt;
        }
            
        let conf =
        {
            '#BsvRechner': <BsvRechner data={this.state.bsv.spardaten} onDataChange={this.onDataChange} lastschrifteinzugdaten={this.state.bsv.lastschrifteinzugdaten} />,
            '#Spardaten': <TabSpardaten data={this.state.bsv} onChange={this.onDataChange} />,
            //'#Person': <PersonListe verbund_liste={this.state.bsv.verbund_liste || []} person_liste={this.state.bsv.person_liste} onChange={this.onDataChange} />,
            '#Person': <Personen bsv={this.state.bsv} onDataChange={this.onDataChange} />,
            '#Beguenstigung': <Beguenstigter data={this.state.bsv.beguenstigung_daten} onChange={this.onDataChange} />,
           
            '#Lastschrift': <Lastschrifteinzug data={this.state.bsv.lastschrifteinzugdaten} onChange={this.onDataChange} />,
            '#Dokumente':
                <DokumentenListe
                    antragId={this.state.bsv.antrag_id}
                    data={this.state.bsv.dokument_liste||[]}
                    personListe={personListe}
                    onDataChange={this.onDataChange} />,
           
            '#Protokoll': <Beratungsprotokoll data={this.state.bsv.beratungsprotokoll} provision={this.state.bsv.bsv_provision} onChange={this.onDataChange} />,
            '#Comments': <Comments backendId={this.state.bsv.antrag_id} messages={this.state.bsv?.nachrichten_verlauf}></Comments>,
            '#Summary': <Summary data={this.state.bsv} _idbid={this.state._idbid} onDataChange={this.onDataChange} />,
        }

        const state = this.props.location.hash || Object.keys(conf)[0];
        const i = Object.keys(conf).indexOf(state);
        const view = Object.values(conf)[i]

        return (
            <div>
                <BottomNav active={state} data={this.state.bsv}></BottomNav>
                <div style={{ maxHeight: '90vh', overflow: 'auto' }}>{view}</div>
            </div>
        )
    }
}
