import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap';
import { IDokument, IDokumentMetadaten } from '../../../types/Antrag';
import { EnumInput, TextInput, BezugsObjektInput } from '../aif';
import BackendService from '../../../services/BackendService';
import { ToastEvents } from '../Toast';
import { FaSyncAlt } from 'react-icons/fa';
import { IBezugsobjekt } from '../../common/aif/BezugsObjektInput'
import SettingsService from '../../../services/SettingsService';

interface IProps {
    antragId?: string
    personListe?: IBezugsobjekt[]
    finanzierungsmittelListe?: IBezugsobjekt[]
    immobilieListe?: IBezugsobjekt[]
    sonstigerFinanzierungsgegenstandListe?: IBezugsobjekt[]
    sicherheitListe?: IBezugsobjekt[]
    onUploadSucceed?(doc: IDokument): void
}

interface IState {
    file?: any
    data: IDokumentMetadaten
    isLoading: boolean
}


export default class Upload extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: {},
            isLoading: false
        }
        this.onFileSelect = this.onFileSelect.bind(this);
        this.onChange = this.onChange.bind(this);
        this.doUpload = this.doUpload.bind(this);
    }

    doUpload(ev: any) {
        this.setState({ isLoading: true });
        const metadata = Object.assign({}, this.state.data);
        if (metadata.bezugsobjekt === 'Vorgang'){
            metadata.bezugsobjekt_index = undefined;
        }
        const blob = new Blob([JSON.stringify(metadata)], { type: 'application/json' })
        const data = new FormData()
        data.append('contents', this.state.file);
        data.append('metadaten', blob);

        const { api_url } = SettingsService.getSettings();
        if (api_url.startsWith("https://b2b")) {
            data.append('body', JSON.stringify(metadata));
        }
        BackendService.uploadDokument(data, this.props.antragId || ' ').then(ok => {
            console.log(ok)
            ToastEvents.notify("Dokument Upload erfolgreich!")
            if (this.props.onUploadSucceed) {
                this.props.onUploadSucceed(ok.data as any);
            }
            this.setState({ file: undefined });
        }).catch(err => {
            console.error(err);
            const msgs = err.response.data.messages;
            msgs.forEach((m: any) => {
                ToastEvents.error(m);
            });
        }).finally(
            () => this.setState({ isLoading: false })
        )
    };

    onFileSelect(ev: any) {
        ev.preventDefault();
        let copy = Object.assign(this.state.data, { name: ev.target?.files[0]?.name || null });
        this.setState({ data: copy, file: ev.target?.files[0] });
    }

    onChange(ev: React.ChangeEvent<any>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy });
    }

    render() {
        if (this.props.antragId) {

            let key = 'Vorgang';
            if (this.state.data.bezugsobjekt !== undefined) {
                key = this.state.data.bezugsobjekt;
            }

            return <div className="mt-5">
                <fieldset >
                    <label htmlFor="Upload File"></label>
                    <input name="file" type="file" onChange={(ev) => this.onFileSelect(ev)} />
                    {this.state.file && <div>
                        <Row>
                            <Col><TextInput name="name" value={this.state.data.name} label="Dateiname" onChange={this.onChange} /></Col>
                            <Col><EnumInput name="typ" value={this.state.data.typ} enum="dokument_typ" onChange={this.onChange} /></Col>
                            <Col><EnumInput name="bezugsobjekt" value={this.state.data.bezugsobjekt} enum="dokument_bezugsobjekt" onChange={this.onChange} /></Col>
                            <Col>
                                <BezugsObjektInput
                                    name="bezugsobjekt_index"
                                    value={this.state.data.bezugsobjekt_index}
                                    label="Typ"
                                    bezugsobjektArt={this.state.data.bezugsobjekt}
                                    key={key}
                                    personListe={this.props.personListe}
                                    finanzierungsmittelListe={this.props.finanzierungsmittelListe}
                                    immobilieListe={this.props.immobilieListe}
                                    sonstigerFinanzierungsgegenstandListe={this.props.sonstigerFinanzierungsgegenstandListe}
                                    sicherheitListe={this.props.sicherheitListe}
                                    onChange={this.onChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-right">
                                {this.state.isLoading ? <span color="primary"><FaSyncAlt className="runsync" /></span> : <Button color="primary" size="sm" onClick={this.doUpload}>Upload</Button>}
                            </Col>
                        </Row></div>}
                </fieldset>
            </div>;
        }

        else {
            return <div>
                <fieldset>
                    Nach dem Sie den Vorgang abgeschickt haben, können Sie hier die Begleitdokumentation hochladen.
                </fieldset>
            </div>
        }
    }
}