import * as React from 'react';
import { IFremddarlehen, ISondertilgung, IRatenaenderung, IZinssatzaenderung } from './types';
import { IVerbund } from '../../common/Verbund/types';
import { IEnum, EnumService } from '../../../services';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import { Row, Col } from 'reactstrap';
import {FaTrashAlt, FaPlus, FaCopy} from 'react-icons/fa';
import EnumInput from '../../common/aif/EnumInput';
import { TPerson } from '../../common/Person/types';
import Sondertilgung from './Sondertilgung';
import Ratenaenderung from './Ratenaenderung';
import Zinssatzaenderung from './Zinssatzaenderung';
import { CurrencyInput, DateInput, NumberInput, ACollapse, TextInput, AButton } from '../../common/aif';
import PercentInput from '../../common/aif/PercentInput';

export interface IFremddarlehenProps {
  data: IFremddarlehen
  person_liste: TPerson[]
  verbund_liste: IVerbund[]
  index: number
  onChange?(index: number, data: IFremddarlehen): void
  onRemove?(index: number): void
  onCopy?(data: any): void

}

interface IFremddarlehenState {
  enums?: IEnum
  data: IFremddarlehen
}

export default class Fremddarlehen extends React.Component<IFremddarlehenProps, IFremddarlehenState> {
  constructor(props: IFremddarlehenProps) {
    super(props);

    this.state = {
      data: props.data
    }

    this.notify = this.notify.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onPVSelect = this.onPVSelect.bind(this);
    this.remove = this.remove.bind(this);
    this.copy = this.copy.bind(this);
    this.addSondertilgung = this.addSondertilgung.bind(this);
    this.removeSondertilgung = this.removeSondertilgung.bind(this);
    this.addRatenaenderung = this.addRatenaenderung.bind(this);
    this.removeRatenaenderung = this.removeRatenaenderung.bind(this);
    this.addZinssatzaenderung = this.addZinssatzaenderung.bind(this);
    this.removeZinssatzaenderung = this.removeZinssatzaenderung.bind(this);
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then(ok => {
      this.setState({ enums: ok });
    })
  }

  notify() {
    if (this.props.onChange) {
      this.props.onChange(this.props.index, this.state.data);
    }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const newVal = { [ev.target.name]: ev.target.value }
    const newState: IFremddarlehen = Object.assign(this.state.data, newVal);
    this.setState({ data: newState }, this.notify)
  }

  remove() {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.index);
    }
  }

  copy() {
    if (this.props.onCopy) {
      this.props.onCopy(this.props.data);
    }
  }

  onPVSelect(obj: IPVSelectReturn) {
    let copy = Object.assign({}, this.state.data);
    if (obj.type === "verbund_index") {
      copy.antragssteller_verbund_index = obj.value;
      copy.antragssteller_person_index = undefined;
    }
    else {
      copy.antragssteller_verbund_index = undefined;
      copy.antragssteller_person_index = obj.value;
    }
    this.setState({ data: copy }, this.notify);
  }

  addSondertilgung() {
    let liste: ISondertilgung[] = [...this.state.data.sondertilgung_liste || []];
    liste.push({ betrag: 0 })
    const copy = Object.assign(this.state.data, { sondertilgung_liste: liste });
    this.setState({ data: copy });
  }

  removeSondertilgung(data: ISondertilgung) {
    let liste = [...this.state.data.sondertilgung_liste || []]
    let index = liste?.indexOf(data);
    if (index !== -1) {
      liste.splice(index, 1);
      const copy = Object.assign(this.state.data, { sondertilgung_liste: liste });
      this.setState({ data: copy }, this.notify);
    }
  }

  addRatenaenderung() {
    let liste: IRatenaenderung[] = [...this.state.data.ratenaenderung_liste || []];
    liste.push({})
    const copy = Object.assign(this.state.data, { ratenaenderung_liste: liste });
    this.setState({ data: copy });
  }

  removeRatenaenderung(data: IRatenaenderung) {
    let liste = [...this.state.data.ratenaenderung_liste || []]
    let index = liste?.indexOf(data);
    console.log(index);
    if (index !== -1) {
      liste.splice(index, 1);
      const copy = Object.assign(this.state.data, { ratenaenderung_liste: liste });
      this.setState({ data: copy }, this.notify);
    }
  }

  addZinssatzaenderung() {
    let liste: IZinssatzaenderung[] = [...this.state.data.zinssatzaenderung_liste || []];
    liste.push({})
    const copy = Object.assign(this.state.data, { zinssatzaenderung_liste: liste });
    this.setState({ data: copy });
  }

  removeZinssatzaenderung(data: IZinssatzaenderung) {
    let liste: IZinssatzaenderung[] = [...this.state.data.zinssatzaenderung_liste || []]
    let index = liste?.indexOf(data);
    console.log(index);
    if (index !== -1) {
      liste.splice(index, 1);
      const copy = Object.assign(this.state.data, { zinssatzaenderung_liste: liste });
      this.setState({ data: copy }, this.notify);
    }
  }

  public render() {

    const sondertilgung = this.state.data.sondertilgung_liste?.map((t, i) => {
      return <Sondertilgung key={i} data={t} onChange={this.notify} onRemove={this.removeSondertilgung} />
    });

    const ratenaenderung = this.state.data.ratenaenderung_liste?.map((t, i) => {
      return <Ratenaenderung key={i} data={t} onChange={this.notify} onRemove={this.removeRatenaenderung} />
    });

    const zinssatzaenderung = this.state.data.zinssatzaenderung_liste?.map((t, i) => {
      return <Zinssatzaenderung key={i} data={t} onChange={this.notify} onRemove={this.removeZinssatzaenderung} />
    });

    return (
      <div>
        <Row>
          <Col>
            <div className="h5 text-secondary">Fremddarlehen </div>
          </Col>
          <Col className="text-right">
            <FaTrashAlt onClick={this.remove} className="removeIcon" title="Fremddarlehen entfernen" />
            <FaCopy onClick={this.copy} className="copyIcon" title="LbsAnnudarlehen kopieren" />
            <span style={{marginRight: "10px"}}></span>
          </Col>
        </Row>
        <Row>
          <Col>
            <PVSelect person_index={this.state.data.antragssteller_person_index}
              verbund_index={this.state.data.antragssteller_verbund_index}
              onChange={this.onPVSelect}
              person_liste={this.props.person_liste}
              verbund_liste={this.props.verbund_liste}
              required
            />
          </Col>
          <Col><EnumInput label="Kreditgeber" name="kreditgeber" value={this.state.data.kreditgeber} onChange={this.onChange} /></Col>
          <Col><CurrencyInput required label="Darlehen Betrag" name="darlehen_betrag" value={this.state.data.darlehen_betrag} onChange={this.onChange} /></Col>
          <Col><DateInput required label="Termin Auszahlung" name="termin_auszahlung" value={this.state.data.termin_auszahlung} onChange={this.onChange} />          </Col>
        </Row>
        <Row>
          <Col><PercentInput required label="Sollzins" name="sollzins" value={this.state.data.sollzins} onChange={this.onChange} /></Col>
          <Col><EnumInput required label="Tilgungsform" name="tilgungsform" value={this.state.data.tilgungsform} onChange={this.onChange} /></Col>
          <Col><PercentInput label="Tilgungssatz" name="tilgung_satz" value={this.state.data.tilgung_satz} onChange={this.onChange} /></Col>
          <Col><CurrencyInput label="Rate" name="rate" value={this.state.data.rate} onChange={this.onChange} /></Col>
        </Row>
        <ACollapse>
          <Row>
            <Col><DateInput label="Laufzeitende" name="laufzeitende" value={this.state.data.laufzeitende} onChange={this.onChange} />          </Col>
            <Col><EnumInput enum="rhythmus" label="Abtrag Rhythmus" name="abtrag_rhythmus" value={this.state.data.abtrag_rhythmus} onChange={this.onChange} />            </Col>
            <Col><NumberInput name="zinsfestschreibung_dauer" label="Dauer Zinsfestschreibung Monate" value={this.state.data.zinsfestschreibung_dauer} onChange={this.onChange} /></Col>
            <Col><PercentInput label="Folgezins" name="folgezins" value={this.state.data.folgezins} onChange={this.onChange} />            </Col>
          </Row>
          <Row>
            <Col><NumberInput label="Tilgungsfreie Monate" name="anzahl_monate_tilgungsfrei" value={this.state.data.anzahl_monate_tilgungsfrei} onChange={this.onChange} />            </Col>
            <Col><PercentInput label="Auszahlungskurs" name="auszahlungskurs" value={this.state.data.auszahlungskurs} onChange={this.onChange} /></Col>
            <Col><CurrencyInput label="Provision" name="provision_betrag" value={this.state.data.provision_betrag} onChange={this.onChange} /></Col>
            <Col><TextInput label="KFW Programmnummer" name="kfw_programmnummer" value={this.state.data.kfw_programmnummer} onChange={this.onChange} /></Col>
          </Row>
        </ACollapse>
        <ACollapse label="Sondertilgung">
          <Row>
            <Col className="text-right">   <AButton size="sm" rounded onClick={this.addSondertilgung} color="info" outline> <FaPlus /></AButton></Col>
          </Row>
          {sondertilgung}
        </ACollapse>
        <ACollapse label="Ratenänderung">
          <Row>
            <Col className="text-right">   <AButton size="sm" rounded onClick={this.addRatenaenderung} color="info" outline> <FaPlus /></AButton></Col>
          </Row>
          {ratenaenderung}
        </ACollapse>
        <ACollapse label="Zinssatzänderung">
          <Row>
            <Col className="text-right">   <AButton size="sm" rounded onClick={this.addZinssatzaenderung} color="info" outline> <FaPlus /></AButton></Col>
          </Row>
          {zinssatzaenderung}
        </ACollapse>

      </div>
    );
  }
}