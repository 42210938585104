import * as React from 'react';
import '../style.css';
export interface ITextInputProps {
    name: string
    label?: string
    value?: string
    required?: boolean
    type?: 'text' | 'email' | 'url' | 'password' | 'number' | 'search'
    id?: string
    size?: number
    readOnly?: boolean
    onChange?(ev: React.ChangeEvent): void
    onFocus?(): void
    onBlur?(): void
    regexp?: RegExp
}

export default class TextInput extends React.Component<ITextInputProps> {

    public render() {
        let mainClass = 'AnimatedInput'
        let labelClass = this.props.value ? 'hasValue' : ''

        if (this.props.required && !(this.props.value && this.props.value !== '')) {
            mainClass += ' invalid';
        }

        if (this.props.regexp) {
            mainClass += ' ' + (this.props.regexp.test(this.props.value || '') ? '' : 'invalid');
        }

        let cildren = <></>;
        if (this.props.children) {
            cildren = <div className="InputIcon">{this.props.children}</div>;
            mainClass += ' WithIcon'
        }

        const value = this.props.value || ''
        return (
            <div className={mainClass}>

                <input type={this.props.type || 'text'}
                    name={this.props.name}
                    value={value}
                    onChange={this.props.onChange}
                    id={this.props.id}
                    size={this.props.size}
                    minLength={this.props.size}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    readOnly={this.props.readOnly}
                    autoComplete="none"
                   
                />

                <label htmlFor={this.props.name} className={labelClass}>
                    <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''}</span>
                </label>
                {cildren}
            </div>
        );
    }
}
