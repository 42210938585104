import * as React from 'react';
import { EnumService, IEnum } from '../../../../services';
import { IKommunikationsdaten } from '../types';
import { Row, Col} from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import Entry from './Entry';
import { AButton } from '../../aif';

interface IKommunikationsdatenProps {
    data?: IKommunikationsdaten[]
    onChange?(data: IKommunikationsdaten[]): void
}

interface IKommunikationsdatenState {
    data: IKommunikationsdaten[]
    enums?: IEnum
}

export default class Kommunikationsdaten extends React.Component<IKommunikationsdatenProps, IKommunikationsdatenState> {
    constructor(props: IKommunikationsdatenProps) {
        super(props);
        this.state = {
            data: this.props.data || []
        }
        this.addItem = this.addItem.bind(this);
        this.remoVeItem = this.remoVeItem.bind(this);
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })
    }

    UNSAFE_componentWillReceiveProps(newProps: IKommunikationsdatenProps) {
        this.setState({ data: newProps.data || [] });

    }
    addItem() {
        let e = this.state.enums?.kommunikation_art||[];
        let n = this.state.enums?.kommunikation_nutzung||[];
        const newItem: IKommunikationsdaten = {
            kommunikation_adresse: '',
            kommunikation_art: e[0],
            kommunikation_nutzung: n[0],
            bemerkung: '',
            einfache_aktualisierung: false,
            einwilligung_produkt_information: false,
            ans_id: '',
            loesch_kz: false
        }
        let copy = [...this.state.data];
        copy.push(newItem);
        this.setState({ data: copy }, () => {
            if (this.props.onChange) { this.props.onChange(this.state.data) }
        })
    }

    remoVeItem(item: IKommunikationsdaten) {
        let index = this.state.data.indexOf(item)
        if (index !== -1) {
            let copy = [...this.state.data];
            copy.splice(index, 1);
            this.setState({ data: copy }, () => {
                if (this.props.onChange) { this.props.onChange(this.state.data) }
            });
        }
    }

    
    public render() {
        if (!this.state.enums) return <></>;
        const list = this.state.data.map((k, i) => <Entry onRemove={this.remoVeItem} enums={this.state.enums} data={k} key={i} />)
        return (
            <div>
                <Row>
                    <Col className="text-right mt-2"><AButton  rounded onClick={this.addItem} size="sm" color="info" outline><FaPlus /></AButton></Col>
                </Row>
                {list}
            </div>
        );
    }

}
