import * as React from 'react';
import { IListItem, IChartData, IDayFilterItem } from '../types';
import { Bar } from 'react-chartjs-2';

interface IDayTrafficProps {
    data: IListItem[]
    redraw: boolean
    onChartItemClick?(item: IDayFilterItem): void
}

interface IDayTrafficState {
    data: IListItem[]
    _chart_data: IChartData
}

const backgroundColors = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
]

const borderColors = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
]

export default class DayTraffic extends React.Component<IDayTrafficProps, IDayTrafficState> {
    constructor(props: IDayTrafficProps) {
        super(props);
        this.state = {
            data: props.data,
            _chart_data: { datasets: [], labels: [] }
        }
        this.prepareData = this.prepareData.bind(this);
        this.onElementClick = this.onElementClick.bind(this);
        this.prepareData();
    }

    UNSAFE_componentWillReceiveProps(newProps: IDayTrafficProps) {
        this.setState({ data: newProps.data }, this.prepareData);
    }

    onElementClick(el: any) {
        if (el && el[0]) {
            const x = this.state._chart_data?.datasets[el[0]._datasetIndex];
            const l = this.state._chart_data?.labels[el[0]._index];

            const formatedL = l.replace(/\./ig, '');
            this.props.onChartItemClick && this.props.onChartItemClick({ art: x.label, label: formatedL });
        }
    }

    prepareData() {
        let data: IChartData = { datasets: [], labels: [] }
        data.datasets.push({
            key: "1",
            label: 'Bausparantrag',
            data: [],
            borderColor: borderColors[0],
            backgroundColor: backgroundColors[0],
            borderWidth: 1,
        })

        data.datasets.push({
            key: "2",
            label: 'Finanzierungsantrag',
            data: [],
            borderColor: borderColors[1],
            backgroundColor: backgroundColors[1],
            borderWidth: 1
        })
        data.datasets.push({
            key: "3",
            label: 'Lastschrifteinzugantrag',
            data: [],
            borderColor: borderColors[2],
            backgroundColor: backgroundColors[2],
            borderWidth: 1
        })
        data.datasets.push({
            key: "4",
            label: 'Personendatenantrag',
            data: [],
            borderColor: borderColors[3],
            backgroundColor: backgroundColors[3],
            borderWidth: 1
        })

        let agg: any = {}

        for (let i = 0; i < this.state.data.length; i++) {
            const item = this.state.data[i];
            const day = new Date(item._cat);
            const d_options = { year: 'numeric', month: 'short', day: 'numeric' };
            let label = day.toLocaleDateString('de-DE', d_options);

            const key = item.request?.antrag_art || 'nix';
            if (!agg[label]) {
                const item = { [key]: 1 }
                agg[label] = item;
            }
            else if (!agg[label][key]) {
                agg[label][key] = 1;
            }
            else {
                //if (item.response?.status === 200) { agg[label][key]++; }
                agg[label][key]++;
            }
        }

        const keyes = Object.keys(agg).reverse();


        keyes.forEach(k => {
            const i = agg[k];
            data.labels.push(k);
            data.datasets[0].data.push(i.Bausparantrag)
            data.datasets[1].data.push(i.Finanzierungsantrag)
            data.datasets[2].data.push(i.Lastschrifteinzugantrag)
            data.datasets[3].data.push(i.Personendatenantrag)
        })
        this.setState({ _chart_data: data });
    }

    public render() {
        return (
            <div className="DayTrafficChart">
                <Bar
                    getElementAtEvent={this.onElementClick}
                    data={this.state._chart_data}
                    height={40}
                    redraw={this.props.redraw}
                    options={{ maintainAspectRatio: false }} />
            </div>
        );
    }
}
