import * as React from 'react';
import { IWkrDaten } from './types';
import { Row, Col } from 'reactstrap';
import { EnumInput, CurrencyInput, TextInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

interface IWkrDatenProps {
    data?: IWkrDaten
    onChange?(data: IWkrDaten): void
}

interface IWkrDatenState {
    data: IWkrDaten
}

export default class WkrDaten extends React.Component<IWkrDatenProps, IWkrDatenState> {
    constructor(props: IWkrDatenProps) {
        super(props);
        this.state = {
            data: props.data || {}
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);

    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <>
                <Row>
                    <Col><EnumInput label="AVD / IVD" name="ivd_avd_kennzeichen" value={this.state.data.ivd_avd_kennzeichen} onChange={this.onChange} /></Col>
                    <Col><CurrencyInput label="Provision" name="provision_betrag" value={this.state.data.provision_betrag} onChange={this.onChange} /></Col>
                    <Col><TextInput label="Abweichende Vermittler Nr." name="abweichende_vermittler_nr" value={this.state.data.abweichende_vermittler_nr} onChange={this.onChange} /></Col>
                    <Col><TextInput label="Abschlussart" name="abschluss_art" value={this.state.data.abschluss_art} onChange={this.onChange} /></Col>
                    <Col><CheckBoxInput label="Empfehlung" name="produkt_empfehlung" value={this.state.data.produkt_empfehlung} onChange={this.onChange} /></Col>
                    <Col><CheckBoxInput label="sozialer Wohnungsbau" name="sozialer_wohnungsbau" value={this.state.data.sozialer_wohnungsbau} onChange={this.onChange} /></Col>
                </Row>
            </>
        );
    }
}
