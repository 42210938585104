import { ITarifData } from "../../common/Settings/Tarife/TarifService";

interface ISparPlanMonat {
    // count
    monat: number
    datum: Date
    guthaben: number
    anspargrad: number
    zinsen: number
    bewertungszahl: number
}

export interface IbsvRechnerResult {
    input: IBsvRechnerInput
    sparplan: ISparPlanMonat[]
    zuteilungstermin?: ISparPlanMonat
}

export interface IBsvRechnerInput {
    tarif: ITarifData
    spardauer_monate: number
    bausparsumme: number
    vertragsbeginn: string
    montl_sparrate: number
    vl: number
}

export default class BsvKalkulator {
    input: IBsvRechnerInput | undefined

    constructor(input: IBsvRechnerInput) {
        this.input = input
    }

    calculate(): IbsvRechnerResult | undefined {
        const input = this.input
        if (!input || !input.tarif) { return }

        let result: IbsvRechnerResult = {
            input: input,
            sparplan: []
        }

        const zinsfaktor = 1 + (input.tarif.data.guthabenzins_in_prozent / 100) / 12;
        const xbewertungszahl_zinsfaktor = input.tarif.data.bewertungszahl_zinsfaktor || .1;
        const mindest_bewertungszahl = input.tarif.data.mindest_bewertungszahl || 1;
        const mindest_ansparguthaben_in_prozent = input.tarif.data.mindest_ansparguthaben_in_prozent || 1;

        let i: number = 1;
        while (i < (input.spardauer_monate + 1)) {

            let zt = new Date(input.vertragsbeginn);
            zt.setMonth(zt.getMonth() + i);
            zt.setDate(1);
            let vormonat: ISparPlanMonat = result.sparplan[result.sparplan.length - 1] || { datum: new Date(zt.toISOString()), guthaben: 0, monat: i }
            let sparbetrag: number = input.montl_sparrate + input.vl;

            let akt_monat: ISparPlanMonat = {
                datum: new Date(zt.toISOString()),
                guthaben: 0,
                monat: i,
                anspargrad: 0,
                zinsen: 0,
                bewertungszahl: 0
            }

            let ansparsumme = Math.round((vormonat.guthaben + sparbetrag) * zinsfaktor * 100) / 100
            akt_monat.guthaben = ansparsumme;
            akt_monat.zinsen = Math.round((akt_monat.guthaben - (i * sparbetrag))*100)/100;
            akt_monat.anspargrad = Math.round((akt_monat.guthaben / input.bausparsumme) * 100 * 1000) / 1000
            akt_monat.bewertungszahl = Math.round((akt_monat.guthaben + (akt_monat.zinsen * xbewertungszahl_zinsfaktor)) / (input.bausparsumme * .004) * 1000) / 1000

            result.sparplan.push(akt_monat);

            if (akt_monat.bewertungszahl > mindest_bewertungszahl && akt_monat.anspargrad > mindest_ansparguthaben_in_prozent) {
                result.zuteilungstermin = akt_monat;
                break
            }
            i++;
        }
        return result;
    }
}
