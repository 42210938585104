import * as React from 'react';
import './style.css'
import { ICazeStoreItem, cazeDB } from '../../../../stores/dexiestore';
import { ToastEvents } from '../../Toast';

export interface IJsonImportProps {
    onImportDone?(): void
}

interface IJsonImportState {
    dragOver: boolean
}

export default class JsonImport extends React.Component<IJsonImportProps, IJsonImportState> {
    fileInputRef: React.RefObject<HTMLInputElement>;
    constructor(props: IJsonImportProps) {
        super(props);
        this.state = {
            dragOver: false
        }
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.importCaze = this.importCaze.bind(this);
        this.onClick = this.onClick.bind(this);
        this.fileInputRef = React.createRef();
    }

    onDragOver(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();
        this.setState({ dragOver: true });
    }

    onDragEnd(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();
        this.setState({ dragOver: false });
    }

    onDrop(ev: any) {
        ev.stopPropagation();
        ev.preventDefault();

        const files = ev.dataTransfer?.files ||ev.target?.files|| []
        for (var i = 0; i < files.length; i++) {
            let f = files[i];
            let reader = new FileReader();
            reader.onload = () => {
                try {
                    let j = JSON.parse(reader.result + '');
                    this.importCaze(j);
                }
                catch (err) {
                    console.log(f);
                    ToastEvents.error(f.name + ' Kein gültiger iBaufi Vorgang!');
                }
            }
            reader.readAsText(f)
        }
        this.setState({ dragOver: false });
    }

    importCaze(caze: any) {
        let data: ICazeStoreItem = {
            _idbid: caze.extern_id,
            value: {}
        }
        if (caze.antrag_art === "Finanzierungsantrag") {
            data = Object.assign(data, { fbad: caze, timestamp: Date.now() });
        }
        else if (caze.antrag_art === "Bausparantrag") {
            data = Object.assign(data, { bsv: caze, timestamp: Date.now()  });
        }
        else if (caze.antrag_art === "Lastschrifteinzugantrag") {
            data = Object.assign(data, { lastschrifteinzugantrag: caze, timestamp: Date.now()  });
        }
        else if (caze.antrag_art === "Personendatenantrag") {
            data = Object.assign(data, { personendatenantrag: caze, timestamp: Date.now()  });
        }
        cazeDB.saveCaze(data);
        if (this.props.onImportDone) {
            this.props.onImportDone()
        }
    }
    onClick() {
        if (this.fileInputRef) { 
            this.fileInputRef.current?.click();
        }
    }

    public render() {
        let css = "JsonImport"
        if (this.state.dragOver) {
            css += ' dragOver'
        }
        return (
            <div className={css}
                onClick={this.onClick}
                onDragOver={this.onDragOver}
                onDragEnter={this.onDragOver}
                onDragEnd={this.onDragEnd}
                onDragLeave={this.onDragEnd}
                onDrop={this.onDrop}
                onDropCapture={this.onDrop}
            >
                Antrag importieren
                <input type="file" multiple ref={this.fileInputRef} onChange={this.onDrop} style={{ display: 'none' }} accept="application/json" />
            </div>
        );
    }
}
