import * as React from 'react';
import { FaTimes } from 'react-icons/fa';
import { TPerson } from '../Person/types';

interface IPersonCardProps {
    person: TPerson
    index: number
    selected?: number
    onSelect?(index: number): void
    onRevokePerson?(index: number): void
}

interface IState { }

export default class PersonCard extends React.Component<IPersonCardProps> {

    constructor(props: IPersonCardProps) {
        super(props);
        this.onDrag = this.onDrag.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.revokePerson = this.revokePerson.bind(this);
    }

    select() {
        if (this.props.onSelect) {
            this.props.onSelect(this.props.index);
        }
    }

    onDragStart(ev: any) {
        ev.dataTransfer.setData("text", this.props.index);
    }

    onDrag(ev: any) {
        ev.preventDefault();
    }

    revokePerson() {
        if (this.props.onRevokePerson) {
            this.props.onRevokePerson(this.props.index);
        }
    }

    public render() {
        if (!this.props.person) { return <></> }
        let person:any = this.props.person;
        let css = 'PersonCard ' + this.props.person.person_art + ' ' + this.props.person.anrede
        if (this.props.index === this.props.selected) {
            css += ' selected';
        }
        const toolbar = this.props.onRevokePerson ? <div title="Aus dem Verbund entfernen." className="ToolBar text-right RevokeIcon" onClick={this.revokePerson}>
            <FaTimes />
        </div> : '';
        let title = person.person_nr;
        if (!!person.nachname || !!person.vorname) {
            title = this.props.person.nachname;
            if (this.props.person.person_art !== 'Juristisch') {
                title = person.vorname+' '+person.nachname;
            }
        }
        

        return (
            <div className={css.toString()} onClick={() => { this.select() }}
                draggable={true}
                onDrag={this.onDrag}
                onDragStart={this.onDragStart}>
                {toolbar}
                <div>
                    {title}
                </div>
            </div>
        );
    }
}
