import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IEinmaleinzug } from '../../../types/Lastschrifteinzugdaten';
import { CurrencyInput, DateInput, EnumInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';


interface IEinmaleinzugProps {
    data?: IEinmaleinzug
    onChange?(data?: IEinmaleinzug): void
}

interface IEinmaleinzugState {
    data: IEinmaleinzug
}

export default class Einmaleinzug extends React.Component<IEinmaleinzugProps, IEinmaleinzugState> {
    constructor(props: IEinmaleinzugProps) {
        super(props);

        this.state = {
            data: props.data || {
                einzug_restschuld_darlehen: false
            }
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <Row>
                <Col><EnumInput name="konto_art" value={this.state.data.konto_art} label="Konto" onChange={this.onChange} /></Col >
                <Col><CurrencyInput label="Betrag" name="betrag" value={this.state.data.betrag || 0} onChange={this.onChange} /></Col>
                <Col><DateInput label="Einzug am" name="einzug_am" value={this.state.data.einzug_am} onChange={this.onChange} /></Col>
                <Col><CheckBoxInput label="Einzug Restschulddarlehen" name="einzug_restschuld_darlehen" value={this.state.data.einzug_restschuld_darlehen} onChange={this.onChange} /></Col>
            </Row>
        );
    }
}
