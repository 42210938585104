import * as React from 'react';
import { ISondertilgung } from './types';
import { Row, Col } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { DateInput, CurrencyInput, NumberInput, EnumInput } from '../../common/aif';

interface ISondertilgungProps {
    data: ISondertilgung
    onChange?(data: ISondertilgung): void
    onRemove?(data: ISondertilgung): void
}

interface ISondertilgungState {
    data: ISondertilgung
}

export default class Sondertilgung extends React.Component<ISondertilgungProps, ISondertilgungState> {
    constructor(props: ISondertilgungProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentWillReceiveProps(newProps: ISondertilgungProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.data);
        }
    }

    public render() {
        return (
            <Row>
                <Col>
                    <DateInput required label="Termin" name="termin" value={this.state.data.termin} onChange={this.onChange} />
                </Col>
                <Col>
                    <CurrencyInput required label="Betrag" name="betrag" value={this.state.data.betrag} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Anzahl Jahre" name="anzahl_jahre" value={this.state.data.anzahl_jahre} onChange={this.onChange} />
                </Col>
                <Col>
                    <EnumInput required label="Art" enum="annuitaetenanpassung_art" name="annuitaetenanpassung_art" value={this.state.data.annuitaetenanpassung_art} onChange={this.onChange} />
                </Col>
                <Col md="1">
                    <FaTrashAlt style={{ marginTop: '100%' }} className="removeIcon" onClick={this.onRemove} title="Sondertilgung entfernen" />
                </Col>
            </Row>
        );
    }
}
