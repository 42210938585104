import * as React from 'react';
import { IBausparantrag, IVLDaten } from './types';
import { Container } from 'reactstrap';
import Spardaten from './Spardaten';
import ACollapse from '../common/aif/ACollapse';
import VlDaten from './VlDaten';
import RiesterdatenKind from '../common/Riesterdaten/RiesterdatenKind';
import { IRiesterdatenKind } from '../common/Riesterdaten';

interface ITabSpardatenProps {
    data: IBausparantrag
    onChange?(data: IBausparantrag): void
}

interface ITabSpardatenState {
    data: IBausparantrag
}

export default class TabSpardaten extends React.Component<ITabSpardatenProps, ITabSpardatenState> {
    constructor(props: ITabSpardatenProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onVlDatenChange = this.onVlDatenChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onRiesterKindChange=this.onRiesterKindChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: ITabSpardatenProps) {
        this.setState({ data: newProps.data });
    }

    onVlDatenChange(data: IVLDaten | undefined) {
        const copy = Object.assign(this.state.data, { vl_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    onRiesterKindChange(data: IRiesterdatenKind[]) {
        const copy = Object.assign(this.state.data, { riester_kind_liste: data });
        this.setState({ data: copy }, this.notify);
    }


    public render() {
        return (
            <Container>
                <Spardaten data={this.state.data.spardaten} />
                <ACollapse label="VL Daten">
                    <VlDaten data={this.state.data.vl_daten} person_liste={this.state.data.person_liste} onChange={this.onVlDatenChange} />
                </ACollapse>
                
                <ACollapse label="Riester Kind">
                    <fieldset>
                        <RiesterdatenKind data={this.state.data.riester_kind_liste || []} onChange={this.onRiesterKindChange}/>
                    </fieldset>
                </ACollapse>


            </Container>
        );
    }
}
