import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IBsvProvisionVertrieb } from '../types';
import { NumberInput, TextInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

interface IProvisionVertriebProps {
    data?: IBsvProvisionVertrieb
    onChange?(data: IBsvProvisionVertrieb): void
}

interface IProvisionVertriebState {
    data: IBsvProvisionVertrieb
}

export default class ProvisionVertrieb extends React.Component<IProvisionVertriebProps, IProvisionVertriebState> {
    constructor(props: IProvisionVertriebProps) {
        super(props);

        this.state = {
            data: props.data || {}
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <>
                <Row>
                    <Col>
                        <NumberInput label="Sachpreis Nr." name="sachpreis_nr" value={this.state.data.sachpreis_nr} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <NumberInput label="Vertriebsaktion Nr." name="vertriebsaktion_nr" value={this.state.data.vertriebsaktion_nr} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <NumberInput label="Zusatzprovision Nr" name="zusatzprovision_nr" value={this.state.data.zusatzprovision_nr} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <TextInput label="Bonifikation" name="bonifikation" value={this.state.data.bonifikation} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CheckBoxInput label="Aussendienstlich" name="aussendienstlich" value={this.state.data.aussendienstlich} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CheckBoxInput label="Folgevertrag" name="folgevertrag" value={this.state.data.folgevertrag} onChange={this.onChange} />
                    </Col>
                </Row>
            </>
        );
    }
}
