import { IEnum } from "."

const init_enums: IEnum = {
    _idbid:'enumlist',
    "abschluss_grund": ["Bildung_Eigenkapital", "Geschenk_BSV", "Perspektivische_Finanzierer", "Renovierung_Modenisierung_Umbau", "Sofortfinanzierer", "Sonstiges", "Unterlegung_Annuitätendarlehen", "Wohnriester_Altersvorsorge", "Zinssicherung_Finanzierung"],
    "antrag_art": ["Bausparantrag", "Finanzierungsantrag"],
    "ausgabe_art": ["Weitere_geplante_Ausgaben", "Bausparvertrag", "Bewirtschaftungskosten", "Darlehensraten", "Haushaltshilfe_Kinderbetreuungskosten", "Leasingraten", "Lebenshaltungskosten", "Lebensversicherung", "Miete_incl_Umlagen", "Nebenkosten", "Personenversicherungen", "Private_Kranken_und_Pflegeversicherung", "Schadenversicherungen", "Sonstige_Ausgaben", "Sonstige_Gebühren", "Sparraten", "Steuerschulden", "Unterhaltszahlungen", "Versicherung", "Vorsorgeaufwendungen"],
    "ausstattungsstandard": ["Gehoben", "Normal", "Einfach"],
    "berufliche_stellung": ["kein_Eintrag", "Auszubildender", "Arbeiter_öffentlicher_Dienst", "Angestellter_öffentlicher_Dienst", "Leitender_Angestellter_öffentlicher_Dienst", "Hausfrau", "Hausmann", "ohne_Beruf", "Arbeitslos", "Arbeiter", "Angestellter", "Leitender_Angestellter", "Beamter", "Kind", "Schüler", "Student", "Pensionär", "Rentner", "Freiwilliger_Sozialbereich", "Wehrdienst", "Selbstständig"],
    "branche": ["Land_und_Forstwirtschaft_Fischerei", "Bergbau_Erdöl_ErdgasIndustrie", "Handwerk_verarbeitendes_Gewerbe", "Produktion_Industrie", "Energie_und_Wasserversorgung", "Abwasser_und_Abfallentsorgung", "Baugewerbe", "Groß_und_Einzelhandel", "Verkehr_Transport_Lagerei", "Hotel_Gastronomie", "Information_Kommunikation", "Banken", "Versicherungen", "Grundstücks_und_Wohnungswesen", "Öffentlicher_Dienst", "Erziehung_Bildung_Unterricht", "Gesundheits_und_Sozialwesen", "Kunst_Unterhaltung_Erholung", "Sonstige", "Nicht_relevant", "Wohnungseigentümergemeinschaft"],
    "dokumenten_art": ["Reisepass", "Personalausweis"],
    "eigenmittel_art": ["Einlagen", "Bargeld", "Beantragte_Fremdmittel", "Bezahltes_Grundstück", "Bezahlte_Baukosten", "Eigenleistung", "Riestereinlage"],
    "einnahme_art": ["geplante_Ersparnis", "Fällige_Passiveinlage", "Kapitalerträge", "Kindergeld", "Lohn_und_Gehalt_Netto", "Miete_ohne_Haushaltspauschale", "Geplante_Mieteinnahmen", "Mieteinnahme", "Nebenberufliche_Einnahme", "Rente_Pension", "Schenkung", "Selbstständige_Tätigkeit_Netto", "Sonstige_Einnahmen", "Sonstiges_ohne_Haushaltspauschale", "Unterhaltszahlungen", "Versicherungsprämien", "Zins_und_Kapitalerträge"],
    "ersatzsicherheit_art": ["Lebensversicherung", "Bundesschatzbriefe", "LBS_Guthabenverpfändung", "Schatzanweisung", "Emission_der_Landesbank", "Aktie", "Mündelsichere_Wertpapiere", "Fondanteile", "Sparbrief", "Zertifikate", "Termingeld", "Festgeld", "Sparbuch", "Sonstige_Bausparguthaben", "Obligationen_öffentlicher_Stellen", "SPK_Obligationen_Schuldverschreibungen", "Lombardfähige_Schuldverschreibung", "Sonstige_Schuldverschreibungen", "Globalbürgschaft", "S_Bürgschaft", "Fremde_Grundschuld_neu", "SPK_Grundschuld_neu", "Sparkassen_Blanko"],
    "familienstand": ["eheähnliche_Gemeinschaft", "geschieden", "getrennt_lebend", "Lebenspartnerschaft", "ledig", "verheiratet", "verwitwet"],
    "finanzierungsmittel_art": ["LbsFinanzierung", "Bauspardarlehen", "DarlehenOhneBsv", "Kombiprodukt"],
    finanzierungsgegenstand_art: ["Immobilie", "Sonstiger Finanzierungsgegenstand"],
    "finanzierungszweck": ["Kauf_Ersterwerb", "Bau_Ersterwerb", "Kauf_ohne_Ersterwerb", "Kauf_Modernisierung_ohne_Ersterwerb", "Entschuldung", "AnbauUmbau", "Modernisierung", "Mietermodernisierung", "Wohnfolgeeinrichtung", "Sonstige_wohnwirtschaftliche_Zwecke", "Erwerb_von_Bauland", "Erbauseinandersetzung", "Umschuldung", "Sicherungsobjekt"],
    "gebaeude_art": ["Eine_Etage_ausgebautes_DG_unterkellert", "Eine_Etage_ausgebautes_DG_nicht_unterkellert", "Eine_Etage_kein_DG_unterkellert", "Eine_Etage_kein_DG_nicht_unterkellert", "Eine_Etage_nicht_ausgebautes_DG_unterkellert", "Eine_Etage_nicht_ausgebautes_DG_nicht_unterkellert", "Zwei_Etagen_ausgebautes_DG_unterkellert", "Zwei_Etagen_ausgebautes_DG_nicht_unterkellert", "Zwei_Etagen_kein_DG_unterkellert", "Zwei_Etagen_kein_DG_nicht_unterkellert", "Zwei_Etagen_nicht_ausgebautes_DG_unterkellert", "Zwei_Etagen_nicht_ausgebautes_DG_nicht_unterkellert"],
    "gebaeude_zustand": ["Laufend_Instandgehalten", "Instandhaltungsrückstand", "renovierungsbedürftig"],
    "gebiet": ["Wohngebiet", "Mischgebiet", "Gewerbegebiet", "Kerngebiet"],
    "gebuehr_art": ["Sonderzahlung", "Verrechnung"],
    "ivd_avd_kennzeichen": ["IVD", "AVD"],
    "jur_person_unterart": ["Kreditinstitut", "Öffentlicher_Haushalt", "Unternehmen"],
    "kreditgeber": ["LBS", "KfW"],
    "kommunikation_art": ["Telefon_privat", "Telefon_geschäftlich", "Telefon_mobil", "Email"],
    "kommunikation_nutzung": ["privat", "geschäftlich", "geschäftlich und privat"],
    "kundenpraeferenz": ["unwichtig", "weniger_wichtig", "wichtig", "sehr_wichtig"],
    "lagebeurteilung": ["Bevorzugt", "Gut", "Weniger_gut", "Schlecht", "Keine_Angabe"],
    "land": ["Deutschland"],
    "last_art": ["Lieferung_von_Naturalien", "Nutzungsbeschränkungen", "Nacherbschaft", "Reallast", "Recht_auf_Mietzins", "Erbbauzinsreallast", "Rückauflassungsvormerkung", "Sanierungsvermerk", "Transformatorenrecht", "Tankstellenrecht", "Vorerbschaft", "Verbot_der_Zuführung_externer_Einflüsse", "Vorkaufsrecht", "Wohnungsbelegungsrecht", "Wegerecht", "Wohnrecht", "Wettbewerbsbeschränkung", "Wege_und_Weidegerechtigkeit", "Zwangsversteigerung", "Zwangsverwaltung", "Schürf_Abbau_Ausbeutungsrecht", "Leibgeding", "Grunddienstbarkeit", "Beschränkt", "Persönliche_Dienstbarkeit", "Dauerwohnrecht", "Rangvorbehalt_Abteilung_II", "Dauernutzungsrecht", "Wiederkaufsrecht", "Widerspruchsrecht", "Sonstiges_Nutzungsrecht_Vormerkung", "Unerhebliches_Nutzungsrecht", "sonstiger_Vorbehalt", "Vermieterpfandrecht", "Zubehörhaftungsrecht", "Nachlassverwaltungsvermerk", "Bergschadenvoll_und_teilverzicht", "Immessionsschadenverzicht", "Vormerkung_Erhöhung_Erbbauzins", "Vormerkung_Erhöhung_Reallast", "Insolvenzvermerk", "Testamentsvollstreckervermerk", "Umlegungsvermerk", "Verfügungsbeschränkung", "Eigenes_Grundpfandrecht", "Fremdes_Grundpfandrecht", "Erbbaurecht"],
    "nachrichtentyp": ["Frage", "Antwort"],
    "nutzung_art": ["Eigen", "Fremd", "Teilweise_eigen"],
    "objekt_art": ["Eigentumswohnung", "Einfamilienhaus", "Zweifamilienhaus", "Mehrfamilienhaus", "Bauplatz", "Wohn_Geschäftshaus", "Sonstiges_Gewerbevorhaben"],
    "person_art": ["Privat", "Juristisch"],
    "raten_rhythmus": ["Jährlich", "Halbjährlich", "Vierteljährlich", "Einmalig", "Monatlich"],
    "rechtsform": ["Einzelunternehmen", "OHG", "GBR", "KG", "AG", "GMBH"],
    "riester_berechnungsziel": ["Eigenleistung_für_volle_Zulage_und_Steuerersparnis", "Zulagen_aus_monatlicher_Eigenleistung", "Eigenleistung_für_volle_Zulage", "Zulagen_aus_Pflichtraten_zur_Finanzierung"],
    "riester_zulagenberechtigung": ["mittelbar", "unmittelbar"],
    "riester_zulagenverwendung": ["Sondertilgung", "Ratenverrechnung"],
    "rolle": ["Antragssteller", "Sicherheitengeber", "Mitverpflichteter", "Wirtschaftlich_Berechtigter", "Gesetzlicher_Vertreter"],
    "status": ["Abgelehnt", "Beendet", "Bewilligt", "Erledigt", "In_Arbeit", "Rückfrage", "Rückfrage_beantwortet", "Übermittelt", "Übermittelt_mit_Fehler", "Offen"],
    "steuer_art": ["Befreit", "AgSt_ohne_Kirchensteuer", "AgSt_mit_Kirchensteuer", "Kapitalertragsteuer"],
    "sicherheit_art": ["Ersatzsicherheit", "Dingliche_Sicherheit"],
    "verbund_art": ["Eheleute", "Eheähnliche_Gemeinschaft", "Lebenspartnerschaft", "Wohnungseigentümergemeinschaft", "Erbengemeinschaft", "nicht_rechtsfähiger_Verein", "Sonstige_Personengemeinschaft", "Handeln_für_Privatpersonen"],
    "vereinbarung_art": ["Abtretung", "Haltevereinbarung"],
    "vl_art": ["Vermögenswirksame_Leistungen", "Altersvorsorgewirksame_Leistungen"],
    "zinsfestschreibung_art": ["Fest", "Variabel", "Bis_Zuteilung"]
}
export default init_enums
