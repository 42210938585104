import * as React from 'react';
import { IEigenmittel } from './types';
import { EnumService, IEnum } from '../../../services';
import { Row, Col, ListGroupItem } from 'reactstrap';
import { IVerbund } from '../../common/Verbund/types';
import { TPerson } from '../../common/Person/types';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';

import { TextInput, CurrencyInput, EnumInput } from '../../common/aif';

import { FaTrashAlt, FaCopy } from 'react-icons/fa';

interface IEigenmittelProps {
  onDataChange?(obj: any): void
  data: IEigenmittel
  index: number
  person_liste: TPerson[]
  verbund_liste: IVerbund[]
  onSelect?(index: number): void
  onDelete?(index: number): void
  onCopy?(data: IEigenmittel): void
}

interface IState {
  enums?: IEnum
  data: IEigenmittel
}

export default class Eigenmittel extends React.Component<IEigenmittelProps, IState> {

  constructor(props: IEigenmittelProps) {
    super(props);
    this.state = { data: this.props.data };
    this.notify = this.notify.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onPVSelect = this.onPVSelect.bind(this);
  }

  notify() {
    if (this.props.onDataChange) {
      this.props.onDataChange(this.state.data);
    }
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then(ok => {
      this.setState({ enums: ok });
    });
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const newVal = { [ev.target.name]: ev.target.value };
    const copy = Object.assign(this.state.data, newVal);
    this.setState({ data: copy }, this.notify)
  }

  onRemove() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.index);
    }
  }

  onCopy() {
    if (this.props.onCopy) {
      this.props.onCopy(this.props.data);
    }
  }

  onPVSelect(obj: IPVSelectReturn) {
    let data: any = {
      eigenmittelgeber_verbund_index: undefined,
      eigenmittelgeber_person_index: undefined
    }
    if (obj.type === "verbund_index") {
      data.eigenmittelgeber_verbund_index = obj.value;
    }
    else {
      data.eigenmittelgeber_person_index = obj.value;
    }

    let copy = Object.assign(this.state.data, data);
    this.setState({ data: copy }, this.notify)
  }

  public render() {
    if (!this.state.enums) { return <></> }
    return (
      <ListGroupItem>
        <Row>
          <Col>
            <PVSelect label="Person / Verbund"
              person_liste={this.props.person_liste}
              verbund_liste={this.props.verbund_liste}
              person_index={this.state.data.eigenmittelgeber_person_index}
              verbund_index={this.state.data.eigenmittelgeber_verbund_index}
              onChange={this.onPVSelect}
            />
          </Col>
          <Col>
            <EnumInput label="Art" name="eigenmittel_art" value={this.state.data.eigenmittel_art} onChange={this.onChange} />
          </Col>
          <Col>
            <TextInput label="Bemerkung" name="bemerkung" value={this.state.data.bemerkung} onChange={this.onChange} />
          </Col>
          <Col md="2">
            <CurrencyInput label="Betrag" name="betrag" value={this.state.data.betrag} onChange={this.onChange} />
          </Col>
          <Col md="2">
            <FaTrashAlt style={{ marginTop: '20%' }} className="removeIcon" onClick={this.onRemove} title="Rate entfernen" />
            <FaCopy style={{ marginTop: '20%' }} onClick={this.onCopy} className="copyIcon" title="kopieren" />
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}
