import axios, { AxiosPromise } from 'axios';
import SettingsService from './SettingsService';
import { keycloak } from '../components/security';

const _getHeader = () => {
    try {
        const token = keycloak.token
        const s = SettingsService.getSettings();
        return {
            "Authorization": 'Bearer ' + token,
            "X-OSPE-BLZ": s["X-OSPE-BLZ"],
            "apikey": s.apikey
        }
    }
    catch (err) {
        console.error(err);
        return {}
    }
}
const api_url = process.env.REACT_APP_API_HOST +'/log/dashboard/api'
export class DashboardService {
    static getDashboardData(from?:Date):AxiosPromise<any> {
        let q=''
        if(from)
        {
            q='?from='+from;
        }
 
        return axios.get(api_url + '/all'+q, { headers: _getHeader() });
    }
    
    static getDashboardEntryById(_id:string):AxiosPromise<any> {
        return axios.get(api_url + '/entry/'+_id, { headers: _getHeader() });
    }
    
}