import * as React from 'react';
import {FaShare, FaSyncAlt} from 'react-icons/fa';
import ReactJson from 'react-json-view';
import {Button, Col, Container, Row} from 'reactstrap';
import BackendService from '../../../services/BackendService';
import {TextInput} from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import {AntragLoeschenOderAendernButton} from "../../common/Summary/AntragLoeschenOderAendernButton";
import AntragReloadButton from "../../common/Summary/AntragReloadButton";
import {StatusDropdown} from "../../common/Summary/StatusDropdown";
import {ToastEvents} from '../../common/Toast';
import {IFinanzierungsantrag} from '../types';

export interface ISummaryProps {
    data: IFinanzierungsantrag
    _idbid?: string

    onDataChange?(obj: any): void
}

interface IState {
    loading: boolean
    data: IFinanzierungsantrag
    antragGespeichert: IFinanzierungsantrag
}

export default class Summary extends React.Component<ISummaryProps, IState> {

    constructor(props: ISummaryProps) {
        super(props);
        this.state = {loading: false, data: this.props.data, antragGespeichert: {...this.props.data}};
        this.sendFbad = this.sendFbad.bind(this);
        this.encodedURI = this.encodedURI.bind(this);
        this.onAntragInformationenChange = this.onAntragInformationenChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onDataChange) {
            this.props.onDataChange(this.state.data);
        }
    }

    onAntragInformationenChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let antragInformationen = Object.assign(this.state.data.antrag_informationen, {[ev.target.name]: ev.target.value});
        let copy = Object.assign(this.state.data, {antrag_informationen: antragInformationen});
        this.setState({data: copy}, this.notify);
    }

    UNSAFE_componentWillReceiveProps(newProps: ISummaryProps) {
        this.setState({data: newProps.data});
    }


    async sendFbad() {
        this.setState({loading: true});
        try {
            let antrag = Object.assign(this.props.data, {antrag_informationen:{extern_id: this.props._idbid}});
            let statusData = this.state.data.status;
            antrag.status = statusData;
            if(antrag.immobilie_liste){
                for (let i= 0; i< antrag.immobilie_liste?.length; i++){
                    let energietraeger = antrag.immobilie_liste[i].bewertung.energietraeger;
                    if(energietraeger){
                        if(energietraeger[0] === "" && energietraeger[1] !== ""){
                            energietraeger = [energietraeger[1]]
                            antrag.immobilie_liste[i].bewertung.energietraeger = energietraeger;
                        } else if(energietraeger[0] !== "" && energietraeger[1] === ""){
                            energietraeger = [energietraeger[0]]
                            antrag.immobilie_liste[i].bewertung.energietraeger = energietraeger;
                        } else if(energietraeger[0] ==="" && energietraeger[1] ===""){
                            antrag.immobilie_liste[i].bewertung.energietraeger = undefined
                        }

                    }
                }
            }
            let ok = await BackendService.sendAntrag(antrag)
            const savedFbad: IFinanzierungsantrag = ok.data;
            this.handleAntragChange(savedFbad)
            if (this.props.onDataChange) {
                this.props.onDataChange(savedFbad);
            }

            ToastEvents.notify("Antrag übertragen!")
        } catch (err) {
            ToastEvents.error('Fehler bei Datenübertragung! ' + err.message);
            if (err.response && err.response.status === 400) // Validation fails   
            {
                if (err.response.data.messages) {
                    const msgs = err.response.data.messages;
                    msgs.forEach((m: any) => {
                        ToastEvents.error(m);
                    });
                } else {
                    ToastEvents.error(err.response.statusText);
                }
            }
        } finally {
            this.setState({loading: false});
        }
    }

    encodedURI(): string {
        const str = JSON.stringify(this.state.data, null, 2);
        const encodedURI = encodeURI('data:application/json;charset=utf-8,' + str);
        return encodedURI;
    }

    handleStatusChange = (status: string) => {
        let data = {...this.state.data};
        data.status = status;
        this.setState({data: data});
    }

    handleAntragChange = (antrag: any) => {
        this.setState({data: antrag});
        this.setState({antragGespeichert: {...antrag}})
    }

    public render() {

        const icon = this.state.loading ? <FaSyncAlt className="runsync"/> : <FaShare/>;
        const sendBtn = <Button disabled={this.state.loading} onClick={this.sendFbad}
                                color="info">{icon} Absenden</Button>;
        const encURI = this.encodedURI();
        const dName = this.state.data.antrag_art + '.json';

        return (
            <Container className="mt-4">
                <Row>
                    <Col> <TextInput label="Transaktionsnummer (TAN)" name="tan"
                                     value={this.state.data.antrag_informationen.tan === undefined ? "" :this.state.data.antrag_informationen.tan}
                                     onChange={this.onAntragInformationenChange}/></Col>
                    <Col> <CheckBoxInput label="Druck" name="druck_kz"
                                         value={this.state.data.antrag_informationen.druck_kz === undefined ? false : this.state.data.antrag_informationen.druck_kz}
                                         onChange={this.onAntragInformationenChange}/></Col>
                    <Col> <StatusDropdown onStatusSelect={this.handleStatusChange}/></Col>
                    <Col className="pt-2 text-right">
                        {sendBtn}
                        <a href={encURI} download={dName} className="btn btn-primary">Export</a>
                        <AntragLoeschenOderAendernButton antrag={this.state.data}
                                                         antragGespeichert={this.state.antragGespeichert}
                                                         onChange={this.handleAntragChange} isLoeschen={true}/>
                        <AntragLoeschenOderAendernButton antrag={this.state.data}
                                                         antragGespeichert={this.state.antragGespeichert}
                                                         onChange={this.handleAntragChange} isLoeschen={false}/>
                        <AntragReloadButton antragId={this.state.antragGespeichert.antrag_id === undefined ? "" : this.state.antragGespeichert.antrag_id} onAntragLoaded={this.handleAntragChange}/>
                    </Col>
                </Row>
                <ReactJson src={this.state.data} collapsed={1}></ReactJson>
            </Container>
        );
    }
}
