import * as React from 'react';
import { IRatenaenderung } from '.';
import { Row, Col } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { DateInput, CurrencyInput } from '../../common/aif';

interface IRatenaenderungProps {
    data: IRatenaenderung
    onChange?(data: IRatenaenderung): void
    onRemove?(data: IRatenaenderung): void
}

interface IRatenaenderungState {
    data: IRatenaenderung
}

export default class Ratenaenderung extends React.Component<IRatenaenderungProps, IRatenaenderungState> {
    constructor(props: IRatenaenderungProps) {
        super(props);

        this.state = {
            data: props.data
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentWillReceiveProps(newProps: IRatenaenderungProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onRemove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.data);
        }
    }

    public render() {
        return (
            <Row>
                <Col>
                    <DateInput required label="Termin" name="termin" value={this.state.data.termin} onChange={this.onChange} />
                </Col>
                <Col>
                    <CurrencyInput required name="rate" label="Rate" value={this.state.data.rate} onChange={this.onChange} />
                </Col>
                <Col md="1">
                    <FaTrashAlt style={{ marginTop: '100%' }} className="removeIcon" onClick={this.onRemove} title="Ratenänderung  entfernen" />

                </Col>
            </Row>
        );
    }
}
