import React, {Component} from 'react';
import {FaBars} from 'react-icons/fa';
import {BrowserRouter as Router, Link, Route} from "react-router-dom";
import Routes from '../Routes';
import Links from '../Links';
import './style.css';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem,} from 'reactstrap';

import logo from '../favicon-32x32.png';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.togle = this.togle.bind(this);
        this.state = { isOpen: false }
        this.state.user = {}
    }

    togle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const navItems = Links.map(i => (
            <Link to={i.path} key={i.path} ><NavItem>
               
                    <span className="navItem">
                        <span className='navIcon'>{i.icon()}</span>
                        <span className='navTitle'>{i.title}</span>
                    </span>
                

            </NavItem></Link>
        ))
        return (
            <Router>
                <Navbar expand="lg" className="fixed-top" color="info">

                    <NavbarBrand className="text-inline">
                        <img src={logo} alt="iBaufi" /> <span className="badge">V6</span>
                    </NavbarBrand>

                    <NavbarToggler onClick={this.togle} className="mr-2">
                        <FaBars style={{ color: 'fff' }}></FaBars>
                    </NavbarToggler>

                    <Collapse isOpen={this.state.isOpen} navbar={true}>
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="ml-auto" navbar>
                                {navItems}
                            </Nav>
                        </Collapse>
                    </Collapse>

                </Navbar>
                <div className="pt-5"></div>
                <Route render={({ location, history }) => (
                    <React.Fragment>
                        <main>
                            <Routes />
                        </main>
                    </React.Fragment>
                )}
                />
            </Router>
        )
    }
}

export default NavBar;