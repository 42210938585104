import React, { useState } from "react";
import {Button} from "reactstrap";
import {IAntrag} from "../../../types/Antrag";
import BackendService from "../../../services/BackendService";
import {ToastEvents} from "../Toast";

interface AntragLoeschenOderAendernButtonProps {
    antrag: IAntrag
    antragGespeichert: IAntrag
    onChange: (updatedAntrag: any) => void;
    isLoeschen: boolean
}

export function AntragLoeschenOderAendernButton(props: AntragLoeschenOderAendernButtonProps) {
    const [isLoading, setIsLoading] = useState(false);

    async function handleClick() {
        try {
            setIsLoading(true);
            let antrag = props.antrag;
            if (props.isLoeschen) {
                const promise = await BackendService.leseAntrag(props.antragGespeichert.antrag_id === undefined ? "" : props.antragGespeichert.antrag_id)
                setIsLoading(false);
                antrag = promise.data
                antrag.status = "Verworfen"
            }
            const {data} = await BackendService.updateAntrag(antrag)
            props.onChange(data);
            ToastEvents.notify("Antrag erfolgreich geändert");

        } catch (err) {
            ToastEvents.error('Fehler bei Datenübertragung! ' + err.message);
            if (err.response && err.response.status === 400) // Validation fails
            {
                if (err.response.data.messages) {
                    const msgs = err.response.data.messages;
                    msgs.forEach((m: any) => {
                        ToastEvents.error(m);
                    });
                } else {
                    ToastEvents.error(err.response.statusText);
                }
            } else if (err.response && err.response.status === 403) {
                ToastEvents.error("Der zuänderde Antrag ist nicht mehr \"In Planung\"");
            } else if (err.response && err.response.status === 404) {
                ToastEvents.error("Der zuänderde Antrag konnte nicht gefunden werden");
            }
        }
        setIsLoading(false);
    }

    function isDisabled() {
        let antrag = props.antragGespeichert;
        return antrag.antrag_id === null || antrag.status !== "In Planung" || isLoading

    }

    function getName(): String {
        return props.isLoeschen ? "Löschen" : "Ändern";
    }

    return (
        <Button onClick={() => handleClick()} disabled={isDisabled()} color={"info"}>{getName()}</Button>
    )

}