import React, {Component} from 'react';
import SideNav, {NavIcon, NavItem, NavText} from '@trendmicro/react-sidenav';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Routes from '../Routes';
import Links from '../Links';
import './style.css'
import {Navbar, NavbarBrand} from 'reactstrap';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import logo from '../favicon-32x32.png';
import {useKeycloak} from '@react-keycloak/web'
import UserInfo from '../UserInfo'

const User = () => {
    const { initialized, keycloak } = useKeycloak();
    if ((initialized && keycloak.authenticated)) {
        return (<UserInfo keycloak={keycloak} />);
    }
    return (<span onClick={() => keycloak.login()}>Login</span>);
}

class SideNavigation extends Component {
    render() {
        const navItems = Links.map(i => (
            <NavItem key={i.title} eventKey={i.path}>
                <NavIcon>
                    {i.icon()}
                </NavIcon>
                <NavText>
                    {i.title}
                </NavText>
            </NavItem>
        ));

        return (
            <Router>
                <Navbar expand="md" className="border-bottom bg-light">
                    <NavbarBrand className="text-inline text-info" style={{ marginLeft: '3em' }}>
                        <div className="text-info"> <img src={logo} alt="iBaufi" /> iBaufi <span className="badge">V6</span></div>
                    </NavbarBrand>
                    <div className="mr-auto">

                    </div>
                    <div className="text-right">
                        <User></User>
                    </div>
                </Navbar>

                <Route render={({ location, history }) => (
                    <React.Fragment>
                        <SideNav
                            onSelect={(selected) => {
                                const to = '/' + selected;
                                if (location.pathname !== to) {
                                    history.push(to);
                                }
                            }}
                        ><SideNav.Toggle />
                            <SideNav.Nav defaultSelected="/">
                                {navItems}
                            </SideNav.Nav>
                        </SideNav>
                        <main className="h-100 ">
                            <Routes />
                        </main>
                    </React.Fragment>
                )}
                />
            </Router>
        );
    }
}


export default SideNavigation;