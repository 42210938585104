import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { Ort } from '../../../inputValidations';
import { EnumInput, TextInput } from '../aif';
import CheckBoxInput from '../aif/CheckBoxInput';
import { IAnschrift } from '../Person/types';

interface IAnschriftProps {
  anschrift?: IAnschrift
  onChange?(anschrift: IAnschrift): void
}

interface IAnschriftState {
  anschrift: IAnschrift
}

export default class Anschrift extends React.Component<IAnschriftProps, IAnschriftState> {
  constructor(props: IAnschriftProps) {
    super(props);

    this.state = {
      anschrift: props.anschrift || {
        hausnummer: '',
        ort: '',
        postleitzahl: '',
        strasse: '',
        land: 'DEUTSCHLAND'
      }
    }

    this.onChange = this.onChange.bind(this);
    this.notify = this.notify.bind(this);
  }

  notify() {
    if (this.props.onChange) {
      this.props.onChange(this.state.anschrift);
    }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    let copy = Object.assign(this.state.anschrift, { [ev.target.name]: ev.target.value });
    this.setState({ anschrift: copy }, this.notify);
  }

  public render() {
    return (
      <div>
        <Row>
          <Col>
            <EnumInput required label="Land" name="land" value={this.state.anschrift?.land} enum="land" onChange={this.onChange} />
          </Col>
          <Col>
            <CheckBoxInput label="Löschkennzeichen" name="loesch_kz" value={this.state.anschrift?.loesch_kz} onChange={this.onChange} />
          </Col>
          <Col>
            <CheckBoxInput label="Produkt Information" name="einwilligung_produkt_information" value={this.state.anschrift?.einwilligung_produkt_information} onChange={this.onChange} />
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <TextInput required label="Strasse" name='strasse' value={this.state.anschrift?.strasse} onChange={this.onChange} /></Col>
          <Col>
            <TextInput required label="Hausnummer" name='hausnummer' value={this.state.anschrift?.hausnummer} onChange={this.onChange} />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <TextInput required label="PLZ" name='postleitzahl' value={this.state.anschrift?.postleitzahl} onChange={this.onChange} />
          </Col>
          <Col>
            <Ort
              required
              label="Ort"
              name='ort'
              value={this.state.anschrift?.ort} onChange={this.onChange}
              plz={this.state.anschrift?.postleitzahl || ''}
            />
          </Col>
          <Col>
            <TextInput name="anschrift_zusatz" label="Zusatz" value={this.state.anschrift.anschrift_zusatz} onChange={this.onChange} />
          </Col>
          <Col>
            <CheckBoxInput label="Aktualisierung" name="einfache_aktualisierung" value={this.state.anschrift?.einfache_aktualisierung} onChange={this.onChange} />
          </Col>          
        </Row>
      </div>
    );
  }
}
