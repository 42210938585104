import * as React from 'react';
import { FaClipboardList, FaComments, FaFile, FaMoneyCheck } from 'react-icons/fa';
import { Button, ButtonGroup, Fade } from 'reactstrap';
import { ILastschrifteinzugantrag } from '../../types/Lastschrifteinzugantrag';

interface ITopNavProps {
    active?: string
    data: ILastschrifteinzugantrag
}

interface ITopNavState {

}

export default class TopNav extends React.Component<ITopNavProps, ITopNavState> {
    constructor(props: ITopNavProps) {
        super(props);
        this.state = {
        }
        this.isActive = this.isActive.bind(this);
    }

    isActive(o: string) {
        return o === window.location.hash || o === this.props.active;
    }

    public render() {
        let anfragen = this.props.data.nachrichten_verlauf?.filter(n => n.nachrichtentyp === 'Frage');
        let messageCount = anfragen?.length || 0;
        let messageBadge = messageCount > 0 ? <span className="badge badge-light">{messageCount}</span> : ''

        return (
            <div style={{ textAlign: "center" }}>
                <Fade>
                    <ButtonGroup>
                        <Button href="#LEDaten" color="info" active={this.isActive('#LEDaten')}><FaMoneyCheck /> <span className="d-none d-md-inline">LE Daten</span></Button>
                        <Button href="#Dokumente" color="info" active={this.isActive('#Dokumente')} ><FaFile /><span className="d-none d-md-inline"> Dokumente</span></Button>
                        <Button href="#Comments" color="info" active={this.isActive('#Comments')}><FaComments /> {messageBadge} <span className="d-none d-md-inline">Anfragen</span></Button>
                        <Button href="#Summary" color="light" active={this.isActive('#Summary')}><FaClipboardList /><span className="d-none d-md-inline"> Ihr Antrag</span></Button>
                    </ButtonGroup>
                </Fade>
            </div>
        );
    }
}
