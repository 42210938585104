import * as React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { ILastschrifteinzugantrag } from '../../../types/Lastschrifteinzugantrag';

export interface ILEAListItemProps {
    data: any
}

export default class LEAListItem extends React.Component<ILEAListItemProps> {
    public render() {
        const data: ILastschrifteinzugantrag = this.props.data.lastschrifteinzugantrag || this.props.data.data;
        const title = data.person_liste.map((p: any, index) => {
            if (index > 2) return '.';
            return <span key={index}>{index === 0 ? '' : ' ,'} {p.vorname} {p.nachname}</span>
        })
        const path = '/le/' + this.props.data._idbid;
        return (
            <div className="InboxListItem">
                <div>
                    <Badge className={data.antrag_art} > LE </Badge>
                    <Link to={path}  >{title}</Link>
                </div>
                <div className="text-right">
                    {this.props.data.datum + " "}
                    {data.status}</div>
            </div>
        );
    }
}
