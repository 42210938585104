import * as React from 'react';
import { IVertragsaenderung } from '.';
import { Row, Col } from 'reactstrap';
import { CurrencyInput } from '../../common/aif';
import BSTarifSelect from '../../common/BSTarivSelect';

interface IVertragsaenderungProps {
    data?: IVertragsaenderung
    onChange?(data: IVertragsaenderung): void
}

interface IVertragsaenderungState {
    data: IVertragsaenderung
}

export default class Vertragsaenderung extends React.Component<IVertragsaenderungProps, IVertragsaenderungState> {
    constructor(props: IVertragsaenderungProps) {
        super(props);
        this.state = {
            data: this.props.data || {}
        }

        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }
    
    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <div>
                <Row>
                    <Col><BSTarifSelect label="Tarif Neu" name="tarif_neu" value={this.state.data.tarif_neu} onChange={this.onChange} /></Col>
                    <Col><CurrencyInput label="Bausparsumme Neu" name="bausparsumme_neu" value={this.state.data.bausparsumme_neu} onChange={this.onChange} /></Col>
                    <Col><CurrencyInput label="Gebühr Tarifwechsel" name="gebuehr_tarifwechsel" value={this.state.data.gebuehr_tarifwechsel} onChange={this.onChange} /></Col>
                </Row>
                <Row>
                    <Col><CurrencyInput label="Gebühr Erhöhung" name="gebuehr_erhoehung" value={this.state.data.gebuehr_erhoehung} onChange={this.onChange} /> </Col>
                    <Col><CurrencyInput label="Teilbausparsumme" name="teilbausparsumme" value={this.state.data.teilbausparsumme} onChange={this.onChange} /></Col>
                    <Col><CurrencyInput label="Gebühr Teilung" name="gebuehr_teilung" value={this.state.data.gebuehr_teilung} onChange={this.onChange} /></Col>
                </Row>
            </div>
        );
    }
}
