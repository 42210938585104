import React, { Component } from "react";
import { Alert, Fade } from "reactstrap";
import "./Comments.css";
import Message from "./Message";
import CommentService from "./CommentService";
import { bsveService, actions } from "../../Bausparantrag/rxjs_service";
import JwtUtils from "../utils/JwtUtils";
import BackendService from "../../../services/BackendService";
import { ToastEvents } from "../../common/Toast/ToastEvents";
import { ITextNachricht } from "../../../types";

interface Props {
  /**
   * UID des Vorgangs
   */
  backendId?: string;
  messages?: ITextNachricht[];
}

interface State {
  messages: ITextNachricht[];
  [key: string]: any;
}

class Comments extends Component<Props, State> {
  subscription: any;

  constructor(props: Props) {
    super(props);
    this.state = { messages: props.messages || [] };
    this.addComment = this.addComment.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: Props, newState: State) {
    this.setState({
      messages: newProps.messages||[],
      backendId: newProps.backendId
    });
  }

  componentDidMount() {
    this.subscription = CommentService.getService().subscribe(
      (msg: ITextNachricht) => {
        if (!msg) {
          return;
        }
        bsveService.addObject({ anfragen: this.state.messages });
      }
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
    bsveService.runAction(actions.SAVE_PERSON);
  }

  handleChange(ev: any) {
    this.setState({ [ev.target.name]: ev.target.value }, () => {});
  }

  addComment(nachricht: string) {
    if(!this.props.backendId) return;
    
    let msgs: State = Object.assign({}, this.state);
    if (!Array.isArray(msgs.messages)) {
      msgs.messages = []; //
    }
    let response: ITextNachricht = {
      autor: JwtUtils.getAppUser(),
      nachricht: nachricht,
      erstellungsdatum: new Date(),
      nachrichtentyp: "Antwort",
      gelesen: true
    };

    BackendService.sendComment(this.props.backendId, response).then(
      (ok: any) => {
        console.log(ok);

        msgs.messages.push(response);
        this.setState(msgs, () => {
          CommentService.addComment(msgs);
        });
        ToastEvents.notify("Nachricht versendet!");
      },
      (err: any) => {
        ToastEvents.error("Fehler beim Versenden der Nachricht! (" + err.message+")");
      }
    );
  }

  render() {
    let msgList: any = (
      <Alert className="text-center" color="light">
        Noch keine Anfragen
      </Alert>
    );
    if (this.state.messages && this.state.messages.length > 0) {
      msgList = this.state.messages.map((m, index) => (
        <Message
          addResponse={this.addComment}
          message={m}
          key={index}
        ></Message>
      ));
    }

    return (
      <div className="mt-4">
        <Fade>
          <div className="messageList">{msgList}</div>
        </Fade>
      </div>
    );
  }
}

export default Comments;
