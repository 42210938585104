import * as React from 'react';
import { IVerbund } from '../Verbund/types';
import { IEnum, EnumService } from '../../../services';
import SelectInput from '../aif/SelectInput';
import { TPerson } from '../Person/types';

type TPVIndex = 'person_index' | 'verbund_index'

export interface IPVSelectReturn {
    type: TPVIndex
    value: number
}

interface MyOption {
    type: TPVIndex
    name: string,
    index: number,
    verbund?: string
    selected: boolean
}

interface IPVSelectProps {
    verbund_liste: IVerbund[]
    person_liste: TPerson[]
    label?: string
    person_index?: number
    verbund_index?: number
    onChange(ret: IPVSelectReturn): void
    required?: boolean
}

interface ISTate {
    enums?: IEnum
    optionList?: MyOption[]
    person_index?: number
    verbund_index?: number

}

export default class PVSelect extends React.Component<IPVSelectProps, ISTate> {

    constructor(props: IPVSelectProps) {
        super(props);
        this.state = { verbund_index: props.verbund_index, person_index: props.person_index };
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IPVSelectProps) {
        this.setState({ person_index: newProps.person_index, verbund_index: newProps.verbund_index }, () => {
            this.setState({ optionList: this.createOptionsList() });
        });
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok }, () => {
                this.setState({ optionList: this.createOptionsList() });
            });
        });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const index = Number.parseInt(ev.target.value);
        if (this.state.optionList !== undefined) {
            const selected = this.state.optionList[index];
            let ret: IPVSelectReturn = { type: selected.verbund ? "verbund_index" : "person_index", value: selected.index }
            this.props.onChange(ret);
        }
    }

    createOptionsList(): MyOption[] {
        const peronenListe = this.props.person_liste;
        const verbundListe = this.props.verbund_liste || [];
        let pList: MyOption[] = this.props.person_liste.map((p: any, i) => {
            const name = p.vorname + ' ' + p.nachname;
            return { name: name, type: 'person_index', index: i, selected: this.state.person_index === i };
        })

        let vList: MyOption[] = verbundListe.map((v, i) => {
            let name = v.verbund_art + ':'//this.state.enums?.verbund_art[v.verbund_art];

            name += v.person_index_liste.map((p, i) => {
                const person: any = peronenListe[p];
                if (!person) return '';
                return ' ' + person.vorname + ' ' + person.nachname;
            });

            const ret: MyOption = { name: name, type: 'verbund_index', index: i, verbund: v.verbund_art, selected: this.state.verbund_index === i }
            return ret;
        })
        return vList.concat(pList);
    }

    public render() {

        if (!this.state.enums) return <></>;
        const selected = this.state.optionList?.find((v, i) => v.selected);
        let ix = 0;
        if (this.state.optionList && selected) { ix = this.state.optionList?.indexOf(selected); }
        let options = this.state.optionList?.map((o, i) =>
            <option key={i} value={i}>{o.name}</option>)

        return (
            <SelectInput
                name="pvselect"
                value={ix + ''}
                onChange={this.onChange}
                label={this.props.label || "Person / Verbund"}
                required={this.props.required}
            >
                {options}
            </SelectInput>
        );
    }
}
