import * as React from 'react';
import { Person } from '../../common/Person';
import { Container, Fade, Button } from 'reactstrap';
import { IEnum, EnumService } from '../../../services';
import { FaUserFriends, FaUserMinus } from 'react-icons/fa';
import VerbundListe from '../Verbund';
import { IVerbund } from '../Verbund/types';
import VerbunsartenSelect from '../Verbund/VerbunsartenSelect';
import { PersonEventService } from './PersonEvents';
import { TPerson, IPrivatperson } from './types';

interface IPersonListeProps {
  verbund_liste: IVerbund[]
  //person_liste: PersonInt[]
  person_liste: [TPerson]
  onChange?(data: any): void
  onDelete?(index: number): void
}

interface IState {
  liste: TPerson[]
  verbund_liste: IVerbund[]
  currentPersonIndex: number
  enums?: IEnum
}

export default class PersonListe extends React.Component<IPersonListeProps, IState> {
  subscription: any;

  constructor(props: IPersonListeProps) {
    super(props);
    this.state = { liste: props.person_liste || [], currentPersonIndex: 0, verbund_liste: props.verbund_liste || [] }
    this.onPersonChange = this.onPersonChange.bind(this);
    this.addPerson = this.addPerson.bind(this);
    this.remPerson = this.remPerson.bind(this);
    this.select = this.select.bind(this);
    this.addToVerbund = this.addToVerbund.bind(this);
    this.onVerbundListeChange = this.onVerbundListeChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props: IPersonListeProps, newState: IState) {
    this.setState({
      liste: props.person_liste,
      currentPersonIndex: 0,
      verbund_liste: props.verbund_liste
    });
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then(ok => {
      this.setState({ enums: ok });
    })
  }

  _initPerson(): IPrivatperson {

    const person: IPrivatperson = {
      rolle: 'Antragssteller',
      "person_art": "Privat",
    }

    return person as IPrivatperson
  }

  addPerson() {
    let copy = JSON.parse(JSON.stringify(this.state.liste));
    const newPerson = this._initPerson();
    copy.push(newPerson);
    this.setState({ liste: copy, currentPersonIndex: copy.indexOf(newPerson) }, () => {
    });
  }

  remPerson() {
    let copy = JSON.parse(JSON.stringify(this.state.liste));
    copy.splice(this.state.currentPersonIndex, 1);
    PersonEventService.addEvent({ type: 'person_removed', index: this.state.currentPersonIndex });

    this.setState({ liste: copy, currentPersonIndex: 0 }, () => {
      if (this.props.onChange) {
        this.props.onChange({ person_liste: this.state.liste });
      }
    });
  }

  select(index: number) {
    this.setState({ currentPersonIndex: index });
  }

  onVerbundListeChange(verbundListe: IVerbund[]) {
    this.setState({ verbund_liste: verbundListe }, () => {
      if (this.props.onChange) {
        this.props.onChange({ verbund_liste: verbundListe });
      }
    })
  }

  addToVerbund(verbund: string) {
    let v_copy: IVerbund[] = [...this.state.verbund_liste]

    /*
      let xV = v_copy.find((v) => v.verbund_art === verbund);
      if (xV && xV.person_index_liste.indexOf(this.state.currentPersonIndex) === -1) {
        xV.person_index_liste.push(this.state.currentPersonIndex);
      }
      else {
        const newOne: IVerbund = { verbund_art: verbund, person_index_liste: [this.state.currentPersonIndex] }
        v_copy.push(newOne);
      }*/

    const newOne: IVerbund = { verbund_art: verbund, person_index_liste: [this.state.currentPersonIndex] }
    v_copy.push(newOne);

    this.setState({ verbund_liste: v_copy });
  }

  onPersonChange(person: TPerson) {
    const copy = this.state.liste;
    copy[this.state.currentPersonIndex] = person;
    this.setState({ liste: copy }, () => {
      if (this.props.onChange) {
        this.props.onChange({ person_liste: this.state.liste, verbund_liste: this.state.verbund_liste });
      }
    });
  }

  public render() {
    if (!this.state.liste) { return <></> }
    const curentPerson = this.state.liste[this.state.currentPersonIndex];
    const currentPersonIndex = <Person onChange={this.onPersonChange} data={curentPerson} />
    const remBtn = this.state.liste.length > 1 ? <Button onClick={this.remPerson} className="m-2" outline={true} color="danger"><FaUserMinus /> Entfernen</Button> : '';
    const verbuntBtn = this.state.liste.length > 1 ? <VerbunsartenSelect onChange={this.addToVerbund} /> : '';

    return (
      <Container className="mt-4">
        <Fade>
          <div className="mt-2 text-right">
            {verbuntBtn}
            {remBtn}
            <Button color="info" onClick={this.addPerson}><FaUserFriends /> weitere Vertragsinhaber</Button>
          </div>

          <VerbundListe onChange={this.onVerbundListeChange} selected={this.state.currentPersonIndex} onSelect={this.select} person_liste={this.state.liste} verbund_liste={this.state.verbund_liste} />
          {currentPersonIndex}
        </Fade>
      </Container >
    );
  }
}
