import * as React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
import { DateInput,CurrencyInput, EnumInput } from '../common/aif';
import CheckBoxInput from '../common/aif/CheckBoxInput';
import { IRate } from '../Finanzierungsantrag/Finanzierungsbaustein';

interface ISparRateProps {
    data: IRate
    index: number
    onChange?(index: number, data: IRate): void
    onRemove?(index: number): void
}

interface ISparRateState {
    data: IRate
}

export default class SparRate extends React.Component<ISparRateProps, ISparRateState> {
    constructor(props: ISparRateProps) {
        super(props);
        this.state = { data: this.props.data }
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        if (this.props.onRemove) { this.props.onRemove(this.props.index) }
    }

    UNSAFE_componentWillReceiveProps(newProps: ISparRateProps) {
        this.setState({ data: newProps.data })
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IRate = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.index, this.state.data);
            }
        })
    }

    public render() {
        return (
                <Row>
                    <Col><DateInput required label="Begin am" onChange={this.onChange} name="beginn_datum" value={this.state.data.beginn_datum} /></Col>
                    <Col><DateInput label="Ende am" onChange={this.onChange} name="ende_datum" value={this.state.data.ende_datum} /></Col>
                    <Col><CurrencyInput required label="Betrag" name="betrag" onChange={this.onChange} value={this.state.data.betrag} /> </Col>
                    <Col><EnumInput required label="Rhythmus" onChange={this.onChange} enum="rhythmus" name="rhythmus" value={this.state.data.rhythmus} /></Col>
                    <Col md="1"><CheckBoxInput label="VL" name="vl_merkmal" value={this.state.data.vl_merkmal} onChange={this.onChange} /></Col>
                    <Col md="1">
                        <FaTrashAlt className="removeIcon" style={{marginTop:'4.5rem'}} onClick={this.onRemove} title="Rate entfernen" />

                    </Col>
                </Row>
            
        );
    }
}
