import * as React from 'react';

interface IATubContentProps {
}

export default class ATubContent extends React.Component<IATubContentProps> {
    public render() {
        return (
            <div className="ATubContent">
                {this.props.children}
            </div>
        );
    }
}
