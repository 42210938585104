const STORE_KEY_NAME = 'iBaufiUser'

export default class JwtUtils{
   static getPayload(jwt_string){
        const splitter= jwt_string.split('.');
        if(splitter[1])
        {
            return JSON.parse(atob(splitter[1]));
        }
    } 

    static storeUser(user)
    {
        var u = JSON.stringify(user);
        localStorage.setItem(STORE_KEY_NAME, u);
    }

    static getAppUser(){
        var data=localStorage.getItem(STORE_KEY_NAME)||undefined;
        if(data)
        {
            return JSON.parse(data);
        }
        return null;
    }

    static logout(){
        let u= this.getAppUser();
        delete u.oauth;
        window.location.pathname='/';
    }
}