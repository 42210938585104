import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IListItem } from './types';
import FormatedDate from '../common/utils/FormatedDate';

interface IListItemProps {
    item: IListItem
    onClick?(item: IListItem): void
}

interface IListItemState {

}

export default class ListItem extends React.Component<IListItemProps, IListItemState> {
    constructor(props: IListItemProps) {
        super(props);
        this.state = {
        }
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        return this.props.onClick && this.props.onClick(this.props.item);
    }

    public render() {
        if (!this.props.item || !this.props.item.request) { return <></> }
        const persons = this.props.item.request.person_liste.map((p, i) => { return (p.vorname || '') + ' ' + p.nachname })
        const style = 'status_' + (this.props.item?.response?.status||'503')

        let title = this.props.item.request.antrag_art;
        if (this.props.item?.response?.data?.messages) { title += '\n' + this.props.item?.response?.data?.messages?.join('\n') || '' }
        else  { title += '\n' + this.props.item?.response?.data?.fault || this.props.item?.response }

        return (
            <Row className={style} onClick={this.onClick} title={title}>
                <Col className={this.props.item?.request.antrag_art} md="1">{this.props.item?.response?.data?.antrag_nr || ''}</Col>
                <Col md="2">{this.props.item?.request?.vermittlerdaten?.firma}</Col>
                <Col >{this.props.item?.request?.vermittlerdaten?.vorname} {this.props.item?.request?.vermittlerdaten?.nachname}</Col>
                <Col md="1">{this.props.item?.requestHeader["x-ospe-blz"]}</Col>
                <Col>{persons.join(', ')}</Col>
                <Col className="text-right text-secondary"><FormatedDate short time value={this.props.item._cat} /></Col>
            </Row>
        );
    }
}
