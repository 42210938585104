import * as React from 'react';

import './style.css';

interface IACollapseProps {
    label?: string
    expand?: boolean
    onExpand?(): void
    onClose?(): void
}

interface IACollapseState {
    expand: boolean
}

export default class ACollapse extends React.Component<IACollapseProps, IACollapseState> {

    constructor(props: IACollapseProps) {
        super(props);
        this.state = {
            expand: this.props.expand || false
        }
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ expand: !this.state.expand }, () => {
            if (this.state.expand && this.props.onExpand) {
                this.props.onExpand();
            }
            else if (!this.state.expand && this.props.onClose) {
                this.props.onClose();
            }
        });
    }

    public render() {
        const more = this.state.expand ? this.props.children : '';
        const label = this.props.label ? this.props.label : this.state.expand ? 'Weniger' : 'Mehr';

        const iconStyle = this.state.expand ? 'icon expand' : 'icon';
        const contentStyle = this.state.expand ? 'Content expand' : 'Content';

        return (
            <div className="ACollapse">
                <div className="Link" onClick={this.toggle}>
                    <div className={iconStyle}>&raquo;</div>
                    <div className="Label"> {label}</div>
                </div>
                <div className={contentStyle}>
                    {more}
                </div>
            </div>
        );
    }
}
