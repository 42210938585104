import * as React from 'react';
import {Col, Row} from 'reactstrap';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import EnumInput from '../../../common/aif/EnumInput';
import Energieausweis from './Energieausweis';
import {IImmoblienbewertung} from './types';

export interface IImmoblienbewertungProps {
    data?: IImmoblienbewertung
    onChange?(data: IImmoblienbewertung): void
}

interface IState {
    ib: IImmoblienbewertung
}

export default class Immoblienbewertung  extends React.Component<IImmoblienbewertungProps, IState> {
    constructor(props: IImmoblienbewertungProps) {
        super(props);
        this.state = { ib: this.props.data||{} }
        this.onChange = this.onChange.bind(this);
        this.onEnergietraegerChange = this.onEnergietraegerChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IImmoblienbewertung = Object.assign(this.state.ib,newVal);
        this.setState({ ib: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.ib);
            }
        });
    }

    onEnergietraegerChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const lastChar = ev.target.name.slice(-1);
        const index:number = +lastChar;
        let energietraeger = this.state.ib.energietraeger;
        if (!energietraeger){
            energietraeger = ["", ""];
        }
        energietraeger[index] = ev.target.value;
        const newState: IImmoblienbewertung = Object.assign(this.state.ib, {"energietraeger": energietraeger});
        this.setState({ ib: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.ib);
            }
        });
    }

    public render() {
        const ib = this.state.ib||{};
        return (
            <>
            <Row>
                <Col md="4">
                    <CurrencyInput label="Verkehrswert" name="verkehrswert" value={ib.verkehrswert} onChange={this.onChange} />
                    <CurrencyInput label="Beleihungswertvorschlag" name="beleihungswertvorschlag" value={ib.beleihungswertvorschlag} onChange={this.onChange} />
                    <CurrencyInput label="Energiebedarf vorher" name="energiebedarf_vorher" value={ib.energiebedarf_vorher} onChange={this.onChange} />
                    <CurrencyInput label="Energiebedarf nachher" name="energiebedarf_nachher" value={ib.energiebedarf_nachher} onChange={this.onChange} />
                    <CurrencyInput label="CO2 Emission" name="co2_emission" value={ib.co2_emission} onChange={this.onChange} />
                    <EnumInput name={"energietraeger0"} label={"Energieträger"} onChange={this.onEnergietraegerChange} value={ib.energietraeger ? ib.energietraeger[0] : ""} enum={"energietraeger"}/>
                    <EnumInput name={"energietraeger1"} label={"Energieträger"} onChange={this.onEnergietraegerChange} value={ib.energietraeger ? ib.energietraeger[1] : ""} enum={"energietraeger"}/>
                    <EnumInput label="Level Zertifikat" name="level_zertifikat" value={this.state.ib.level_zertifikat}
                               onChange={this.onChange} enum={"level_zertifikat"}/>
                </Col>
            </Row>
        <Energieausweis data={ib.energieausweis} onChange={(energieausweis) => {
            const newState: IImmoblienbewertung = Object.assign(this.state.ib, {"energieausweis": energieausweis});
            this.setState({ ib: newState }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.ib);
                }
            });}}/>
            </>
        );
    }
}
