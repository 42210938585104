import * as React from 'react';
import {Col, Row} from 'reactstrap';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import {IModernisierungskosten} from './types';

export interface IModernisierungskostenProps {
    data?: IModernisierungskosten
    onChange?(data: IModernisierungskosten): void
}

interface IState {
    ok: IModernisierungskosten
}

export default class Modernisierungskosten extends React.Component<IModernisierungskostenProps, IState> {
    constructor(props: IModernisierungskostenProps) {
        super(props);
        this.state = { ok: this.props.data||{} }
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IModernisierungskosten = Object.assign(this.state.ok,newVal);
        this.setState({ ok: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.ok);
            }
        });
    }

    public render() {
        const ok = this.state.ok||{};
        return (
            <Row>
                <Col md="4">
                    <CurrencyInput label="Dämmung Dach" name="daemmung_dach" value={ok.daemmung_dach} onChange={this.onChange} />
                    <CurrencyInput label="Fenster" name="fenster" value={ok.fenster} onChange={this.onChange} />
                    <CurrencyInput label="Dämmung Fassade" name="daemmung_fassade" value={ok.daemmung_fassade} onChange={this.onChange} />
                    <CurrencyInput label="Heizungstechnik" name="heizungstechnik" value={ok.heizungstechnik} onChange={this.onChange} />
                    <CurrencyInput label="Anbau/Umbau" name="anbau_umbau" value={ok.anbau_umbau} onChange={this.onChange} />
                    <CurrencyInput label="Sonstige Kosten" name="sonstige_kosten" value={ok.sonstige_kosten} onChange={this.onChange} />
                    <CurrencyInput label="Dämmung Decken" name="daemmung_decken" value={ok.daemmung_decken} onChange={this.onChange} />
                    <CurrencyInput label="Türen" name="tueren" value={ok.tueren} onChange={this.onChange} />
                    <CurrencyInput label="Ladestation Elektrofahrzeug" name="ladestation_elektrofahrzeug" value={ok.ladestation_elektrofahrzeug} onChange={this.onChange} />
                    <CurrencyInput label="Steuergeraete Gesamtenergieeffizienz " name="steuergeraete_gesamtenergieeffizienz" value={ok.steuergeraete_gesamtenergieeffizienz } onChange={this.onChange} />
                    <CurrencyInput label="Erneuerbare Energien" name="erneuerbare_energien" value={ok.erneuerbare_energien} onChange={this.onChange} />
                    <CurrencyInput label="Energetische Sanierung" name="energetische_sanierung" value={ok.energetische_sanierung} onChange={this.onChange} />
                </Col>
            </Row>
        );
    }
}
