import * as React from 'react';
import { IAbteilung3 } from '.';
import { Row, Col, Button } from 'reactstrap';
import TextInput from '../../../common/aif/TextInput';
import CheckBoxInput from '../../../common/aif/CheckBoxInput';
import DateInput from '../../../common/aif/DateInput';
import NumberInput from '../../../common/aif/NumberInput';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import { IAbtretung } from './types';
import Abtretung from './Abtretung';
import { FaTrashAlt, FaPlus } from 'react-icons/fa';
import ACollapse from '../../../common/aif/ACollapse';

interface IAbteilung3Props {
    data: IAbteilung3
    onRemove?(data: IAbteilung3): void
}

interface IAbteilung3State {
    data: IAbteilung3
    expand: boolean
}

export default class Abteilung3 extends React.Component<IAbteilung3Props, IAbteilung3State> {
    constructor(props: IAbteilung3Props) {
        super(props);
        this.state = {
            data: this.props.data,
            expand: false
        }
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
        this.addAbtretung = this.addAbtretung.bind(this);
        this.toggle = this.toggle.bind(this);
        this.removeAbtretung = this.removeAbtretung.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IAbteilung3Props) {
        this.setState({ data: newProps.data || [] });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: newVal });
    }

    remove() {
        if (this.props.onRemove) { this.props.onRemove(this.props.data); }
    }

    addAbtretung() {
        let al: IAbtretung[] = [...this.state.data.abtretung_liste] || [];
        let abtrettung: IAbtretung = {
            vereinbarung_art: 'Abtretung',
            eigenrecht:false,
            

        }
        al.push(abtrettung);
        const copy = Object.assign(this.state.data, { abtretung_liste: al });
        this.setState({ data: copy }, () => {

        });
    }

    removeAbtretung(item: IAbtretung) {
        const i = this.state.data.abtretung_liste.indexOf(item);
        if (i !== -1) {
            let copy = [...this.state.data.abtretung_liste];
            copy.splice(i, 1);
            const newstate = Object.assign(this.state.data, { abtretung_liste: copy });
            this.setState({ data: newstate });
        }
    }

    toggle() {
        this.setState({ expand: !this.state.expand })
    }

    public render() {
        const abtrettungsListe = this.state.data.abtretung_liste.map((a, i) => <Abtretung data={a} key={i} onRemove={this.removeAbtretung} />);
        return (
            <div className="Abteilung">
                <div className="text-right"><FaTrashAlt onClick={this.remove} className="removeIcon" /></div>

                <Row>
                    <Col md="4"><TextInput label="Berechtigter" name="berechtigter" value={this.state.data.berechtigter} onChange={this.onChange} /></Col>
                    <Col>
                        <TextInput label="Kommentar" name="kommentar" value={this.state.data.kommentar} onChange={this.onChange} />
                    </Col>
                    <Col md="2">
                        <DateInput label="Datum" onChange={this.onChange} name="datum" value={this.state.data.datum} />
                    </Col>
                </Row>
                <ACollapse>
                    <Row>
                        <Col>
                            <NumberInput label="Rang" onChange={this.onChange} name="rang" value={this.state.data.rang} />
                        </Col>
                        <Col><TextInput label="Laufende Nummer" name="laufende_nummer" value={this.state.data.laufende_nummer} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput required label="Neueintragung" name="neueintragung" value={this.state.data.neueintragung} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput required label="Eigenrecht" name="eigenrecht" value={this.state.data.eigenrecht} onChange={this.onChange} /></Col>
                        <Col md="2">
                            <CurrencyInput name="summe" label="Summe" value={this.state.data.summe} onChange={this.onChange} />
                        </Col>
                    </Row>
                    {abtrettungsListe}
                    <Row>
                        <Col className="text-right">
                            <Button size="sm" outline color="info" label="Abtrettung" onClick={this.addAbtretung}><FaPlus /> Abtrettung </Button>
                        </Col>
                    </Row>
                 
                </ACollapse>

            </div>
        );
    }
}

