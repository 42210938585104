import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { IEnum, EnumService } from "../services";

interface IProps {
  value: string;
  name: string;
  label?: string;
  onChange(ev: any): void;
}


interface IState {
  enums?: IEnum
}

class BeruflicheStellung extends Component<IProps, IState> {
  constructor(props: IProps) {

    super(props);
    this.state = {};
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then(ok => {
      this.setState({ enums: ok });
    });
  }
  render() {

    if(!this.state.enums){return <></>}

    const data =this.state.enums.berufliche_stellung;
    const optList=Object.keys(data).map((key,index)=>{

      return <option key={key} value={key}>{Object.values(data)[index]} </option>
    })

    return (
      <FormGroup>
        <Label>{this.props.label}</Label>
        <Input
          type="select"
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
        >
          {optList}
        </Input>
      </FormGroup>
    );
  }
}

export default BeruflicheStellung;
