import * as React from 'react';
import { FaFilter } from 'react-icons/fa';
import TextInput, { ITextInputProps } from '../TextInput';
import './style.css';

interface ISearchInputProps extends ITextInputProps {
    type?: 'search'
    onClear?(): void
}

interface ISearchInputState {
}

export default class SearchInput extends React.Component<ISearchInputProps, ISearchInputState> {
    constructor(props: ISearchInputProps) {
        super(props);
        this.clear = this.clear.bind(this);
    }
    clear() {

        return this.props.onClear && this.props.onClear();

    }
    public render() {

        let mainClass = 'AnimatedInput SearchInput'
        if (this.props.required && !this.props.value) {
            mainClass += ' invalid';
        }
        const value = this.props.value || ''
        return (
            <div className={mainClass}>

                <TextInput type={this.props.type || 'search'}
                    name={this.props.name}
                    value={value}
                    onChange={this.props.onChange}
                    id={this.props.id}
                    size={this.props.size}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    readOnly={this.props.readOnly}
                    label={this.props.label||'.. suche nach'}
                >
                    <FaFilter /></TextInput>
                <div className="Clear" onClick={this.clear}></div>
            </div>
        );
    }
}
