import * as React from 'react';
import { IVlDatenEinzelperson } from '../../types';
import SelectInput from '../../../common/aif/SelectInput';
import { Row, Col } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { TPerson } from '../../../common/Person/types';
import { CurrencyInput, DateInput, EnumInput } from '../../../common/aif';

interface IVlDatenEinzelpersonProps {
    data: IVlDatenEinzelperson
    person_liste: TPerson[]
    onChange?(data: IVlDatenEinzelperson): void
    onRemove?():void
    label: string
}

interface IVlDatenEinzelpersonState {
    data: IVlDatenEinzelperson
}

export default class VlDatenEinzelperson extends React.Component<IVlDatenEinzelpersonProps, IVlDatenEinzelpersonState> {
    constructor(props: IVlDatenEinzelpersonProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onChange = this.onChange.bind(this);
        this.notify=this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IVlDatenEinzelpersonProps) {
        this.setState({ data: newProps.data || [] });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: newVal }, this.notify);
    }

    
    public render() {
    const p_options = this.props.person_liste.map((p:any, i) => <option value={i} key={i}> {p.vorname || ''} {p.nachname} </option>);
        return (
            <div className="mt-4">
                <Row className="border-top">
                    <Col className="text-secondary">{this.props.label}</Col>
                    <Col className="text-right mt-1"><FaTrashAlt style={{ position: 'relative'}} className="removeIcon" onClick={this.props.onRemove} title="Entfernen" />
                   </Col>
                </Row>
                <Row>
                    <Col>
                        <SelectInput required label="Person" name="person_index" value={this.state.data.person_index+''} onChange={this.onChange}>
                            {p_options}
                        </SelectInput>
                    </Col>
                </Row>

                <Row>
                    <Col><CurrencyInput label="Einmalzahlung Betrag" name="einmalzahlung_betrag" value={this.state.data.einmalzahlung_betrag} onChange={this.onChange} /></Col>
                    <Col><DateInput label="Einmalzahlung Termin" name="einmalzahlung_termin" value={this.state.data.einmalzahlung_termin} onChange={this.onChange} /></Col>
                    <Col>
                        <CurrencyInput label="Regelzahlung Betrag" name="regelzahlung_betrag" value={this.state.data.regelzahlung_betrag} onChange={this.onChange} />
                    </Col>
                    <Col><EnumInput label="Rhythmus" enum="rhythmus" name="regelzahlung_rhythmus" value={this.state.data.regelzahlung_rhythmus} onChange={this.onChange} /></Col>
                </Row>
            </div>
        );
    }
}
