import * as React from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Button, Col, Row } from 'reactstrap';
import { CurrencyInput } from '../aif';
import { IRiesterdatenEhepartner } from './types';

interface IRiesterdatenEhepartnerProps {
    data?: IRiesterdatenEhepartner
    onChange?(data?: IRiesterdatenEhepartner): void
}

interface IRiesterdatenEhepartnerState {
    data?: IRiesterdatenEhepartner
}

export default class RiesterdatenEhepartner extends React.Component<IRiesterdatenEhepartnerProps, IRiesterdatenEhepartnerState> {
    constructor(props: IRiesterdatenEhepartnerProps) {
        super(props);
        this.state = {
            data: props.data
        }

        this.onChange = this.onChange.bind(this);
        this.addEhepartner = this.addEhepartner.bind(this);
        this.removeEhepartner = this.removeEhepartner.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    addEhepartner() {
        this.setState({ data: {} }, this.notify);
    }

    removeEhepartner() {
        this.setState({ data: undefined }, this.notify);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const val = ev.target.valueAsDate || ev.target.valueAsNumber || ev.target.value;
        const copy = Object.assign(this.state.data, { [ev.target.name]: val });
        this.setState({ data: copy }, this.notify);
    }

    public render() {

        if (!this.state.data) {

            return (<Row>
                <Col className="text-right">
                    <Button size="sm" outline color="info" onClick={this.addEhepartner}><FaPlus /> Ehepartner</Button>
                </Col>
            </Row>);
        }

        return (
            <>
                <Row>
                    <Col className="text-right">
                        <Button size="sm" outline color="info" onClick={this.removeEhepartner}><FaMinus /> Ehepartner</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CurrencyInput label="Ehepartner Bruttogehalt Vorjahr" name="bruttogehalt_vorjahr" value={this.state.data.bruttogehalt_vorjahr} onChange={this.onChange} />
                    </Col>
                </Row>
            </>
        );
    }
}
