import * as React from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { Col, Container, Fade, Row } from 'reactstrap';
import { ILastschrifteinzugdaten } from '../../../types/Lastschrifteinzugdaten';
import { AButton, ACollapse, TextInput, DateInput } from '../../common/aif';
import Anschrift from '../../common/Anschrift';
import { IAnschrift } from '../../common/Person/types';
import Einmaleinzug from '../../Lastschrifteinzugantrag/LE-Daten/Einmaleinzug';
import Regeleinzug from '../../Lastschrifteinzugantrag/LE-Daten/Regeleinzug';

interface ILastschrifteinzugProps {
    data?: ILastschrifteinzugdaten
    onChange?(led: any): void
}

interface IState {
    data: ILastschrifteinzugdaten
}

export default class Lastschrifteinzug extends React.Component<ILastschrifteinzugProps, IState> {
    constructor(props: ILastschrifteinzugProps) {
        super(props);
        this.state = {
            data: props.data || {

            }
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onAnschriftChange = this.onAnschriftChange.bind(this);
        this.addAK = this.addAK.bind(this);
        this.removeAK = this.removeAK.bind(this);
        this.addEinmaleinzug = this.addEinmaleinzug.bind(this);
        this.removeEinmaleinzug = this.removeEinmaleinzug.bind(this);
        this.addRegeleinzug = this.addRegeleinzug.bind(this);
        this.removeRegeleinzug = this.removeRegeleinzug.bind(this);
    }
    componentWillUnmount() {
        this.notify();
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange({ lastschrifteinzugdaten: this.state.data });
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onAnschriftChange(anschrift: IAnschrift) {
        const copy = Object.assign(this.state.data, { abweichender_kontoinhaber_anschrift: anschrift });
        this.setState({ data: copy }, this.notify);
    }

    addAK() {
        const copy = Object.assign(this.state.data, { abweichender_kontoinhaber_name: '', abweichender_kontoinhaber_anschrift: {} });
        this.setState({ data: copy });
    }

    removeAK() {
        const copy = Object.assign(this.state.data, { abweichender_kontoinhaber_name: undefined, abweichender_kontoinhaber_anschrift: undefined });
        this.setState({ data: copy });
    }

    addEinmaleinzug() {
        const copy = Object.assign(this.state.data, { einmaleinzug: { betrag: 0 } });
        this.setState({ data: copy });
    }

    removeEinmaleinzug() {
        const copy = Object.assign(this.state.data, { einmaleinzug: undefined });
        this.setState({ data: copy });
    }

    addRegeleinzug() {
        const copy = Object.assign(this.state.data, { regeleinzug: { rueckstand_einziehen: false } });
        this.setState({ data: copy });
    }

    removeRegeleinzug() {
        const copy = Object.assign(this.state.data, { regeleinzug: undefined });
        this.setState({ data: copy });
    }


    public render() {
        const data = this.state.data;
        return (
            <Container className="mt-4">
                <Fade>
                    <Row>
                        <Col>
                            <DateInput label="Datum Lastschriftmandat" name="unterschriftsdatum" value={this.state.data?.unterschriftsdatum} onChange={this.onChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextInput name="iban" label="IBAN" value={this.state.data?.iban} onChange={this.onChange} />
                        </Col>
                    </Row>
                    <ACollapse label="Abweichender Kontoinhaber" expand={this.state.data?.abweichender_kontoinhaber_name ? true : false} onExpand={this.addAK} onClose={this.removeAK}>
                        <TextInput name="abweichender_kontoinhaber_name" label="Abweichender Kontoinhaber" value={this.state.data?.abweichender_kontoinhaber_name} onChange={this.onChange} />
                        <Anschrift anschrift={this.state.data.abweichender_kontoinhaber_anschrift} onChange={this.onAnschriftChange} />
                    </ACollapse>
                    <Row className="border-top">
                        <Col>
                            <div className="text-secondary mt-4">Einmaleinzug</div>
                        </Col>
                        <Col className="text-right">

                            {!data?.einmaleinzug ?
                                <AButton outline onClick={this.addEinmaleinzug} color="info" size="sm" rounded><FaPlus /></AButton>
                                : <AButton outline onClick={this.removeEinmaleinzug} color="info" size="sm" rounded><FaTrash /></AButton>
                            }  </Col>
                    </Row>
                    {data?.einmaleinzug && <Row>
                        <Col>
                            <Einmaleinzug data={this.state.data.einmaleinzug} />
                        </Col>
                    </Row>
                    }
                    <Row className="border-top">
                        <Col>
                            <div className="text-secondary mt-4"> <div className="text-secondary mt-4">Regeleinzug</div></div>
                        </Col>
                        <Col className="text-right">
                            {
                                !data.regeleinzug ?
                                    <AButton outline onClick={this.addRegeleinzug} color="info" size="sm" rounded><FaPlus /></AButton>
                                    : <AButton outline onClick={this.removeRegeleinzug} color="info" size="sm" rounded><FaTrash /></AButton>
                            }
                        </Col>
                    </Row>
                    {data?.regeleinzug && <Row>
                        <Col>
                            <Regeleinzug data={data.regeleinzug} />
                        </Col>
                    </Row>}
                </Fade>
            </Container>
        );
    }
}
