import * as React from 'react';
import {FaRandom} from 'react-icons/fa';
import BackendService from '../../../../services/BackendService';
import {ToastEvents} from '../../Toast';
import './style.css';

interface IApigeeRoute {
  name: string,
  url: string
}

interface IApigeeRouteSelectProps {
  onChange?(url: string): void
  selectedRoute?: string
}

interface IApigeeRouteSelectState {
  routes: IApigeeRoute[]
}

const dev_route:IApigeeRoute={
  name:"Entwicklung",
  url:"https://en01-api.f-i-apim.intern/lbs-fibapi-env/lbs/olp/v6/"
}

export default class ApigeeRouteSelect extends React.Component<IApigeeRouteSelectProps, IApigeeRouteSelectState> {
  constructor(props: IApigeeRouteSelectProps) {
    super(props);
    this.state = {
      routes: []
    }

    BackendService.getRoutes().then(ok => {
      let keys = Object.keys(ok.data);
      let routes: IApigeeRoute[] = [dev_route]
      for (var i = 0; i < keys.length; i++) {
        const route = keys[i];
        const url = process.env.REACT_APP_API_HOST + route + "lbs-fibapi-env/lbs/olp/v6/";
        routes.push({ name: ok.data[route].name, url: url });
      }
      this.setState({ routes: routes });
    }, err => {
      ToastEvents.error('Hopla! Kann FI Routes nicht laden');
      console.error(err);
    });
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(url: string) {
   
    if (this.props.onChange) { this.props.onChange(url); }
  }

  public render() {

    const items = this.state.routes.map((r, i) => {
      const selected = this.props.selectedRoute?.indexOf(r.url)!== -1;
      const clazz = 'selected_' + selected
      return <li className={clazz} key={i} onClick={() => this.onSelect(r.url)}>{r.name}</li>
    })

    return (
      <div className="ApigeeRouteSelect">
        <div className="icon"><FaRandom /></div>
        <ul>
          {items}
        </ul>
      </div>
    );
  }
}
