import * as React from 'react';
import { SearchInput } from '../common/aif';
import { filterService } from './rxjs_service';

interface IInputSearchProps {
    q?: string
    onClear?(): void
}

interface IInputSearchState {
    q: string
}

export default class FilterInput extends React.Component<IInputSearchProps, IInputSearchState> {

    constructor(props: IInputSearchProps) {
        super(props);
        this.state = {
            q: props.q || ''
        }
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ q: ev.target.value }, () => filterService.addObject(this.state.q));
    }

    onClear() {
        this.setState({ q: '' }, () => filterService.addObject(this.state.q));
    }

    public render() {
        return (
            <SearchInput label="Suche nach ..." name="q" value={this.state.q} onChange={this.onChange} onClear={this.onClear} />
        );
    }
}
