import {FaHome, FaUsers, FaChartBar, FaCogs } from 'react-icons/fa';

const Links = [
    {
        title: 'Home',
        path: 'home',
        icon: FaHome,
    },
    {
        title: 'Beratung',
        path: 'start',
        icon: FaUsers,
    },
    {
        title: 'Dashboard',
        path: 'dashboard',
        icon: FaChartBar,
    },
    {
        title: 'Einstellungen',
        path: 'settings',
        icon: FaCogs,
    },
]

export default Links;