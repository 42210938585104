import React, { Component } from 'react';
import { FaSearch } from 'react-icons/fa';
import { Container, Spinner, Form, Input, InputGroupAddon, InputGroup, CardBody, Col, Row, Card, CardText, CardTitle, Button } from 'reactstrap';
import BackendService from '../../services/BackendService';
import ImageCarousel from './ImageCarousel';
import { ToastEvents } from '../common/Toast';
import PropTypes from 'prop-types';


class ImmoList extends Component {

    constructor(props) {
        super(props);
        this.state = { lbs: {}, search: 'Münster', loading: true }
        this.handleChange = this.handleChange.bind(this);
        this.search = this.search.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    nextPage() {
        const self = this;
        const url = '/search/real_estates.jsp?' + this.state.lbs.next;
        self.setState({ lbs: {}, loading: true });
        BackendService.proxyLbsRequest(url).then(
            ok => {
                self.setState({ lbs: ok.data });
            },
            err => {
                console.error(err)
                ToastEvents.error('Hopla! Fehler beim laden der Daten');
            }
        ).finally(() => {
            self.setState({ loading: false });
        });
    }
    search(ev) {
        if (ev) { ev.preventDefault(); }
        const self = this;
        self.setState({ lbs: {}, loading: true });
        const url = '/search/real_estates.jsp?marketing_usage_object_type=buy_residential_house&search=' + this.state.search + '&perimeter=30&sort_by=date_desc';
        BackendService.proxyLbsRequest(url).then(
            ok => {
                self.setState({ lbs: ok.data });
                console.log(ok.data);
            },
            err => {
                console.error(err)
                ToastEvents.error('Hopla! Fehler beim laden der Daten');
            }
        ).finally(() => {
            self.setState({ loading: false });
        });
    }
    onSelect(ev) {
        const immo=JSON.parse(ev.target.value);
        if(this.props.onSelect){this.props.onSelect(immo);}
    }
 
    handleChange(ev) {
        this.setState({ [ev.target.name]: ev.target.value });
    }

    UNSAFE_componentWillMount() {
        this.search();
    }

    render() {
        const exposes = this.state.lbs.exposes || [];
        const NextPage = (props) => {
            if (props.next) {
                return (<div className="mb-4 mt-4 d-flex justify-content-end">
                    <Button outline color="info" onClick={this.nextPage}>Weiter</Button>
                </div>)
            }
            return <></>
        }

        const Loading = (props) => {
            if (props.show) { return <div className="d-flex justify-content-center"><Spinner type="grow" color="primary" /></div> }
            return <></>
        };

        const ZumObject = (props) => {
            const url = 'https://www.lbs.de' + props.url;
            return (<a className="card-link" rel="noopener noreferrer" href={url} target="_blank">Zum Objekt</a>);
        }

        const Counter = (props) => {
            return <></>
        }

        let Currency = (props) => {
            if (props.value) {
                return Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(props.value);
            }
       }

        const items = exposes.map((e) => {
            return (

                <div key={e.sid}>
                    <Card className="mt-2">
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <ImageCarousel sid={e.sid} images={e.pictures}></ImageCarousel>
                                </Col>
                                <Col md={6}>
                                    <CardTitle> {e.title}</CardTitle>
                                    <CardText>{e.category} {e.zipcode},{e.city} </CardText>
                                    <CardText>
                                        Grundstück: {e.propertySize} m²<br />
                                        Wohnfläche: {e.livingSpace} m²<br />
                                        Zimmer: {e.rooms} </CardText>
                                    <CardText>Kaufpreis: <b><Currency value={e.price} /></b></CardText>
                                    <ZumObject url={e.detailLink} />

                                    <div className="mt-4 d-flex justify-content-end">
                                        <Button color="info" onClick={this.onSelect} value={JSON.stringify(e)}>Auswählen</Button>
                                    </div>
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </div>);

        });

        return (
            <Container className="mt-4">
                <Form onSubmit={this.search}>
                    <InputGroup className="mb-4">
                        <Input type="search" name="search" value={this.state.search} onChange={this.handleChange} placeholder="suche nach ..." />
                        <InputGroupAddon addonType="append" >
                            <Button color="info" onClick={this.search} >
                                <FaSearch />
                            </Button>
                        </InputGroupAddon >
                    </InputGroup>
                    <Counter value={this.state.lbs} />
                </Form>
                <Loading show={this.state.loading}></Loading>
                {items}
                <NextPage next={this.state.lbs.next} />
            </Container>
        );
    }
}

ImmoList.propTypes = {
    onSelect: PropTypes.func
};

export default ImmoList;