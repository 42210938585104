import * as React from 'react';
import { IVerbund } from '../../common/Verbund/types';
import { ILbsKredit, IRate, IVertragsaenderung, IWkrDaten, ISonderkondition } from './types';
import TarifService, { ITarifData } from '../../common/Settings/Tarife/TarifService';
import { IEnum, EnumService } from '../../../services';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import Rate from './Rate';
import { Row, Col } from 'reactstrap';
import {FaTrashAlt, FaPlus, FaCopy} from 'react-icons/fa';
import Riesterdaten, { IFM_ListeItem, IRiesterdaten } from '../../common/Riesterdaten';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import Vertragsaenderung from './Vertragsaenderung';
import BSTarifSelect from '../../common/BSTarivSelect';
import { TPerson } from '../../common/Person/types';
import { EnumInput, CurrencyInput, DateInput, PercentInput, ACollapse, NumberInput, TextInput, AButton } from '../../common/aif';
import WkrDaten from './WkrDaten';
import Sonderkondition from './Sonderkondition';

interface ILbsKreditProps {
    data: ILbsKredit
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    fm_liste: IFM_ListeItem[]
    index: number
    onChange?(index: number, data: ILbsKredit): void
    onRemove?(index: number): void
    onCopy?(data: any): void
}

interface ILbsKreditState {
    tarife?: ITarifData[]
    enums?: IEnum
    data: ILbsKredit
}

export default class LbsKredit extends React.Component<ILbsKreditProps, ILbsKreditState> {

    constructor(props: ILbsKreditProps) {
        super(props);

        this.state = {
            data: this.props.data
        }
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addRate = this.addRate.bind(this);
        this.removeRate = this.removeRate.bind(this);
        this.onRiesterDataChange = this.onRiesterDataChange.bind(this);
        this.notify = this.notify.bind(this);
        this.onVEChanges = this.onVEChanges.bind(this);
        this.onPVSelect = this.onPVSelect.bind(this);
        this.onWkrDataChange = this.onWkrDataChange.bind(this);
        this.onKreditmodellChange = this.onKreditmodellChange.bind(this);
        this.onSonderkonditionDataChange = this.onSonderkonditionDataChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        const tService = new TarifService()

        Promise.all([tService.getAll().then(ok => {
            this.setState({ tarife: ok });
        }, err => {
            console.error(err);
        }),
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })]);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.props.index, this.state.data);
        }
    }

    onRiesterDataChange(data: IRiesterdaten) {
        let copy = Object.assign(this.state.data, { riesterdaten: data });
        this.setState({ data: copy }, this.notify);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const value = (ev.target.value === '' ? null : ev.target.value);
        const newVal = { [ev.target.name]: value }
        const newState: ILbsKredit = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, this.notify)
    }

    remove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.index);
        }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    onPVSelect(obj: IPVSelectReturn) {
        let copy = Object.assign({}, this.state.data);
        if (obj.type === "verbund_index") {
            copy.antragssteller_verbund_index = obj.value;
            copy.antragssteller_person_index = undefined;
        }
        else {
            copy.antragssteller_verbund_index = undefined;
            copy.antragssteller_person_index = obj.value;
        }
        this.setState({ data: copy }, this.notify);
    }

    addRate() {
        const item: IRate = {
            beginn_datum: new Date(),
            betrag: 100,
            rhythmus: 'Monatlich'
        }
        const copy = [...this.state.data?.raten_liste || []];
        copy.push(item);
        this.setState({ data: Object.assign(this.state.data, { raten_liste: copy }) }, this.notify);
    }

    removeRate(data: IRate) {
        const index = this.state.data?.raten_liste.indexOf(data);
        if (index !== -1) {
            const copy = [...this.state.data?.raten_liste || []];
            copy.splice(index, 1);
            this.setState({ data: Object.assign(this.state.data, { raten_liste: copy }) }, this.notify);
        }
    }

    onVEChanges(data: IVertragsaenderung) {
        let copy = Object.assign(this.state.data, { vertrag_aenderung: data });
        this.setState({ data: copy }, this.notify);
    }

    onWkrDataChange(data: IWkrDaten) {
        const copy = Object.assign(this.state.data, { wkr_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    onSonderkonditionDataChange(data: ISonderkondition) {
        const copy = Object.assign(this.state.data, { sonderkondition: data });
        this.setState({ data: copy }, this.notify);
    }

    onKreditmodellChange(ev: React.ChangeEvent<HTMLInputElement>) {

        if (ev.target.value === '') {
            const copy = Object.assign(this.state.data, { kreditmodell: null });
            this.setState({ data: copy }, this.notify);

        }
        else {

            const copy = Object.assign(this.state.data, { kreditmodell: { bezeichnung: ev.target.value } });
            this.setState({ data: copy }, this.notify);
        }
    }

    public render() {
        let r_data = this.state.data?.raten_liste || [];
        const raten = r_data.map((r, i) => <Rate onRemove={()=>this.removeRate(r)} data={r} key={i} />)

        return (
            <div>
                <Row>
                    <Col>
                        <div className="h5 text-secondary">LBS Kredit </div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="DarlehenOhneBsv entfernen" />
                        <FaCopy onClick={this.copy} className="copyIcon" title="DarlehenOhneBsv kopieren" />
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PVSelect person_index={this.state.data.antragssteller_person_index}
                            verbund_index={this.state.data.antragssteller_verbund_index}
                            onChange={this.onPVSelect}
                            person_liste={this.props.person_liste}
                            verbund_liste={this.props.verbund_liste}
                            required
                        />
                    </Col>
                    <Col>
                        <EnumInput name="kreditgeber" label="Kreditgeber" onChange={this.onChange} value={this.state.data.kreditgeber} />
                    </Col>

                    <Col>
                        <EnumInput required label="Art" name="kredit_art" value={this.state.data.kredit_art} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BSTarifSelect name="tarif" required
                            onChange={this.onChange}
                            value={this.state.data.tarif}
                        />
                    </Col>
                    <Col>
                        <CurrencyInput required label="Bausparsumme" name="bausparsumme" value={this.state.data.bausparsumme} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <TextInput label="Vertragnummer" name="vertrag_nummer" value={this.state.data.vertrag_nummer} onChange={this.onChange} />
                    </Col>
                </Row>
                <ACollapse>
                    <Row>
                        <Col>
                            <DateInput required label="Termin Aszahlung" value={this.state.data.termin_auszahlung} name="termin_auszahlung" onChange={this.onChange} />
                        </Col>
                        <Col>
                            <NumberInput name="tranchen_nr" label="Tranchen Nr." value={this.state.data.tranchen_nr} onChange={this.onChange} />
                        </Col>

                        <Col>
                            <TextInput label="Kreditmodel" name="kreditmodell" value={this.state.data.kreditmodell?.bezeichnung} onChange={this.onKreditmodellChange} />
                        </Col>
                        <Col>
                            <PercentInput required label="Sollzins" name="sollzins" value={this.state.data.sollzins} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <EnumInput enum="berechnungsziel" label="Berechnungsziel" name="berechnungsziel_sparraten" value={this.state.data.berechnungsziel_sparraten} onChange={this.onChange} />
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <EnumInput required label="Zinsfestschreibungart" name="zinsfestschreibung_art" value={this.state.data.zinsfestschreibung_art} onChange={this.onChange} />
                        </Col>
                        <Col><NumberInput name="zinsfestschreibung_dauer" label="Dauer Zinsfestschreibung Monate" value={this.state.data.zinsfestschreibung_dauer} onChange={this.onChange} /></Col>
                        <Col>
                            <PercentInput name="folgezins" label="Folgezins" value={this.state.data.folgezins} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <PercentInput label="Disagio" name="disagio" value={this.state.data.disagio} onChange={this.onChange} />
                        </Col>


                    </Row>
                    <Row>
                        <Col><CurrencyInput required label="Kreditbetrag" name="kredit_betrag" value={this.state.data.kredit_betrag} onChange={this.onChange} /></Col>
                        <Col><CurrencyInput label="Gebühr" onChange={this.onChange} name="gebuehr" value={this.state.data.gebuehr} /> </Col>
                        <Col><EnumInput label="Gebührart" onChange={this.onChange} name="gebuehr_art" value={this.state.data.gebuehr_art} /> </Col>
                        <Col><DateInput label="Angebot gueltig bis" name="" value={this.state.data.angebot_gueltig_bis} onChange={this.onChange} /> </Col>
                    </Row>
                    <Row>
                        <Col><DateInput label="Bereithaltungszinsen ab" name="bereithaltungszinsen_ab" value={this.state.data.bereithaltungszinsen_ab} onChange={this.onChange} /> </Col>
                        <Col><PercentInput label="Bereithaltungszinsen" name="bereithaltungszinsen_zinssatz" value={this.state.data.bereithaltungszinsen_zinssatz} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput label="Versicherung" name="versicherung" value={this.state.data.versicherung} onChange={this.onChange} /></Col>
                        <Col><EnumInput label="Steuerart" name="steuer_art" value={this.state.data.steuer_art} onChange={this.onChange} /></Col>
                    </Row>


                    <Row className="mt-4 border-top">
                        <Col><label>Darlehen Raten</label></Col>
                        <Col className="text-right">   <AButton size="sm" onClick={this.addRate} color="info" outline rounded> <FaPlus /></AButton>
                        </Col>
                    </Row>
                    {raten}

                </ACollapse>
                <ACollapse label="Sonderkondition">
                    <Sonderkondition data={this.state.data.sonderkondition} onChange={this.onSonderkonditionDataChange} />
                </ACollapse>
                <ACollapse label="WKR Daten">
                    <WkrDaten data={this.state.data.wkr_daten} onChange={this.onWkrDataChange} />
                </ACollapse>
                <ACollapse label="Riesterdaten">
                    <Riesterdaten fm_liste={this.props.fm_liste} data={this.state.data.riesterdaten} person_liste={this.props.person_liste} onChange={this.onRiesterDataChange} />
                </ACollapse>
                <ACollapse label="Vetragsänderung">
                    <Vertragsaenderung data={this.state.data.vertrag_aenderung} onChange={this.onVEChanges} />
                </ACollapse>

            </div>
        );
    }
}
