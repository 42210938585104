import React, { Component } from 'react';
import { Label, Row, Col, FormGroup } from 'reactstrap'
import Slider from 'rc-slider';
import { toFormatedString } from './util';
import CurrencyInput from '../aif/CurrencyInput';


interface IMoneySliderProps {
  label?: string
  value: number
  name: string
  digits?: number
  onChange?(fieldname: string, value: number): void
  append?: string,
  min?: number,
  max?: number,
  step?: number
}

interface IState {
  value: number
  digits: number
  min: number,
  max: number,
  step: number
  mode: 1 | 2
}

export default class MoneySlider extends Component<IMoneySliderProps, IState> {
  constructor(props: IMoneySliderProps) {
    super(props);
    this.state = {
      value: props.value,
      digits: props.digits || 2,
      min: props.min || 0,
      max: props.max || 10,
      step: props.step || 1,
      mode: 1
    }

    this.slideHandler = this.slideHandler.bind(this);
    this.onChange = this.onChange.bind(this);
    this.switchMode = this.switchMode.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props: IMoneySliderProps) {
    this.setState({ value: props.value });
  }

  slideHandler(ev: number) {
    this.setState({ value: ev }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.props.name, this.state.value);
      }
    })
  }

  

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const v = Number.parseFloat(ev.target.value);
    this.setState({ value: v }, () => {
      if (this.props.onChange) {
        this.props.onChange(this.props.name, this.state.value);
      }
    });
  }

  switchMode() {
    const mode = this.state.mode === 1 ? 2 : 1;
    this.setState({ mode: mode });
  }

  render() {
    let formated_val = toFormatedString(this.state.value);
    if (this.props.append) {
      formated_val = this.state.value + ' ' + this.props.append;
    }
    const ModVal = this.state.mode === 1 ? <Label role="button" onClick={this.switchMode}>{formated_val}</Label> : <CurrencyInput label=' ' autoFocus onBlur={this.switchMode} onChange={this.onChange} name="ival" value={this.state.value} />;
    return (
      <FormGroup className="mt-5">
        <Row>
          <Col className="col-sm-7">
            <Label>{this.props.label}</Label>
          </Col>
          <Col className="text-right">
            {ModVal}
          </Col>
        </Row>

        <Slider
          min={this.state.min}
          max={this.state.max}
          step={this.state.step}
          value={this.state.value}
          onChange={this.slideHandler}
        ></Slider>
      </FormGroup>
    )
  }
}
