import React, { Component } from 'react';
import { EnumService } from '../../../services/EnumService';
import { ToastEvents } from "../Toast";
import { Fade, Container, Row, Col, FormText } from 'reactstrap'
import SettingsService, { ISettings, initSettings } from '../../../services/SettingsService';
import TarifService from './Tarife/TarifService';
import { Link } from 'react-router-dom';
import FormatedDate from '../utils/FormatedDate'
import TextInput from '../aif/TextInput';
import AButton from '../aif/AButton';
import { IVermittlerdaten } from '../../../types/Vermittlerdaten';
import BackendService from '../../../services/BackendService';
import ApigeeRouteSelect from './ApigeeRouteSelect';

interface ISettingsProps {

}

interface IState extends ISettings {
    enum_info?: Date
    tarifInfo: any
    reloading: boolean
}

class Settings extends Component<ISettingsProps, IState> {
    tarifService: TarifService;
    constructor(props: ISettingsProps) {
        super(props);
        this.state = {
            reloading: false,
            tarifInfo: {},
            api_url: initSettings.api_url,
            xhr_timeout: initSettings.xhr_timeout,
            'X-OSPE-BLZ': '',
            apikey: initSettings.apikey
        };
        this.handleChange = this.handleChange.bind(this);
        this.tarifService = new TarifService()
        this.loadEnums = this.loadEnums.bind(this);
        this.handleVermitlerChange = this.handleVermitlerChange.bind(this);
        this.relaodParams = this.relaodParams.bind(this);
        this.onApigeeRouteSelect = this.onApigeeRouteSelect.bind(this);
    }
    test() {
        BackendService.getDemo().then(ok => {
            console.log(ok);
        }, err => { console.error(err) })
    }
    async loadEnums() {
        return EnumService.loadEnums().then(enums => {
            console.log(enums);
            this.setState({ enum_info: enums.uat });

            ToastEvents.notify('Enums aktualisiert! 👍');
        }, err => {
            ToastEvents.error('Hopla! Kann Enums nicht aktualisieren 😕 !');
            console.error(err);
        });
    }

    relaodParams() {
        this.setState({ reloading: true })
        Promise.all([
            this.loadEnums(),
            TarifService.loadTarife().then(ok => {
                ToastEvents.notify('BSV Tarife aktualisiert! 👍');

                this.tarifService.getInfo().then(ok => {
                    this.setState({ tarifInfo: ok });
                });
            }, (err) => {
                ToastEvents.error('BSV Tarife konten nicht aktualisiert werden 😕 !');
            })]).finally(() => this.setState({ reloading: false }))
    };

    clearApp() {
        var DBDeleteRequest = window.indexedDB.deleteDatabase("iBaufi");
        DBDeleteRequest.onerror = function (event) {
            console.error("Error deleting database.");
        };

        DBDeleteRequest.onsuccess = function (event) {
            console.log("Database deleted successfully!");
        };

        this.relaodParams();
        window.location.reload();
    };

    UNSAFE_componentWillMount() {
        const s = SettingsService.getSettings();
        if (!s.vermittler) {
            s.vermittler = {
                firma: '',
                nachname: '',
                lbs_user_id: '',
                vorname: '',
            }
        }
        this.setState(s, () => {
        });
    }

    handleVermitlerChange(ev: React.ChangeEvent<HTMLInputElement>) {

        let copy: IVermittlerdaten = Object.assign(this.state?.vermittler, { [ev.target.name]: ev.target.value });
        this.setState({ vermittler: copy }, () => {
            SettingsService.saveSetting(this.state);
        });
    }

    handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state, { [ev.target.name]: ev.target.value });
        const fn = () => {
            SettingsService.saveSetting(this.state);
        }
        this.setState(copy, fn);

        if (ev.target.name === 'X-OSPE-BLZ' && ev.target.value.length === 8) {
            setTimeout(this.relaodParams, 500);
        }
    }
    onApigeeRouteSelect(url: string) {

        this.setState({ api_url: url }, () => {
            SettingsService.saveSetting(this.state);
            setTimeout(this.relaodParams, 500);
        });
    }

    render() {
        return (
            <Container className="mt-4" >
                <Fade>

                    <fieldset>
                        <legend>Vermittler</legend>
                        <Row>
                            <Col md="6">
                                <TextInput
                                    required
                                    label=" Vorname"
                                    onChange={this.handleVermitlerChange}
                                    name="vorname"
                                    value={this.state.vermittler?.vorname}
                                />
                            </Col>
                            <Col md="6">
                                <TextInput
                                    required
                                    label=" Nachname"
                                    onChange={this.handleVermitlerChange}
                                    name="nachname"
                                    value={this.state.vermittler?.nachname}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <TextInput label="Firma"
                                    required
                                    value={this.state.vermittler?.firma}
                                    name="firma"
                                    onChange={this.handleVermitlerChange}
                                />
                            </Col>
                            <Col md="6">
                                <TextInput label="LBS User"
                                    value={this.state.vermittler?.lbs_user_id}
                                    name="lbs_user_id"
                                    onChange={this.handleVermitlerChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <TextInput label="E-Mail Adresse"
                                    value={this.state.vermittler?.email}
                                    name="email"
                                    onChange={this.handleVermitlerChange}
                                />
                            </Col>
                            <Col md="6">
                                <TextInput label="Telefonnummer"
                                    value={this.state.vermittler?.telefon}
                                    name="telefon"
                                    onChange={this.handleVermitlerChange}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <fieldset>
                        <legend>API Settings</legend>
                        <Row>
                            <Col>
                                <TextInput
                                    required
                                    label="API URL"
                                    type="url"
                                    name="api_url"
                                    value={this.state.api_url}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col md="1">
                                <ApigeeRouteSelect  onChange={this.onApigeeRouteSelect} selectedRoute={this.state.api_url} />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <TextInput
                                    required
                                    label="API KEY"
                                    name="apikey"
                                    value={this.state["apikey"]}
                                    onChange={this.handleChange}
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    required
                                    label="XHR Timeout"
                                    type="number"
                                    name="xhr_timeout"
                                    value={this.state.xhr_timeout + ''}
                                    onChange={this.handleChange}
                                />
                            </Col>

                            <Col>
                                <TextInput
                                    required
                                    label="X-OSPE-BLZ"
                                    name="X-OSPE-BLZ"
                                    value={this.state["X-OSPE-BLZ"]}
                                    onChange={this.handleChange}
                                />
                            </Col>

                        </Row>
                    </fieldset>

                    <Row>
                        <Col >
                            <Link to='/tarife'>{this.state.tarifInfo.count} BSV Tarife</Link>
                            <FormText className=""> letzte Aktualisierung: <FormatedDate value={this.state.tarifInfo.last_sync} /> </FormText> </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>

                    <Row>
                        <Col >
                            <AButton onClick={() => this.loadEnums()} color="info" label="Load Enums"></AButton>
                            <FormText className=""> letzte Aktualisierung: <FormatedDate value={this.state.enum_info} /> </FormText> </Col>
                    </Row>
                    <Col>
                        <hr />
                    </Col>
                    <Row>
                        <Col>
                            <AButton onClick={() => this.clearApp()} color="danger" label="reset app"></AButton>
                            <FormText color="danger"> ACHTUNG! Daten gehen dabei verloren!</FormText>
                        </Col>

                    </Row>

                </Fade>
            </Container>
        );
    }
}

export default Settings;