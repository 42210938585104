import * as React from 'react';
import { IVerbund } from './types';
import { IEnum, EnumService } from '../../../services';
import './style.css'
import PersonCard from './PersonCard';
import Verbund from './Verbund';
import { PersonEventService } from '../Person/PersonEvents';
import { TPerson } from '../Person/types';

interface IVerbundProps {
    verbund_liste: IVerbund[]
    person_liste: TPerson[]
    onChange?(verbund_liste: IVerbund[]): void
    onSelect?(index: number): void
    selected?: number
}

interface IState {
    enums?: IEnum
}

export default class VerbundListe extends React.Component<IVerbundProps, IState> {

    constructor(props: IVerbundProps) {
        super(props);
        this.state = {}
        this.onVerbundChange = this.onVerbundChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => this.setState({ enums: ok }, () => {
        }))
    }
    _getVerbundPersonenListe(): number[] {
        let ret: number[] = [];
        if(!this.props.verbund_liste) {return ret};
       
        this.props.verbund_liste.map((v) => {
            for (let pl in v.person_index_liste) {
                ret.push(v.person_index_liste[pl]);
            }
            return ret
        })
        return ret
    }

    onVerbundChange(verbund: IVerbund, index: number): void {
        let copy = [...this.props.verbund_liste];
        if (verbund.person_index_liste.length === 0) {
            copy.splice(index, 1);
            PersonEventService.addEvent({type:'verbund_removed',index:index});
        }
        else {
            copy[index] = verbund;
        }

        if (this.props.onChange) {
            this.props.onChange(copy);
        }
    }

    public render() {
        if (!this.state.enums || !this.props.verbund_liste) { return <></> }
        const verbundPersonen = this._getVerbundPersonenListe();
        const persons = this.props.person_liste.map((p, i) => verbundPersonen.indexOf(i) === -1 ? <PersonCard key={i} person={p} index={i} onSelect={this.props.onSelect} selected={this.props.selected} /> : '')
        //const persons = this.props.person_liste.map((p, i) => true ? <PersonCard key={i} person={p} index={i} onSelect={this.props.onSelect} selected={this.props.selected} /> : '')
        const verbund = this.props.verbund_liste.map((v, index) => {
            return (<Verbund
                onSelect={this.props.onSelect}
                onChange={this.onVerbundChange}
                selected={this.props.selected}
                index={index}
                verbund={v}
                person_liste={this.props.person_liste}
                key={index} />
            );
        });

        return (
            <div>
                <div className="verbundliste">
                    {this.props.person_liste.length>1?persons:''}
                </div>
                {verbund}
            </div>
        );
    }
}
