import * as React from 'react';
import { ILbsDarlehenOhneBsv, IRate, IWkrDaten, ISonderkondition } from '.';
import { IVerbund } from '../../common/Verbund/types';
import TarifService, { ITarifData } from '../../common/Settings/Tarife/TarifService';
import { IEnum, EnumService } from '../../../services';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import { Row, Col, Button } from 'reactstrap';
import {FaTrashAlt, FaPlus, FaCopy} from 'react-icons/fa';
import Rate from './Rate';
import { TPerson } from '../../common/Person/types';
import { CurrencyInput, DateInput, PercentInput, ACollapse, EnumInput, NumberInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import WkrDaten from './WkrDaten';
import Sonderkondition from './Sonderkondition';

interface ILbsDarlehenOhneBsvProps {
    data: ILbsDarlehenOhneBsv
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    index: number
    onChange?(index: number, data: ILbsDarlehenOhneBsv): void
    onRemove?(index: number): void
    onCopy?(data: any): void
}

interface ILbsDarlehenOhneBsvState {
    tarife?: ITarifData[]
    enums?: IEnum
    data: ILbsDarlehenOhneBsv
}

export default class LbsDarlehenOhneBsv extends React.Component<ILbsDarlehenOhneBsvProps, ILbsDarlehenOhneBsvState> {
    constructor(props: ILbsDarlehenOhneBsvProps) {
        super(props);

        this.state = {
            data: this.props.data
        }
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.onChange = this.onChange.bind(this);
        this.addRate = this.addRate.bind(this);
        this.removeRate = this.removeRate.bind(this);
        this.notify = this.notify.bind(this);
        this.onPVSelect = this.onPVSelect.bind(this);
        this.onWkrDataChange = this.onWkrDataChange.bind(this);
        this.onSonderkonditionDataChange = this.onSonderkonditionDataChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        const tService = new TarifService()

        Promise.all([tService.getAll().then(ok => {
            this.setState({ tarife: ok });
        }, err => {
            console.error(err);
        }),
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })]);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.props.index, this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: ILbsDarlehenOhneBsv = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.index, this.state.data);
            }
        })
    }
    remove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.index);
        }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    onPVSelect(obj: IPVSelectReturn) {
        console.log(obj);
        let copy = Object.assign({}, this.state.data);
        if (obj.type === "verbund_index") {
            copy.antragssteller_verbund_index = obj.value;
            copy.antragssteller_person_index = undefined;
        }
        else {
            copy.antragssteller_verbund_index = undefined;
            copy.antragssteller_person_index = obj.value;
        }
        this.setState({ data: copy }, this.notify);
    }

    addRate() {
        const item: IRate = {
            beginn_datum: new Date(),
            betrag: 100,
            rhythmus: 'Monatlich'
        }
        const copy = [...this.state.data?.raten_liste || []];
        copy.push(item);
        this.setState({ data: Object.assign(this.state.data, { raten_liste: copy }) });
    }

    removeRate(data: IRate) {
        const index = (this.state.data?.raten_liste ||[]).indexOf(data);
        if (index !== -1) {
            const copy = [...this.state.data?.raten_liste || []];
            copy.splice(index, 1);
            this.setState({ data: Object.assign(this.state.data, { raten_liste: copy }) });
        }
    }

    onWkrDataChange(data: IWkrDaten) {
        const copy = Object.assign(this.state.data, { wkr_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    onSonderkonditionDataChange(data: ISonderkondition) {
        const copy = Object.assign(this.state.data, { sonderkondition: data });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        let r_data = this.state.data?.raten_liste || [];
        const raten = r_data.map((r, i) => <Rate onRemove={()=>this.removeRate(r)} data={r} key={i} />)


        return (
            <div>
                <Row>
                    <Col>
                        <div className="h5 text-secondary">LBS Darlehen ohne Bsv </div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="LbsDarlehenOhneBsv entfernen" />
                        <FaCopy onClick={this.copy} className="copyIcon" title="LbsDarlehenOhneBsv kopieren" />
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PVSelect person_index={this.state.data.antragssteller_person_index}
                            verbund_index={this.state.data.antragssteller_verbund_index}
                            onChange={this.onPVSelect}
                            person_liste={this.props.person_liste}
                            verbund_liste={this.props.verbund_liste}
                            required />
                    </Col>
                    <Col><EnumInput name="kreditgeber" label="Kreditgeber" onChange={this.onChange} value={this.state.data.kreditgeber} /></Col>
                    <Col><CurrencyInput required label="Darlehen Betrag" value={this.state.data.darlehen_betrag} name="darlehen_betrag" onChange={this.onChange} /></Col>
                </Row>
                <Row>
                    <Col><DateInput required label="Termin Aszahlung" value={this.state.data.termin_auszahlung} name="termin_auszahlung" onChange={this.onChange} /></Col>
                    <Col> <PercentInput required label="Sollzins" name="sollzins" value={this.state.data.sollzins} onChange={this.onChange} /> </Col>
                    <Col><NumberInput name="tranchen_nr" label="Tranchen Nr." value={this.state.data.tranchen_nr} onChange={this.onChange} /></Col>
                    <Col><PercentInput name="tilgung_satz" label="Tilgungssatz" value={this.state.data.tilgung_satz} onChange={this.onChange} /></Col>

                </Row>
                <ACollapse>
                    <Row>
                        <Col>
                            <NumberInput name="zinsfestschreibung_dauer" label="Zinsfestschreibung Monate" value={this.state.data.zinsfestschreibung_dauer} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <PercentInput name="folgezins" label="Folgezins" value={this.state.data.folgezins} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <NumberInput label="Tilgungsfreie Monate" name="anzahl_monate_tilgungsfrei" value={this.state.data.anzahl_monate_tilgungsfrei} onChange={this.onChange} />
                        </Col>
                        <Col>
                            <DateInput label="Angebot gültig bis" name="angebot_gueltig_bis" value={this.state.data.angebot_gueltig_bis} onChange={this.onChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col><DateInput label="Bereithaltungszinsen ab" name="bereithaltungszinsen_ab" value={this.state.data.bereithaltungszinsen_ab} onChange={this.onChange} /> </Col>
                        <Col><PercentInput label="Bereithaltungszinsen" name="bereithaltungszinsen_zinssatz" value={this.state.data.bereithaltungszinsen_zinssatz} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput label="Versicherung" name="versicherung" value={this.state.data.versicherung} onChange={this.onChange} /></Col>
                    </Row>
                    <fieldset className="mt-4">
                        <Row>
                            <Col>Darlehen Raten</Col>
                            <Col className="text-right">   <Button size="sm" onClick={this.addRate} color="info" outline> <FaPlus /> Rate</Button>
                            </Col>
                        </Row>
                        {raten}
                    </fieldset>
                </ACollapse>

                <ACollapse label="Sonderkondition">
                    <Sonderkondition data={this.state.data.sonderkondition} onChange={this.onSonderkonditionDataChange} />
                </ACollapse>
                <ACollapse label="WKR Daten">
                    <WkrDaten data={this.state.data.wkr_daten} onChange={this.onWkrDataChange} />
                </ACollapse>

            </div>
        );
    }
}
