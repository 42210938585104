import React, { Component } from "react";
import { TPerson } from "./types";
import Privatperson from "./Privatperson";
import Geschaeftsperson from "./Geschaeftsperson";
import JuristischePerson from "./JuristischePerson";

interface IProps {
  data: any
  onChange(person: TPerson): void
}

interface IState {
  person: TPerson
}

export class Person extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { person: props.data };
    this.getPerson = this.getPerson.bind(this);
    this.notify = this.notify.bind(this);
    this.onPersonChange = this.onPersonChange.bind(this);
  }

  notify() {
    if (this.props.onChange) { this.props.onChange(this.state.person) }
  }

  UNSAFE_componentWillReceiveProps(newPros: IProps) {
    this.setState({ person: newPros.data });
  }

  onPersonChange(person: TPerson) { this.notify() }

  getPerson() {
    let person: any = this.state.person
    if (person.person_art === 'Privat') {
      return (<Privatperson person={person} onChange={this.onPersonChange} />)
    }
    else  if (person.person_art === 'Geschäftlich') {
      return (<Geschaeftsperson person={person} onChange={this.onPersonChange} />)
    }
    else  if (person.person_art === 'Juristisch') {
      return (<JuristischePerson person={person} onChange={this.onPersonChange} />)
    }
    return <></>
  }
  render() {
    return this.getPerson();
  }
}
