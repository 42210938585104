import * as React from 'react';
import {ILbsBauspardarlehen, IRate, IVertragsaenderung, IWkrDaten} from './types';
import { Row, Col, Button } from 'reactstrap';
import TarifService, { ITarifData } from '../../common/Settings/Tarife/TarifService';
import Rate from './Rate';
import {FaCopy, FaPlus, FaTrashAlt} from 'react-icons/fa';
import { IEnum, EnumService } from '../../../services';
import { IVerbund } from '../../common/Verbund/types';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import Riesterdaten, { IFM_ListeItem, IRiesterdaten } from '../../common/Riesterdaten';
import BSTarifSelect from '../../common/BSTarivSelect';
import { TPerson } from '../../common/Person/types';
import { CurrencyInput, EnumInput, DateInput, ACollapse, TextInput, PercentInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
import WkrDaten from './WkrDaten';
import Vertragsaenderung from './Vertragsaenderung';

export interface ILbsBauspardarlehenProps {
    data: ILbsBauspardarlehen
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    fm_liste: IFM_ListeItem[]
    index: number
    onChange?(index: number, data: ILbsBauspardarlehen): void
    onRemove?(index: number): void
    onCopy?(data: any): void
}

interface ILbsBauspardarlehenState {
    data: ILbsBauspardarlehen
    tarife?: ITarifData[]
    enums?: IEnum
}


export default class LbsBauspardarlehen extends React.Component<ILbsBauspardarlehenProps, ILbsBauspardarlehenState> {

    constructor(props: ILbsBauspardarlehenProps) {
        super(props);
        this.state = {
            data: props.data
        }
        this.onChange = this.onChange.bind(this);
        this.addSparRate = this.addSparRate.bind(this);
        this.removeSparRate = this.removeSparRate.bind(this);
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.onPVSelect = this.onPVSelect.bind(this);
        this.notify = this.notify.bind(this);
        this.addDarlehenRate = this.addDarlehenRate.bind(this);
        this.removeDarlehenRate = this.removeDarlehenRate.bind(this);
        this.onRiesterDataChange = this.onRiesterDataChange.bind(this);
        this.onVEChanges = this.onVEChanges.bind(this);
        this.onWkrDataChange = this.onWkrDataChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        const tService = new TarifService()
        Promise.all([tService.getAll().then(ok => {
            this.setState({ tarife: ok });
        }, err => {
            console.error(err);
        }),
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })]);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.props.index, this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const value = (ev.target.value === '' ? null : ev.target.value);
        const newVal = { [ev.target.name]: value }
        const newState: ILbsBauspardarlehen = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, this.notify)
    }

    removeSparRate(data: IRate) {
        const index = this.state.data.spar_raten_liste.indexOf(data);
        if (index !== -1) {
            const copy = [...this.state.data.spar_raten_liste];
            copy.splice(index, 1);
            this.setState({ data: Object.assign(this.state.data, { spar_raten_liste: copy }) }, this.notify);
        }
    }

    removeDarlehenRate(data: IRate) {
        const index = this.state.data.darlehen_raten_liste.indexOf(data);
     
        if (index !== -1) {
            const copy = [...this.state.data.darlehen_raten_liste];
            copy.splice(index, 1);
            this.setState({ data: Object.assign(this.state.data, { darlehen_raten_liste: copy }) }, this.notify);
        }
    }

    addSparRate() {
        const rhythmus = "Monatlich"//Object.keys(this.state.enums?.raten_rhythmus)[0] || '';
        const initRate: IRate = {
            beginn_datum: new Date(),
            betrag: 100,
            rhythmus: rhythmus
        }
        const copy = [...this.state.data.spar_raten_liste];
        copy.push(initRate);
        this.setState({ data: Object.assign(this.state.data, { spar_raten_liste: copy }) }, this.notify);
    }

    addDarlehenRate() {
        const rhythmus = "Monatlich"//Object.keys(this.state.enums?.raten_rhythmus)[0] || '';
        const initRate: IRate = {
            beginn_datum: new Date(),
            betrag: 100,
            rhythmus: rhythmus
        }

        const copy = [...this.state.data.darlehen_raten_liste];
        copy.push(initRate);
        this.setState({ data: Object.assign(this.state.data, { darlehen_raten_liste: copy }) }, this.notify);
    }

    remove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.index);
        }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    onPVSelect(obj: IPVSelectReturn) {
        let copy = Object.assign({}, this.state.data);
        if (obj.type === "verbund_index") {
            copy.antragssteller_verbund_index = obj.value;
            copy.antragssteller_person_index = undefined;
        }
        else {
            copy.antragssteller_verbund_index = undefined;
            copy.antragssteller_person_index = obj.value;
        }
        this.setState({ data: copy }, this.notify);
    }

    onRiesterDataChange(data: IRiesterdaten) {
        let copy = Object.assign(this.state.data, { riesterdaten: data });
        this.setState({ data: copy }, this.notify);
    }

    onVEChanges(data: IVertragsaenderung) {
        let copy = Object.assign(this.state.data, { vertrag_aenderung: data });
        this.setState({ data: copy }, this.notify);
    }

    onWkrDataChange(data: IWkrDaten) {
        const copy = Object.assign(this.state.data, { wkr_daten: data });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        const raten = (this.state.data.spar_raten_liste || []).map((r, i) => <Rate onRemove={()=>this.removeSparRate(r)} data={r} key={i} />)
        const darlehen_raten = (this.state.data.darlehen_raten_liste || []).map((r, i) => <Rate onRemove={()=>this.removeDarlehenRate(r)} data={r} key={i} />)

        return (
            <div>
                <Row>
                    <Col>
                        <div className="h5 text-secondary">LBS Bauspardarlehen </div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="LbsAnnudarlehen entfernen" />
                        <FaCopy onClick={this.copy} className="copyIcon" title="LbsAnnudarlehen kopieren" />
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PVSelect person_index={this.state.data.antragssteller_person_index}
                            verbund_index={this.state.data.antragssteller_verbund_index}
                            onChange={this.onPVSelect}
                            person_liste={this.props.person_liste}
                            verbund_liste={this.props.verbund_liste}
                            required
                        />
                    </Col>
                    <Col>
                        <EnumInput name="kreditgeber" label="Kreditgeber" onChange={this.onChange} value={this.state.data.kreditgeber} />
                    </Col>

                    <Col>
                        <CurrencyInput label="Darlehen Betrag" name="darlehen_betrag" value={this.state.data.darlehen_betrag} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col><DateInput onChange={this.onChange} label="Auszahlungstermin" name="termin_auszahlung" value={this.state.data.termin_auszahlung} /></Col>
                    <Col><BSTarifSelect label="Tarif" name="tarif" required onChange={this.onChange} value={this.state.data.tarif} /></Col>
                    <Col><CurrencyInput required label="Bausparsumme" name="bausparsumme" value={this.state.data.bausparsumme} onChange={this.onChange} /></Col>
                    <Col>
                        <EnumInput enum="berechnungsziel" label="Berechnungsziel" name="berechnungsziel_sparraten" value={this.state.data.berechnungsziel_sparraten} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4"></Col>
                </Row>
                <ACollapse>

                    <Row>
                        <Col><TextInput label="Vertrag Nr." name="vertrag_nummer" value={this.state.data.vertrag_nummer} onChange={this.onChange} /></Col>
                        <Col><DateInput label="Termin Zuteilung" name="termin_zuteilung" value={this.state.data.termin_zuteilung} onChange={this.onChange} /> </Col>
                        <Col><CurrencyInput label="Gebühr" name="gebuehr" value={this.state.data.gebuehr} onChange={this.onChange} /> </Col>
                        <Col><EnumInput label="Gebührart" name="gebuehr_art" value={this.state.data.gebuehr_art} onChange={this.onChange} /></Col>
                    </Row>
                    <Row>
                        <Col><DateInput label="Angebot gültig bis" name="angebot_gueltig_bis" value={this.state.data.angebot_gueltig_bis} onChange={this.onChange} /></Col>
                        <Col><DateInput label="Bereithaltungszinsen ab" name="bereithaltungszinsen_ab" value={this.state.data.bereithaltungszinsen_ab} onChange={this.onChange} /></Col>
                        <Col><PercentInput label="Bereithaltungszinsen" name="bereithaltungszinsen_zinssatz" value={this.state.data.bereithaltungszinsen_zinssatz} onChange={this.onChange} /></Col>
                        <Col><CheckBoxInput label="Versicherung" name="versicherung" value={this.state.data.versicherung} onChange={this.onChange} /></Col>
                        <Col><EnumInput label="Steuerart" name="steuer_art" value={this.state.data.steuer_art} onChange={this.onChange} /></Col>

                    </Row>
                    <Row>
                        <Col> </Col>
                    </Row>
                    <fieldset className="mt-4">
                        <Row>
                            <Col>Sparrraten</Col>
                            <Col className="text-right">  <Button size="sm" onClick={this.addSparRate} color="info" outline> <FaPlus /> Rate</Button>
                            </Col>
                        </Row>
                        {raten}
                    </fieldset>
                    <Row>
                        <Col> </Col>
                    </Row>
                    <fieldset>
                        <Row>
                            <Col>Darlehen Raten</Col>
                            <Col className="text-right">  <Button size="sm" onClick={this.addDarlehenRate} color="info" outline> <FaPlus /> Rate</Button>
                            </Col>
                        </Row>
                        {darlehen_raten}
                    </fieldset>

                </ACollapse>
                <ACollapse label="WKR Daten">
                    <WkrDaten data={this.state.data.wkr_daten} onChange={this.onWkrDataChange} />
                </ACollapse>

                <ACollapse label="Riesterdaten">
                    <Riesterdaten data={this.state.data.riesterdaten} fm_liste={this.props.fm_liste} person_liste={this.props.person_liste} onChange={this.onRiesterDataChange} />
                </ACollapse>
                <ACollapse label="Vetragsänderung">
                    <Vertragsaenderung data={this.state.data.vertrag_aenderung} onChange={this.onVEChanges} />
                </ACollapse>

            </div>
        );
    }
}
