import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Keycloak from 'keycloak-js'
import { Welcome } from './Welcome';

export * from './Loading';

const keycloakConfig: Keycloak.KeycloakConfig = {
    "realm": "FinanzInformatik",
    "url": "https://keycloak.h2938295.stratoserver.net/auth",
    "clientId": "iBaufi",
}

export const keycloak = Keycloak(keycloakConfig);

export const Secure = ({ children }: any) => {
    const { initialized, keycloak } = useKeycloak();
 
    if ((initialized && keycloak.authenticated)) {
        return (children);
    }

    return (<Welcome />);
}