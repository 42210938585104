import * as React from 'react';
import SelectInput from '../SelectInput';

export interface IBezugsObjektInputProps {
    name: string
    key: string
    value?: number
    label?: string

    bezugsobjektArt?: string
    personListe?: IBezugsobjekt[]
    finanzierungsmittelListe?: IBezugsobjekt[]
    immobilieListe?: IBezugsobjekt[]
    sonstigerFinanzierungsgegenstandListe?: IBezugsobjekt[]
    sicherheitListe?: IBezugsobjekt[]


    onChange(ev: React.ChangeEvent<HTMLInputElement>): void
}

interface IBezugsObjektInputState {
    bezugsobjekt?: IBezugsobjekt

    bezugsobjektArt?: string
    personListe: IBezugsobjekt[]
    finanzierungsmittelListe: IBezugsobjekt[]
    immobilieListe: IBezugsobjekt[]
    sonstigerFinanzierungsgegenstandListe: IBezugsobjekt[]
    sicherheitListe: IBezugsobjekt[]
}

export interface IBezugsobjekt {
    label: string
    index: number
}

export default class BezugsObjektInput extends React.Component<IBezugsObjektInputProps, IBezugsObjektInputState> {
    constructor(props: IBezugsObjektInputProps) {
        super(props);
        this.state = {
            bezugsobjektArt: props.bezugsobjektArt || 'Vorgang',
            personListe: props.personListe || [],
            finanzierungsmittelListe: props.finanzierungsmittelListe || [],
            immobilieListe: props.immobilieListe || [],
            sonstigerFinanzierungsgegenstandListe: props.sonstigerFinanzierungsgegenstandListe || [],
            sicherheitListe: props.sicherheitListe || [],
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev: any) {
        let evx: React.ChangeEvent<HTMLInputElement> = Object.assign({}, ev);
        if (this.props.onChange) {
            this.props.onChange(evx);
        }
    }

    private getOption(key: number, currentOption: IBezugsobjekt) {
        return <option key={key} value={currentOption.index}>{currentOption.label}</option>;
    }

    public render() {
        let options = [];

        if ('Vorgang' === this.state.bezugsobjektArt) {
            return <div></div>
        }
        else if ('Person' === this.state.bezugsobjektArt) {
            for (var index_P = 0; index_P < this.state.personListe.length; index_P++) {
                options[index_P] = this.getOption(index_P, this.state.personListe[index_P]);
            }
        }
        else if ('Finanzierungsmittel' === this.state.bezugsobjektArt) {
            for (var index_F = 0; index_F < this.state.finanzierungsmittelListe.length; index_F++) {
                options[index_F] = this.getOption(index_F, this.state.finanzierungsmittelListe[index_F]);
            }
        }
        else if ('Immobilie' === this.state.bezugsobjektArt) {
            for (var index_I = 0; index_I < this.state.immobilieListe.length; index_I++) {
                options[index_I] = this.getOption(index_I, this.state.immobilieListe[index_I]);
            }
        }
        else if ('Sonstiger Finanzierungsgegenstand' === this.state.bezugsobjektArt) {
            for (var index_SF = 0; index_SF < this.state.sonstigerFinanzierungsgegenstandListe.length; index_SF++) {
                options[index_SF] = this.getOption(index_SF, this.state.sonstigerFinanzierungsgegenstandListe[index_SF]);
            }
        }
        else if ('Sicherheit' === this.state.bezugsobjektArt) {
            for (var index_S = 0; index_S < this.state.sicherheitListe.length; index_S++) {
                options[index_S] = this.getOption(index_S, this.state.sicherheitListe[index_S]);
            }
        }

        let nix = undefined;
        return (
            <SelectInput
                key={this.props.key}
                name={this.props.name}
                value={this.props.value}
                onChange={this.onChange}
                label={this.props.label}
            >
                <option value={nix}></option>
                {options}
            </SelectInput>
        );
    }
}
