import * as React from 'react';
import { Row, Col } from 'reactstrap';
import DateInput from '../aif/DateInput';
import NumberInput from '../aif/NumberInput';
import { IRiesterdatenKind } from './types';
import { FaTrashAlt } from 'react-icons/fa';
import TextInput from '../aif/TextInput';
import { EnumInput } from '../aif';

interface IKindProps {
    data: IRiesterdatenKind
    onChange?(data: IRiesterdatenKind): void
    onRemove?(data: IRiesterdatenKind): void
    label?: string
}

interface IKindState {
    data: IRiesterdatenKind
}

export default class Kind extends React.Component<IKindProps, IKindState> {
    constructor(props: IKindProps) {
        super(props);

        this.state = {
            data: this.props.data
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
        this.remove = this.remove.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IKindProps) {
        this.setState({ data: newProps.data });
    }

    remove(ev: React.MouseEvent<SVGElement, MouseEvent>) {
        if (this.props.onRemove) { this.props.onRemove(this.state.data) }
    };

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy })
    };

    public render() {
        return (
            <fieldset>
                <Row>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="Entfernen" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput label="Vorname" name="vorname" value={this.state.data.vorname} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <TextInput label="Nachname" name="nachname" value={this.state.data.nachname} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Geburtsdatum" name="geburtsdatum" value={this.state.data.geburtsdatum} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <NumberInput label="Kinderzulage bis" name="kinderzulage_bis" value={this.state.data.kinderzulage_bis} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <EnumInput enum="riester_kind_zuordnung" name="zuordnung" value={this.state.data.zuordnung} onChange={this.onChange} />
                    </Col>
                </Row>
            </fieldset>
        );
    }
}
