import * as React from 'react';
import './style.css'

interface ITextAreaProps {
  name: string
  label?: string
  value?: string
  maxRows?: number
  onChange?(ev: React.ChangeEvent): void
}

interface ITextAreaState {
  value: string
  rows: number
  maxRows: number
}

export default class TextArea extends React.Component<ITextAreaProps, ITextAreaState> {
  myref: React.RefObject<any>;
  constructor(props: ITextAreaProps) {
    super(props);
    this.state = {
      value: props.value || '',
      rows: 1,
      maxRows: props.maxRows || 4
    }
    this.myref = React.createRef();
    this.onKeyUp = this.onKeyUp.bind(this);
    this.getRowCount = this.getRowCount.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount(){
    this.getRowCount();
  }

  UNSAVE_componentWillReceiveProps(newProps: ITextAreaProps) {
    this.setState({ value: newProps.value || '' })
    this.getRowCount();
  }


  getRowCount() {
    const lines = 1 + (this.state.value.match(/\n/g) || []).length
    this.setState({ rows: lines > this.state.maxRows ? this.state.maxRows : lines });
  }

  onChange(ev: React.ChangeEvent<HTMLTextAreaElement>) {
    const evCopy = Object.assign({}, ev);
    this.setState({ value: ev.target.value }, () => {
      this.getRowCount();
      if (this.props.onChange) {
        this.props.onChange(evCopy);
      }
    });
  }

  onKeyUp(ev: any) {
    this.getRowCount();
  }

  public render() {
    const hasvalue = this.state.value.trim().length > 0;
    const hasRows = this.state.rows > 1;
    let cssClass = hasvalue ? 'TextArea hasValue' : 'TextArea';
    if (hasRows) { cssClass += ' hasRows' }
   // const transform = (hasvalue && this.state.rows > 1) ? ('translateY(' + ((this.state.rows + 1) * -100) + '%)') : '';

    return (
      <div className={cssClass}>
        <textarea value={this.state.value} id={this.props.name} onChange={this.onChange} name={this.props.name} rows={this.state.rows} onKeyUp={this.onKeyUp}></textarea>
        <label htmlFor={this.props.name}>
          <span className="Label" ref={this.myref}>{this.props.label || this.props.name}</span>
        </label>
      </div>
    );
  }
}
/**style={{ transform: transform }}  */