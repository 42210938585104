import * as React from 'react';
import { TextInput } from '../../../common/aif';
import { FaPlus, FaCheck, FaMinus } from 'react-icons/fa';
import { Col, Row, Badge } from 'reactstrap';
import './style.css';
import Slider from 'rc-slider';

interface IKinderAlterInputProps {
    value?: number[]
    onChange?(data: number[]): void
}

interface IKinderAlterInputState {
    showDlg: boolean
    value: number[]
    editValue: number
    index?: number
}

export default class KinderAlterInput extends React.Component<IKinderAlterInputProps, IKinderAlterInputState> {
    wrapperRef: React.RefObject<HTMLDivElement>;
    constructor(props: IKinderAlterInputProps) {
        super(props);
        this.state = {
            showDlg: false,
            value: props.value || [],
            editValue: 1
        }
        this.toggleDlg = this.toggleDlg.bind(this);
        this.slideHandler = this.slideHandler.bind(this);
        this.addToList = this.addToList.bind(this);
        this.editEntry = this.editEntry.bind(this);
        this.removeEntry = this.removeEntry.bind(this);
        this.notify = this.notify.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    }

    editEntry(index: number) {
        const val = this.state.value[index];
        this.setState({ editValue: val, index: index, showDlg: true });
    }

    removeEntry() {
        if (this.state.index !== undefined && this.state.value[this.state.index]) {
            let copy = [...this.state.value];
            copy.splice(this.state.index, 1);
            this.setState({ value: copy, index: undefined, showDlg: false }, this.notify);
        }
    }

    toggleDlg() {
        this.setState({ showDlg: !this.state.showDlg });
    }

    slideHandler(value: number) {
        this.setState({ editValue: value });
    }

    addToList() {
        const copy = [...this.state.value];
        if (this.state.index !== undefined) {
            copy[this.state.index] = this.state.editValue;
        }
        else {
            copy.push(this.state.editValue);
        }
        this.setState({ value: copy, editValue: 1, index: undefined }, this.notify);
        this.toggleDlg();
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.setState({ showDlg: false });
        }
    }


    public render() {

        const badges = this.state.value.map((b, i) => <Badge key={i} color="primary" onClick={() => this.editEntry(i)}>{b}</Badge>);
        const dumyValue = this.state.value.length > 0 ? ' ' : undefined;
        const showView =
            <Row>
                <Col>
                    <TextInput onFocus={this.toggleDlg} label="Kinder" name="kinder" readOnly value={dumyValue} />
                    <div className="Badges">{badges}</div>
                 <FaPlus className="AddBtn" onClick={this.toggleDlg} />
                </Col>
             </Row>

        const editView = <div className="EditView" ref={this.wrapperRef} >
            <Row>
                <Col>
                    <label>Kind {(this.state.index || this.state.value.length) + 1}: Alter {this.state.editValue}</label>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Slider
                        min={1}
                        max={30}
                        step={1}
                        value={this.state.editValue}
                        onChange={this.slideHandler}
                    ></Slider>
                </Col>
                <Col md="1">
                    <div className="EditViewBtns">
                        <span color="info" onClick={this.addToList} title="Speichern"><FaCheck /></span>
                        <span color="danger" onClick={this.removeEntry} style={{ display: this.state.index !== undefined ? 'block' : 'none' }} title="Entfernen"><FaMinus /></span>
                    </div>
                </Col>
            </Row>
        </div>

        return (
            <div className="KinderAlterInput" ref={this.wrapperRef}  >{this.state.showDlg ? editView : showView}</div>
        );
    }
}
