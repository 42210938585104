import { ITarifData } from "../../common/Settings/Tarife/TarifService";
import { IRate } from "../../Finanzierungsantrag/Finanzierungsbaustein";
import MonthPlan from './MonthPlan';
import { ILBResult, ISparPlanMonat } from "./types";

interface IRequest {
    tarif?: ITarifData
    bausparsumme: number
    vertragsbeginn?: Date
    spar_raten_liste: IRate[]
}

interface IParams {
    startDate: Date
    endDate: Date
    guthabenzins_in_prozent: number;
    abs_gebuehr_betrag: number
}

export default class BsvRechner {
    request: IRequest;
    private params: IParams
    private plan: any
    constructor(request: IRequest) {
        this.request = request;
        this.plan = {};
        this.params = {
            guthabenzins_in_prozent: 0,
            abs_gebuehr_betrag: 0,
            endDate: new Date(),
            startDate: new Date(),
        }
    }

    /**
     * 100 * ( 12√ 1 + Jahreszins / 100 - 1)
     */

    private calcMonatszins(jahreszins: number) {
        var x = Math.round((Math.pow(1 + jahreszins / 100, 1 / 12) - 1) * 10000000000) / 100000000
        return x;
    }

    private _init() {
        const guthabenzins = (1 + (this.request.tarif?.data.guthabenzins_in_prozent || 0 / 100));
        this.params.guthabenzins_in_prozent = guthabenzins;
        this.params.startDate = new Date(this.request.vertragsbeginn || new Date());
        this.params.endDate = new Date(this.params.startDate.toISOString());
        this.params.endDate.setFullYear(this.params.startDate.getFullYear() + 20);

        // init Plan
        let i: number = 0;
        while (i < (this.params.startDate.getMonth()) + (10 * 12)) {
            let currentMonth = new Date(this.params.startDate.toISOString());
            currentMonth.setMonth(currentMonth.getMonth() + i);
            const mp = new MonthPlan(currentMonth);
            this.plan[mp.getKey()] = mp;
            i++;
        }

        //map sparplan to plan
        this.request.spar_raten_liste.forEach((rate) => {
            const months = Object.keys(this.plan);
            const rate_from = new Date(rate.beginn_datum);
            rate_from.setDate(1);
            const rate_to = rate.ende_datum ? new Date(rate.ende_datum) : new Date('9999/12/31');

            if (rate.rhythmus === 'Monatlich') {
                for (var i = 0; i < months.length; i++) {
                    const m: MonthPlan = this.plan[months[i]];
                    if (rate_from <= m.getDate() && rate_to >= m.getDate()) {
                        m.addRate(rate);
                    }
                };
            }

            else if (rate.rhythmus === 'Einmalig') {
                const plan: MonthPlan = this.plan[rate_from.toISOString().substring(0, 10)];
                if (plan) {
                    plan.addRate(rate);
                }
                else {
                    /**@TODO 
                     * 
                     * Promise.reject (?)
                     */
                }
            }

            else if (rate.rhythmus === 'Jährlich') {
                let plan: MonthPlan = this.plan[rate_from.toISOString().substring(0, 10)];
                let fromYear = rate_from.getFullYear();
                while (plan && rate_to >= plan.getDate()) {
                    plan.addRate(rate);
                    fromYear++;
                    const nextPlan = new Date(rate_from.toISOString());
                    nextPlan.setFullYear(fromYear);
                    plan = this.plan[nextPlan.toISOString().substring(0, 10)];
                }
            }

            else if (rate.rhythmus === 'Halbjährlich') {
                let plan: MonthPlan = this.plan[rate_from.toISOString().substring(0, 10)];
                let fromMonth = rate_from.getMonth();
                while (plan) {
                    plan.addRate(rate);
                    fromMonth += 6;
                    const nextPlan = new Date(rate_from.toISOString());
                    nextPlan.setMonth(fromMonth);
                    plan = this.plan[nextPlan.toISOString().substring(0, 10)];
                }
            }

            else if (rate.rhythmus === 'Vierteljährlich') {
                let plan: MonthPlan = this.plan[rate_from.toISOString().substring(0, 10)];
                let fromMonth = rate_from.getMonth();
                while (plan && rate_to >= plan.getDate()) {
                    plan.addRate(rate);
                    fromMonth += 4;
                    const nextPlan = new Date(rate_from.toISOString());
                    nextPlan.setMonth(fromMonth);
                    plan = this.plan[nextPlan.toISOString().substring(0, 10)];
                }
            }
            return rate;
        })
    }

    private calcLaufzeitberechnung(): ILBResult | undefined {
        const months = Object.keys(this.plan);
        // Sparphase
        const tarif = this.request.tarif;
        if (!tarif) { return }
        const abs_gebuehr_betrag = tarif.data.abschlussgebuehr_proz * (this.request.bausparsumme / 100) * -1;

        let ret: ILBResult = {
            abs_gebuehr: abs_gebuehr_betrag,
            bausparguthaben: 0,
            plan: [],
            summe_sparzinsen: 0,
            zinssatz: tarif.data.guthabenzins_in_prozent,
            success: false
        };

        //  const zinsfaktor = this.calcMonatszins(tarif.data.guthabenzins_in_prozent);//1 + (tarif.data.guthabenzins_in_prozent / (100 * 12));
        const bewertungszahl_zinsfaktor = tarif.data.bewertungszahl_zinsfaktor || 0;
        const mindest_bewertungszahl = tarif.data.mindest_bewertungszahl || 0;
        const mindest_ansparguthaben_in_prozent = tarif.data.mindest_ansparguthaben_in_prozent || 0;
        const geb_jahr = tarif.data.raw['VTR_GEBUEHR_BTR'] * -1;
        let sum_zinsen = 0;
        months.forEach(m => {
            if (ret.success) return;
            const vormonat_index = months.indexOf(m) - 1;
            const vormonat: MonthPlan = this.plan[months[vormonat_index]];
            const vormonat_guthaben = (vormonat?.getSparPLan() !== undefined) ? vormonat?.getSparPLan()?.guthaben : 0;
            const month_plan: MonthPlan = this.plan[m];
            const neu_guthaben = (vormonat_guthaben || 0) + month_plan.getRatenSum();
            let ansparsumme = neu_guthaben//Math.round(neu_guthaben * (neu_guthaben > 0 ? (1+zinsfaktor) : 1) * 100) / 100;

            let zinsen = 0;
            let entgelt = 0;

            if (vormonat_index === -1) {
                entgelt = geb_jahr / (month_plan.getDate().getMonth() + 1) + abs_gebuehr_betrag;
            }

            if (month_plan.getDate().getMonth() === 0) {
                entgelt = geb_jahr;
            }
            else if (month_plan.getDate().getMonth() === 11) {
                zinsen = ansparsumme * tarif.data.guthabenzins_in_prozent / 100;
            }

            ansparsumme += zinsen + entgelt;
            sum_zinsen += zinsen
            const bewertungszahl = ansparsumme > 0 ? (Math.round((ansparsumme + (sum_zinsen * bewertungszahl_zinsfaktor)) / (this.request.bausparsumme * .004) * 1000) / 1000) : 0;

            const anspargrad = ansparsumme > 0 ? (Math.round((ansparsumme / this.request.bausparsumme) * 100 * 1000) / 1000) : 0;
            let sparPLan: ISparPlanMonat = {
                anspargrad: anspargrad,
                bewertungszahl: bewertungszahl,
                guthaben: ansparsumme,
                zinsen: zinsen,//+ (vormonat?.getSparPLan()?.zinsen||0),
                mindest_bewertungszahl: mindest_bewertungszahl,
                mindest_ansparguthaben_in_prozent: mindest_ansparguthaben_in_prozent,
                bewertungszahl_erreicht: mindest_bewertungszahl < bewertungszahl,
                ansparguthaben_erreicht: mindest_ansparguthaben_in_prozent < anspargrad,
                entgelt: entgelt
            }

            month_plan.setSparPlan(sparPLan);
            ret.plan.push(month_plan);
            ret.summe_sparzinsen += zinsen;
            ret.zuteilungstermin = month_plan.getDate();
            ret.bausparguthaben = ansparsumme;
            ret.success = (sparPLan.ansparguthaben_erreicht && sparPLan.bewertungszahl_erreicht);
        })
        return ret;
    }

    run() {
        this._init();
        const ret = this.calcLaufzeitberechnung();
        return ret;
    }
}
