import * as React from 'react';
import {FaPlus} from 'react-icons/fa';
import {ButtonDropdown, Container, DropdownItem, DropdownMenu, DropdownToggle, Fade, ListGroup} from 'reactstrap';
import {TPerson} from '../../common/Person/types';
import Immobilie from './Immobilie/Immobilie';
import {IImmobilie} from './Immobilie/types';
import Sonstige from './Sonstige';
import {SonstigerFinanzierungsgegenstand} from "./Sonstige/types";

interface IFinanzierungsgegenstandListeProps {
    immobilie_liste: IImmobilie[]
    sonstige_liste: SonstigerFinanzierungsgegenstand[]
    person_liste: TPerson[]

    onChange(obj: any): void
}

interface IState {
    dropdownOpen: boolean
    sonstige_liste: SonstigerFinanzierungsgegenstand[]
    immobilie_liste: IImmobilie[]
}

export default class FinanzierungsgegenstandListe extends React.Component<IFinanzierungsgegenstandListeProps, IState> {
    constructor(props: IFinanzierungsgegenstandListeProps) {
        super(props);
        this.state = {
            dropdownOpen: false,
            sonstige_liste: props.sonstige_liste || [],
            immobilie_liste: props.immobilie_liste || []
        }

        this.addImmobilie = this.addImmobilie.bind(this);
        this.addSonstige = this.addSonstige.bind(this);
        this.toggleButtonDropdown = this.toggleButtonDropdown.bind(this);
        this.removeImmobilie = this.removeImmobilie.bind(this);
        this.copyImmobilie = this.copyImmobilie.bind(this);
        this.removeSonnstig = this.removeSonnstig.bind(this);
        this.copySonnstig = this.copySonnstig.bind(this);
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IFinanzierungsgegenstandListeProps) {
        this.setState({immobilie_liste: newProps.immobilie_liste, sonstige_liste: newProps.sonstige_liste});
    }

    toggleButtonDropdown() {
        this.setState({dropdownOpen: !this.state.dropdownOpen})
    }

    notify() {
        this.props.onChange({
            immobilie_liste: this.state.immobilie_liste,
            sonstige_finanzierungsgegenstand_liste: this.state.sonstige_liste,
        });
    }

    addImmobilie() {
        let newImo: IImmobilie = {
            ist_finanzierungsgegenstand: false,
            nutzung_art: 'eigengenutzte Immobilie',
            finanzierungszweck: 'Kauf (Ersterwerb)',
            objekt_art: 'Eigentumswohnung',
            objektdaten: {},
            objektkosten: {},
            modernisierungskosten: {},
            bewertung:{},
            eigentuemer_index_liste: [0]
        }
        let copy = [...this.state.immobilie_liste]
        copy.push(newImo);
        this.setState({immobilie_liste: copy}, this.notify);
    }

    addSonstige() {
        let newSonst: SonstigerFinanzierungsgegenstand = {
            bezeichnung: '',
            finanzierungszweck: 'Kauf (Ersterwerb)',
            gesamtkosten: 0
        }

        let copy = [...this.state.sonstige_liste]
        copy.push(newSonst);
        this.setState({sonstige_liste: copy}, this.notify);
    }


    onChange(data: any) {
        this.notify();
    }

    removeImmobilie(data: IImmobilie) {
        let copy = [...this.state.immobilie_liste];
        const index = copy.indexOf(data);
        if (index !== -1) {
            copy.splice(index, 1);
            this.setState({immobilie_liste: copy}, this.notify)
        }
    }

    copyImmobilie(data: IImmobilie) {
        let copy = [...this.state.immobilie_liste];
        copy.push({...data});
        this.setState({immobilie_liste: copy}, this.notify);
    }

    removeSonnstig(data: SonstigerFinanzierungsgegenstand) {
        let copy = [...this.state.sonstige_liste];
        const index = copy.indexOf(data);
        if (index !== -1) {
            copy.splice(index, 1);
            this.setState({sonstige_liste: copy}, this.notify)
        }
    }

    copySonnstig(data: SonstigerFinanzierungsgegenstand) {
        let copy = [...this.state.sonstige_liste];
        copy.push({...data});
        this.setState({sonstige_liste: copy}, this.notify);
    }


    public render() {
        const immo_liste = this.state.immobilie_liste.map((o, i) => {
            return <Immobilie data={o} key={i} onRemove={this.removeImmobilie} onCopy={this.copyImmobilie}
                              person_liste={this.props.person_liste}
                              onChange={this.onChange}/>
        });

        const sonst_liste = this.state.sonstige_liste.map((o, i) => {
            return <Sonstige data={o} key={i} onRemove={this.removeSonnstig} onChange={this.onChange} onCopy={this.copySonnstig}/>
        });


        return (
            <Container className="mt-4">
                <Fade>

                    <div className="text-right mb-2" style={{height: 'auto'}}>
                        <ButtonDropdown color="info" isOpen={this.state.dropdownOpen}
                                        toggle={this.toggleButtonDropdown}>
                            <DropdownToggle caret color="info">
                                <FaPlus/> Neu
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={this.addImmobilie}>Immobilie</DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem onClick={this.addSonstige}>Sonstige</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                        <ListGroup className="mt-4">
                            <div style={{minHeight: '800px'}}>
                                {immo_liste}
                                {sonst_liste}
                            </div>
                        </ListGroup>
                    </div>
                </Fade>
            </Container>
        );
    }
}

