import * as React from 'react';
import { IRate } from '.';
import { Row, Col } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa'
import { DateInput, CurrencyInput, EnumInput, TextInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';
/**
 * TODO: index mus raus 
 */
export interface IRateProps {
    data: IRate
    onChange?(data: IRate): void
    onRemove?(): void
}

interface IState {
    data: IRate
}

export default class Rate extends React.Component<IRateProps, IState> {

    constructor(props: IRateProps) {
        super(props);
        this.state = { data: this.props.data }
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onRemove() {
        if (this.props.onRemove) { this.props.onRemove() }
    }

    UNSAFE_componentWillReceiveProps(newProps: IRateProps) { 
        this.setState({data:newProps.data})
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IRate = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.data);
            }
        })
    }

    public render() {
        return (
            <fieldset>
                <div className="text-right">
                    <FaTrashAlt className="removeIcon" onClick={this.onRemove} title="Rate entfernen" />
                </div>

                <Row>
                    <Col><DateInput required label="Begin am" onChange={this.onChange} name="beginn_datum" value={this.state.data.beginn_datum} /></Col>
                    <Col><TextInput label="Bezeichnung" name="bezeichnung" value={this.state.data.bezeichnung} onChange={this.onChange} /></Col>
                    <Col><DateInput label="Ende am" onChange={this.onChange} name="ende_datum" value={this.state.data.ende_datum} /></Col>
                </Row>

                <Row>
                    <Col><CurrencyInput required label="Betrag" name="betrag" onChange={this.onChange} value={this.state.data.betrag} /> </Col>
                    <Col><EnumInput required label="Rhythmus" onChange={this.onChange} enum="rhythmus" name="rhythmus" value={this.state.data.rhythmus} /></Col>
                    <Col><CheckBoxInput label="FKÜ relevant" name="fkue_relevant" value={this.state.data.fkue_relevant} onChange={this.onChange} />  </Col>
                    <Col><CheckBoxInput label="VL" name="vl_merkmal" value={this.state.data.vl_merkmal} onChange={this.onChange} /></Col>
                </Row>
            </fieldset>
        );
    }
}
