import * as React from 'react';
import { Container, Fade, ListGroup, ListGroupItem, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { EnumService, IEnum } from '../../../services';
import Ersatzsicherheit, { IErsatzsicherheit } from './Ersatzsicherheit';
import DinglicheSicherheit, { IDinglicheSicherheit } from './DinglicheSicherheit';
import './style.css'
import { FaPlus } from 'react-icons/fa';
import { TPerson } from '../../common/Person/types';
import { IImmobilie } from '../FinanzierungsgegenstandListe/Immobilie/types';

export * from './types';

interface ISicherheitenProps {
  data: (IDinglicheSicherheit | IErsatzsicherheit)[]
  immobilien_liste: IImmobilie[]
  person_liste: TPerson[]
  onChange?(data: any): void
}

interface IState {
  data: (IDinglicheSicherheit | IErsatzsicherheit)[]
  dropdownOpen: boolean
  enums?: IEnum
  index?: number
}

export default class Sicherheiten extends React.Component<ISicherheitenProps, IState> {

  constructor(props: ISicherheitenProps) {
    super(props);
    this.state = { data: this.props.data, dropdownOpen: false }
    this.addDinglicheSicherheit = this.addDinglicheSicherheit.bind(this);
    this.remove = this.remove.bind(this);
    this.copy = this.copy.bind(this);
    this.toggleButtonDropdown = this.toggleButtonDropdown.bind(this);
    this.addErsatzsicherheit = this.addErsatzsicherheit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then(ok => {
      this.setState({ enums: ok });
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: ISicherheitenProps) {
    this.setState({ data: newProps.data })
  }

  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange({ sicherheit_liste: this.state.data });
    }
  }

  addDinglicheSicherheit() {
    const s: IDinglicheSicherheit = { sicherheit_art: 'Dingliche Sicherheit', 
    immobilie_index: 0,
    
   }
    const copy = [...this.state.data || []];
    copy.push(s);
    
    this.setState({ data: copy }, () => {
      if (this.props.onChange) {
        this.props.onChange({ sicherheit_liste: copy });
      }
    });
  }

  addErsatzsicherheit() {
    const s: IErsatzsicherheit = { sicherheit_art: 'Ersatzsicherheit' }
    const copy = [...this.state.data || []];
    copy.push(s);
    this.setState({ data: copy }, () => {
      if (this.props.onChange) {
        this.props.onChange({ sicherheit_liste: copy });
      }
    });
  }

  toggleButtonDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen })
  }

  remove(data: IDinglicheSicherheit | IErsatzsicherheit) {
    let copy = [...this.state.data];
    const index = copy.indexOf(data);
    console.log(index);
    if (index !== -1) {
      copy.splice(index, 1);
      this.setState({ data: copy }, () => { })
    }
  }

  copy(data: IDinglicheSicherheit | IErsatzsicherheit) {
    let copy = [...this.state.data];
    copy.push(data)
      this.setState({ data: copy }, () => { })
  }

  onChange(data: IDinglicheSicherheit | IErsatzsicherheit) {
    console.log(data);
  }

  public render() {
    const items = (this.state.data || []).map((s, i) => {
      if (s.sicherheit_art === 'Dingliche Sicherheit') {
        return <ListGroupItem key={i}><DinglicheSicherheit immobilien_liste={this.props.immobilien_liste} person_liste={this.props.person_liste} data={s} onRemove={this.remove} onCopy={this.copy} onChange={this.onChange} /></ListGroupItem>
      }
      else if (s.sicherheit_art === 'Ersatzsicherheit') {
        return <ListGroupItem key={i}><Ersatzsicherheit data={s} onRemove={this.remove} onCopy={this.copy} /></ListGroupItem>
      }
      else {
        return <ListGroupItem key={i}>Unklar</ListGroupItem>
      }
    })

    return (
      <Container className="mt-4">
        <Fade>
          <div className="text-right">
            <ButtonDropdown color="info" isOpen={this.state.dropdownOpen} toggle={this.toggleButtonDropdown}>
              <DropdownToggle caret color="info">
                <FaPlus /> Neu
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.addDinglicheSicherheit}>Dingliche Sicherheit</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this.addErsatzsicherheit}>Ersatzsicherheit</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>

          <div style={{ minHeight: '800px' }}>
            <ListGroup className="mt-1">
              {items}
            </ListGroup>
          </div>
        </Fade>
      </Container>
    );
  }
}