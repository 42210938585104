import * as React from 'react';
import TextInput from '../TextInput';
import './style.css';
import ASelectList from './ASelectList';
export interface IASelectProps {
    multiple?: boolean
    label?: string
    onChange?(): void
}

interface IASelectState {
    focus: boolean
    label: string
}

export default class ASelect extends React.Component<IASelectProps, IASelectState> {
    wrapperRef: React.RefObject<HTMLDivElement>;
    constructor(props: IASelectProps) {
        super(props);
        this.state = {
            focus: false,
            label: ''
        }
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.createLabel = this.createLabel.bind(this);
        this.wrapperRef = React.createRef();

    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.createLabel();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    createLabel() {
        const items = this.wrapperRef.current?.querySelectorAll('.Label.selected');
        let labels: string[] = [];
        items?.forEach((item, index) => {
            labels.push(item.innerHTML);
        });
        let val = labels.join(', ');
        this.setState({ label: val })
    }

    handleClickOutside(event: any) {
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.createLabel();
            this.setState({ focus: false });
        }
    }

    onFocus() {
        this.setState({ focus: true });
    }

    onBlur() {
        this.setState({ focus: false });
    }

    onChange() {

    }

    public render() {
        return (
            <div className="ASelect" ref={this.wrapperRef}>
                <TextInput
                    label={this.props.label}
                    name="sname"
                    value={this.state.label}
                    onFocus={this.onFocus}
                    readOnly
                />
                <div className={this.state.focus ? '' : 'd-none'}><ASelectList>{this.props.children}</ASelectList></div>
            </div>
        );
    }
}
