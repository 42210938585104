import axios, { AxiosPromise } from 'axios';
//import qs from 'querystring';
import SettingsService from './SettingsService';
import { keycloak } from '../components/security';
import { IAntrag } from '../types/Antrag';

/**TODO
 * 
 * use interceptor
 */

const _getHeader = () => {
    try {
        const token = keycloak.token;//JwtUtils.getAppUser().oauth.access_token
        const s = SettingsService.getSettings();
        return {
            "Authorization": 'Bearer ' + token,
            "X-OSPE-BLZ": s["X-OSPE-BLZ"],
            "apikey": s.apikey
        }
    }
    catch (err) {
        console.error(err);
        return {}
    }
}

export default class BackendService {
    /*   static login(payload: any) {
           const { oauth_url } = SettingsService.getSettings();
           axios.defaults.baseURL = oauth_url;
           return axios.post(oauth_url + 'oauth2/token', qs.stringify(payload));
       }
   */
    /**
     * 
     * @param caseId Vorgang UUID
     * @param message 
     */
    static sendComment(caseId: string, message: any) {
        const { api_url } = SettingsService.getSettings();
        return axios.post(api_url + 'antrag/' + caseId + '/antwort', message, { headers: _getHeader() });
    }

    /**
     * Create new Case
     * @param fbad 
     */
    static sendAntrag(fbad: IAntrag) {
        const { api_url } = SettingsService.getSettings();
        if (fbad.verbund_liste && fbad.verbund_liste.length === 0) {
            delete fbad.verbund_liste;
        }
        console.log(api_url)
        return axios.post(api_url + '/antrag', fbad, { headers: _getHeader() });
    }

    static updateAntrag(antrag: IAntrag): AxiosPromise<any> {
        const { api_url } = SettingsService.getSettings();
        return axios.put(api_url +  'antrag', antrag, { headers: _getHeader() });
    }

    static leseAntrag(antragid: String): AxiosPromise<any> {
        const { api_url } = SettingsService.getSettings();
        return axios.get(api_url +  'antrag/' + antragid,  { headers: _getHeader() });
    }


    static getEnums(): AxiosPromise<any> {
        const { api_url } = SettingsService.getSettings();
        return axios.get(api_url + 'service/enums', { headers: _getHeader() });
    }

    static getTarife(): AxiosPromise<any> {
        const { api_url } = SettingsService.getSettings();
        const url = api_url + 'service/tarife';
        return axios.get(url, { headers: _getHeader() });
    }

    static getDemo(): AxiosPromise<any> {
        const { api_url } = SettingsService.getSettings();
        const url = api_url + 'demo/demo';
        return axios.get(url, { headers: _getHeader() });
    }

    static getRoutes(): AxiosPromise<any> {
        const url = process.env.REACT_APP_API_HOST + "/fi-routes";
        return axios.get(url, { headers: _getHeader() });
    }

    static uploadDokument(data: any, antragId: string): AxiosPromise {
        const maxFileSize = 100 * 1024 * 1024; // 100 MB
        const { api_url } = SettingsService.getSettings();
        return axios.post(api_url + '/antrag/' + antragId + '/dokument', data, { headers: _getHeader(), maxContentLength: maxFileSize, maxBodyLength: maxFileSize });
    }
}
