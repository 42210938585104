import * as React from 'react';
import { FaUser } from 'react-icons/fa';
import JwtUtils from '../../utils/JwtUtils';
import './style.css';

export interface IUserInfo {
    email?: string
    email_verified?: boolean
    family_name?: string
    given_name?: string
    name?: string
    preferred_username?: string
    sub?: string
}

interface IUserInfoProps {
    keycloak: Keycloak.KeycloakInstance
}

interface IUserInfoState {
    userInfo?: IUserInfo
}

export default class UserInfo extends React.Component<IUserInfoProps, IUserInfoState> {
    constructor(props: IUserInfoProps) {
        super(props);
        this.state = {}
        this._init = this._init.bind(this);
        this.logout = this.logout.bind(this);
        this.info = this.info.bind(this);
        this._init();
    }

    _init() {
        if (this.props.keycloak.authenticated) {
            this.props.keycloak.loadUserInfo().then((ok) => {
                const ui: IUserInfo = Object.assign({}, ok);
                JwtUtils.storeUser(ui.name);
                this.setState({ userInfo: ui });
            },(err)=>{
                console.error('Could not load UserInfo : ', err)
            });
        }
    }

    logout(ev: React.MouseEvent) {
        this.props.keycloak.logout();
    }

    info() {
        console.log(this.props.keycloak);
    }

    public render() {

        return (
            <div className="UserInfo">
                <span><FaUser/> {this.state.userInfo?.name}</span>
                <ul className="UserMenue">
                    <li onClick={() => { this.props.keycloak.accountManagement() }}>Mein Account</li>
                    <li onClick={this.logout}>Logout</li>
                </ul>
            </div>
        );
    }
}
