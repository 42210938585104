import * as React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { IRiesterdatenKind } from '.';
import Kind from './Kind';

interface IRiesterdatenKindProps {
    data: IRiesterdatenKind[]
    onChange?(data: IRiesterdatenKind[]): void
}

interface IRiesterdatenKindState {
    data: IRiesterdatenKind[]
}

export default class RiesterdatenKind extends React.Component<IRiesterdatenKindProps, IRiesterdatenKindState> {
    constructor(props: IRiesterdatenKindProps) {
        super(props);
        this.state = {
            data: this.props.data
        }

        this.notify = this.notify.bind(this);
        this.addKind = this.addKind.bind(this);
        this.removeKind = this.removeKind.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IRiesterdatenKindProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    addKind() {
        let copy = [...this.state.data];
        copy.push({});
        this.setState({ data: copy }, this.notify);
    }

    removeKind(data: IRiesterdatenKind) {
        let i = this.state.data.indexOf(data);
        if (i !== -1) {
            let copy = [...this.state.data];
            copy.splice(i, 1);
            this.setState({ data: copy }, this.notify);
        }
    }

    public render() {
        const liste = this.state.data.map((k, i) => {
            return <Kind data={k} key={i} onChange={this.notify} onRemove={this.removeKind} label={'Kind '+(i+1)}/>
        });

        return (
            <div className="RiesterdatenKind mb-2">
                <Row>
                    <Col className="text-right" ><Button outline color="info" size="sm" onClick={this.addKind}> <FaPlus /> Kind </Button></Col>
                </Row>
                {liste}
            </div>
        );
    }
}
