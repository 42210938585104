import * as React from 'react';
import { IEigenmittel } from './types';
import { Container, Fade, Button, Row, Col, ListGroup, ListGroupItem } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { EnumService, IEnum } from '../../../services';
import Eigenmittel from './Eigenmittel';
import { toFormatedString } from '../../common/utils/util';
import { IVerbund } from '../../common/Verbund/types';
import { TPerson } from '../../common/Person/types';
export * from './types';

interface IEigenmittelListeProps {
    onDataChange?(obj: any): void
    data: IEigenmittel[] | []
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
}

interface IState {
    enums?: IEnum
    data: IEigenmittel[]
    total:number;
}

export default class EigenmittelListe extends React.PureComponent<IEigenmittelListeProps, IState> {

    constructor(props: IEigenmittelListeProps) {
        super(props);
        this.state = { data: props.data ,total:0}
        this.addItem = this.addItem.bind(this);
        this.onPosChange = this.onPosChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.notify = this.notify.bind(this);
        this.calcTotals=this.calcTotals.bind(this);
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        });
        this.calcTotals();
    }

    UNSAVE_componentWillReceiveProps(newProps: IEigenmittelListeProps) {
        this.setState({ data: newProps.data },this.calcTotals);
    }

    notify() {
        this.calcTotals();
        if (this.props.onDataChange) { this.props.onDataChange({ eigenmittel_liste: this.state.data }); }
    }
    
    onDelete(index: number) {
        const copy = [...this.state.data];
        copy.splice(index, 1);
        this.setState({ data: copy }, this.notify)
    }

    onCopy(data: IEigenmittel) {
        const copy = [...this.state.data];
        copy.push({...data});
        this.setState({ data: copy }, this.notify)
    }

    addItem() {
        const item: IEigenmittel = {
            betrag: 0,
            eigenmittel_art: this.state.enums?.eigenmittel_art[0] || '',
            eigenmittelgeber_person_index: 0,
            bemerkung: ''
        }
        const copy = [...this.state.data];
        copy.push(item);
        this.setState({ data: copy},this.notify);
    }

    onPosChange(pos: IEigenmittel): void {
        this.notify();
    }

    calcTotals(){
        let t=0;
        for(var i =0;i<this.state.data.length;i++){
            const item = this.state.data[i];
            t+= parseFloat(item.betrag+'');
        }
        this.setState({total:t});
    }


    public render() {
        if (!this.state.enums) { return <></> }
        const plist = this.props.person_liste;
        const eItems: IEigenmittel[] = [...this.state.data];

        const items = eItems.map((e, i) => {
            return <Eigenmittel onDataChange={this.onPosChange} verbund_liste={this.props.verbund_liste} person_liste={plist} data={e} index={i} key={i} onDelete={this.onDelete} onCopy={this.onCopy} />
        })

      

        return (
            <Container className="mt-4">
                <Fade>
                    <div className="text-right">
                        <Button onClick={this.addItem} color="info"> <FaPlus /> Neu</Button>
                    </div>

                    <ListGroup className="mt-4">
                        {items}
                        <ListGroupItem style={{ display: (this.state.total > 0) ? 'inline' : 'none' }}>
                            <Row className="border-top">
                                <Col>Gesamt:</Col>
                                <Col className="text-right"><b>{toFormatedString(this.state.total)}</b></Col>
                                <Col md="1"></Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Fade>

            </Container>
        );
    }
}
