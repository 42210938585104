import React, { Component } from 'react'
import "./validation.css";
import PlzOrt from './data/PlzOrt';
import TextInput from '../components/common/aif/TextInput';

/**
 *  Erweiterte Validierung: Ort und PLZ sind von einander Abhängig
 */

interface IOrtProps {
  plz: string
  name: string
  value?: string
  onChange(obj: any): void
  required?:boolean
  label?:string
}

export default class Ort extends Component<IOrtProps> {

  UNSAFE_componentWillReceiveProps(props: IOrtProps) {
    if (props.plz && props.plz.length === 5 && props.plz !== this.props.plz) {
      const plz: string = props.plz
      const index = Object.keys(PlzOrt).indexOf(plz);
      const lkp = Object.values(PlzOrt)[index];
      if (!lkp) {
        return;
      }
      const lkpValue = lkp[0].o || undefined;
      props.onChange({ target: { name: props.name, value: lkpValue } });
    }
    else {
    }
  }

  isValide() {
    if (!this.props.plz) { return false; }
    const plz: string = this.props.plz
    const index = Object.keys(PlzOrt).indexOf(plz);
    const lkp = Object.values(PlzOrt)[index];

    if (lkp !== undefined) {
      const lkpValue = lkp[0].o;
      return (lkpValue === this.props.value)
    }
    return false;
  }

  render() {
    return (
      <TextInput
        label={this.props.label||"Ort"}
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        required={this.props.required}
      />

    )
  }
}