import BackendService from "./BackendService";
import { cazeDB } from '../stores/dexiestore';
import init_enums from './enums';
export interface IEnum {
    _idbid: 'enumlist'
    uat?: Date
    abschluss_grund: string[]
    antrag_art: string[]
    ausgabe_art: string[]
    ausstattungsstandard: string[]
    berufliche_stellung: string[]
    branche: string[]
    dokumenten_art: string[]
    eigenmittel_art: string[]
    einnahme_art: string[]
    ersatzsicherheit_art: string[]
    familienstand: string[]
    finanzierungsmittel_art: string[]
    finanzierungsgegenstand_art: string[]
    finanzierungszweck: string[]
    gebaeude_art: string[]
    gebaeude_zustand: string[]
    gebiet: string[]
    gebuehr_art: string[]
    ivd_avd_kennzeichen: string[]
    jur_person_unterart: string[]
    kreditgeber: string[]
    kommunikation_art: string[]
    kommunikation_nutzung: string[]
    kundenpraeferenz: string[]
    lagebeurteilung: string[]
    land: string[]
    last_art: string[]
    nachrichtentyp: string[]
    nutzung_art: string[]
    objekt_art: string[]
    person_art: string[]
    raten_rhythmus: string[]
    rechtsform: string[]
    riester_berechnungsziel: string[]
    riester_zulagenberechtigung: string[]
    riester_zulagenverwendung: string[]
    rolle: string[]
    status: string[]
    steuer_art: string[]
    sicherheit_art: string[]
    verbund_art: string[]
    vereinbarung_art: string[]
    vl_art: string[]
    zinsfestschreibung_art: string[]
}
let cache: IEnum | undefined;

export class EnumService {

    private static createKV(list: string[]) {
        let ret: any = {};
        for (var i in list) {
            const f = list[i];
            ret[f] = f.replace(/_/g, ' ');
        }
        return ret;
    }

    /**
     * Load Enums from BackEnd 
     */
    static async loadEnums(): Promise<IEnum> {
        const { data } = await BackendService.getEnums()
        let enums: any = Object.assign(data, { uat: new Date() });
        await cazeDB.saveEnums(enums)
        return Promise.resolve(enums);
    }

    /**
     * 
     * get enums from indexed db
     * 
     */
    static async getEnums(): Promise<IEnum> {
        if (cache) {

            return Promise.resolve(cache);
        }
        const data = await cazeDB.getEnums();

        if (data) {
            let ret: IEnum = data;
            cache = ret;
            return Promise.resolve(ret);
        }
        else {
            try {
                const data = await this.loadEnums();
                return Promise.resolve(data);
            }
            catch (err) {
                console.warn('Enums konnten nicht geladen werden! Sie arbeiten mit lokalen Enums die abweichen können.');
                return Promise.resolve(init_enums);
            }
        }
    }
}