import * as React from 'react';
import { IBsvProvision, IBsvProvisionVertrieb, IBsvProvisionVermittler, IBsvProvisionSparkasse } from '../types';
import { Col, Row } from 'reactstrap';
import { NumberInput, EnumInput, ACollapse } from '../../common/aif';
import ProvisionVermittler from './ProvisionVermittler';
import ProvisionSparkasse from './ProvisionSparkasse';
import ProvisionVertrieb from './ProvisionVertrieb';

interface IBsvProvisionProps {
    data?: IBsvProvision
    onChange?(data: any): void
}

interface IBsvProvisionState {
    data: IBsvProvision
}

export default class BsvProvision extends React.Component<IBsvProvisionProps, IBsvProvisionState> {
    constructor(props: IBsvProvisionProps) {
        super(props);
        this.state = {
            data: this.props.data || {}
        }
        
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onProvisionVermittlerChange = this.onProvisionVermittlerChange.bind(this);
        this.onProvisionSparkasseChange = this.onProvisionSparkasseChange.bind(this);
        this.onProvisionVertriebChange = this.onProvisionVertriebChange.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            //this.props.onChange({ beratungsprotokoll: this.state.data });
            this.props.onChange(this.state.data);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: IBsvProvisionProps) {
        this.setState({ data: newProps.data || {} });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }

    onProvisionVermittlerChange(data: IBsvProvisionVermittler) {
        
        let provision_vermittler:any= data;
        if(data.vermittler_daten_liste?.length===0){
            provision_vermittler.vermittler_daten_liste = undefined
        }
        
        const copy = Object.assign(this.state.data, { provision_vermittler: provision_vermittler });
        this.setState({ data: copy }, this.notify);
    }

    onProvisionSparkasseChange(data: IBsvProvisionSparkasse) {
        const copy = Object.assign(this.state.data, { provision_sparkasse: data });
        this.setState({ data: copy }, this.notify);
    }

    onProvisionVertriebChange(data: IBsvProvisionVertrieb) {
        const copy = Object.assign(this.state.data, { provision_vertrieb: data });
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <>
                <Row>
                    <Col><NumberInput label="Variante" name="variante" value={this.state.data.variante} onChange={this.onChange} /></Col>
                    <Col><EnumInput label="Rufendes System" enum="bsv_provision_rufendes_system" name="rufendes_system" value={this.state.data.rufendes_system} onChange={this.onChange} /> </Col>
                </Row>
                <ACollapse label="Provision Vermittler">
                    <ProvisionVermittler data={this.state.data.provision_vermittler} onChange={this.onProvisionVermittlerChange} />
                </ACollapse>
                <ACollapse label="Provision Sparkasse" >
                    <ProvisionSparkasse data={this.state.data.provision_sparkasse} onChange={this.onProvisionSparkasseChange} />
                </ACollapse>
                <ACollapse label="Provision Vertrieb">
                    <ProvisionVertrieb data={this.state.data.provision_vertrieb} onChange={this.onProvisionVertriebChange} />
                </ACollapse>
            </>
        );
    }
}
