import { IPerson, IPrivatperson, IGeschaeftsperson } from "./types";



export function getPersonLabel(person: IPerson) {
    let vorname;
    if (person.person_art === 'Privat') {
        vorname = getVornamePrivatPerson(person);
    }
    else if (person.person_art === 'Geschäftlich') {
        vorname = getVornameGeschaeftsperson(person);
    }

    let result = concatWithSeparator(vorname, person.nachname, " ");
    result = concatWithBracket(result, person.person_nr);
    result = concatWithSeparator(result, getPersonArtPretty(person), " - ")
    return result;
}

function concatWithSeparator(first: any, second: any, separator: string) {
    let result = first || "";
    if (second && second.length > 0) {
        result = result + separator + second;
    }
    return result
}

function concatWithBracket(first: any, second: any) {
    let result = first || "";
    if (second && second.length > 0) {
        result = result + " (" + second + ")";
    }
    return result
}

function getVornamePrivatPerson(person: IPrivatperson) {
    return person.vorname;
}

function getVornameGeschaeftsperson(person: IGeschaeftsperson) {
    return person.vorname;
}

function getPersonArtPretty(person: IPerson) {
    if (person.person_art === 'Privat') {
        return "Privat Person";
    }
    else if (person.person_art === 'Geschäftlich') {
        return "Geschäftsperson";
    }
    else if (person.person_art === 'Juristisch') {
        return "juristische Person";
    }
    return "";
}
