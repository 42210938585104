import * as React from 'react';
import { IKapitaldienstdaten, IEinnahme, IAusgabe, IKorrektur } from './types';
import { Col, Row} from 'reactstrap';
import { EnumService, IEnum } from '../../../services';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import { TPerson } from '../../common/Person/types';
import { IVerbund } from '../../common/Verbund/types';
import { FaPlus, FaTrashAlt, FaCopy } from 'react-icons/fa';
import Einnahme from './Einnahme';
import { toFormatedString } from '../../common/utils';
import Ausgabe from './Ausgabe';
import {ACollapse, AButton} from '../../common/aif';
import Korrektur from './Korrektur';
export * from './types';

export interface IKapitaldienstProps {
    data: IKapitaldienstdaten
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    onChange?(data: IKapitaldienstdaten): void
    onRemove?(data: IKapitaldienstdaten): void
    onCopy?(data: IKapitaldienstdaten): void
}

interface IState {
    data: IKapitaldienstdaten
    enums?: IEnum
    totals?: { e: number, a: number }
}

export class Kapitaldienstdaten extends React.Component<IKapitaldienstProps, IState> {

    constructor(props: IKapitaldienstProps) {
        super(props);
        this.state = {
            data: this.props.data,
            totals: { e: 0, a: 0 }
        }
        this.onChange = this.onChange.bind(this);
        this.copy = this.copy.bind(this);
        this.createReceipt = this.createReceipt.bind(this);
        this.createExpense = this.createExpense.bind(this);
        this.createKorrektur = this.createKorrektur.bind(this);
        this.removeReceipt = this.removeReceipt.bind(this);
        this.removeExpense = this.removeExpense.bind(this);
        this.removeKorrektur = this.removeKorrektur.bind(this);
        this.calcTotals = this.calcTotals.bind(this);
        this.onPVSelect = this.onPVSelect.bind(this);
        this.notify = this.notify.bind(this);
        this.onPosChange = this.onPosChange.bind(this);
        this.removeKapitaldienst = this.removeKapitaldienst.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
            this.calcTotals();
        }, err => console.error(err));
    }

    UNSAFE_componentWillReceiveProps(newProps: IKapitaldienstProps) {
        this.setState({ data: newProps.data });
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IKapitaldienstdaten = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, this.notify);
    }

    removeKapitaldienst() {
        if (this.props.onRemove) { this.props.onRemove(this.state.data) }
    }

    copy(){
        if (this.props.onCopy) { this.props.onCopy(this.state.data) }
    }


    createReceipt() {
        const art = this.state.enums?.einnahme_art[0];
        const r: IEinnahme = {
            einnahme_art: art || '',
            bemerkung: '',
            betrag: 0,
        }

        let liste = [...this.state.data.einnahmen_liste];
        liste.push(r);
        const copy = Object.assign(this.state.data, { einnahmen_liste: liste });
        this.setState({ data: copy }, this.notify);

    }
    removeReceipt(data: IEinnahme) {
        const index = this.state.data.einnahmen_liste.indexOf(data);

        if (index !== -1) {
            let liste = [...this.state.data.einnahmen_liste] || [];
            liste.splice(index, 1);
            const copy: any = Object.assign(this.state.data.einnahmen_liste, { einnahmen_liste: liste })
            this.setState({ data: copy }, this.notify);
        }
    }

    createExpense() {
        const art = this.state.enums?.ausgabe_art[0];
        const r: IAusgabe = {
            ausgabe_art: art || '',
            bemerkung: '',
            betrag: 0,
        }
        let liste = [...this.state.data.ausgaben_liste] || [];
        liste.push(r);
        const copy = Object.assign(this.state.data, { ausgaben_liste: liste });
        this.setState({ data: copy }, this.notify);
    }

    removeExpense(data: IAusgabe) {
        const index = this.state.data.ausgaben_liste.indexOf(data);
        if (index !== -1) {
            let liste = [...this.state.data.ausgaben_liste] || [];
            liste.splice(index, 1);
            const copy: any = Object.assign(this.state.data, { ausgaben_liste: liste })
            this.setState({ data: copy }, this.notify);
        }
    }

    createKorrektur() {
        const data: IKorrektur = { rate: 0 }
        let liste = [...this.state.data.korrekturen_liste];
        liste.push(data);
        const copy = Object.assign(this.state.data, { korrekturen_liste: liste });
        this.setState({ data: copy }, this.notify);
    }

    removeKorrektur(data: IKorrektur) {
        const index = this.state.data.korrekturen_liste.indexOf(data);
        if (index !== -1) {
            let liste = [...this.state.data.korrekturen_liste] || [];
            liste.splice(index, 1);
            const copy: any = Object.assign(this.state.data, { korrekturen_liste: liste })
            this.setState({ data: copy }, this.notify);
        }
    }

    enumValue(enumx: object, key: string): string {
        if (!enumx) return ''
        const i = Object.keys(enumx).indexOf(key);
        const v = Object.values(enumx)
        const ret: string = '' + v[i]
        return ret;
    }

    onPosChange(data: IEinnahme | IAusgabe) {
        this.calcTotals();
        this.notify();
    }

    calcTotals(): void {
        const totals = { e: 0, a: 0 };
        for (var i in this.state.data.einnahmen_liste) {
            const pos = this.state.data.einnahmen_liste[i];
            if (pos) { totals.e += parseFloat((pos.betrag ? pos.betrag : 0) + ''); }
        }
        for (var k in this.state.data.ausgaben_liste) {
            const pos = this.state.data.ausgaben_liste[k];
            if (pos) { totals.a += parseFloat((pos.betrag ? pos.betrag : 0) + ''); }
        }
        this.setState({ totals: totals });
    }

    onPVSelect(obj: IPVSelectReturn) {

        let data: any = {
            verbund_index: undefined,
            person_index: undefined
        }

        if (obj.type === "verbund_index") {
            data.verbund_index = obj.value;
        }
        else {
            data.person_index = obj.value;
        }

        let copy = Object.assign(this.state.data, data);
        this.setState({ data: copy }, this.notify)
    }

    public render() {

        const einnamen = this.state.data.einnahmen_liste?.map((e, i) => <Einnahme key={i} data={e} onChange={this.onPosChange} onRemove={this.removeReceipt} />)
        const ausgaben = this.state.data.ausgaben_liste?.map((e, i) => <Ausgabe key={i} data={e} onChange={this.onPosChange} onRemove={this.removeExpense} />)
        const korrektur = this.state.data.korrekturen_liste?.map((e, i) => <Korrektur key={i} data={e} onChange={this.onPosChange} onRemove={this.removeKorrektur} />)

        return (
            <div className="mb-3">
                <Row>
                    <Col>
                        <PVSelect
                            person_liste={this.props.person_liste}
                            verbund_liste={this.props.verbund_liste}
                            person_index={this.state.data.person_index}
                            verbund_index={this.state.data.verbund_index}
                            onChange={this.onPVSelect}
                        />
                    </Col>
                    <Col md="2">
                        <FaTrashAlt style={{ marginTop: '30%' }} className="removeIcon" title="Entfernen" onClick={this.removeKapitaldienst} />
                        <FaCopy style={{ marginTop: '30%' }} onClick={this.copy} className="copyIcon" title="kopieren" />
                    </Col>
                </Row>
                <ACollapse label="Einnamen">
                    <Row className="border-bottom">
                        <Col className="Label"></Col>
                        <Col className="text-right"><AButton color="info" size="sm" outline rounded onClick={this.createReceipt}><FaPlus /></AButton></Col>
                    </Row>

                    <Row>
                        <Col>
                            {einnamen}
                        </Col>
                    </Row>

                    <Row className="mb-4 border-top">
                        <Col>Einnamen Gesamt:</Col>
                        <Col className="text-right"><b className="border-top">{toFormatedString(this.state.totals?.e)}</b></Col>
                        <Col md="1"></Col>
                    </Row>
                </ACollapse>
                <ACollapse label="Ausgaben">
                    <Row className="border-bottom mt-2">
                        <Col className="Label"></Col>
                        <Col className="text-right"><AButton color="info" outline rounded size="sm" onClick={this.createExpense}><FaPlus /></AButton></Col>
                    </Row>
                    <Row>
                        <Col>
                            {ausgaben}
                        </Col>
                    </Row>
                    <Row className="mb-4 border-top">
                        <Col>Ausgaben Gesamt:</Col>
                        <Col className="text-right"><b>{toFormatedString(this.state.totals?.a)}</b></Col>
                        <Col md="1"></Col>
                    </Row>
                </ACollapse>
                <ACollapse label="Korrektur">
                    <Row className="border-bottom mt-2" >
                        <Col className="Label"> </Col>
                        <Col className="text-right"><AButton color="info" outline rounded size="sm" onClick={this.createKorrektur}><FaPlus /></AButton></Col>
                    </Row>
                    <Row>
                        <Col>
                            {korrektur}
                        </Col>
                    </Row>
                </ACollapse>
            </div>)
    }

}
