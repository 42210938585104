import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IFremderFinanzierungsbaustein } from './types';
import { IEnum, EnumService } from '../../../services';
import PVSelect, { IPVSelectReturn } from '../../common/utils/PVSelect';
import {FaCopy, FaTrashAlt} from 'react-icons/fa';
import { TextInput, CurrencyInput, PercentInput, DateInput, EnumInput } from '../../common/aif';
import { TPerson } from '../../common/Person/types';
import { IVerbund } from '../../common/Verbund/types';

export interface IFremderFinanzierungsbausteinProps {
    data: IFremderFinanzierungsbaustein
    person_liste: TPerson[]
    verbund_liste: IVerbund[]
    index: number
    onChange?(index: number, data: IFremderFinanzierungsbaustein): void
    onRemove?(index: number): void
    onCopy?(data: IFremderFinanzierungsbaustein): void
}

interface IState {
    enums?: IEnum
    data: IFremderFinanzierungsbaustein
}

export default class FremderFinanzierungsbaustein extends React.Component<IFremderFinanzierungsbausteinProps, IState> {

    constructor(props: IFremderFinanzierungsbausteinProps) {
        super(props);
        this.state = { data: props.data }
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.notify = this.notify.bind(this);
        this.onPVSelect = this.onPVSelect.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.props.index, this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const value = (ev.target.value === '' ? null : ev.target.value);
        const newVal = { [ev.target.name]: value }
        const newState: IFremderFinanzierungsbaustein = Object.assign(this.state.data, newVal);
        this.setState({ data: newState }, this.notify)
    }

    UNSAFE_componentWillReceiveProps(newProps: IFremderFinanzierungsbausteinProps) {
        this.setState({ data: newProps.data })
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })
    }

    remove() {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.index);
        }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    onPVSelect(obj: IPVSelectReturn) {
        let copy = Object.assign({}, this.state.data);
        if (obj.type === "verbund_index") {
            copy.verbund_index = obj.value;
            copy.person_index = undefined;
        }
        else {
            copy.verbund_index = undefined;
            copy.person_index = obj.value;
        }
        this.setState({ data: copy }, this.notify);
    }

    public render() {
        return (
            <div className="border p-2 mb-2">
                <Row>
                    <Col>
                        <div className="h5 text-secondary">Fremder Finanzierungsbaustein</div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="Fremden Finanzierungsbaustein entfernen"/>
                        <FaCopy onClick={this.copy} className="copyIcon" title="Fremden Finanzierungsbaustein kopieren"/>
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <PVSelect person_index={this.state.data.person_index}
                            verbund_index={this.state.data.verbund_index}
                            onChange={this.onPVSelect}
                            person_liste={this.props.person_liste}
                            verbund_liste={this.props.verbund_liste}
                            required
                        />
                    </Col>
                    <Col>
                        <TextInput label="Mittelgeber" name="mittelgeber" value={this.state.data.mittelgeber} onChange={this.onChange}/>
                    </Col>
                    <Col>
                        <CurrencyInput label="Darlehensbetrag" name="darlehensbetrag" value={this.state.data.darlehensbetrag} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CurrencyInput label="Leistungsrate" name="rate" value={this.state.data.rate} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <EnumInput label="Rhythmus" name='rhythmus' value={this.state.data.rhythmus || ''} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <CurrencyInput label="Saldo" name="saldo" value={this.state.data.saldo} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PercentInput label="Sollzins" name="sollzins" value={this.state.data.sollzins} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Zinsbindung bis" name="zinsbindung_bis" value={this.state.data.zinsbindung_bis} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <EnumInput label="Tilgungsform" name='tilgungsform' value={this.state.data.tilgungsform || ''} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DateInput label="Vertragsbeginn" name="vertragsbeginn" value={this.state.data.vertragsbeginn} onChange={this.onChange} />
                    </Col>
                    <Col>
                        <DateInput label="Vertragsende" name="vertragsende" value={this.state.data.vertragsende} onChange={this.onChange}/>
                    </Col>
                    <Col>
                        <DateInput label="Letzte Rate am" name="letzte_rate" value={this.state.data.letzte_rate} onChange={this.onChange} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextInput label="Kontobezeichnung" name="konto_bezeichnung" value={this.state.data.konto_bezeichnung} onChange={this.onChange}/>
                    </Col>
                    <Col>
                        <TextInput label="Bemerkung" name="bemerkung" value={this.state.data.bemerkung} onChange={this.onChange}/>
                    </Col>
                </Row>
            </div>
        );
    }
}
