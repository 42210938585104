import * as React from 'react';
import TopNav from './TopNav';
import { IFinanzierungsantrag } from './types';
import Finanzierungsbausteine from './Finanzierungsbaustein';
import Sicherheiten from './Sicherheiten';
import Summary from './Summary';
import PersonListe from '../common/Person/PersonListe';
import { cazeDB, ICazeStoreItem } from '../../stores/dexiestore';
import Comments from '../common/Comments/Comments';
import EigenmittelListe, { IEigenmittel } from './Eigenmittel';
import SettingsService from '../../services/SettingsService';
import { PersonEventService } from '../common/Person/PersonEvents';
import FinanzierungsgegenstandListe from './FinanzierungsgegenstandListe';
import Kapitaldienst from './Kapitaldienst';
import FremdeVereinbarungListe from './FremdeVereinbarung';
import Kundenwunsch from './Kundenwunsch';
import { syncRequest } from '../../services/SyncService';
import DokumentenListe from '../common/Dokumente';
import { IBezugsobjekt } from '../common/aif/BezugsObjektInput'
import { getPersonLabel } from '../common/Person/PersonUtils'


interface IFbadProps {
  location: any // from React Router
  match: any // from React Router
}

interface IState {
  fbad: IFinanzierungsantrag
  render?: boolean
  _sync: boolean
  _idbid?: string
}

const initFBad: IFinanzierungsantrag =
{
  antrag_art: 'Finanzierungsantrag',
  "status": "Offen",
  "verbund_liste": [],
  "person_liste": [
    {
      "person_art": "Privat",
      rolle: 'Antragssteller',
    }
  ],
  "vermittlerdaten": {
    "firma": "iBaufi GmbH",
    "nachname": "Tony",
    "vorname": "Bennet",
    "lbs_user_id": "007",
    "telefon": "",
    "email": ""
  },
  "nachrichten_verlauf": [],
  "antrag_informationen": {},
  "finanzierungsmittel_liste": [],
  eigenmittel_liste: [],
  immobilie_liste: [],
  sicherheit_liste: [],
  kapitaldienst: { kapitaldienstdaten_liste: [] },
  fremde_vereinbarung_liste : []
}


export default class Fbad extends React.Component<IFbadProps, IState> {
  subscription: any
  constructor(props: IFbadProps) {
    super(props);
    this.state = { fbad: Object.assign({}, initFBad), _sync: false };
    this.onDataChange = this.onDataChange.bind(this);
    this.saveData = this.saveData.bind(this);
  }

  shouldComponentUpdate(nextProps: IFbadProps, nextState: IState) {
    if (nextState.render === false) {
      delete nextState.render;
      return false;
    }
    return true;
  }

  UNSAFE_componentWillMount() {

    this.subscription = PersonEventService.getService().subscribe((ev) => {
      let copy = Object.assign({}, this.state.fbad);
      if (ev.type === 'person_removed' && ev.index !== undefined) {
        let i = ev.index;

        /** 
         * korrigiere person finanzierungsbaustein_liste 
         * 
        */
        let fbListe = [...copy.finanzierungsmittel_liste]
        copy.finanzierungsmittel_liste = [];
        fbListe.forEach(f => {
          if (f.antragssteller_person_index === i) {
            /**
             * anträge der gelöschen person werden nicht übernommen
             */
          }
          else if (f.antragssteller_person_index !== undefined && f.antragssteller_person_index > i) {
            //nachfolgende Personen rutcchen im index hoch
            f.antragssteller_person_index--;
            copy.finanzierungsmittel_liste.push(f);
          }
          else {
            copy.finanzierungsmittel_liste.push(f);
          }
        }
        )

        /** 
        * korrigiere person eigenmitel
        */
        let eListe = [...copy.eigenmittel_liste]
        copy.eigenmittel_liste = [];

        eListe.forEach((em: IEigenmittel) => {
          if ((em.eigenmittelgeber_person_index === i)) {
            //ignor
          }
          else if (em.eigenmittelgeber_person_index !== undefined && em.eigenmittelgeber_person_index > i) {
            em.eigenmittelgeber_person_index--;
            copy.eigenmittel_liste.push(em)
          }
          else { copy.eigenmittel_liste.push(em) }
        })

        /**
         * korrektur Kapitaldienst
         */

      }

      if (ev.type === 'verbund_removed' && ev.index !== undefined) {
        let i = ev.index;
        let fbListe = [...copy.finanzierungsmittel_liste]
        copy.finanzierungsmittel_liste = [];
        fbListe.forEach(f => {
          if (f.antragssteller_verbund_index === i) {
            /**
             * anträge der gelöschen person werden nicht übernommen
             */
          }
          else if (f.antragssteller_verbund_index !== undefined && f.antragssteller_verbund_index > i) {
            //nachfolgende Personen rutcchen im index hoch
            f.antragssteller_verbund_index--;
            copy.finanzierungsmittel_liste.push(f);
          }
          else {
            copy.finanzierungsmittel_liste.push(f);
          }
        }
        )

        /** korrektur egenmitteln verbund */
        let eListe = [...copy.eigenmittel_liste]
        copy.eigenmittel_liste = [];

        eListe.forEach((em: IEigenmittel) => {
          if ((em.eigenmittelgeber_verbund_index === i)) {
            //ignor
          }
          else if (em.eigenmittelgeber_verbund_index !== undefined && em.eigenmittelgeber_verbund_index > i) {
            em.eigenmittelgeber_verbund_index--;
            copy.eigenmittel_liste.push(em)
          }
          else { copy.eigenmittel_liste.push(em) }
        })
      }
      this.setState({ fbad: copy });
    })
  }

  componentWillUnmount() {
    try {
      this.subscription.unsubscribe();
    }
    catch (err) { console.warn('FinPlus: Could not unsubscribe subscription!') }
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params._id) {
      cazeDB.getCazeByKey(this.props.match.params._id).then((ok: any) => {
        const xstate = ok as IState;
        this.setState(xstate, () => { });
      })
    }
  }

  saveData() {
    if (!this.state.fbad.person_liste[0] || !this.state.fbad.person_liste[0].nachname) { return; }
    let dataPre = Object.assign(this.state, {timestamp: Date.now()});
    const data: ICazeStoreItem = Object.assign(dataPre);
    cazeDB.saveCaze(data);
    syncRequest(data._idbid);
  }

  onDataChange(obj: any) {
    const settings = SettingsService.getSettings();
    obj.vermittlerdaten = {
      firma: settings.vermittler?.firma,
      nachname: settings.vermittler?.nachname,
      vorname: settings.vermittler?.vorname,
      lbs_user_id: settings.vermittler?.lbs_user_id,
      email: settings.vermittler?.email,
      telefon: settings.vermittler?.telefon,
    }

    const copy = Object.assign(this.state.fbad, obj);
    this.setState({ fbad: copy, render: false, _sync: false }, () => {
      this.saveData();
    });
    //FbadService.addObject(ev);
  }


  public render() {
    const personListe = [];
    for (var index = 0; index < this.state.fbad.person_liste.length; index++) {
      const currentItem = this.state.fbad.person_liste[index];
      let bezugsobjekt: IBezugsobjekt = {
        label: getPersonLabel(currentItem),
        index: index
      }
      personListe[index] = bezugsobjekt;
    }

    const immobilieListe = [];
    if (this.state.fbad.immobilie_liste !== undefined) {
      for (var index_I = 0; index_I < this.state.fbad.immobilie_liste.length; index_I++) {
        const currentItem = this.state.fbad.immobilie_liste[index_I];
        let bezugsobjekt: IBezugsobjekt = {
          label: currentItem.objekt_art,
          index: index_I
        }
        immobilieListe[index_I] = bezugsobjekt;
      }
    }

    const finanzierungsmittelListe = [];
    for (var index_F = 0; index_F < this.state.fbad.finanzierungsmittel_liste.length; index_F++) {
      const currentItem = this.state.fbad.finanzierungsmittel_liste[index_F];
      let bezugsobjekt: IBezugsobjekt = {
        label: currentItem.finanzierungsmittel_art,
        index: index_F
      }
      finanzierungsmittelListe[index_F] = bezugsobjekt;
    }

    const sicherheitListe = [];
    for (var index_S = 0; index_S < this.state.fbad.sicherheit_liste.length; index_S++) {
      const currentItem = this.state.fbad.sicherheit_liste[index_S];
      let bezugsobjekt: IBezugsobjekt = {
        label: currentItem.sicherheit_art,
        index: index_S
      }
      sicherheitListe[index_S] = bezugsobjekt;
    }

    const conf = {
      '#Person': <PersonListe verbund_liste={this.state.fbad?.verbund_liste || []} person_liste={this.state.fbad.person_liste} onChange={this.onDataChange} />,
      '#Vorhaben': <FinanzierungsgegenstandListe person_liste={this.state.fbad.person_liste} sonstige_liste={this.state.fbad.sonstige_finanzierungsgegenstand_liste || []} immobilie_liste={this.state.fbad.immobilie_liste || []} onChange={this.onDataChange} />,
      '#Finanzierung': <Finanzierungsbausteine person_liste={this.state.fbad.person_liste} verbund_liste={this.state.fbad?.verbund_liste || []} data={this.state.fbad.finanzierungsmittel_liste || []} onChange={this.onDataChange} />,
      '#Kapitaldienst': <Kapitaldienst verbund_liste={this.state.fbad?.verbund_liste || []} person_liste={this.state.fbad.person_liste} data={this.state.fbad.kapitaldienst} onChange={this.onDataChange} />,
      '#Sicherheiten': <Sicherheiten immobilien_liste={this.state.fbad.immobilie_liste || []} person_liste={this.state.fbad.person_liste} data={this.state.fbad.sicherheit_liste} onChange={this.onDataChange} />,
      '#Eigenmittel': <EigenmittelListe verbund_liste={this.state.fbad?.verbund_liste || []} person_liste={this.state.fbad.person_liste} onDataChange={this.onDataChange} data={this.state.fbad.eigenmittel_liste} />,
      '#FremdeVereinbarung': <FremdeVereinbarungListe person_liste={this.state.fbad.person_liste} verbund_liste={this.state.fbad?.verbund_liste || []} data={this.state.fbad.fremde_vereinbarung_liste || []} onChange={this.onDataChange} />,
      '#Dokumente':
        <DokumentenListe
          antrag={this.state.fbad}
          antragId={this.state.fbad.antrag_id}
          data={this.state.fbad.dokument_liste || []}
          personListe={personListe}
          immobilieListe={immobilieListe}
          finanzierungsmittelListe={finanzierungsmittelListe}
          sicherheitListe={sicherheitListe}
          onDataChange={this.onDataChange} />,

      '#Summary': <Summary onDataChange={this.onDataChange} data={this.state.fbad} _idbid={this.state._idbid}></Summary>,
      '#Kundenwunsch': <Kundenwunsch onDataChange={this.onDataChange} data={this.state.fbad.kundenwunsch} ></Kundenwunsch>,
      '#Comments': <Comments backendId={this.state.fbad.antrag_id} messages={this.state.fbad.nachrichten_verlauf}></Comments>
    }
    const state = this.props.location.hash || Object.keys(conf)[0];
    const i = Object.keys(conf).indexOf(state);
    const view = Object.values(conf)[i]

    return (
      <div>
        <TopNav active={state} data={this.state.fbad}></TopNav>
        <div style={{ maxHeight: '90vh', overflow: 'auto' }}>{view}</div>
      </div>
    );
  }
}

