import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { SonstigerFinanzierungsgegenstand } from './types';
import { IEnum, EnumService } from '../../../../services';
import {FaCopy, FaTrashAlt} from 'react-icons/fa';
import { TextInput, EnumInput, CurrencyInput } from '../../../common/aif';

export interface ISonstigeProps {
    data: SonstigerFinanzierungsgegenstand
    onRemove?(data: SonstigerFinanzierungsgegenstand): void
    onChange?(data: SonstigerFinanzierungsgegenstand): void
    onCopy?(data: SonstigerFinanzierungsgegenstand): void
}

interface IState {
    enums?: IEnum
    data: SonstigerFinanzierungsgegenstand
}

export default class Sonstige extends React.Component<ISonstigeProps, IState> {

    constructor(props: ISonstigeProps) {
        super(props);
        this.state = { data: props.data }
        this.onChange = this.onChange.bind(this);
        this.remove = this.remove.bind(this);
        this.copy = this.copy.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: newVal },this.notify);
    }

    UNSAFE_componentWillReceiveProps(newProps: ISonstigeProps) {
        this.setState({ data: newProps.data })
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            this.setState({ enums: ok });
        })
    }
    remove() {
        if (this.props.onRemove) { this.props.onRemove(this.props.data); }
    }

    copy() {
        if (this.props.onCopy) {
            this.props.onCopy(this.props.data);
        }
    }

    public render() {
        return (
            <div className="border p-2 mb-2">
                <Row>
                    <Col>    <div className="h5 text-secondary"> Sonstige</div>
                    </Col>
                    <Col className="text-right">
                        <FaTrashAlt onClick={this.remove} className="removeIcon" title="Immobilie entfernen"/>
                        <FaCopy onClick={this.copy} className="copyIcon" title="Immobilie kopieren"/>
                        <span style={{marginRight: "10px"}}></span>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <TextInput label="Bezeichnung" name="bezeichnung" value={this.state.data.bezeichnung} onChange={this.onChange}/>
                    </Col>
                    <Col md="3">
                        <EnumInput enum="finanzierungszweck" label="Finanzierungszweck" name="finanzierungszweck" value={this.state.data.finanzierungszweck} onChange={this.onChange} />
                    </Col>
                    <Col md="3">
                        <CurrencyInput label="Gesamtkosten" name="gesamtkosten" value={this.state.data.gesamtkosten} onChange={this.onChange} />
                    </Col>
                </Row>
            </div>
        );
    }
}
