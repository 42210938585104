import React, { Component } from 'react'
import './App.css'
import NavBar from './components/common/Nav/NavBar'
import { ToastProvider } from 'react-toast-notifications';
import { Toast } from './components/common/Toast';
import SideNavigation from './components/common/Nav/SideNav';

import { keycloak,Secure} from './components/security';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import SettingsService from './services/SettingsService';
import { loadSyncProposals } from './services/SyncService';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    this.handleKeycloakEvent = this.handleKeycloakEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize',
      () => {
        this.setState({ width: window.innerWidth });
      });
  }

  handleKeycloakEvent(ev, err) {
  if (ev === "onReady" && keycloak.authenticated) {
      keycloak.loadUserInfo().then((ok) => {
        let settings = SettingsService.getSettings();
        if (settings && settings.vermittler) {
          settings.vermittler.vorname = ok.given_name || '';
          settings.vermittler.nachname = ok.family_name || '';
          SettingsService.saveSetting(settings);
        }
      }, err => {
        console.error(err);
      });

      loadSyncProposals();
    }
  }

  render() {
    const loading = <div className="Loading">... Loading</div>
    const navi = this.state.width < 1200 ? <NavBar /> : <SideNavigation />
    return (
      <ReactKeycloakProvider authClient={keycloak} onEvent={this.handleKeycloakEvent} LoadingComponent={loading}>
          <div className="App">
          <Secure>
            {navi}
            <ToastProvider placement='bottom-right'>
              <Toast></Toast>
            </ToastProvider>
            </Secure>
          </div>
      </ReactKeycloakProvider>
    );
  }
}

export default App;
