import * as React from 'react';
import { IBsvProvisionSparkasse } from '../types';
import { Row, Col } from 'reactstrap';
import { NumberInput, PercentInput } from '../../common/aif';
import CheckBoxInput from '../../common/aif/CheckBoxInput';

interface IProvisionSparkasseProps {
    data?: IBsvProvisionSparkasse
    onChange?(data: IBsvProvisionSparkasse): void
}

interface IProvisionSparkasseState {
    data: IBsvProvisionSparkasse
}

export default class ProvisionSparkasse extends React.Component<IProvisionSparkasseProps, IProvisionSparkasseState> {
    constructor(props: IProvisionSparkasseProps) {
        super(props);

        this.state = {
            data: props.data || {}
        }
        this.notify = this.notify.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.data);
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const copy = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
        this.setState({ data: copy }, this.notify);
    }


    public render() {
        return (<>
            <Row>
                <Col>
                    <NumberInput label="Sparkassen Nr." name="sparkassen_nr" value={this.state.data.sparkassen_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Geschäftsstelle Nr." name="geschaeftsstellen_nr" value={this.state.data.geschaeftsstellen_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Beraterplatz Nr." name="beraterplatz_nr" value={this.state.data.beraterplatz_nr} onChange={this.onChange} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <NumberInput label="Abschl. Beraterplatz" name="abschliessender_beraterplatz_nr" value={this.state.data.abschliessender_beraterplatz_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Spk. Personen Nr." name="sparkassen_personen_nr" value={this.state.data.sparkassen_personen_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="MA Erfolg OE" name="mitarbeiter_erfolg_oe" value={this.state.data.mitarbeiter_erfolg_oe} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Spk. Zusatzinfo" name="sparkassen_zusatzinfo" value={this.state.data.sparkassen_zusatzinfo} onChange={this.onChange} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <NumberInput label="Betreuende Vermittler Nr." name="betreunde_vermittler_nr" value={this.state.data.betreunde_vermittler_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <NumberInput label="Vermittler Nr." name="vermittler_nr" value={this.state.data.vermittler_nr} onChange={this.onChange} />
                </Col>
                <Col>
                    <PercentInput label="Provision Satz" name="provision_satz" value={this.state.data.provision_satz} onChange={this.onChange} />
                </Col>
                <Col>
                    <CheckBoxInput label="Gemeinschaftsgeschäft" name="gemeinschaftsgeschaeft" value={this.state.data.gemeinschaftsgeschaeft} onChange={this.onChange} />
                </Col>
            </Row>
        </>
        );
    }
}
