import * as React from 'react';
import { IDinglicheSicherheit } from './types';
import { Row, Col } from 'reactstrap';
import TextInput from '../../../common/aif/TextInput';
import NumberInput from '../../../common/aif/NumberInput';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import {FaCopy, FaTrashAlt} from 'react-icons/fa';
import ATubContent from '../../../common/aif/ATabs/ATabContent';
import ATubs from '../../../common/aif/ATabs';
import ATabNav from '../../../common/aif/ATabs/ATabNav';
import ATub from '../../../common/aif/ATabs/ATab';
import A2Liste from './A2Liste';
import A3Liste from './A3Liste';
import { TPerson } from '../../../common/Person/types';
import SelectInput from '../../../common/aif/SelectInput';
import { IImmobilie } from '../../FinanzierungsgegenstandListe/Immobilie/types';
export * from './types'

interface IDinglicheSicherheitProps {
  data: IDinglicheSicherheit
  onRemove?(data: IDinglicheSicherheit): void
  onCopy?(data: IDinglicheSicherheit): void
  onChange?(data: IDinglicheSicherheit): void
  person_liste: TPerson[]
  immobilien_liste: IImmobilie[]
}

interface IDinglicheSicherheitState {
  data: IDinglicheSicherheit
}

export default class DinglicheSicherheit extends React.Component<IDinglicheSicherheitProps, IDinglicheSicherheitState> {
  constructor(props: IDinglicheSicherheitProps) {
    super(props);

    if (props.data.immobilie_index && !props.immobilien_liste[props.data.immobilie_index]) {
      delete this.props.data.immobilie_index
    }

    this.state = {
      data: this.props.data
    }

    this.onChange = this.onChange.bind(this);
    this.remove = this.remove.bind(this);
    this.copy = this.copy.bind(this);
    this.onAbteilungChange = this.onAbteilungChange.bind(this);
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
    this.setState({ data: newVal });
  }

  remove() {
    if (this.props.onRemove) { this.props.onRemove(this.props.data); }
  }
  copy() {
    if (this.props.onCopy) { this.props.onCopy(this.props.data); }
  }

  onAbteilungChange(data: any) {
    let copy = Object.assign(this.state.data, data);
    this.setState({ data: copy }, () => {
      if (this.props.onChange) { this.props.onChange(this.state.data); }
    })
  }

  public render() {

    const options = this.props.immobilien_liste.map((immo, i) => <option key={i} value={i}>{immo.objekt_art}</option>)

    return (
      <div className="mb-2">
        <Row>
          <Col>
            <div className="h5 text-secondary">Dingliche Sicherheit</div>
          </Col>
          <Col className="text-right">
            <FaTrashAlt onClick={this.remove} className="removeIcon" title="Entfernen" />
            <FaCopy onClick={this.copy} className="copyIcon" title="Kopieren" />
            <span style={{marginRight: "10px"}}></span>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <SelectInput value={this.state.data.immobilie_index} label="Immobilie" onChange={this.onChange} name="immobilie_index" required>
              {options}
            </SelectInput>
          </Col>
          <Col>
            <TextInput label="Grundbuch" name="grundbuch_von" value={this.state.data.grundbuch_von} onChange={this.onChange} />
          </Col>

        </Row>
        <Row>
          <Col> <TextInput label="Band" name="band" value={this.state.data.band} onChange={this.onChange} /></Col>
          <Col> <TextInput label="Blatt" name="blatt" value={this.state.data.blatt} onChange={this.onChange} /></Col>
          <Col>
            <TextInput label="MEA" name="mea" value={this.state.data.mea} onChange={this.onChange} />
          </Col>
          <Col>
            <TextInput label="Flurkarte" name="flurkarte" value={this.state.data.flurkarte} onChange={this.onChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput label="Flurstueck" name="flurstueck" value={this.state.data.flurstueck} onChange={this.onChange} />
          </Col>
          <Col><TextInput label="WE Nr." name="we_nr" onChange={this.onChange} value={this.state.data.we_nr} /></Col>
          <Col><TextInput label="TE Nr." name="te_nr" onChange={this.onChange} value={this.state.data.te_nr} /></Col>
          <Col><TextInput label="SE Nr." name="se_nr" onChange={this.onChange} value={this.state.data.se_nr} /></Col>
          <Col><TextInput label="Größe" name="groesse" onChange={this.onChange} value={this.state.data.groesse} /></Col>
        </Row>
        <Row>
          <Col><NumberInput label="Bürgschaft BLZ" name="buergschaft_bankleitzahl" value={this.state.data.buergschaft_bankleitzahl} onChange={this.onChange} /></Col>
          <Col><CurrencyInput label="Bürgschaft Betrag" name="buergschaft_betrag" value={this.state.data.buergschaft_betrag} onChange={this.onChange} /></Col>
        </Row>
        <Row>
          <Col>
            <ATubs>
              <ATabNav>
                <ATub>Abteilung 2</ATub>
                <ATub>Abteilung 3</ATub>
              </ATabNav>
              <ATubContent key={2}>
                <A2Liste data={this.state.data.abteilung2_liste} onChange={this.onAbteilungChange} />
              </ATubContent>
              <ATubContent key={3}>
                <A3Liste data={this.state.data.abteilung3_liste} onChange={this.onAbteilungChange} />
              </ATubContent>
            </ATubs>

          </Col>
        </Row>
      </div>
    );
  }
}
