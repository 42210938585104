import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';

class ImageCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0, items: this.initImages(props) };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        //this.initImages(props);
    }

    initImages(props) {
        if (props.images) {
            let items = [];
            for (var i = 0; i < props.images.length; i++) {
                let im = props.images[i];
                items.push({ src: (im.imageUrl || im), caption: (im.imageAlt || '') });
            }
            return items;
            //this.setState({ items: items, activeIndex: (items.length - 1) });
            //console.log('😰', items);
        }
    }
    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ items: this.initImages(props) });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        let items = this.state.items;

        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        let items = this.state.items;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex, items } = this.state;
        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={Math.random(1000)}
                    className="CarouselItem"
                >
                    <div className="d-flex justify-content-center">
                        <img src={item.src} alt={item.altText} style={{ maxHeight: '300px' }} />
                    </div>
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
        });

        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
        );
    }
}

export default ImageCarousel;