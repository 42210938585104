import React, { Component } from 'react'
import { Col, Container, Fade, Row } from 'reactstrap'
import { IAntrag, IDokument } from '../../../types/Antrag'
import Uploader from './Upload';
import { IBezugsobjekt } from '../../common/aif/BezugsObjektInput'


interface IProps {
    data: IDokument[],
    antragId?: string,
    antrag?: IAntrag
    personListe?: IBezugsobjekt[]
    finanzierungsmittelListe?: IBezugsobjekt[]
    immobilieListe?: IBezugsobjekt[]
    sonstigerFinanzierungsgegenstandListe?: IBezugsobjekt[]
    sicherheitListe?: IBezugsobjekt[]
    onDataChange?(obj: any): void
}

interface IState {
    data: IDokument[]
}

export default class DokumentenListe extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: props.data,
        }
        this.onUploadSucceed = this.onUploadSucceed.bind(this);
    }

    UNSAFE_componentWillReceiveProps(newProps: IProps) {
        this.setState({ data: newProps.data });
    }

    notify() {
        if (this.props.onDataChange) { this.props.onDataChange({ dokument_liste: this.state.data }); }
    }

    onUploadSucceed = (doc: IDokument) => {
        const list = [...this.state.data];
        list.push(doc);
        this.setState({ data: list }, this.notify);
    };

    render() {
        const fileList = this.state.data.map(f => <Row key={f.dokument_id}>
            <Col>{f.metadaten?.name}</Col>
            <Col>{f.metadaten?.typ}</Col>
            <Col>{f.metadaten?.bezugsobjekt}</Col>
            <Col>{f.metadaten?.bezugsobjekt_index}</Col>
            <Col>{f.uebertragungszeitpunkt}</Col>
        </Row>)

        return (
            <Container fluid={false} className="mt-4">
                <Fade>
                    <h4>Dokumenten</h4>
                    {fileList}
                    <Uploader
                        onUploadSucceed={this.onUploadSucceed}
                        antragId={this.props.antragId}
                        personListe={this.props.personListe}
                        finanzierungsmittelListe={this.props.finanzierungsmittelListe}
                        immobilieListe={this.props.immobilieListe}
                        sonstigerFinanzierungsgegenstandListe={this.props.sonstigerFinanzierungsgegenstandListe}
                        sicherheitListe={this.props.sicherheitListe}
                    />
                </Fade>
            </Container>
        )
    }
}
