import * as React from 'react';
import './style.css'
interface IATubsProps {

}

interface IATubsState {
  
}

export default class ATubs extends React.Component<IATubsProps, IATubsState> {
    myRef: React.RefObject<HTMLDivElement>;
    constructor(props: IATubsProps) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        let atubs = this.myRef.current?.querySelectorAll('.ATub');
        let acnts = this.myRef.current?.querySelectorAll('.ATubContent');

        const clear = () => {
            atubs?.forEach((atub, i) => {
                atub.classList.remove('active');
            })
        }

        const showContent = (i: number) => {
            acnts?.forEach((c, ix) => {
                if (ix === i) { c.classList.add('active') }
                else {
                    c.classList.remove('active');
                }
            });
        }

        atubs?.forEach((atub, i) => {
            if (i === 0) {
                atub.classList.add('active');
                showContent(0);
            }
            atub.addEventListener('click', (x) => {
                clear();
                atub.classList.add('active');
                showContent(i);
            });
        });
    }

    public render() {
        return (
            <div className="ATubs" ref={this.myRef}>
                {this.props.children}
            </div>
        );
    }
}
