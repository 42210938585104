import * as React from 'react';
import TarifService, { ITarifData } from '../Settings/Tarife/TarifService';
import SelectInput from '../aif/SelectInput';
import { toFormatedString } from '../utils';

interface IBsvTarifProps {
    name: string,
    value?: number | string | undefined
    onChange(ev: React.ChangeEvent<HTMLInputElement>): void
    label?: string
    required?: boolean
}

interface IBsvTarifState {
    tarife?: ITarifData[]
    loading: boolean
    value?: number | string
}

export default class BSTarifSelect extends React.Component<IBsvTarifProps, IBsvTarifState> {

    constructor(props: IBsvTarifProps) {
        super(props);
        this.state = {
            loading: false,
            value: props.value
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let val: any = parseInt(ev.target.value);

        if (isNaN(val)) {
            val = undefined;
        }

        this.setState({ value: val }, () => {
            if (this.props.onChange) {
                this.props.onChange(ev);
            }
        })
    }

    UNSAFE_componentWillReceiveProps(newProps: IBsvTarifProps) {
        let v = undefined;
        try {
            if (newProps?.value !== undefined && newProps.value !== '') {
                const pi = parseInt(newProps.value + '');
                v = isNaN(pi) ? undefined : pi;
            }
        }
        finally {
            this.setState({ value: v });
        }
    }

    UNSAFE_componentWillMount() {
        const tarifeService = new TarifService();
        tarifeService.getAll().then(ok => {
            this.setState({ tarife: ok });
        });
    }

    public render() {
        const tarife = this.state.tarife || []
        const tarife_opt = tarife.map(t => <option key={t._idbid} value={t._idbid}>{t.data.bezeichnung}</option>);
        const tarif = tarife.find(t => { return t._idbid === this.state?.value });

        const tarifInfo = tarif ? <div className="muted">
            Guthabenzins: {tarif?.data.guthabenzins_in_prozent}%, Min.-Bausparsumme: {toFormatedString(tarif?.data.mindest_bausparsumme)}
        </div> : ''

        return (
            <div>
                <SelectInput
                    label={this.props.label || "Tarif"}
                    name={this.props.name}
                    onChange={this.onChange}
                    value={this.state.value}
                    required={this.props.required}
                > <option></option>
                    {tarife_opt}
                </SelectInput>
                {tarifInfo}
            </div>
        );
    }
}
