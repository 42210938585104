import { IRate } from "../../Finanzierungsantrag/Finanzierungsbaustein";
import { ISparPlanMonat } from "./types";

export default class MonthPlan {
    private _date: Date;
    private _key: string;
    private raten: IRate[];
    private raten_sum: number;
    private sparPlan?: ISparPlanMonat;

    constructor(date: Date) {
        this._date = date;
        this.raten = [];
        this.raten_sum = 0;
        this._key = new Date(date.setDate(1)).toISOString().substring(0, 10);
    }

    getDate(): Date { return this._date; }

    getKey(): string {
        return this._key;
    }

    addRate(rate: IRate) {
        this.raten.push(rate);
        this.calcRatenSum();
    }

    getRaten() {
        return this.raten;
    }

    getRatenSum() {
        return this.raten_sum;
    }

    setSparPlan(sp: ISparPlanMonat) {
        this.sparPlan = sp;
    }

    getSparPLan() {
        return this.sparPlan;
    }

    private calcRatenSum() {
        this.raten_sum = this.raten.reduce((val, i) => { return val + parseFloat(i.betrag + '') }, parseFloat('0.0'));

    }
}