import * as React from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { EnumService, IEnum } from "../../../services";
import { FaUsers } from "react-icons/fa";

interface IProps {
  onChange(verbundart: any): void;
}

interface IState {
  enums?: IEnum
  isOpen: boolean
}

export default class VerbunsartenSelect extends React.Component<IProps, IState>
{
  constructor(props: IProps) {
    super(props);
    this.state = { isOpen: false }
    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
  }

  UNSAFE_componentWillMount() {
    EnumService.getEnums().then((ok: IEnum) => {
      this.setState({ enums: ok });
    });
  }

  select(ev: any) {
    this.props.onChange(ev.target.value);
  };
  
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public render() {
    if (!this.state.enums) { return <></> }
    const verbundartenOpt = this.state.enums?.verbund_art.map((v, index) => {
      return (
        <DropdownItem
          key={index}
          onClick={this.select}
          value={v}
        >
          {v}
        </DropdownItem>
      );
    });

    return (
      <ButtonDropdown
        outline="true"
        color="info"
        toggle={this.toggle}
        isOpen={this.state.isOpen}>
        <DropdownToggle caret outline color="info"><FaUsers /> Verbund</DropdownToggle>
        <DropdownMenu>{verbundartenOpt}</DropdownMenu>
      </ButtonDropdown>
    );
  }
};
