import * as React from 'react';
import { EnumService } from '../../../../services';
import SelectInput from '../SelectInput';

export interface IEnumInputProps {
    enum?: string
    name: string
    value?: string
    label?: string
    required?: boolean
    onChange(ev: React.ChangeEvent<HTMLInputElement>): void
}

interface IEnumInputState {
    enums?: any
}

export default class EnumInput extends React.Component<IEnumInputProps, IEnumInputState> {
    constructor(props: IEnumInputProps) {
        super(props);
        this.state = {}
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        EnumService.getEnums().then(ok => {
            const i = Object.keys(ok).indexOf(this.props.enum || this.props.name);
           
            if (i!==-1) {
                this.setState({ enums: Object.values(ok)[i] }, () => {
                    if (!this.props.value) {
// TODO .. take first one (?)
                    }
                });
            }
        })
    }

    onChange(ev: any) {
        let evx: React.ChangeEvent<HTMLInputElement> = Object.assign({}, ev);
        if (this.props.onChange) {
            this.props.onChange(evx);
        }
    }

    public render() {
        if (!this.state.enums) { return (<span className="text-danger"> <b>{this.props.name}</b>.. keine daten vorhanden</span>) }
        const options = Object.keys(this.state.enums).map((k) => {
            return <option key={k} value={this.state.enums[k]}>{this.state.enums[k]}</option>
        });

        let nix = undefined;
        return (
            <SelectInput name={this.props.name}
                value={this.props.value}
                onChange={this.onChange}
                label={this.props.label}
                required={this.props.required}
            >
                <option value={nix}></option>
                {options}
            </SelectInput>
        );
    }
}
