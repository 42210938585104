import * as React from 'react';
import { IEinzug } from '../../../types/Lastschrifteinzugdaten';
import { CurrencyInput } from '../../common/aif';

interface IEinzugProps {
    einzug?: IEinzug
    onChange?( einzug?: IEinzug):void
}

interface IEinzugState {
    einzug: IEinzug
}

export default class Einzug extends React.Component<IEinzugProps, IEinzugState> {
    constructor(props: IEinzugProps) {
        super(props);

        this.state = {
            einzug: props.einzug || {
                betrag: 0,
                minderung_betrag: 0
            }
        }
        this.onChange = this.onChange.bind(this);
        this.notify = this.notify.bind(this);
    }

    notify() {
        if (this.props.onChange) {
            this.props.onChange(this.state.einzug);
        }
    }
    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let copy = Object.assign(this.state.einzug, { [ev.target.name]: ev.target.value });
        this.setState({ einzug: copy },this.notify);
    }

    public render() {
        return (
            <div>
                <CurrencyInput label="Betrag" name="betrag" value={this.state.einzug.betrag} onChange={this.onChange} /><br/>
                <CurrencyInput label="Minderung" name="minderung_betrag" value={this.state.einzug.minderung_betrag} onChange={this.onChange} />
            </div>
        );
    }
}
