import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import { FaReply } from "react-icons/fa";
import { ITextNachricht } from "../../../types";
import AButton from "../aif/AButton";
import FormatedDate from "../utils/FormatedDate";


interface Props {
  message: ITextNachricht;
  addResponse(message: string): void;
}

interface State {
  modal: boolean;
  message: ITextNachricht;
  [key: string]: any;
  response?: string;
}

class Message extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { message: this.props.message, modal: false };
    this.toggle = this.toggle.bind(this);
    this.addResponse = this.addResponse.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev: any) {
    this.setState({ [ev.target.name]: ev.target.value }, () => { });
  }

  async addResponse() {
    this.props.addResponse(this.state.response || "");
    this.toggle();
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const m = this.props.message;
    let replyBtn = (
      <div className="text-right">
        <AButton
          onClick={this.toggle}
          label="Antworten"
          size="sm"
        >
          <FaReply />
        </AButton></div>
    );
    if (this.state.message.nachrichtentyp === "Antwort") {
      replyBtn = <></>;
    }
    let classNames = ["messageCard", this.state.message.nachrichtentyp];
    return (
      <div>
        <div className={classNames.join(" ")}>
          <div className="header">
            <div>
              {m.autor}
              <small className="font-italic text-info">
                {" "}
                am <FormatedDate value={m.erstellungsdatum} />{" "}
              </small>
            </div>
          </div>
          <div className="nachricht">
            <div>{m.nachricht}</div>
            <br />
            {replyBtn}
          </div>

        </div>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.state.message.nachrichtentyp}
        >
          <ModalHeader toggle={this.toggle}>Antwort</ModalHeader>
          <ModalBody>
            <Input
              autoFocus={true}
              type="textarea"
              name="response"
              onChange={this.handleChange}
              rows={10}
            ></Input>
          </ModalBody>
          <ModalFooter>
            <AButton label="Absenden" color="info" onClick={this.addResponse}>

            </AButton>
            <AButton label="Abbrechen" onClick={this.toggle} />

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Message;
