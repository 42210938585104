import React, { Component } from 'react';

const month = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

class FormatedDate extends Component {
    formatDate() {
        if (!this.props.value) { return '' }
        try {
            let d = new Date(this.props.value);
            let m = month[d.getMonth()];

            if(this.props.short){
                m=m.substr(0,3);
            }

            let ret = d.getDate() + ' ' + m + ' ' + d.getFullYear();
            return ret;//d.toISOString().substring(0,10);
        }
        catch (err) {
            console.error(err);
            return '';
        }
    }

    formatTime() {
        if (!this.props.value) { return '' }
        try {
            let d = new Date(this.props.value);
            //let ret = d.getHours() + ' : ' + d.getMinutes();
            
            let ret=d.toLocaleTimeString();
            return ret;//d.toISOString().substring(0,10);
        }
        catch (err) {
            console.error(err);
            return '';
        }
    }

    render() {
        let d = this.formatDate();
        const time = this.props.time ? <span style={{ color: 'grey', fontSize: '.8rem' }}>{this.formatTime()}</span> : '';
        return (
            <>{d} {time}</>
        );
    }
}

export default FormatedDate
