import React, { Component } from 'react';
import { cazeDB } from '../../../stores/dexiestore';
import { Fade, Container } from 'reactstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { SwipeableList, SwipeableListItem } from './SwipeableList';
import BsvListItem from './BsvListItem'
import './style.css';
import FbadListItem from './FbadListItem';
import JsonImport from './JsonImport';
import ACollapse from '../aif/ACollapse';
import { SearchInput } from '../aif';
import { deleteProposal, SyncEvent } from '../../../services/SyncService';
import LEAListItem from './LEAListItem';
import PDAListItem from './PDAListItem';

interface IInboxProps {

}

interface IState {
    data: any[]
    q?: string
    filtered_list?: any[]
}

class Inbox extends Component<IInboxProps, IState> {
    subscription: any;
    constructor(props: IInboxProps) {
        super(props);
        this.state = { data: [] };
        //  this.getState = this.getState.bind(this);
        //this.onSwipe = this.onSwipe.bind(this);
        this.loadList = this.loadList.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.filterData = this.filterData.bind(this);
        this.onClearQ = this.onClearQ.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    loadList() {
        cazeDB.getAllCazes().then(data => {
            const hashed = data.map((d: any) => {
                const val = d.bsv || d.fbad || {};
                const persons = val.person_liste?.map((p: any) => { return p.vorname + ' ' + p.nachname }) || []
                d.timestamp = d.timestamp !== undefined ? d.timestamp : 0;
                d.hash = ' ' + persons.join(' ');
                return d;
            })
            this.setState({ data: hashed });
        })
    }

    componentDidMount() {
        this.loadList();
        const self = this;
        this.subscription = SyncEvent.asObservable().subscribe(msg => {
            self.loadList();
        })
    }

    filterData() {
        const q = this.state.q || ''
        const regex = new RegExp(q, 'gi');
        const filter = new Promise((resolve) => {
            const filteredList = this.state.data?.filter((i: any) => i && i.hash?.match(regex));
            resolve(filteredList)
        });
        filter.then((ok: any) => {
            this.setState({ filtered_list: ok });
        })
    }

    onFilterChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ q: ev.target.value }, this.filterData);
    }

    deleteItem(idbid: string) {
        console.log("DELETE ITEM", idbid);
        Promise.all([

            cazeDB.deleteCazeById(idbid).then(ok => {
                console.info(ok);
            }).finally(() => {
                this.loadList();
            }),

            deleteProposal(idbid).then(ok => {
                console.log(ok);
            }, err => {
                console.error(err);
            })
        ]);
    }

    onClearQ() {
        this.setState({ q: '' }, this.filterData);
    }

    formatTimestamp(timestamp: number): string {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();
        return `${hours}:${minutes} ${day}.${month}.${year}`;
    }

    render() {
        const SItems = (props: IState) => {
            const self = this;
            const data = props.data.sort((a,b) => b.timestamp - a.timestamp);
            const listItems = (data).map((i) => {

                i.datum = i.timestamp !== 0 ? this.formatTimestamp(i.timestamp) : "";

                const background = <div className="text-danger"><FaTrashAlt /> Delete </div>;
                const action = () => { this.deleteItem(i._idbid); } //i.uid ? self.getState : self.onSwipe;

                if (i.bsv) {
                    return (<SwipeableListItem root={self} background={background} _idbid={i._idbid} key={i._idbid} onSwipe={action} >
                        <BsvListItem data={i} />
                    </SwipeableListItem>
                    )
                }
                else if (i.fbad) {
                    return (
                        <SwipeableListItem root={self} background={background} _idbid={i._idbid} key={i._idbid} onSwipe={action} >
                            <FbadListItem data={i} />
                        </SwipeableListItem>
                    )
                }
                else if (i.lastschrifteinzugantrag || i?.data?.antrag_art === "Lastschrifteinzugantrag") {

                    return (
                        <SwipeableListItem root={self} background={background} _idbid={i._idbid} key={i._idbid} onSwipe={action} >
                            <LEAListItem data={i} />
                        </SwipeableListItem>
                    )
                }
                else if (i.personendatenantrag || i?.data?.antrag_art === "Personendatenantrag") {

                    return (
                        <SwipeableListItem root={self} background={background} _idbid={i._idbid} key={i._idbid} onSwipe={action} >
                            <PDAListItem data={i} />
                        </SwipeableListItem>
                    )
                }
                else {
                    return (
                        <SwipeableListItem root={self} background={background} _idbid={i._idbid} key={i._idbid} onSwipe={action} >
                            <div>Unbekannte Antragsart</div>
                        </SwipeableListItem>
                    )
                }
            }
            );
            return (<SwipeableList>{listItems}</SwipeableList>);
        }

        return (
            <Container fluid={false} className="mt-4">
                <Fade>
                    <div className="InboxGrid">
                        <div className="InboxHeader">
                            <SearchInput type="search" label="Suche nach ..." name="q" value={this.state.q} onChange={this.onFilterChange} onClear={this.onClearQ} />
                            <ACollapse label="Json Import">
                                <JsonImport onImportDone={this.loadList} />
                            </ACollapse>
                        </div>
                        <div className="InboxBody">
                            <SItems data={this.state.filtered_list || this.state.data} ></SItems>
                        </div>
                    </div>
                </Fade>
            </Container>
        );
    }
}

export default Inbox;
