import { BehaviorSubject } from 'rxjs';
const subject = new BehaviorSubject();
export const Types = {
    Error_Message:'error',
    Message:'notify'
}

export const ToastEvents = {
    error:(message)=>{subject.next({type:Types.Error_Message,message:message})},
    notify: (message) => {subject.next({type:Types.Message,message:message})},
    getService: () => subject.pipe().asObservable()
};
