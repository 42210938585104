import * as React from 'react';
import { IAbteilung2 } from '.';
import Abteilung2 from './Abteilung2';
import { Row, Col } from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import { AButton } from '../../../common/aif';

interface IA2ListeProps {
    data?: IAbteilung2[]
    onChange?(data: any): void
}

interface IA2ListeState {
    data: IAbteilung2[]
}

export default class A2Liste extends React.Component<IA2ListeProps, IA2ListeState> {
    constructor(props: IA2ListeProps) {
        super(props);
        this.state = {
            data: this.props.data || []
        }
        this.addItem = this.addItem.bind(this);
        this.remove = this.remove.bind(this);
    }

    componentWillUnmount() {
        if (this.props.onChange) { this.props.onChange({ abteilung2_liste: this.state.data }) }
    }

    addItem() {
        let item: IAbteilung2 = {}
        let copy = [...this.state.data];
        copy.push(item);
        this.setState({ data: copy });
    }

    remove(data: IAbteilung2) {
        const index = this.state.data.indexOf(data);
        if (index !== -1) {
            let copy = [...this.state.data];
            copy.splice(index, 1);
            this.setState({ data: copy });
        }
    }

    public render() {
        const items = this.state.data.map((a, i) => {
            return <Abteilung2 key={i} data={a} onRemove={this.remove} />
        });
        return (
            <div>
                <Row>
                    <Col>
                        <div className="text-secondary pl-2">Lasten und Beschränkungen</div>
                    </Col>
                    <Col>
                        <div className="text-right"><AButton rounded onClick={this.addItem} size="sm" color="info" outline><FaPlus /></AButton> </div>
                    </Col>
                </Row>

                {items}
            </div>
        );
    }
}
