import * as React from 'react';

interface ICheckBoxInputProps {
    name: string
    value?: boolean|string
    _onChange?(name:string, value:boolean): void
    onChange?(ev: React.ChangeEvent<HTMLInputElement>):void
    label?: string
    required?:boolean
}

interface ICheckBoxInputState {
    value: boolean|string
}

export default class CheckBoxInput extends React.Component<ICheckBoxInputProps, ICheckBoxInputState> {
    myRef: React.RefObject<any>;
    
    constructor(props: ICheckBoxInputProps) {
        super(props);
        this.state = {
            value: this.props.value || false
        }
        this.myRef = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.onLabelClick = this.onLabelClick.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let evCopy= Object.assign({},ev);
        evCopy.target.value=ev.target.checked+'';
        this.setState({ value: ev.target.checked }, () => {
            if(this.props.onChange){
                this.props.onChange(evCopy);
            }
        });
    }
    onLabelClick(ev:any){
        this.myRef.current.click();
    }
    public render() {
        let value:boolean = this.state.value === "true" || this.state.value===true;
        let labelClass = value ? 'checked' : '';
        labelClass+=this.props.required?' required':'';
        return (
            <div className="CheckBoxInput">
                <input ref={this.myRef} type="checkbox" name={this.props.name} checked={value} onChange={this.onChange} />
                <label onClick={this.onLabelClick} htmlFor={this.props.name} className={labelClass}>{this.props.label || this.props.name}</label>
            </div>
        );
    }
}
