import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IAbtretung } from './types';
import TextInput from '../../../common/aif/TextInput';
import NumberInput from '../../../common/aif/NumberInput';
import CheckBoxInput from '../../../common/aif/CheckBoxInput';
import EnumInput from '../../../common/aif/EnumInput';
import { FaTrashAlt } from 'react-icons/fa';

interface IAbtretungProps {
  data: IAbtretung
  onRemove?(data: IAbtretung): void
}

interface IAbtrettungState {
  data: IAbtretung
}

export default class Abtretung extends React.Component<IAbtretungProps, IAbtrettungState> {
  constructor(props: IAbtretungProps) {
    super(props);

    this.state = {
      data: this.props.data
    }
    this.onChange = this.onChange.bind(this);
    this.remove = this.remove.bind(this);
  }

  UNSAFE_componentWillReceiveProps(newProps: IAbtretungProps) {
    this.setState({ data: newProps.data || [] });
  }

  remove() {
    if (this.props.onRemove) { this.props.onRemove(this.props.data); }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
    this.setState({ data: newVal });
  }
  
  public render() {
    return (
      <div className="Abtretung">

        <Row>
          <Col md="3">
            <TextInput label="Berechtigter" name="berechtigter" value={this.state.data.berechtigter} onChange={this.onChange} />
          </Col>
          <Col>
            <TextInput label="Kommentar" name="kommentar" value={this.state.data.kommentar} onChange={this.onChange} />
          </Col>
          <Col md="2">
            <NumberInput label="Rang" name="rang" value={this.state.data.rang} onChange={this.onChange} />
          </Col>
          <Col md="1" >
            <div onClick={this.remove} style={{marginTop:'100%',padding:'0px'}}><FaTrashAlt onClick={this.remove} className="removeIcon" /></div>
          </Col>

        </Row>
        <Row>
          <Col>
            <EnumInput label="Vereinbarung" name="vereinbarung_art" value={this.state.data.vereinbarung_art} onChange={this.onChange} />
          </Col>
          <Col>
            <NumberInput label="Abtretung Anteil" name="abtretung_anteil" value={this.state.data.abtretung_anteil} onChange={this.onChange} />
          </Col>
          <Col md="2">
            <CheckBoxInput required label="Eigenrecht" name="eigenrecht" value={this.state.data.eigenrecht} onChange={this.onChange} />
          </Col>
        </Row>
      </div>
    );
  }
}
