import { cazeDB } from "../../../../stores/dexiestore";

import { TarifeDemo } from "./TarifeDemo";
import { TarifInt } from '../../../../types';
import { TarifMapper } from '../Tarife/TarifMapper';
import BackendService from "../../../../services/BackendService";

export interface ITarifData {
  institut?: string
  created: Date;
  data: TarifInt;
  _idbid: number | string
}

export interface TarifeInfo {
  last_sync?: Date,
  count?: number
}

class TarifService {
  constructor() {
    this.init();
  }

  static async loadTarife() {
    const t = await BackendService.getTarife()
    const tarife: TarifInt[] = TarifMapper.map(t.data);
    return this.saveTarife(tarife);
  }

  getAll(): Promise<ITarifData[]> {
    return cazeDB.getAllTarife();
  }

  static saveTarife(tarife: TarifInt[]): Promise<any> {
    cazeDB.tarife.clear();
    return new Promise((resolve, reject) => {
      tarife.forEach(async t => {
        const item: ITarifData = {
          created: new Date(),
          institut: '(?) unklar',
          data: t,
          _idbid: t.nummer
        }
        try {
          await cazeDB.saveTarif(item);
        } catch (err) {
          reject(err); return;
        }
      })
      resolve(true);
      console.info(tarife.length + ' %c BSV Tarife geladen', 'color:orange')
    });
  }

  static getTarif(tnr: number) {
    return  cazeDB.tarife.get(tnr);
  }

  async getInfo(): Promise<TarifeInfo> {
    let ret: TarifeInfo = { count: 0 };
    const all = await this.getAll();
    if (all.length > 0) {
      ret.count = all.length;
      ret.last_sync = all[0].created;
      Promise.resolve(ret);
    }
    else {
      Promise.reject('BSV Tariffe nicht geladen')
    }
    return ret;
  }

  async init() {
    const all = await this.getAll();
    if (all.length === 0) {
      await this._initDummyData();
    };
  }

  _initDummyData() {
    cazeDB.tarife.clear();
    const data = TarifMapper.map(TarifeDemo);
    TarifService.saveTarife(data);
  }
}

export default TarifService