import React, { Component } from "react";
import {
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";
import "./validation.css";
import TextInput from "../components/common/aif/TextInput";

interface Props {
  name: string;
  value?: string;
  required?: boolean;
  onChange: any;
  label?: string;
  art?: string;
}

const docTypes = {
  Handelsregisterauszug: /^[a-zA-Z0-9]{8}$/,
  Personalausweis: /^[a-zA-Z0-9]{9}$/,
  Reisepass: /^[a-zA-Z0-9]{9}$/
}


const getRegex = (art: string): RegExp => {
  const index = Object.keys(docTypes).indexOf(art);
  if (index !== -1) {
    return Object.values(docTypes)[index];
  }
  return new RegExp(/.+/);
}

class DocNr extends Component<Props> {

  render() {
   const regex = getRegex(this.props.art||'');
    let image = null

    switch (this.props.art) {
      case 'Reisepass':
        image = require("./data/paspoort-documentnummer.webp");
        break
      case 'Personalausweis':
        image = require("./data/ausweisnummer-neuer-personalausweis.jpg");
        break
    }
    let helperPopUp = <></>
    if (image) {

      helperPopUp = <UncontrolledPopover trigger="focus" placement="bottom" target="docnr">
        <PopoverHeader>Hinweis</PopoverHeader>
        <PopoverBody>
          <img alt="Hinweis" src={image} width="250px"></img>
        </PopoverBody>
      </UncontrolledPopover>;
    }

    return (
      <div>
        <TextInput        
          label={this.props.label}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          id="docnr"
          required={this.props.required}
          regexp={regex}
        />
        {helperPopUp}
      </div>
    );
  }
}

export default DocNr;
