import { BehaviorSubject } from 'rxjs';
import { PersonInt } from '../../../types';
import { debounceTime } from 'rxjs/operators';


export interface IPersonEvent {
    // inden in PersonenListe
    type: 'init' | 'person_removed'|'verbund_removed'
    index?: number
    person?: PersonInt
}
const subject = new BehaviorSubject<IPersonEvent>({ type: 'init' });

export class PersonEventService {
    static addEvent(object: IPersonEvent) { subject.next(object) }
    static getService() { return subject.pipe(debounceTime(100)) }
};
