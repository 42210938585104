import * as React from 'react';

interface IASelectListProps {
    onBlur?(): void
}

interface IASelectListState {

}

export default class ASelectList extends React.Component<IASelectListProps, IASelectListState> {

    constructor(props: IASelectListProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        return (
            <div className="ASelectList" onBlur={this.props.onBlur}>
                {this.props.children}
            </div>
        );
    }
}
