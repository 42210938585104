import * as React from 'react';
import { ACollapse } from '../common/aif';
import { toFormatedString } from '../common/utils';
import { IRate } from '../Finanzierungsantrag/Finanzierungsbaustein';
import { ILBResult } from './lib/types';

interface ISparPlanProps {
    data?: ILBResult;
}

interface ISparPlanState {

}

const d_opt_1 = { month: 'short', year: 'numeric' };
//const d_opt_2 = { year: 'numeric', month: 'short', day: 'numeric' };
//const d_opt_3 = { day: 'numeric' };

// let label = day.toLocaleDateString('de-DE', d_options);

export default class SparPlan extends React.Component<ISparPlanProps, ISparPlanState> {

    constructor(props: ISparPlanProps) {
        super(props);
        this.state = {
        }
    }

    public render() {
        const data = this.props.data;

        if (!data || data?.plan.length === 0) { return <></> }
       // if (!data?.success) { return <>Kein Ergebnis. Die Sparrate ist zu klein</> }

        const rows = data?.plan.map((m, i) => <div className="SparPlanRow" key={i}>
            <div>{m.getDate().toLocaleDateString('de-DE', d_opt_1)}</div>
            <div>
                <ul>
                    {m.getRaten().map((r: IRate, x) => <li key={x}>{toFormatedString(r.betrag)}</li>)}
                </ul>
            </div>
            <div className={m.getSparPLan()?.entgelt===0?'erreicht_false':''}>
                {toFormatedString(m.getSparPLan()?.entgelt)}
            </div>
            <div className="erreicht_false">
                {toFormatedString(0.0)}
            </div>
            <div className={m.getSparPLan()?.zinsen===0?'erreicht_false':''}>
                {toFormatedString(m.getSparPLan()?.zinsen || 0)}
            </div>
            <div>
                {toFormatedString(m.getSparPLan()?.guthaben || 0)}
            </div>
            <div className={'erreicht_' + m.getSparPLan()?.ansparguthaben_erreicht || ''}>
                {m.getSparPLan()?.anspargrad}
            </div>
            <div className={'erreicht_' + m.getSparPLan()?.bewertungszahl_erreicht || ''} title={m.getSparPLan()?.mindest_bewertungszahl + ''}>
                {m.getSparPLan()?.bewertungszahl}
            </div>
        </div>)

        return (
            <ACollapse label="Sparplan" expand={true}>
                <table style={{ display: (data?.success ? 'block' : 'none') }}><tbody>
                    <tr>
                        <th>Abschlussgebühr</th>
                        <td>{toFormatedString(data.abs_gebuehr)}</td>
                        <th>Bausparguthaben</th>
                        <td>{toFormatedString(data.bausparguthaben)}</td>
                    </tr>
                    <tr>
                        <th>Summe Sparzinsen</th>
                        <td>{toFormatedString(data.summe_sparzinsen)}</td>
                        <th>Zuteilungstermin</th>
                        <td>{data.zuteilungstermin ? data.zuteilungstermin.toLocaleDateString('de-DE', d_opt_1) : ''}</td>
                    </tr>
                </tbody>
                </table>
                <br />
                <div className="SparPlan">
                    <div className="SparPlanRow Header">
                        <div>Monat</div>
                        <div>Zahlungen</div>
                        <div>Entgelt</div>
                        <div>Versicherung</div>
                        <div>Zinsen</div>
                        <div>Kontostand</div>
                        <div>Anspargrad</div>
                        <div>BWZ</div>
                    </div>
                    {rows}
                </div>
            </ACollapse>
        );
    }
}

