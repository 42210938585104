import React, { useState } from "react";
import BackendService from '../../../services/BackendService';
import { ToastEvents } from '../Toast';
import {Button} from "reactstrap";

interface AntragReloadButtonProps {
    antragId: string;
    onAntragLoaded: (antrag: any) => void;
}

export default function AntragReloadButton(props: AntragReloadButtonProps) {
    const [isLoading, setIsLoading] = useState(false);

    const handleReload = async () => {
        try{
            setIsLoading(true);
            const antrag = await BackendService.leseAntrag(props.antragId);
            props.onAntragLoaded(antrag.data);
            ToastEvents.notify("Antrag erfolgreich geladen")
        } catch (err) {
            ToastEvents.error('Fehler bei Datenübertragung! ' + err.message);
            if (err.response && err.response.status === 404) {
                ToastEvents.error("Der Antrag konnte nicht gefunden werden");
            }
        }
        setIsLoading(false);
    };

    return (
        <Button disabled={!props.antragId || isLoading} onClick={handleReload} color={"info"}>
            Reload
        </Button>
    );
}
