import * as React from 'react';
import {Col, Row} from 'reactstrap';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import TextInput from '../../../common/aif/TextInput';
import {IObjektkosten} from './types';

export interface IObjektkostenProps {
    data: IObjektkosten
    onChange?(data: IObjektkosten): void
}

interface IState {
    ok: IObjektkosten
}

export default class Objektkosten extends React.Component<IObjektkostenProps, IState> {
    constructor(props: IObjektkostenProps) {
        super(props);
        this.state = { ok: this.props.data||{} }
        this.onChange = this.onChange.bind(this);
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        const newVal = { [ev.target.name]: ev.target.value }
        const newState: IObjektkosten = Object.assign(this.state.ok,newVal);
        this.setState({ ok: newState }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.ok);
            }
        });
    }

    public render() {
        const ok = this.state.ok||{};
        return (
            <Row>
                <Col md="4">
                    <CurrencyInput label="Erdaushub Hausanschlüsse" name="erdaushub_hausanschluesse" value={ok.erdaushub_hausanschluesse} onChange={this.onChange} />
                    <CurrencyInput label= "Aussenanlage" name="aussenanlage" value={ok.aussenanlage} onChange={this.onChange} />
                    <CurrencyInput label="Architekt-/Statiker-/Vermesserkosten" name="architekt_statiker_vermesser_kosten" value={ok.architekt_statiker_vermesser_kosten} onChange={this.onChange} />
                    <CurrencyInput label="Renovierungskosten" name="renovierungskosten" value={ok.renovierungskosten} onChange={this.onChange} />
                    <CurrencyInput label="Kosten Bau" name="kosten_bau" value={ok.kosten_bau} onChange={this.onChange} />
                    <CurrencyInput label="Kaufpreis" name="kaufpreis" value={ok.kaufpreis} onChange={this.onChange} />
                    <CurrencyInput label="Garage" name="garage" value={ok.garage} onChange={this.onChange} />
                    <CurrencyInput label="Stellplatz" name="stellplatz" value={ok.stellplatz} onChange={this.onChange} />
                </Col>
                <Col md="4">
                    <CurrencyInput label="Nachträgliche Herstellungskosten" name="nachtraegliche_herstellungskosten" value={ok.nachtraegliche_herstellungskosten} onChange={this.onChange} />
                    <CurrencyInput label="Wertsteigernde Renovierungskosten" name="wertsteigernde_renovierungskosten" value={ok.wertsteigernde_renovierungskosten} onChange={this.onChange} />
                    <CurrencyInput name="notarkosten" label="Notarkosten" value={ok.notarkosten} onChange={this.onChange} />
                    <CurrencyInput name="maklergebuehr" value={ok.maklergebuehr} onChange={this.onChange} />
                    <CurrencyInput name="grundstueckspreis" value={ok.grundstueckspreis} onChange={this.onChange} />
                    <CurrencyInput name="grunderwerbssteuer" value={ok.grunderwerbssteuer} onChange={this.onChange} />
                
                </Col>
                <Col md="4">
                    <CurrencyInput label="Sonstige Kosten" name="sonstige_kosten" value={ok.sonstige_kosten} onChange={this.onChange} />
                    <TextInput label="Sonnstige Kosten Bezeichnung" name="sonstige_kosten_bezeichnung" value={ok.sonstige_kosten_bezeichnung || ''} onChange={this.onChange} />
                    <CurrencyInput label="Erschliessungskosten" name="erschliessungskosten" value={ok.erschliessungskosten} onChange={this.onChange} />
                </Col>
            </Row>
        );
    }
}
