import axios from 'axios';
import { keycloak } from '../components/security';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cazeDB } from '../stores/dexiestore';

const subject = new BehaviorSubject('');
export const SyncEvent = new BehaviorSubject('');

const syncInstance = axios.create({
    baseURL:process.env.REACT_APP_API_HOST,
   
});

syncInstance.interceptors.request.use((config) => {
    const token = keycloak.token;
    config.headers.Authorization='Bearer ' + token;
    return config;
})

subject.asObservable().pipe(debounceTime(10000)).subscribe(msg => {
    cazeDB.getCazeByKey(msg).then(data => {
        syncInstance.post('/sync/proposal/' + msg, data).catch(err => console.error(err));
    }, err => { });
});

export const syncRequest = (msg: string | undefined) => { return msg ? subject.next(msg) : null };

export const loadSyncProposals = () => {
    syncInstance.get('/sync/proposals').then((ok) => {
        ok.data.forEach((i: any) => {
            cazeDB.saveCaze(i.data);
        });
        SyncEvent.next('Sync_Completed');
    })
        .catch(err => console.error(err));
}

export const deleteProposal = (_idbid: string) => {
    return syncInstance.delete('/sync/proposal/' + _idbid)
}