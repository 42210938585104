import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ImmoList from './ImmoList';
import Immo from './Immo';

class ImmoPool
    extends Component {
    render() {
        return (
            <Router>
                <Route exact path="/immopool" component={ImmoList} />
                <Route path="/immopool/immo" component={Immo} />
                <Route path="/immopool/immo/:_id" component={Immo} />
            </Router>
        );
    }
}

export default ImmoPool;