import * as React from 'react';
import { IErsatzsicherheit } from './types';
import EnumInput from '../../../common/aif/EnumInput';
import { Row, Col } from 'reactstrap';
import CurrencyInput from '../../../common/aif/CurrencyInput';
import TextInput from '../../../common/aif/TextInput';
import { FaTrashAlt, FaCopy } from 'react-icons/fa';
export * from './types';

interface IErsatzsicherheitProps {
  data: IErsatzsicherheit
  onRemove?(data: IErsatzsicherheit): void
  onCopy?(data: IErsatzsicherheit): void
}

interface IErsatzsicherheitState {
  data: IErsatzsicherheit
}

export default class Ersatzsicherheit extends React.Component<IErsatzsicherheitProps, IErsatzsicherheitState> {
  constructor(props: IErsatzsicherheitProps) {
    super(props);
    this.state = {
      data: this.props.data
    }
    this.onChange = this.onChange.bind(this);
    this.remove = this.remove.bind(this);
    this.copy = this.copy.bind(this);
  }

  remove() {
    if (this.props.onRemove) { this.props.onRemove(this.props.data); }
  }

  copy() {
    if (this.props.onCopy) { this.props.onCopy(this.props.data); }
  }

  onChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const newVal = Object.assign(this.state.data, { [ev.target.name]: ev.target.value });
    this.setState({ data: newVal });
  }

  public render() {
    return (
      <div className="mb-4">
        <Row>
          <Col> 
          <div className="h5 p-2 text-secondary">Ersatzsicherheit</div>
          </Col>
          <Col className="text-right"><FaTrashAlt onClick={this.remove} className="removeIcon" title="Entfernen" />
            <FaCopy onClick={this.copy} className="copyIcon" title="LbsAnnudarlehen kopieren" />
            <span style={{marginRight: "10px"}}></span>
          </Col>
        </Row>
        <Row>
          <Col><TextInput label="Sicherheitengeber" name="sicherheitengeber" value={this.state.data.sicherheitengeber} onChange={this.onChange} /></Col>
          <Col><TextInput label="Kennnummer" name="kennnummer" value={this.state.data.kennnummer} onChange={this.onChange} /></Col>
        </Row>
        <Row>
          <Col>
            <EnumInput label="Art" onChange={this.onChange} name="ersatzsicherheit_art" value={this.state.data.ersatzsicherheit_art + ''} /></Col>
          <Col md="3">
            <CurrencyInput label="Nomineller Wert" name="nomineller_wert" value={this.state.data.nomineller_wert} onChange={this.onChange} />
          </Col>
          <Col md="3">
            <CurrencyInput label="Aktueller Wert" name="aktueller_wert" value={this.state.data.aktueller_wert} onChange={this.onChange} />
          </Col>
        </Row>
      </div>
    );
  }
}
