import * as React from 'react';
export interface IDateInputProps {
    name: string
    label?: string
    value?: string | Date
    required?: boolean
    onChange?(ev: React.ChangeEvent<HTMLInputElement>): void
}

export default class DateInput extends React.Component<IDateInputProps> {

    constructor(props: IDateInputProps) {
        super(props);
        this.formatDate = this.formatDate.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    formatDate() {
        if (!this.props.value) { return '' }
        try {
            let d = new Date(this.props.value);
            return d.toISOString().substring(0, 10);
        }
        catch (err) {
            console.error(err);
            return '';
        }
    }

    onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        let datum = new Date(ev.target.value);
       
        try {
            if (this.props.onChange) { this.props.onChange(ev); 
                console.log('-> ',ev.target.value,' :: ',datum);
            }
        } catch (err) {
            console.error(err);
        }
    }

    public render() {
        let mainClass = 'AnimatedInput '
        let labelClass = 'hasValue'
        if (this.props.required && (this.props.value === undefined ||this.props.value === '' )) {
            mainClass += ' invalid';
        }
        let d = this.formatDate();
        return (
            <div className={mainClass}>
                <input type="date" name={this.props.name} value={d} onChange={this.props.onChange} />
                <label htmlFor={this.props.name} className={labelClass}>
                    <span className="Label">{this.props.label || this.props.name} {this.props.required ? '*' : ''}</span></label>
            </div>
        );
    }
}
